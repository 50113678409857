import {
  ITransactionsByFilterResponse,
  ITransactionsRequest,
  ITransactionsResponse,
} from '@store/features/transactions/types';
import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import { application, operations } from '@constants/traceability';

const transactionsApi = {
  getSpendingTransactions({
    cardId,
    forceRefresh = false,
    additionalHeaders = {},
  }: ITransactionsRequest) {
    return wrappedAxiosRequest<null, ITransactionsResponse>({
      method: 'get',
      url: endpoints.getTransactions
        .replace('{card_id}', cardId)
        .concat(`?force_refresh=${forceRefresh}`),
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn':
          application.bizpnPrefix + operations.AllSpendingTransactions,
        'x-payx-comp':
          application.compPrefix + operations.AllSpendingTransactions,
      },
    });
  },
  getSavingsTransactions({
    cardId,
    forceRefresh = false,
    additionalHeaders = {},
  }: ITransactionsRequest) {
    return wrappedAxiosRequest<null, ITransactionsResponse>({
      method: 'get',
      url: endpoints.getTransactions
        .replace('{card_id}', cardId)
        .concat(`?force_refresh=${forceRefresh}`),
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn':
          application.bizpnPrefix + operations.AllSavingsTransactions,
        'x-payx-comp':
          application.compPrefix + operations.AllSavingsTransactions,
      },
    });
  },
  getSpendingTransactionsByFilter({
    cardId,
    transactionType,
    date_from,
    date_to,
    additionalHeaders = {},
  }: ITransactionsRequest) {
    return wrappedAxiosRequest<null, ITransactionsByFilterResponse>({
      method: 'get',
      url:
        date_from || date_to
          ? endpoints.getTransactionsByFilter
              .replace('{card_id}', cardId)
              .replace('{filter_type}', transactionType ?? '')
              .concat(`?date_from=${date_from}&date_to=${date_to}`)
          : endpoints.getTransactionsByFilter
              .replace('{card_id}', cardId)
              .replace('{filter_type}', transactionType ?? ''),
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn':
          application.bizpnPrefix + operations.FilteredSpendingTransactions,
        'x-payx-comp':
          application.compPrefix + operations.FilteredSpendingTransactions,
      },
    });
  },
  getSavingsTransactionsByFilter({
    cardId,
    transactionType,
    date_from,
    date_to,
    additionalHeaders = {},
  }: ITransactionsRequest) {
    return wrappedAxiosRequest<null, ITransactionsByFilterResponse>({
      method: 'get',
      url:
        date_from || date_to
          ? endpoints.getTransactionsByFilter
              .replace('{card_id}', cardId)
              .replace('{filter_type}', transactionType ?? '')
              .concat(`?date_from=${date_from}&date_to=${date_to}`)
          : endpoints.getTransactionsByFilter
              .replace('{card_id}', cardId)
              .replace('{filter_type}', transactionType ?? ''),
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn':
          application.bizpnPrefix + operations.FilteredSavingsTransactions,
        'x-payx-comp':
          application.compPrefix + operations.FilteredSavingsTransactions,
      },
    });
  },
  getSpendingTransactionsBySearch({
    cardId,
    searchText,
    additionalHeaders = {},
  }: ITransactionsRequest) {
    return wrappedAxiosRequest<null, ITransactionsResponse>({
      method: 'get',
      url: endpoints.getTransactionsBySearch.replace('{card_id}', cardId),
      params: {
        q: searchText,
      },
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn':
          application.bizpnPrefix + operations.SearchSpendingTransactions,
        'x-payx-comp':
          application.compPrefix + operations.SearchSpendingTransactions,
      },
    });
  },
  getSavingsTransactionsBySearch({
    cardId,
    searchText,
    additionalHeaders = {},
  }: ITransactionsRequest) {
    return wrappedAxiosRequest<null, ITransactionsResponse>({
      method: 'get',
      url: endpoints.getTransactionsBySearch.replace('{card_id}', cardId),
      params: {
        q: searchText,
      },
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn':
          application.bizpnPrefix + operations.SearchSavingsTransactions,
        'x-payx-comp':
          application.compPrefix + operations.SearchSavingsTransactions,
      },
    });
  },
};

export default transactionsApi;
