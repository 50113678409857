import { FillInDataFirstView } from '@views/SignUp';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  fillInDataFirstSchema,
  IFillInDataFirstFormInputs,
  phoneOnChange,
} from '@organisms/FillInDataFirstForm/helper';
import { selectFillInDataFirst } from '@store/features/fillInData/selectors';
import { useSelector } from 'react-redux';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useEffect, useMemo } from 'react';
import { fillInDataActions } from '@store/features/fillInData/slice';
import fillInDataThunks from '@store/features/fillInData/asyncThunks';
import { FillInDataFirstProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import { useAppDispatch } from '@store/hooks';

const FillInDataFirstPage = ({ navigation }: FillInDataFirstProps) => {
  const fillInData = useSelector(selectFillInDataFirst);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFillInDataFirstFormInputs>({
    defaultValues: fillInData,
    resolver: yupResolver(fillInDataFirstSchema),
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fillInDataThunks.getUserInfo());
  }, []);

  useEffect(() => {
    reset({
      ...fillInData,
      phoneNumber: phoneOnChange(fillInData.phoneNumber),
    });
  }, [fillInData]);

  const onSubmit = (data: IFillInDataFirstFormInputs) => {
    dispatch(fillInDataActions.localUpdateFirst(data));
    navigation.navigate('FillInDataSecond');
  };

  const isStateReady = useMemo(() => {
    return (
      fillInData.firstName !== '' ||
      fillInData.lastName !== '' ||
      fillInData.phoneNumber !== '' ||
      fillInData.emailAddress !== ''
    );
  }, [fillInData]);

  return (
    <MediaLayoutTemplate
      Desktop={FillInDataFirstView.desktop}
      Mobile={FillInDataFirstView.mobile}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      isStateReady={isStateReady}
    />
  );
};

export default FillInDataFirstPage;
