import { OrderNewCardRequestContent } from '@organisms';
import {
  HeaderAndFooterMobileTemplate,
  HeaderMobileTemplate,
} from '@templates';
import { requestStatusType } from '@store/features/cards/types';
import { Button, Loader } from '@atoms';
import { Platform } from 'react-native';
import { MobileWebHeader } from '@molecules';
import React from 'react';
import { ICardholderAdditionalInfo } from '@store/features/cardholder/types';
import { View } from 'tamagui';

interface OrderNewCardRequestViewProps {
  navigateBack: () => void;
  requestCard: () => void;
  orderRequestStatus: requestStatusType | null;
  allowCardReissue: ICardholderAdditionalInfo['allow_card_reissue'];
  isLoading: boolean;
}

const OrderNewCardRequestView = ({
  navigateBack,
  requestCard,
  orderRequestStatus,
  allowCardReissue,
  isLoading,
}: OrderNewCardRequestViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      {allowCardReissue ? (
        <HeaderAndFooterMobileTemplate
          header={{
            isBackButton: true,
            onPressBack: navigateBack,
            title: 'Order a new card',
          }}
          footerContent={
            <>
              {!isLoading && (
                <Button
                  onPress={requestCard}
                  isLoading={orderRequestStatus === 'pending'}
                >
                  Order card
                </Button>
              )}
            </>
          }
        >
          {isLoading ? (
            //TODO: replace with custom skeleton loader to improve UI/UX
            <View
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              flexGrow={1}
              height={'100%'}
            >
              <Loader width={64} height={64} />
            </View>
          ) : (
            <OrderNewCardRequestContent allowCardReissue={allowCardReissue} />
          )}
        </HeaderAndFooterMobileTemplate>
      ) : (
        <HeaderMobileTemplate
          header={{
            isBackButton: true,
            onPressBack: navigateBack,
            title: 'Order a new card',
          }}
        >
          {isLoading ? (
            <View
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              flexGrow={1}
              height={'100%'}
            >
              <Loader width={64} height={64} />
            </View>
          ) : (
            <OrderNewCardRequestContent allowCardReissue={allowCardReissue} />
          )}
        </HeaderMobileTemplate>
      )}
    </>
  );
};

export default OrderNewCardRequestView;
