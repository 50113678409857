import { HeaderAndFooterMobileTemplate, WebLogoTemplate } from '@templates';
import { View, ScrollView } from 'tamagui';
import { FooterButtonsRow, ImageWithContent } from '@molecules';
import React from 'react';
import { Platform, useWindowDimensions } from 'react-native';
import { TButton, Text } from '@atoms';
import TCImage from '../../../assets/TCupdate.svg';
import TCFileLogo from '../../../assets/TCFileLogo.svg';
import useIsTablet from '@hooks/useIsTablet';
import { RootState } from '@store';
import { maxMediaQuery } from '@constants/ui';
interface AgreementsIntroViewProps {
  navigateBack: () => void;
  onPressSave: () => void;
  agreements: RootState['agreements']['agreements'];
}

const mobile = ({
  navigateBack,
  onPressSave,
  agreements,
}: AgreementsIntroViewProps) => {
  const isTablet = useIsTablet();

  return (
    <>
      <HeaderAndFooterMobileTemplate
        header={{
          title: 'Terms and conditions',
          isBackButton: true,
          onPressBack: navigateBack,
        }}
        footerContent={<TButton onPress={onPressSave}>Let’s start</TButton>}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          h={Platform.OS === 'web' ? 0 : '100%'}
        >
          <>
            <View pt={40} px={isTablet ? 4 : 7} pb={6}>
              <ImageWithContent image={<TCImage />} space={'$7'}>
                <Text
                  textAlign={'center'}
                  variant="subtitle"
                  pb={3}
                  marginHorizontal={40}
                >
                  To complete your registration, review and accept the terms and
                  conditions in the following documents:
                </Text>
              </ImageWithContent>
            </View>
            <View
              display={'flex'}
              flexDirection={'column'}
              marginHorizontal={50}
              mt={24}
              alignSelf={'center'}
              justifyContent={'center'}
            >
              {agreements.length > 1 &&
                agreements.map((agreement) => {
                  return (
                    <View
                      key={agreement.id}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      marginBottom={24}
                      marginRight={25}
                    >
                      <View>
                        <TCFileLogo />
                      </View>
                      <Text
                        marginLeft={16}
                        color={'$black'}
                        fontSize={'$h5'}
                        fontWeight={'$medium'}
                        lineHeight={20}
                        lineBreakMode={'tail'}
                      >
                        {agreement.name}
                      </Text>
                    </View>
                  );
                })}
            </View>
          </>
        </ScrollView>
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

const desktop = ({
  navigateBack,
  onPressSave,
  agreements,
}: AgreementsIntroViewProps) => {
  const { width: desktopWidth } = useWindowDimensions();

  return (
    <WebLogoTemplate withLogoIcon={desktopWidth > maxMediaQuery.laptop}>
      <Text variant={'title'} mb={32}>
        Terms and conditions
      </Text>
      <View maxWidth="325px" width="100%" overflow="hidden" mb={4}>
        <ScrollView showsVerticalScrollIndicator={false} h={'100%'}>
          <>
            <View px={7} pb={6}>
              <ImageWithContent image={<TCImage />} space={32}>
                <Text
                  variant="subtitle"
                  pb={3}
                  marginHorizontal={40}
                  textAlign={'center'}
                >
                  To complete your registration, review and accept the terms and
                  conditions in the following documents:
                </Text>
              </ImageWithContent>
            </View>
            <View
              display={'flex'}
              flexDirection={'column'}
              marginHorizontal={40}
              mt={24}
              alignSelf={'center'}
              justifyContent={'center'}
            >
              {agreements.length > 1 &&
                agreements.map((agreement) => {
                  return (
                    <View
                      key={agreement.id}
                      display={'flex'}
                      flexDirection={'row'}
                      alignItems={'center'}
                      mb={24}
                    >
                      <View>
                        <TCFileLogo />
                      </View>
                      <Text
                        ml={16}
                        color={'$black'}
                        fontSize={'$h5'}
                        fontWeight={'$medium'}
                        lineHeight={20}
                        lineBreakMode={'tail'}
                      >
                        {agreement.name}
                      </Text>
                    </View>
                  );
                })}
            </View>
          </>
        </ScrollView>
      </View>
      <View py={'$4'} width={340}>
        <FooterButtonsRow
          firstButton={{
            label: 'Back',
            handler: navigateBack,
            testID:
              'paychexpay.agreementsIntroView.footerButtonsRow.button.backButton',
          }}
          secondButton={{
            label: 'Let’s start',
            handler: onPressSave,
            testID:
              'paychexpay.agreementsIntroView.footerButtonsRow.button.next',
          }}
        />
      </View>
    </WebLogoTemplate>
  );
};

const AgreementsIntroView = {
  mobile,
  desktop,
};

export default AgreementsIntroView;
