import { createSlice } from '@reduxjs/toolkit';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { ICardholder } from '@store/features/cardholder/types';
import oowQuestionsThunks from '@store/features/oowQuestions/asynkThunks';

const initialState = {
  first_name: '',
  last_name: '',
  middle_name: '',
  email: '',
  phone_details: [],
  date_of_birth: '',
  status: '',
  addresses: [],
  cards: [],
  cardholder_id: '',
  kyc_status: '',
  external_ref_no: '',
  additional_data: {
    kyc_host_eval_token: '',
    'Paychex User Id': '',
    kyc_host_entity_token: '',
  },
  additional_info: {
    id: '',
    show_direct_deposit_prompt: true,
    address_changed_date: 0,
    allow_card_reissue: true,
    show_bill_pay_prompt: true,
    show_cash_back_prompt: true,
  },
  additional_info_is_loading: false,
  currentStatus: 'empty',
} as ICardholder;

const cardholderSlice = createSlice({
  name: 'cardholder',
  initialState,
  reducers: {
    accessTokenReset(state) {
      state.currentStatus = 'withNewAccessToken';
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      cardholderThunks.postCreateCardholder.fulfilled,
      (state, { payload }) => {
        if (payload.kyc_status === 'APPROVED') {
          state.currentStatus = 'justCreated';
        }
        if (payload.kyc_status === 'PENDING_OOW_QUESTIONS') {
          state.currentStatus = 'oowQuestions';
        }
        if (payload.kyc_status === 'DENIED') {
          state.currentStatus = 'denied';
        }
      }
    );
    builder.addCase(
      cardholderThunks.getCardholder.fulfilled,
      (state, { payload }) => {
        if (payload.kyc_status === 'APPROVED') {
          return {
            ...payload,
            currentStatus: 'fetchedFirstTime',
            additional_info: state.additional_info,
          };
        }
        if (payload.kyc_status === 'PENDING_OOW_QUESTIONS') {
          state.currentStatus = 'oowQuestions';
        }
        if (payload.kyc_status === 'DENIED') {
          state.currentStatus = 'denied';
        }
      }
    );
    builder.addCase(cardholderThunks.getCardholder.rejected, (state) => {
      state.currentStatus = 'notCreated';
    });
    builder.addCase(
      oowQuestionsThunks.postSubmitOwwQuestions.fulfilled,
      (state, { payload }) => {
        if (payload.kyc_status === 'APPROVED') {
          state.currentStatus = 'justCreated';
        }
        if (payload.kyc_status === 'PENDING_OOW_QUESTIONS') {
          state.currentStatus = 'oowQuestions';
        }
        if (payload.kyc_status === 'DENIED') {
          state.currentStatus = 'denied';
        }
      }
    );
    builder.addCase(
      cardholderThunks.updateCardholderInfo.fulfilled,
      (state, { payload }) => {
        return {
          ...payload,
          currentStatus: 'fetchedFirstTime',
          additional_info: state.additional_info,
        };
      }
    );
    builder.addCase(
      cardholderThunks.getCardholderAdditionalInfo.pending,
      (state) => {
        state.additional_info_is_loading = true;
      }
    );
    builder.addCase(
      cardholderThunks.getCardholderAdditionalInfo.rejected,
      (state) => {
        state.additional_info_is_loading = false;
      }
    );
    builder.addCase(
      cardholderThunks.getCardholderAdditionalInfo.fulfilled,
      (state, { payload }) => {
        state.additional_info = payload.content[0];
        state.additional_info_is_loading = false;
      }
    );
    builder.addCase(
      cardholderThunks.updateCardholderAdditionalInfo.fulfilled,
      (state, { payload }) => {
        state.additional_info = payload.content[0];
        state.additional_info_is_loading = false;
      }
    );
  },
});

export const { reducer: cardholderReducer, actions: cardholderActions } =
  cardholderSlice;
