import { Box } from 'native-base';
import { FooterMobileTemplate } from '@templates';
import { IconWithDescription } from '@molecules';

import WalletIcon from '../../../assets/walletIllustration.svg';
import NfcIcon from '../../../assets/nfc.svg';
import { FunctionComponent } from 'react';
import { SvgProps } from 'react-native-svg';
import { EXTERNAL_WALLETS } from '@constants/general';
import { View, XStack } from 'tamagui';
import { Text } from '@atoms';

interface AddedCardToWalletViewProps {
  continueHandler: () => void;
  walletName: string;
  WalletLogo: FunctionComponent<SvgProps>;
}

const AddedCardToWalletView = ({
  continueHandler,
  walletName,
  WalletLogo,
}: AddedCardToWalletViewProps) => {
  return (
    <FooterMobileTemplate.withOneButton
      buttonHandler={continueHandler}
      buttonLabel="Continue"
    >
      <Box safeAreaTop bg="white" flexGrow={1}>
        <View display="flex" paddingTop="$9" alignItems="center">
          <IconWithDescription
            Icon={WalletIcon}
            description="Use your device to pay for purchases in stores, online or in apps when you see"
            title={`The PaychexPay card added to ${walletName}`}
          />
        </View>
        <XStack justifyContent="center" alignItems="center" pt="5">
          <NfcIcon width={64} height={64} />
          <Text px="5" color="$gray40">
            or
          </Text>
          {walletName === EXTERNAL_WALLETS.SAMSUNG ? (
            <WalletLogo width={51} height={51} viewBox="0 0 112 112" />
          ) : (
            <WalletLogo width={64} height={64} viewBox="0 0 140 140" />
          )}
        </XStack>
      </Box>
    </FooterMobileTemplate.withOneButton>
  );
};

export default AddedCardToWalletView;
