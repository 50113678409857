import { HeaderMobileTemplate } from '@templates';
import { DebitCardContent, FreezeCardWebModal } from '@organisms';
import { debitCardMenuItem } from '@organisms/DebitCardContent/DebitCardContent.types';
import { debitCardStatesType } from '@devTypes/cards';
import { cardInfoType } from '@molecules/PaychexPayCard/PaychexPayCard.types';
import { Platform } from 'react-native';
import { DesktopAreaHeader, MobileWebHeader } from '@molecules';
import SideBarTemplate from '@templates/SideBarTemplate/SideBarTemplate';
import { View } from 'tamagui';
import React from 'react';
import { ICardAuthInfo, unfreezeParams } from '@store/features/cards/types';
import { Text } from '@atoms';
import { PAYCHEX_PAY_DEPOSIT_ACCOUNT } from '@constants/policies';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface DebitCardViewProps {
  navigateBack: () => void;
  menuItems: debitCardMenuItem[];
  state: debitCardStatesType;
  cardInfo: cardInfoType;
  cardImgSrc: string;
  cardAuthInfo: ICardAuthInfo;
  learnMoreHandler: () => void;
  isBlockAnimated: boolean;
  isFreezeModalOpen: boolean;
  setIsFreezeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  freezeCardHandler: () => void;
  unfreezeCardHandler: (params: { calledFrom: unfreezeParams }) => void;
}

const mobile = ({
  navigateBack,
  menuItems,
  state,
  cardInfo,
  cardAuthInfo,
  cardImgSrc,
  learnMoreHandler,
  isBlockAnimated,
  isFreezeModalOpen,
  setIsFreezeModalOpen,
  freezeCardHandler,
  unfreezeCardHandler,
}: DebitCardViewProps) => {
  const { bottom: bottomOffset } = useSafeAreaInsets();

  return (
    <View display={'flex'} flexDirection={'column'} height={'100%'}>
      {Platform.OS === 'web' && (
        <FreezeCardWebModal
          state={state}
          isFreezeModalOpen={isFreezeModalOpen}
          setIsFreezeModalOpen={setIsFreezeModalOpen}
          freezeCardHandler={freezeCardHandler}
          unfreezeCardHandler={unfreezeCardHandler}
        />
      )}

      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderMobileTemplate
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: 'Paychex Pay Debit Card',
          bg: '$blue10',
        }}
        isBlue
      >
        <DebitCardContent.mobile
          menuItems={menuItems}
          cardImgSrc={cardImgSrc}
          state={state}
          cardInfo={cardInfo}
          cardAuthInfo={cardAuthInfo}
          learnMoreHandler={learnMoreHandler}
          isBlockAnimated={isBlockAnimated}
        />
      </HeaderMobileTemplate>

      <View
        display={'flex'}
        flexDirection={'column'}
        zIndex={2}
        paddingHorizontal={16}
        paddingBottom={bottomOffset}
        backgroundColor="white"
        position={'absolute'}
        bottom={0}
        left={0}
        right={0}
      >
        <Text
          color="$gray50"
          fontSize={10}
          fontWeight={'500'}
          lineHeight={12}
          paddingVertical={12}
          paddingHorizontal={16}
          textAlign="center"
          accessible
          accessibilityLabel={PAYCHEX_PAY_DEPOSIT_ACCOUNT}
        >
          {PAYCHEX_PAY_DEPOSIT_ACCOUNT}
        </Text>
      </View>
    </View>
  );
};

const desktop = ({
  navigateBack,
  menuItems,
  state,
  cardInfo,
  cardAuthInfo,
  cardImgSrc,
  learnMoreHandler,
  isBlockAnimated,
  isFreezeModalOpen,
  setIsFreezeModalOpen,
  freezeCardHandler,
  unfreezeCardHandler,
}: DebitCardViewProps) => {
  return (
    <View display={'flex'} flexDirection={'column'} height={'100%'}>
      <FreezeCardWebModal
        state={state}
        isFreezeModalOpen={isFreezeModalOpen}
        setIsFreezeModalOpen={setIsFreezeModalOpen}
        freezeCardHandler={freezeCardHandler}
        unfreezeCardHandler={unfreezeCardHandler}
      />

      <SideBarTemplate>
        <View px={7}>
          <DesktopAreaHeader
            title="Paychex Pay Debit Card"
            backButtonHandler={navigateBack}
          />
        </View>
        <DebitCardContent.mobile
          menuItems={menuItems}
          state={state}
          cardInfo={cardInfo}
          cardImgSrc={cardImgSrc}
          cardAuthInfo={cardAuthInfo}
          learnMoreHandler={learnMoreHandler}
          isBlockAnimated={isBlockAnimated}
        />
      </SideBarTemplate>
    </View>
  );
};

const DebitCardView = {
  mobile,
  desktop,
};

export default DebitCardView;
