import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import notificationsThunks from '@store/features/notifications/asyncThunks';
import { INotificationsList } from '@store/features/notifications/types';

const initialState = {
  'paychexbank.card.pin.changed': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.bank_transfer.success': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.bank_transfer.return': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.card.status.unblocked': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.card.status.fraud_blocked': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.card.status.closed': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.card.status.blocked': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.trans.auth.failed': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.trans.large_atm_withdrawal': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.trans.large_purchase': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.trans.low_balance': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
  'paychexbank.reward.payout_completed': {
    mobilePushPaychexPay: false,
    email: false,
    text: false,
  },
} as INotificationsList;

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    localUpdate(state, action: PayloadAction<INotificationsList>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      notificationsThunks.getNotifications.fulfilled,
      (state, { payload }) => {
        return payload;
      }
    );
  },
});

export const { reducer: notificationsReducer, actions: notificationsActions } =
  notificationsSlice;
