import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import { generateDdaRequestType } from '@store/features/ddaForm/types';
import { application, operations } from '@constants/traceability';

const ddaFormAPI = {
  postGenerateDda(body: generateDdaRequestType) {
    return wrappedAxiosRequest<generateDdaRequestType, any>({
      method: 'post',
      url: endpoints.generateDda,
      data: body,
      responseType: 'arraybuffer',
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GenerateDdaForm,
        'x-payx-comp': application.compPrefix + operations.GenerateDdaForm,
      },
    });
  },
};

export default ddaFormAPI;
