import { HeaderMobileTemplate } from '@templates';
import { ScrollView, View, YStack } from 'tamagui';
import { DebitCardMenuItem, MobileWebHeader } from '@molecules';
import { TransfersMenuItem } from '@hooks/useTransfers';
import { TransactionsFilter, TransactionsList } from '@organisms';
import { Dimensions, KeyboardAvoidingView, Platform } from 'react-native';
import React, { useRef, useState } from 'react';
import {
  ITransaction,
  TransactionType,
} from '@store/features/transactions/types';
import { TransfersOptionsType } from '@organisms/TransactionsFilter/TransactionsFilter';
import { Text } from '@atoms';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { selectDateRange } from '@store/features/transactions/selectors';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { MOBILE_HEADER_HEIGHT } from '@constants/general';

interface TransfersViewMobileProps {
  isLoadingSpending: boolean;
  navigateBack: () => void;
  menuItems: TransfersMenuItem[];
  onPressTransaction: (id: string) => void;
  transactions: ITransaction[];
  isSelectOpen: boolean;
  selectTransfersOption: (option: TransfersOptionsType | string) => void;
  transfersSelect: TransfersOptionsType;
  transactionsSearchText: string;
  transactionsFilteredBy: TransactionType;
  isLoadingTransactions: boolean;
  onRefreshTransactionsList?: () => void;
  isRefreshLoading?: boolean;
}

interface TransferViewHeaderProps {
  children: React.ReactNode;
  navigateBack: () => void;
}

const TransferViewHeader = ({
  children,
  navigateBack,
}: TransferViewHeaderProps) =>
  Platform.OS === 'web' ? (
    <>
      <MobileWebHeader />
      <HeaderMobileTemplate
        header={{
          title: 'Transfer money to',
          isBackButton: true,
          onPressBack: navigateBack,
        }}
      >
        {children}
      </HeaderMobileTemplate>
    </>
  ) : (
    <HeaderMobileTemplate
      header={{
        title: 'Transfer money to',
        isBackButton: true,
        onPressBack: navigateBack,
      }}
    >
      {children}
    </HeaderMobileTemplate>
  );

const mobile = ({
  navigateBack,
  menuItems,
  onPressTransaction,
  transactions,
  selectTransfersOption,
  transactionsSearchText,
  transactionsFilteredBy,
  isLoadingTransactions,
  isRefreshLoading,
  onRefreshTransactionsList,
}: TransfersViewMobileProps) => {
  const filterDateRange = useSelector(selectDateRange);
  const screenHeight = Dimensions.get('screen').height;
  const scrollWrapperRef = useRef(null);
  const [scrollWrapperHeight, setScrollWrapperHeight] = useState(0);
  const { bottom: bottomInset, top: topInset } = useSafeAreaInsets();
  const tabBarHeight = useBottomTabBarHeight();

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{
        height: '100%',
        borderWidth: 1,
      }}
    >
      <TransferViewHeader navigateBack={navigateBack}>
        <YStack
          px="$4"
          height={
            screenHeight -
            bottomInset -
            topInset -
            tabBarHeight -
            MOBILE_HEADER_HEIGHT
          }
        >
          <YStack pb="$5">
            {menuItems.map((item, index) => {
              if (item.isDisabled) return null;
              return (
                <DebitCardMenuItem
                  key={index}
                  handler={item.handler}
                  title={item.title}
                />
              );
            })}
          </YStack>
          <TransactionsFilter.mobile
            filterType={'transfers'}
            setTransferFilterOption={selectTransfersOption}
          />
          <View
            ref={scrollWrapperRef}
            flex={1}
            height={Platform.OS === 'web' ? scrollWrapperHeight - 40 : 'auto'}
            // @ts-ignore
            onLayout={() => {
              // @ts-ignore
              scrollWrapperRef.current?.measureInWindow((x, y) => {
                const calculatedHeight = screenHeight - y;
                setScrollWrapperHeight(calculatedHeight);
              });
            }}
          >
            {Platform.OS === 'web' ? (
              <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                  flexGrow: 1,
                  paddingBottom: 10,
                }}
                style={{
                  height: '100%',
                }}
              >
                <View flexGrow={1}>
                  <View
                    flexGrow={1}
                    flexDirection={'column'}
                    display={'flex'}
                    height={'100%'}
                  >
                    <TransactionsList
                      transactions={transactions}
                      onPress={onPressTransaction}
                      withHighlight={true}
                      transactionsSearchText={transactionsSearchText}
                      transactionsFilteredBy={transactionsFilteredBy}
                      isLoading={isLoadingTransactions}
                    />
                  </View>
                </View>
              </ScrollView>
            ) : (
              <View flexGrow={1}>
                <View
                  flexGrow={1}
                  flexDirection={'column'}
                  display={'flex'}
                  height={'100%'}
                >
                  <TransactionsList
                    transactions={transactions}
                    onPress={onPressTransaction}
                    withHighlight={true}
                    transactionsSearchText={transactionsSearchText}
                    transactionsFilteredBy={transactionsFilteredBy}
                    isLoading={isLoadingTransactions}
                    isRefreshLoading={isRefreshLoading}
                    onRefreshTransactionsList={onRefreshTransactionsList}
                  />
                </View>
              </View>
            )}
          </View>
          <YStack
            flex={0}
            backgroundColor={'white'}
            paddingHorizontal={16}
            paddingTop={2}
            marginBottom={Platform.OS === 'android' ? 5 : 10}
          >
            <View
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
            >
              {filterDateRange?.date_from ? (
                <Text
                  variant={'semiTitle'}
                  fontWeight={'400'}
                  fontSize={14}
                  paddingVertical={10}
                >
                  {filterDateRange?.date_from &&
                    format(
                      new Date(filterDateRange.date_from),
                      'MMM dd, yyyy'
                    )}{' '}
                  {filterDateRange?.date_to &&
                    filterDateRange.date_from !== filterDateRange.date_to &&
                    format(
                      new Date(filterDateRange.date_to),
                      ' - MMM dd, yyyy'
                    )}
                </Text>
              ) : (
                <>
                  {transactions.length > 0 && (
                    <Text
                      variant={'caption'}
                      color={'$gray60'}
                      fontSize={12}
                      paddingTop={2}
                    >
                      Showing last 90 days
                    </Text>
                  )}
                </>
              )}
            </View>
          </YStack>
        </YStack>
      </TransferViewHeader>
    </KeyboardAvoidingView>
  );
};

const TransfersView = {
  mobile,
};

export default TransfersView;
