import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { View, XStack, YStack } from 'tamagui';
import { Line, TButton, Text } from '@atoms';
import { accessibleHitSlop } from '@constants/ui';
import ArrowLeft from '../../../assets/arrowLeft.svg';
import ArrowRight from '../../../assets/arrowRight.svg';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import './CalendarDatePickerWeb.css';
import { customTheme } from '../../../styles/customTheme';

export interface CalendarDatePickerProps {
  setDateRange: (dateRange: {
    date_from: string | null;
    date_to: string | null;
  }) => void;
  toCloseSelectDate: () => void;
  toClearCalendar: boolean;
  setToClearCalendar: (clearCalendar: boolean) => void;
  dateRange: {
    date_from: string | null;
    date_to: string | null;
  };
  isClearPressed: boolean;
  setIsClearPressed: (clearPressed: boolean) => void;
}
const MAX_RANGE_NUMBER = 90;
const pastMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

const CalendarDatePicker = ({
  setDateRange,
  toCloseSelectDate,
  toClearCalendar,
  setToClearCalendar,
  dateRange,
  isClearPressed,
  setIsClearPressed,
}: CalendarDatePickerProps) => {
  const defaultSelected: DateRange = {
    from: dateRange?.date_from ? new Date(dateRange.date_from) : undefined,
    to: dateRange?.date_to ? new Date(dateRange.date_to) : undefined,
  };
  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

  const onCloseCalendar = () => {
    if (isClearPressed) {
      setIsClearPressed(false);
    }
    toCloseSelectDate();
  };

  const onClearCalendarHandler = () => {
    if (dateRange.date_from || dateRange.date_to) {
      // checking if date range was selected AND applied for filtering before clear button pressed
      setIsClearPressed(true);
    }
    onClearCalendar();
  };

  const onClearCalendar = () => {
    setRange({ from: undefined, to: undefined });
    setDateRange({
      date_from: null,
      date_to: null,
    });
  };

  useEffect(() => {
    if (toClearCalendar) {
      onClearCalendar();
      setToClearCalendar(false);
    }
  }, [toClearCalendar]);

  const onPressViewActivity = () => {
    const from = range?.from ? format(range.from, 'yyyy-MM-dd') : '';
    const to = range?.to ? format(range.to, 'yyyy-MM-dd') : from;

    setDateRange({
      date_from: from,
      date_to: to,
    });

    setIsClearPressed(false);
    onCloseCalendar();
  };

  const [calendarContainerWidth, setCalendarContainerWidth] = useState(0);

  return (
    <YStack
      // @ts-ignore
      onLayout={(event) => {
        setCalendarContainerWidth(event.nativeEvent.layout.width);
      }}
    >
      <View flexDirection={'row'} display={'flex'} justifyContent={'center'}>
        <View>
          <DayPicker
            numberOfMonths={calendarContainerWidth < 625 ? 1 : 2}
            defaultMonth={pastMonth}
            mode="range"
            max={MAX_RANGE_NUMBER}
            toDate={new Date()}
            selected={range}
            onSelect={setRange}
            components={{
              IconLeft: () => (
                <ArrowLeft fill={customTheme.colors.primary.normal} />
              ),
              IconRight: () => (
                <ArrowRight fill={customTheme.colors.primary.normal} />
              ),
            }}
            styles={{
              caption: {
                fontFamily: 'Inter-SemiBold',
                fontSize: 16,
                color: customTheme.colors.black,
              },
              day: {
                fontFamily: 'Inter',
                fontSize: 14,
                fontWeight: '500',
              },
              head: {
                fontFamily: 'Inter',
                fontSize: 14,
                fontWeight: '400',
                color: customTheme.colors.gray[30],
              },
            }}
          />
        </View>
      </View>

      <Line />
      <YStack
        backgroundColor={'white'}
        paddingHorizontal={16}
        paddingBottom={20}
      >
        <View
          flex={1}
          justifyContent={'center'}
          alignContent={'center'}
          alignItems={'center'}
          display={'flex'}
          flexDirection={'column'}
        >
          <Text
            variant={'semiTitle'}
            fontWeight={'400'}
            fontSize={14}
            paddingVertical={10}
          >
            {range?.from && format(range.from, 'MMM dd, yyyy')}{' '}
            {range?.from &&
              range?.to &&
              format(range.to, 'MMM dd, yyyy') !==
                format(range.from, 'MMM dd, yyyy') &&
              format(range.to, ' - MMM dd, yyyy')}
          </Text>
          <Text
            variant={'caption'}
            color={'$gray60'}
            fontSize={12}
            paddingVertical={10}
          >
            Select up to 90 days
          </Text>
        </View>

        <XStack flex={1}>
          <TButton
            flex={1}
            marginTop={5}
            variant="white"
            marginRight={10}
            onPress={onClearCalendarHandler}
            testID="paychexpay.calendarDatePicker.button.clearButton"
            hitSlop={accessibleHitSlop}
            accessible
            accessibilityLabel="Clear selection"
            disabled={!range?.from}
          >
            Clear
          </TButton>
          <TButton
            flex={1}
            marginTop={5}
            marginLeft={10}
            variant="primary"
            onPress={onPressViewActivity}
            testID="paychexpay.calendarDatePicker.button.viewActivity"
            hitSlop={accessibleHitSlop}
            accessible
            accessibilityLabel="View activity"
            disabled={!range?.from}
          >
            View activity
          </TButton>
        </XStack>
      </YStack>
    </YStack>
  );
};

export default CalendarDatePicker;
