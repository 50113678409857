import { TransferStatusView } from '@views/Transfers';
import { useEffect, useMemo } from 'react';
import { transfersScreensNames } from '@constants/transfersScreensNames';
import { TransferStatusProps } from '@navigation/RootNavigator/RootNavigator.types';
import { transfersActions } from '@store/features/transfers/slice';
import { cardsActions } from '@store/features/cards/slice';
import { useAppDispatch } from '@store/hooks';
import { errorStatesActions } from '@store/features/errorStates/slice';

const TransferStatusPage = ({ navigation, route }: TransferStatusProps) => {
  const navigateHome = () => {
    dispatch(errorStatesActions.resetError());
    navigation.reset({
      index: 0,
      routes: [{ name: 'BottomTabsNavigator' }],
    });
  };
  const transferType = useMemo(() => {
    switch (route.params.transferType) {
      case 'BetweenPaychexPay':
        return transfersScreensNames.betweenPaychexPay;
      case 'ToExternalBank':
        return transfersScreensNames.externalAccount;
      case 'ToOtherFriends':
        return transfersScreensNames.toOtherFriends;
      default:
        return transfersScreensNames.betweenPaychexPay;
    }
  }, [route]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(transfersActions.resetStatus());
    dispatch(cardsActions.setRequestSpendingRequestStatus('pending'));
    dispatch(cardsActions.setRequestSavingsRequestStatus('pending'));
  }, []);

  return (
    <TransferStatusView
      navigateHome={navigateHome}
      transferType={transferType}
    />
  );
};

export default TransferStatusPage;
