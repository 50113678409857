const linking = {
  prefixes: [
    'http://localhost:3000',
    'https://d2c873spgbdihq.cloudfront.net',
    '{{bank.paychexbank.public.url}}',
  ],
  config: {
    screens: {
      AuthNavigator: {
        screens: {
          SignInPage: 'signin',
          PaychexLoginPage: 'login',
          FillInDataFirst: 'signup/start-basic-info',
          FillInDataSecond: 'signup/complete-basic-info',
          AgreementsPage: 'signup/policy',
          AboutPFAccount: 'about-paychex-pay-account',
          AboutSsnPage: 'signup/ssn',
          AboutDobPage: 'signup/dob',
          BiometricsPage: 'biometrics-permission ',
          AddressVerificationPage: 'signup/verify-address',
          UnableToSignupPage: 'unable-to-signup',
        },
      },
      CIPNavigator: {
        screens: {
          CIPMessagePage: 'verify-identity',
          OutWalletQuestionsPage: 'verify-identity/oowq',
          VerificationFailedPage: 'verify-identity-error',
        },
      },
      OnboardingNavigator: {
        screens: {
          OnboardingPage: 'signup/onboarding',
          WelcomeToPaychexPayPage: 'signup/account-open',
          HowToUseCardPage: 'signup/account-open/how-to-digital-card',
          AllowNotificationsPage: 'allow-notifications',
          CreateCardholderLoaderPage: 'cardholder-is-creating',
        },
      },
      BottomTabsNavigator: {
        screens: {
          HomePage: 'home',
          MobileDeposit: 'home/mobile-deposit',
          More: 'more',
        },
      },
      HomeNavigator: {
        screens: {
          SpendingAccount: 'spending',
          SavingAccount: 'savings',
          TransactionDetails: 'transaction-details',
          DebitCard: 'virtual-card',
          SetupDda: 'setup-direct-deposit',
          DdaForm: 'setup-direct-deposit/direct-deposit-form',
          DisputeCharge: 'dispute-charge',
          BlockedCard: 'virtual-card/blocked-card',
          Transfers: 'transfers',
          CardLost: 'virtual-card/card-lost',
          Support: 'support',
          CashBack: 'home/cashback',
          BetweenPaychexPay: 'transfers/between-my-paychex-pay-accounts',
          TransferAmount: 'transfers/amount',
          ToExternalBank: 'transfers/external-bank-accounts',
          SelectAccount: 'transfers/external-bank-accounts/select-account',
          ToOtherFriends: 'transfers/to-other-friends',
          TransferStatus: 'transfers/status',
          StatementsList: 'statements-list',
          SavingsAgreements: 'savings-account/agreements',
          ChangePin: 'spending/virtual-card/change-pin',
          PayBills: 'account/pay-bills',
          ConnectAccount: '/transfers/external-bank-accounts/connect',
          BetweenPaychexPayWeb: 'transfers/between-my-paychex-pay-accounts-web',
          ToOtherFriendsWeb: 'transfers/to-other-friends-web',
          ToExternalBankWeb: 'transfers/external-bank-accounts-web',
          TransfersHistory: 'transfers/transfers-history',
        },
      },
      MoreNavigator: {
        screens: {
          Atm: 'more/atm-reload-locators',
          DebitCard: 'more/debit-card',
          ProfileSetting: 'more/profile-settings',
          Notifications: 'more/notifications',
          Help: 'more/help',
          Legal: 'more/legal',
          LogOut: 'log-out',
          EditPhone: 'more/profile/edit-phone',
          EditEmail: 'more/profile/edit-email',
          EditAddress: 'more/profile/edit-address',
          Faq: 'help/faq',
          LegalAgreements: 'more/legal-agreements',
          MastercardFeatures: 'more/mastercard-features',
          IdentityTheft: 'more/master-card-features/identity-theft',
          ZeroLiability: 'more/master-card-features/zero-liability',
          AirportConcierge: 'more/master-card-features/airport-concierge',
          OrderNewCardRequest: 'account-spending/virtual-card/new-card',
          OrderNewCardStatus: 'account-spending/virtual-card/new-card-status',
          SendFeedback: 'send-feedback',
        },
      },
      PaychexLoginPageSkipLanding: 'login/skip-landing',
      ErrorPage: 'error-page',
    },
  },
};

export default linking;
