import { View, ScrollView } from 'tamagui';
import { ImageWithContent } from '@molecules';
import { callPhone } from '@utils/communicationServices';
import { Platform, useWindowDimensions } from 'react-native';
import React, { ReactElement } from 'react';
import { Line, Text } from '@atoms';
import BackButton from '@atoms/BackButton/BackButton';
import useIsTablet from '@hooks/useIsTablet';
import {
  MOBILE_HEADER_HEIGHT,
  MOBILE_WEB_HEADER_HEIGHT,
} from '@constants/general';

interface SupportScreenContentProps {
  headerTitle: string;
  title: string;
  text: string;
  icon: ReactElement;
  supportInfo: {
    phone: string;
    email: string;
    mailingAddress: string;
  };
  onPressBack?: () => void;
  isSmallSpaces?: boolean;
  showHeader?: boolean;
}

const SupportScreenContent = ({
  headerTitle,
  title,
  text,
  icon,
  supportInfo,
  onPressBack,
  isSmallSpaces = false,
  showHeader = true,
}: SupportScreenContentProps) => {
  const isTablet = useIsTablet();
  const { height: screenHeight } = useWindowDimensions();

  return (
    <View
      accessible={false}
      accessibilityLabel="Support Content"
      flex={1}
      {...(isTablet && {
        height: screenHeight - MOBILE_WEB_HEADER_HEIGHT - MOBILE_HEADER_HEIGHT,
      })}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          paddingBottom: 6,
        }}
      >
        {!isTablet && !isSmallSpaces && (
          <View position="relative">
            {onPressBack && (
              <BackButton
                onPress={onPressBack}
                args={{
                  position: 'absolute',
                  left: '$7',
                  top: '$6',
                  zIndex: 1,
                }}
              />
            )}
            {showHeader && (
              <>
                <View
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  h="88px"
                >
                  <Text
                    variant="title"
                    testID={`paychexpay.supportScreenContent.${headerTitle
                      .toLowerCase()
                      .split(' ')
                      .join('')}Title`}
                  >
                    {headerTitle}
                  </Text>
                </View>
                <Line bg="$gray20" />
              </>
            )}
          </View>
        )}
        {isSmallSpaces && (
          <Text
            variant="title"
            pt={20}
            textAlign="center"
            testID={`paychexpay.supportScreenContent.${headerTitle
              .toLowerCase()
              .split(' ')
              .join('')}Title`}
          >
            {headerTitle}
          </Text>
        )}
        <View
          pt={'$7'}
          pb={isSmallSpaces ? 20 : 0}
          px={isTablet ? '$4' : isSmallSpaces ? 20 : '$7'}
        >
          <ImageWithContent image={icon} space={'$7'}>
            <View width={'100%'}>
              <Text variant="title" pb={'$2'}>
                {title}
              </Text>
              <Text variant="subtitle" pb={'$6'}>
                {text}
              </Text>
              <Text fontSize="$h4" fontWeight="$medium" pb={'$4'}>
                How to reach us:
              </Text>
              <View backgroundColor="$blue10" borderRadius={8} p={'$4'}>
                <View
                  onPress={() => {
                    callPhone(supportInfo.phone);
                  }}
                  accessible
                  accessibilityLabel={`Phone: ${supportInfo.phone} (toll free in US)`}
                >
                  <Text variant="subtitle" pb={'$1'}>
                    Phone
                  </Text>
                  <Text variant="regular" color={'$primaryNormal'} pb={'$4'}>
                    {supportInfo.phone}{' '}
                    <Text
                      variant="regular"
                      display={Platform.OS === 'web' ? 'inline' : 'flex'}
                    >
                      (toll free in US)
                    </Text>
                  </Text>
                </View>
                <View
                  accessible
                  accessibilityLabel={`Mailing address: ${supportInfo.mailingAddress}`}
                >
                  <Text variant="subtitle" pb={'$1'}>
                    Mailing address
                  </Text>
                  <Text variant="regular" whiteSpace={'pre-line'}>
                    {supportInfo.mailingAddress}
                  </Text>
                </View>
              </View>
            </View>
          </ImageWithContent>
        </View>
      </ScrollView>
    </View>
  );
};

export default SupportScreenContent;
