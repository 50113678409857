import { Platform } from 'react-native';

const EXTERNAL_WALLETS = {
  APPLE: 'Apple Pay',
  GOOGLE: 'Google Pay',
  SAMSUNG: 'Samsung Pay',
};

const PHONE_MANUFACTURER = {
  SAMSUNG: 'samsung',
};

const TOAST_POPUP_VISIBILITY_TIME = 3000;
const DEFAULT_SCREEN_HEIGHT = 812;

const REFRESH_KEY = 'REFRESH_KEY';

const MOBILE_WEB_HEADER_HEIGHT = Platform.OS === 'web' ? 48 : 0; //px

const MOBILE_HEADER_HEIGHT = Platform.OS === 'web' ? 57 : 39; //px

const MAX_TRANSFER_COMMENT_LENGTH = 80;

const MAX_TRANSFER_SEARCH_LENGTH = 35;

export {
  EXTERNAL_WALLETS,
  PHONE_MANUFACTURER,
  TOAST_POPUP_VISIBILITY_TIME,
  DEFAULT_SCREEN_HEIGHT,
  REFRESH_KEY,
  MOBILE_WEB_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
  MAX_TRANSFER_COMMENT_LENGTH,
  MAX_TRANSFER_SEARCH_LENGTH,
};
