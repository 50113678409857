import homeWidgetsApi from './api';
import {
  IBillPayRequestBody,
  IBillPayResponse,
  IDdaEligibilityResponse,
  IIngoDataRequestBody,
  IIngoDataResponse,
} from './types';
import { createTypedThunk } from '@utils/createTypedThunk';

const homeWidgetsThunks = {
  billPay: createTypedThunk<IBillPayRequestBody, IBillPayResponse>(
    'homeWidgets/billPay',
    homeWidgetsApi.billPay
  ),
  ddaEligibility: createTypedThunk<undefined, IDdaEligibilityResponse>(
    'homeWidgets/ddaEligibility',
    homeWidgetsApi.ddaEligibility
  ),
  getIngoData: createTypedThunk<IIngoDataRequestBody, IIngoDataResponse>(
    'homeWidgets/getIngoData',
    homeWidgetsApi.getIngoData
  ),
};

export default homeWidgetsThunks;
