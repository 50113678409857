import { createSlice } from '@reduxjs/toolkit';
import { IDdaFormState } from '@store/features/ddaForm/types';
import ddaFormThunks from '@store/features/ddaForm/asyncThunks';

const initialState = {
  pdfString: null,
  isPdfReady: false,
} as IDdaFormState;

const ddaFormSlice = createSlice({
  name: 'ddaForm',
  initialState,
  reducers: {
    resetStatus(state) {
      state.isPdfReady = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      ddaFormThunks.postGenerateDda.fulfilled,
      (state, { payload }) => {
        state.pdfString = payload;
        state.isPdfReady = true;
      }
    );
  },
});

export const { reducer: ddaFormReducer, actions: ddaFormActions } =
  ddaFormSlice;
