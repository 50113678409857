export const setupDdaAccount = {
  pageTitle: 'Getting started',
  title: 'Ready to set up direct deposit?',
  modalText:
    'For you to be paid early, your payroll or benefits payment provider must submit the deposit early. Your payment provider may not submit the deposit early each payment period, so ask when they submit your deposit information to the bank for processing.\n' +
    '\n' +
    'Early funds deposit begins on the 2nd qualifying deposit, which is defined as a direct deposit greater than $5.00 received from the same payer.',
  text: 'Fill out the template if your employer needs a form. Or share this info with them directly.',
  hint: 'Need a voided check? Use the form below. It contains the same info.',
  buttonText: 'Download form',
};

export const ddaForm = {
  pageTitle: 'Direct Deposit form',
  text:
    'I wish to have my funds deposited into my Paychex Pay Account. I authorize you (my payer) to initiate electronic entries and, if necessary, debit entries and adjustments for any credit entries in error to my account. This authority will remain in effect until I notify my payer in writing or as otherwise specified by my payer.\n' +
    '\n' +
    'I agree that only direct deposits in my name or the name of someone for whom I have the legal authority to accept funds will be deposited to my account. All other direct deposits in the name of anyone else may be declined and returned. If Central Bank of Kansas City discovers that a direct deposit in the name of someone else was successfully deposited to my account, Central Bank of Kansas City may deduct the amount of the deposit and return it. I agree that I should check with the payer to determine the effective date for any direct deposit they send to my account.',
  firstButtonLabel: 'Print form',
  secondButtonLabel: 'Send Email',
};
