/* eslint-disable no-constant-condition */
import React from 'react';
import { PaychexPayCardProps } from '@molecules/PaychexPayCard/PaychexPayCard.types';
import CardLogo from '../../../assets/CardLogo.svg';
import MasterCardIcon from '../../../assets/masterCard.svg';
import { addSpacesToCardNumber } from '@utils/transformers';
import useIsPhone from '@hooks/useIsPhone';
import { Loader, Text } from '@atoms';
import { View, XStack, YStack } from 'tamagui';

const PaychexPayCard = ({
  cardInfo,
  cardImgSrc,
  hideNumbers = false,
}: PaychexPayCardProps) => {
  const { month, year } = cardInfo.expDate;
  const isPhone = useIsPhone();
  const [imageLoadError, setImageLoadError] = React.useState(false);

  return (
    <View
      bg="$primaryDark"
      p={cardImgSrc && !imageLoadError ? 0 : 20}
      width={343}
      height={202}
      borderRadius={14}
      overflow={'hidden'}
      style={{
        shadowColor: 'rgba(46, 66, 102, 1)',
        shadowOffset: {
          width: 0,
          height: 12,
        },
        shadowRadius: !isPhone ? 30 : 10,
        elevation: 25,
        shadowOpacity: 0.24,
      }}
      accessible
      accessibilityLabel={'Paychex card image'}
      testID={'paychexpay.paychexPayCard'}
    >
      {!isPhone && (
        <View
          justifyContent={'center'}
          alignItems={'center'}
          position={'absolute'}
          top={10}
          bottom={10}
          left={10}
          right={10}
        >
          <Loader variant={'white'} />
        </View>
      )}
      {cardImgSrc && !imageLoadError ? (
        <View>
          <img
            alt={'Paychex card image'}
            height={202}
            src={cardImgSrc} // use normal <img> attributes as props
            width={343}
            onError={() => {
              setImageLoadError(true);
            }}
            onLoad={() => {
              setImageLoadError(false);
            }}
          />
        </View>
      ) : (
        <YStack bg="$primaryDark">
          <View>
            <CardLogo />
          </View>
          <XStack pt="35px" justifyContent={'flex-end'}>
            <Text color="white" fontSize={12}>
              CVV
            </Text>
            <Text color="white" fontSize={12} marginLeft={'$4'}>
              ***
            </Text>
          </XStack>
          <Text color="white" fontSize={20}>
            {addSpacesToCardNumber(cardInfo.number)}
          </Text>
          <XStack pt={'3px'} ml={'35%'}>
            <YStack>
              <Text color="white" fontSize={9} lineHeight={7} paddingTop={3}>
                GOOD
              </Text>
              <Text color="white" fontSize={9} lineHeight={7} paddingTop={3}>
                THRU
              </Text>
            </YStack>

            <XStack justifyContent="space-between" flex={1}>
              <Text color="white" fontSize="$h4" marginLeft={6}>
                {hideNumbers ? '**' : month}/{hideNumbers ? '**' : year}
              </Text>
              <Text color="white" fontSize="$h4" marginLeft={6}>
                debit
              </Text>
            </XStack>
          </XStack>
          <XStack justifyContent="space-between" pt="4" alignItems="center">
            <Text
              flex={6}
              color="white"
              fontSize={16}
              fontWeight={'400'}
              lineHeight={22}
              ellipsizeMode={'tail'}
              numberOfLines={1}
            >
              {cardInfo.name.toUpperCase()}
            </Text>
            <View flex={1} display="flex" alignItems="flex-end">
              <MasterCardIcon />
            </View>
          </XStack>
        </YStack>
      )}
    </View>
  );
};

export default React.memo(PaychexPayCard);
