const routesToTitlesMap = {
  SplashScreen: 'Paychex Pay - Paychex Pay',
  SignInPage: 'Paychex Pay - Sign in',
  PaychexLoginPage: 'Paychex Pay - Sign in',
  PaychexLoginPageSkipLanding: 'Paychex Pay - Sign in',
  UsaPatriotActPage: 'Paychex Pay - USA Patriot Act',
  // eslint-disable-next-line
  FillInDataFirst: "Paychex Pay - Let's start with your basic info",
  FillInDataSecond: 'Paychex Pay - Complete your basic info',
  AgreementsIntro: 'Paychex Pay - Terms and conditions',
  AgreementsPage: 'Paychex Pay - Terms and conditions',
  AboutPFAccount: 'Paychex Pay - Sign in to Paychex Flex',
  AboutSsnPage: 'Paychex Pay - SSN info',
  AboutDobPage: 'Paychex Pay - DOB info',
  BiometricsPage: 'Paychex Pay - Allow Biometrics',
  AddressVerificationPage: 'Paychex Pay - Confirm your address',
  UnableToSignupPage: 'Paychex Pay - Unable to signup',

  CIPMessagePage: 'Paychex Pay - Verify Identity',
  OutWalletQuestionsPage:
    'Paychex Pay - Verify Identity - Out Of Wallet Questions',
  VerificationFailedPage: 'Paychex Pay - Verify Identity - Unable to verify',

  OnboardingPage: 'Paychex Pay - Welcome to Paychex Pay Account',
  SetupDdaOnboardingPage: 'Paychex Pay - Setup Direct Deposit Account',
  WelcomeToPaychexPayPage: 'Paychex Pay - Congratulations',
  HowToUseCardPage: 'Paychex Pay - How to use digital card',
  AllowNotificationsPage: 'Paychex Pay - Stay in the loop',
  CreateCardholderLoaderPage: 'Paychex Pay - Creating your account',

  HomePage: 'Paychex Pay - Home',
  MobileDeposit: 'Paychex Pay - Home - Direct Deposit',
  More: 'Paychex Pay - More',

  SpendingAccount: 'Paychex Pay - Spending Account',
  SavingAccount: 'Paychex Pay - Savings Account',
  TransactionDetails: 'Paychex Pay - Transaction Details',
  DebitCard: 'Paychex Pay - Debit Card',
  SetupDda: 'Paychex Pay - Setup Direct Deposit Account',
  DdaForm: 'Paychex Pay - Setup Direct Deposit Account - Direct Deposit form',
  DisputeCharge: 'Paychex Pay - Dispute a Transaction',
  BlockedCard: 'Paychex Pay - Debit Card Blocked',
  Transfers: 'Paychex Pay - Transfer money',
  CardLost: 'Paychex Pay - Report Lost',
  Support: 'Paychex Pay - Contact Customer Service',
  CashBackIntro: 'Paychex Pay - Get paid to spend',
  CashBack: 'Paychex Pay - Cashback',
  DigitalWalletPage: 'Paychex Pay - Open digital wallet',
  BetweenPaychexPay: 'Paychex Pay - Transfers - My Paychex Pay accounts',
  TransferAmount: 'Paychex Pay - Transfers - Amount',
  ToExternalBank: 'Paychex Pay - Transfers - Transfer by account number',
  SelectAccount: 'Paychex Pay - Transfers - Select account',
  ToOtherFriends: 'Paychex Pay - Transfers - Transfer by phone/email',
  TransferStatus: 'Paychex Pay - Transfers - Status',
  StatementsList: 'Paychex Pay - Statements',
  OpenSavingsAccount: 'Paychex Pay - Open Savings Account',
  SavingsAgreements: 'Paychex Pay - Savings Account Agreement and Disclosures',
  ChangePin: 'Paychex Pay - Change PIN',
  PayBills: 'Paychex Pay - Pay bills',
  ConnectAccount: 'Paychex Pay - Add Account',
  DeleteAccount: 'Paychex Pay - Remove Account',
  BetweenPaychexPayWeb: 'Paychex Pay - Transfers - My Paychex Pay accounts',
  ToOtherFriendsWeb: 'Paychex Pay - Transfers - Transfer by phone/email',
  ToExternalBankWeb: 'Paychex Pay - Transfers - Transfer by account number',
  TransfersHistory: 'Paychex Pay - Transfers - Transfers History',

  Atm: 'Paychex Pay - ATM finder',
  ProfileSetting: 'Paychex Pay - Profile Settings',
  Notifications: 'Paychex Pay - Notifications',
  Help: 'Paychex Pay - Help',
  Legal: 'Paychex Pay - Document Center',
  LogOut: 'Paychex Pay - Log Out',
  EditPhone: 'Paychex Pay - Profile Settings - Mobile Phone',
  EditEmail: 'Paychex Pay - Profile Settings - Personal Email',
  EditAddress: 'Paychex Pay - Profile Settings - Address',
  Faq: 'Paychex Pay - Help - FAQs',
  GetStartedWizard: 'Paychex Pay - Help - Replay welcome intro',
  CustomerService: 'Paychex Pay - Contact Customer Service',
  LegalAgreements: 'Paychex Pay - Document Center',
  FeeSchedule: 'Paychex Pay - Deposit Account Fee Schedule',
  MastercardFeatures: 'Paychex Pay - Mastercard Features',
  IdentityTheft:
    'Paychex Pay - Mastercard Features - Mastercard ID Theft Protection™',
  ZeroLiability: 'Paychex Pay - Mastercard Features - Zero Liability',
  AirportConcierge:
    'Paychex Pay - Mastercard Features - Mastercard Airport Concierge™',
  OrderNewCardRequest: 'Paychex Pay - Order a new card',
  OrderNewCardStatus: 'Paychex Pay - Order a new card',
  SendFeedback: 'Paychex Pay - Help - Give feedback',

  ErrorPage: 'Paychex Pay - Error',

  unknown: 'Paychex Pay',
};

export default routesToTitlesMap;
