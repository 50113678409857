import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { customTheme } from '../../../styles/customTheme';
import React from 'react';
import { Pressable, Text } from '@atoms';
import HelpIcon from '../../../assets/helpIconWhite.svg';
import {
  AccountsWidgetSkeleton,
  TileSkeleton,
  TransactionHistorySkeleton,
} from '@molecules';
import { COLUMN_WIDTH, WidgetWrapper } from '@views/BottomTabs/HomeView';
import useIsPhone from '@hooks/useIsPhone';
import { View, XStack, YStack } from 'tamagui';

const HomePageSkeleton = () => {
  const isPhone = useIsPhone();

  return (
    <SkeletonTheme
      baseColor={customTheme.colors.blue['20']}
      highlightColor={customTheme.colors.blue['10']}
    >
      {isPhone ? (
        <YStack>
          <View pb={5}>
            <View
              bg="white"
              paddingTop={20}
              pb={20}
              px={20}
              borderRadius={12}
              borderColor="$gray20"
              borderWidth={1}
            >
              <AccountsWidgetSkeleton />
            </View>
          </View>
          <View pb={5}>
            <XStack justifyContent={'space-between'}>
              <View
                width="48%"
                px={6}
                bg="white"
                borderRadius={12}
                borderColor="$gray20"
                borderWidth={1}
                height={32}
                justifyContent={'center'}
                alignContent={'center'}
              >
                <Skeleton height={16} width="100%" borderRadius={4} />
              </View>
              <View
                w="48%"
                px={6}
                bg="white"
                borderRadius={12}
                borderColor="$gray20"
                borderWidth={1}
                height={32}
                justifyContent={'center'}
                alignContent={'center'}
              >
                <Skeleton height={16} width="100%" borderRadius={4} />
              </View>
            </XStack>
          </View>

          <View pb={5}>
            <WidgetWrapper>
              <TileSkeleton />
            </WidgetWrapper>
          </View>

          <View pb={5}>
            <WidgetWrapper>
              <TileSkeleton />
            </WidgetWrapper>
          </View>

          <View pb={5}>
            <TransactionHistorySkeleton />
          </View>

          <View pb={5}>
            <WidgetWrapper>
              <TileSkeleton />
            </WidgetWrapper>
          </View>
        </YStack>
      ) : (
        <View width={'100%'} height="100vh" py="24px" pl="40px">
          <View
            pr="40px"
            pb="40px"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text variant="pageTitle">Overview</Text>
            <Pressable flexDirection="row">
              <Text color="$gray40" px="13px">
                Help
              </Text>
              <HelpIcon height="20px" width="20px" fill="#808797" />
            </Pressable>
          </View>
          <XStack flexGrow={1}>
            <YStack space="20px" w={COLUMN_WIDTH + 'px'} mr="20px">
              <View
                bg="white"
                pt={20}
                pb={20}
                px={20}
                borderRadius={12}
                borderColor="$gray20"
                borderWidth={1}
              >
                <AccountsWidgetSkeleton />
              </View>
              <XStack justifyContent={'space-between'}>
                <View
                  w="48%"
                  px={6}
                  bg="white"
                  borderRadius={12}
                  borderColor="$gray20"
                  borderWidth={1}
                  h={48}
                  justifyContent={'center'}
                  alignContent={'center'}
                >
                  <Skeleton height={16} width="100%" borderRadius={4} />
                </View>
                <View
                  w="48%"
                  px={6}
                  bg="white"
                  borderRadius={12}
                  borderColor="$gray20"
                  borderWidth={1}
                  h={48}
                  justifyContent={'center'}
                  alignContent={'center'}
                >
                  <Skeleton height={16} width="100%" borderRadius={4} />
                </View>
              </XStack>
              <TransactionHistorySkeleton />
            </YStack>
            <YStack
              w={COLUMN_WIDTH * 2 + 40 + 'px'}
              flexWrap="wrap"
              minHeight="650px"
              maxHeight="760px"
            >
              <WidgetWrapper>
                <TileSkeleton />
              </WidgetWrapper>

              <WidgetWrapper>
                <TileSkeleton />
              </WidgetWrapper>
            </YStack>
          </XStack>
        </View>
      )}
    </SkeletonTheme>
  );
};

export default HomePageSkeleton;
