import { HeaderAndFooterMobileTemplate, WebLogoTemplate } from '@templates';
import { ImageWithContent, SideModal } from '@molecules';
import ErrorIcon from '../../../assets/error.svg';
import ErrorMark from '../../../assets/ErrorMark.svg';
import ErrorClockIcon from '../../../assets/errorClock.svg';
import CupErrorIcon from '../../../assets/CupError.svg';
import { Center } from 'native-base';
import { Button, Text } from '@atoms';
import { IPageErrorBody } from '@store/features/errorStates/types';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import React from 'react';
import SupportScreenContent from '@organisms/SupportScreenContent/SupportScreenContent';
import SUPPORT_PAGES from '@constants/support';
import { View, YStack } from 'tamagui';

interface ErrorViewProps {
  pageContent: IPageErrorBody;
  mainButtonHandler: () => void;
  ghostButtonHandler?: () => void;
  isModalOpen?: boolean;
  toCloseModal?: () => void;
  modalName?: string | undefined;
}

const mainImageHandler = (icon: string | null) => {
  switch (icon) {
    case 'clock':
      return <ErrorClockIcon />;
    case 'cup':
      return <CupErrorIcon />;
    case 'error':
      return <ErrorMark />;
    default:
      return <ErrorIcon />;
  }
};

const mobile = ({
  pageContent,
  mainButtonHandler,
  ghostButtonHandler,
}: ErrorViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      footerContent={
        <Button
          onPress={mainButtonHandler}
          testID="paychexpay.ErrorView.button.mainButton"
        >
          {pageContent.mainButtonText}
        </Button>
      }
      avoidKeyboard={false}
    >
      <YStack
        pt={72}
        pb={'$4'}
        px={'$7'}
        flexGrow={1}
        justifyContent="space-between"
      >
        <ImageWithContent
          image={mainImageHandler(pageContent.icon)}
          space={'$7'}
        >
          <Text variant="title" pb={'$3'} textAlign="center">
            {pageContent.title}
          </Text>
          <Text variant="subtitle" textAlign="center">
            {pageContent.subtitle}
          </Text>
        </ImageWithContent>
        {pageContent.ghostButtonAction && (
          <Center>
            <Button
              variant="simple"
              _text={{
                underline: true,
              }}
              onPress={ghostButtonHandler}
              testID="paychexpay.ErrorView.button.ghostButton"
            >
              {pageContent.ghostButtonText}
            </Button>
          </Center>
        )}
      </YStack>
    </HeaderAndFooterMobileTemplate>
  );
};

const desktop = ({
  pageContent,
  mainButtonHandler,
  ghostButtonHandler,
  isModalOpen = false,
  toCloseModal = () => false,
  modalName = '',
}: ErrorViewProps) => {
  return (
    <>
      <WebLogoTemplate>
        <Center>
          <View w="325px">
            <ImageWithContent
              image={mainImageHandler(pageContent.icon)}
              space={'$7'}
              mb="$10"
            >
              <Text variant="title" pb={'$3'} textAlign="center">
                {pageContent.title}
              </Text>
              <Text variant="subtitle" textAlign="center">
                {pageContent.subtitle}
              </Text>
            </ImageWithContent>
            {pageContent.ghostButtonText && (
              <Center pt={7} pb={6}>
                <Button
                  variant="simple"
                  _text={{
                    underline: true,
                  }}
                  onPress={ghostButtonHandler}
                  testID="paychexpay.ErrorView.button.ghostButton"
                >
                  {pageContent.ghostButtonText}
                </Button>
              </Center>
            )}
            <Button
              mt={'$10'}
              onPress={mainButtonHandler}
              testID="paychexpay.ErrorView.button.mainButton"
            >
              {pageContent.mainButtonText}
            </Button>
          </View>
        </Center>
      </WebLogoTemplate>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.customerService]: (
              <SupportScreenContent {...SUPPORT_PAGES.customerService} />
            ),
          }}
        />
      </SideModal>
    </>
  );
};

const ErrorView = {
  mobile,
  desktop,
};

export default ErrorView;
