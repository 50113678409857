import { AuthNavigatorParamsProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useSelector } from 'react-redux';
import {
  selectBiometricsType,
  selectIsBiometricEnabled,
} from '@store/features/biometrics/selectors';
import { useAppDispatch } from '@store/hooks';
import { useEffect, useMemo, useState } from 'react';
import {
  selectInitialUrl,
  selectIsAuthenticated,
  selectIsAuthorizedCardholder,
} from '@store/features/navigationStates/selectors';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
import { fillInDataActions } from '@store/features/fillInData/slice';
import { Linking, Platform } from 'react-native';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import { useAuth0 } from '@hooks/useCustomAuth0';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import { selectCardholderCurrentStatus } from '@store/features/cardholder/selectors';
import useIsPhone from '@hooks/useIsPhone';
import ReactNativeBiometrics from 'react-native-biometrics';
import { getBiometryName } from '@utils/biometricsHelper';
import { customTheme } from '../styles/customTheme';
import {
  getRefreshToken,
  removeRefreshToken,
} from '@utils/accessTokenHelper.native';
import FaceIdIcon from '../assets/signIn/faceIdButton.svg';
import TouchIdIcon from '../assets/signIn/touchIdButton.svg';
import FingerprintIcon from '../assets/signIn/fingerprintButton.svg';
import useIsTablet from '@hooks/useIsTablet';
import linking from '../navigation/linking';
import { biometricsDataActions } from '@store/features/biometrics/slice';

const retryTimeInMin = 1;
const retryTimeout = retryTimeInMin * 60 * 1000;

const useSignIn = ({ isSkipLanding = false } = {}) => {
  const navigation = useNavigation<AuthNavigatorParamsProps['navigation']>();
  const route = useRoute<AuthNavigatorParamsProps['route']>();

  const isPhone = useIsPhone();
  const isTablet = useIsTablet();
  const isFocused = useIsFocused();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const biometryType = useSelector(selectBiometricsType);
  const isBiometricEnabled = useSelector(selectIsBiometricEnabled);
  const rnBiometrics = new ReactNativeBiometrics();
  const biometryName = useMemo(
    () => getBiometryName(biometryType),
    [biometryType]
  );
  const isAuthorizedCardholderSelector = useSelector(
    selectIsAuthorizedCardholder
  );

  const dispatch = useAppDispatch();
  const [biometricsIcon, setBiometricsIcon] = useState<
    JSX.Element | Array<JSX.Element> | null
  >(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirectTo, setRedirectTo] = useState<
    null | 'signIn' | 'signUp' | 'other' | 'unableToSignup'
  >(null);
  const [isRetry, setIsRetry] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const initialUrl = useSelector(selectInitialUrl);
  const [isBiometricPending, setIsBiometricPending] = useState(false);
  const [isSignInButtonDisabled, setSignInButtonDisabled] = useState(false);

  const {
    handleBiometricSuccess,
    loginInfo,
    onLogin,
    handleWebViewNavigationStateChange,
    loading,
    onLogout,
    handleRedirectAfterLogin,
  } = useAuth0(setRedirectTo, true, isSkipLanding);

  useEffect(() => {
    if (isFocused) {
      if (isPhone) {
        changeNavigationBarColor(
          customTheme.colors.primary.normal,
          false,
          false
        );
      } else {
        changeNavigationBarColor('white', true, false);
      }
    }
  }, [isFocused]);

  useEffect(() => {
    if (isBiometricPending) {
      changeNavigationBarColor(
        customTheme.colors.primary.coveredWith25PercentBlack,
        false,
        false
      );
    } else {
      changeNavigationBarColor(customTheme.colors.primary.normal, false, false);
    }
  }, [isBiometricPending]);

  const onGooglePlayPress = () => {
    // todo redirect to google play
  };
  const onAppStorePress = () => {
    // todo redirect to apple store
  };

  const isBiometricsSupport = async () => {
    const refreshToken = await getRefreshToken();

    if (isBiometricEnabled && refreshToken) {
      if (Platform.OS === 'android') {
        setBiometricsIcon(<FingerprintIcon color={'white'} />);
        onPressBiometrics();
        return;
      }

      switch (biometryType) {
        case 'FaceID':
          setBiometricsIcon(<FaceIdIcon color={'white'} />);
          onPressBiometrics();
          break;
        case 'TouchID':
          setBiometricsIcon(<TouchIdIcon color={'white'} />);
          onPressBiometrics();
          break;
        case 'Fingerprint':
          setBiometricsIcon(<FingerprintIcon color={'white'} />);
          onPressBiometrics();
          break;
        default:
          return;
      }
      // Retrieve the credentials
    }
  };

  useEffect(() => {
    Platform.OS !== 'web' && isBiometricsSupport();
  }, []);

  const resetUserInfo = () => {
    dispatch(fillInDataActions.refresh());
  };

  useEffect(() => {
    resetUserInfo();
    parseUrl();
    if (Platform.OS !== 'web') {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, []);

  const handleBiometricCancel = async () => {
    await removeRefreshToken();
    dispatch(biometricsDataActions.setIsBiometricEnabled(false));
    setBiometricsIcon(null);
  };

  const onPressBiometrics = () => {
    rnBiometrics
      .simplePrompt({ promptMessage: `Sign in with your ${biometryName}` })
      .then(async (resultObject) => {
        const { success } = resultObject;

        if (success) {
          handleBiometricSuccess();
          setTimeout(() => {
            setIsBiometricPending(true);
          }, 500);
        } else {
          handleBiometricCancel();
        }
      });
  };

  const goToCIP = () => {
    navigation.reset({
      index: 0,
      // @ts-ignore
      routes: [{ name: 'CIPNavigator' }],
    });
  };

  const goToHome = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          // @ts-ignore
          name: 'BottomTabsNavigator',
        },
      ],
    });
  };

  const goToUsaPatriotAct = () => {
    navigation.navigate('UsaPatriotActPage');
  };
  const goToProvideDocuments = () => {
    navigation.navigate('CIPNavigator', {
      screen: 'VerificationFailedPage',
    });
  };
  const onPressHelp = () => {
    if (isPhone) {
      navigation.navigate('AboutPFAccount');
    } else {
      toOpenModal(sideModalNames.signInFlex)();
    }
  };

  const onPressBack = () => {
    navigation.goBack();
  };

  const onSignInPress = () => {
    if (isPhone) {
      setSignInButtonDisabled(true);
      onLogin();
      setTimeout(() => setSignInButtonDisabled(false), 1500);
    }
  };
  const onSignUpPress = async () => {
    setIsLoading(true);
    if (Platform.OS === 'web' && isTablet) {
      //on safari we should open new tab by user action, so we moved onLogin func inside onLogout
      //and we are passing true to onLogout to call login function right away
      await onLogout(true);
      toCloseModal();
    } else {
      await onLogout();
      toCloseModal();
      onLogin();
    }
  };

  const goToBiometrics = async () => {
    if (Platform.OS !== 'web' && biometryType !== null) {
      if (isBiometricEnabled) {
        goToUsaPatriotAct();
      } else {
        navigation.navigate('BiometricsPage', {
          nextScreen: 'UsaPatriotActPage',
        });
      }
    } else {
      goToUsaPatriotAct();
    }
  };

  const { isModalOpen, modalName, toOpenModal, toCloseModal } = useSideModal({
    route,
    navigation,
  });

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (loading) {
      timeout = setTimeout(() => {
        setIsRetry(true);
      }, retryTimeout);
    }
    return () => timeout && clearTimeout(timeout);
  }, [loading]);

  const cardholderStatus = useSelector(selectCardholderCurrentStatus);

  const parseUrl = async () => {
    if (Platform.OS === 'web') {
      if (
        isAuthenticated &&
        isAuthorizedCardholderSelector &&
        cardholderStatus !== 'oowQuestions' &&
        cardholderStatus !== 'denied'
      ) {
        if (isSkipLanding) {
          goToHome();
        }
      } else {
        setIsLoading(true);
        const newInitialUrl = (await Linking.getInitialURL()) || '';

        if (
          newInitialUrl.includes(
            linking.config.screens.PaychexLoginPageSkipLanding
          ) &&
          isSkipLanding
        ) {
          await onLogin();
          setIsLoading(false);
        } else {
          if (newInitialUrl !== initialUrl) {
            await dispatch(
              navigationStatesActions.setInitialUrl(newInitialUrl)
            );
            if (newInitialUrl.includes('code')) {
              await handleRedirectAfterLogin();
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        }
      }
    }
  };

  const setIsAuthorizedCardholderAndNavigate = async (
    cardholderStatus: string
  ) => {
    await dispatch(navigationStatesActions.setIsAuthorizedCardholder(true));
    setTimeout(async () => {
      switch (cardholderStatus) {
        case 'oowQuestions':
          await goToCIP();
          break;
        case 'denied':
          await goToProvideDocuments();
          break;
        default:
          await goToHome();
          break;
      }
      setIsLoading(false);
    }, 10);
  };

  useEffect(() => {
    setIsLoading(true);
    if (cardholderStatus !== 'empty' && redirectTo === 'other') {
      setIsBiometricPending(false);
      setIsAuthorizedCardholderAndNavigate(cardholderStatus);
      return;
    }
    if (cardholderStatus !== 'empty' && redirectTo) {
      setIsBiometricPending(false);
      if (redirectTo === 'signUp') {
        goToBiometrics();
      } else if (redirectTo === 'signIn') {
        onSignInPress();
      } else if (redirectTo === 'unableToSignup') {
        navigation.navigate('UnableToSignupPage');
      }
    }
  }, [cardholderStatus, redirectTo]);

  return {
    onPressBack,
    onPressHelp,
    toCloseModal,
    isModalOpen,
    modalName,
    onGooglePlayPress,
    onAppStorePress,
    loginInfo,
    handleWebViewNavigationStateChange,
    loading,
    isLoading,
    onLogin,
    onSignUpPress,
    isRetry,
    biometricsIcon,
    onSignInPress,
    onPressBiometrics,
    isBiometricPending,
    isSignInButtonDisabled,
  };
};
export default useSignIn;
