import DisputeChargeImage from '../assets/support/cardSupport.svg';
import BlockedCardImage from '../assets/support/cardBlocked.svg';
import CardLostImage from '../assets/support/cardLost.svg';
import CloseAccountImage from '../assets/support/closeAccount.svg';
import CustomerServiceImage from '../assets/support/customerService.svg';
import CardFailImage from '../assets/cards/failCard.svg';

export const supportInfo = {
  phone: '1-866-438-0579',
  email: 'PaychexPayFeedback@paychex.com',
  mailingAddress: 'Paychex Pay\nPO Box 1124\nSioux Falls, SD 57101',
};

export const disputeCharge = {
  headerTitle: 'Dispute a transaction',
  title: 'Something wrong with a transaction?',
  text: 'Let’s look into it together. We’re here to help, 24/7.',
  icon: <DisputeChargeImage />,
  supportInfo,
};

export const blockedCard = {
  headerTitle: 'Your card is blocked',
  title: 'Your card has been blocked',
  text: 'We’ve blocked your card to help safeguard your funds. Call Customer Service right away as your card will not work for new transactions.',
  icon: <BlockedCardImage />,
  supportInfo,
};

export const cardLost = {
  headerTitle: 'Report lost or stolen',
  title: 'Card missing?',
  text: 'Freeze your card right away to prevent unauthorized use. If you can’t find your card, call Customer Service to report your card as lost/stolen and to order a new card.',
  icon: <CardLostImage />,
  supportInfo,
};

export const closeSpending = {
  headerTitle: 'Close account',
  title: 'Sorry to see you go',
  text: 'To close your account, call Customer Service.',
  icon: <CloseAccountImage />,
  supportInfo,
};

export const closeSaving = {
  headerTitle: 'Close account',
  title: 'Sorry to see you go',
  text: 'To close your account, call Customer Service.',
  icon: <CloseAccountImage />,
  supportInfo,
};

export const customerService = {
  headerTitle: 'Contact Customer Service',
  title: 'Need help?',
  text: 'We’re here to answer your questions, 24/7.',
  icon: <CustomerServiceImage />,
  supportInfo,
};

export const orderNewCardAddressRecentlyChanged = {
  headerTitle: 'Order a new card',
  title: 'Address recently changed',
  text: 'Contact Customer Service to order a new card.',
  icon: <CardFailImage />,
  supportInfo,
};

const SUPPORT_PAGES = {
  disputeCharge,
  blockedCard,
  cardLost,
  closeSpending,
  closeSaving,
  customerService,
  orderNewCardAddressRecentlyChanged,
};

export default SUPPORT_PAGES;
