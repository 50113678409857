import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import fillInDataThunks from '@store/features/fillInData/asyncThunks';
import { cleanUpPhoneField } from '@organisms/FillInDataFirstForm/helper';
import {
  IFillInData,
  IFirstPartOfUserInfo,
  ISecondPartOfUserData,
} from '@store/features/fillInData/types';
import statesMap from '@constants/statesMap';

const initialState = {
  addressVerificationStatus: 'notVerified',
  userInfo: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    zipCode: '',
    city: '',
    state: '',
    addressLine1: '',
    addressLine2: '',
    ssn: '',
    date: '',
  },
  isEligible: false,
  addressSuggestions: [],
  lockedInputs: [],
} as IFillInData;

const fillInDataSlice = createSlice({
  name: 'fillInData',
  initialState,
  reducers: {
    localUpdateFirst(state, action: PayloadAction<IFirstPartOfUserInfo>) {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
        phoneNumber: cleanUpPhoneField(action.payload.phoneNumber),
      };
    },
    localUpdateSecond(state, action: PayloadAction<ISecondPartOfUserData>) {
      state.userInfo = {
        ...state.userInfo,
        ...action.payload,
      };
    },
    refresh() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fillInDataThunks.getUserInfo.fulfilled,
      (state, { payload }) => {
        state.userInfo = {
          ...payload,
          state: statesMap[payload.state],
        };
        state.lockedInputs = [];
        if (payload.dob) {
          const splittedDate = payload.dob.split('-');
          const dateOfBirth = `${splittedDate[1]}/${splittedDate[2]}/****`;

          state.lockedInputs.push('date');
          state.userInfo.date = dateOfBirth;
        }
        if (payload.ssn) state.lockedInputs.push('ssn');
        if (payload.firstName) state.lockedInputs.push('firstName');
        if (payload.lastName) state.lockedInputs.push('lastName');
      }
    );
    builder.addCase(
      fillInDataThunks.checkIsEligible.fulfilled,
      (state, { payload }) => {
        state.isEligible = payload.content[0].paychex_pay_eligible;
      }
    );
    builder.addCase(
      fillInDataThunks.postAddressSuggestions.fulfilled,
      (state, { payload }) => {
        state.addressSuggestions = payload.content;
      }
    );
    builder.addCase(
      fillInDataThunks.postAddressVerify.fulfilled,
      (state, { payload }) => {
        if (Object.keys(payload).length === 0) {
          state.addressVerificationStatus = 'verified';
        } else {
          if (payload?.content['1'][0].poBoxIndicator) {
            state.addressVerificationStatus = 'PoBox';
          } else state.addressVerificationStatus = 'changed';
        }
      }
    );
    builder.addCase(fillInDataThunks.postAddressVerify.rejected, (state) => {
      state.addressVerificationStatus = 'notVerified';
    });
    builder.addCase(fillInDataThunks.postAddressVerify.pending, (state) => {
      state.addressVerificationStatus = 'pending';
    });
  },
});

export const { reducer: fillInDataReducer, actions: fillInDataActions } =
  fillInDataSlice;

export { initialState as FillIdDataInitialState };
