import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const generalSelector = (state: RootState) => state.general;

export const selectAppState = createSelector(
  generalSelector,
  (general) => general.appState
);

export const selectWebNavTransferSubsectionIsVisible = createSelector(
  generalSelector,
  (general) => general.webNavTransferSubsectionIsVisible
);

export const selectWebNavHelpSubsectionIsVisible = createSelector(
  generalSelector,
  (general) => general.webNavHelpSubsectionIsVisible
);

export const selectWebNavMasterCardSubsectionIsVisible = createSelector(
  generalSelector,
  (general) => general.webNavMasterCardSubsectionIsVisible
);

export const selectWebNavLegalSubsectionIsVisible = createSelector(
  generalSelector,
  (general) => general.setWebNavLegalSubsectionIsVisible
);

export const selectNotificationConfirmationModalVisible = createSelector(
  generalSelector,
  (general) => general.webNotificationConfirmationModalVisible
);

export const selectIsNotificationPreferencesUpdated = createSelector(
  generalSelector,
  (general) => general.notificationPreferencesUpdated
);

export const selectModalSubmitHandler = createSelector(
  generalSelector,
  (general) => general.modalSubmitHandler
);

export const selectIsProfileSettingsUpdated = createSelector(
  generalSelector,
  (general) => general.profileSettingsUpdated
);

export const selectProfileSettingsModalVisible = createSelector(
  generalSelector,
  (general) => general.profileSettingsModalVisible
);

export const selectInitialFetchSpending = createSelector(
  generalSelector,
  (general) => general.initialFetchSpending
);

export const selectInitialFetchSaving = createSelector(
  generalSelector,
  (general) => general.initialFetchSaving
);

export const selectIsDeliveryWidgetHidden = createSelector(
  generalSelector,
  (state) => state.isHideDeliveryWidget
);

export const selectVariableAnnualPercentageYield = createSelector(
  generalSelector,
  (state) => {
    return state.variables?.find(
      (item) =>
        item.id === 'Annual_Percentage_Yield' ||
        item.variable_name === 'Annual_Percentage_Yield'
    );
  }
);

export const selectVariableSavingsAgreementDate = createSelector(
  generalSelector,
  (state) =>
    state.variables?.find(
      (item) =>
        item.id === 'Savings_Agreement_Date' ||
        item.variable_name === 'Savings_Agreement_Date'
    )
);

export const selectVariableTransferFee = createSelector(
  generalSelector,
  (state) =>
    state.variables?.find(
      (item) =>
        item.id === 'Transfer_Fee' || item.variable_name === 'Transfer_Fee'
    )
);

export const selectVariableSavingsInterestRate = createSelector(
  generalSelector,
  (state) =>
    state.variables?.find(
      (item) =>
        item.id === 'Savings_Interest_Rate' ||
        item.variable_name === 'Savings_Interest_Rate'
    )
);

export const selectVariableATMFee = createSelector(generalSelector, (state) =>
  state.variables?.find(
    (item) => item.id === 'ATM_Fee' || item.variable_name === 'ATM_Fee'
  )
);
