/* eslint-disable react/prop-types */
import React from 'react';
import Masonry from 'react-masonry-css';
import { View } from 'tamagui';

interface HomeDesktopLayoutWeb {
  children: React.ReactNode;
  shouldBeUpdated?: boolean;
}

const breakpointColumnsObj = {
  default: 3,
  1410: 2,
  820: 2,
  768: 1,
  580: 1,
};

const HomeDesktopLayout = ({ children }: HomeDesktopLayoutWeb) => {
  return (
    // @ts-ignore
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
    >
      {React.Children.map(
        children,
        (child, index) => child && <View key={index}>{child}</View>
      )}
    </Masonry>
  );
};

export default HomeDesktopLayout;
