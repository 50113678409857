import { Box } from 'native-base';
import { MobileHeader } from '@molecules';
import { MoreSectionContent } from '@organisms';
import { LOGOUT_MENU_ITEM, MORE_SECTION } from '@constants/menu';
import { morePageType } from '@navigation/MoreNavigator/MoreNavigator.types';
import { FocusAwareStatusBar, Text } from '@atoms';
import { ScrollView } from 'tamagui';

interface MoreViewProps {
  logoutHandler: () => void;
  appVersion: string;
  onItemPress: (pageName: morePageType) => () => void;
}

const MoreView = ({
  logoutHandler,
  onItemPress,
  appVersion,
}: MoreViewProps) => {
  return (
    <>
      <Box safeAreaTop bg="blue.10" flexGrow={1}>
        <MobileHeader withBorder={false} bg="$blue10" title="More" />
        <ScrollView
          flexGrow={1}
          paddingHorizontal="$4"
          bg="white"
          paddingBottom="$7"
        >
          <MoreSectionContent
            menuItems={MORE_SECTION}
            onItemPress={onItemPress}
            lastMenuItem={LOGOUT_MENU_ITEM}
            logoutHandler={logoutHandler}
          />
          <Text textAlign="center" fontWeight="$semiBold">
            {appVersion}
          </Text>
        </ScrollView>
        <FocusAwareStatusBar barStyle="dark-content" />
      </Box>
    </>
  );
};

export default MoreView;
