export const CARD_STATES = {
  regular: 'ACTIVE',
  frozen: 'BLOCK',
  blocked: 'FRAUD_BLOCK',
  closed: 'CLOSED',
} as const;

export const DEBIT_CARD_SCREENS = {
  main: 'main',
  blockedModal: 'blockedModal',
  cardLostModal: 'cardLostModal',
} as const;
