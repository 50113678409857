import { ITransactionsState } from '@store/features/transactions/types';
import { createSlice } from '@reduxjs/toolkit';
import transactionThunks from '@store/features/transactions/asyncThunks';

const initialState = {
  spending: {
    transactions: [],
    filteredTransactions: [],
    isLoading: false,
    fetchedInitially: false,
    transactionsItemCount: 0,
  },
  saving: {
    transactions: [],
    filteredTransactions: [],
    isLoading: false,
    fetchedInitially: false,
    transactionsItemCount: 0,
  },
  searchText: '',
  filteredBy: '',
  dateRange: {
    date_from: null,
    date_to: null,
  },
} as ITransactionsState;

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setSearchText(state, { payload }) {
      state.searchText = payload;
    },
    setDateRange(state, { payload }) {
      state.dateRange = payload;
    },
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      transactionThunks.getSpendingTransactions.fulfilled,
      (state, { payload }) => {
        if (payload.content) {
          state.spending.transactions = payload.content;
          state.spending.filteredTransactions = payload.content;
          state.spending.transactionsItemCount =
            payload.metadata.contentItemCount;
        }
        state.spending.fetchedInitially = true;
        state.filteredBy = '';
        state.spending.isLoading = false;
      }
    );
    builder.addCase(
      transactionThunks.getSavingsTransactions.fulfilled,
      (state, { payload }) => {
        if (payload.content) {
          state.saving.transactions = payload.content;
          state.saving.filteredTransactions = payload.content;
          state.saving.transactionsItemCount =
            payload.metadata.contentItemCount;
        }
        state.saving.fetchedInitially = true;
        state.filteredBy = '';
        state.saving.isLoading = false;
      }
    );
    builder.addCase(
      transactionThunks.getSpendingTransactionsByFilter.fulfilled,
      (state, { payload, meta }) => {
        if (payload.content)
          state.spending.filteredTransactions = payload.content;
        else state.spending.filteredTransactions = [];
        state.filteredBy = meta.arg.transactionType || '';
        state.spending.isLoading = false;
      }
    );
    builder.addCase(
      transactionThunks.getSavingsTransactionsByFilter.fulfilled,
      (state, { payload, meta }) => {
        if (payload.content)
          state.saving.filteredTransactions = payload.content;
        else state.saving.filteredTransactions = [];
        state.filteredBy = meta.arg.transactionType || '';
        state.saving.isLoading = false;
      }
    );
    builder.addCase(
      transactionThunks.getSpendingTransactionsBySearch.fulfilled,
      (state, { payload }) => {
        if (payload.content) {
          state.spending.filteredTransactions = payload.content;
        } else {
          state.spending.filteredTransactions = [];
        }
        state.spending.isLoading = false;
      }
    );
    builder.addCase(
      transactionThunks.getSavingsTransactionsBySearch.fulfilled,
      (state, { payload }) => {
        if (payload.content) {
          state.saving.filteredTransactions = payload.content;
        } else {
          state.saving.filteredTransactions = [];
        }
        state.saving.isLoading = false;
      }
    );
    builder.addCase(
      transactionThunks.getSpendingTransactions.pending,
      (state) => {
        state.spending.isLoading = true;
      }
    );
    builder.addCase(
      transactionThunks.getSavingsTransactions.pending,
      (state) => {
        state.saving.isLoading = true;
      }
    );
    builder.addCase(
      transactionThunks.getSpendingTransactionsByFilter.pending,
      (state) => {
        state.spending.isLoading = true;
      }
    );
    builder.addCase(
      transactionThunks.getSavingsTransactionsByFilter.pending,
      (state) => {
        state.saving.isLoading = true;
      }
    );
    builder.addCase(
      transactionThunks.getSpendingTransactionsBySearch.pending,
      (state) => {
        state.spending.isLoading = true;
      }
    );
    builder.addCase(
      transactionThunks.getSavingsTransactionsBySearch.pending,
      (state) => {
        state.saving.isLoading = true;
      }
    );
  },
});

export const { reducer: transactionsReducer, actions: transactionsActions } =
  transactionsSlice;
