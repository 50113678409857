import {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { Dimensions, Keyboard } from 'react-native';

const animationOptions = {
  duration: 500,
  easing: Easing.bezier(0.65, 0, 0.35, 1),
};

const windowWidth = Dimensions.get('window').width;

interface useListViewAnimationProps {
  isListViewOpen: boolean;
  setIsListViewOpen: (f: boolean) => void;
  closePreview: () => void;
}

const useListViewAnimation = ({
  isListViewOpen,
  setIsListViewOpen,
  closePreview,
}: useListViewAnimationProps) => {
  const listViewOffsetX = useSharedValue(isListViewOpen ? 0 : windowWidth);
  const listViewStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: listViewOffsetX.value }],
    };
  });

  const onPressListView = () => {
    Keyboard.dismiss();
    if (isListViewOpen) {
      listViewOffsetX.value = withTiming(windowWidth, animationOptions);
      setIsListViewOpen(false);
    } else {
      closePreview();
      listViewOffsetX.value = withTiming(0, animationOptions);
      setIsListViewOpen(true);
    }
  };

  return { listViewStyle, onPressListView };
};

export default useListViewAnimation;
