import { StackProps, View, YStack } from 'tamagui';
import { ReactNode } from 'react';

export interface ImageWithContentProps extends StackProps {
  image: any;
  children: ReactNode;
  space?: string | number;
}

const ImageWithContent = ({
  children,
  image,
  space = 0,
  ...props
}: ImageWithContentProps) => {
  return (
    <YStack alignItems="center" {...props}>
      <View marginBottom={space}>{image}</View>
      {children}
    </YStack>
  );
};
export default ImageWithContent;
