import { NativeBaseProvider } from 'native-base';
import { TamaguiProvider } from 'tamagui';
import { Alert, AppState, Linking, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  initialWindowMetrics,
  SafeAreaProvider,
} from 'react-native-safe-area-context';
import * as Keychain from 'react-native-keychain';
import { isPinOrFingerprintSet } from 'react-native-device-info';
import SplashScreen from 'react-native-splash-screen';
import Toast from 'react-native-toast-message';
import { useEffect, useRef, useState } from 'react';
import ErrorsProvider from '@utils/ErrorsProvider';
import * as TransparentStatusAndNavigationBar from 'react-native-transparent-status-and-navigation-bar';
import RootNavigator from './navigation/RootNavigator/RootNavigator';
import linking from './navigation/linking';
import { persistor, store } from '@store';
import { toastConfig } from '@atoms';
import { biometricsDataActions } from '@store/features/biometrics/slice';
import { customTheme } from './styles/customTheme';
import { Auth0Wrapper } from '@molecules';
// @ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import useHandleNavigation from '@hooks/useHandleNavigation';
import { ErrorBoundary, LogoutModal } from '@organisms';
import { JailMonkey } from '@utils/jailMonkey';
import Airship, { iOS } from '@utils/airship';
import tamaguiConfig from './styles/tamaguiConfig';
import Heap from '@heap/react-native-heap';
import { generalActions } from '@store/features/general/slice';
import AccessTokenProvider from '@utils/AccessTokenProvider';
import { tracker } from './eventCollector';

const HEAP_APP_ID = '{{bank.paychexbank.heapAppId}}';

const HeapNavigationContainer =
  Heap.withReactNavigationAutotrack(NavigationContainer);
import { LogBox } from 'react-native';
import { BlurView } from '@utils/rnBlur';

const App = () => {
  LogBox.ignoreAllLogs(); //TODO: remove
  HEAP_APP_ID && Heap.setAppId(HEAP_APP_ID);

  tracker.context({ OS: Platform.OS });

  const appState = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const isJailBroken = __DEV__ ? false : JailMonkey.isJailBroken();

  useEffect(() => {
    if (Platform.OS === 'web') return;

    Airship.takeOff({
      default: {
        appSecret: '{{bank.paychexbank.urbanAirship.secret}}',
        appKey: '{{bank.paychexbank.urbanAirship.key}}',
      },
      site: 'us',
      urlAllowList: ['*'],
      android: {
        notificationConfig: {
          icon: 'ic_launcher_round',
          accentColor: '#0073C4',
        },
      },
    })
      .then(() => false)
      .catch(() => false);

    if (Platform.OS === 'ios') {
      Airship.push.iOS
        .setNotificationOptions([
          iOS.NotificationOption.Badge,
          iOS.NotificationOption.Sound,
        ])
        .then(() => false)
        .catch(() => false);
      Airship.push.iOS
        .setForegroundPresentationOptions([
          iOS.ForegroundPresentationOption.List,
          iOS.ForegroundPresentationOption.Badge,
          iOS.ForegroundPresentationOption.Sound,
        ])
        .then(() => false)
        .catch(() => false);
    }
  }, []);

  const isBiometricsSupport = async () => {
    const supportedBiometryType = await Keychain.getSupportedBiometryType();
    if (supportedBiometryType === null) {
      await Keychain.resetGenericPassword();
      store.dispatch(biometricsDataActions.setIsBiometricEnabled(false));
    }
    store.dispatch(
      biometricsDataActions.updateBiometricsType(supportedBiometryType)
    );
  };

  const isPinOrBiometricsSet = async () => {
    if (!(await isPinOrFingerprintSet())) {
      Alert.alert(
        'Identity Verification Required',
        'To use the app please set up identity verification on your smartphone. It will help to protect your data.',
        [
          {
            text: 'Go to settings',
            onPress: async () =>
              (await Platform.OS) === 'ios'
                ? Linking.openSettings()
                : Linking.sendIntent('android.settings.SETTINGS'),
          },
        ]
      );
    }
  };

  const isJailBrokenOrRootedDevice = () => {
    if (isJailBroken) {
      Alert.alert(
        'Device is jail-broken/rooted',
        'Some functinality can be limited or fully locked',
        [
          {
            text: 'OK',
            onPress: () => isJailBrokenOrRootedDevice(),
          },
        ]
      );
    }
  };

  useEffect(() => {
    if (appStateVisible === 'active' && Platform.OS !== 'web') {
      isPinOrBiometricsSet();
    }
    store.dispatch(generalActions.setAppState(appStateVisible));
  }, [appStateVisible]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const subscription = AppState.addEventListener(
        'change',
        (nextAppState) => {
          appState.current = nextAppState;
          setAppStateVisible(appState.current);
        }
      );
      isPinOrBiometricsSet();
      isBiometricsSupport();
      isJailBrokenOrRootedDevice();
      TransparentStatusAndNavigationBar.init();
      TransparentStatusAndNavigationBar.setBarsStyle(true, 'light-content');
      SplashScreen.hide();

      return () => {
        subscription.remove();
      };
    }
  }, []);

  useEffect(() => {
    (async () => {
      const result = await getAccessToken();
      store.dispatch(navigationStatesActions.setIsAuthenticated(!!result));
    })();
  }, []);

  const { initialState, onStateChange, currentRouteName } =
    useHandleNavigation();

  return (
    <NativeBaseProvider
      theme={customTheme}
      //DO NOT TURN IT ON, IT WILL BREAK THE APP
      config={{ strictMode: 'off' }}
    >
      <TamaguiProvider config={tamaguiConfig}>
        <ErrorBoundary>
          <Auth0Wrapper>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <SafeAreaProvider initialMetrics={initialWindowMetrics}>
                  <HeapNavigationContainer
                    // @ts-ignore
                    linking={linking}
                    initialState={initialState}
                    onStateChange={onStateChange}
                  >
                    <AccessTokenProvider currentRouteName={currentRouteName}>
                      <ErrorsProvider>
                        <RootNavigator />
                        <LogoutModal />
                      </ErrorsProvider>
                    </AccessTokenProvider>
                    {appStateVisible !== 'active' && Platform.OS !== 'web' ? (
                      <BlurView
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                        }}
                        blurType="dark"
                        blurAmount={8}
                        blurRadius={10}
                        reducedTransparencyFallbackColor="black"
                      />
                    ) : null}
                  </HeapNavigationContainer>
                  <Toast config={toastConfig} />
                </SafeAreaProvider>
              </PersistGate>
            </Provider>
          </Auth0Wrapper>
        </ErrorBoundary>
      </TamaguiProvider>
    </NativeBaseProvider>
  );
};

export default App;
