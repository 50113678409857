import { Platform } from 'react-native';

export const VELOCITY_THRESHOLD =
  Platform.select({
    ios: 600,
    android: 600,
    web: 0.5,
  }) || 600;
export const SCALE_OF_OTHER_SLIDES = 0.9;
export const OPACITY_OF_OTHER_SLIDES = 0.8;
export const DEFAULT_ANIMATION_CONFIG = {
  damping: 20,
  mass: 1,
  stiffness: 100,
  overshootClamping: false,
  restDisplacementThreshold: 0.01,
  restSpeedThreshold: 3,
  velocity: 0,
};
