import Keychain from 'react-native-keychain';
import { v4 as uuidv4 } from 'uuid';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { REFRESH_KEY } from '@constants/general';

const key = uuidv4();

const getStoredRefreshKey = async () => {
  let keyRefresh = await AsyncStorage.getItem(REFRESH_KEY);

  if (!keyRefresh) {
    keyRefresh = uuidv4();
    await AsyncStorage.setItem(REFRESH_KEY, keyRefresh);
  }
  return keyRefresh;
};

const saveAccessToken = async (token: string) => {
  return Keychain.setGenericPassword('user', token, {
    service: key,
  });
};

const getAccessToken = async () => {
  const result = await Keychain.getGenericPassword({ service: key });
  if (result) {
    return result.password;
  } else {
    return false;
  }
};

const removeAccessToken = async () => {
  return Keychain.resetGenericPassword({ service: key });
};

// methods to save, get and remove tracking log events that occur before login
// these exist to save those logs into local storage and retrieve/send them over after login
const savePayload = async (payload: any) => {
  await AsyncStorage.setItem('payload', JSON.stringify(payload));
};

const getPayload = async () => {
  return AsyncStorage.getItem('payload');
};

const removePayload = async () => {
  await AsyncStorage.removeItem('payload');
};

const saveRefreshToken = async (token: string) => {
  const keyRefresh = await getStoredRefreshKey();

  return Keychain.setGenericPassword('userRefresh', token, {
    service: keyRefresh,
  });
};

const getRefreshToken = async () => {
  const keyRefresh = await getStoredRefreshKey();

  const result = await Keychain.getGenericPassword({
    service: keyRefresh,
  });
  if (result) {
    return result.password;
  } else {
    return false;
  }
};

const removeRefreshToken = async () => {
  const keyRefresh = await getStoredRefreshKey();
  await AsyncStorage.removeItem(REFRESH_KEY);

  return Keychain.resetGenericPassword({ service: keyRefresh });
};

export {
  saveAccessToken,
  getAccessToken,
  removeAccessToken,
  savePayload,
  getPayload,
  removePayload,
  saveRefreshToken,
  getRefreshToken,
  removeRefreshToken,
};
