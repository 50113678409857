import { DeleteAccountProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import DeleteAccountView from '@views/Transfers/DeleteAccountView';
import { useForm } from 'react-hook-form';
import { IDeleteAccountFormInputs } from '@organisms/DeleteAccountForm/helper';
import externalBankAccountsThunks from '@store/features/externalBankAccounts/asyncThunks';
import { useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import { Alert, Platform } from 'react-native';
import { CenterModal } from '@molecules';
import { Separator, View, XStack } from 'tamagui';
import { TButton, Text } from '@atoms';

const DeleteAccountPage = ({ navigation, route }: DeleteAccountProps) => {
  const bank_account_id = route.params?.account.id ?? '';

  const defaultValues = {
    achNick: route.params?.account.name || '',
    accountType:
      route.params?.account.type === 'spending'
        ? 'checking'
        : route.params?.account.type === 'saving'
        ? 'savings'
        : '',
    accountNumber: route.params?.account.accountNumber || '',
    bankName: route.params?.account.bankName || '',
    routingNumber: route.params?.account.routingNumber || '',
    accountOwnership: route.params?.account.accountOwnership || '',
  };

  const { control } = useForm<IDeleteAccountFormInputs>({
    defaultValues,
  });

  const [
    isDeleteExternalAccountModalOpen,
    setIsDeleteExternalAccountModalOpen,
  ] = useState(false);

  const navigateBack = () => {
    navigation.goBack();
  };
  const dispatch = useAppDispatch();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const deleteAccount = async () => {
    setIsButtonLoading(true);
    await dispatch(
      externalBankAccountsThunks.deleteExternalAccount({
        accountId: bank_account_id,
      })
    );
    dispatch(externalBankAccountsThunks.getExternalAccounts());
    setIsButtonLoading(false);
    navigateBack();
    setIsDeleteExternalAccountModalOpen(false);
  };

  const onDeleteAccount = async () => {
    if (Platform.OS === 'web') {
      setIsDeleteExternalAccountModalOpen(true);
    } else {
      Alert.alert(
        'Remove account?',
        'It will no longer be available through Paychex Pay.',
        [
          {
            text: 'Cancel',
            style: 'cancel',
          },
          { text: 'Remove', onPress: deleteAccount, style: 'destructive' },
        ]
      );
    }

    if (route.params?.testEnv) {
      deleteAccount();
    }
  };

  const closeDeleteExternalAccountModal = () => {
    setIsDeleteExternalAccountModalOpen(false);
  };

  return (
    <>
      <DeleteAccountView
        navigateBack={navigateBack}
        control={control}
        onDeleteAccount={onDeleteAccount}
        isButtonLoading={isButtonLoading}
        onCloseDeleteExternalAccountModal={closeDeleteExternalAccountModal}
        isDeleteExternalAccountModalOpen={isDeleteExternalAccountModalOpen}
      />
      {Platform.OS === 'web' && (
        <CenterModal
          isModalOpen={isDeleteExternalAccountModalOpen}
          closeByOutsideClick
          closeModal={closeDeleteExternalAccountModal}
        >
          <View
            maxWidth={343}
            paddingVertical={16}
            bg="$white"
            borderRadius={12}
            paddingTop={6}
            paddingBottom={4}
            flexDirection="column"
            display="flex"
          >
            <Text textAlign="center" fontSize={16} fontWeight={'600'} pb={2}>
              Remove account?
            </Text>
            <Text
              fontSize={14}
              fontWeight={'400'}
              color={'$color.gray60'}
              textAlign="center"
              paddingBottom={6}
            >
              It will no longer be available through Paychex Pay.
            </Text>
            <Separator borderColor="$gray20" />
            <XStack alignItems={'center'} marginTop={4} paddingHorizontal={12}>
              <TButton
                flex={1}
                mr={'$2'}
                variant={'simple'}
                borderColor={'$gray20'}
                borderWidth={1}
                testID="paychexpay.DeleteAccountPage.button.cancel"
                onPress={closeDeleteExternalAccountModal}
              >
                Cancel
              </TButton>
              <TButton
                flex={1}
                ml={'$2'}
                onPress={deleteAccount}
                isLoading={isButtonLoading}
                backgroundColor={'$red50'}
                borderWidth={0}
              >
                Remove
              </TButton>
            </XStack>
          </View>
        </CenterModal>
      )}
    </>
  );
};

export default DeleteAccountPage;
