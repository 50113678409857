import { helpScreensNames } from '@constants/helpScreensNames';
import { HelpProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { useEffect, useState } from 'react';
import useIsTablet from '@hooks/useIsTablet';
import DotIcon from '../assets/webDrawer/dot.svg';
import routesToTitlesMap from '../navigation/routesToTitlesMap';

const useHelp = ({ navigation, route }: HelpProps) => {
  const isTablet = useIsTablet();
  const [screenName, setScreenName] = useState<string | null>(
    helpScreensNames.customerService
  );
  useEffect(() => {
    if (route.params?.screenName) {
      setScreenName(route.params.screenName);
    } else {
      setScreenName(route.params?.pageName || route.name || null);
    }

    setScreenTitle();
  }, [route]);

  const setScreenTitle = () => {
    let title = routesToTitlesMap.Faq;

    if (route.params?.screenName) {
      switch (route.params.screenName) {
        case helpScreensNames.faq:
          title = routesToTitlesMap.Faq;
          break;
        case helpScreensNames.getStartedWizard:
          title = routesToTitlesMap.GetStartedWizard;
          break;
        case helpScreensNames.customerService:
          title = routesToTitlesMap.CustomerService;
          break;
        case helpScreensNames.feedback:
          title = routesToTitlesMap.SendFeedback;
          break;
      }

      navigation.setOptions({
        title: title,
      });
    }
  };

  const navigateToCustomerService = () => {
    if (isTablet) {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: { pageName: 'customerService' },
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'Help',
              params: {
                screenName: helpScreensNames.customerService,
              },
            },
          },
        ],
      });
    }
  };
  const navigateToFAQ = () => {
    if (isTablet) {
      navigation.navigate('MoreNavigator', { screen: 'Faq' });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'Help',
              params: {
                screenName: helpScreensNames.faq,
              },
            },
          },
        ],
      });
    }
  };
  const navigateToGetStartedWizard = () => {
    if (isTablet) {
      navigation.navigate('OnboardingNavigator', {
        screen: 'OnboardingPage',
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'Help',
              params: {
                screenName: helpScreensNames.getStartedWizard,
              },
            },
          },
        ],
      });
    }
  };
  const navigateToFeedback = () => {
    if (isTablet) {
      navigation.navigate('MoreNavigator', { screen: 'SendFeedback' });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'Help',
              params: {
                screenName: helpScreensNames.feedback,
              },
            },
          },
        ],
      });
    }
  };
  const menuItems = [
    {
      Icon: DotIcon,
      pageName: 'FAQ',
      label: 'View FAQs',
      isSubsectionItem: true,
      name: helpScreensNames.faq,
      title: 'View FAQs',
      handler: navigateToFAQ,
      testId: 'paychexpay.helpMenuItems.button.faq',
    },
    {
      Icon: DotIcon,
      pageName: 'GetStartedWizard',
      label: 'Replay welcome intro',
      isSubsectionItem: true,
      name: helpScreensNames.getStartedWizard,
      title: 'Replay welcome intro',
      handler: navigateToGetStartedWizard,
      testId: 'paychexpay.helpMenuItems.button.getStartedWizard',
    },
    {
      Icon: DotIcon,
      pageName: 'CustomerService',
      label: 'Contact Customer Service',
      isSubsectionItem: true,
      name: helpScreensNames.customerService,
      title: 'Contact Customer Service',
      handler: navigateToCustomerService,
      testId: 'paychexpay.helpMenuItems.button.customerService',
    },
    {
      Icon: DotIcon,
      pageName: 'Feedback',
      label: 'Give feedback',
      isSubsectionItem: true,
      name: helpScreensNames.feedback,
      title: 'Give feedback',
      handler: navigateToFeedback,
      testId: 'paychexpay.helpMenuItems.button.feedback',
    },
  ];
  return { menuItems, screenName };
};

export interface IHelpMenuItem {
  title: string;
  name: string;
  handler: () => void;
  testId: string;
}

export default useHelp;
