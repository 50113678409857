import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { OpenSavingsAccountsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { OpenSavingsAccountView } from '@views/Accounts';
import { useSelector } from 'react-redux';
import {
  selectVariableAnnualPercentageYield,
  selectVariableSavingsAgreementDate,
  selectVariableSavingsInterestRate,
} from '@store/features/general/selectors';

const OpenSavingsAccountPage = ({ navigation }: OpenSavingsAccountsProps) => {
  const onPressBack = () => {
    navigation.goBack();
  };

  const mainButtonHandler = () => {
    navigation.navigate('HomeNavigator', { screen: 'SavingsAgreements' });
  };

  const variableAnnualPercentageYield = useSelector(
    selectVariableAnnualPercentageYield
  );

  const variableSavingsInterestRate = useSelector(
    selectVariableSavingsInterestRate
  );

  const variableSavingsAgreementDate = useSelector(
    selectVariableSavingsAgreementDate
  );

  return (
    <MediaLayoutTemplate
      Mobile={OpenSavingsAccountView.mobile}
      Desktop={OpenSavingsAccountView.desktop}
      onPressBack={onPressBack}
      mainButtonHandler={mainButtonHandler}
      variables={{
        variableAnnualPercentageYield,
        variableSavingsInterestRate,
        variableSavingsAgreementDate,
      }}
    />
  );
};

export default OpenSavingsAccountPage;
