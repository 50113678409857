import {
  INotificationsList,
  INotificationsOption,
} from '@store/features/notifications/types';
import { HeaderAndFooterMobileTemplate, SideBarTemplate } from '@templates';
import NotificationsOption from '@molecules/NotificationsOption/NotificationsOption';
import { TButton, Text } from '@atoms';
import { Alert, Platform } from 'react-native';
import { MobileWebHeader } from '@molecules';
import { ScrollView, View, XStack } from 'tamagui';
import { NOTIFICATIONS_GROUPS } from '@constants/notifications';
import { useState } from 'react';
import ConfirmationModal from '@organisms/ConfirmationModal/ConfirmationModal';
import { useSelector } from 'react-redux';
import { selectNotificationConfirmationModalVisible } from '@store/features/general/selectors';
import { useAppDispatch } from '@store/hooks';
import { generalActions } from '@store/features/general/slice';

interface NotificationsViewProps {
  navigateBack: () => void;
  notificationsList: INotificationsList;
  allSwitches: INotificationsOption;
  toSwitchAll: (
    option: 'email' | 'text' | 'mobilePushPaychexPay'
  ) => (value: boolean) => void;
  toSwitchOne: (
    property: keyof INotificationsList
  ) => (
    option: 'email' | 'text' | 'mobilePushPaychexPay'
  ) => (value: boolean) => void;
  isTogglesDisabled: boolean;
  setupAllNotifications: () => void;
  saveProcessing?: boolean;
  isNotificationPreferencesUpdated?: boolean;
  isDesktopModalOpen?: boolean;
  setIsConfirmationModalOpen: (flag: boolean) => void;
  confirmHandler?: () => void;
  currentModalVariant?: string;
}

const mobile = ({
  navigateBack,
  notificationsList,
  allSwitches,
  toSwitchAll,
  toSwitchOne,
  isTogglesDisabled,
  setupAllNotifications,
  saveProcessing,
  isNotificationPreferencesUpdated,
  isDesktopModalOpen = false,
  setIsConfirmationModalOpen: setTextModalOpen,
  confirmHandler,
  currentModalVariant,
}: NotificationsViewProps) => {
  const [isModalOpen, setIsConfirmationModalOpen] = useState(false);
  const goBackHandler = () => {
    if (Platform.OS === 'web') {
      setIsConfirmationModalOpen(true);
    } else {
      Alert.alert('Unsaved changes', 'Are you sure you want to leave?', [
        {
          text: 'Stay',
          style: 'cancel',
        },
        {
          text: 'Leave',
          onPress: navigateBack,
        },
      ]);
    }
  };
  const webNotificationConfirmationModalVisible = useSelector(
    selectNotificationConfirmationModalVisible
  );
  const dispatch = useAppDispatch();

  const setNotificationModalVisible = (value: boolean) => {
    dispatch(generalActions.setWebNotificationConfirmationModalVisible(value));
  };

  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      {Platform.OS === 'web' && (
        <ConfirmationModal
          isModalOpen={isModalOpen || webNotificationConfirmationModalVisible}
          setIsConfirmationModalOpen={(value: boolean) => {
            setNotificationModalVisible(value);
            setIsConfirmationModalOpen(value);
          }}
          confirmHandler={navigateBack}
        />
      )}

      <ConfirmationModal
        variant={currentModalVariant}
        isModalOpen={isDesktopModalOpen}
        setIsConfirmationModalOpen={setTextModalOpen}
        confirmHandler={confirmHandler}
      />

      <HeaderAndFooterMobileTemplate
        header={{
          isBackButton: true,
          onPressBack: isNotificationPreferencesUpdated
            ? goBackHandler
            : navigateBack,
          title: 'Notification settings',
        }}
        footerContent={
          <TButton
            onPress={setupAllNotifications}
            isLoading={saveProcessing}
            testID="paychexpay.mobile.NotificationsView.button.saveChanges"
            disabled={!isNotificationPreferencesUpdated}
          >
            Save changes
          </TButton>
        }
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            paddingTop: 6,
            backgroundColor: '$blue10',
            height: Platform.OS === 'web' ? 0 : 'auto',
          }}
        >
          <NotificationsOption.mobile
            options={allSwitches}
            onChangeOption={toSwitchAll}
            title={'All notifications'}
            topDivider
            isTogglesDisabled={isTogglesDisabled}
          />
          <View h={'$5'} bg="$blue10" />
          {NOTIFICATIONS_GROUPS.map((prop, index) => (
            <NotificationsOption.mobile
              options={
                //@ts-ignore
                notificationsList[prop.notificationKeysIncluded[0]]
              }
              //@ts-ignore
              onChangeOption={toSwitchOne(prop.notificationKeysIncluded[0])}
              title={prop.title}
              topDivider={!index}
              key={prop.title}
              isTogglesDisabled={isTogglesDisabled}
            />
          ))}
          <View height={10} bg="$blue10" />
        </ScrollView>
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

const desktop = ({
  notificationsList,
  allSwitches,
  toSwitchAll,
  toSwitchOne,
  isTogglesDisabled,
  setupAllNotifications,
  saveProcessing,
  isDesktopModalOpen,
  confirmHandler,
  currentModalVariant,
  isNotificationPreferencesUpdated,
  setIsConfirmationModalOpen,
}: NotificationsViewProps) => {
  const webNotificationConfirmationModalVisible = useSelector(
    selectNotificationConfirmationModalVisible
  );
  const dispatch = useAppDispatch();

  const setNotificationModalVisible = (value: boolean) => {
    setIsConfirmationModalOpen(value);
    dispatch(generalActions.setWebNotificationConfirmationModalVisible(value));
  };

  return (
    <>
      <SideBarTemplate>
        <ScrollView
          width="100%"
          height="100vh"
          contentContainerStyle={{
            padding: 24,
          }}
        >
          <XStack
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={40}
          >
            <Text variant="title" lineHeight={62}>
              Notification settings
            </Text>
            <TButton
              maxWidth={270}
              onPress={setupAllNotifications}
              isLoading={saveProcessing}
              disabled={!isNotificationPreferencesUpdated}
            >
              Save changes
            </TButton>
          </XStack>

          <View
            bg="white"
            borderRadius={12}
            paddingHorizontal={20}
            paddingTop={20}
            paddingBottom={7}
          >
            <XStack
              justifyContent="space-between"
              bg="$blue10"
              borderRadius={8}
              paddingHorizontal={16}
              paddingVertical={16}
              mb={'$4'}
            >
              <View flex={1} flexGrow={1} />
              <XStack width={'156px'} justifyContent="space-between">
                <Text width="42px" variant="regular">
                  Email
                </Text>
                <Text width="42px" variant="regular">
                  Text
                </Text>
              </XStack>
            </XStack>
            <NotificationsOption.desktop
              options={allSwitches}
              onChangeOption={toSwitchAll}
              title={'All notifications'}
              isTogglesDisabled={isTogglesDisabled}
            />
            {NOTIFICATIONS_GROUPS.map((prop, index) => (
              <NotificationsOption.desktop
                options={
                  //@ts-ignore
                  notificationsList[prop.notificationKeysIncluded[0]]
                }
                //@ts-ignore
                onChangeOption={toSwitchOne(prop.notificationKeysIncluded[0])}
                title={prop.title}
                topDivider={!index}
                key={prop.title}
                isTogglesDisabled={isTogglesDisabled}
              />
            ))}
          </View>
        </ScrollView>
      </SideBarTemplate>
      <ConfirmationModal
        variant={
          webNotificationConfirmationModalVisible
            ? 'confirmBack'
            : currentModalVariant
        }
        isModalOpen={
          isDesktopModalOpen || webNotificationConfirmationModalVisible || false
        }
        setIsConfirmationModalOpen={setNotificationModalVisible}
        confirmHandler={confirmHandler}
      />
    </>
  );
};

const NotificationsView = {
  mobile,
  desktop,
};

export default NotificationsView;
