import * as yup from 'yup';
import { accountOwnerType } from '@store/features/externalBankAccounts/types';

const requiredErrorMessage = 'This field is required';

export interface IConnectAccountFormInputs {
  accountNumber: string;
  routingNumber: string;
  bankName: string;
  achNick: string;
  accountType: string;
  accountOwnership: accountOwnerType;
}

const regexForFirstAndLastNameToBeAtLeastTwoCharLong =
  /^[a-zA-Z]{2,}\s+[a-zA-Z]{2,}$/;

export const ConnectAccountSchema = yup.object().shape({
  accountNumber: yup
    .string()
    .required(requiredErrorMessage)
    .min(8, 'Should contain at least 8 digits')
    .max(17, 'Should contain no more than 17 digits'),
  routingNumber: yup
    .string()
    .required(requiredErrorMessage)
    .matches(/^\d{9}$/, 'Should contain only 9 digits'),
  bankName: yup
    .string()
    .required(requiredErrorMessage)
    .max(255, 'Should contain no more than 255 digits'),
  achNick: yup
    .string()
    .required(requiredErrorMessage)
    .max(22, 'Should contain no more than 22 alpha numeric symbols')
    .matches(
      regexForFirstAndLastNameToBeAtLeastTwoCharLong,
      'Include at least 2 characters in 2 words'
    ),
  accountType: yup.string().required(requiredErrorMessage),
  accountOwnership: yup
    .string()
    .oneOf(['SELF', 'OTHER'])
    .required(requiredErrorMessage),
});
