import { BetweenPaychexPayProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import BetweenPaychexPayView from '@views/Transfers/BetweenPaychexPayView';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSavingsAccountPreviewInfo,
  selectSpendingAccountPreviewInfo,
} from '@store/features/cards/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import { useAppDispatch } from '@store/hooks';
import { Platform } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { tracker } from '../../../eventCollector';
import { application, operations } from '@constants/traceability';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

const availableTransfersFromSavingNumber = 6;

const BetweenPaychexPayPage = ({ navigation }: BetweenPaychexPayProps) => {
  const isFocused = useIsFocused();

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'Transfers' });
    } else {
      navigation.goBack();
    }
  };
  const navigateToAmount = () => {
    navigation.navigate('HomeNavigator', {
      screen: 'TransferAmount',
      params: { fromAccountId: fromAccount.id, toAccountId: toAccount.id },
    });
  };

  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const savingsAccount = useSelector(selectSavingsAccountPreviewInfo);

  const [isSwitched, setIsSwitched] = useState(false);
  const onPressSwitch = () => {
    setIsSwitched(!isSwitched);
    setIsInsufficientFundsAlertShown(false);
  };

  const [fromAccount, toAccount] = useMemo(
    () =>
      isSwitched
        ? [savingsAccount, spendingAccount]
        : [spendingAccount, savingsAccount],
    [isSwitched, savingsAccount, spendingAccount]
  );

  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingsAccountId = useSelector(selectSavingsAccountId);
  useEffect(() => {
    if (savingsAccountId && spendingAccountId) {
      // "Account to Account Transfer" CT
      const spendingTimer = tracker.start(
        'Between Paychex Pay Accounts: Fetch Spending Account'
      );
      const savingsTimer = tracker.start(
        'Between Paychex Pay Accounts: Fetch Savings Account'
      );
      const txid = tracker.uuid();
      const spendingBizpn =
        application.bizpnPrefix + operations.SpendingAccount;
      const savingsBizpn = application.bizpnPrefix + operations.SavingsAccount;
      const spendingComp = application.compPrefix + operations.SpendingAccount;
      const savingsComp = application.compPrefix + operations.SavingsAccount;

      dispatch(
        cardsThunks.getCardInfo({
          cardId: spendingAccountId,
          additionalHeaders: {
            'x-payx-txid': txid || tracker.uuid(),
            'x-payx-bizpn': spendingBizpn,
            'x-payx-comp': spendingComp,
            'x-payx-subtxnbr': '1',
          },
        })
      )
        .unwrap()
        .then(() => {
          spendingTimer({
            txid: txid,
            bizpn: spendingBizpn,
            subtxnbr: '1',
          });
        })
        .catch((err) => handleRequestError(err));
      dispatch(
        cardsThunks.getCardInfo({
          cardId: savingsAccountId,
          additionalHeaders: {
            'x-payx-txid': txid || tracker.uuid(),
            'x-payx-bizpn': savingsBizpn,
            'x-payx-comp': savingsComp,
            'x-payx-subtxnbr': '2',
          },
        })
      )
        .unwrap()
        .then(() => {
          savingsTimer({
            txid: txid,
            bizpn: savingsBizpn,
            subtxnbr: '2',
          });
        })
        .catch((err) => handleRequestError(err));
    }
  }, [savingsAccountId, spendingAccountId]);

  const [isInsufficientFundsAlertVisible, setIsInsufficientFundsAlertVisible] =
    useState(false);

  const [isInsufficientFundsAlertShown, setIsInsufficientFundsAlertShown] =
    useState(false);

  useEffect(() => {
    if (
      isFocused &&
      fromAccount.amount === 0 &&
      !isInsufficientFundsAlertShown &&
      savingsAccount.id &&
      spendingAccount.id
    ) {
      setIsInsufficientFundsAlertVisible(true);
      setIsInsufficientFundsAlertShown(true);
    }
  }, [
    isFocused,
    fromAccount,
    isInsufficientFundsAlertShown,
    savingsAccount.id,
    spendingAccount.id,
  ]);

  const hideInsufficientFundsAlertModal = () => {
    setIsInsufficientFundsAlertVisible(false);
  };

  useEffect(() => {
    if (!isFocused) {
      setIsInsufficientFundsAlertShown(false);
    }
  }, [isFocused]);

  return (
    <BetweenPaychexPayView
      navigateBack={navigateBack}
      navigateToAmount={navigateToAmount}
      onPressSwitch={onPressSwitch}
      fromAccount={fromAccount}
      toAccount={toAccount}
      availableTransfersFromSavingNumber={availableTransfersFromSavingNumber}
      isSwitched={isSwitched}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    />
  );
};

export default BetweenPaychexPayPage;
