import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const errorStatesSelector = (state: RootState) => state.errorStates;

export const selectErrorState = createSelector(
  errorStatesSelector,
  (state) => ({
    isError: state.isError,
    displayType: state.displayType,
  })
);

export const selectErrorStatesPage = createSelector(
  errorStatesSelector,
  (state) => state.error.page
);

export const selectErrorStatesRedirect = createSelector(
  errorStatesSelector,
  (state) => state.error.redirect
);

export const selectErrorStateToast = createSelector(
  errorStatesSelector,
  (state) => state.error.toast
);

export const selectErrorsStateTransfers = createSelector(
  errorStatesSelector,
  (state) => state.error.transfer
);
