import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { customTheme } from '../../../styles/customTheme';
import { View, XStack } from 'tamagui';

const FillInDataSkeleton = () => {
  return (
    <SkeletonTheme
      baseColor={customTheme.colors.blue['20']}
      highlightColor={customTheme.colors.blue['10']}
    >
      <XStack pb={4}>
        <View width="50%" pr={2}>
          <Skeleton
            height={12}
            width="50%"
            borderRadius={12}
            style={{
              marginBottom: 8,
              marginTop: 4,
            }}
          />
          <Skeleton height={48} borderRadius={8} />
        </View>
        <View width="50%" pl={2}>
          <Skeleton
            height={12}
            width="50%"
            borderRadius={12}
            style={{
              marginBottom: 8,
              marginTop: 4,
            }}
          />
          <Skeleton height={48} borderRadius={8} />
        </View>
      </XStack>
      <Skeleton
        height={12}
        width="25%"
        borderRadius={12}
        style={{
          marginBottom: 8,
          marginTop: 4,
        }}
      />
      <Skeleton height={48} borderRadius={8} />
      <Skeleton
        height={12}
        width="25%"
        borderRadius={12}
        style={{
          marginBottom: 8,
          marginTop: 20,
        }}
      />
      <Skeleton height={48} borderRadius={8} />
    </SkeletonTheme>
  );
};

export default FillInDataSkeleton;
