import { InAppBrowser } from 'react-native-inappbrowser-reborn';
import { Alert, Linking } from 'react-native';

export const openLinkInAppBrowser = async (url: string) => {
  const encodedURI = encodeURI(url);
  try {
    if (await InAppBrowser.isAvailable()) {
      await InAppBrowser.open(encodedURI, {
        // iOS Properties
        dismissButtonStyle: 'done',
        preferredBarTintColor: '#0059A2',
        preferredControlTintColor: 'white',
        readerMode: false,
        animated: true,
        modalPresentationStyle: 'pageSheet',
        modalTransitionStyle: 'coverVertical',
        modalEnabled: true,
        enableBarCollapsing: false,
        // Android Properties
        showTitle: true,
        toolbarColor: '#0059A2',
        secondaryToolbarColor: 'black',
        navigationBarColor: 'black',
        navigationBarDividerColor: 'white',
        enableUrlBarHiding: true,
        enableDefaultShare: true,
        forceCloseOnRedirection: false,
      });
    } else Linking.openURL(encodedURI);
  } catch (error) {
    // @ts-ignore
    Alert.alert(error?.message ?? 'Error opening link');
  }
};

export default openLinkInAppBrowser;
