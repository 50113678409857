import { IExternalAccountsState } from '@store/features/externalBankAccounts/types';
import { createSlice } from '@reduxjs/toolkit';
import externalBankAccountsThunks from '@store/features/externalBankAccounts/asyncThunks';

const initialState = {
  externalAccounts: [],
  connectAccountStatus: null,
} as IExternalAccountsState;

const externalBankAccountsSlice = createSlice({
  name: 'externalBankAccounts',
  initialState,
  reducers: {
    resetConnectStatus(state) {
      state.connectAccountStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      externalBankAccountsThunks.getExternalAccounts.fulfilled,
      (state, { payload }) => {
        state.externalAccounts = payload;
      }
    );
    builder.addCase(
      externalBankAccountsThunks.postExternalAccount.fulfilled,
      (state) => {
        state.connectAccountStatus = 'success';
      }
    );
    builder.addCase(
      externalBankAccountsThunks.postExternalAccount.rejected,
      (state) => {
        state.connectAccountStatus = 'error';
      }
    );
    builder.addCase(
      externalBankAccountsThunks.postExternalAccount.pending,
      (state) => {
        state.connectAccountStatus = 'pending';
      }
    );
  },
});

export const {
  reducer: externalBankAccountReducer,
  actions: externalBankAccountAccounts,
} = externalBankAccountsSlice;
