import { StackProps, View } from 'tamagui';

interface LineProps extends StackProps {
  vertical?: boolean;
  color?: string;
}

const Line = ({ vertical = false, color = '$gray20', ...props }: LineProps) => {
  return (
    <View
      w={vertical ? 1 : '100%'}
      h={vertical ? '100%' : 1}
      bg={color}
      {...props}
      accessibilityLabel="Line"
    />
  );
};

export default Line;
