import { createSlice } from '@reduxjs/toolkit';
import rewardsThunks from './asynkThunks';
interface rewardsState {
  rewardsUrl: string | null;
}

const initialState = {
  rewardsUrl: null,
} as rewardsState;

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    resetRewardsUrl(state) {
      state.rewardsUrl = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      rewardsThunks.getRewardsUrl.fulfilled,
      (state, { payload }) => {
        state.rewardsUrl = payload.data.secure_url;
      }
    );
  },
});

export const { reducer: rewardsStateReducer, actions: rewardsStateActions } =
  rewardsSlice;
