import { HeaderMobileTemplate } from '@templates';
import AboutDobContent from '@organisms/AboutDobContent/AboutDobContent';

interface AboutDobViewProps {
  onPressRightButton: () => void;
}

const AboutDobView = ({ onPressRightButton }: AboutDobViewProps) => {
  return (
    <HeaderMobileTemplate
      header={{
        title: 'Birth date info',
        isRightButton: true,
        onPressRightButton,
      }}
    >
      <AboutDobContent />
    </HeaderMobileTemplate>
  );
};

export default AboutDobView;
