import { FooterButtonsRowTypes } from '@molecules/FooterButtonsRow/FooterButtonsRow.types';
import React from 'react';
import { Loader, TButton } from '@atoms';
import { View } from 'tamagui';

const FooterButtonsRow = ({
  firstButton,
  secondButton,
  isLoading = false,
}: FooterButtonsRowTypes) => {
  return (
    <View flexGrow={1} flexDirection="row">
      <View pr={'$2'} width="50%">
        <TButton
          //@ts-ignore
          variant={firstButton.variant || 'white'}
          onPress={firstButton.handler}
          disabled={firstButton.isDisabled || isLoading}
          testID={firstButton.testID}
          accessible
          accessibilityLabel={`${firstButton.label}`}
        >
          {firstButton.label}
        </TButton>
      </View>
      <View pl={'$2'} width="50%">
        <TButton
          //@ts-ignore
          variant={secondButton.variant || 'primary'}
          onPress={secondButton.handler}
          disabled={secondButton.isDisabled || isLoading}
          testID={secondButton.testID}
          accessible
          accessibilityLabel={`${secondButton.label}`}
        >
          {!isLoading ? secondButton.label : <Loader width={24} height={24} />}
        </TButton>
      </View>
    </View>
  );
};

export default FooterButtonsRow;
