import { supportInfo } from '@constants/support';

const faq = [
  {
    title: 'Why do I need to use Paychex Flex credentials to login?',
    text:
      'Using Paychex Flex login credentials allows workers of Paychex clients to have one set of credentials for logging into both Paychex Flex and the Paychex Pay Deposit Account. Whether you access your Paychex Pay Account through Paychex Flex or directly in the app*, you will use the same login credentials.\n' +
      '#\n' +
      '#caption_ *Standard data rates from your wireless service provider may apply.',
  },
  {
    title:
      'What happens if I leave my current employer or my employer leaves Paychex?',
    text: 'The Paychex Pay Account is your bank account that you keep even if you leave your current employer or if your employer no longer uses Paychex services.',
  },
  {
    title: 'Can my employer see the transactions in my Paychex Pay Account?',
    text: 'No. Your transactions and account details are private, and your employer does not have access to that information. If you direct deposit your payroll to your Paychex Pay Account, your employer will see your account number.',
  },
  {
    title: 'Does my Paychex Pay account come with a debit card?',
    text: 'Yes, you’ll receive a Paychex Pay Debit Mastercard® when you open an account.',
  },
  {
    title: 'Can I get a secondary card for my spouse or family members?',
    text: 'No, we do not offer additional cards for your account. If your family member works for an employer using Paychex, they can sign up for their own Paychex Pay Deposit Account.',
  },
  {
    title:
      'I see that I can connect other accounts. What is the benefit of doing that?',
    text: 'Paychex Pay allows you to easily transfer funds between your accounts as needed.',
  },
  {
    title: 'How can I access\\spend funds in my Paychex Pay account?',
    text:
      'The Paychex Pay account is very flexible and offers many ways to access and spend your funds.\n' +
      '#\n' +
      '• Purchases – The Paychex Pay Card can be used everywhere Debit Mastercard is accepted to make purchases in-person or online. Add your card to mobile wallets like Apple Wallet® and Google WalletTM to make payments without your physical card.\n' +
      '#\n' +
      '#caption_ Apple, the Apple logo and Apple Wallet are trademarks of Apple Inc., registered in the U.S. and other countries. Google Wallet is a trademark of Google LLC.\n' +
      '#\n' +
      '• Access to cash -\n' +
      '1. MoneyPass® ATMs. Withdraw cash fee-free at any MoneyPass ATM. Use the ATM locator to find a MoneyPass ATM near you.\n' +
      '2. Non-MoneyPass ATMs. Withdraw cash at any non-MoneyPass ATM. However, please note that there is an ATM Withdrawal Fee of $%VARIABLE% and you may also be charged an additional fee by the Out-of-Network ATM operator or network.\n' +
      '3. Bank Teller Withdrawals. Go to a bank that displays the Mastercard acceptance mark and ask for a Bank Teller Withdrawal. Give the teller your Paychex Pay Card and photo ID and request the amount of funds you would like to withdraw. Paychex Pay does not charge a fee for Bank Teller Withdrawals. However, some banks may impose fees and additional limits.\n' +
      '#\n' +
      '• Bill payments – Paychex Pay enables you to schedule and automate bill payments.\n' +
      '1. Pay bills using the Paychex Pay app*. Simply snap a picture of the bill to be paid or set up a recurring payment as needed in the bill pay feature.\n' +
      '2. Provide your account and routing numbers to billers to debit your account for the amount due.\n' +
      '3. Billers may also allow you to pay bills using your Paychex Pay Card, but they may impose a transaction fee for card payments.\n' +
      '#\n' +
      '#caption_ *Standard data rates from your wireless service provider may apply.',
  },
  {
    title: 'Can I send money to friends or family?',
    text:
      'Funds can be transferred† from your Paychex Pay Account to an external bank account or to an eligible debit card. You can make a transfer in the Paychex Pay mobile app* by choosing Transfer from the bottom toolbar, or in the web app by choosing Transfer on the left menu.\n' +
      '#\n' +
      '#caption_ †Fees and limitations may apply. Consult your Deposit Account Agreement and Fee Schedule for complete details.\n' +
      '#caption_ *Standard data rates from your wireless service provider may apply.',
  },
  {
    title: 'How can I deposit money in my Paychex Pay Account?',
    text:
      '1. Direct deposit – Funds can be deposited to your account electronically by providing your account and routing numbers to your employer or benefits provider. You can also receive your tax refund via direct deposit.\n' +
      '2. Mobile deposit – Use the Deposit Checks feature found on the home screen to deposit checks with Ingo Money‡ by taking a picture with your phone camera.\n' +
      '3. Cash deposit – Visit a Mastercard rePower® retail location to deposit cash§ in your Paychex Pay Account. Use the locator to find a retail location near you.\n' +
      '4. Transfers – Funds can be transferred to your Paychex Pay Spending Account from an external account.\n' +
      '#\n' +
      '#caption_ ‡The Ingo Money service is provided by First Century Bank, N.A. and Ingo Money Inc., subject to the First Century Bank and Ingo Money Terms and Conditions and Privacy Policy available in the App. All checks are subject to approval prior to funding. Unapproved checks will not be funded to your Account. Approval review may take up to one hour. Fees and data rates may apply. Ingo Money, Inc. and First Century Bank, N.A., are not affiliated with Central Bank of Kansas City.\n' +
      '#\n' +
      '#caption_ §Service fee up to $5.95 applies. Deposit and account balance limits apply.',
  },
  {
    title: 'Does Paychex Pay charge fees?',
    text: 'Paychex Pay was designed to have minimal fees. There is no monthly fee or minimum balance required. Fees may be charged when you withdraw cash at a non-MoneyPass ATM or if you transfer money to an external debit card. See the https://fee-schedule for the complete list of fees and additional details.',
  },
  {
    title: 'What should I do if I lose my card or it is stolen?',
    text: `Freeze your Paychex Pay Card and contact Customer Service immediately at ${supportInfo.phone}.`,
  },
  {
    title: 'What do I do if my card has not arrived yet?',
    text: `You will receive your card within 7-10 business days. If it has been longer than 10 business days and your card still hasn’t arrived, please contact Customer Service at ${supportInfo.phone}.`,
  },
  {
    title: 'What should I do if I do not recognize a transaction?',
    text: `We recommend that you freeze your Paychex Pay Card and contact Customer Service immediately at ${supportInfo.phone}.`,
  },
  {
    title: 'How can I get additional assistance with my account?',
    text: `Contact Customer Service at ${supportInfo.phone} 24 hours a day, 7 days a week, in both English and Spanish.`,
  },
  {
    title: 'Can I get a Paychex Pay Savings Account?',
    text: 'The Paychex Pay Savings Account is an optional savings account for those who have a Paychex Pay Deposit Account. Funds may be deposited to your Savings Account by transferring funds from your Paychex Pay Deposit Account.',
  },
  {
    title: 'How do I earn cash back rewards?',
    text:
      'Cash back rewards** are earned when you make qualified purchases at participating merchants with your Paychex Pay Card. Cash back is automatically credited to your account.\n' +
      '#\n' +
      '#caption_ **Participating in Paychex Pay Rewards is optional, and you may opt-out at any time by calling 1-877-460-0036. Offers are based on your shopping habits. Cash back is earned by using your Paychex Pay Card for qualifying purchases and is credited to your account once the qualified purchase transaction is settled, depending upon the merchant. Central Bank of Kansas City is not affiliated with and does not endorse or sponsor the rewards program. For complete details, including the use of transaction data, see the Paychex Pay Rewards Terms of Service provided to you with the Deposit Account Agreement.',
  },
  {
    title: 'How secure is the Paychex Pay Account?',
    text:
      'Your Paychex Pay Account funds are FDIC-insured through Central Bank of Kansas City. Your Paychex Pay Card is protected by the Mastercard Zero Liability Protection Policy.¶\n' +
      '#\n' +
      '#caption_ ¶Mastercard won’t hold you responsible for “unauthorized transactions.” As a Mastercard cardholder, Zero Liability applies to your purchases made in the store, over the telephone, online, or via a mobile device and ATM transactions. As a cardholder, you will not be held responsible for unauthorized transactions if you have used reasonable care in protecting your card from loss or theft; and you promptly reported loss or theft to your financial institution. If you believe there has been unauthorized use of your account and you meet the conditions above, rest easy knowing you have the protection of Mastercard’s Zero Liability promise.\n' +
      '#\n' +
      `#caption_ The Paychex Pay Deposit Account is established by Central Bank of Kansas City, Member FDIC. The Paychex Pay Debit Mastercard is issued by Central Bank of Kansas City pursuant to a license by Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. Certain fees, terms, and conditions are associated with the approval, maintenance, and use of the Deposit Account and Card. If you have any questions regarding your Deposit Account or Card, you should consult your https://deposit-account-agreement and https://fee-schedule or contact us toll-free 24/7/365 at ${supportInfo.phone}.`,
  },
];

export default faq;
