import { createStackNavigator } from '@react-navigation/stack';
import { DEFAULT_SCREEN_OPTIONS } from '@constants/navigation';
import { MoreNavigatorParamsList } from './MoreNavigator.types';
import {
  AtmAndReloadPage,
  DigitalWalletPage,
  EditAddressPage,
  EditEmailPage,
  EditPhonePage,
  HelpPage,
  LegalAgreementPage,
  LegalPage,
  NotificationsPage,
  OrderNewCardRequestPage,
  OrderNewCardStatusPage,
  ProfileSettingPage,
  SendFeedbackPage,
} from '@pages/More';
import FaqPage from '../../pages/More/FaqPage';
import {
  AirportConciergePage,
  IdentityTheftPage,
  MastercardFeaturesPage,
  ZeroLiabilityPage,
} from '@pages/MastercardFeatures';
import routesToTitlesMap from '../routesToTitlesMap';

const Stack = createStackNavigator<MoreNavigatorParamsList>();

const MoreNavigator = () => {
  return (
    <Stack.Navigator screenOptions={{ ...DEFAULT_SCREEN_OPTIONS }}>
      <Stack.Screen
        name="ProfileSetting"
        component={ProfileSettingPage}
        options={{
          title: routesToTitlesMap.ProfileSetting,
        }}
      />
      <Stack.Screen
        name="Notifications"
        component={NotificationsPage}
        options={{
          title: routesToTitlesMap.Notifications,
        }}
      />
      <Stack.Screen
        name="Atm"
        component={AtmAndReloadPage}
        options={{
          title: routesToTitlesMap.Atm,
        }}
      />
      <Stack.Screen
        name="Legal"
        component={LegalPage}
        options={{
          title: routesToTitlesMap.Legal,
        }}
      />
      <Stack.Screen
        name="LegalAgreements"
        component={LegalAgreementPage}
        options={{
          title: routesToTitlesMap.LegalAgreements,
        }}
      />
      <Stack.Screen
        name="EditPhone"
        component={EditPhonePage}
        options={{
          title: routesToTitlesMap.EditPhone,
        }}
      />
      <Stack.Screen
        name="EditEmail"
        component={EditEmailPage}
        options={{
          title: routesToTitlesMap.EditEmail,
        }}
      />
      <Stack.Screen
        name="EditAddress"
        component={EditAddressPage}
        options={{
          title: routesToTitlesMap.EditAddress,
        }}
      />
      <Stack.Screen
        name="Help"
        component={HelpPage}
        options={{
          title: routesToTitlesMap.Help,
        }}
      />
      <Stack.Screen
        name="Faq"
        component={FaqPage}
        options={{
          title: routesToTitlesMap.Faq,
        }}
      />
      <Stack.Screen
        name="MastercardFeatures"
        component={MastercardFeaturesPage}
        options={{
          title: routesToTitlesMap.MastercardFeatures,
        }}
      />
      <Stack.Screen
        name="IdentityTheft"
        component={IdentityTheftPage}
        options={{
          title: routesToTitlesMap.IdentityTheft,
        }}
      />
      <Stack.Screen
        name="ZeroLiability"
        component={ZeroLiabilityPage}
        options={{
          title: routesToTitlesMap.ZeroLiability,
        }}
      />
      <Stack.Screen
        name="AirportConcierge"
        component={AirportConciergePage}
        options={{
          title: routesToTitlesMap.AirportConcierge,
        }}
      />
      <Stack.Screen
        name="DigitalWalletPage"
        component={DigitalWalletPage}
        options={{
          title: routesToTitlesMap.DigitalWalletPage,
        }}
      />
      <Stack.Screen
        name="OrderNewCardRequest"
        component={OrderNewCardRequestPage}
        options={{
          title: routesToTitlesMap.OrderNewCardRequest,
        }}
      />
      <Stack.Screen
        name="OrderNewCardStatus"
        component={OrderNewCardStatusPage}
        options={{
          title: routesToTitlesMap.OrderNewCardStatus,
        }}
      />
      <Stack.Screen
        name="SendFeedback"
        component={SendFeedbackPage}
        options={{
          title: routesToTitlesMap.SendFeedback,
        }}
      />
    </Stack.Navigator>
  );
};

export default MoreNavigator;
