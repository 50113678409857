import { Alert } from 'native-base';
import ErrorIcon from '../../../assets/smallError.svg';
import { View } from 'tamagui';
import { Text } from '@atoms';

interface ErrorToastWebProps {
  isOpen: boolean;
  title: string;
}

const ErrorToastWeb = ({ isOpen, title }: ErrorToastWebProps) => {
  return (
    <View
      width="100%"
      height={isOpen ? '45px' : 0}
      accessible
      accessibilityLabel={`Error ${title}`}
    >
      <Alert justifyContent="center" status="error" flexDirection="row">
        <ErrorIcon />
        <Text color="$error600" fontSize="$h4" paddingLeft="$2">
          {title}
        </Text>
      </Alert>
    </View>
  );
};

export default ErrorToastWeb;
