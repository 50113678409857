import { createTypedThunk } from '@utils/createTypedThunk';
import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';
import cardsAPI from '@store/features/cards/api';
import {
  ICardAuthInfo,
  ICardInfo,
  ICardInfoRequest,
  ICardStatusRequest,
  IChangePinRequest,
  IChangePinResponse,
} from '@store/features/cards/types';

const cardsThunks = {
  getCardInfo: createTypedThunk<ICardInfoRequest, ICardInfo>(
    'cards/getCardInfo',
    cardsAPI.getCardInfo
  ),
  getCardAuthInfo: createTypedThunk<ICardInfoRequest, ICardAuthInfo>(
    'cards/getCardAuthInfo',
    cardsAPI.getCardAuthInfo
  ),
  putCardStatus: createTypedThunk<ICardStatusRequest, ICardInfo>(
    'cards/putCardStatus',
    cardsAPI.putCardStatus
  ),
  createSavingAccount: createCommonAsyncThunk(
    'cards/createSavingAccount',
    cardsAPI.createSavingAccount
  ),
  putActivePhysicalCard: createTypedThunk<ICardInfoRequest, ICardInfo>(
    'cards/putActivePhysicalCard',
    cardsAPI.putActivePhysicalCard
  ),
  changePin: createTypedThunk<IChangePinRequest, IChangePinResponse>(
    'cards/changePin',
    cardsAPI.changePin
  ),
  postOrderNewCard: createTypedThunk<ICardInfoRequest, ICardInfo>(
    'cards/postOrderNewCard',
    cardsAPI.postOrderNewCard
  ),
};

export default cardsThunks;
