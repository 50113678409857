import secureLocalStorage from 'react-secure-storage';

const getAccessToken = async () => {
  const result = secureLocalStorage.getItem('accessToken');
  if (result) {
    return result;
  } else {
    return false;
  }
};

const saveAccessToken = (accessToken: string) => {
  secureLocalStorage.setItem('accessToken', accessToken);
};

// methods to save, get and remove tracking log events that occur before login
// these exist to save those logs into local storage and retrieve/send them over after login
const savePayload = (payload: any) => {
  secureLocalStorage.setItem('payload', payload);
};

const getPayload = () => {
  const result = secureLocalStorage.getItem('payload');
  if (result) {
    return result;
  } else {
    return false;
  }
};

const removePayload = () => {
  secureLocalStorage.removeItem('payload');
};

export {
  getAccessToken,
  saveAccessToken,
  savePayload,
  getPayload,
  removePayload,
};
