import { Control, Controller, useForm } from 'react-hook-form';
import { IDeleteAccountFormInputs } from '@organisms/DeleteAccountForm/helper';
import { TextInput } from '@molecules';
import { TPressable, Text } from '@atoms';
import React, { useState } from 'react';
import EyeShowIcon from '../../../assets/eyeShow.svg';
import EyeHideIcon from '../../../assets/eyeHide.svg';
import { View, XStack } from 'tamagui';

interface DeleteAccountFormProps {
  control?: Control<IDeleteAccountFormInputs>;
}

const DeleteAccountForm = ({
  control: controlProp,
}: DeleteAccountFormProps) => {
  const { control } = useForm<IDeleteAccountFormInputs>();
  const [isAccountNumberVisible, setIsAccountNumberVisible] = useState(false);
  const [isRoutingNumberVisible, setIsRoutingNumberVisible] = useState(false);
  const [isAccountNicknameVisible, setIsAccountNicknameVisible] =
    useState(false);
  const accountHideHandler = () => {
    setIsAccountNumberVisible(!isAccountNumberVisible);
  };
  const routingHideHandler = () => {
    setIsRoutingNumberVisible(!isRoutingNumberVisible);
  };
  const accountNicknameHideHandler = () => {
    setIsAccountNicknameVisible(!isAccountNicknameVisible);
  };
  return (
    <View accessible={false} accessibilityLabel="Delete Account Form">
      <Controller
        name="accountNumber"
        control={controlProp || control}
        render={({ field: { value } }) => (
          <TextInput
            label="Account number"
            styles={{ marginBottom: 18 }}
            args={{
              value,
              testID: 'paychexpay.deleteAccountForm.input.accountNumberInput',
              secureTextEntry: !isAccountNumberVisible,
              accessible: true,
              accessibilityLabel: 'Enter account number',
            }}
            isDisabled
            InputRightElement={
              <TPressable
                onPress={accountHideHandler}
                p={'$3'}
                accessible
                accessibilityLabel={`${
                  isAccountNumberVisible
                    ? 'Hide account number button'
                    : 'Show account number button'
                }`}
                testID="paychexpay.deleteAccountForm.eyeIconAccountNumber"
              >
                {isAccountNumberVisible ? <EyeShowIcon /> : <EyeHideIcon />}
              </TPressable>
            }
          />
        )}
      />
      <Controller
        name="routingNumber"
        control={controlProp || control}
        render={({ field: { value } }) => (
          <TextInput
            label="Routing number"
            styles={{ marginBottom: 18 }}
            args={{
              value,
              testID: 'paychexpay.deleteAccountForm.input.routingNumberInput',
              secureTextEntry: !isRoutingNumberVisible,
              accessible: true,
              accessibilityLabel: 'Enter routing number',
            }}
            isDisabled
            InputRightElement={
              <TPressable
                onPress={routingHideHandler}
                p={'$3'}
                accessible
                accessibilityLabel={`${
                  isRoutingNumberVisible
                    ? 'Hide routing number button'
                    : 'Show routing number button'
                }`}
                testID="paychexpay.deleteAccountForm.eyeIconRoutingNumber"
              >
                {isRoutingNumberVisible ? <EyeShowIcon /> : <EyeHideIcon />}
              </TPressable>
            }
          />
        )}
      />
      <Controller
        name="bankName"
        control={controlProp || control}
        render={({ field: { value } }) => (
          <TextInput
            label="Bank name"
            styles={{ marginBottom: 18 }}
            args={{
              value,
              testID: 'paychexpay.deleteAccountForm.input.bankNameInput',
              accessible: true,
              accessibilityLabel: 'Enter bank name',
            }}
            isDisabled
          />
        )}
      />
      <Controller
        name="accountType"
        control={controlProp || control}
        render={({ field: { value } }) => (
          <View mb={'$4'}>
            <Text color="$gray40" lineHeight="$h2" fontSize="$h5" mb={1}>
              Account type
            </Text>
            <TPressable borderRadius={8} bg="#EFEFEF" px={12} py={13} disabled>
              <XStack justifyContent="space-between">
                <Text
                  fontSize="$body"
                  color={'$gray40'}
                  textTransform={'capitalize'}
                >
                  {value ? value : 'Unknown'}
                </Text>
              </XStack>
            </TPressable>
          </View>
        )}
      />
      <Controller
        name="accountOwnership"
        control={controlProp || control}
        render={({ field: { value } }) => (
          <View mb={'$4'}>
            <Text color="$gray40" lineHeight="$h2" fontSize="$h5" mb={1}>
              Account Ownership
            </Text>
            <TPressable borderRadius={8} bg="#EFEFEF" px={12} py={13} disabled>
              <XStack justifyContent="space-between">
                <Text fontSize="$body" color={'$gray40'}>
                  {value
                    ? value.charAt(0) + value.toLowerCase().slice(1)
                    : 'Unknown'}
                </Text>
              </XStack>
            </TPressable>
          </View>
        )}
      />
      <Controller
        name="achNick"
        control={controlProp || control}
        render={({ field: { value } }) => (
          <TextInput
            label="Account nickname"
            styles={{ marginBottom: 28 }}
            args={{
              value,
              keyboardType: 'default',
              testID: 'paychexpay.deleteAccountForm.input.accountNameInput',
              secureTextEntry: !isAccountNicknameVisible,
              accessible: true,
              accessibilityLabel: 'Enter account nickname',
            }}
            isDisabled
            InputRightElement={
              <TPressable
                onPress={accountNicknameHideHandler}
                p={'$3'}
                accessible
                accessibilityLabel={`${
                  isAccountNicknameVisible
                    ? 'Hide account nickname button'
                    : 'Show account nickname button'
                }`}
                testID="paychexpay.deleteAccountForm.eyeIconAccountNickname"
              >
                {isAccountNicknameVisible ? <EyeShowIcon /> : <EyeHideIcon />}
              </TPressable>
            }
          />
        )}
      />
    </View>
  );
};

export default DeleteAccountForm;
