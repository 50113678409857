import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  IAdditionalCardholderInfoUpdateRequest,
  ICardholder,
  ICardholderAdditionalInfoResponse,
  ICardholderRequestBody,
  ICardholderUpdateRequest,
} from '@store/features/cardholder/types';
import { ICreateCardholderResponse } from '@store/features/oowQuestions/types';
import { application, operations } from '@constants/traceability';

const cardholderAPI = {
  postCreateCardholder(body: ICardholderRequestBody) {
    return wrappedAxiosRequest<
      ICardholderRequestBody,
      ICreateCardholderResponse
    >({
      method: 'post',
      url: endpoints.cardholder,
      data: body,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.CreateCardholder,
        'x-payx-comp': application.compPrefix + operations.CreateCardholder,
      },
    });
  },
  getCardholder() {
    return wrappedAxiosRequest<null, ICardholder>({
      method: 'get',
      url: endpoints.cardholder,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GetCardholder,
        'x-payx-comp': application.compPrefix + operations.GetCardholder,
      },
    });
  },
  deleteCardholder() {
    return wrappedAxiosRequest<null, ICardholder>({
      method: 'delete',
      url: endpoints.cardholder,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.DeleteCardholder,
        'x-payx-comp': application.compPrefix + operations.DeleteCardholder,
      },
    });
  },
  getAdditionalCardholderInfo() {
    return wrappedAxiosRequest<null, ICardholderAdditionalInfoResponse>({
      method: 'get',
      url: endpoints.additionalCardholderInfo,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.GetAdditionalCardholderInfo,
        'x-payx-comp':
          application.compPrefix + operations.GetAdditionalCardholderInfo,
      },
    });
  },
  updateAdditionalCardholderInfo(body: IAdditionalCardholderInfoUpdateRequest) {
    return wrappedAxiosRequest<
      IAdditionalCardholderInfoUpdateRequest,
      ICardholderAdditionalInfoResponse
    >({
      method: 'post',
      url: endpoints.additionalCardholderInfo,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.UpdateAdditionalCardholderInfo,
        'x-payx-comp':
          application.compPrefix + operations.UpdateAdditionalCardholderInfo,
      },
      data: body,
    });
  },
  updateCardholderInfo(body: ICardholderUpdateRequest) {
    return wrappedAxiosRequest<ICardholderUpdateRequest, ICardholder>({
      method: 'put',
      url: endpoints.cardholder,
      data: body,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.UpdateCardholderInfo,
        'x-payx-comp': application.compPrefix + operations.UpdateCardholderInfo,
      },
    });
  },
};

export default cardholderAPI;
