import React from 'react';
import { HeaderMobileTemplate } from '@templates';
import { Box, Center, Spinner } from 'native-base';

interface CashBackViewProps {
  navigateBack: () => void;
}

const CashBackView = ({ navigateBack }: CashBackViewProps) => {
  return (
    <HeaderMobileTemplate
      header={{
        isBackButton: true,
        onPressBack: navigateBack,
        title: 'Cash back',
      }}
    >
      <Box bgColor="white" safeAreaBottom={3} flexGrow={1}>
        <Center flex={1}>
          <Spinner color={'primary.normal'} />
        </Center>
      </Box>
    </HeaderMobileTemplate>
  );
};

export default CashBackView;
