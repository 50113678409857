import TransferIcon from '../../../assets/homepage/transfer.svg';
import BillPayIcon from '../../../assets/homepage/billPay.svg';
import { TButton } from '@atoms';
import { View, XStack } from 'tamagui';

export interface CTAsButtonsProps {
  transferHandler: () => void;
  billPayHandler: () => void;
  isPayBillButtonDisabled: boolean;
  isRedirectToPayBillsLoading: boolean;
}

const CTAsButtons = ({
  transferHandler,
  billPayHandler,
  isPayBillButtonDisabled,
  isRedirectToPayBillsLoading,
}: CTAsButtonsProps) => {
  const TransferLeftIcon = () => {
    return <TransferIcon fill={'#B2BAC5'} />;
  };
  const BillPayLeftIcon = () => {
    return <BillPayIcon />;
  };

  return (
    <XStack>
      <View w="50%" pr="$2">
        <TButton
          variant="white"
          onPress={transferHandler}
          w="100%"
          testID="paychexpay.CTAsButtons.button.transferButton"
          icon={<TransferLeftIcon />}
          accessible
          accessibilityLabel="Transfer button"
        >
          Transfer
        </TButton>
      </View>
      <View w="50%" pl="$2">
        <TButton
          variant="white"
          onPress={billPayHandler}
          w="100%"
          testID="paychexpay.CTAsButtons.button.billPayButton"
          icon={<BillPayLeftIcon />}
          loaderIconColor={'$primaryNormal'}
          disabled={isPayBillButtonDisabled || isRedirectToPayBillsLoading}
          isLoading={isRedirectToPayBillsLoading}
          accessible
          accessibilityLabel="Pay bills button"
        >
          Pay bills
        </TButton>
      </View>
    </XStack>
  );
};

export default CTAsButtons;
