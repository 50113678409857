import { Text } from '@atoms';
import React from 'react';
import { customTheme } from '../../../styles/customTheme';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import RightArrow from '../../../assets/homepage/rightArrow.svg';
import useIsPhone from '@hooks/useIsPhone';
import { Separator, View, XStack, YStack } from 'tamagui';

const AccountsWidgetSkeleton = () => {
  const isPhone = useIsPhone();
  return (
    <SkeletonTheme
      baseColor={customTheme.colors.blue['20']}
      highlightColor={customTheme.colors.blue['10']}
    >
      {isPhone ? (
        <YStack justifyContent="center">
          <YStack gap="$4">
            <XStack>
              <View display="flex" flexGrow={1} paddingRight={'$4'}>
                <Skeleton height={24} borderRadius={4} />
              </View>
              <View width="96px" paddingRight={'$4'}>
                <Skeleton height={24} borderRadius={4} />
              </View>
              <RightArrow width="24px" />
            </XStack>
            <XStack marginTop={5}>
              <View display="flex" flexGrow={1} paddingRight={'$4'}>
                <Skeleton height={24} borderRadius={4} />
              </View>
              <View width="96px" paddingRight={'$4'}>
                <Skeleton height={24} borderRadius={4} />
              </View>
              <RightArrow width="24px" />
            </XStack>
          </YStack>

          <Separator borderColor={'$gray20'} marginVertical={16} />
          <View alignSelf="center">
            <Skeleton height={24} width={149} borderRadius={4} />
          </View>
        </YStack>
      ) : (
        <>
          <XStack justifyContent="space-between" pb="24px">
            <Text variant="title">My accounts</Text>
          </XStack>
          <XStack height={24}>
            <View display="flex" flexGrow={1} paddingRight={4}>
              <Skeleton height={24} borderRadius={4} />
            </View>
            <View width="96px" paddingRight={4}>
              <Skeleton height={24} borderRadius={4} />
            </View>
            <RightArrow width="24px" />
          </XStack>
          <Separator
            borderColor={'$gray20'}
            marginVertical={16}
            borderWidth={0.5}
          />

          <XStack height={24}>
            <View display="flex" flexGrow={1} paddingRight={4}>
              <Skeleton height={24} borderRadius={4} />
            </View>
            <View display="flex" width="96px" paddingRight={4}>
              <Skeleton height={24} borderRadius={4} />
            </View>
            <RightArrow width="24px" />
          </XStack>
        </>
      )}
    </SkeletonTheme>
  );
};

export default AccountsWidgetSkeleton;
