import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CreateCardholderLoaderPageProps } from '@navigation/OnboardingNavigator/OnboardingNavigator.types';
import { useAuth0 } from '@hooks/useCustomAuth0';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import CreateCardholderLoaderView from '../../../components/views/Onboarding/CreateCardholderLoaderView';
import {
  selectFillInDataFirst,
  selectFillInDataSecond,
} from '@store/features/fillInData/selectors';
import {
  selectCardholderCurrentStatus,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import cardsThunks from '@store/features/cards/asyncThunks';
import { selectSpendingCardPreviewInfo } from '@store/features/cards/selectors';
import notificationsThunks from '@store/features/notifications/asyncThunks';
import { selectNotificationsList } from '@store/features/notifications/selectors';
import { useAppDispatch } from '@store/hooks';
import { Platform } from 'react-native';

const CreateCardholderLoaderPage = ({
  navigation,
}: CreateCardholderLoaderPageProps) => {
  const dispatch = useAppDispatch();

  const {
    loginInfo,
    generateNewAccessToken,
    resetAccessToken,
    handleWebViewNavigationStateChange,
    isWebViewLoading,
    onLogout,
  } = useAuth0();

  const userInfo = {
    ...useSelector(selectFillInDataFirst),
    ...useSelector(selectFillInDataSecond),
  };
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const cardholderCurrentStatus = useSelector(selectCardholderCurrentStatus);
  const spendingAccount = useSelector(selectSpendingCardPreviewInfo);
  const notificationsList = useSelector(selectNotificationsList);

  const timeout = useRef<ReturnType<typeof setInterval>>();

  const handleRequestError = async () => {
    await onLogout();
  };

  const createCardholder = () => {
    const cardholderRequestBody = {
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
      email: userInfo.emailAddress,
      phone_details: [
        {
          phone_type: 'MOBILE',
          country_dialing_code: 1,
          phone_number: userInfo.phoneNumber,
        },
      ],
      addresses: [
        {
          address_type: 'PRIMARY',
          address1: userInfo.addressLine1,
          address2: userInfo.addressLine2,
          city: userInfo.city,
          region: userInfo.state,
          postal_code: userInfo.zipCode,
          country: 'USA',
        },
      ],
    };
    dispatch(cardholderThunks.postCreateCardholder(cardholderRequestBody))
      .unwrap()
      .catch(handleRequestError)
      .finally(() => {
        timeout.current && clearTimeout(timeout.current);
      });
  };

  const getSpendingCardInfo = () => {
    if (spendingAccountId) {
      dispatch(
        cardsThunks.getCardInfo({
          cardId: spendingAccountId,
        })
      );
    }
  };

  useEffect(() => {
    createCardholder();

    timeout.current = setTimeout(() => {
      handleRequestError();
    }, 60000); // logout after 60 sec (for failure cases)

    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, []);

  useEffect(() => {
    if (cardholderCurrentStatus === 'oowQuestions') {
      navigation.reset({
        index: 0,
        // @ts-ignore
        routes: [{ name: 'CIPNavigator' }],
      });
    }
    if (cardholderCurrentStatus === 'denied') {
      navigation.reset({
        index: 0,
        routes: [
          {
            // @ts-ignore
            name: 'CIPNavigator',
            params: { screen: 'VerificationFailedPage' },
          },
        ],
      });
    }
    if (cardholderCurrentStatus === 'justCreated') {
      if (Platform.OS === 'web') {
        resetAccessToken();
      } else {
        generateNewAccessToken();
      }
    }
    if (cardholderCurrentStatus === 'withNewAccessToken') {
      dispatch(cardholderThunks.getCardholder());
      dispatch(cardholderThunks.getCardholderAdditionalInfo());
    }
    if (cardholderCurrentStatus === 'fetchedFirstTime') {
      getSpendingCardInfo();
      dispatch(notificationsThunks.postNotifications(notificationsList));
    }
  }, [cardholderCurrentStatus]);

  useEffect(() => {
    if (spendingAccount) {
      navigation.reset({
        index: 0,
        routes: [{ name: 'WelcomeToPaychexPayPage' }],
      });
    }
  }, [spendingAccount]);

  return (
    <MediaLayoutTemplate
      Desktop={CreateCardholderLoaderView.desktop}
      Mobile={CreateCardholderLoaderView.mobile}
      loginInfo={loginInfo}
      handleWebViewNavigationStateChange={handleWebViewNavigationStateChange}
      isWebViewLoading={isWebViewLoading}
    />
  );
};

export default CreateCardholderLoaderPage;
