import { useNavigation } from '@react-navigation/native';
import { AllowNotificationsView } from '@views/Onboarding';
import { RootNavigatorParamProps } from '@navigation/RootNavigator/RootNavigator.types';
import { useState } from 'react';
import notificationsThunks from '@store/features/notifications/asyncThunks';
import { useAppDispatch } from '@store/hooks';

const AllowNotificationsPage = () => {
  const navigation = useNavigation<RootNavigatorParamProps['navigation']>();
  const dispatch = useAppDispatch();

  const [isModalOpen, setIsConfirmationModalOpen] = useState(false);

  const goToSetupDdaOnboardingPage = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'OnboardingNavigator',
          params: { screen: 'SetupDdaOnboardingPage' },
        },
      ],
    });
  };

  const [notificationsSettings, setNotificationsSettings] = useState({
    mobilePushPaychexPay: false,
    text: false,
    email: false,
  });
  const mobilePushPaychexPayChange = (f: boolean) => {
    setNotificationsSettings({
      ...notificationsSettings,
      mobilePushPaychexPay: f,
    });
  };
  const textChange = (f: boolean) => {
    setNotificationsSettings({ ...notificationsSettings, text: f });
  };
  const emailChange = (f: boolean) => {
    setNotificationsSettings({ ...notificationsSettings, email: f });
  };

  const onDonePress = () => {
    dispatch(
      notificationsThunks.postNotifications({
        'paychexbank.card.status.unblocked': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.bank_transfer.return': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.status.closed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.status.fraud_blocked': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.pin.changed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.bank_transfer.success': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.status.blocked': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.auth.failed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.large_atm_withdrawal': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.large_purchase': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.low_balance': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.reward.payout_completed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
      })
    );
    setIsConfirmationModalOpen(false);
    goToSetupDdaOnboardingPage();
  };

  const showConfirmationModal = () => {
    if (notificationsSettings.text) {
      setIsConfirmationModalOpen(true);
    } else {
      onDonePress();
    }
  };

  return (
    <AllowNotificationsView
      onDonePress={showConfirmationModal}
      notificationsSettings={notificationsSettings}
      mobilePushPaychexPayChange={mobilePushPaychexPayChange}
      textChange={textChange}
      emailChange={emailChange}
      isModalOpen={isModalOpen}
      confirmHandler={onDonePress}
      setIsConfirmationModalOpen={setIsConfirmationModalOpen}
    />
  );
};

export default AllowNotificationsPage;
