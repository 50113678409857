export { default as ProfileSettingView } from './ProfileSettingView';
export { default as EditPhoneView } from './EditPhoneView';
export { default as EditEmailView } from './EditEmailView';
export { default as EditAddressView } from './EditAddressView';
export { default as HelpView } from './HelpView';
export { default as FaqView } from './FaqView';
export { default as DigitalWalletView } from './DigitalWalletView';
export { default as OrderNewCardRequestView } from './OrderNewCardRequestView';
export { default as OrderNewCardStatusView } from './OrderNewCardStatusView';
export { default as NotificationsView } from './NotificationsView';
export { default as SendFeedbackView } from './SendFeedbackView';
