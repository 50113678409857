import { MobileDepositView } from '@views/BottomTabs';
import { Platform } from 'react-native';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import homeWidgetsThunks from '@store/features/homeWidgets/asynkThunks';
import { useAppDispatch } from '@store/hooks';
import { NativeModules } from 'react-native';
import { selectIngoData } from '@store/features/homeWidgets/selectors';
import { widgetStateActions } from '@store/features/homeWidgets/slice';
import { BottomTabsNavigatorParamsProps } from '@navigation/BottomsTabsNavigator/BottomTabsNavigator.types';
import Toast from 'react-native-toast-message';
import { TOAST_POPUP_VISIBILITY_TIME } from '@constants/general';
const { IngoModule } = NativeModules;
import { getBrand, getDeviceId } from 'react-native-device-info';

const MobileDepositPage = () => {
  const dispatch = useAppDispatch();
  const ingoData = useSelector(selectIngoData);
  const navigation =
    useNavigation<BottomTabsNavigatorParamsProps['navigation']>();

  const navigateBack = () => {
    navigation.goBack();
  };

  const isFocused = useIsFocused();
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isFocused) {
      const deviceId = `${getBrand()} ${getDeviceId()}`;

      dispatch(widgetStateActions.resetIngoData());
      dispatch(
        homeWidgetsThunks.getIngoData({
          device_id: deviceId,
        })
      );
    } else {
      dispatch(widgetStateActions.resetIngoData());

      if (Platform.OS === 'ios') {
        IngoModule.lockToPortrait();
      } else {
        if (timeout.current) {
          clearTimeout(timeout.current);
          timeout.current = null;
        }
      }
    }
  }, [isFocused]);

  useEffect(() => {
    if (ingoData.vendor_auth_token) {
      if (Platform.OS === 'android') {
        IngoModule.openIngoModule(
          ingoData.vendor_auth_token,
          ingoData.vendor_customer_id,
          () => {
            //TODO: success callback executes straight away
            // Improve it to be executed only after Ingo opening

            timeout.current = setTimeout(() => {
              navigateBack();
            }, 10000);
          },

          (error: any) => {
            navigateBack();

            Toast.show({
              type: 'error',
              text1: 'Check deposit fails:',
              text2: error,
              visibilityTime: TOAST_POPUP_VISIBILITY_TIME * 2,
            });
          }
        );
        dispatch(widgetStateActions.resetIngoData());
      } else {
        IngoModule.openIngoModule(
          ingoData.vendor_auth_token,
          ingoData.vendor_customer_id,
          () => {
            navigateBack();
          },

          (error: any) => {
            navigateBack();

            Toast.show({
              type: 'error',
              text1: 'Check deposit fails:',
              text2: error,
              visibilityTime: TOAST_POPUP_VISIBILITY_TIME * 2,
            });
          }
        );
        dispatch(widgetStateActions.resetIngoData());
      }
    }
  }, [ingoData]);

  return <MobileDepositView />;
};

export default MobileDepositPage;
