import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  IAgreementsRequest,
  IAgreementsResponse,
  IAgreementsResponseById,
} from './types';
import { application, operations } from '@constants/traceability';

const agreementsApi = {
  getAgreementById({ document_id }: IAgreementsRequest) {
    return wrappedAxiosRequest<Record<string, never>, IAgreementsResponseById>({
      method: 'get',
      url: endpoints.getAgreementById.replace('{document_id}', document_id),
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GetAgreementById,
        'x-payx-comp': application.compPrefix + operations.GetAgreementById,
      },
    });
  },

  getAgreementSaving() {
    return wrappedAxiosRequest({
      method: 'get',
      url: endpoints.getAgreementSaving,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.GetSavingsAgreement,
        'x-payx-comp': application.compPrefix + operations.GetSavingsAgreement,
      },
    });
  },
  postAgreementAcknowledgement({ document_id }: IAgreementsRequest) {
    return wrappedAxiosRequest<Record<string, never>, IAgreementsResponse>({
      method: 'post',
      url: endpoints.postAgreementAcknowledgement.replace(
        '{document_id}',
        document_id
      ),
      data: {},
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.AgreementAcknowledgement,
        'x-payx-comp':
          application.compPrefix + operations.AgreementAcknowledgement,
      },
    });
  },
};

export default agreementsApi;
