import { modalsListType } from '@devTypes/sideModals';
import { View } from 'tamagui';

interface ComponentsListProviderProps {
  components: modalsListType;
  componentsName: string | undefined;
}

const ComponentsListProvider = ({
  components,
  componentsName,
}: ComponentsListProviderProps) => {
  return componentsName ? components[componentsName] : <View />;
};

export default ComponentsListProvider;
