import React from 'react';
import { AccountTemplate } from '@templates';
import AccountTransactions from '@organisms/AccountTransactions/AccountTransactions';
import AccountDetails from '@organisms/AccountDetails/AccountDetails';
import {
  AccountViewProps,
  SpendingAccountDesktopProps,
} from '@devTypes/accounts';
import AccountDesktopTemplate from '@templates/AccountDesktopTemplate/AccountDesktopTemplate';
import { useNavigation } from '@react-navigation/native';
import { HomeNavigatorParamsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { RootNavigatorParamProps } from '@navigation/RootNavigator/RootNavigator.types';
import { SideModal } from '@molecules';
import {
  DdaFormContent,
  DebitCardContent,
  OrderNewCardRequestContent,
  OrderNewCardStatusContent,
  TransactionDetailsContent,
  FreezeCardWebModal,
} from '@organisms';
import SetupDdaContent from '@organisms/SetupDdaContent/SetupDdaContent';
import ONBOARDING_SLIDES from '@constants/onboardingSlides';
import SUPPORT_PAGES from '@constants/support';
import SupportScreenContent from '@organisms/SupportScreenContent/SupportScreenContent';
import idToTransaction from '@utils/idToTransaction';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import { Button, Loader, Text } from '@atoms';
import BackButton from '@atoms/BackButton/BackButton';
import StatementItem from '@molecules/StatementItem/StatementItem';
import { dateStringToMonthYearLongFormat } from '@utils/dateHelpers';
import TCImage from '../../../assets/TCupdate.svg';
import { Separator, View, YStack } from 'tamagui';
import { Center } from 'native-base';

const mobile = ({
  navigateBack,
  account,
  transactions,
  filteredTransactions,
  transactionsSearchText,
  transactionsFilteredBy,
  menuItems,
  copyToClipboard,
  ToastPopup,
  navigation,
  filterType,
  loaderOverlay,
  setLoaderOverlay,
  isLoadingTransactions,
  onRefreshTransactionsList,
  isRefreshLoading,
}: AccountViewProps) => {
  return (
    <>
      {ToastPopup}
      <AccountTemplate
        withWebHeader
        navigateBack={navigateBack}
        accountName={account.name}
        accountAmount={account.amount}
        loaderOverlay={loaderOverlay}
        // @ts-ignore
        TransactionsElement={AccountTransactions}
        transactionsElementProps={{
          onRefreshTransactionsList,
          isRefreshLoading,
          transactions,
          filteredTransactions,
          transactionsSearchText,
          transactionsFilteredBy,
          navigation,
          filterType,
          setLoaderOverlay,
          isLoadingTransactions,
        }}
        // @ts-ignore
        AccountDetailsElement={AccountDetails}
        accountDetailsElementProps={{
          account,
          menuItems,
          copyToClipboard,
        }}
        navigation={navigation}
      />
    </>
  );
};

const desktop = ({
  navigateBack,
  account,
  transactions,
  filteredTransactions,
  transactionsSearchText,
  transactionsFilteredBy,
  cardholderAdditionalInfo,
  menuItems,
  copyToClipboard,
  ToastPopup,
  onOpenTransactionModal,
  transactionId,
  cardInfo,
  cardAuthInfo,
  cardImgSrc,
  debitCardMenuItems,
  debitCardState,
  learnMoreHandler,
  isBlockAnimated,
  isModalOpen,
  toCloseModal,
  modalName,
  toOpenModal,
  statementsList,
  onPdfIconPress,
  orderRequestStatus,
  requestCard,
  filterType,
  shipmentInfo,
  loaderOverlay,
  setLoaderOverlay,
  isFreezeModalOpen,
  setIsFreezeModalOpen,
  freezeCardHandler,
  unfreezeCardHandler,
  cardholderAdditionalInfoIsLoading,
  isLoadingTransactions,
}: SpendingAccountDesktopProps) => {
  const navigation = useNavigation<
    HomeNavigatorParamsProps['navigation'] &
      RootNavigatorParamProps['navigation']
  >();
  const onPressTransaction = (id: string) => {
    navigation.navigate('HomeNavigator', {
      screen: 'TransactionDetails',
      params: { transactionId: id },
    });
  };

  return (
    <>
      {ToastPopup}
      <FreezeCardWebModal
        state={debitCardState}
        isFreezeModalOpen={isFreezeModalOpen}
        setIsFreezeModalOpen={setIsFreezeModalOpen}
        freezeCardHandler={freezeCardHandler}
        unfreezeCardHandler={unfreezeCardHandler}
      />
      <AccountDesktopTemplate
        account={account}
        menuItems={menuItems}
        navigateBack={navigateBack}
        transactions={transactions}
        filteredTransactions={filteredTransactions}
        transactionsSearchText={transactionsSearchText}
        transactionsFilteredBy={transactionsFilteredBy}
        copyToClipboard={copyToClipboard}
        onPressTransaction={onPressTransaction}
        onOpenTransactionModal={onOpenTransactionModal}
        navigation={navigation}
        filterType={filterType}
        loaderOverlay={loaderOverlay}
        setLoaderOverlay={setLoaderOverlay}
        isLoadingTransactions={isLoadingTransactions}
      />
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.transaction]: (
              <View bg="$blue10" height="100vh">
                <View width="100%" h="76px" bg="$primaryNormal" mb={-28} />
                <TransactionDetailsContent
                  transaction={idToTransaction(
                    transactionId,
                    filteredTransactions
                  )}
                  isDesktop
                />
              </View>
            ),
            [sideModalNames.debitCard]: (
              <DebitCardContent.desktop
                cardInfo={cardInfo}
                cardAuthInfo={cardAuthInfo}
                cardImgSrc={cardImgSrc}
                state={debitCardState}
                menuItems={debitCardMenuItems}
                learnMoreHandler={learnMoreHandler}
                isBlockAnimated={isBlockAnimated}
              />
            ),
            [sideModalNames.blockedCard]: (
              <SupportScreenContent
                {...SUPPORT_PAGES.blockedCard}
                onPressBack={toOpenModal(sideModalNames.debitCard)}
              />
            ),
            [sideModalNames.cardLost]: (
              <SupportScreenContent
                {...SUPPORT_PAGES.cardLost}
                onPressBack={toOpenModal(sideModalNames.debitCard)}
              />
            ),
            [sideModalNames.disputeCharge]: (
              <SupportScreenContent {...SUPPORT_PAGES.disputeCharge} />
            ),
            [sideModalNames.closeAccount]: (
              <SupportScreenContent {...SUPPORT_PAGES.closeSpending} />
            ),
            [sideModalNames.setupDda]: (
              <View height={'100vh'}>
                <SetupDdaContent />
                <DdaFormContent.desktop
                  onPressBack={toOpenModal(sideModalNames.setupDda)}
                />
              </View>
            ),
            [sideModalNames.ddaHelp]: (
              <>
                <View position="relative">
                  <BackButton
                    onPress={toOpenModal(sideModalNames.setupDda)}
                    args={{
                      position: 'absolute',
                      left: '$7',
                      top: '$6',
                      zIndex: 1,
                    }}
                  />
                  <Center h="88px">
                    <Text variant="title">Setup Direct Deposit</Text>
                  </Center>
                  <Separator borderColor="$gray20" />
                </View>
                <View px="80px" pt={'$8'}>
                  <Text variant="subtitle" fontSize={11}>
                    {ONBOARDING_SLIDES.getPaidEarly.help}
                  </Text>
                </View>
              </>
            ),
            [sideModalNames.statementsList]: (
              <>
                <View py="$4">
                  <Text
                    fontWeight="600"
                    fontSize={16}
                    textAlign="center"
                    paddingVertical={6}
                  >
                    Spending statements
                  </Text>
                </View>
                <Separator borderColor="$gray20" />
                <View>
                  {statementsList.length > 0 ? (
                    <>
                      {statementsList.map((statement) => {
                        return (
                          <StatementItem
                            title={dateStringToMonthYearLongFormat(
                              statement.start_date
                            )}
                            statementId={statement.id}
                            key={statement.id}
                            onPdfIconPress={onPdfIconPress}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <View
                      display={'flex'}
                      flexDirection={'column'}
                      flexGrow={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                      pt={'$7'}
                    >
                      <TCImage />
                      <Text variant="title" pb={'$2'} mt={'$7'}>
                        No statements to display
                      </Text>
                      <Text variant="subtitle" pb={'$6'}>
                        Statements will appear here once they’re available.
                      </Text>
                    </View>
                  )}
                </View>
              </>
            ),
            [sideModalNames.orderCardRequest]: (
              <YStack justifyContent="space-between" flexGrow={1}>
                <View flexGrow={1}>
                  <View>
                    <BackButton
                      onPress={toOpenModal(sideModalNames.debitCard)}
                      args={{
                        position: 'absolute',
                        left: '$7',
                        top: '$6',
                        zIndex: 1,
                      }}
                    />
                    <Center h="88px">
                      <Text variant="title">Order a new card</Text>
                    </Center>
                    <Separator borderColor="$gray20" />
                  </View>
                  <View px={5} flexGrow={1}>
                    {cardholderAdditionalInfoIsLoading ? (
                      //TODO: replace with custom skeleton loader to improve UI/UX
                      <View
                        display={'flex'}
                        flexDirection={'column'}
                        flex={1}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Loader width={64} height={64} />
                      </View>
                    ) : (
                      <OrderNewCardRequestContent
                        allowCardReissue={
                          cardholderAdditionalInfo?.allow_card_reissue ?? true
                        }
                      />
                    )}
                  </View>
                </View>
                {!cardholderAdditionalInfoIsLoading &&
                  cardholderAdditionalInfo?.allow_card_reissue && (
                    <View p={7}>
                      <Button
                        onPress={requestCard}
                        isLoading={orderRequestStatus === 'pending'}
                        testID="paychexpay.desktop.spendingAccountView.button.orderNewCardButton"
                      >
                        Order card
                      </Button>
                    </View>
                  )}
              </YStack>
            ),
            [sideModalNames.orderCardStatus]: (
              <View justifyContent="space-between" flexGrow={1}>
                <View>
                  <Center h="88px">
                    <Text variant="title">Card shipping status</Text>
                  </Center>
                  <Separator borderColor="$gray20" />
                  <OrderNewCardStatusContent
                    orderStatus={
                      orderRequestStatus === 'error' ? 'error' : 'success'
                    }
                    copyToClipboard={copyToClipboard}
                    shipmentInfo={shipmentInfo}
                  />
                </View>
                <View p={7}>
                  <Button onPress={toOpenModal(sideModalNames.debitCard)}>
                    Got it
                  </Button>
                </View>
              </View>
            ),
          }}
        />
      </SideModal>
    </>
  );
};

const SpendingAccountView = {
  mobile,
  desktop,
};

export default SpendingAccountView;
