import { createStackNavigator } from '@react-navigation/stack';
import qs from 'qs';
import { ErrorPage } from '@pages/Common';
import CIPNavigator from '../CIPNavigator/CIPNavigator';
import AuthNavigator from '../AuthNavigator/AuthNavigator';
import { DEFAULT_SCREEN_OPTIONS } from '@constants/navigation';
import { RootNavigatorParamsList } from './RootNavigator.types';
import OnboardingNavigator from '../OnboardingNavigator/OnboardingNavigator';
import BottomTabsNavigator from '../BottomsTabsNavigator/BottomTabsNavigator';
import HomeNavigator from '../HomeNavigator/HomeNavigator';
import { TransferStatusPage } from '@pages/Transfers';
import MoreNavigator from '../MoreNavigator/MoreNavigator';
import {
  selectCurrentRouteTitle,
  selectIsAuthenticated,
  selectIsAuthorizedCardholder,
  selectIsNavigationHistory,
} from '@store/features/navigationStates/selectors';
import { useSelector } from 'react-redux';
import { selectCardholderCurrentStatus } from '@store/features/cardholder/selectors';
import { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { AuthNavigatorParamsProps } from '@navigation/AuthNavigator';
import {
  selectBiometricsType,
  selectIsTriedToEnableBiometric,
} from '@store/features/biometrics/selectors';
import { useAppDispatch } from '@store/hooks';
import { SplashScreenWeb } from '@atoms';
import { Linking, Platform } from 'react-native';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import linking from '../linking';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import routesToTitlesMap from '../routesToTitlesMap';
import { useMetaTags } from '@hooks/useMetaTags';
import { signal, tracker } from '../../eventCollector';
import { PaychexLoginPageSkipLanding } from '@pages/SignUp';
import { isExpiredToken } from '@utils/accessTokenRenewCheck';

//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

const Stack = createStackNavigator<RootNavigatorParamsList>();

const FetchedFirstTimeNavigator = () => (
  <>
    <Stack.Screen name="BottomTabsNavigator" component={BottomTabsNavigator} />
    <Stack.Screen
      name="HomeNavigator"
      component={HomeNavigator}
      options={{ gestureEnabled: true }}
    />
    <Stack.Screen
      name="TransferStatus"
      // @ts-expect-error
      component={TransferStatusPage}
      options={{
        title: routesToTitlesMap.TransferStatus,
        gestureEnabled: false,
      }}
    />
    <Stack.Screen name="MoreNavigator" component={MoreNavigator} />
  </>
);

const CIPAndOnboardingNavigators = () => (
  <>
    <Stack.Screen name="CIPNavigator" component={CIPNavigator} />
    <Stack.Screen name="OnboardingNavigator" component={OnboardingNavigator} />
    <Stack.Screen
      name="ErrorPage"
      component={ErrorPage}
      options={{
        title: routesToTitlesMap.ErrorPage,
      }}
    />
  </>
);

const RootNavigator = () => {
  const [loading, setLoading] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isAuthorizedCardholderSelector = useSelector(
    selectIsAuthorizedCardholder
  );
  const isNavigationHistory = useSelector(selectIsNavigationHistory);
  const currentRouteTitle = useSelector(selectCurrentRouteTitle);
  const cardholderCurrentStatus = useSelector(selectCardholderCurrentStatus);
  const dispatch = useAppDispatch();

  const navigation = useNavigation<AuthNavigatorParamsProps['navigation']>();
  const biometryType = useSelector(selectBiometricsType);
  const isTriedToEnableBiometric = useSelector(selectIsTriedToEnableBiometric);
  const landingPageTimer = tracker.start('Load Landing Page');

  const enableBiometricAfterGivingPermissions = () => {
    if (biometryType && isTriedToEnableBiometric) {
      navigation.reset({
        index: 0,
        routes: [
          {
            // @ts-ignore
            name: 'AuthNavigator',
            params: {
              screen: 'BiometricsPage',
              params: { nextScreen: 'HomePage' },
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (
      Platform.OS !== 'web' &&
      isAuthenticated &&
      cardholderCurrentStatus === 'fetchedFirstTime'
    ) {
      enableBiometricAfterGivingPermissions();
    }
  }, [isAuthenticated, cardholderCurrentStatus]);

  const verifyCardholder = async (
    successCallback: () => void,
    errorCallback: () => void
  ) => {
    const accessToken = await getAccessToken();
    if (isExpiredToken(accessToken)) {
      errorCallback();
    } else {
      dispatch(cardholderThunks.getCardholder())
        .unwrap()
        .then(() => successCallback())
        .catch(() => errorCallback());
    }
  };

  const handleUrlRedirection = async () => {
    if (Platform.OS === 'web') {
      const initialUrl = (await Linking.getInitialURL()) || '';
      const prefix =
        linking.prefixes.find((prefix: string) =>
          initialUrl.includes(prefix)
        ) || '';

      const baseRouteWithParams = initialUrl
        .replace(prefix, '')
        .replace(/^\//, '');

      let params = {};
      if (baseRouteWithParams.indexOf('?') > 0) {
        params = qs.parse(
          baseRouteWithParams.substring(baseRouteWithParams.indexOf('?') + 1)
        );
      }

      const baseRoute = baseRouteWithParams.substring(
        0,
        baseRouteWithParams.indexOf('?') > 0
          ? baseRouteWithParams.indexOf('?')
          : baseRouteWithParams.length
      );
      const isNotProperRoute =
        JSON.stringify(linking.config).indexOf(baseRoute) < 0;
      let foundScreen = '';
      let foundNavigator = '';
      Object.values(linking.config.screens).forEach(
        (item: any, index: number) => {
          if (typeof item === 'object') {
            const pageName = Object.keys(item.screens).find((value) => {
              return item.screens[value] === baseRoute;
            });
            if (pageName) {
              foundScreen = pageName;
              foundNavigator = Object.keys(linking.config.screens)[index];
            }
          }
        }
      );
      if (initialUrl.indexOf('?code') === -1) {
        setLoading(true);
        navigation.reset({
          index: 0,
          routes: [
            {
              //@ts-ignore
              name: 'SplashScreen',
            },
          ],
        });
        if (baseRoute === linking.config.screens.PaychexLoginPageSkipLanding) {
          if (isAuthorizedCardholderSelector) {
            navigation.reset({
              index: 0,
              routes: [
                {
                  // @ts-ignore
                  name: 'BottomTabsNavigator',
                },
              ],
            });
          } else {
            navigation.reset({
              index: 0,
              routes: [
                {
                  // @ts-ignore
                  name: 'PaychexLoginPageSkipLanding',
                },
              ],
            });
          }

          setLoading(false);
        } else {
          verifyCardholder(
            () => {
              dispatch(navigationStatesActions.setIsAuthorizedCardholder(true));
              dispatch(cardholderThunks.getCardholderAdditionalInfo());
              if (
                isNotProperRoute ||
                initialUrl === '' ||
                initialUrl === `${window.location.origin}/` // condition to handle reload
              ) {
                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      //@ts-ignore
                      name: 'BottomTabsNavigator',
                    },
                  ],
                });
              } else if (foundScreen) {
                setLoading(true);
                if (foundNavigator === 'AuthNavigator') {
                  dispatch(
                    navigationStatesActions.setIsAuthorizedCardholder(false)
                  );
                }

                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      // @ts-ignore
                      name: `${foundNavigator}`,
                      params: {
                        screen: `${foundScreen}`,
                        params,
                      },
                    },
                  ],
                });

                setLoading(false);
              }
            },
            () => {
              dispatch(
                navigationStatesActions.setIsAuthorizedCardholder(false)
              );
              setLoading(false);
              // if platform is web and is not a redirect back from auth0 & get cardholder fails - implies that the user
              // has not signed in and would be shown to the landing page.
              // End the landing page timer here to compute time taken to be sent to the BE endpoint later after login
              landingPageTimer({
                bizpn: 'Load Landing Page',
                txid: tracker.uuid(),
              });
              setTimeout(signal.set, 500);
            }
          );
        }
      } else {
        setLoading(true);
        verifyCardholder(
          () => {
            dispatch(navigationStatesActions.setIsAuthorizedCardholder(true));
            dispatch(cardholderThunks.getCardholderAdditionalInfo());
            setLoading(false);
          },
          () => {
            dispatch(navigationStatesActions.setIsAuthorizedCardholder(false));
            setLoading(false);
          }
        );
      }
    }
  };

  useEffect(() => {
    if (Platform.OS !== 'web') {
      dispatch(cardholderThunks.getCardholder())
        .unwrap()
        .then(() => {
          dispatch(navigationStatesActions.setIsAuthorizedCardholder(true));
          dispatch(cardholderThunks.getCardholderAdditionalInfo());
        })
        .catch(() => {
          // if platform is not web and get cardholder fails - implies that the user has not signed in and would be shown to the landing page
          // end the landing page timer here to compute time taken to be sent to the BE endpoint later after login
          landingPageTimer({
            bizpn: 'Load Landing Page',
            txid: tracker.uuid(),
          });
          setTimeout(signal.set, 500);
          dispatch(navigationStatesActions.setIsAuthorizedCardholder(false));
        });
    } else {
      handleUrlRedirection();
    }
  }, []);

  useMetaTags(
    {
      title: `${currentRouteTitle}`,
      openGraph: {
        title: `${currentRouteTitle}`,
      },
      twitter: {
        title: `${currentRouteTitle}`,
      },
    },
    [currentRouteTitle]
  );

  return (
    <Stack.Navigator
      initialRouteName="AuthNavigator"
      screenOptions={{ ...DEFAULT_SCREEN_OPTIONS }}
    >
      {Platform.OS === 'web' ? (
        <>
          {loading && (
            <Stack.Screen
              // @ts-ignore
              name="SplashScreen"
              component={SplashScreenWeb}
              options={{
                title: routesToTitlesMap.SplashScreen,
              }}
            />
          )}
          {!isAuthorizedCardholderSelector ? (
            <Stack.Screen name="AuthNavigator">
              {() => (
                <AuthNavigator
                  isAuthenticated={isAuthenticated}
                  isNavigationHistory={isNavigationHistory}
                />
              )}
            </Stack.Screen>
          ) : (
            <>
              {cardholderCurrentStatus === 'fetchedFirstTime' &&
                FetchedFirstTimeNavigator()}
              {CIPAndOnboardingNavigators()}
            </>
          )}
        </>
      ) : (
        <>
          <Stack.Screen name="AuthNavigator">
            {() => (
              <AuthNavigator
                isAuthenticated={isAuthenticated}
                isNavigationHistory={isNavigationHistory}
              />
            )}
          </Stack.Screen>
          {isAuthenticated && (
            <>
              {CIPAndOnboardingNavigators()}
              {cardholderCurrentStatus === 'fetchedFirstTime' &&
                FetchedFirstTimeNavigator()}
            </>
          )}
        </>
      )}

      <Stack.Screen
        name="PaychexLoginPageSkipLanding"
        component={PaychexLoginPageSkipLanding}
        options={{
          title: routesToTitlesMap.PaychexLoginPageSkipLanding,
        }}
      />
    </Stack.Navigator>
  );
};

export default RootNavigator;
