import { ScrollView } from 'tamagui';
import { Dimensions, Platform } from 'react-native';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { TButton } from '@atoms';
import { DeleteAccountContent } from '@organisms';
import { Control } from 'react-hook-form';
import { IDeleteAccountFormInputs } from '@organisms/DeleteAccountForm/helper';

interface DeleteAccountViewProps {
  navigateBack: () => void;
  control: Control<IDeleteAccountFormInputs>;
  onDeleteAccount: () => void;
  isButtonLoading: boolean;
  isDeleteExternalAccountModalOpen?: boolean;
  onCloseDeleteExternalAccountModal: () => void;
}

const SCROLL_HEIGHT = Dimensions.get('screen').height - 350; // to avoid scroll blocking issue on small size devices

const DeleteAccountView = ({
  navigateBack,
  control,
  onDeleteAccount,
  isButtonLoading,
}: DeleteAccountViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      header={{
        title: 'Account details',
        isBackButton: true,
        onPressBack: navigateBack,
      }}
      footerContent={
        <TButton
          onPress={onDeleteAccount}
          isLoading={isButtonLoading}
          backgroundColor={'$red50'}
          borderWidth={0}
          testID="paychexpay.DeleteAccountView.button.deleteButton"
        >
          Remove
        </TButton>
      }
    >
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        contentContainerStyle={{
          paddingHorizontal: 16,
          paddingTop: 32,
        }}
        style={
          Platform.OS === 'web' && {
            height: SCROLL_HEIGHT,
            flexGrow: 1,
            paddingBottom: 25,
          }
        }
      >
        <DeleteAccountContent control={control} />
      </ScrollView>
    </HeaderAndFooterMobileTemplate>
  );
};

export default DeleteAccountView;
