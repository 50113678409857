import { ACCOUNT_NAMES } from '@constants/accounts';

const useNavigateToAccount = (navigation: any) => {
  return (type: string, name?: string) => {
    switch (type) {
      case 'spending':
        navigation.navigate('HomeNavigator', {
          screen: 'SpendingAccount',
          params: { accountName: name || ACCOUNT_NAMES.PaychexPaySpending },
        });
        break;
      case 'saving':
        navigation.navigate('HomeNavigator', {
          screen: 'SavingAccount',
          params: { accountName: name || ACCOUNT_NAMES.PaychexPaySaving },
        });
        break;
    }
  };
};

export default useNavigateToAccount;
