import { TButton, Text } from '@atoms';
import { CenterModal } from '@molecules';
import {
  selectModalSubmitHandler,
  selectNotificationConfirmationModalVisible,
  selectProfileSettingsModalVisible,
} from '@store/features/general/selectors';
import { useSelector } from 'react-redux';
import { Separator, View, XStack } from 'tamagui';

const ConfirmationModal = ({
  variant = 'confirmModal',
  isModalOpen,
  setIsConfirmationModalOpen = () => false,
  confirmHandler = () => false,
}: {
  variant?: string;
  isModalOpen: boolean;
  setIsConfirmationModalOpen?: (flag: boolean) => void;
  confirmHandler?: () => void;
}) => {
  const modalSubmitHandler = useSelector(selectModalSubmitHandler);
  const notificationConfirmationModalVisible = useSelector(
    selectNotificationConfirmationModalVisible
  );

  const profileSettingsModalVisible = useSelector(
    selectProfileSettingsModalVisible
  );

  return (
    <CenterModal
      isModalOpen={isModalOpen}
      closeByOutsideClick
      closeModal={() => setIsConfirmationModalOpen(false)}
    >
      <View
        maxWidth={343}
        py="$4"
        bg="$white"
        borderRadius={12}
        flexDirection="column"
        display="flex"
      >
        <Text
          textAlign="center"
          fontSize={16}
          fontWeight={'600'}
          pb={'$2'}
          px="$4"
        >
          {variant !== 'textStatement'
            ? 'Unsaved changes'
            : 'By turning on text notifications, I authorize account notifications to be sent to me via SMS. Message and data rates may apply.'}
        </Text>
        {variant !== 'textStatement' && (
          <Text
            fontSize={14}
            fontWeight={'400'}
            color={'$gray60'}
            textAlign="center"
            paddingBottom={16}
          >
            Are you sure you want to leave?
          </Text>
        )}
        <Separator borderColor={'$gray20'} height={1} />
        <XStack alignItems={'center'} marginTop={'$4'} paddingHorizontal={12}>
          <TButton
            flex={1}
            marginRight={'$2'}
            variant={'simple'}
            borderColor={'$gray10'}
            borderWidth={1}
            onPress={() => setIsConfirmationModalOpen(false)}
          >
            {variant !== 'textStatement' ? 'Stay' : 'Go back'}
          </TButton>
          <TButton
            flex={1}
            marginLeft={2}
            onPress={
              (notificationConfirmationModalVisible ||
                profileSettingsModalVisible) &&
              modalSubmitHandler !== undefined
                ? modalSubmitHandler
                : confirmHandler
            }
            variant="primary"
          >
            {variant !== 'textStatement' ? 'Leave' : 'I agree'}
          </TButton>
        </XStack>
      </View>
    </CenterModal>
  );
};
export default ConfirmationModal;
