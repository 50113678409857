import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useAuth0 } from '@hooks/useCustomAuth0';
import { useAppDispatch } from '@store/hooks';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import UnableToSignupView from '@views/SignUp/UnableToSignupView';
import { Platform } from 'react-native';
import { UnableToSignupPageProps } from '@navigation/AuthNavigator/AuthNavigator.types';

const UnableToSignupPage = ({ navigation }: UnableToSignupPageProps) => {
  const dispatch = useAppDispatch();

  const { onLogout } = useAuth0();

  const logoutHandler = async () => {
    await onLogout();
    await dispatch(navigationStatesActions.setIsAuthenticated(false));
    await dispatch(navigationStatesActions.setIsAuthorizedCardholder(false));
    if (Platform.OS !== 'web') {
      navigation.reset({
        index: 0,
        routes: [
          {
            // @ts-ignore
            name: 'AuthNavigator',
            params: {
              screen: 'SignInPage',
            },
          },
        ],
      });
    }
  };

  const commonProps = {
    logoutHandler,
  };

  return (
    <MediaLayoutTemplate
      Mobile={UnableToSignupView.mobile}
      Desktop={UnableToSignupView.desktop}
      commonProps={commonProps}
    />
  );
};

export default UnableToSignupPage;
