import { AboutPFAccountContent } from '@organisms';
import { HeaderMobileTemplate } from '@templates';
import { StatusBar } from 'native-base';

interface AboutPFAccountViewProps {
  onPressRightButton: () => void;
  onSignUpPress: () => void;
}

const AboutPFAccountView = ({
  onPressRightButton,
  onSignUpPress,
}: AboutPFAccountViewProps) => {
  return (
    <HeaderMobileTemplate
      header={{
        title: 'Sign in to Paychex Flex®',
        isRightButton: true,
        onPressRightButton,
      }}
    >
      <>
        <AboutPFAccountContent signUp={onSignUpPress} />
        <StatusBar barStyle="dark-content" backgroundColor="white" />
      </>
    </HeaderMobileTemplate>
  );
};

export default AboutPFAccountView;
