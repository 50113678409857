import React from 'react';
import { LegalProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import LegalView from '@views/More/LegalView';
import useIsTablet from '@hooks/useIsTablet';
import { useSelector } from 'react-redux';
import {
  selectAgreements,
  selectSavingAccountAgreement,
} from '@store/features/agreements/selectors';
import { IAgreement } from '@store/features/agreements/types';
import { selectSavingsAccountId } from '@store/features/cardholder/selectors';

const LegalPage = ({ navigation }: LegalProps) => {
  const isTablet = useIsTablet();
  const agreements = useSelector(selectAgreements);
  const savingAccountAgreement = useSelector(selectSavingAccountAgreement);
  const savingsAccountId = useSelector(selectSavingsAccountId);

  const navigateBack = () => {
    if (isTablet) {
      navigation.goBack();
    } else {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    }
  };

  const handleAgreementPress = (item: IAgreement) => {
    navigation.navigate('MoreNavigator', {
      screen: 'LegalAgreements',
      params: {
        document_id: item.id,
      },
    });
  };

  return (
    <LegalView
      navigateBack={navigateBack}
      menuItems={
        savingsAccountId
          ? [...agreements, savingAccountAgreement]
          : [...agreements]
      }
      handleAgreementPress={handleAgreementPress}
    />
  );
};

export default LegalPage;
