import { data, config } from '@paychex/internal';
import { tracker } from './eventCollector';
import endpoints from '../src/constants/endpoints';
import { customAxiosAdapter } from './adapter';

const endpoints_array = Object.entries(endpoints).map(([, value]) => ({
  name: value,
}));

export const pipeline = data.createDataPipeline(
  tracker,
  // @ts-ignore
  customAxiosAdapter,
  config.endpoints.concat(endpoints_array)
);
