import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const cardStatementsSelector = (state: RootState) => state.cardStatements;

export const selectSpendingStatementsList = createSelector(
  cardStatementsSelector,
  (state) => {
    return state.spending;
  }
);

export const selectSavingsStatementsList = createSelector(
  cardStatementsSelector,
  (state) => {
    return state.saving;
  }
);

export const selectStatementsByIds = createSelector(
  cardStatementsSelector,
  (state) => {
    return state.statementByIds;
  }
);
