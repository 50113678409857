import { createTypedThunk } from '@utils/createTypedThunk';
import { IErrorRequestBody } from './types';
import errorApi from './api';

const errorThunks = {
  postErrorInfo: createTypedThunk<Array<IErrorRequestBody>, any>(
    'errorStates/postErrorInfo',
    errorApi.postErrorInfo
  ),
};

export default errorThunks;
