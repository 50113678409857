import React, { useEffect } from 'react';

// Appends an ellipsis to the end of the text and animates it to give the user feedback that something is loading
const LoadingEllipsis = ({ text }: { text: string }) => {
  const [loadingText, setLoadingText] = React.useState(
    text.concat('    \u200B')
  );

  useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      if (i === 0) {
        setLoadingText(`${text} .  \u200B`); // "\u200B" is a zero-width space, we use it to prevent the text from jumping as react native auto trims whitespace
        i++;
      } else if (i === 1) {
        setLoadingText(`${text} .. \u200B`);
        i++;
      } else if (i === 2) {
        setLoadingText(`${text} ...`);
        i++;
      } else if (i === 3) {
        setLoadingText(`${text}    \u200B`);
        i = 0;
      }
    }, 400);

    return () => clearInterval(interval);
  }, []);

  return <>{loadingText}</>;
};

export default LoadingEllipsis;
