import { TouchableOpacity } from 'react-native';
import CopyIcon from '../../../assets/copy.svg';
import { AccountInfoItemProps } from '@molecules/AccountInfoItem/AccountInfoItem.types';
import { TPressable, Text } from '@atoms';
import { useState } from 'react';
import hidePartOfNumber from '@utils/hidePartOfNumber';
import { accessibleHitSlop } from '@constants/ui';
import { View, XStack } from 'tamagui';

const AccountInfoItem = ({
  text,
  title,
  onHoldHandler,
  testID,
  hideNumber = false,
  ...rest
}: AccountInfoItemProps) => {
  const [accountNumber, setAccountNumber] = useState(
    hideNumber ? hidePartOfNumber(text, 8) : null
  );
  const [isShow, setIsShow] = useState(false);
  const onPressShow = () => {
    if (isShow) {
      setAccountNumber(hidePartOfNumber(text, 8));
      setIsShow(false);
    } else {
      setAccountNumber(text);
      setIsShow(true);
    }
  };
  return (
    <TouchableOpacity
      onPress={
        onHoldHandler
          ? () => onHoldHandler({ text: text || '', title: title })
          : () => {
              // do nothing
            }
      }
      testID={testID}
      accessible
      accessibilityLabel={`${title} ${
        isNaN(Number(text)) ? text : [...text]
      }, ${hideNumber ? (isShow ? 'is visible' : 'is hidden') : ''}, ${
        onHoldHandler ? 'Tap to copy' : ''
      }`}
      {...rest}
    >
      <View>
        <Text variant="caption" pb={'$2'}>
          {title}
        </Text>
        <XStack justifyContent="space-between">
          <Text variant="regular">{hideNumber ? accountNumber : text}</Text>
          <XStack alignItems="center">
            {hideNumber && (
              <TPressable
                mr={'$5'}
                onPress={onPressShow}
                hitSlop={accessibleHitSlop}
                accessible
                accessibilityLabel={'Toggle account number visibility'}
                testID="paychexpay.accountInfoItem.button.showHideButton"
              >
                <Text
                  color="$primaryNormal"
                  fontWeight={'$medium'}
                  fontSize={14}
                >
                  {isShow ? 'Hide' : 'Show'}
                </Text>
              </TPressable>
            )}
            {onHoldHandler && <CopyIcon />}
          </XStack>
        </XStack>
      </View>
    </TouchableOpacity>
  );
};
export default AccountInfoItem;
