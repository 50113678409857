import { ScrollView, View, YStack } from 'tamagui';
import React, { ReactNode } from 'react';
import { StatusBar, KeyboardAvoidingView, Platform } from 'react-native';
import { MobileHeader } from '@molecules';
import { MobileHeaderProps } from '@molecules/MobileHeader/MobileHeader';
import {
  SafeAreaView,
  useSafeAreaInsets,
} from 'react-native-safe-area-context';
import { customTheme } from '../../../styles/customTheme';
import { getWindowHeightWeb } from '@utils/getWindowHeightWeb';
import { MOBILE_HEADER_HEIGHT } from '@constants/general';
import useIsPhone from '@hooks/useIsPhone';

interface HeaderAndFooterMobileTemplateProps {
  children: ReactNode;
  header?: MobileHeaderProps;
  footerContent: React.ReactNode;
  avoidKeyboard?: boolean;
  withScrollView?: boolean;
}

const HeaderAndFooterMobileTemplate = ({
  header,
  children,
  footerContent,
  avoidKeyboard = true,
  withScrollView = false,
}: HeaderAndFooterMobileTemplateProps) => {
  const { top: topInset } = useSafeAreaInsets();
  const isPhone = useIsPhone();

  const templateContent = (
    <YStack flexGrow={1}>
      {header && <MobileHeader {...header} />}
      <View display={'flex'} flexDirection={'column'} flex={1}>
        {children}
      </View>
      {avoidKeyboard ? (
        <KeyboardAvoidingView
          behavior={'padding'}
          enabled
          keyboardVerticalOffset={topInset}
        >
          <View
            borderTopColor="$gray20"
            borderTopWidth={1}
            backgroundColor="white"
            px={'$4'}
            py={'$4'}
          >
            {footerContent}
          </View>
        </KeyboardAvoidingView>
      ) : (
        <View
          borderTopColor="$gray20"
          borderTopWidth={1}
          backgroundColor="white"
          px={'$4'}
          pt={'$4'}
          pb={'$5'}
        >
          {footerContent}
        </View>
      )}
    </YStack>
  );

  return (
    <SafeAreaView
      style={{
        backgroundColor: customTheme.colors.white,
        height: '100%',
        flex: 1,
        flexGrow: 1,
      }}
    >
      <StatusBar barStyle="dark-content" />

      {withScrollView && Platform.OS === 'web' ? (
        <ScrollView
          scrollEnabled
          showsVerticalScrollIndicator={false}
          height={getWindowHeightWeb() - (isPhone ? MOBILE_HEADER_HEIGHT : 0)}
          bounces={false}
        >
          {templateContent}
        </ScrollView>
      ) : (
        <>{templateContent}</>
      )}
    </SafeAreaView>
  );
};

export default HeaderAndFooterMobileTemplate;
