import { useNavigation } from '@react-navigation/native';
import { AboutSsnView } from '@views/SignUp';

const AboutSsnPage = () => {
  const navigation = useNavigation();
  const onPressRightButton = () => {
    navigation.goBack();
  };
  return <AboutSsnView onPressRightButton={onPressRightButton} />;
};

export default AboutSsnPage;
