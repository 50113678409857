import React, { useEffect, useMemo, useRef, useState } from 'react';
import { withTiming } from 'react-native-reanimated';
import { FreezeLayer } from '@molecules';
import { v4 } from 'uuid';
import {
  freezeDuration,
  freezeLayersNumber,
  layersImages,
  randomFreezeDurationRange,
  randomUnfreezeDurationRange,
  unfreezeDuration,
} from '@constants/cardAnimations';
import { CARD_STATES } from '@constants/cardStates';
import { debitCardStatesType } from '@devTypes/cards';

function generateRandomInteger(min: number, max: number) {
  return Math.floor(min + Math.random() * (max - min + 1));
}

const useFreezeAnimation = ({
  cardStatus,
}: {
  cardStatus?: debitCardStatesType;
}) => {
  const fadeAnimationsRef = useRef<any>([]).current;
  const [isFrozen, setIsFrozen] = useState(cardStatus === CARD_STATES.frozen);
  const isFrozenRef = useRef<boolean>(isFrozen);
  const [freezeLayers] = useState<number[]>([
    ...Array(freezeLayersNumber).keys(),
  ]);

  useEffect(() => {
    setIsFrozen(cardStatus === CARD_STATES.frozen);
    isFrozenRef.current = cardStatus === CARD_STATES.frozen;
  }, [cardStatus]);

  const animateFade = (node: any, toValue: number, duration?: number) => {
    if (!duration) {
      const randomDuration = !toValue
        ? randomUnfreezeDurationRange
        : randomFreezeDurationRange;
      duration = generateRandomInteger(randomDuration[0], randomDuration[1]);
    }

    node.value = withTiming(toValue, {
      duration: duration,
    });
  };

  const renderFreezeLayers = useMemo(() => {
    return freezeLayers.map((layerId: number) => {
      return (
        <FreezeLayer
          key={v4()}
          layerId={layerId}
          imageSource={layersImages[layerId]}
          fadeAnimationsRef={fadeAnimationsRef}
          isFrozenRef={isFrozenRef}
        />
      );
    });
  }, [freezeLayers]);

  const animateFreeze = () => {
    animateFade(fadeAnimationsRef[0], 1, freezeDuration);
    setTimeout(() => {
      animateFade(fadeAnimationsRef[1], 1, freezeDuration);
    }, 100);
    setTimeout(() => {
      animateFade(fadeAnimationsRef[2], 1, freezeDuration);
    }, 200);
    // animateFade(fadeAnimationsRef[1], 1, freezeDuration + 150);
    // animateFade(fadeAnimationsRef[2], 1, freezeDuration + 250);
    // freezeLayers.forEach((layerId: number) => {
    //   if (layerId !== 0) {
    //     animateFade(fadeAnimationsRef[layerId], 1);
    //   }
    // });
  };

  const animateUnfreeze = () => {
    // freezeLayers.forEach((layerId: number) => {
    //   if (layerId !== 0) {
    //     animateFade(fadeAnimationsRef[layerId], 0);
    //   }
    // });
    animateFade(fadeAnimationsRef[2], 0, unfreezeDuration);
    setTimeout(() => {
      animateFade(fadeAnimationsRef[1], 0, unfreezeDuration);
    }, 100);
    setTimeout(() => {
      animateFade(fadeAnimationsRef[0], 0, unfreezeDuration);
    }, 200);
  };

  return {
    animateFreeze,
    animateUnfreeze,
    renderFreezeLayers,
  } as {
    animateFreeze: () => void;
    animateUnfreeze: () => void;
    renderFreezeLayers: any;
  };
};

export default useFreezeAnimation;
