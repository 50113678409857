import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  IBillPayRequestBody,
  IBillPayResponse,
  IDdaEligibilityResponse,
  IIngoDataRequestBody,
  IIngoDataResponse,
} from './types';
import { application, operations } from '@constants/traceability';

const homeWidgetsApi = {
  billPay(body: IBillPayRequestBody) {
    return wrappedAxiosRequest<IBillPayRequestBody, IBillPayResponse>({
      method: 'post',
      url: endpoints.payBills,
      data: body,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.BillPay,
        'x-payx-comp': application.compPrefix + operations.BillPay,
      },
    });
  },
  ddaEligibility() {
    return wrappedAxiosRequest<null, IDdaEligibilityResponse>({
      method: 'get',
      url: endpoints.ddaEligibility,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.CheckIfUserDdaEligible,
        'x-payx-comp':
          application.compPrefix + operations.CheckIfUserDdaEligible,
      },
    });
  },
  getIngoData(body: IIngoDataRequestBody): any {
    return wrappedAxiosRequest<IIngoDataRequestBody, IIngoDataResponse>({
      method: 'post',
      url: endpoints.getMobileCheckLoadCreds,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.GetMobileCheckLoadCreds,
        'x-payx-comp':
          application.compPrefix + operations.GetMobileCheckLoadCreds,
      },
      // @ts-ignore
      data: body,
    });
  },
};

export default homeWidgetsApi;
