import { ITransaction } from '@store/features/transactions/types';

const idToTransaction = (
  transactionId: string | undefined,
  transactions: ITransaction[]
) => {
  return transactionId
    ? transactions?.find(
        (transaction) => transaction.transaction_id === transactionId
      )
    : undefined;
};

export default idToTransaction;
