import { ReactElement, useState, useEffect } from 'react';
import { XStack, View } from 'tamagui';
import { BackButton, Text } from '@atoms';
import { MOBILE_HEADER_HEIGHT } from '@constants/general';
import { accessibleHitSlop } from '@constants/ui';
export interface MobileHeaderProps {
  title?: string;
  logo?: ReactElement;
  isRightButton?: boolean;
  isBackButton?: boolean;
  rightButtonText?: string;
  onPressBack?: () => void;
  onPressRightButton?: () => void;
  withBorder?: boolean;
  bg?: string;
}

const MobileHeader = ({
  title,
  logo,
  isRightButton = false,
  isBackButton = false,
  rightButtonText = 'Done',
  onPressBack,
  onPressRightButton,
  withBorder = true,
  bg = 'white',
}: MobileHeaderProps) => {
  const [titleHorizontalPadding, setTitleHorizontalPadding] = useState({
    titlePaddingLeft: 0,
    titlePaddingRight: 0,
  });

  useEffect(() => {
    if (!logo) {
      if (!!title && title?.length <= 25) {
        if (isRightButton && isBackButton) {
          setTitleHorizontalPadding({
            titlePaddingLeft: 30,
            titlePaddingRight: 0,
          });
        }

        if (!isRightButton && !isBackButton) {
          setTitleHorizontalPadding({
            titlePaddingLeft: 0,
            titlePaddingRight: 0,
          });
        }

        if (isRightButton && !isBackButton) {
          setTitleHorizontalPadding({
            titlePaddingLeft: 50,
            titlePaddingRight: 0,
          });
        }

        if (!isRightButton && isBackButton) {
          setTitleHorizontalPadding({
            titlePaddingLeft: 0,
            titlePaddingRight: 30,
          });
        }
      } else {
        setTitleHorizontalPadding({
          titlePaddingLeft: 0,
          titlePaddingRight: 0,
        });
      }
    }
  }, [title]);

  return (
    <XStack
      height={MOBILE_HEADER_HEIGHT}
      py={8}
      alignItems="center"
      borderBottomColor="$gray20"
      borderStyle={'solid'}
      borderWidth={0}
      borderBottomWidth={withBorder ? 1 : 0}
      bg={bg}
    >
      <View
        display={'flex'}
        flex={0}
        flexGrow={0}
        alignSelf="center"
        ml={16}
        h={24}
        zIndex={100}
      >
        {isBackButton && onPressBack && <BackButton onPress={onPressBack} />}
      </View>
      <View
        display={'flex'}
        flex={1}
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        alignSelf="center"
        h={24}
        accessible
        accessibilityLabel={`Header ${title || ''}`}
        pl={titleHorizontalPadding.titlePaddingLeft}
        pr={titleHorizontalPadding.titlePaddingRight}
      >
        {logo && logo}
        {!!title && (
          <Text variant="title" numberOfLines={1} textOverflow={'ellipsis'}>
            {title}
          </Text>
        )}
      </View>
      <View
        flex={0}
        flexGrow={0}
        alignSelf="center"
        mr={16}
        h={24}
        zIndex={100}
      >
        {isRightButton && (
          <View
            h={24}
            maxWidth={60}
            overflow={'hidden'}
            hitSlop={accessibleHitSlop}
            onPress={onPressRightButton}
            testID="paychexpay.mobileHeader.button.closeButton"
            accessible
            accessibilityLabel={`${rightButtonText} button`}
          >
            <Text
              textAlign="center"
              verticalAlign="middle"
              fontSize={14}
              fontWeight={'400'}
              lineHeight={24}
              color="$primaryNormal"
            >
              {rightButtonText}
            </Text>
          </View>
        )}
      </View>
    </XStack>
  );
};

export default MobileHeader;
