import cardholderAPI from '@store/features/cardholder/api';
import {
  IAdditionalCardholderInfoUpdateRequest,
  ICardholder,
  ICardholderAdditionalInfoResponse,
  ICardholderRequestBody,
  ICardholderUpdateRequest,
} from '@store/features/cardholder/types';
import { createTypedThunk } from '@utils/createTypedThunk';
import { ICreateCardholderResponse } from '@store/features/oowQuestions/types';

const cardholderThunks = {
  postCreateCardholder: createTypedThunk<
    ICardholderRequestBody,
    ICreateCardholderResponse
  >('cardholder/postCreateCardholder', cardholderAPI.postCreateCardholder),
  getCardholder: createTypedThunk<undefined, ICardholder>(
    'cardholder/getCardholder',
    cardholderAPI.getCardholder
  ),
  deleteCardholder: createTypedThunk<undefined, ICardholder>(
    'cardholder/deleteCardholder',
    cardholderAPI.deleteCardholder
  ),
  getCardholderAdditionalInfo: createTypedThunk<
    undefined,
    ICardholderAdditionalInfoResponse
  >(
    'cardholder/getCardholderAdditionalInfo',
    cardholderAPI.getAdditionalCardholderInfo
  ),
  updateCardholderAdditionalInfo: createTypedThunk<
    IAdditionalCardholderInfoUpdateRequest,
    ICardholderAdditionalInfoResponse
  >(
    'cardholder/updateCardholderAdditionalInfo',
    cardholderAPI.updateAdditionalCardholderInfo
  ),
  updateCardholderInfo: createTypedThunk<ICardholderUpdateRequest, ICardholder>(
    'cardholder/updateCardholderInfo',
    cardholderAPI.updateCardholderInfo
  ),
};

export default cardholderThunks;
