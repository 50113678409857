import { Alert } from 'react-native';
import { MoreView } from '@views/BottomTabs';
import VersionInfo from 'react-native-version-info';
import { useNavigation } from '@react-navigation/native';
import {
  MoreNavigatorProps,
  morePageType,
} from '@navigation/MoreNavigator/MoreNavigator.types';
import { useAuth0 } from '@hooks/useCustomAuth0';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import { useAppDispatch } from '@store/hooks';

interface MorePageProps {
  isJestTest?: boolean;
}

const MorePage = ({ isJestTest }: MorePageProps) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<MoreNavigatorProps['navigation']>();
  const appVersion = `v ${VersionInfo.appVersion} build ${VersionInfo.buildVersion}`;
  const { onLogout } = useAuth0();

  const asyncLogoutHandler = async () => {
    await onLogout();
    await dispatch(navigationStatesActions.setIsAuthenticated(false));
    await dispatch(navigationStatesActions.setIsAuthorizedCardholder(false));

    navigation.reset({
      index: 0,
      routes: [
        {
          // @ts-ignore
          name: 'AuthNavigator',
          params: {
            screen: 'SignInPage',
          },
        },
      ],
    });
  };

  const logoutHandler = async () => {
    if (isJestTest) await asyncLogoutHandler();
    Alert.alert('Are you sure?', 'You will need to log in again.', [
      {
        text: 'Cancel',
        style: 'cancel',
      },
      {
        text: 'Log out',
        onPress: async () => {
          await asyncLogoutHandler();
        },
      },
    ]);
  };

  const onItemPress = (pageName: morePageType) => () => {
    if (pageName === 'DebitCard') {
      navigation.navigate('HomeNavigator', {
        screen: 'DebitCard',
      });
    } else {
      navigation.navigate('MoreNavigator', {
        screen: pageName,
      });
    }
  };
  return (
    <MoreView
      logoutHandler={logoutHandler}
      onItemPress={onItemPress}
      appVersion={appVersion}
    />
  );
};

export default MorePage;
