import { HeaderAndFooterMobileTemplate, WebLogoTemplate } from '@templates';
import { FooterButtonsRow, ImageWithContent } from '@molecules';
import OpenSavingsIcon from '../../../assets/accounts/OpenSavingsIcon.svg';
import { ScrollView, View, YStack } from 'tamagui';
import { TButton, Text } from '@atoms';
import React from 'react';
import { Platform } from 'react-native';
import { IVariable } from '@store/features/general/types';

interface OpenSavingsAccountViewProps {
  mainButtonHandler: () => void;
  onPressBack: () => void;
  variables: {
    variableAnnualPercentageYield: IVariable | undefined;
    variableSavingsInterestRate: IVariable | undefined;
    variableSavingsAgreementDate: IVariable | undefined;
  };
}

const OpenSavingsAccountTexts = {
  subtitleClosed:
    'You won’t be able to access your old account via app anymore.',
  subtitle: 'Start saving with us to earn interest and enjoy easy transfers.',
  content:
    'This is a variable rate account. The interest rate on your account is %VARIABLE_0%% with an Annual Percentage Yield ("APY") of %VARIABLE_1%%. You must maintain a minimum balance of $100 in the Savings Account each day to obtain the disclosed APY. At our discretion, we may change the interest rate on your account. Rates may change at any time. The disclosed rate is effective as of %VARIABLE_2%. Interest begins to accrue the business day the deposit is applied to the Savings Account. Interest will be compounded daily and credited to the Savings Account each statement cycle. If you close your account before interest is credited, you will not receive the accrued interest.',
};

const mobile = ({
  mainButtonHandler,
  onPressBack,
  variables,
}: OpenSavingsAccountViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      header={{
        title: 'Open savings',
        isBackButton: true,
        onPressBack: onPressBack,
      }}
      footerContent={
        <TButton onPress={mainButtonHandler}>Yes, let&apos;s do it</TButton>
      }
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        h={Platform.OS === 'web' ? 0 : '100%'}
        bounces={false}
      >
        <YStack
          paddingTop={16}
          paddingBottom={16}
          paddingHorizontal={'$6'}
          flexGrow={1}
          display={'flex'}
          justifyContent="space-between"
        >
          <ImageWithContent image={<OpenSavingsIcon />} space={'$3'}>
            <Text variant="title" paddingBottom={'$3'} textAlign="center">
              Open Savings Account?
            </Text>
            <Text variant="subtitle" textAlign="center" color={'$gray50'}>
              {OpenSavingsAccountTexts.subtitle}
            </Text>
            <Text
              variant={'caption'}
              textAlign={'center'}
              fontSize={'$caption'}
              lineHeight={18}
              marginTop={14}
            >
              {OpenSavingsAccountTexts.content
                .replace(
                  '%VARIABLE_0%',
                  variables.variableSavingsInterestRate?.value || ''
                )
                .replace(
                  '%VARIABLE_1%',
                  variables.variableAnnualPercentageYield?.value || ''
                )
                .replace(
                  '%VARIABLE_2%',
                  variables.variableSavingsAgreementDate?.value || ''
                )}
            </Text>
          </ImageWithContent>
        </YStack>
      </ScrollView>
    </HeaderAndFooterMobileTemplate>
  );
};

const desktop = ({
  mainButtonHandler,
  onPressBack,
  variables,
}: OpenSavingsAccountViewProps) => {
  return (
    <>
      <WebLogoTemplate>
        <View
          w="325px"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <ImageWithContent image={<OpenSavingsIcon />} space={'$3'}>
            <Text variant="title" paddingBottom={'$3'} textAlign="center">
              Open Savings Account?
            </Text>
            <Text variant="subtitle" textAlign="center">
              {OpenSavingsAccountTexts.subtitle}
            </Text>
            <Text
              variant={'caption'}
              textAlign={'center'}
              fontSize={'$caption'}
              lineHeight={18}
              marginTop={14}
            >
              {OpenSavingsAccountTexts.content
                .replace(
                  '%VARIABLE_0%',
                  variables.variableSavingsInterestRate?.value || ''
                )
                .replace(
                  '%VARIABLE_1%',
                  variables.variableAnnualPercentageYield?.value || ''
                )
                .replace(
                  '%VARIABLE_2%',
                  variables.variableSavingsAgreementDate?.value || ''
                )}
            </Text>
          </ImageWithContent>

          <View marginTop={'$6'}>
            <FooterButtonsRow
              firstButton={{
                label: 'Back',
                handler: onPressBack,
                testID:
                  'paychexpay.openSavingsAccountView.footerButtonsRow.button.backButton',
              }}
              secondButton={{
                // eslint-disable-next-line
                  label: "Yes, let's do it",
                handler: mainButtonHandler,
                testID:
                  'paychexpay.openSavingsAccountView.footerButtonsRow.button.openSavingsButton',
              }}
            />
          </View>
        </View>
      </WebLogoTemplate>
    </>
  );
};

const OpenSavingsAccountView = {
  mobile,
  desktop,
};

export default OpenSavingsAccountView;
