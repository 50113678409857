import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { generalReducer } from '../features/general/slice';
import { widgetStateReducer } from '../features/homeWidgets/slice';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { storage } from './storage';
import { fillInDataReducer } from '@store/features/fillInData/slice';
import { biometricsDataReducer } from '@store/features/biometrics/slice';
import { agreementsReducer } from '@store/features/agreements/slice';
import { atmLocationsReducer } from '@store/features/atmLocations/slice';
import { cardholderReducer } from '@store/features/cardholder/slice';
import { cardsReducer } from '@store/features/cards/slice';
import { transactionsReducer } from '@store/features/transactions/slice';
import { cardStatementsReducer } from '@store/features/cardStatements/slice';
import { oowQuestionsReducer } from '@store/features/oowQuestions/slice';
import { transfersReducer } from '@store/features/transfers/slice';
import { externalBankAccountReducer } from '@store/features/externalBankAccounts/slice';
import { notificationsReducer } from '@store/features/notifications/slice';
import { errorStatesReducer } from '@store/features/errorStates/slice';
import { navigationStatesReducer } from '@store/features/navigationStates/slice';
import { ddaFormReducer } from '@store/features/ddaForm/slice';
import { rewardsStateReducer } from '@store/features/rewards/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: [
    'errorStates',
    'oowQuestions',
    'cards',
    'transactions',
    'agreements',
  ],
};

const combinedReducer = combineReducers({
  general: generalReducer,
  biometricsData: biometricsDataReducer,
  fillInData: fillInDataReducer,
  homeWidgets: widgetStateReducer,
  rewards: rewardsStateReducer,
  agreements: agreementsReducer,
  atmLocations: atmLocationsReducer,
  cardholder: cardholderReducer,
  cards: cardsReducer,
  transactions: transactionsReducer,
  oowQuestions: oowQuestionsReducer,
  cardStatements: cardStatementsReducer,
  transfers: transfersReducer,
  externalBankAccounts: externalBankAccountReducer,
  notifications: notificationsReducer,
  errorStates: errorStatesReducer,
  navigationStates: navigationStatesReducer,
  ddaForm: ddaFormReducer,
});

export const persistedReducer = persistReducer(persistConfig, combinedReducer);
