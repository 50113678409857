import { ImageWithContent } from '@molecules';
import DdaImage from '../../../assets/Setup direct deposit.svg';
import { Center } from 'native-base';
import React from 'react';
import useIsTablet from '@hooks/useIsTablet';
import { Text } from '@atoms';
import { setupDdaAccount } from '@constants/ddaScreens';
import { Separator, View } from 'tamagui';

const SetupDdaContent = () => {
  const isTablet = useIsTablet();
  return (
    <>
      {!isTablet && (
        <View>
          <Center h="88px">
            <Text variant="title">Getting started</Text>
          </Center>
          <Separator borderColor="$gray20" borderWidth={0.5} />
        </View>
      )}
      <View pt={'$6'} px={isTablet ? '$4' : '$7'} pb={'$6'}>
        <ImageWithContent image={<DdaImage />} space={isTablet ? '$7' : '$6'}>
          <View position="relative">
            <Text variant="title" textAlign="center" mb={12}>
              {setupDdaAccount.title}
            </Text>
          </View>

          <Text variant="subtitle" pb={12} textAlign={'center'}>
            {setupDdaAccount.text}
          </Text>
        </ImageWithContent>
      </View>
    </>
  );
};

export default SetupDdaContent;
