import { MONTHS_LONG } from '@constants/months';
import { add, format, isAfter, parseISO } from 'date-fns';

export const getShortDateISO = (date: string) =>
  format(parseISO(date.slice(0, 19).replace(' ', 'T') + 'Z'), 'MM/dd/yyyy');
export const getShortDate = (date: Date) => format(date, 'MM/dd/yyyy');

export const isoDateToTransactionsWidgetDate = (date: string) => {
  const dateIso = date.slice(0, 19).replace(' ', 'T') + 'Z';
  const today = getShortDate(new Date());
  const yesterday = getShortDate(add(new Date(), { days: -1 }));
  if (getShortDateISO(dateIso) === today) return 'Today';
  if (getShortDateISO(dateIso) === yesterday) return 'Yesterday';
  return format(parseISO(dateIso), 'd MMM yyyy');
};

export const isoDateToTransactionsDetailsDate = (date: string) => {
  const dateIso = date.slice(0, 19).replace(' ', 'T') + 'Z';
  const today = getShortDate(new Date());
  const yesterday = getShortDate(add(new Date(), { days: -1 }));
  if (getShortDateISO(dateIso) === today) return 'Today';
  if (getShortDateISO(dateIso) === yesterday) return 'Yesterday';
  return format(parseISO(dateIso), 'MMM d, yyyy');
};

export const dateStringToMonthYearLongFormat = (date: string) => {
  const [year, month, day] = date.split('-');

  const formatedDate = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10)
  );

  return `${
    MONTHS_LONG[formatedDate.getMonth()]
  } ${formatedDate.getFullYear()}`;
};

export const dateStringToIsOnTheWay = (date: string) => {
  const currentDate = parseISO(date);
  const now = new Date();
  return isAfter(add(currentDate, { days: 3 }), now);
};

export const dateStringToAfterFiveDays = (date: string) => {
  const currentDate = parseISO(date);
  return format(add(currentDate, { days: 5 }), 'MMM d');
};
