import { ConnectAccountProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { ConnectAccountView } from '@views/Transfers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
  ConnectAccountSchema,
  IConnectAccountFormInputs,
} from '@organisms/ConnectAccountForm/helper';
import externalBankAccountsThunks from '@store/features/externalBankAccounts/asyncThunks';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';
import { selectCardholder } from '@store/features/cardholder/selectors';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';

const defaultValues: IConnectAccountFormInputs = {
  achNick: '',
  accountType: '',
  accountNumber: '',
  bankName: '',
  routingNumber: '',
  accountOwnership: '',
};

const ConnectAccountPage = ({ navigation }: ConnectAccountProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<IConnectAccountFormInputs>({
    resolver: yupResolver(ConnectAccountSchema),
    mode: 'onBlur',
    defaultValues,
  });
  const navigateBack = () => {
    navigation.goBack();
  };
  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const userProfileData = useSelector(selectCardholder);
  const onSubmit = async (data: IConnectAccountFormInputs) => {
    setIsButtonLoading(true);
    const formattedAccountNumber = data.accountNumber.padStart(10, '0');
    await dispatch(
      externalBankAccountsThunks.postExternalAccount({
        account_number: formattedAccountNumber,
        routing_number: data.routingNumber,
        account_type: data.accountType === 'Checking' ? 'CHECKING' : 'SAVINGS',
        account_title: data.achNick,
        bank_name: data.bankName,
        account_ownership: data.accountOwnership,
        country: 'USA',
        currency_code: 'USD',
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
    dispatch(externalBankAccountsThunks.getExternalAccounts())
      .unwrap()
      .catch((err) => handleRequestError(err));
    setIsButtonLoading(false);
    navigateBack();
  };

  const accountNumber = watch('accountNumber');
  const achNick = watch('achNick');
  const accountType = watch('accountType');
  const accountOwnership = watch('accountOwnership');
  const bankName = watch('bankName');
  const routingNumber = watch('routingNumber');
  const isButtonDisabled = useMemo(() => {
    return (
      accountNumber === '' ||
      achNick === '' ||
      accountType === '' ||
      accountOwnership === '' ||
      bankName === '' ||
      routingNumber === ''
    );
  }, [
    accountNumber,
    achNick,
    accountType,
    accountOwnership,
    bankName,
    routingNumber,
  ]);

  const [isBankAcntNameInputDisabled, setIsBankAcntNameInputDisabled] =
    useState(false);

  useEffect(() => {
    if (accountOwnership === 'SELF') {
      setValue(
        'achNick',
        userProfileData.first_name + ' ' + userProfileData.last_name
      );
      setIsBankAcntNameInputDisabled(true);
    } else {
      setIsBankAcntNameInputDisabled(false);
    }
  }, [accountOwnership]);

  return (
    <ConnectAccountView
      navigateBack={navigateBack}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      isButtonDisabled={Object.keys(errors).length !== 0 || isButtonDisabled}
      isButtonLoading={isButtonLoading}
      isBankAcntNameInputDisabled={isBankAcntNameInputDisabled}
    />
  );
};

export default ConnectAccountPage;
