import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const biometricsDataSelector = (state: RootState) => state.biometricsData;

export const selectBiometricsType = createSelector(
  biometricsDataSelector,
  (biometricsData) => biometricsData.biometricsType
);

export const selectIsBiometricEnabled = createSelector(
  biometricsDataSelector,
  (biometricsData) => biometricsData.isBiometricEnabled
);

export const selectIsTriedToEnableBiometric = createSelector(
  biometricsDataSelector,
  (biometricsData) => biometricsData.isTriedToEnableBiometric
);
