import { wrappedAxiosRequest } from '@store/api';
import {
  IStatementsListRequest,
  IStatementsListResponse,
  IStatementRequest,
  IStatementResponse,
} from './types';
import endpoints from '@constants/endpoints';
import { application, operations } from '@constants/traceability';

const cardStatementsApi = {
  getSpendingStatementsList({ cardId }: IStatementsListRequest) {
    return wrappedAxiosRequest<null, IStatementsListResponse>({
      method: 'get',
      url: endpoints.getStatementsList.replace('{card_id}', cardId),
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.GetSpendingStatementsList,
        'x-payx-comp':
          application.compPrefix + operations.GetSpendingStatementsList,
      },
    });
  },
  getSavingStatementsList({ cardId }: IStatementsListRequest) {
    return wrappedAxiosRequest<null, IStatementsListResponse>({
      method: 'get',
      url: endpoints.getStatementsList.replace('{card_id}', cardId),
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.GetSavingsStatementsList,
        'x-payx-comp':
          application.compPrefix + operations.GetSavingsStatementsList,
      },
    });
  },
  getStatement({ statementId }: IStatementRequest) {
    return wrappedAxiosRequest<null, IStatementResponse>({
      method: 'get',
      url: endpoints.getStatement.replace('{statement_id}', statementId),
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GetStatement,
        'x-payx-comp': application.compPrefix + operations.GetStatement,
      },
    });
  },
};

export default cardStatementsApi;
