import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { NotificationsView } from '@views/More';
import { NotificationsProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { selectNotificationsList } from '@store/features/notifications/selectors';
import { useSelector } from 'react-redux';
import notificationsThunks from '@store/features/notifications/asyncThunks';
import { useEffect, useState } from 'react';
import {
  INotificationsList,
  notificationsPairType,
  oneOfNotificationsPropertiesType,
} from '@store/features/notifications/types';
import { notificationsActions } from '@store/features/notifications/slice';
import { useAppDispatch } from '@store/hooks';
import { NOTIFICATIONS_GROUPS } from '@constants/notifications';
import { generalActions } from '@store/features/general/slice';
import { selectIsNotificationPreferencesUpdated } from '@store/features/general/selectors';
import { Platform } from 'react-native';
import useIsPhone from '@hooks/useIsPhone';
import { handlePermissionCheck } from '@utils/notificationsPermissionHelper';

const NotificationsPage = ({ navigation }: NotificationsProps) => {
  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
    dispatch(generalActions.setWebNotificationConfirmationModalVisible(false));
    dispatch(generalActions.setNotificationPreferencesUpdated(false));
  };
  const dispatch = useAppDispatch();
  const notificationsList = useSelector(selectNotificationsList);
  const [textWasEnabledAndNotSaved, setTextWasTouchedAndNotSaved] = useState<
    Array<string>
  >([]);
  const [isFetchedNotifications, setIsFetchedNotifications] = useState(false);
  const [isTogglesDisabled, setIsTogglesDisabled] = useState(false);
  const [isModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [currentModalVariant, setCurrentModalVariant] = useState('confirmBack');
  const [confirmHandler, setConfirmHandler] = useState<any>();
  const [allSwitches, setAllSwitches] = useState({
    email: !Object.values(notificationsList).find((option) => !option.email),
    text: !Object.values(notificationsList).find((option) => !option.text),
    mobilePushPaychexPay: !Object.values(notificationsList).find(
      (option) => !option.mobilePushPaychexPay
    ),
  });
  const [currentNotificationsList, setCurrentNoitificationsList] =
    useState<any>();
  const notificationPreferncesUpdated = useSelector(
    selectIsNotificationPreferencesUpdated
  );

  const [saveProcessing, setSaveProcessing] = useState(false);
  const toSwitchAll =
    (option: 'email' | 'text' | 'mobilePushPaychexPay') =>
    async (value: boolean) => {
      setIsTogglesDisabled(true);
      setAllSwitches({
        ...allSwitches,
        [option]: value,
      });
      const properties = Object.keys(
        notificationsList
      ) as oneOfNotificationsPropertiesType[];
      const entries = [] as notificationsPairType[];

      properties.map((property) => {
        entries.push([
          property,
          {
            ...notificationsList[property],
            [option]: value,
          },
        ]);
      });
      await setCurrentNoitificationsList({
        ...currentNotificationsList,
        ...Object.fromEntries(entries),
      });
      await dispatch(
        notificationsActions.localUpdate({
          ...currentNotificationsList,
          ...Object.fromEntries(entries),
        })
      );
      setAllSwitches({
        email: !Object.values({
          ...currentNotificationsList,
          ...Object.fromEntries(entries),
        }).find(
          //@ts-ignore
          (option) => !option.email
        ),
        text: !Object.values({
          ...currentNotificationsList,
          ...Object.fromEntries(entries),
        }).find(
          //@ts-ignore
          (option) => !option.text
        ),
        mobilePushPaychexPay: !Object.values({
          ...currentNotificationsList,
          ...Object.fromEntries(entries),
        }).find(
          //@ts-ignore
          (option) => !option.mobilePushPaychexPay
        ),
      });
      if (option === 'text') {
        setTextWasTouchedAndNotSaved(value ? ['all'] : []);
      }
      dispatch(generalActions.setNotificationPreferencesUpdated(true));
      setIsTogglesDisabled(false);
    };

  const setupAllNotifications = async () => {
    if (currentNotificationsList) {
      dispatch(notificationsActions.localUpdate(currentNotificationsList));
      const saveNotifications = async () => {
        if (!saveProcessing) await setSaveProcessing(true);
        await dispatch(
          // @ts-ignore
          notificationsThunks.postNotifications(currentNotificationsList)
        );
        await dispatch(notificationsThunks.getNotifications()).unwrap();
        setTextWasTouchedAndNotSaved([]);
        dispatch(generalActions.setNotificationPreferencesUpdated(false));
        setIsConfirmationModalOpen(false);
        await setSaveProcessing(false);
      };

      if (!textWasEnabledAndNotSaved.length) {
        setSaveProcessing(true);
        await saveNotifications();
        await setSaveProcessing(false);
      } else {
        setIsConfirmationModalOpen(true);
        setCurrentModalVariant('textStatement');
        setConfirmHandler(() => saveNotifications);
      }
    }
  };
  const toSwitchOne =
    (property: keyof INotificationsList) =>
    (option: 'email' | 'text' | 'mobilePushPaychexPay') =>
    async (value: boolean) => {
      setIsTogglesDisabled(true);
      if (value) {
        const properties = Object.keys(
          notificationsList
        ) as oneOfNotificationsPropertiesType[];
        let counter = value ? -1 : 1;
        properties.forEach((property) => {
          if (notificationsList[property][option] === false) counter++;
        });
        if (counter === 0) {
          setAllSwitches({
            ...allSwitches,
            [option]: true,
          });
        }
      } else {
        if (allSwitches[option]) {
          setAllSwitches({
            ...allSwitches,
            [option]: false,
          });
        }
      }
      let currentPreferencesToUpdate: INotificationsList;
      const notificationGroupToUpdate = NOTIFICATIONS_GROUPS.find(
        (item) => item.notificationKeysIncluded.indexOf(property) !== -1
      );
      if (option === 'text') {
        setTextWasTouchedAndNotSaved((prevState) =>
          prevState.includes(property)
            ? prevState.filter((key) => key !== property)
            : [...prevState, property]
        );
      }
      if (notificationGroupToUpdate) {
        dispatch(generalActions.setNotificationPreferencesUpdated(true));
        notificationGroupToUpdate.notificationKeysIncluded.forEach((item) => {
          currentPreferencesToUpdate = {
            ...notificationsList,
            ...currentPreferencesToUpdate,
            [item]: {
              //@ts-ignore
              ...notificationsList[item],
              [option]: value,
            },
          };
          dispatch(
            notificationsActions.localUpdate(currentPreferencesToUpdate)
          );
          setCurrentNoitificationsList(currentPreferencesToUpdate);
        });
      }

      setIsTogglesDisabled(false);
    };

  const isPhone = useIsPhone();

  useEffect(() => {
    (async () => {
      if ((Platform.OS === 'web' && isPhone) || Platform.OS !== 'web') {
        await dispatch(notificationsThunks.getNotifications());
        setIsFetchedNotifications(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (isFetchedNotifications) {
      setAllSwitches({
        email: !Object.values(notificationsList).find(
          (option) => !option.email
        ),
        text: !Object.values(notificationsList).find((option) => !option.text),
        mobilePushPaychexPay: !Object.values(notificationsList).find(
          (option) => !option.mobilePushPaychexPay
        ),
      });

      setIsFetchedNotifications(false);
    }
  }, [isFetchedNotifications]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      setTimeout(() => {
        handlePermissionCheck();
      }, 500);
    }
  }, []);

  return (
    <MediaLayoutTemplate
      Mobile={NotificationsView.mobile}
      Desktop={NotificationsView.desktop}
      navigateBack={navigateBack}
      notificationsList={notificationsList}
      allSwitches={allSwitches}
      toSwitchAll={toSwitchAll}
      toSwitchOne={toSwitchOne}
      setupAllNotifications={setupAllNotifications}
      isTogglesDisabled={isTogglesDisabled}
      saveProcessing={saveProcessing}
      isNotificationPreferencesUpdated={notificationPreferncesUpdated}
      isDesktopModalOpen={isModalOpen}
      setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      currentModalVariant={currentModalVariant}
      confirmHandler={confirmHandler}
    />
  );
};

export default NotificationsPage;
