import { Skeleton } from 'native-base';
import { customTheme } from '../../../styles/customTheme';
import { Separator, View, YStack } from 'tamagui';

const SkeletonView = () => {
  return (
    <View px="$4">
      <YStack>
        <Skeleton
          mt="4"
          mb="9px"
          width="226"
          rounded="4"
          h="4"
          startColor={customTheme.colors.blue[20]}
          key={'skeleton_0'}
        />
        <Separator borderColor="$gray20" borderWidth={0.5} />
        <Skeleton.Text
          width="86%"
          h="13px"
          mt="10px"
          mb="12px"
          startColor={customTheme.colors.blue[20]}
          fadeDuration={3}
          key={'skeleton_1'}
        />
        <Skeleton.Text
          width="96%"
          h="13px"
          mb="12px"
          startColor={customTheme.colors.blue[20]}
          fadeDuration={3}
          key={'skeleton_2'}
        />
        <Skeleton.Text
          width="87%"
          h="13px"
          mb="12px"
          startColor={customTheme.colors.blue[20]}
          fadeDuration={3}
          key={'skeleton_3'}
        />
        <Skeleton.Text
          width="87%"
          h="13px"
          startColor={customTheme.colors.blue[20]}
          mb="28px"
          fadeDuration={3}
          key={'skeleton_4'}
        />
      </YStack>
    </View>
  );
};

export default SkeletonView;
