import { View, YStack } from 'tamagui';
import { Dimensions, Platform, RefreshControl } from 'react-native';
import { useSelector } from 'react-redux';
import { FlashList } from '@shopify/flash-list';
import {
  getShortDateISO,
  isoDateToTransactionsWidgetDate,
} from '@utils/dateHelpers';
import { TransactionPreview } from '@molecules';
import { Loader, Text } from '@atoms';
import { ITransaction } from '@store/features/transactions/types';

import {
  transactionToAmount,
  transactionToCategory,
  transactionToIsIncome,
  transactionToTitle,
} from '@utils/transactionsHelper';
import { transactionsToIcon } from '@utils/categoryToIcon';
import { selectAccountIdsToNames } from '@store/features/cards/selectors';

interface TransactionListProps {
  transactions: ITransaction[] | null;
  onPress: (id: string) => void;
  withHighlight?: boolean;
  from?: string;
  setLoaderOverlay?: (value: boolean) => void;
  transactionsSearchText?: string;
  transactionsFilteredBy?: string;
  isLoading?: boolean;
  onRefreshTransactionsList?: () => void;
  isRefreshLoading?: boolean;
}

const accountScrollViewHeight = Dimensions.get('screen').height / 1.5;

const TransactionEmptyComponent = ({
  transactionsSearchText,
  transactionsFilteredBy,
  isLoading,
}: {
  transactionsSearchText?: string;
  transactionsFilteredBy?: string;
  isLoading?: boolean;
}) => (
  <View
    display={'flex'}
    flexDirection={'column'}
    justifyContent={'center'}
    alignItems={'center'}
    flex={1}
    flexGrow={1}
    height={accountScrollViewHeight}
  >
    {isLoading ? (
      <View
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        flexGrow={1}
      >
        <Loader width={64} height={64} />
      </View>
    ) : (
      <View
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        flexGrow={1}
      >
        {(transactionsSearchText && transactionsSearchText.length > 0) ||
        transactionsFilteredBy !== '' ? (
          <YStack justifyContent="center" alignItems="center" flexGrow={1}>
            <Text variant="subtitle" fontWeight={'700'} color={'$gray50'}>
              No results found
            </Text>
            <Text variant="subtitle" color={'$gray50'}>
              Try changing your search, dates, or filters.
            </Text>
          </YStack>
        ) : (
          <YStack justifyContent="center" alignItems="center" flexGrow={1}>
            <Text variant="subtitle" color={'$gray50'}>
              No transactions yet
            </Text>
          </YStack>
        )}
      </View>
    )}
  </View>
);

const TransactionsList = ({
  transactions,
  onPress,
  withHighlight = false,
  setLoaderOverlay,
  transactionsFilteredBy,
  transactionsSearchText,
  isLoading,
  onRefreshTransactionsList,
  isRefreshLoading,
}: TransactionListProps) => {
  const accountIdsToNames = useSelector(selectAccountIdsToNames);
  const transactionData = transactions?.slice(0);
  return Platform.OS === 'web' ? (
    <View
      accessible={false}
      accessibilityLabel="Transactions List"
      testID={'paychexpay.transactionsList.content'}
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
    >
      {transactions && transactions.length > 0 && !isLoading ? (
        <>
          {transactions.map((transaction, index, arr) => {
            return (
              <View
                key={`${transaction.transaction_id}_running_balance_${transaction.running_balance}`}
              >
                {(index === 0 ||
                  getShortDateISO(arr[index].transaction_datetime) !==
                    getShortDateISO(arr[index - 1].transaction_datetime)) && (
                  <View mt="$4">
                    <Text variant="subtitle">
                      {isoDateToTransactionsWidgetDate(
                        transaction.transaction_datetime
                      )}
                    </Text>
                  </View>
                )}
                <TransactionPreview
                  onPress={() => {
                    onPress(transaction.transaction_id);
                  }}
                  icon={transactionsToIcon(transaction)}
                  title={transactionToTitle(transaction, accountIdsToNames)}
                  subtitle={transactionToCategory(
                    transaction,
                    accountIdsToNames
                  )}
                  amount={transactionToAmount(transaction)}
                  isIncome={transactionToIsIncome(transaction)}
                  withHighlight={withHighlight}
                  index={index}
                />
              </View>
            );
          })}
        </>
      ) : (
        <TransactionEmptyComponent
          transactionsSearchText={transactionsSearchText || ''}
          transactionsFilteredBy={transactionsFilteredBy || ''}
          isLoading={isLoading || false}
        />
      )}
    </View>
  ) : (
    <View
      accessible={false}
      flex={1}
      height={'100%'}
      accessibilityLabel="Transactions List"
      testID={'paychexpay.transactionsList.content'}
    >
      <FlashList
        data={isLoading ? [] : transactionData}
        removeClippedSubviews
        estimatedItemSize={56}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.transaction_id}
        refreshControl={
          <RefreshControl
            refreshing={(isRefreshLoading || false) && (!isLoading || false)}
            onRefresh={onRefreshTransactionsList}
          />
        }
        ListEmptyComponent={
          <TransactionEmptyComponent
            transactionsSearchText={transactionsSearchText || ''}
            transactionsFilteredBy={transactionsFilteredBy || ''}
            isLoading={isLoading || false}
          />
        }
        onBlankArea={(event) => {
          if (setLoaderOverlay) {
            event.offsetEnd < -1000
              ? setLoaderOverlay(true)
              : setTimeout(() => {
                  setLoaderOverlay(false);
                }, 2000);
          }
        }}
        renderItem={({ item: transaction, index }) => (
          <View
            key={`${transaction.transaction_id}_running_balance_${transaction.running_balance}`}
          >
            {(index === 0 ||
              (transactionData &&
                getShortDateISO(transactionData[index].transaction_datetime) !==
                  getShortDateISO(
                    transactionData[index - 1].transaction_datetime
                  ))) && (
              <View mt="$4">
                <Text variant="subtitle">
                  {isoDateToTransactionsWidgetDate(
                    transaction.transaction_datetime
                  )}
                </Text>
              </View>
            )}
            <TransactionPreview
              onPress={() => {
                onPress(transaction.transaction_id);
              }}
              icon={transactionsToIcon(transaction)}
              title={transactionToTitle(transaction, accountIdsToNames)}
              subtitle={transactionToCategory(transaction, accountIdsToNames)}
              amount={transactionToAmount(transaction)}
              isIncome={transactionToIsIncome(transaction)}
              withHighlight={withHighlight}
              index={index}
            />
          </View>
        )}
        refreshing
      />
    </View>
  );
};

export default TransactionsList;
