const endpoints = {
  userInfo: '/userInfo',
  logoutFromSessions: '/sessionLogout',
  additionalCardholderInfo: '/additional-cardholder-info',
  checkIsEligible: '/is-paychex-pay-eligible',
  cardholder: '/cardmanagement/cardholders',
  variables: '/get-ui-variables',
  addressSuggestions: '/addressHandler/suggestions',
  addressVerify: '/addressHandler/verify',
  getAgreementSaving: '/eula/get-doc/Paychex Pay Savings Account Agreement',
  getAgreementById: '/eula/get-doc/{document_id}',
  postAgreementAcknowledgement: '/eula/save-response/{document_id}',
  atmLocations: '/cardmanagement/atm-locations',
  getCardInfo: '/cardmanagement/cards/{card_id}',
  getCardAuthInfo: '/cardmanagement/cards/{card_id}/auth-info',
  putCardStatus: '/cardmanagement/cards/{card_id}/status/{status}',
  getTransactions: '/cardmanagement/cards/{card_id}/all-transactions',
  getTransactionsByFilter:
    '/cardmanagement/cards/{card_id}/filter-transactions/{filter_type}',
  getTransactionsBySearch:
    '/cardmanagement/cards/{card_id}/search-transactions',
  createSavingAccount: '/cardmanagement/cards/create-savings-account',
  activePhysicalCard: '/cardmanagement/cards/{card_id}/activate-physical-card',
  submitOwwQuestions: '/cardmanagement/cardholders/oow-answers',
  getStatementsList: '/cardmanagement/statements-list/{card_id}',
  getStatement: '/cardmanagement/statement/{statement_id}',
  transfers: '/cardmanagement/transfers',
  networkTransfers: '/cardmanagement/network-transfers',
  changePin: '/cardmanagement/widgets/pin-change',
  payBills: '/cardmanagement/widgets/bill-pay',
  rewards: '/cardmanagement/widgets/rewards',
  orderCard: '/cardmanagement/cards/reissue/{card_id}',
  externalBankAccounts: '/cardmanagement/bank-accounts',
  outgoingTransfer: '/cardmanagement/bank-transfers/outgoing',
  incomingTransfer: '/cardmanagement/bank-transfers/incoming',
  getMobileCheckLoadCreds: '/cardmanagement/mobile-check-load',
  notifications: '/notification-preferences',
  generateDda: '/generate-direct-deposit',
  ddaEligibility: '/direct-deposit-eligibility',
  errorInfo: '/log-ui-events',
};

export default endpoints;

export const baseUrl = '{{bank.paychexbank.rem.lb.url}}';

export const directDepositEligibleLink =
  'https://' +
  '{{bank.paychexbank.deeplink.url}}' +
  '/landing_remote/login.do#?app=MY_PAY&appContext={"action":"ADD_BANK_ACCOUNT","type":"PAYCHEX_PAY"}';

export const masterCardDepositCash =
  'https://www.mastercard.us/en-us/personal/get-support/reload-a-prepaid-card.html';
