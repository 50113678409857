import { createMedia } from '@tamagui/react-native-media-driver';
import { createFont, createTamagui, createTokens } from 'tamagui';
import { normalizeFontSize } from './customTheme'; // or '@tamagui/core'

const sharedFontProps = {
  size: {
    h1: normalizeFontSize(24),
    h2: normalizeFontSize(20),
    h3: normalizeFontSize(16),
    h4: normalizeFontSize(14),
    h5: normalizeFontSize(14),
    body: normalizeFontSize(16),
    true: normalizeFontSize(16),
    caption: normalizeFontSize(12),
  },
  lineHeight: { h1: normalizeFontSize(24), h2: normalizeFontSize(20) },
  weight: {
    true: '400',
    regular: '400',
    medium: '500',
    semiBold: '600',
    bold: '800',
  },
  letterSpacing: {},
  face: {
    100: {
      normal: 'Inter-Light',
    },
    200: {
      normal: 'Inter-Light',
    },
    300: {
      normal: 'Inter-Light',
    },
    400: {
      normal: 'Inter-Regular',
    },
    true: {
      normal: 'Inter-Regular',
    },
    500: {
      normal: 'Inter-Medium',
    },
    600: {
      normal: 'Inter-SemiBold',
    },
    700: {
      normal: 'Inter-SemiBold',
    },
    800: {
      normal: 'Inter-Bold',
    },
  },
};

const interFont = createFont({
  family: 'Inter, sans-serif',
  ...sharedFontProps,
});

const interLightFont = createFont({
  family: 'Inter-Light, sans-serif',
  ...sharedFontProps,
});

const interRegularFont = createFont({
  family: 'Inter-Regular, sans-serif',
  ...sharedFontProps,
});

const interMediumFont = createFont({
  family: 'Inter-Medium, sans-serif',
  ...sharedFontProps,
});

const interSemiBoldFont = createFont({
  family: 'Inter-SemiBold, sans-serif',
  ...sharedFontProps,
});

const interBoldFont = createFont({
  family: 'Inter-Bold, sans-serif',
  ...sharedFontProps,
});

const size = {
  0: 0,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 24,
  6: 32,
  7: 40,
  8: 48,
  9: 64,
  10: 80,
  true: 24,
};

export const tokens = createTokens({
  size,
  space: { ...size, '-1': -5, '-2': -10 },
  radius: { 0: 0, 1: 8, 2: 12 },
  zIndex: { 0: 0, 1: 100, 2: 200 },
  color: {
    primaryNormal: '#0073C4',
    primaryDark: '#0059A2',
    primaryLight: '#1C85CE',
    primaryCoveredWith25PercentBlack: '#2F579E',

    black: '#272830',
    white: '#FFF',

    gray50: '#666C7B',
    gray40: '#6F7686',
    gray35: '#808797',
    gray30: '#B2BAC5',
    gray20: '#DEE3EC',
    gray10: '#EFEFEF',
    gray60: '#656C7B',

    red50: '#DC2433',
    red40: '#F85E5E',
    red20: '#FFE6E6',
    red10: '#FFF6F6',

    violet40: '#9568FF',
    violet20: '#E3D7FF',

    green40: '#0B7E67',
    green20: '#D8F1EA',

    yellow40: '#F8B414',
    yellow20: '#FFEDBD',

    blue20: '#EBF1FA',
    blue10: '#F6F7F9',
  },
});

const shorthands = {
  px: 'paddingHorizontal',
  py: 'paddingVertical',
  pr: 'paddingRight',
  pl: 'paddingLeft',
  pt: 'paddingTop',
  pb: 'paddingBottom',
  mx: 'marginHorizontal',
  my: 'marginVertical',
  mr: 'marginRight',
  ml: 'marginLeft',
  mt: 'marginTop',
  mb: 'marginBottom',
  m: 'margin',
  p: 'padding',
  w: 'width',
  h: 'height',
  bg: 'backgroundColor',
} as const;

const themes = {
  light: {},
};

const tamaguiConfig = createTamagui({
  themes,
  defaultTheme: 'light',
  shorthands,
  fonts: {
    // for tamagui, heading and body are assumed
    heading: interFont,
    body: interFont,
    interLight: interLightFont,
    interRegular: interRegularFont,
    interMedium: interMediumFont,
    interSemiBold: interSemiBoldFont,
    interBold: interBoldFont,
  },
  tokens,

  defaultProps: {
    Separator: {
      borderColor: '$gray20',
      borderWidth: 5,
    },
  },

  // `@tamagui/core` doesn't provide media query capabilities out of the box
  // for native as it is de-coupled from react-native.

  // For web-only, media queries work out of the box and you can avoid the
  // `createMedia` call here by passing the media object directly.

  // If targeting React Native, add this driver and `createMedia` call.
  // `tamagui` and `config` do this for you automatically.
  media: createMedia({
    sm: { maxWidth: 860 },
    sh: { maxHeight: 780 },
    gtSm: { minWidth: 860 + 1 },
    short: { maxHeight: 820 },
    hoverNone: { hover: 'none' },
    pointerCoarse: { pointer: 'coarse' },
  }),
});

type AppConfig = typeof tamaguiConfig;

declare module 'tamagui' {
  interface TamaguiCustomConfig extends AppConfig {}
}

export default tamaguiConfig;
