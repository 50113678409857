import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAxiosResponse } from '@store/api';

const createTypedThunk = <ReqType, RespType>(
  name: string,
  request: (body: ReqType) => Promise<IAxiosResponse<RespType>>
) => {
  return createAsyncThunk(name, async (body: ReqType, { rejectWithValue }) => {
    const { error, data } = await request(body);

    if (error) {
      return rejectWithValue(error);
    }

    return data;
  });
};

const createTypedThunkOptionalParams = <ReqType, RespType>(
  name: string,
  request: (body: ReqType | undefined) => Promise<IAxiosResponse<RespType>>
) => {
  return createAsyncThunk(
    name,
    async (body: ReqType | undefined, { rejectWithValue }) => {
      const { error, data } = await request(body);

      if (error) {
        return rejectWithValue(error);
      }

      return data;
    }
  );
};

export { createTypedThunk, createTypedThunkOptionalParams };
