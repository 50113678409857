import { ReactNode } from 'react';
import useIsPhone from '@hooks/useIsPhone';
import { View, XStack } from 'tamagui';
import { Text } from 'react-native';
import { customTheme } from '../styles/customTheme';

// We need to use Text from 'react-native' because we have issue with styling on web for tamagui Text component inside Hyperlink component
const textToTextElements = (
  text: string,
  variableATMFee: string
): ReactNode => {
  const isPhone = useIsPhone();
  return text.split('\n').map((string, index) => {
    const replacedString = string.replace('%VARIABLE%', variableATMFee);

    if (replacedString.startsWith('•')) {
      return (
        <XStack key={replacedString + index}>
          <Text
            style={{
              color: customTheme.colors.gray[40],
              fontSize: 14,
              lineHeight: 20,
              fontFamily: 'Inter-Regular, sans-serif',
              fontWeight: '400',
              paddingHorizontal: isPhone ? 1 : 2,
            }}
          >
            •
          </Text>
          <Text
            style={{
              color: customTheme.colors.gray[40],
              fontSize: 14,
              lineHeight: 20,
              fontFamily: 'Inter-Regular, sans-serif',
              fontWeight: '400',
              width: '100%',
              paddingRight: isPhone ? 4 : 0,
            }}
          >
            {replacedString.slice(2)}
          </Text>
        </XStack>
      );
    }
    if (replacedString.startsWith('#caption_')) {
      return (
        <XStack key={replacedString + index}>
          <Text
            style={{
              color: customTheme.colors.gray[40],
              fontSize: 12,
              lineHeight: 18,
              fontFamily: 'Inter-Regular, sans-serif',
              fontWeight: '400',
            }}
          >
            {replacedString.slice(10)}
          </Text>
        </XStack>
      );
    }
    if (/^\d[.]/.exec(replacedString)) {
      return (
        <XStack key={replacedString + index}>
          <Text
            style={{
              color: customTheme.colors.gray[40],
              fontSize: 14,
              lineHeight: 20,
              fontFamily: 'Inter-Regular, sans-serif',
              fontWeight: '400',
              paddingHorizontal: isPhone ? 1 : 2,
            }}
          >
            {replacedString.slice(0, 2)}
          </Text>
          <Text
            style={{
              color: customTheme.colors.gray[40],
              fontSize: 14,
              lineHeight: 20,
              fontFamily: 'Inter-Regular, sans-serif',
              fontWeight: '400',
              width: '100%',
              paddingRight: isPhone ? 4 : 0,
            }}
          >
            {replacedString.slice(3)}
          </Text>
        </XStack>
      );
    }
    if (replacedString === '#') {
      return <View key={replacedString + index} height={'$4'} />;
    }
    return (
      <Text
        key={replacedString + index}
        style={{
          color: customTheme.colors.gray[40],
          fontSize: 14,
          lineHeight: 20,
          fontFamily: 'Inter-Regular, sans-serif',
          fontWeight: '400',
        }}
      >
        {replacedString}
      </Text>
    );
  });
};

export default textToTextElements;
