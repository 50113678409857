import { RadioGroupTypes } from '@molecules/RadioGroup/RadioGroup.types';
import { Text } from '@atoms';
import useIsPhone from '@hooks/useIsPhone';
import { Platform } from 'react-native';
import { View } from 'tamagui';
import { Radio } from 'native-base';

const RadioGroup = ({
  options,
  onChange,
  accessibilityLabel,
  value,
  selectedHover = true,
}: RadioGroupTypes) => {
  const isPhone = useIsPhone();

  return (
    <View>
      <Radio.Group
        name="options"
        onChange={onChange}
        accessibilityLabel={accessibilityLabel}
        value={value}
      >
        {options.map((option, index) => (
          <View
            key={index}
            paddingVertical={'$2'}
            {...(option.additionalInfoLabel && {
              borderRadius: 8,
              borderColor: '$gray20',
              borderWidth: 1,
              width: '100%',
              padding: 16,
              wordBreak: 'break-word',
            })}
            {...(option.additionalInfoLabel &&
              index !== options.length - 1 && {
                marginBottom: '16px',
              })}
            {...(value === option.value.toString() &&
              selectedHover && {
                bg: '$blue10',
                width: '100%',
              })}
          >
            <Radio
              value={option.value.toString()}
              hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
              {...(Platform.OS !== 'web' && {
                alignItems: 'flex-start',
              })}
            >
              {option.additionalInfoLabel ? (
                <Text
                  fontSize={16}
                  ml={'$3'}
                  color="black"
                  fontWeight="500"
                  paddingBottom={4}
                  testID={`paychexpay.radioGroup.text.${option.label}`}
                  accessible
                  accessibilityLabel={`${option.label}`}
                >
                  {option.label}
                </Text>
              ) : (
                <Text
                  fontSize={16}
                  color="black"
                  ml={'$3'}
                  fontWeight="500"
                  lineHeight="$h1"
                  testID={`paychexpay.radioGroup.text.${option.label}`}
                  accessible
                  accessibilityLabel={`${option.label}`}
                >
                  {option.label}
                </Text>
              )}
            </Radio>
            {option.additionalInfoLabel && (
              <Text
                ml={'$6'}
                {...(!isPhone && {
                  width: '90%',
                })}
              >
                {option.additionalInfoLabel}
              </Text>
            )}
          </View>
        ))}
      </Radio.Group>
    </View>
  );
};

export default RadioGroup;
