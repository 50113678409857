import { HelpProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import useHelp from '@hooks/useHelp';
import { HelpView } from '@views/More';
import useSwiper from '@hooks/useSwiper';
import { slidesArrayLength } from '@constants/onboardingSlides';
import { useSideModal } from '@hooks/useSideModal';
import { sendEmail } from '@utils/communicationServices';
import { supportInfo } from '@constants/support';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPayBillsUrl } from '@store/features/homeWidgets/selectors';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';
import homeWidgetsThunks from '@store/features/homeWidgets/asynkThunks';
import { widgetStateActions } from '@store/features/homeWidgets/slice';
import { useAppDispatch } from '@store/hooks';
import useIsTablet from '@hooks/useIsTablet';
import { Platform } from 'react-native';
import {
  selectDepositAccountAgreement,
  selectFeeAgreement,
} from '@store/features/agreements/selectors';
import { redirectsHelper } from '@utils/redirectsHelper';

const HelpPage = ({ navigation, route }: HelpProps) => {
  const dispatch = useAppDispatch();
  const isTablet = useIsTablet();

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };
  const { menuItems, screenName } = useHelp({
    navigation,
    route,
  });

  const { isModalOpen, modalName, toOpenModal, toCloseModal } = useSideModal({
    route,
    navigation,
  });
  const { onPressNext, onPressPrev, index, setIndex } = useSwiper({
    onPressLastNext: () => false,
    componentsArrayLength: slidesArrayLength,
    toOpenModal,
  });
  const onSendFeedback = () => {
    sendEmail(supportInfo.email);
  };
  const payBillsUrl = useSelector(selectPayBillsUrl);
  const spendingCardId = useSelector(selectSpendingAccountId);
  const feeAgreement = useSelector(selectFeeAgreement);
  const depositAgreement = useSelector(selectDepositAccountAgreement);

  const handlePayBillsPress = () => {
    dispatch(homeWidgetsThunks.billPay({ card_id: spendingCardId }));
  };

  useEffect(() => {
    if (payBillsUrl)
      navigation.navigate('HomeNavigator', {
        screen: 'PayBills',
        params: { payBillsUrl: payBillsUrl },
      });

    dispatch(widgetStateActions.resetPayBillsUrl());
  }, [payBillsUrl]);

  const navigationMap = useCallback(
    (link: string) => {
      redirectsHelper({
        navigation,
        link,
        toOpenModal,
        handlePayBillsPress,
        feeAgreement,
        depositAgreement,
      });
    },
    [navigation]
  );
  return (
    <MediaLayoutTemplate
      Mobile={HelpView.mobile}
      Desktop={isTablet ? HelpView.mobile : HelpView.desktop}
      navigateBack={navigateBack}
      menuItems={menuItems}
      screenName={screenName}
      isModalOpen={isModalOpen}
      modalName={modalName}
      toCloseModal={toCloseModal}
      onPressNext={onPressNext}
      onPressPrev={onPressPrev}
      index={index}
      setIndex={setIndex}
      onSendFeedback={onSendFeedback}
      navigationMap={navigationMap}
    />
  );
};

export default HelpPage;
