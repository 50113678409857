import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCardholder,
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import cardsThunks from '@store/features/cards/asyncThunks';
import transactionThunks from '@store/features/transactions/asyncThunks';
import { cardsActions } from '@store/features/cards/slice';
import { useAppDispatch } from '@store/hooks';
import {
  selectIsLoadingSavingsTransactions,
  selectIsLoadingSpendingTransactions,
  selectSpendingRecentTransactions,
} from '@store/features/transactions/selectors';

import { selectShouldForceRefreshNextTransactionsFetch } from '@store/features/transfers/selectors';
import { transfersActions } from '@store/features/transfers/slice';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
import { tracker } from '../../eventCollector';
import { TimerStopFunction } from '@paychex/core/types/trackers';
import { application, operations } from '@constants/traceability';

export interface ITrackerData {
  timer: TimerStopFunction;
  txid: string;
  bizpn: string;
  subtxnbr: string | undefined;
}

const useHomepageRequests = () => {
  const dispatch = useAppDispatch();

  const cardholder = useSelector(selectCardholder);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingsAccountId = useSelector(selectSavingsAccountId);
  const transactions = useSelector(selectSpendingRecentTransactions);
  const isLoadingSpending = useSelector(selectIsLoadingSpendingTransactions);
  const isLoadingSavings = useSelector(selectIsLoadingSavingsTransactions);
  const shouldForceRefreshNextTransactionsFetch = useSelector(
    selectShouldForceRefreshNextTransactionsFetch
  );

  const { onLogout, accessTokenRenew } = useAuth0();

  useEffect(() => {
    if (cardholder.currentStatus !== 'fetchedFirstTime') {
      dispatch(cardholderThunks.getCardholder());
      dispatch(cardholderThunks.getCardholderAdditionalInfo());
    }
  }, []);

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const fetchSpendingAccount = async (
    trackerData: ITrackerData | undefined
  ) => {
    if (spendingAccountId) {
      // Load Recent Transactions CT (Transactions Widget Home Page)
      const spendingTransactionsTimer = tracker.start(
        'Load Transactions Widget: Fetch Spending Transactions'
      );
      const spendingTransactionsTxid = tracker.uuid();
      const spendingTransactionsBizpn =
        application.bizpnPrefix + operations.SpendingTransactions;
      const spendingTransactionsComp =
        application.compPrefix + operations.SpendingTransactions;
      await dispatch(
        cardsThunks.getCardInfo({
          cardId: spendingAccountId,
          additionalHeaders: {
            'x-payx-txid': trackerData?.txid || tracker.uuid(),
            'x-payx-bizpn': trackerData?.bizpn,
            'x-payx-subtxnbr': trackerData?.subtxnbr,
          },
        })
      )
        .then(() => {
          if (trackerData?.timer) {
            trackerData.timer({
              bizpn: trackerData.bizpn,
              txid: trackerData.txid,
              subtxnbr: trackerData.subtxnbr,
            });
          }
        })
        .catch((err) => handleRequestError(err));
      await dispatch(
        cardsThunks.getCardAuthInfo({ cardId: spendingAccountId })
      ).catch((err) => handleRequestError(err));
      dispatch(cardsActions.setRequestSpendingRequestStatus(null));
      !isLoadingSpending &&
        dispatch(
          transactionThunks.getSpendingTransactions({
            cardId: spendingAccountId,
            forceRefresh: shouldForceRefreshNextTransactionsFetch,
            additionalHeaders: {
              'x-payx-txid': spendingTransactionsTxid || tracker.uuid(),
              'x-payx-bizpn': spendingTransactionsBizpn,
              'x-payx-comp': spendingTransactionsComp,
            },
          })
        )
          .unwrap()
          .then(() => {
            spendingTransactionsTimer({
              bizpn: spendingTransactionsBizpn,
              txid: spendingTransactionsTxid,
            });
          })
          .catch((err) => handleRequestError(err));
    }
  };
  const fetchSavingsAccount = async (trackerData: ITrackerData | undefined) => {
    if (savingsAccountId) {
      await dispatch(
        cardsThunks.getCardInfo({
          cardId: savingsAccountId,
          additionalHeaders: {
            'x-payx-txid': trackerData?.txid || tracker.uuid(),
            'x-payx-bizpn': trackerData?.bizpn,
            'x-payx-subtxnbr': trackerData?.subtxnbr,
          },
        })
      )
        .unwrap()
        .then(() => {
          if (trackerData?.timer) {
            trackerData.timer({
              bizpn: trackerData.bizpn,
              txid: trackerData.txid,
              subtxnbr: trackerData.subtxnbr,
            });
          }
        })
        .catch((err) => handleRequestError(err));
      dispatch(cardsActions.setRequestSavingsRequestStatus(null));
      !isLoadingSavings &&
        dispatch(
          transactionThunks.getSavingsTransactions({
            cardId: savingsAccountId,
            forceRefresh: shouldForceRefreshNextTransactionsFetch,
          })
        )
          .unwrap()
          .catch((err) => handleRequestError(err));
    }

    dispatch(transfersActions.resetForceRefresh());
  };

  const fetchSavingData = useCallback(
    (trackerData?: ITrackerData | undefined) => {
      if (savingsAccountId) {
        dispatch(cardsActions.setRequestSavingsRequestStatus('pending'));
        fetchSavingsAccount(trackerData);
      }
    },
    [savingsAccountId]
  );

  const fetchSpendingData = useCallback(
    (trackerData?: ITrackerData | undefined) => {
      dispatch(cardsActions.setRequestSpendingRequestStatus('pending'));
      fetchSpendingAccount(trackerData);
    },
    [spendingAccountId]
  );

  const fetchDataOnRender = () => {
    if (isLoadingSpending || isLoadingSavings) {
      return;
    }
    if (!transactions.length) {
      !isLoadingSpending && fetchSpendingData();
      !isLoadingSavings && fetchSavingData();
    }
  };

  return { fetchDataOnRender, fetchSpendingData, fetchSavingData };
};

export default useHomepageRequests;
