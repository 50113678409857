import { ToExternalBankWebProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { ToExternalBankWebView } from '@views/Transfers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAccountsCategories,
  selectSavingsAccountPreviewInfo,
  selectSpendingAccountPreviewInfo,
} from '@store/features/cards/selectors';
import { selectTransferStatus } from '@store/features/transfers/selectors';
import transfersThunks from '@store/features/transfers/asyncThunks';
import { transfersActions } from '@store/features/transfers/slice';
import idToAccount from '@utils/idToAccount';
import { selectExternalAccounts } from '@store/features/externalBankAccounts/selectors';
import { cloneDeep } from 'lodash';
import cardsThunks from '@store/features/cards/asyncThunks';
import externalBankAccountsThunks from '@store/features/externalBankAccounts/asyncThunks';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import { useForm } from 'react-hook-form';
import {
  ConnectAccountSchema,
  IConnectAccountFormInputs,
} from '@organisms/ConnectAccountForm/helper';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useAppDispatch } from '@store/hooks';
import { ICategoryAccount } from '@devTypes/accountTypes';
import { IDeleteAccountFormInputs } from '@organisms/DeleteAccountForm/helper';
import { MAX_TRANSFER_COMMENT_LENGTH } from '@constants/general';
import transactionThunks from '@store/features/transactions/asyncThunks';
import {
  selectCardholder,
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import amountCommaCleaner from '@utils/amountCommaCleaner';
import { tracker } from '../../../eventCollector';
import { application, operations } from '@constants/traceability';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

const defaultValues = {
  achNick: '',
  accountType: '',
  accountNumber: '',
  accountOwnership: '',
  bankName: '',
  routingNumber: '',
};

const ToExternalBankWebPage = ({
  navigation,
  route,
}: ToExternalBankWebProps) => {
  const dispatch = useAppDispatch();
  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };
  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const savingsAccount = useSelector(selectSavingsAccountPreviewInfo);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingsAccountId = useSelector(selectSavingsAccountId);
  const userProfileData = useSelector(selectCardholder);

  useEffect(() => {
    dispatch(externalBankAccountsThunks.getExternalAccounts())
      .unwrap()
      .catch((err) => handleRequestError(err));
  }, []);

  const [isCenterModalOpen, setIsCenterModalOpen] = useState(false);
  const [
    isDeleteExternalAccountModalOpen,
    setIsDeleteExternalAccountModalOpen,
  ] = useState(false);
  const [bankAccountId, setBankAccountId] = useState('');

  const { isModalOpen, modalName, toOpenModal, toCloseModal } = useSideModal({
    route,
    navigation,
  });

  const onOpenCenterModal = () => {
    setIsCenterModalOpen(true);
  };
  const onCloseCenterModal = () => {
    setIsCenterModalOpen(false);
    dispatch(
      transactionThunks.getSpendingTransactions({
        cardId: spendingAccount.id,
        forceRefresh: true,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
    if (savingsAccount) {
      dispatch(
        transactionThunks.getSavingsTransactions({
          cardId: savingsAccount.id,
          forceRefresh: true,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
    navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
  };
  const onOpenDeleteExternalAccountModal = () => {
    setIsDeleteExternalAccountModalOpen(true);
  };
  const onCloseDeleteExternalAccountModal = () => {
    setIsDeleteExternalAccountModalOpen(false);
  };

  const { control: deleteAccountControl, setValue: deleteAccountFormSetValue } =
    useForm<IDeleteAccountFormInputs>({
      defaultValues,
    });

  const addExternalAccountHandler = () => {
    toOpenModal(sideModalNames.addExternalAccount)();
  };
  const deleteExternalAccountHandler = (account: ICategoryAccount) => {
    deleteAccountFormSetValue('achNick', account.name);
    deleteAccountFormSetValue(
      'accountType',
      account.type === 'spending'
        ? 'checking'
        : account.type === 'saving'
        ? 'savings'
        : ''
    );
    deleteAccountFormSetValue('accountOwnership', account.accountOwnership);
    deleteAccountFormSetValue('accountNumber', account.accountNumber);
    deleteAccountFormSetValue('bankName', account.bankName);
    deleteAccountFormSetValue('routingNumber', account.routingNumber);

    setBankAccountId(account.id);
    toOpenModal(sideModalNames.deleteExternalAccount)();
  };

  const deleteAccount = async () => {
    setIsButtonLoading(true);
    await dispatch(
      externalBankAccountsThunks.deleteExternalAccount({
        accountId: bankAccountId,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
    dispatch(externalBankAccountsThunks.getExternalAccounts())
      .unwrap()
      .catch((err) => handleRequestError(err));
    setIsButtonLoading(false);
    toCloseModal();
    onCloseDeleteExternalAccountModal();
  };

  const onDeleteAccount = async () => {
    await deleteAccount();
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<IConnectAccountFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(ConnectAccountSchema),
    defaultValues,
  });
  const [isAnimateClose, setIsAnimateClose] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const onSubmit = async (data: IConnectAccountFormInputs) => {
    setIsButtonLoading(true);
    const formattedAccountNumber = data.accountNumber.padStart(10, '0');
    await dispatch(
      externalBankAccountsThunks.postExternalAccount({
        account_number: formattedAccountNumber,
        routing_number: data.routingNumber,
        account_type: data.accountType === 'Checking' ? 'CHECKING' : 'SAVINGS',
        account_title: data.achNick,
        bank_name: data.bankName,
        account_ownership: data.accountOwnership,
        country: 'USA',
        currency_code: 'USD',
      })
    );
    setIsButtonLoading(false);
    reset({
      achNick: '',
      accountType: '',
      accountOwnership: '',
      accountNumber: '',
      bankName: '',
      routingNumber: '',
    });
    dispatch(externalBankAccountsThunks.getExternalAccounts());
    setIsAnimateClose(true);
    setIsAnimateClose(false);
  };
  const externalAccounts = useSelector(selectExternalAccounts);

  const { toAccount, isTransferDisabled } = useMemo(() => {
    return {
      toAccount: idToAccount(
        route.params?.toAccountId,
        spendingAccount,
        savingsAccount,
        externalAccounts
      ),
      isTransferDisabled: !route.params?.toAccountId,
    };
  }, [route]);

  const fromAccount = useMemo(() => {
    return idToAccount(
      spendingAccountId,
      spendingAccount,
      savingsAccount,
      externalAccounts
    );
  }, [route, spendingAccountId, spendingAccount, savingsAccount]);

  const isFromExternalAccount = useMemo(() => {
    return (
      fromAccount.id !== spendingAccount.id &&
      fromAccount.id !== savingsAccount.id
    );
  }, [fromAccount]);

  const [comment, setComment] = useState('');
  const [amount, setAmount] = useState('');

  const isNotExceedBalance = useMemo(() => {
    return (
      !isFromExternalAccount &&
      fromAccount.amount <
        (isNaN(parseFloat(amountCommaCleaner(amount)))
          ? 0
          : parseFloat(amountCommaCleaner(amount)))
    );
  }, [amount, route, fromAccount]);

  const isButtonDisabled = useMemo(
    () =>
      comment.length > MAX_TRANSFER_COMMENT_LENGTH ||
      isTransferDisabled ||
      isNotExceedBalance ||
      (isNaN(parseFloat(amountCommaCleaner(amount)))
        ? 0
        : parseFloat(amountCommaCleaner(amount))) === 0,
    [amount, isTransferDisabled, isNotExceedBalance, comment]
  );

  const selectAccountType = useMemo(() => route.params?.selectAccount, [route]);

  const allAccounts = useSelector(selectAccountsCategories);
  const filteredAccounts = useMemo(() => {
    const currentAccounts = cloneDeep(allAccounts);
    if (selectAccountType === 'To') {
      if (
        route.params?.fromAccountId === spendingAccount.id ||
        route.params?.fromAccountId === savingsAccount.id
      ) {
        currentAccounts.spending.accounts[0].isDisabled = true;
        if (savingsAccount.id) {
          currentAccounts.saving.accounts[0].isDisabled = true;
        }
      } else if (route.params?.fromAccountId) {
        currentAccounts.spending.accounts =
          currentAccounts.spending.accounts.map((acc, index) => ({
            ...acc,
            isDisabled: !!index,
          }));
        currentAccounts.saving.accounts = currentAccounts.saving.accounts.map(
          (acc, index) => ({
            ...acc,
            isDisabled: !!index || !savingsAccount.id,
          })
        );
      }
    } else {
      if (
        route.params?.toAccountId === spendingAccount.id ||
        route.params?.toAccountId === savingsAccount.id
      ) {
        currentAccounts.spending.accounts[0].isDisabled = true;
        if (savingsAccount.id) {
          currentAccounts.saving.accounts[0].isDisabled = true;
        }
      } else if (route.params?.toAccountId) {
        currentAccounts.spending.accounts =
          currentAccounts.spending.accounts.map((acc, index) => ({
            ...acc,
            isDisabled: !!index,
          }));
        currentAccounts.saving.accounts = currentAccounts.saving.accounts.map(
          (acc, index) => ({
            ...acc,
            isDisabled: !!index || !savingsAccount.id,
          })
        );
      }
    }

    // Commented due to issue https://uptech.atlassian.net/browse/BLAZ-1655
    // if (currentAccounts.saving.accounts.length > 2) {
    //   currentAccounts.saving.accounts = currentAccounts.saving.accounts.filter(
    //     (acc) =>
    //       acc.id !== route.params?.toAccountId &&
    //       acc.id !== route.params?.fromAccountId
    //   );
    // }
    // if (currentAccounts.spending.accounts.length > 2) {
    //   currentAccounts.spending.accounts =
    //     currentAccounts.spending.accounts.filter(
    //       (acc) =>
    //         acc.id !== route.params?.toAccountId &&
    //         acc.id !== route.params?.fromAccountId
    //     );
    // }
    return currentAccounts;
  }, [allAccounts, selectAccountType]);

  const navigateToSelect = (selectAccountType: 'From' | 'To') => () => {
    navigation.setParams({ selectAccount: selectAccountType });
  };
  const navigateSelectBack = () => {
    navigation.setParams({ selectAccount: undefined });
  };

  const selectAccount = (id: string) => {
    navigation.setParams(
      selectAccountType === 'From'
        ? { fromAccountId: id, selectAccount: undefined }
        : { toAccountId: id, selectAccount: undefined }
    );
  };

  const transferStatus = useSelector(selectTransferStatus);
  const [isPressedOnce, setIsPressedOnce] = useState(false);
  const onPressTransfer = () => {
    if (!isPressedOnce) {
      setIsPressedOnce(true);
      const externalAccount = externalAccounts.find(
        (acc) => acc.id === toAccount.id
      );
      if (externalAccount) {
        // ACH transfer complete CT
        const transferTimer = tracker.start(
          'To External Bank (Web): Post Outgoing Transfer'
        );
        const transferTxid = tracker.uuid();
        const transferBizpn =
          application.bizpnPrefix + operations.OutgoingTransfer;
        const transferComp =
          application.compPrefix + operations.OutgoingTransfer;
        dispatch(
          transfersThunks.postOutgoing({
            amount: parseFloat(amountCommaCleaner(amount)),
            card_id: fromAccount.id,
            bank: {
              account_number: externalAccount.accountNumber,
              routing_number: externalAccount.routingNumber,
              account_type:
                externalAccount.type === 'spending' ? 'CHECKING' : 'SAVINGS',
              account_title: externalAccount.name,
              name: externalAccount.bankName,
              account_id: externalAccount.id,
            },
            same_day_transfer: false,
            description: comment,
            additionalHeaders: {
              'x-payx-txid': transferTxid || tracker.uuid(),
              'x-payx-bizpn': transferBizpn,
              'x-payx-comp': transferComp,
            },
          })
        )
          .unwrap()
          .then(() => {
            transferTimer({
              txid: transferTxid,
              bizpn: transferBizpn,
            });
          })
          .catch((err) => handleRequestError(err));
      }
    }
  };

  const clearTransferData = useCallback(() => {
    setAmount('');
    setComment('');
  }, []);
  useEffect(() => {
    if (transferStatus === 'success' || transferStatus === 'error') {
      navigation.setParams({ isCompleted: transferStatus === 'success' });
      clearTransferData();
      onOpenCenterModal();
      dispatch(transfersActions.resetStatus());
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccount.id }))
        .unwrap()
        .catch((err) => handleRequestError(err));
      dispatch(cardsThunks.getCardInfo({ cardId: savingsAccount.id }))
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
  }, [transferStatus]);

  useEffect(() => {
    // ACH transfer List CT
    const spendingTimer = tracker.start(
      'To External Bank (Web): Fetch Spending Account'
    );
    const savingsTimer = tracker.start(
      'To External Bank (Web): Fetch Savings Account'
    );
    const externalTimer = tracker.start(
      'To External Bank (Web): Fetch External Accounts'
    );
    const txid = tracker.uuid();
    const spendingBizpn = application.bizpnPrefix + operations.SpendingAccount;
    const spendingComp = application.compPrefix + operations.SpendingAccount;
    const savingsBizpn = application.bizpnPrefix + operations.SpendingAccount;
    const savingsComp = application.compPrefix + operations.SavingsAccount;
    const externalBizpn = application.bizpnPrefix + operations.ExternalAccount;
    const externalComp = application.compPrefix + operations.ExternalAccount;
    let subtxnbr = 1;
    const spendingSubtxnbr = subtxnbr;
    if (spendingAccountId) {
      dispatch(
        cardsThunks.getCardInfo({
          cardId: spendingAccountId,
          additionalHeaders: {
            'x-payx-txid': txid || tracker.uuid(),
            'x-payx-subtxnbr': spendingSubtxnbr,
            'x-payx-bizpn': spendingBizpn,
            'x-payx-comp': spendingComp,
          },
        })
      ).then(() => {
        spendingTimer({
          txid: txid,
          bizpn: spendingBizpn,
          subtxnbr: spendingSubtxnbr,
        });
      });
    }
    if (savingsAccountId) {
      subtxnbr++;
      const savingsSubtxnbr = subtxnbr;
      dispatch(
        cardsThunks.getCardInfo({
          cardId: savingsAccountId,
          additionalHeaders: {
            'x-payx-txid': txid || tracker.uuid(),
            'x-payx-subtxnbr': savingsSubtxnbr,
            'x-payx-bizpn': savingsBizpn,
            'x-payx-comp': savingsComp,
          },
        })
      )
        .unwrap()
        .then(() => {
          savingsTimer({
            txid: txid,
            bizpn: savingsBizpn,
            subtxnbr: savingsSubtxnbr,
          });
        })
        .catch((err) => handleRequestError(err));
    }
    subtxnbr++;
    const externalSubtxnbr = subtxnbr;
    dispatch(
      externalBankAccountsThunks.getExternalAccounts({
        additionalHeaders: {
          'x-payx-txid': txid || tracker.uuid(),
          'x-payx-subtxnbr': externalSubtxnbr,
          'x-payx-bizpn': externalBizpn,
          'x-payx-comp': externalComp,
        },
      })
    )
      .unwrap()
      .then(() => {
        externalTimer({
          txid: txid,
          bizpn: externalBizpn,
          subtxnbr: externalSubtxnbr,
        });
      })
      .catch((err) => handleRequestError(err));
  }, [spendingAccountId, savingsAccountId]);

  const accountNumber = watch('accountNumber');
  const achNick = watch('achNick');
  const accountType = watch('accountType');
  const accountOwnership = watch('accountOwnership');
  const bankName = watch('bankName');
  const routingNumber = watch('routingNumber');
  const isAddAccountButtonDisabled = useMemo(() => {
    return (
      accountNumber === '' ||
      achNick === '' ||
      accountType === '' ||
      accountOwnership === '' ||
      bankName === '' ||
      routingNumber === ''
    );
  }, [
    accountNumber,
    achNick,
    accountType,
    bankName,
    routingNumber,
    accountOwnership,
  ]);
  const [isBankAcntNameInputDisabled, setIsBankAcntNameInputDisabled] =
    useState(false);

  useEffect(() => {
    if (accountOwnership === 'SELF') {
      setValue(
        'achNick',
        userProfileData.first_name + ' ' + userProfileData.last_name
      );
      setIsBankAcntNameInputDisabled(true);
    } else {
      setIsBankAcntNameInputDisabled(false);
    }
  }, [accountOwnership]);

  const [isInsufficientFundsAlertVisible, setIsInsufficientFundsAlertVisible] =
    useState(false);

  useEffect(() => {
    if (fromAccount.amount === 0 && spendingAccount.id) {
      setIsInsufficientFundsAlertVisible(true);
    }
  }, [fromAccount, spendingAccount.id]);

  const hideInsufficientFundsAlertModal = () => {
    setIsInsufficientFundsAlertVisible(false);
    navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
  };

  return (
    <ToExternalBankWebView
      toAccount={toAccount}
      fromAccount={fromAccount}
      onPressTransfer={onPressTransfer}
      isButtonDisabled={isButtonDisabled}
      comment={comment}
      amount={amount}
      isCenterModalOpen={isCenterModalOpen}
      isDeleteExternalAccountModalOpen={isDeleteExternalAccountModalOpen}
      onCloseCenterModal={onCloseCenterModal}
      onCloseDeleteExternalAccountModal={onCloseDeleteExternalAccountModal}
      onOpenDeleteExternalAccountModal={onOpenDeleteExternalAccountModal}
      setComment={setComment}
      setAmount={setAmount}
      transferStatus={transferStatus}
      isCompleted={!!route.params?.isCompleted}
      isNotExceedBalance={isNotExceedBalance}
      selectAccountType={selectAccountType}
      accounts={filteredAccounts}
      navigateToSelect={navigateToSelect}
      selectAccount={selectAccount}
      navigateSelectBack={navigateSelectBack}
      isModalOpen={isModalOpen}
      modalName={modalName}
      toCloseModal={toCloseModal}
      addExternalAccountHandler={addExternalAccountHandler}
      deleteExternalAccountHandler={deleteExternalAccountHandler}
      control={control}
      deleteAccountControl={deleteAccountControl}
      onSubmit={handleSubmit(onSubmit)}
      onDeleteAccount={onDeleteAccount}
      isAddAccountButtonDisabled={
        Object.keys(errors).length !== 0 || isAddAccountButtonDisabled
      }
      isButtonLoading={isButtonLoading}
      isAnimateClose={isAnimateClose}
      isBankAcntNameInputDisabled={isBankAcntNameInputDisabled}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    />
  );
};

export default ToExternalBankWebPage;
