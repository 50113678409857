import { MediaLayoutChildren } from '@devTypes/types';
import useIsPhone from '@hooks/useIsPhone';

const MediaLayoutTemplate = <T extends Record<any, unknown>>({
  Mobile,
  Desktop,
  ...rest
}: MediaLayoutChildren & T) => {
  const isPhone = useIsPhone();
  if (Mobile && isPhone) {
    return <Mobile {...rest} />;
  } else if (Desktop && !isPhone) {
    return <Desktop {...rest} />;
  }
  return null;
};

export default MediaLayoutTemplate;
