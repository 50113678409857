import { Center, Image } from 'native-base';
import WebView from 'react-native-webview';
import React from 'react';
import { Text } from '@atoms';
import { WebLogoTemplate } from '@templates';
import { useWindowDimensions, Platform } from 'react-native';
import useIsTablet from '@hooks/useIsTablet';
import { maxMediaQuery } from '@constants/ui';
import LottieView from 'lottie-react-native';
import LoadingEllipsis from '@utils/LoadingEllipsis';
import { View } from 'tamagui';
interface CreateCardholderLoaderViewProps {
  loginInfo: any;
  handleWebViewNavigationStateChange: () => void;
  isWebViewLoading: boolean;
}

const CreateCardholderLoaderContentWeb = () => {
  return (
    <Center flexGrow={1}>
      <Image
        source={require('../../../assets/lotties/cardholder.gif')}
        width="250px"
        height="250px"
      />
      <Text
        fontSize={14}
        color="$gray50"
        marginTop="4"
        width={90}
        textAlign="center"
      >
        <View display="flex" alignItems="flex-start">
          <LoadingEllipsis text={'Processing'} />
        </View>
      </Text>
    </Center>
  );
};

const CreateCardholderLoaderContentModile = () => {
  return Platform.OS === 'web' ? (
    <CreateCardholderLoaderContentWeb />
  ) : (
    <Center flexGrow={1}>
      <LottieView
        source={require('../../../assets/lotties/cardholder.json')}
        autoPlay
        loop
        style={{ width: 250, height: 250 }}
      />
      <Text
        fontSize={14}
        color="$gray50"
        marginTop="4"
        width={310}
        textAlign="center"
      >
        <LoadingEllipsis text={'Processing'} />
      </Text>
    </Center>
  );
};

const mobile = ({
  loginInfo,
  handleWebViewNavigationStateChange,
  isWebViewLoading,
}: CreateCardholderLoaderViewProps) => {
  return (
    <View flexGrow={1} bg="white">
      <CreateCardholderLoaderContentModile />
      <View h={0}>
        {loginInfo.authorizeUrl !== null && !isWebViewLoading && (
          <WebView
            domStorageEnabled={true}
            source={{ uri: loginInfo.authorizeUrl }}
            startInLoadingState={true}
            renderLoading={() => <></>}
            onNavigationStateChange={handleWebViewNavigationStateChange}
            scalesPageToFit={true}
            style={{
              height: 0,
            }}
          />
        )}
      </View>
    </View>
  );
};

const desktop = () => {
  const { width: desktopWidth } = useWindowDimensions();
  const isTablet = useIsTablet();

  return (
    <WebLogoTemplate bg="$blue10" withLogoIcon={!isTablet}>
      <View
        maxWidth={900}
        maxHeight={740}
        bg="white"
        flexGrow={1}
        my="$5"
        overflow="hidden"
        borderRadius={12}
        width="100%"
        height="100%"
        {...(desktopWidth < 1265 &&
          desktopWidth > 915 && {
            maxWidth: desktopWidth - maxMediaQuery.tablet / 2,
            width: '100%',
          })}
        {...(desktopWidth < 915 && {
          maxWidth: '95%',
          width: '100%',
        })}
      >
        <CreateCardholderLoaderContentWeb />
      </View>
    </WebLogoTemplate>
  );
};

const CreateCardholderLoaderView = {
  mobile,
  desktop,
};

export default CreateCardholderLoaderView;
