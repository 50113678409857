import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { SpendingAccountView } from '@views/Accounts';
import { SpendingAccountProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import useAccount from '@hooks/useAccount';
import { useSelector } from 'react-redux';
import useDebitCardMenu from '@hooks/useDebitCardMenu';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import {
  cardImage,
  selectPhysicalCardStatus,
  selectRequestCardRequestStatus,
  selectShipmentInfo,
  selectSpendingAccountPreviewInfo,
  selectSpendingCardAuthInfo,
  selectSpendingCardPreviewInfo,
} from '@store/features/cards/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
import emptyCard from '@constants/emptyCard';
import {
  selectFilteredBy,
  selectIsLoadingSpendingTransactions,
  selectSearchText,
  selectSpendingAllTransactions,
  selectSpendingAllTransactionsFiltered,
} from '@store/features/transactions/selectors';
import { selectSpendingStatementsList } from '@store/features/cardStatements/selectors';
import { cardsActions } from '@store/features/cards/slice';
import useIsTablet from '@hooks/useIsTablet';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectErrorState } from '@store/features/errorStates/selectors';
import {
  selectCardholder,
  selectCardholderAdditionalInfo,
  selectCardholderAdditionalInfoIsLoading,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { BlastedImage } from '@utils/rnBlastedImage';
import transactionThunks from '@store/features/transactions/asyncThunks';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';

const SpendingAccountPage = ({ navigation, route }: SpendingAccountProps) => {
  const spendingStatementsList = useSelector(selectSpendingStatementsList);
  const account = useSelector(selectSpendingAccountPreviewInfo);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const cardInfo = useSelector(selectSpendingCardPreviewInfo);
  const cardholder = useSelector(selectCardholder);
  const cardAuthInfo = useSelector(selectSpendingCardAuthInfo);
  const transactions = useSelector(selectSpendingAllTransactions);
  const filteredTransactions = useSelector(
    selectSpendingAllTransactionsFiltered
  );
  const isLoadingSpending = useSelector(selectIsLoadingSpendingTransactions);
  const shipmentInfo = useSelector(selectShipmentInfo);
  const physicalCardStatus = useSelector(selectPhysicalCardStatus);
  const transactionsSearchText = useSelector(selectSearchText);
  const transactionsFilteredBy = useSelector(selectFilteredBy);
  const errorState = useSelector(selectErrorState);
  const cardholderAdditionalInfo = useSelector(selectCardholderAdditionalInfo);
  const cardholderAdditionalInfoIsLoading = useSelector(
    selectCardholderAdditionalInfoIsLoading
  );

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    setRefreshLoading(false);
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const fetchSpendingAccount = async () => {
    if (spendingAccountId) {
      await dispatch(cardsThunks.getCardInfo({ cardId: spendingAccountId }))
        .unwrap()
        .catch((err) => handleRequestError(err));
      await dispatch(cardsThunks.getCardAuthInfo({ cardId: spendingAccountId }))
        .unwrap()
        .catch((err) => handleRequestError(err));
      dispatch(cardsActions.setRequestSpendingRequestStatus(null));
      dispatch(
        transactionThunks.getSpendingTransactions({
          cardId: spendingAccountId,
          forceRefresh: true,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
    setRefreshLoading(false);
  };

  const [isRefreshLoading, setRefreshLoading] = useState(false);

  const handleRefreshTransactionsList = () => {
    setRefreshLoading(true);
    if (spendingAccountId) {
      dispatch(cardsActions.setRequestSpendingRequestStatus(null));
      dispatch(
        transactionThunks.getSpendingTransactions({
          cardId: spendingAccountId,
          forceRefresh: true,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
    setRefreshLoading(false);
  };

  useEffect(() => {
    if (transactions.length === 0) {
      fetchSpendingAccount();
    }
  }, []);

  const [isBlockAnimated, setIsBlockAnimated] = useState(false);
  const [loaderOverlay, setLoaderOverlay] = useState(false);

  const dispatch = useAppDispatch();
  const isTablet = useIsTablet();

  const navigateBack = () => {
    if (Platform.OS !== 'web') {
      navigation.goBack();
    } else {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    }
  };

  const {
    isModalOpen,
    modalName,
    toOpenModal,
    toCloseModal,
    transactionId,
    toSetTransactionId,
  } = useSideModal({
    route,
    navigation,
  });

  // @ts-ignore
  const cardImgSrc = useAppSelector(cardImage);

  const fetchImage = async (cardAuthInfoPayload?: any) => {
    const authInfo = cardAuthInfoPayload || cardAuthInfo;
    if (authInfo && cardImgSrc?.length === 0) {
      if (Object.keys(authInfo).length) {
        if (Platform.OS === 'web') {
          fetch(authInfo.secure_url).then(async (res) => {
            if (res?.blob) {
              const imageBlob = await res.blob();
              const imageObjectURL = URL.createObjectURL(imageBlob);
              dispatch(cardsActions.setCardImage(imageObjectURL));
            } else {
              if (spendingAccountId) {
                dispatch(
                  cardsThunks.getCardAuthInfo({ cardId: spendingAccountId })
                );
              }
              dispatch(cardsActions.setCardImage(''));
            }
          });
        } else {
          dispatch(cardsActions.setCardImage(authInfo.secure_url));
          BlastedImage.preload([{ uri: authInfo.secure_url }]);
        }
      }
    }
  };

  useEffect(() => {
    if (cardAuthInfo?.secure_url) {
      fetchImage();
    } else {
      dispatch(cardsThunks.getCardAuthInfo({ cardId: spendingAccountId })).then(
        (response) => {
          fetchImage(response.payload);
        }
      );
    }
  }, [cardAuthInfo]);

  const onOpenDebitCardModal = () => {
    toOpenModal(sideModalNames.debitCard)();
  };
  const onOpenDisputeChargeModal = () => {
    toOpenModal(sideModalNames.disputeCharge)();
  };
  const onOpenDdaModal = () => {
    toOpenModal(sideModalNames.setupDda)();
  };
  const onOpenCloseCardModal = () => {
    toOpenModal(sideModalNames.closeAccount)();
  };
  const onOpenCardLostModal = () => {
    toOpenModal(sideModalNames.cardLost)();
    setIsBlockAnimated(false);
  };
  const onOpenOrderCardModal = () => {
    if (physicalCardStatus === 'ORDERED') {
      toOpenModal(sideModalNames.orderCardStatus)();
    } else {
      dispatch(cardholderThunks.getCardholderAdditionalInfo());
      toOpenModal(sideModalNames.orderCardRequest)();
    }
  };

  const onOpenTransactionModal = (id: string) => {
    toSetTransactionId(id);
    toOpenModal(sideModalNames.transaction)();
  };
  const onOpenStatementsListModal = () => {
    toOpenModal(sideModalNames.statementsList)();
  };

  const learnMoreHandler = () => {
    toOpenModal(sideModalNames.blockedCard)();
    setIsBlockAnimated(true);
  };

  const { menuItems, copyToClipboard, ToastPopup } = useAccount({
    currentCardId: account.id,
    statementsCardType: 'spending',
    accountPreview: account,
    onOpenDebitCardModal,
    onOpenDisputeChargeModal,
    onOpenDdaModal,
    onOpenCloseCardModal,
    onOpenStatementsListModal,
    navigation,
  });

  const {
    menuItems: debitCardMenuItems,
    cardStatus: debitCardState,
    isFreezeModalOpen,
    setIsFreezeModalOpen,
    freezeCardHandler,
    unfreezeCardHandler,
  } = useDebitCardMenu({
    onOpenCardLostModal,
    navigation,
    onOpenOrderCardModal,
  });

  useEffect(() => {
    if (modalName === sideModalNames.debitCard) {
      dispatch(
        cardsThunks.getCardInfo({
          cardId: account.id || cardholder.cards[0].card_id,
        })
      );
    }
  }, [isModalOpen]);

  const handlePdfIconPress = (documentUrl: string) => {
    window.open(documentUrl, '_blank', 'noopener,noreferrer');
  };

  const orderRequestStatus = useSelector(selectRequestCardRequestStatus);
  const requestCard = () => {
    dispatch(cardsThunks.postOrderNewCard({ cardId: account.id }));
  };
  useEffect(() => {
    if (
      orderRequestStatus === 'success' ||
      (orderRequestStatus === 'error' &&
        errorState.isError &&
        errorState.displayType !== 'toast')
    ) {
      toOpenModal(sideModalNames.orderCardStatus)();
      dispatch(cardsActions.resetOrderCardRequestStatus());
    }
  }, [orderRequestStatus, errorState]);

  return (
    <MediaLayoutTemplate
      Mobile={SpendingAccountView.mobile}
      Desktop={
        isTablet ? SpendingAccountView.mobile : SpendingAccountView.desktop
      }
      setLoaderOverlay={setLoaderOverlay}
      loaderOverlay={loaderOverlay}
      navigation={navigation}
      navigateBack={navigateBack}
      account={account}
      transactions={transactions}
      filteredTransactions={filteredTransactions}
      isLoadingTransactions={isLoadingSpending}
      transactionsSearchText={transactionsSearchText}
      transactionsFilteredBy={transactionsFilteredBy}
      cardholderAdditionalInfo={cardholderAdditionalInfo}
      menuItems={menuItems}
      copyToClipboard={copyToClipboard}
      ToastPopup={ToastPopup}
      onOpenTransactionModal={onOpenTransactionModal}
      transactionId={transactionId}
      cardInfo={cardInfo || emptyCard}
      cardAuthInfo={cardAuthInfo}
      cardImgSrc={cardImgSrc}
      debitCardMenuItems={debitCardMenuItems}
      debitCardState={debitCardState}
      learnMoreHandler={learnMoreHandler}
      isBlockAnimated={isBlockAnimated}
      toCloseModal={toCloseModal}
      isModalOpen={isModalOpen}
      modalName={modalName}
      statementsList={spendingStatementsList.list}
      toOpenModal={toOpenModal}
      onPdfIconPress={handlePdfIconPress}
      orderRequestStatus={orderRequestStatus}
      requestCard={requestCard}
      filterType="spending"
      shipmentInfo={shipmentInfo}
      isFreezeModalOpen={isFreezeModalOpen}
      setIsFreezeModalOpen={setIsFreezeModalOpen}
      freezeCardHandler={freezeCardHandler}
      unfreezeCardHandler={unfreezeCardHandler}
      cardholderAdditionalInfoIsLoading={cardholderAdditionalInfoIsLoading}
      onRefreshTransactionsList={handleRefreshTransactionsList}
      isRefreshLoading={isRefreshLoading}
    />
  );
};

export default SpendingAccountPage;
