const emptyCard = {
  number: '**** **** **** ****',
  name: 'Name',
  expDate: {
    month: '00',
    year: '00',
  },
  type: 'spending',
};

export default emptyCard;
