import { TButton, Text } from '@atoms';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { Box, Center } from 'native-base';
import AccountCard from '@molecules/AccountCard/AccountCard';
import amountToString from '@utils/amountToString';
import { IAccountTile } from '@utils/idToAccount';
import { Platform } from 'react-native';
import {
  InsufficientFundsAlertModal,
  InsufficientFundsAlertModalProps,
  MobileWebHeader,
} from '@molecules';
import BackButton from '@atoms/BackButton/BackButton';
import { customTheme } from '../../../styles/customTheme';
import React from 'react';
import { Separator, View, XStack, YStack } from 'tamagui';

interface ToExternalBankViewProps extends InsufficientFundsAlertModalProps {
  navigateBack: () => void;
  navigateToAmount: () => void;
  toAccount: IAccountTile;
  fromAccount: IAccountTile;
  isTransferDisabled: boolean;
  navigateToSelect: (selectAccount: 'From' | 'To') => () => void;
}

interface BetweenPaychexPayViewHeaderProps
  extends InsufficientFundsAlertModalProps {
  children: React.ReactNode;
  navigateBack: () => void;
  navigateToAmount: () => void;
  isTransferDisabled: boolean;
}

const BetweenPaychexPayViewHeader = ({
  children,
  navigateBack,
  navigateToAmount,
  isTransferDisabled,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: BetweenPaychexPayViewHeaderProps) =>
  Platform.OS === 'web' ? (
    <>
      <MobileWebHeader />
      <Box bgColor="white" safeAreaBottom flexGrow={1}>
        <XStack paddingVertical="$4" paddingHorizontal="$4" display="flex">
          <BackButton onPress={navigateBack} fill={customTheme.colors.black} />
          <Center flexGrow={1} mr="24px">
            <Text variant="title" textAlign="center">
              Transfer by account number
            </Text>
          </Center>
          <View />
        </XStack>
        <Separator borderColor="$gray20" />
        <View flexGrow={1} display="flex" flexDirection="column">
          <View flex={1} display="flex" flexDirection="column">
            {children}
          </View>
        </View>
      </Box>

      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  ) : (
    <>
      <HeaderAndFooterMobileTemplate
        footerContent={
          <TButton
            onPress={navigateToAmount}
            disabled={isTransferDisabled}
            testID="paychexpay.ToExternalBankView.button.continueButton"
          >
            Continue
          </TButton>
        }
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: 'Transfer by account number',
        }}
      >
        {children}
      </HeaderAndFooterMobileTemplate>

      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  );

const ToExternalBankView = ({
  navigateBack,
  navigateToAmount,
  toAccount,
  fromAccount,
  isTransferDisabled,
  navigateToSelect,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: ToExternalBankViewProps) => {
  return (
    <BetweenPaychexPayViewHeader
      navigateBack={navigateBack}
      navigateToAmount={navigateToAmount}
      isTransferDisabled={isTransferDisabled}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    >
      <YStack
        display="flex"
        flexGrow={1}
        flex={1}
        paddingHorizontal="$4"
        paddingTop="$5"
        paddingBottom="$4"
        justifyContent="space-between"
      >
        <YStack>
          <AccountCard
            icon={fromAccount.icon}
            title={fromAccount.name}
            label="From"
            amount={amountToString(fromAccount.amount)}
            isDefault={false}
            withArrow={false}
          />
          <View marginTop="$4" />
          <AccountCard
            icon={toAccount.icon}
            title={toAccount.name}
            label="To"
            amount={amountToString(toAccount.amount)}
            isDefault={toAccount.id === '0000' || toAccount.type === 'external'}
            isExternal={toAccount.type === 'external'}
            accountType={toAccount.accountType}
            withArrow
            onPress={navigateToSelect('To')}
          />
        </YStack>
        <View>
          <Text
            fontSize={'$h5'}
            color={'$gray50'}
            textAlign="center"
            paddingBottom={'$4'}
          >
            Funds available in 2-3 days depending on the recipient’s bank
            processing time.
          </Text>
        </View>
      </YStack>
      {Platform.OS === 'web' && (
        <View
          borderTopColor="$gray20"
          borderStyle="solid"
          borderBottomWidth="$0"
          borderLeftWidth="$0"
          borderRightWidth="$0"
          borderTopWidth={1}
          bg="white"
          paddingHorizontal="$4"
          paddingTop="$4"
          paddingBottom={16}
        >
          <TButton onPress={navigateToAmount} disabled={isTransferDisabled}>
            Continue
          </TButton>
        </View>
      )}
    </BetweenPaychexPayViewHeader>
  );
};

export default ToExternalBankView;
