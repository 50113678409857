import {
  AirshipRoot,
  NotificationResponseEvent,
} from '@ua/react-native-airship';
// @ts-ignore
import AirshipCustom, { EventType, iOS } from './airship';

const AirshipTyped: AirshipRoot = AirshipCustom;
// export default Airship;
export default AirshipTyped;
export { EventType, iOS };
export type { NotificationResponseEvent };
