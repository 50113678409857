const TILES = {
  earnRewards: {
    title: 'Get paid to spend',
    subtitle:
      'Earn cash back† on qualified purchases with your Paychex Pay Debit Mastercard® at over 10,000 restaurants, hotels, and stores.',
    leftButtonText: 'Learn more',
  },
  payBills: {
    title: 'Pay bills in a snap',
    subtitle:
      'Take a photo of a bill to pay, set up automatic payments, and more — all in one place.',
    leftButtonText: 'Hide this',
    rightButtonText: 'Get started',
  },
  getPercentOnSavings: {
    title: 'Save for your future',
    subtitle:
      'We know you have big plans for your money. Let us make it worthwhile.',
    leftButtonText: 'Maybe later',
    rightButtonText: 'Learn more',
  },
  physicalCard: {
    onTheWay: {
      title: 'Your card is on the way',
      subtitleNoShipmentInfo:
        'It should arrive in 7-10 days. Until then, access your money online.',
      subtitle:
        'It should arrive by {date}. Until then, access your money online.',
      leftButtonText: 'Hide this',
      rightButtonText: 'See account details',
    },
    deliveredSoon: {
      title: 'Have your card in hand?',
      subtitle: 'Activate it to make purchases in person and access ATMs.',
      leftButtonText: 'Yes, it’s here!',
    },
    ready: {
      title: 'Ready to set up direct deposit?',
      subtitle: 'Send your paychecks straight to your account.',
      leftButtonText: 'I’m all set',
      rightButtonText: 'Get started ',
    },
    blocked: {
      title: 'Your card is blocked',
      subtitle:
        'We’ve blocked your card to help safeguard your funds. Call Customer Service right away as your card will not work for new transactions.',
      leftButtonText: 'Learn more',
    },
    frozen: {
      title: 'Your card is frozen',
      subtitle:
        'All card transactions are prevented. Unfreeze your card to resume making purchases and ATM withdrawals.',
      leftButtonText: 'Unfreeze',
    },
  },
};

export default TILES;
