import { View } from 'tamagui';
import NewCardIcon from '../../../assets/cards/newCard.svg';
import FailCardIcon from '../../../assets/cards/failCard.svg';
import { ImageWithContent } from '@molecules';
import { Text } from '@atoms';
import React from 'react';
import { ICardholderAdditionalInfo } from '@store/features/cardholder/types';
import { Platform } from 'react-native';
import { callPhone } from '@utils/communicationServices';
import SUPPORT_PAGES from '@constants/support';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const OrderNewCardRequestContent = ({
  allowCardReissue,
}: {
  allowCardReissue: ICardholderAdditionalInfo['allow_card_reissue'];
}) => {
  const screenInfo = SUPPORT_PAGES.orderNewCardAddressRecentlyChanged;
  const { bottom } = useSafeAreaInsets();
  return (
    <View
      paddingTop={'$7'}
      paddingHorizontal={'$4'}
      accessible={false}
      accessibilityLabel="Order New Card Request"
      flexGrow={1}
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      justifyContent={allowCardReissue ? 'space-between' : 'flex-start'}
    >
      <ImageWithContent
        image={
          allowCardReissue ? (
            <NewCardIcon height="120px" width="120px" />
          ) : (
            <FailCardIcon height="120px" width="120px" />
          )
        }
        space={'$7'}
      >
        <Text
          variant="title"
          pb={'$2'}
          w="100%"
          textAlign={allowCardReissue ? 'center' : 'left'}
        >
          {allowCardReissue ? 'Need a replacement card?' : screenInfo.title}
        </Text>
        <Text
          variant="subtitle"
          w="100%"
          textAlign={allowCardReissue ? 'center' : 'left'}
        >
          {allowCardReissue
            ? 'If your card is damaged or needs to be replaced, order one here.'
            : screenInfo.text}
        </Text>
      </ImageWithContent>
      {allowCardReissue ? (
        <View
          marginBottom={20}
          marginHorizontal={30}
          paddingHorizontal={12}
          paddingVertical={12}
          borderRadius={12}
          justifyContent={'flex-end'}
          backgroundColor={'$blue10'}
        >
          <Text variant={'regular'} fontSize={'$h5'} textAlign={'center'}>
            Note: If your address has recently changed, contact Customer Service
            to order a new card.
          </Text>
        </View>
      ) : (
        <View
          display={'flex'}
          paddingTop={Platform.OS === 'web' ? '80px' : 60}
          flexDirection={'column'}
          justifyContent="center"
          marginBottom={bottom}
        >
          <View backgroundColor="$blue10" borderRadius={8} p={'$4'}>
            <View
              onPress={() => {
                callPhone(screenInfo.supportInfo.phone);
              }}
              testID="paychexpay.OrderNewCardRequestContent.button.pressPhone"
              accessible
              accessibilityLabel={`Phone: ${screenInfo.supportInfo.phone} (toll free in US)`}
            >
              <Text variant="subtitle" pb={'$1'}>
                Phone
              </Text>
              <Text variant="regular" color={'$primaryNormal'} pb={'$4'}>
                {screenInfo.supportInfo.phone}{' '}
                <Text
                  variant="regular"
                  display={Platform.OS === 'web' ? 'inline' : 'flex'}
                >
                  (toll free in US)
                </Text>
              </Text>
            </View>
            <View
              accessible
              accessibilityLabel={`Mailing address: ${screenInfo.supportInfo.mailingAddress}`}
            >
              <Text variant="subtitle" pb={'$1'}>
                Mailing address
              </Text>
              <Text variant="regular" whiteSpace={'pre-line'}>
                {screenInfo.supportInfo.mailingAddress}
              </Text>
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

export default OrderNewCardRequestContent;
