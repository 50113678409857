import { useMediaQuery } from 'native-base';
import LogoIcon from '../../../assets/PaychexLogo.svg';
import AppStoreIcon from '../../../assets/appstoreButton.svg';
import GooglePlayIcon from '../../../assets/googleplayButton.svg';
import AppPreviewImage from '../../../assets/appPreview.svg';
import AppPreviewLargeImage from '../../../assets/appPreviewLarge.svg';
import { AppPreviewDesktopTypes } from '@organisms/AppPreviewDesktop/AppPreviewDesktop.types';
import { Button, Text } from '@atoms';
import { View } from 'tamagui';

const AppPreviewDesktop = ({
  onAppStorePress,
  onGooglePlayPress,
}: AppPreviewDesktopTypes) => {
  const [isLargeDesktop] = useMediaQuery({
    minWidth: 1441,
  });
  const [isAverageDesktop] = useMediaQuery({
    maxWidth: 1440,
    minWidth: 1025,
  });

  return (
    <View
      backgroundColor="$primaryNormal"
      flexGrow={0}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      {...(isLargeDesktop && {
        minWidth: '795px',
      })}
      {...(isAverageDesktop && {
        minWidth: '595px',
      })}
      accessible
      accessibilityLabel={'Paychex Pay preview'}
    >
      <View justifyContent="space-evenly" flexGrow={1}>
        <View alignItems="center">
          <LogoIcon width="208px" height="20px" />
        </View>

        <View
          accessible
          accessibilityLabel={
            'Payday just got easy. Easily manage your finances with the Paychex Pay Online Account with a Debit Mastercard® and optional Savings Account'
          }
        >
          <Text
            fontWeight="700"
            color="white"
            fontSize="$h1"
            textAlign="center"
            paddingBottom="16px"
          >
            Payday just got easy
          </Text>
          <Text
            color="white"
            fontWeight="500"
            fontSize="$h3"
            textAlign="center"
            width="400px"
          >
            Easily manage your finances with the Paychex Pay Online Account with
            a Debit Mastercard® and optional Savings Account
          </Text>
        </View>
        <View
          flexGrow={0}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <View>
            <Button
              variant="simple"
              leftIcon={<AppStoreIcon color="white" />}
              onPress={onAppStorePress}
              flexGrow="0"
              testID="paychexpay.appPreviewDesktop.button.appStoreButton"
              accessible
              accessibilityLabel={'Appstore button'}
            />
          </View>
          <View>
            <Button
              variant="simple"
              leftIcon={<GooglePlayIcon color="white" />}
              onPress={onGooglePlayPress}
              testID="paychexpay.appPreviewDesktop.button.googlePlayButton"
              accessible
              accessibilityLabel={'GooglePlay button'}
            />
          </View>
        </View>
      </View>
      {isLargeDesktop ? <AppPreviewLargeImage /> : <AppPreviewImage />}
    </View>
  );
};

export default AppPreviewDesktop;
