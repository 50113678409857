import { SavingAccountView } from '@views/Accounts';
import { SavingAccountProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import useAccount from '@hooks/useAccount';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useSelector } from 'react-redux';
import { selectSavingsAccountPreviewInfo } from '@store/features/cards/selectors';
import {
  selectFilteredBy,
  selectIsLoadingSavingsTransactions,
  selectSavingsAllTransactions,
  selectSavingsAllTransactionsFiltered,
  selectSearchText,
} from '@store/features/transactions/selectors';
import { useEffect, useState } from 'react';
import transactionThunks from '@store/features/transactions/asyncThunks';
import { selectSavingsStatementsList } from '@store/features/cardStatements/selectors';
import useIsTablet from '@hooks/useIsTablet';
import { useAppDispatch } from '@store/hooks';
import { selectCardholder } from '@store/features/cardholder/selectors';
import { Platform } from 'react-native';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
import { transactionsActions } from '@store/features/transactions/slice';

const SavingAccountPage = ({ navigation, route }: SavingAccountProps) => {
  const account = useSelector(selectSavingsAccountPreviewInfo);
  const transactions = useSelector(selectSavingsAllTransactions);
  const cardholder = useSelector(selectCardholder);
  const savingsStatementsList = useSelector(selectSavingsStatementsList);
  const transactionsSearchText = useSelector(selectSearchText);
  const transactionsFilteredBy = useSelector(selectFilteredBy);
  const filteredTransactions = useSelector(
    selectSavingsAllTransactionsFiltered
  );
  const isLoadingSaving = useSelector(selectIsLoadingSavingsTransactions);

  const dispatch = useAppDispatch();
  const isTablet = useIsTablet();

  const navigateBack = () => {
    if (Platform.OS !== 'web') {
      navigation.goBack();
    } else {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    }
  };

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    setRefreshLoading(false);
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  useEffect(() => {
    dispatch(
      transactionThunks.getSavingsTransactions({
        cardId: account.id || cardholder.cards[1].card_id,
        forceRefresh: true,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
  }, []);

  const {
    isModalOpen,
    modalName,
    toOpenModal,
    toCloseModal,
    transactionId,
    toSetTransactionId,
  } = useSideModal({
    route,
    navigation,
  });

  const onOpenTransactionModal = (id: string) => {
    toSetTransactionId(id);
    toOpenModal(sideModalNames.transaction)();
  };
  const onOpenCloseCardModal = () => {
    toOpenModal(sideModalNames.closeAccount)();
  };
  const onOpenStatementsListModal = () => {
    toOpenModal(sideModalNames.statementsList)();
  };

  const { menuItems, copyToClipboard, ToastPopup } = useAccount({
    currentCardId: account.id,
    statementsCardType: 'savings',
    accountPreview: account,
    onOpenCloseCardModal,
    onOpenStatementsListModal,
    navigation,
  });

  const handlePdfIconPress = (documentUrl: string) => {
    window.open(documentUrl, '_blank', 'noopener,noreferrer');
  };

  const [isRefreshLoading, setRefreshLoading] = useState(false);

  const handleRefreshTransactionsList = () => {
    setRefreshLoading(true);
    dispatch(transactionsActions.setSearchText(''));
    dispatch(
      transactionsActions.setDateRange({
        date_from: null,
        date_to: null,
      })
    );
    dispatch(
      transactionThunks.getSavingsTransactions({
        cardId: account.id || cardholder.cards[1].card_id,
        forceRefresh: true,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));

    setRefreshLoading(false);
  };

  return (
    <MediaLayoutTemplate
      Mobile={SavingAccountView.mobile}
      Desktop={isTablet ? SavingAccountView.mobile : SavingAccountView.desktop}
      navigation={navigation}
      navigateBack={navigateBack}
      account={account}
      transactions={transactions}
      filteredTransactions={filteredTransactions}
      isLoadingTransactions={isLoadingSaving}
      transactionsSearchText={transactionsSearchText}
      transactionsFilteredBy={transactionsFilteredBy}
      menuItems={menuItems}
      copyToClipboard={copyToClipboard}
      ToastPopup={ToastPopup}
      onOpenTransactionModal={onOpenTransactionModal}
      transactionId={transactionId}
      toCloseModal={toCloseModal}
      isModalOpen={isModalOpen}
      modalName={modalName}
      statementsList={savingsStatementsList.list}
      onPdfIconPress={handlePdfIconPress}
      filterType="saving"
      isRefreshLoading={isRefreshLoading}
      onRefreshTransactionsList={handleRefreshTransactionsList}
    />
  );
};

export default SavingAccountPage;
