import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  errorTypes,
  IErrorStatesState,
} from '@store/features/errorStates/types';
import errorsMap from '@store/features/errorStates/errorsMap';

const initialState = {
  isError: false,
  displayType: null,
  error: {
    page: {
      title: '',
      subtitle: '',
      icon: null,
      ghostButtonText: '',
      mainButtonText: '',
      ghostButtonAction: null,
      mainButtonAction: null,
    },
    transfer: {
      title: '',
      subtitle: '',
    },
    toast: {
      title: '',
      subtitle: null,
    },
    redirect: {
      redirectPageName: null,
    },
    other: {},
  },
} as IErrorStatesState;

const errorStatesSlice = createSlice({
  name: 'errorStates',
  initialState,
  reducers: {
    resetError() {
      return initialState;
    },
    setError(state, action: PayloadAction<errorTypes>) {
      // @ts-ignore
      state.error[error.displayType] = error.body;
      state.isError = true;
      state.displayType = action.payload.displayType;
    },
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      if (action.meta?.requestStatus === 'rejected') {
        if (action.payload?.message?.response_code) {
          const errorCode = action.payload.message.response_code;
          if (Object.keys(errorsMap).includes(errorCode)) {
            const error = errorsMap[errorCode];
            // @ts-ignore
            state.error[error.displayType] = error.body;
            state.isError = true;
            state.displayType = error.displayType;
          } else {
            const error = errorsMap['unhandled'];
            // @ts-ignore
            state.error[error.displayType] = error.body;
            state.isError = true;
            state.displayType = error.displayType;
          }
        } else {
          if (action.payload?.status === 401) {
            const error = errorsMap['noTokenStored'];
            // @ts-ignore
            state.error[error.displayType] = error.body;
            state.isError = true;
            state.displayType = error.displayType;
          } else if (action.payload?.status === 500) {
            const error = errorsMap['unhandled500'];
            // @ts-ignore
            state.error[error.displayType] = error.body;
            state.isError = true;
            state.displayType = error.displayType;
          } else if (action.payload?.status === 424) {
            const error = errorsMap['transferUnhandled'];
            //@ts-ignore
            state.error[error.displayType] = error.body;
            state.isError = true;
            state.displayType = error.displayType;
          }
        }
      }
    });
  },
});

export const { reducer: errorStatesReducer, actions: errorStatesActions } =
  errorStatesSlice;
