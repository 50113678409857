import React, { ReactElement } from 'react';
import { Platform } from 'react-native';
import { View, XStack } from 'tamagui';
import { Line, TButton, Text } from '@atoms';
import { TileSkeleton } from '@molecules/index';
import { WidgetWrapper } from '@views/BottomTabs/HomeView';

export interface TileProps {
  icon: ReactElement;
  title: string;
  subtitle: string;
  leftButtonText?: string;
  rightButtonText?: string;
  leftButtonHandler?: () => void;
  rightButtonHandler?: () => void;
  isCarousel?: boolean;
  variant?: 'twoButtons' | 'blueButton' | 'simpleButton';
  firstTestID?: string;
  secondTestID?: string;
  isLeftButtonLoading?: boolean;
  isRightButtonLoading?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  skeletonEnabled?: boolean;
}

let maxHeightSubtitle = 60;

const Tile = ({
  icon,
  title,
  subtitle,
  leftButtonText,
  rightButtonText,
  leftButtonHandler,
  rightButtonHandler,
  variant = 'twoButtons',
  isCarousel,
  firstTestID,
  secondTestID,
  isLeftButtonLoading = false,
  isRightButtonLoading = false,
  isDisabled,
  isLoading = false,
  skeletonEnabled = true,
}: TileProps) => {
  return (
    <View>
      {isLoading && skeletonEnabled ? (
        <WidgetWrapper>
          <TileSkeleton />
        </WidgetWrapper>
      ) : (
        <View>
          <View position="absolute" h="100%" w="100%" zIndex={-1} p={20}>
            <View
              flexGrow={1}
              bg="$white"
              style={{
                shadowColor: 'rgba(46, 66, 102, 1)',
                shadowOffset: {
                  width: 0,
                  height: 18,
                },
                shadowRadius: Platform.OS === 'web' ? 30 : 10,
                elevation: 25,
                shadowOpacity: 0.24,
              }}
            />
          </View>
          <View
            borderRadius="$2"
            borderColor="$gray20"
            borderWidth={1}
            bg="$white"
            pt="$4"
            px="$4"
            position="relative"
          >
            <XStack pb="$4">
              <View w="$8" h="$8" mr="$3">
                {icon}
              </View>
              <View flexShrink={1}>
                <Text
                  variant="title"
                  pb="$2"
                  testID={`paychexpay.physicalCardWidget.${title
                    .toLowerCase()
                    .split(' ')
                    .join('')}Title`}
                  accessible
                  accessibilityLabel={'Widget on the home page. ' + title}
                >
                  {title}
                </Text>
                {isCarousel ? (
                  <Text
                    // @ts-ignore
                    onLayout={({
                      nativeEvent: {
                        // @ts-ignore
                        layout: { height },
                      },
                    }) => {
                      if (height > maxHeightSubtitle)
                        maxHeightSubtitle = height;
                    }}
                    minHeight={maxHeightSubtitle}
                    variant="subtitle"
                    accessible
                    accessibilityLabel={subtitle}
                  >
                    {subtitle}
                  </Text>
                ) : (
                  <Text
                    variant="subtitle"
                    accessible
                    accessibilityLabel={subtitle}
                  >
                    {subtitle}
                  </Text>
                )}
              </View>
            </XStack>
            {variant !== 'blueButton' && <Line mt="$1" />}
            {variant === 'twoButtons' && (
              <XStack py={3} alignItems="center" justifyContent="space-between">
                <TButton
                  onPress={leftButtonHandler}
                  w="49%"
                  variant="simple"
                  py={0}
                  color="$gray40"
                  testID={firstTestID}
                  isLoading={isLeftButtonLoading}
                  loaderIconColor={'$primaryNormal'}
                  accessible
                  accessibilityLabel={leftButtonText}
                >
                  {leftButtonText}
                </TButton>
                <Line vertical h={32} />
                <TButton
                  onPress={rightButtonHandler}
                  w="49%"
                  variant="simple"
                  py={0}
                  testID={secondTestID}
                  isLoading={isRightButtonLoading}
                  loaderIconColor={'$primaryNormal'}
                  {...(isDisabled && { disabled: isDisabled })}
                  accessible
                  accessibilityLabel={rightButtonText}
                >
                  {rightButtonText}
                </TButton>
              </XStack>
            )}
            {variant !== 'twoButtons' && (
              <View
                pb={variant === 'simpleButton' ? '$1' : '$4'}
                pt={variant === 'simpleButton' ? '$1' : 0}
              >
                <TButton
                  variant={variant === 'simpleButton' ? 'simple' : 'primary'}
                  onPress={leftButtonHandler}
                  testID={firstTestID}
                  isLoading={isLeftButtonLoading}
                  loaderIconColor={
                    variant !== 'blueButton' ? '$primaryNormal' : '$white'
                  }
                  accessible
                  accessibilityLabel={leftButtonText}
                >
                  {leftButtonText}
                </TButton>
              </View>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default Tile;
