import { YStack } from 'tamagui';
import ScrollArrow from '../../../assets/scrollArrow.svg';
import { Text } from '@atoms';

const ScrollBanner = () => {
  return (
    <YStack
      bg="$blue10"
      alignItems="center"
      py={'$3'}
      accessible
      accessibilityLabel="Scroll down to review terms and conditions"
    >
      <Text variant="caption" p={'$2'} px="$4">
        Scroll down to review terms and conditions
      </Text>
      <ScrollArrow />
    </YStack>
  );
};

export default ScrollBanner;
