import { createStackNavigator } from '@react-navigation/stack';
import {
  AboutPFAccountPage,
  AboutSsnPage,
  AboutDobPage,
  AddressVerificationPage,
  FillInDataFirstPage,
  FillInDataSecondPage,
  PaychexLoginPage,
  SignInPage,
} from '@pages/SignUp';
import useIsPhone from '@hooks/useIsPhone';
import { AuthNavigatorParamsList } from './AuthNavigator.types';
import { DEFAULT_SCREEN_OPTIONS } from '@constants/navigation';
import { BiometricsPage } from '@pages/Biometrics';
import AgreementsPage from '@pages/SignUp/AgreementsPage/AgreementsPage';
import UnableToSignupPage from '@pages/SignUp/UnableToSignupPage/UnableToSignupPage';
import UsaPatriotActPage from '@pages/Onboarding/UsaPatriotActPage';
import AgreementsIntroPage from '@pages/SignUp/AgreementsPage/AgreementsIntroPage';
import routesToTitlesMap from '../routesToTitlesMap';

const Stack = createStackNavigator<AuthNavigatorParamsList>();

interface AuthNavigatorProps {
  isAuthenticated: boolean;
  isNavigationHistory: boolean;
}

const AuthNavigator = ({
  isAuthenticated,
  isNavigationHistory,
}: AuthNavigatorProps) => {
  const isPhone = useIsPhone();
  return (
    <Stack.Navigator
      // @ts-ignore
      defaultScreenOptions={{ headerShown: false }}
      initialRouteName={isPhone ? 'SignInPage' : 'PaychexLoginPage'}
      screenOptions={{ ...DEFAULT_SCREEN_OPTIONS }}
    >
      {isPhone && (
        <Stack.Screen
          name="SignInPage"
          component={SignInPage}
          options={{
            title: routesToTitlesMap.SignInPage,
          }}
        />
      )}
      <Stack.Screen
        name="PaychexLoginPage"
        component={PaychexLoginPage}
        options={{
          title: routesToTitlesMap.PaychexLoginPage,
        }}
      />
      <Stack.Screen
        name="AboutPFAccount"
        component={AboutPFAccountPage}
        options={{
          title: routesToTitlesMap.AboutPFAccount,
        }}
      />
      {isAuthenticated && (
        <>
          <Stack.Screen
            name="UnableToSignupPage"
            component={UnableToSignupPage}
            options={{
              title: routesToTitlesMap.UnableToSignupPage,
            }}
          />
          {isNavigationHistory && (
            <Stack.Screen
              name="UsaPatriotActPage"
              component={UsaPatriotActPage}
              options={{
                title: routesToTitlesMap.UsaPatriotActPage,
                gestureEnabled: false,
              }}
            />
          )}
          <Stack.Screen
            name="FillInDataFirst"
            component={FillInDataFirstPage}
            options={{
              title: routesToTitlesMap.FillInDataFirst,
              gestureEnabled: false,
            }}
          />
          <Stack.Screen
            name="FillInDataSecond"
            component={FillInDataSecondPage}
            options={{
              title: routesToTitlesMap.FillInDataSecond,
            }}
          />
          <Stack.Screen
            name="AgreementsIntro"
            component={AgreementsIntroPage}
            options={{
              title: routesToTitlesMap.AgreementsIntro,
              ...DEFAULT_SCREEN_OPTIONS,
              gestureEnabled: false,
            }}
          />
          <Stack.Screen
            name="AgreementsPage"
            component={AgreementsPage}
            options={{
              title: routesToTitlesMap.AgreementsPage,
            }}
          />

          <Stack.Screen
            name="AboutSsnPage"
            component={AboutSsnPage}
            options={{
              title: routesToTitlesMap.AboutSsnPage,
            }}
          />
          <Stack.Screen
            name="AboutDobPage"
            component={AboutDobPage}
            options={{
              title: routesToTitlesMap.AboutDobPage,
            }}
          />
          <Stack.Screen
            name="BiometricsPage"
            component={BiometricsPage}
            options={{
              title: routesToTitlesMap.BiometricsPage,
              gestureEnabled: false,
            }}
          />
          <Stack.Screen
            name="AddressVerificationPage"
            component={AddressVerificationPage}
            options={{
              title: routesToTitlesMap.AddressVerificationPage,
            }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};

export default AuthNavigator;
