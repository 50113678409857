import { OrderNewCardRequestProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { OrderNewCardRequestView } from '@views/More';
import { useSelector } from 'react-redux';
import { selectRequestCardRequestStatus } from '@store/features/cards/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
import {
  selectCardholderAdditionalInfo,
  selectCardholderAdditionalInfoIsLoading,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import { useEffect } from 'react';
import { useAppDispatch } from '@store/hooks';
import { selectErrorState } from '@store/features/errorStates/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';

const OrderNewCardRequestPage = ({
  navigation,
  route,
}: OrderNewCardRequestProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };
  const dispatch = useAppDispatch();
  const orderRequestStatus = useSelector(selectRequestCardRequestStatus);
  const spendingCardId = useSelector(selectSpendingAccountId);
  const errorState = useSelector(selectErrorState);
  const cardholderAdditionalInfo = useSelector(selectCardholderAdditionalInfo);
  const cardholderAdditionalInfoIsLoading = useSelector(
    selectCardholderAdditionalInfoIsLoading
  );

  useEffect(() => {
    dispatch(cardholderThunks.getCardholderAdditionalInfo());
  }, []);
  const requestCard = () => {
    dispatch(cardsThunks.postOrderNewCard({ cardId: spendingCardId }));
  };
  const isOpenedAsFullPage = route.params?.isOpenedAsFullPage;

  useEffect(() => {
    if (orderRequestStatus === 'success') {
      navigation.navigate('MoreNavigator', {
        screen: 'OrderNewCardStatus',
        params: { status: 'success', isOpenedAsFullPage: isOpenedAsFullPage },
      });
    }
    if (
      orderRequestStatus === 'error' &&
      errorState.isError &&
      errorState.displayType !== 'toast'
    ) {
      navigation.navigate('MoreNavigator', {
        screen: 'OrderNewCardStatus',
        params: { status: 'error', isOpenedAsFullPage: isOpenedAsFullPage },
      });
    }
  }, [orderRequestStatus, errorState]);

  return (
    <OrderNewCardRequestView
      navigateBack={navigateBack}
      requestCard={requestCard}
      orderRequestStatus={orderRequestStatus}
      allowCardReissue={cardholderAdditionalInfo?.allow_card_reissue ?? true}
      isLoading={cardholderAdditionalInfoIsLoading}
    />
  );
};

export default OrderNewCardRequestPage;
