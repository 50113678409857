import { useSelector } from 'react-redux';
import { savingAccountSelector } from '@store/features/cards/selectors';
import React from 'react';
import { savingsWidget } from '@store/features/homeWidgets/selectors';
import useIsPhone from '@hooks/useIsPhone';
import { Tile, TileSkeleton } from '@molecules/index';
import TILES from '@constants/tiles';
import OpenSavingsTileIcon from '../../../assets/tiles/openSavings.svg';
import { WidgetWrapper } from '@views/BottomTabs/HomeView';

interface AddSavingsWidgetProps {
  onOpenSavings: () => void;
  onHideSavingsWidget: () => void;
  isLoading: boolean;
}

const AddSavingsWidget = ({
  onOpenSavings,
  onHideSavingsWidget,
  isLoading,
}: AddSavingsWidgetProps) => {
  const isPhone = useIsPhone();

  const isSavingsWidgetVisible = useSelector(savingsWidget);
  const savingsAccount = useSelector(savingAccountSelector);

  if (isLoading && !isPhone) {
    return (
      <WidgetWrapper>
        <TileSkeleton />
      </WidgetWrapper>
    );
  }
  if (isSavingsWidgetVisible && !savingsAccount) {
    return (
      <WidgetWrapper>
        <Tile
          {...TILES.getPercentOnSavings}
          title={TILES.getPercentOnSavings.title}
          icon={<OpenSavingsTileIcon />}
          rightButtonHandler={onOpenSavings}
          leftButtonHandler={onHideSavingsWidget}
          firstTestID="interestNotNowButton"
          secondTestID="interestOpenSavingsButton"
        />
      </WidgetWrapper>
    );
  }
  return null;
};

export default AddSavingsWidget;
