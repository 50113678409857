import { Text } from '@atoms';
import { BIRTH_DATE_INFO } from '@constants/policies';
import useIsPhone from '@hooks/useIsPhone';
import { View } from 'tamagui';

interface AboutDobContentProps {
  withTitle?: boolean;
}

const AboutDobContent = ({ withTitle = false }: AboutDobContentProps) => {
  const isPhone = useIsPhone();
  return (
    <>
      {withTitle && (
        <Text
          fontWeight="$medium"
          textAlign="center"
          fontSize="$h3"
          pt="7"
          accessible
          accessibilityLabel={'Birth date info'}
        >
          Birth date info
        </Text>
      )}
      <View bg="white" px={isPhone ? '$4' : '$10'} pt="$5">
        <Text
          variant="subtitle"
          pb="6"
          accessible
          accessibilityLabel={`${BIRTH_DATE_INFO}`}
        >
          {BIRTH_DATE_INFO}
        </Text>
      </View>
    </>
  );
};

export default AboutDobContent;
