import Lottie from 'react-lottie';
import PAYCHEX_LOADER from '../../../assets/lotties/paychex_loader.json';
import { LoaderPaychexProps } from '@atoms/LoaderPaychex/LoaderPaychex.types';

const defaultOptions = () => ({
  loop: true,
  autoplay: true,
  animationData: PAYCHEX_LOADER,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});
const LoaderPaychex = ({ width = 238, height = 24 }: LoaderPaychexProps) => {
  return (
    // @ts-ignore
    <Lottie options={defaultOptions()} width={width} height={height} />
  );
};

export default LoaderPaychex;
