import { ToOtherFriendsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { ToOtherFriendsView } from '@views/Transfers';
import {
  isValidBeneficiaryInfoFn,
  isValidBeneficiaryInfoLn,
  isValidBeneficiaryInfoEmail,
  isValidBeneficiaryInfoPhone,
} from '@utils/isValidBeneficiaryInfo';
import useBeneficiaryInput from '@hooks/useBeneficiaryInput';
import { useSelector } from 'react-redux';
import { selectSpendingAccountPreviewInfo } from '@store/features/cards/selectors';
import { useEffect, useState } from 'react';
import { P2P_TRANSFER_OPTIONS } from '@constants/transfersScreensNames';
import { Platform } from 'react-native';
import { selectVariableTransferFee } from '@store/features/general/selectors';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
import { useAppDispatch } from '@store/hooks';

const ToOtherFriendsPage = ({ navigation }: ToOtherFriendsProps) => {
  const [selectedMethod, setSelectedMethod] = useState<string>(
    P2P_TRANSFER_OPTIONS[0].value
  );
  const {
    beneficiaryInfoP2P: { benEmail, benFirstName, benLastName, benPhone },
    isErrorInputEmail,
    isErrorInputFn,
    isErrorInputLn,
    isErrorInputPhone,
    isValidationFailed,
    onChangebenFirstName,
    onChangebenLastName,
    onChangebenEmail,
    onChangebenPhone,
    setIsErrorInputEmail,
    setIsErrorInputPhone,
    setIsErrorInputFn,
    setIsErrorInputLn,
  } = useBeneficiaryInput({ transferOption: selectedMethod });

  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const variableTransferFee = useSelector(selectVariableTransferFee);
  const dispatch = useAppDispatch();

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'Transfers' });
    } else {
      navigation.goBack();
    }
  };
  const navigateToAmount = () => {
    if (
      (selectedMethod === 'Email address'
        ? isValidBeneficiaryInfoEmail(benEmail)
        : isValidBeneficiaryInfoPhone(benPhone)) &&
      isValidBeneficiaryInfoFn(benFirstName) &&
      isValidBeneficiaryInfoLn(benLastName)
    ) {
      navigation.navigate('HomeNavigator', {
        screen: 'TransferAmount',
        params: {
          fromAccountId: spendingAccount.id,
          benInfo: {
            firstName: benFirstName.trim(),
            lastName: benLastName.trim(),
            email: benEmail,
            mobile: benPhone,
          },
        },
      });
    } else {
      setIsErrorInputEmail(!isValidBeneficiaryInfoEmail(benEmail));
      setIsErrorInputFn(!isValidBeneficiaryInfoFn(benFirstName));
      setIsErrorInputPhone(!isValidBeneficiaryInfoPhone(benPhone));
      setIsErrorInputLn(!isValidBeneficiaryInfoLn(benLastName));
    }
  };
  const onRadioButtonSelect = (option: string) => {
    if (option === 'Phone number') {
      setIsErrorInputPhone(false);
    } else {
      setIsErrorInputEmail(false);
    }
    onChangebenEmail('');
    onChangebenPhone('');
    setSelectedMethod(option);
  };

  useEffect(() => {
    if (spendingAccountId) {
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccountId }));
    }
  }, [spendingAccountId]);

  const [isInsufficientFundsAlertVisible, setIsInsufficientFundsAlertVisible] =
    useState(false);

  useEffect(() => {
    if (spendingAccount.amount === 0 && spendingAccount.id) {
      setIsInsufficientFundsAlertVisible(true);
    }
  }, [spendingAccount, spendingAccount.id]);

  const hideInsufficientFundsAlertModal = () => {
    setIsInsufficientFundsAlertVisible(false);
    navigateBack();
  };

  return (
    <ToOtherFriendsView
      navigateBack={navigateBack}
      navigateToAmount={navigateToAmount}
      benInfo={{
        benEmail,
        benFirstName,
        benLastName,
        benPhone,
      }}
      variableTransferFee={variableTransferFee}
      selectedMethod={selectedMethod}
      onRadioButtonSelect={onRadioButtonSelect}
      onChangeBeneficiaryInfoFn={onChangebenFirstName}
      onChangeBeneficiaryInfoLn={onChangebenLastName}
      onChangeBeneficiaryInfoPhone={onChangebenPhone}
      onChangeBenInfoEmail={onChangebenEmail}
      account={spendingAccount}
      isButtonDisabled={isValidationFailed}
      isErrorInputLn={isErrorInputLn}
      isErrorInputFn={isErrorInputFn}
      isErrorInputEmail={isErrorInputEmail}
      isErrorInputPhone={isErrorInputPhone}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    />
  );
};

export default ToOtherFriendsPage;
