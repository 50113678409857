import { CenterModal, IconWithDescription } from '@molecules';
import NotificationIcon from '../../../assets/notificationIllustration.svg';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { Button, Switch, TButton, Text } from '@atoms';
import { Platform } from 'react-native';
import { Separator, View, XStack, YStack } from 'tamagui';

interface AllowNotificationsViewProps {
  onDonePress: () => void;
  notificationsSettings: {
    mobilePushPaychexPay: boolean;
    text: boolean;
    email: boolean;
  };
  mobilePushPaychexPayChange: (f: boolean) => void;
  textChange: (f: boolean) => void;
  emailChange: (f: boolean) => void;
  confirmHandler?: () => void;
  isModalOpen: boolean;
  setIsConfirmationModalOpen?: (flag: boolean) => void;
}

const ConfirmationModal = ({
  isModalOpen,
  setIsConfirmationModalOpen = () => false,
  confirmHandler = () => false,
}: {
  isModalOpen: boolean;
  setIsConfirmationModalOpen?: (flag: boolean) => void;
  confirmHandler?: () => void;
}) => {
  return (
    <CenterModal
      isModalOpen={isModalOpen}
      closeByOutsideClick
      closeModal={() => setIsConfirmationModalOpen(false)}
    >
      <View
        maxWidth={343}
        py="$4"
        bg="$white"
        borderRadius={12}
        flexDirection="column"
        display="flex"
      >
        <Text
          textAlign="center"
          fontSize={16}
          fontWeight={'600'}
          pb={'$2'}
          px="$4"
        >
          By turning on text notifications, I authorize account notifications to
          be sent to me via SMS. Message and data rates may apply.
        </Text>
        <Separator borderColor="$gray20" borderWidth={0.5} />
        <XStack alignItems={'center'} marginTop={'$4'} paddingHorizontal={12}>
          <TButton
            flex={1}
            marginRight={'$2'}
            variant={'simple'}
            borderColor={'$gray10'}
            borderWidth={1}
            onPress={() => setIsConfirmationModalOpen(false)}
          >
            Go back
          </TButton>
          <TButton
            flex={1}
            marginLeft={2}
            onPress={confirmHandler}
            variant="primary"
          >
            I agree
          </TButton>
        </XStack>
      </View>
    </CenterModal>
  );
};

const AllowNotificationsView = ({
  onDonePress,
  notificationsSettings,
  mobilePushPaychexPayChange,
  textChange,
  emailChange,
  isModalOpen,
  confirmHandler,
  setIsConfirmationModalOpen,
}: AllowNotificationsViewProps) => {
  return (
    <>
      <HeaderAndFooterMobileTemplate
        footerContent={
          <Button
            onPress={onDonePress}
            testID="paychexpay.AllowNotificationsView.button.done"
          >
            Done
          </Button>
        }
      >
        <View flexGrow={1} bg="white">
          <View
            display="flex"
            pt={2}
            flexGrow={1}
            alignItems="center"
            paddingHorizontal={'$6'}
          >
            <IconWithDescription
              Icon={NotificationIcon}
              description="Choose how to get updates on your account activity. Change this at any time."
              title="Stay in the loop"
              titlePaddingTop={0}
            />
            <YStack width="100%" pt={4} justifyContent="center" flexGrow={1}>
              {Platform.OS !== 'web' && (
                <XStack
                  justifyContent="space-between"
                  paddingVertical={'$3'}
                  alignItems="center"
                >
                  <Text>Push</Text>
                  <Switch
                    checked={notificationsSettings.mobilePushPaychexPay}
                    onChange={mobilePushPaychexPayChange}
                  />
                </XStack>
              )}
              <XStack
                justifyContent="space-between"
                paddingVertical={'$3'}
                alignItems="center"
              >
                <Text>Email</Text>
                <Switch
                  testID="paychexpay.AllowNotificationsView.switch.emailSwitch"
                  checked={notificationsSettings.email}
                  onChange={emailChange}
                />
              </XStack>
              <XStack
                justifyContent="space-between"
                paddingVertical={'$3'}
                alignItems="center"
              >
                <Text>Text</Text>
                <Switch
                  testID="paychexpay.AllowNotificationsView.switch.textSwitch"
                  checked={notificationsSettings.text}
                  onChange={textChange}
                />
              </XStack>
            </YStack>
            <View flexGrow={1} justifyContent="flex-end" paddingBottom={'$7'}>
              <View display="flex" borderRadius={12} bg="$blue10" padding={12}>
                <Text textAlign="center" fontSize={14}>
                  We’ll always send you updates on things like password resets.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </HeaderAndFooterMobileTemplate>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        setIsConfirmationModalOpen={setIsConfirmationModalOpen}
        confirmHandler={confirmHandler}
      />
    </>
  );
};

export default AllowNotificationsView;
