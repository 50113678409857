import { UsaPatriotActPageProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import UsaPatriotActView from '@views/Onboarding/UsaPatriotActView';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
const UsaPatriotActPage = ({ navigation }: UsaPatriotActPageProps) => {
  const goToFillInData = () => {
    navigation.navigate('FillInDataFirst');
  };

  return (
    <MediaLayoutTemplate
      Desktop={UsaPatriotActView.desktop}
      Mobile={UsaPatriotActView.mobile}
      onPressContinue={goToFillInData}
    />
  );
};

export default UsaPatriotActPage;
