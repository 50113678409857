import * as yup from 'yup';
import { statesFull, statesShort } from '@constants/statesMap';

export interface IFillInDataSecondFormInputs {
  line1: string;
  line2?: string;
  zipCode: string;
  city: string;
  state: string;
  date?: string;
  ssn?: string;
  country?: string;
}

const requiredErrorMessage = 'This field is required';

export const fillInDataSecondSchema = yup.object().shape({
  line1: yup
    .string()
    .required(requiredErrorMessage)
    .min(5, 'Address line must be at least 5 letters'),
  line2: yup.string(),
  zipCode: yup
    .string()
    .required(requiredErrorMessage)
    .matches(/^\d{5}$/, 'ZIP code must contain 5 digits'),
  city: yup
    .string()
    .required(requiredErrorMessage)
    .min(2, 'City name must be at least 2 letters'),
  state: yup
    .string()
    .required('Select your state')
    .min(2, 'State name must be at least 2 letters')
    .oneOf([...statesFull, ...statesShort], 'Enter real state name'),
  date: yup
    .string()
    .required(requiredErrorMessage)
    .matches(
      /^[0-9]{2}[/-][0-9]{2}[/-][*0-9]{4}$/,
      'Enter date in a correct format: mm/dd/yyyy'
    )
    .matches(
      /^((0[1-9])|(1[0-2]))[/-]((0[1-9])|([1-2][0-9])|(3[0-1]))[/-](((19[2-9][0-9])|(20[0-1][0-9]))|([*]{4}))$/,
      'Please enter a real date'
    ),
  ssn: yup
    .string()
    .required(requiredErrorMessage)
    .matches(
      /^[*\d]{3}-[*\d]{2}-\d{4}$/,
      'Enter SSN in a correct format: XXX-XX-XXXX'
    ),
});

export const updateAddressForm = yup
  .object()
  .shape({
    line1: yup
      .string()
      .required(requiredErrorMessage)
      .min(5, 'Address line must be at least 5 letters'),
    line2: yup.string(),
    zipCode: yup
      .string()
      .required(requiredErrorMessage)
      .matches(/^\d{5}$/, 'ZIP code must contain 5 digits'),
    city: yup
      .string()
      .required(requiredErrorMessage)
      .min(2, 'City name must be at least 2 letters'),
    state: yup
      .string()
      .required('Select your state')
      .min(2, 'State name must be at least 2 letters')
      .lowercase()
      .oneOf(
        [
          ...statesFull.map((e) => e.toLowerCase()),
          ...statesShort.map((e) => e.toLowerCase()),
        ],
        'Enter real state name'
      ),
  })
  .required();

export const zipCodeOnChange = (value: string) => {
  return value.replace(/\D/g, '');
};

export const ssnOnChange = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{3})(\d)/, '$1-$2')
    .replace(/(\d{3}-\d{2})(\d{1,4})/, '$1-$2')
    .replace(/(\d{3}-\d{2}-\d{4})(\d+?)/, '$1');
};

export const dateOnChange = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2}\/\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2}\/\d{2}\/\d{4})(\d)/, '$1');
};

export const stateOnChange = (value: string) => {
  const text = value.replace(/[^A-Za-z ]|\d/g, '');
  if (statesShort.includes(value.toUpperCase()) && text.length === 2) {
    return text.toUpperCase();
  } else {
    const transformedValue = text.toLowerCase().split(' ');
    const transformedWords = transformedValue.map(
      (word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`
    );

    return transformedWords.join(' ');
  }
};
