import { Platform, useWindowDimensions } from 'react-native';
import React from 'react';
import { TButton, Text } from '@atoms';
import useInsufficientFundsAlertModal from '@hooks/Animations/useInsufficientFundsAlertModal';
import { Separator, View } from 'tamagui';
import { maxMediaQuery } from '@constants/ui';
import Animated from 'react-native-reanimated';

export type InsufficientFundsAlertModalProps = {
  isInsufficientFundsAlertVisible: boolean;
  hideInsufficientFundsAlertModal: () => void;
};

const InsufficientFundsAlertModal = ({
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: InsufficientFundsAlertModalProps) => {
  const { width: desktopWidth } = useWindowDimensions();

  const { backgroundStyle, isModalVisible } = useInsufficientFundsAlertModal({
    isInsufficientFundsAlertVisible,
  });
  return (
    <>
      {isInsufficientFundsAlertVisible && (
        <View
          justifyContent={'center'}
          alignItems={'center'}
          position="absolute"
          top={0}
          left={0}
          h="100%"
          w="100%"
          zIndex={1000}
        >
          <Animated.View
            style={[
              backgroundStyle,
              {
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            ]}
          />
          {isModalVisible && (
            <View
              w={
                Platform.OS !== 'web'
                  ? '100%'
                  : desktopWidth <= maxMediaQuery.tablet
                  ? '100%'
                  : '33%'
              }
              px={'$4'}
            >
              <View p={'$4'} bg="white" borderRadius={12}>
                <Text variant="title" pt={'$2'} textAlign="center">
                  Insufficient funds
                </Text>
                <Text variant="subtitle" pt={'$4'} pb={'$5'} textAlign="center">
                  Add funds to account before transferring.
                </Text>
                <Separator borderColor="$gray20" />
                <View pt={'$4'}>
                  <TButton
                    onPress={hideInsufficientFundsAlertModal}
                    testID={
                      'paychexpay.InsufficientFundsAlertModal.button.gotIt'
                    }
                  >
                    Got it
                  </TButton>
                </View>
              </View>
            </View>
          )}
        </View>
      )}
    </>
  );
};

export default InsufficientFundsAlertModal;
