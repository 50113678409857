import {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useEffect, useState } from 'react';

const animationDuration = 500;

const useLocationPermissionModal = ({
  isPermissionDenied,
}: {
  isPermissionDenied: boolean;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const backgroundOpacity = useSharedValue(0);
  const backgroundStyle = useAnimatedStyle(() => {
    return {
      opacity: backgroundOpacity.value,
    };
  });
  useEffect(() => {
    if (isPermissionDenied) {
      backgroundOpacity.value = withTiming(0.6, {
        duration: animationDuration,
      });
      setTimeout(() => {
        setIsModalVisible(true);
      }, animationDuration);
    } else {
      setIsModalVisible(false);
      backgroundOpacity.value = 0;
    }
  }, [isPermissionDenied]);
  return { backgroundStyle, isModalVisible };
};

export default useLocationPermissionModal;
