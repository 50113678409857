export const formatPercentInput = (text: string) => {
  if (text === '0') return '% 1';
  if (text === '%') return '% ';
  if (text === '% ') return '% ';
  if (text === '% 0') return '% 1';
  return (
    '% ' +
    text
      .replace(',', '')
      .replace('.', '')
      .replace('% ', '')
      .replace('%', '')
      .replace(/^0(\d)/, '$1')
      .replace(/^\d{3}/, '99')
      .replace(/\D/g, '')
  );
};

export const formatDDAAmountInput = (text: string) => {
  if (text === '0') return '$1';
  if (text === '.' || text === ' ') return '$1.';
  if (text === '$') return '';
  if (text === '$0') return '$';
  return (
    '$' +
    text
      // .replace(',', '.')
      .replace(/[^.\d]/g, '')
      .replace(/^0(.+)$/, '$1')
      .replace(/^(\..*)/, '0$1')
      .replace(/^(.*\..*)\./g, '$1')
      .replace(/^(.*\.\d{2}).*$/, '$1')
      .replace(/^(\d{9})\d+$/, '$1')
      .replace(/^(\d{9})\d+(\.\d*)$/, '$1$2')
      .replace(/^(\d+)(\d{3}\.?\d*)$/, '$1,$2')
      .replace(/^(\d+)(\d{3},\d{3}\.?\d*)$/, '$1,$2')
  );
};

export const formatTransferInput = (text: string) => {
  if (text === '0') return '$0';
  if (text === '.' || text === ' ') return '$0.';
  if (text === '$') return '';
  return (
    '$' +
    text
      // .replace(',', '.')
      .replace(/[^.\d]/g, '')
      .replace(/^0(.+)$/, '$1')
      .replace(/^(\..*)/, '0$1')
      .replace(/^(.*\..*)\./g, '$1')
      .replace(/^(.*\.\d{2}).*$/, '$1')
      .replace(/^(\d{9})\d+$/, '$1')
      .replace(/^(\d{9})\d+(\.\d*)$/, '$1$2')
      .replace(/^(\d+)(\d{3}\.?\d*)$/, '$1,$2')
      .replace(/^(\d+)(\d{3},\d{3}\.?\d*)$/, '$1,$2')
  );
};
