import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import { ISubmitOowQuestionsRequest } from '@store/features/oowQuestions/types';
import { application, operations } from '@constants/traceability';

const oowQuestionsAPI = {
  postSubmitOwwQuestions(body: ISubmitOowQuestionsRequest) {
    return wrappedAxiosRequest<ISubmitOowQuestionsRequest, any>({
      method: 'post',
      url: endpoints.submitOwwQuestions,
      data: body,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.SubmitOOWQuestions,
        'x-payx-comp': application.compPrefix + operations.SubmitOOWQuestions,
      },
    });
  },
};

export default oowQuestionsAPI;
