import { AgreementsIntroProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import AgreementsIntroView from '@views/SignUp/AgreementsIntroView';
import { useSelector } from 'react-redux';
import { selectAgreements } from '@store/features/agreements/selectors';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
const AgreementsIntroPage = ({ navigation }: AgreementsIntroProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };

  const agreements = useSelector(selectAgreements);

  const onPressSave = async () => {
    navigation.navigate('AuthNavigator', {
      screen: 'AgreementsPage',
      params: { type: 'Re-agree' },
    });
  };

  return (
    <MediaLayoutTemplate
      Mobile={AgreementsIntroView.mobile}
      Desktop={AgreementsIntroView.desktop}
      navigateBack={navigateBack}
      onPressSave={onPressSave}
      agreements={agreements}
    />
  );
};

export default AgreementsIntroPage;
