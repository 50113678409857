import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BIOMETRY_TYPE } from 'react-native-keychain';

interface biometricsDataState {
  biometricsType: BIOMETRY_TYPE | null | '';
  isBiometricEnabled: boolean;
  isTriedToEnableBiometric: boolean; // true if biometric was disabled on phone and user tried to enable it in app
}

const initialState = {
  biometricsType: '',
  isBiometricEnabled: false,
  isTriedToEnableBiometric: false,
} as biometricsDataState;

const biometricsDataSlice = createSlice({
  name: 'objects',
  initialState,
  reducers: {
    updateBiometricsType(state, action: PayloadAction<BIOMETRY_TYPE | null>) {
      state.biometricsType = action.payload;
    },
    setIsBiometricEnabled(state, action: PayloadAction<boolean>) {
      state.isBiometricEnabled = action.payload;
    },
    setIsTriedToEnableBiometric(state, action: PayloadAction<boolean>) {
      state.isTriedToEnableBiometric = action.payload;
    },
  },
});

export const {
  reducer: biometricsDataReducer,
  actions: biometricsDataActions,
} = biometricsDataSlice;
