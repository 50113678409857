import { Center } from 'native-base';
import { Text as RNText } from 'react-native';
import { AccountInfoItem, TextInput } from '@molecules';
import { ddaForm, setupDdaAccount } from '@constants/ddaScreens';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  formatDDAAmountInput,
  formatPercentInput,
} from '@utils/validateDdaInputs';
import { TButton, Text } from '@atoms';
import BackButton from '@atoms/BackButton/BackButton';
import { useSelector } from 'react-redux';
import { selectSpendingAccountPreviewInfo } from '@store/features/cards/selectors';
import { IAccountPreview } from '@devTypes/accountTypes';
import ddaFormThunks from '@store/features/ddaForm/asyncThunks';
import {
  selectDdaPdfString,
  selectIsPdfReady,
} from '@store/features/ddaForm/selectors';
import { ddaFormActions } from '@store/features/ddaForm/slice';
import { useAppDispatch } from '@store/hooks';
import {
  ddaFormSchema,
  ddaOptionType,
  IDdaFormInputs,
} from '@organisms/DdaFormContent/helper';
import { Control, Controller, useForm } from 'react-hook-form';
import useIsPhone from '@hooks/useIsPhone';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { generateDdaRequestType } from '@store/features/ddaForm/types';
import { RadioGroup, Separator, View, XStack, YStack } from 'tamagui';
import RadioGroupItemWithLabel from '@molecules/RadioButtonWithLabel/RadioButtonWithLabel';
import amountCommaCleaner from '@utils/amountCommaCleaner';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';

//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

interface DdaFormContentDesktopProps {
  onPressBack: () => void;
}

interface DdaFormContentMobileProps {
  account: IAccountPreview;
  control?: Control<IDdaFormInputs>;
  watchCurrentOption: ddaOptionType;
}

const desktop = ({ onPressBack = () => false }: DdaFormContentDesktopProps) => {
  const dispatch = useAppDispatch();
  const pdfString = useSelector(selectDdaPdfString);
  const isPdfReady = useSelector(selectIsPdfReady);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [dollarAmtError, setDollarAmtError] = useState(false);

  const defaultValues = {
    companyName: '',
    idNumber: '',
    option: '',
    percent: '',
    number: '',
  } as IDdaFormInputs;
  const { control, watch, resetField } = useForm<IDdaFormInputs>({
    defaultValues,
    resolver: yupResolver(ddaFormSchema),
  });
  const watchCurrentOption = watch('option');
  const watchPercent = watch('percent');
  const watchNumber = watch('number');
  const watchCompanyName = watch('companyName');
  const watchIdNumber = watch('idNumber');

  const isButtonDisabled = useMemo(() => {
    return !(
      watchCurrentOption === 'nothing' ||
      (watchCurrentOption === 'percent' &&
        watchPercent.includes('% ') &&
        watchPercent.length > 2) ||
      (watchCurrentOption === 'number' &&
        watchNumber.includes('$') &&
        watchNumber.length > 1)
    );
  }, [watchCurrentOption, watchPercent, watchNumber]);

  const formRequestBody: generateDdaRequestType = useMemo(() => {
    switch (watchCurrentOption) {
      case 'percent':
        return {
          percentageChk: true,
          percentageAmt: parseFloat(watchPercent.replace(/\D/g, '')),
        };
      case 'number':
        return {
          dollarAmtChk: true,
          dollarAmt: parseFloat(amountCommaCleaner(watchNumber)),
        };
      default:
        return {
          entireChk: true,
        };
    }
  }, [watchCurrentOption, watchPercent, watchNumber]);

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const onSave = () => {
    if (
      watchCurrentOption === 'number' &&
      parseFloat(amountCommaCleaner(watchNumber)) < 1
    ) {
      setDollarAmtError(true);
      resetField('number');
      return;
    }

    setIsLoadingButton(true);
    dispatch(
      ddaFormThunks.postGenerateDda({
        ...formRequestBody,
        companyName: watchCompanyName,
        employeeNumber: watchIdNumber,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
    setIsLoadingButton(false);
  };
  useEffect(() => {
    if (isPdfReady && pdfString) {
      const file = new Blob([pdfString], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);

      const ddaFormTab = window.open('', '_blank');
      if (ddaFormTab) {
        ddaFormTab.opener = null;
        ddaFormTab.location.href = fileURL;
      }
    }
    dispatch(ddaFormActions.resetStatus());
  }, [pdfString]);

  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const isPhone = useIsPhone();

  const idNumberInputRef = useRef(null);
  const numberInputRef = useRef(null);
  const percentInputRef = useRef(null);

  useEffect(() => {
    switch (watchCurrentOption) {
      case 'percent':
        // @ts-ignore
        percentInputRef.current?.focus();
        break;
      case 'number':
        // @ts-ignore
        numberInputRef.current?.focus();
        break;
      default:
        break;
    }
  }, [watchCurrentOption]);

  useEffect(() => {
    if (dollarAmtError && watchNumber.length > 1) {
      setDollarAmtError(false);
    }
  }, [watchNumber]);

  return (
    <>
      {isPhone && (
        <View
          position="relative"
          accessible
          accessibilityLabel={'Direct Deposit form'}
        >
          <BackButton
            onPress={onPressBack}
            args={{ position: 'absolute', left: '$7', top: '$6', zIndex: 1 }}
          />
          <Center h="88px">
            <Text variant="title">Getting started</Text>
          </Center>
          <Separator borderColor="$gray20" borderWidth={0.5} />
        </View>
      )}

      <View
        pt={'$6'}
        px={'$7'}
        accessible
        accessibilityLabel={'Direct Deposit form'}
      >
        <YStack gap={'$4'} pb={'$4'}>
          <AccountInfoItem
            title="Employee name"
            text={spendingAccount.nameOnCard}
          />
          <AccountInfoItem
            title="Employee address"
            text={spendingAccount.address}
          />
          <AccountInfoItem title="Bank name" text={spendingAccount.bankName} />
          <AccountInfoItem
            title="Routing number"
            text={spendingAccount.routingNumber}
            testID="paychexpay.desktop.ddaFormContent.button.copyRoutingNumberButton"
          />
          <AccountInfoItem
            title="Account number"
            text={spendingAccount.accountNumber}
            testID="paychexpay.desktop.ddaFormContent.button.copyAccountNumberButton"
            hideNumber={true}
          />
        </YStack>
        <Separator borderColor="$gray20" borderWidth={0.5} mb={'$4'} />
        <Text variant="caption" pb={'$2'}>
          Company name
        </Text>
        <Controller
          name="companyName"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              args={{
                fontSize: '$body',
                onBlur: onBlur,
                value: value,
                onChangeText: (value: string) => onChange(value),
                onSubmitEditing: () => {
                  // @ts-ignore
                  idNumberInputRef.current?.focus();
                },
                accessible: true,
                placeholder: 'Enter company name',
                accessibilityLabel: 'company name input',
                testID:
                  'paychexpay.desktop.ddaFormContent.input.companyNameInput',
              }}
            />
          )}
        />
        <Text variant="caption" py={'$4'}>
          Employee ID
        </Text>
        <Controller
          name="idNumber"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              args={{
                ref: idNumberInputRef,
                fontSize: '$body',
                onBlur: onBlur,
                value: value,
                onChangeText: (value: string) => onChange(value),
                onSubmitEditing: () => {
                  // @ts-ignore
                  idNumberInputRef.current?.focus();
                },
                accessible: true,
                placeholder: 'Enter ID number',
                accessibilityLabel: 'ID number input',
                testID: 'paychexpay.desktop.ddaFormContent.input.idNumberInput',
              }}
            />
          )}
        />
        <Separator borderColor="$gray20" borderWidth={0.5} mt="$4" />
        <Text variant="subtitle" pt={'$4'}>
          Choose the direct deposit amount
        </Text>
        <XStack py={'$4'}>
          <Controller
            name="option"
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                onValueChange={(text) => {
                  onChange(text);
                }}
                value={value}
                defaultValue="nothing"
                accessibilityLabel="options"
                name="form"
              >
                <YStack gap={'$4'} alignItems="flex-start">
                  <View
                    paddingVertical={14}
                    testID={
                      'paychexpay.mobile.ddaFormContent.container.remainderOfPayContainer'
                    }
                  >
                    <RadioGroupItemWithLabel
                      size="$7"
                      label="All available funds"
                      value="nothing"
                      testID={
                        'paychexpay.mobile.ddaFormContent.radioButton.remainderOfPay'
                      }
                    />
                  </View>
                  <View
                    paddingVertical={14}
                    testID={
                      'paychexpay.mobile.ddaFormContent.container.setPercentOfCheckContainer'
                    }
                  >
                    <RadioGroupItemWithLabel
                      size="$7"
                      label="Set percentages"
                      value={'percent'}
                      testID={
                        'paychexpay.mobile.ddaFormContent.radioButton.setPercentOfCheck'
                      }
                    />
                  </View>
                  <View
                    paddingVertical={14}
                    testID={
                      'paychexpay.mobile.ddaFormContent.container.setAmountContainer'
                    }
                  >
                    <RadioGroupItemWithLabel
                      size="$7"
                      label="Custom"
                      value={'number'}
                      testID={
                        'paychexpay.mobile.ddaFormContent.radioButton.setAmount'
                      }
                    />
                  </View>
                </YStack>
              </RadioGroup>
            )}
          />
          <YStack
            flex={1}
            width={124}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <View height={48} />
            <View height={48} width={124}>
              <Controller
                name="percent"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    args={{
                      ref: percentInputRef,
                      disabled: watchCurrentOption !== 'percent',
                      fontSize: '$body',
                      onBlur: onBlur,
                      value: value,
                      onChangeText: (value: string) =>
                        onChange(formatPercentInput(value)),
                      keyboardType: 'numeric',
                      maxLength: 5,
                      accessible: true,
                      placeholder: '% 0',
                      testID:
                        'paychexpay.desktop.ddaFormContent.input.percentInput',
                    }}
                    isDisabled={watchCurrentOption !== 'percent'}
                  />
                )}
              />
            </View>
            <View height={48} width={124}>
              <Controller
                name="number"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    args={{
                      ref: numberInputRef,
                      width: 124,
                      placeholder: '$0.00',
                      fontSize: '$body',
                      value: value,
                      onChangeText: (value: string) =>
                        onChange(formatDDAAmountInput(value)),
                      onBlur: onBlur,
                      keyboardType: 'numeric',
                      accessible: true,
                      accessibilityLabel: 'amount input',
                      testID:
                        'paychexpay.desktop.ddaFormContent.input.amountInput',
                    }}
                    isDisabled={watchCurrentOption !== 'number'}
                  />
                )}
              />
            </View>
          </YStack>
        </XStack>
        <Separator borderColor="$gray20" borderWidth={0.5} />
        <RNText
          style={{
            color: '#666C7B',
            fontSize: 14,
            fontWeight: '500',
            lineHeight: 20,
            paddingTop: 16,
            fontFamily: 'Inter-Regular, sans-serif',
          }}
        >
          {ddaForm.text}
        </RNText>
        <View py={'$4'}>
          {dollarAmtError && (
            <Text variant="error" textAlign="center" pb={'$1'}>
              Custom deposit amount should be more than $1
            </Text>
          )}
          <TButton
            onPress={onSave}
            disabled={isButtonDisabled}
            isLoading={isLoadingButton}
            accessible
            accessibilityLabel={`${setupDdaAccount.buttonText}`}
            testID={
              'paychexpay.desktop.ddaFormContent.button.completeFormButton'
            }
          >
            {setupDdaAccount.buttonText}
          </TButton>
        </View>
      </View>
    </>
  );
};

const mobile = ({
  account,
  control: controlProp,
  watchCurrentOption,
}: DdaFormContentMobileProps) => {
  const { control } = useForm<IDdaFormInputs>({
    resolver: yupResolver(ddaFormSchema),
  });
  const idNumberInputRef = useRef(null);
  const numberInputRef = useRef(null);
  const percentInputRef = useRef(null);

  useEffect(() => {
    switch (watchCurrentOption) {
      case 'percent':
        // @ts-ignore
        percentInputRef.current?.focus();
        break;
      case 'number':
        // @ts-ignore
        numberInputRef.current?.focus();
        break;
      default:
        break;
    }
  }, [watchCurrentOption]);

  return (
    <>
      <View px={'$4'}>
        <YStack gap={'$4'} pb={'$4'}>
          <AccountInfoItem title="Employee name" text={account.nameOnCard} />
          <AccountInfoItem title="Employee address" text={account.address} />
          <AccountInfoItem title="Bank name" text={account.bankName} />
          <AccountInfoItem
            title="Routing number"
            text={account.routingNumber}
            testID="paychexpay.mobile.ddaFormContent.button.copyRoutingNumberButton"
          />
          <AccountInfoItem
            title="Account number"
            text={account.accountNumber}
            testID="paychexpay.mobile.ddaFormContent.button.copyAccountNumberButton"
            hideNumber={true}
          />
        </YStack>
        <Separator borderColor="$gray20" borderWidth={0.5} marginBottom="$4" />
        <Text variant="caption" pb={'$4'}>
          Company name
        </Text>
        <Controller
          name="companyName"
          control={controlProp || control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              args={{
                fontSize: '$body',
                onBlur: onBlur,
                value: value,
                onChangeText: (value: string) => onChange(value),
                onSubmitEditing: () => {
                  // @ts-ignore
                  idNumberInputRef.current?.focus();
                },
                placeholder: 'Enter company name',
                accessible: true,
                accessibilityLabel: 'company name input',
                testID:
                  'paychexpay.mobile.ddaFormContent.input.companyNameInput',
              }}
            />
          )}
        />
        <Text variant="caption" py="$4">
          Employee ID
        </Text>
        <Controller
          name="idNumber"
          control={controlProp || control}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextInput
              args={{
                ref: idNumberInputRef,
                fontSize: '$body',
                onBlur: onBlur,
                value: value,
                onChangeText: (value: string) => onChange(value),
                marginBottom: 4,
                accessible: true,
                placeholder: 'Enter ID number',
                accessibilityLabel: 'ID number input',
                testID: 'paychexpay.mobile.ddaFormContent.input.idNumberInput',
              }}
            />
          )}
        />
        <Text variant="subtitle" pt={'$4'}>
          Choose the direct deposit amount
        </Text>
        <XStack py={'$4'}>
          <View display="flex" flex={1}>
            <Controller
              name="option"
              control={controlProp || control}
              render={({ field: { onChange, value } }) => (
                <RadioGroup
                  onValueChange={(value) => {
                    onChange(value);
                  }}
                  value={value}
                  defaultValue="nothing"
                  accessibilityLabel="options"
                  name="form"
                >
                  <YStack space={'$4'} alignItems="flex-start">
                    <View
                      paddingVertical={14}
                      testID={
                        'paychexpay.mobile.ddaFormContent.container.remainderOfPayContainer'
                      }
                    >
                      <RadioGroupItemWithLabel
                        size="$7"
                        label="All available funds"
                        value="nothing"
                        testID={
                          'paychexpay.mobile.ddaFormContent.radioButton.remainderOfPay'
                        }
                      />
                    </View>
                    <View
                      paddingVertical={14}
                      testID={
                        'paychexpay.mobile.ddaFormContent.container.setPercentOfCheckContainer'
                      }
                    >
                      <RadioGroupItemWithLabel
                        size="$7"
                        label="Set percentages"
                        value={'percent'}
                        testID={
                          'paychexpay.mobile.ddaFormContent.radioButton.setPercentOfCheck'
                        }
                      />
                    </View>
                    <View
                      paddingVertical={14}
                      testID={
                        'paychexpay.mobile.ddaFormContent.container.setAmountContainer'
                      }
                    >
                      <RadioGroupItemWithLabel
                        size="$7"
                        label="Custom"
                        value={'number'}
                        testID={
                          'paychexpay.mobile.ddaFormContent.radioButton.setAmount'
                        }
                      />
                    </View>
                  </YStack>
                </RadioGroup>
              )}
            />
          </View>

          <YStack
            flex={1}
            width={124}
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <View height={48} />
            <View width={124}>
              <Controller
                name="percent"
                control={controlProp || control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    args={{
                      ref: percentInputRef,
                      fontSize: '$body',
                      onBlur: onBlur,
                      value: value,
                      onChangeText: (value: string) =>
                        onChange(formatPercentInput(value)),
                      keyboardType: 'numeric',
                      maxLength: 5,
                      accessible: true,
                      placeholder: '% 0',
                      testID:
                        'paychexpay.mobile.ddaFormContent.input.percentInput',
                    }}
                    isDisabled={watchCurrentOption !== 'percent'}
                  />
                )}
              />
            </View>
            <View width={124}>
              <Controller
                name="number"
                control={controlProp || control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    args={{
                      ref: numberInputRef,
                      width: 124,
                      placeholder: '$0.00',
                      fontSize: '$body',
                      value: value,
                      onChangeText: (value: string) =>
                        onChange(formatDDAAmountInput(value)),
                      onBlur: onBlur,
                      keyboardType: 'numeric',
                      accessible: true,
                      accessibilityLabel: 'amount input',
                      testID:
                        'paychexpay.mobile.ddaFormContent.input.amountInput',
                    }}
                    isDisabled={watchCurrentOption !== 'number'}
                  />
                )}
              />
            </View>
          </YStack>
        </XStack>
        <RNText
          style={{
            color: '#666C7B',
            fontSize: 14,
            fontWeight: '500',
            lineHeight: 20,
            paddingTop: 16,
            fontFamily: 'Inter-Regular, sans-serif',
          }}
        >
          {ddaForm.text}
        </RNText>
      </View>
    </>
  );
};

const DdaFormContent = {
  desktop,
  mobile,
};

export default DdaFormContent;
