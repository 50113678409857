import { CIPMessageView } from '@views/CIP';
import { useNavigation } from '@react-navigation/native';
import { CIPNavigatorParamsProps } from '@navigation/CIPNavigator';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';

const CIPMessagePage = () => {
  const navigation = useNavigation<CIPNavigatorParamsProps['navigation']>();
  const onNextButtonPress = () => {
    navigation.navigate('OutWalletQuestionsPage');
  };

  const commonProps = {
    buttonHandler: onNextButtonPress,
  };
  return (
    <MediaLayoutTemplate
      Mobile={CIPMessageView.mobile}
      Desktop={CIPMessageView.desktop}
      commonProps={commonProps}
    />
  );
};

export default CIPMessagePage;
