import { HomePage, MobileDepositPage, MorePage } from '@pages/BottomTabs';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import HomeIcon from '../../assets/bottomTabs/homeTab.svg';
import HomeIconActive from '../../assets/bottomTabs/homeTabActive.svg';
import MoreIcon from '../../assets/bottomTabs/moreTab.svg';
import MoreIconActive from '../../assets/bottomTabs/moreTabActive.svg';
import TransferIcon from '../../assets/bottomTabs/Transfer.svg';
import TransferIconActive from '../../assets/bottomTabs/TransferActive.svg';
import MobilDepositIcon from '../../assets/bottomTabs/MobileDeposit.svg';
import MobilDepositIconActive from '../../assets/bottomTabs/MobileDepositActive.svg';
import {
  DEFAULT_SCREEN_OPTIONS,
  DEFAULT_TAB_BAR_OPTIONS,
} from '@constants/navigation';
import { customTheme } from '../../styles/customTheme';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Platform } from 'react-native';
import { BottomTabsNavigatorParamsProps } from '@navigation/BottomsTabsNavigator/BottomTabsNavigator.types';
import { useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import Airship, { EventType, NotificationResponseEvent } from '@utils/airship';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@store/features/navigationStates/selectors';
import { ACCOUNT_NAMES } from '@constants/accounts';
import { TransfersPage } from '@pages/Transfers';
import { View } from 'tamagui';
import routesToTitlesMap from '../routesToTitlesMap';
import useHomepageRequests from '@hooks/Api/useHomepageRequests';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { useAppDispatch } from '@store/hooks';

const Tab = createBottomTabNavigator();

const TabNavigator = () => {
  const navigation =
    useNavigation<BottomTabsNavigatorParamsProps['navigation']>();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const savingsAccountId = useSelector(selectSavingsAccountId);
  const spendingCardId = useSelector(selectSpendingAccountId);

  const dispatch = useAppDispatch();
  const { fetchSavingData, fetchSpendingData } = useHomepageRequests();

  useEffect(() => {
    if (Platform.OS === 'web') return;

    Airship.push
      .setUserNotificationsEnabled(true)
      .then(() => false)
      .catch(() => false);

    const listener = (event: NotificationResponseEvent) => {
      if (isAuthenticated) {
        const pushNotificationText = event.pushPayload.alert;
        let isDebitCardPage = false;
        let isTransferPage = false;
        let isSpendingAccount = false;

        if (event.isForeground) {
          dispatch(cardholderThunks.getCardholder());
          dispatch(cardholderThunks.getCardholderAdditionalInfo());
          if (savingsAccountId) {
            fetchSavingData();
          }
          if (spendingCardId) {
            fetchSpendingData();
          }
        }

        if (pushNotificationText) {
          if (
            pushNotificationText.includes('card') ||
            pushNotificationText.includes('PIN')
          ) {
            isDebitCardPage = true;
          } else if (
            pushNotificationText.includes('transfer') ||
            pushNotificationText.includes('transaction has been declined')
          ) {
            isTransferPage = true;
          } else if (pushNotificationText.includes('withdrawal')) {
            isSpendingAccount = true;
          }
        }

        if (isDebitCardPage) {
          navigation.navigate('HomeNavigator', {
            screen: 'DebitCard',
          });
        } else if (isTransferPage) {
          navigation.navigate('BottomTabsNavigator', {
            screen: 'Transfers',
          });
        } else if (isSpendingAccount) {
          navigation.navigate('HomeNavigator', {
            screen: 'SpendingAccount',
            params: { accountName: ACCOUNT_NAMES.PaychexPaySpending },
          });
        }
      }
    };

    Airship.addListener(EventType.NotificationResponse, listener);

    return () => {
      // @ts-ignore
      Airship.removeListener(EventType.NotificationResponse, listener);
    };
  }, [isAuthenticated]);

  return (
    <SafeAreaView
      style={{ flex: 1, backgroundColor: 'white' }}
      edges={['bottom']}
    >
      <Tab.Navigator
        initialRouteName="Home"
        screenOptions={({ route }) => ({
          ...DEFAULT_SCREEN_OPTIONS,
          tabBarStyle: DEFAULT_TAB_BAR_OPTIONS,
          safeAreaInsets: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
          tabBarIcon: ({ focused }) => {
            switch (route.name) {
              case 'HomePage':
                return focused ? <HomeIconActive /> : <HomeIcon />;
              case 'More':
                return focused ? <MoreIconActive /> : <MoreIcon />;
              case 'Transfers':
                return focused ? (
                  <TransferIconActive />
                ) : (
                  <TransferIcon fill={'#B2BAC5'} />
                );
              case 'MobileDeposit':
                return focused ? (
                  <MobilDepositIconActive />
                ) : (
                  <MobilDepositIcon />
                );
            }
          },
          tabBarActiveTintColor: customTheme.colors.primary.normal,
          tabBarInactiveTintColor: customTheme.colors.gray['40'],
          tabBarLabelStyle: { fontWeight: '500', fontSize: 12 },
          tabBarLabelPosition: 'below-icon',
          tabBarBackground: () => (
            <View
              borderTopWidth={1}
              borderTopColor="$gray20"
              marginHorizontal={'$4'}
            />
            // We use view with border top and mx="4", to make border paddings,
            // and do not apply these padding for tabs
          ),
          title: (() => {
            switch (route.name) {
              case 'Transfers':
                return Platform.OS !== 'web'
                  ? 'Transfer'
                  : routesToTitlesMap.Transfers;
              case 'MobileDeposit':
                return Platform.OS !== 'web'
                  ? 'Check Deposit'
                  : routesToTitlesMap.MobileDeposit;
              case 'More':
                return Platform.OS !== 'web' ? 'More' : routesToTitlesMap.More;
              case 'HomePage':
                return Platform.OS !== 'web'
                  ? 'Home'
                  : routesToTitlesMap.HomePage;
            }
          })(),
        })}
      >
        <Tab.Screen name="HomePage" component={HomePage} />
        {/*@ts-ignore*/}
        <Tab.Screen name="Transfers" component={TransfersPage} />
        <Tab.Screen name="MobileDeposit" component={MobileDepositPage} />
        <Tab.Screen name="More" component={MorePage} />
      </Tab.Navigator>
    </SafeAreaView>
  );
};

export default TabNavigator;
