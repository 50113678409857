import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  IExternalAccountTransferRequest,
  IExternalAccountTransferResponse,
  INetworkTransferRequest,
  INetworkTransferRequestBody,
  ITransferRequest,
  ITransferRequestBody,
  ITransferResponse,
} from '@store/features/transfers/types';
import { omit } from 'lodash';
import { application, operations } from '@constants/traceability';

const transfersApi = {
  postTransfer({
    fromAccountId,
    toAccountId,
    amount,
    description,
    additionalHeaders = {},
  }: ITransferRequest) {
    return wrappedAxiosRequest<ITransferRequestBody, ITransferResponse>({
      method: 'post',
      url: endpoints.transfers,
      data: {
        source: fromAccountId,
        beneficiary: toAccountId,
        amount: amount,
        description: description,
      },
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn': application.bizpnPrefix + operations.Transfer,
        'x-payx-comp': application.compPrefix + operations.Transfer,
      },
    });
  },
  postNetworkTransfer({
    fromAccountId,
    email,
    mobile,
    amount,
    description,
    firstName,
    lastName,
  }: INetworkTransferRequest) {
    return wrappedAxiosRequest<INetworkTransferRequestBody, ITransferResponse>({
      method: 'post',
      url: endpoints.networkTransfers,
      data: {
        card_id: fromAccountId,
        beneficiary: {
          email: email || '',
          mobile: mobile || '',
          first_name: firstName,
          last_name: lastName,
        },
        amount: amount,
        description: description,
      },
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.NetworkTransfer,
        'x-payx-comp': application.compPrefix + operations.NetworkTransfer,
      },
    });
  },
  postOutgoing(body: IExternalAccountTransferRequest) {
    return wrappedAxiosRequest<
      IExternalAccountTransferRequest,
      IExternalAccountTransferResponse
    >({
      method: 'post',
      url: endpoints.outgoingTransfer,
      data: omit(body, 'additionalHeaders'),
      headers: {
        ...body.additionalHeaders,
        'x-payx-bizpn': application.bizpnPrefix + operations.OutgoingTransfer,
        'x-payx-comp': application.compPrefix + operations.OutgoingTransfer,
      } || {
        'x-payx-bizpn': application.bizpnPrefix + operations.OutgoingTransfer,
        'x-payx-comp': application.compPrefix + operations.OutgoingTransfer,
      },
    });
  },
  postIncoming(body: IExternalAccountTransferRequest) {
    return wrappedAxiosRequest<
      IExternalAccountTransferRequest,
      IExternalAccountTransferResponse
    >({
      method: 'post',
      url: endpoints.incomingTransfer,
      data: body,
      headers: {
        ...body.additionalHeaders,
        'x-payx-bizpn': application.bizpnPrefix + operations.IncomingTransfer,
        'x-payx-comp': application.compPrefix + operations.IncomingTransfer,
      } || {
        'x-payx-bizpn': application.bizpnPrefix + operations.IncomingTransfer,
        'x-payx-comp': application.compPrefix + operations.IncomingTransfer,
      },
    });
  },
};

export default transfersApi;
