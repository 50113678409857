export { default as ProfileSettingPage } from './ProfileSettingPage/ProfileSettingPage';
export { default as EditPhonePage } from './EditPhonePage/EditPhonePage';
export { default as EditEmailPage } from './EditEmailPage/EditEmailPage';
export { default as EditAddressPage } from './EditAddressPage/EditAddressPage';
export { default as HelpPage } from './HelpPage';
export { default as FaqPage } from './FaqPage';
export { default as NotificationsPage } from './NotificationsPage';
export { default as LegalPage } from './LegalPage';
export { default as LegalAgreementPage } from './LegalAgreementPage';
export { default as AtmAndReloadPage } from './AtmAndReloadPage';
export { default as DigitalWalletPage } from './DigitalWalletPage';
export { default as OrderNewCardRequestPage } from './OrderNewCardRequestPage';
export { default as OrderNewCardStatusPage } from './OrderNewCardStatusPage';
export { default as SendFeedbackPage } from './SendFeedbackPage';
