import AgreementsView from '@views/SignUp/AgreementsView';
import { useEffect, useRef, useState } from 'react';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { AgreementsPageProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import { useSelector } from 'react-redux';
import { selectAgreements } from '@store/features/agreements/selectors';
import agreementsThunks from '@store/features/agreements/asyncThunks';
import { ScrollView } from 'react-native';
import { useAppDispatch } from '@store/hooks';
import { navigationStatesActions } from '@store/features/navigationStates/slice';

const AgreementsPage = ({ navigation }: AgreementsPageProps) => {
  const [isAgreeDisabled, setIsAgreeDisabled] = useState(true);

  const agreements = useSelector(selectAgreements);
  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);
  const [isLastAgreeButtonPressed, setIsLastAgreeButtonPressed] =
    useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  // eslint-disable-next-line no-console
  const pdfRef = useRef({ setPage: (n: number) => console.log(n) });
  const scrollViewRef = useRef<ScrollView | null>(null);
  const onPressScrollArrow = () => {
    pdfRef.current.setPage(numberOfPages);
    setTimeout(() => {
      scrollViewRef?.current?.scrollToEnd({ animated: true });
    }, 500);
  };

  const dispatch = useAppDispatch();

  const onPressBack = () => {
    navigation.goBack();
  };

  const onPressAgree = () => {
    setIsAgreeDisabled(true);

    if (currentVisibleIndex < agreements.length - 1) {
      setCurrentVisibleIndex(currentVisibleIndex + 1);
      pdfRef.current.setPage(0);

      dispatch(
        agreementsThunks.postAgreementAcknowledgement({
          document_id: agreements[currentVisibleIndex].document.documentId,
        })
      );
    } else {
      setIsLastAgreeButtonPressed(true);
    }
  };

  useEffect(() => {
    const isAllAgreementsAcknowledged = agreements.every((agreement) => {
      return agreement.isAcknowledged;
    });

    if (isAllAgreementsAcknowledged) {
      dispatch(navigationStatesActions.setIsAuthorizedCardholder(true));
      setTimeout(() => {
        navigation.reset({
          index: 0,
          // @ts-ignore
          routes: [{ name: 'OnboardingNavigator' }],
        });
      }, 0);
    } else {
      if (isLastAgreeButtonPressed) {
        agreements.map((agreement) => {
          if (!agreement.isAcknowledged) {
            dispatch(
              agreementsThunks.postAgreementAcknowledgement({
                document_id: agreement.document.documentId,
              })
            );
          }
        });
      }
    }
  }, [agreements, isLastAgreeButtonPressed]);

  useEffect(() => {
    agreements.map((agreement) => {
      dispatch(
        agreementsThunks.getAgreementById({
          document_id: agreement.id,
        })
      );
    });
    setCurrentVisibleIndex(0);
  }, []);

  return (
    <MediaLayoutTemplate
      Mobile={AgreementsView.mobile}
      Desktop={AgreementsView.desktop}
      title={'Terms and conditions'}
      onPressAgree={onPressAgree}
      onPressBack={onPressBack}
      onPressScrollArrow={onPressScrollArrow}
      isAgreeDisabled={isAgreeDisabled}
      agreements={agreements}
      currentVisibleIndex={currentVisibleIndex}
      setNumberOfPages={setNumberOfPages}
      setIsAgreeDisabled={setIsAgreeDisabled}
      pdfRef={pdfRef}
      numberOfPages={numberOfPages}
      scrollViewRef={scrollViewRef}
    />
  );
};

export default AgreementsPage;
