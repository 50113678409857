import { ReactElement } from 'react';
import { TPressable, Text } from '@atoms';
import ArrowIcon from '../../../assets/menuArrow.svg';
import { View, XStack, YStack } from 'tamagui';
import { IAccountPreview } from '@devTypes/accountTypes';

export interface AccountCardProps {
  icon: ReactElement;
  title: string;
  label: 'From' | 'To';
  accountType?: IAccountPreview['type'];
  isExternal?: boolean;
  amount?: string;
  isDefault?: boolean;
  withArrow?: boolean;
  onPress?: () => void;
}

const AccountCard = ({
  icon,
  title,
  label,
  amount,
  accountType,
  isExternal = false,
  isDefault = false,
  withArrow = false,
  onPress,
}: AccountCardProps) => {
  return (
    <View>
      <Text
        fontSize={'$h4'}
        color={'$gray50'}
        fontWeight={'500'}
        paddingBottom={12}
      >
        {label}
      </Text>
      <TPressable
        onPress={onPress}
        cursor={withArrow ? 'pointer' : 'default'}
        accessible
        accessibilityLabel={`${title}`}
        testID={`paychexpay.AccountCard.button.${title}`}
      >
        <XStack
          justifyContent="space-between"
          alignItems="center"
          p={'$3'}
          borderColor="$gray20"
          borderRadius={12}
          borderWidth={1}
        >
          <XStack>
            <View borderRadius={8} overflow={'hidden'}>
              {icon}
            </View>
            <YStack
              justifyContent={isDefault ? 'center' : 'space-between'}
              pl={'$4'}
            >
              <Text variant="regular">
                {title}{' '}
                {isExternal &&
                  (accountType === 'spending' ? 'checking' : 'savings')}
              </Text>
              {!isDefault && <Text>{amount}</Text>}
            </YStack>
          </XStack>
          {withArrow && (
            <View pr={'$1'}>
              <ArrowIcon />
            </View>
          )}
        </XStack>
      </TPressable>
    </View>
  );
};

export default AccountCard;
