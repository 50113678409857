import fillInDataAPI from '@store/features/fillInData/api';
import { createTypedThunk } from '@utils/createTypedThunk';
import {
  IAddressSuggestionsRequest,
  IAddressSuggestionsResponse,
  IAddressVerifyRequest,
  IUserInfoResponse,
  IUserIsEligibleResponse,
} from '@store/features/fillInData/types';

const fillInDataThunks = {
  logoutFromSessions: createTypedThunk<undefined, IUserInfoResponse>(
    'auth/logout',
    fillInDataAPI.logoutFromSessions
  ),
  getUserInfo: createTypedThunk<undefined, IUserInfoResponse>(
    'fillInData/userInfo',
    fillInDataAPI.getUserInfo
  ),
  checkIsEligible: createTypedThunk<undefined, IUserIsEligibleResponse>(
    'fillInData/checkIsEligible',
    fillInDataAPI.checkIfUserEligible
  ),
  postAddressVerify: createTypedThunk<IAddressVerifyRequest, any>(
    'fillInData/addressVerify',
    fillInDataAPI.postAddressVerify
  ),
  postAddressSuggestions: createTypedThunk<
    IAddressSuggestionsRequest,
    IAddressSuggestionsResponse
  >('fillInData/postAddressSuggestions', fillInDataAPI.postAddressSuggestions),
};

export default fillInDataThunks;
