import { Platform } from 'react-native';
import useDebitCardMenu from '@hooks/useDebitCardMenu';
import { useSelector } from 'react-redux';
import { DebitCardView } from '@views/Accounts';
import { useEffect, useState } from 'react';
import {
  cardImage,
  selectSpendingCardAuthInfo,
  selectSpendingCardPreviewInfo,
} from '@store/features/cards/selectors';
import emptyCard from '@constants/emptyCard';
import { DebitCardProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import useIsTablet from '@hooks/useIsTablet';
import { useAppDispatch } from '@store/hooks';
import { cardsActions } from '@store/features/cards/slice';
import cardsThunks from '@store/features/cards/asyncThunks';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';
import { BlastedImage } from '@utils/rnBlastedImage';

const DebitCardPage = ({ navigation, route }: DebitCardProps) => {
  const isTablet = useIsTablet();
  const isOpenedAsFullPage = !!route.params?.isOpenedAsFullPage;

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };
  const cardInfo = useSelector(selectSpendingCardPreviewInfo);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const cardAuthInfo = useSelector(selectSpendingCardAuthInfo);
  const dispatch = useAppDispatch();
  const [isBlockAnimated, setIsBlockAnimated] = useState(false);
  const {
    menuItems,
    cardStatus,
    isFreezeModalOpen,
    setIsFreezeModalOpen,
    freezeCardHandler,
    unfreezeCardHandler,
  } = useDebitCardMenu({
    navigation,
    isOpenedAsFullPage,
  });

  const learnMoreHandler = () => {
    navigation.navigate('HomeNavigator', {
      screen: 'Support',
      params: { pageName: 'blockedCard' },
    });
    setIsBlockAnimated(true);
  };

  const cardImgSrc = useSelector(cardImage);

  const fetchImage = async (cardAuthInfoPayload?: any) => {
    const authInfo = cardAuthInfoPayload || cardAuthInfo;
    if (authInfo && cardImgSrc?.length === 0) {
      if (Object.keys(authInfo).length) {
        if (Platform.OS === 'web') {
          fetch(authInfo.secure_url).then(async (res) => {
            if (res?.blob) {
              const imageBlob = await res.blob();
              const imageObjectURL = URL.createObjectURL(imageBlob);
              dispatch(cardsActions.setCardImage(imageObjectURL));
            } else {
              if (spendingAccountId) {
                dispatch(
                  cardsThunks.getCardAuthInfo({ cardId: spendingAccountId })
                );
              }
              dispatch(cardsActions.setCardImage(''));
            }
          });
        } else {
          dispatch(cardsActions.setCardImage(authInfo.secure_url));
          BlastedImage.preload([{ uri: authInfo.secure_url }]);
        }
      }
    }
  };

  useEffect(() => {
    if (cardAuthInfo?.secure_url) {
      fetchImage();
    } else {
      dispatch(cardsThunks.getCardAuthInfo({ cardId: spendingAccountId })).then(
        (response: any) => {
          fetchImage(response.payload);
        }
      );
    }
  }, [cardAuthInfo]);

  return (
    <MediaLayoutTemplate
      Mobile={DebitCardView.mobile}
      Desktop={isTablet ? DebitCardView.mobile : DebitCardView.desktop}
      navigateBack={navigateBack}
      menuItems={menuItems}
      state={cardStatus}
      cardImgSrc={cardImgSrc || cardAuthInfo?.secure_url}
      cardInfo={cardInfo || emptyCard}
      cardAuthInfo={cardAuthInfo}
      learnMoreHandler={learnMoreHandler}
      isBlockAnimated={isBlockAnimated}
      isFreezeModalOpen={isFreezeModalOpen}
      setIsFreezeModalOpen={setIsFreezeModalOpen}
      freezeCardHandler={freezeCardHandler}
      unfreezeCardHandler={unfreezeCardHandler}
    />
  );
};

export default DebitCardPage;
