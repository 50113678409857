import { ImageWithContent } from '@molecules';
import { View } from 'tamagui';
import { Text } from '@atoms';
import OrderedCardIcon from '../../../assets/cards/orderedCard.svg';
import { IShipmentInfo } from '@store/features/cards/types';
import { CopyToClipboardType } from '@devTypes/accounts';
import CopyIcon from '../../../assets/copy.svg';

interface OrderNewCardStatusContentProps {
  orderStatus: 'success' | 'error';
  shipmentInfo?: IShipmentInfo;
  copyToClipboard: CopyToClipboardType;
}

const OrderNewCardStatusContent = ({
  orderStatus,
  shipmentInfo,
  copyToClipboard,
}: OrderNewCardStatusContentProps) => {
  return (
    <View
      paddingTop={'$7'}
      paddingHorizontal={'$4'}
      flexGrow={1}
      justifyContent={'space-between'}
      display={'flex'}
      flexDirection={'column'}
      height={'100%'}
      accessible={false}
      accessibilityLabel="Order New Card Status"
    >
      <ImageWithContent image={<OrderedCardIcon />} space={'$7'}>
        {orderStatus === 'success' ? (
          <>
            {shipmentInfo && shipmentInfo.shipment_date?.length > 0 ? (
              <Text variant="title" pb={'$5'}>
                Your card was shipped
              </Text>
            ) : (
              <>
                {shipmentInfo ? (
                  <Text variant="title" pb={'$5'}>
                    Your card was ordered
                  </Text>
                ) : (
                  <>
                    <Text variant="title" pb={'$2'}>
                      Card ordered!
                    </Text>

                    <Text variant="subtitle" pb={'$5'}>
                      Check here later for shipping details.
                    </Text>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Text variant="title" paddingBottom={'$2'}>
              Something went wrong
            </Text>

            <Text variant="subtitle" paddingBottom={'$5'}>
              Try again later
            </Text>
          </>
        )}
        {shipmentInfo && (
          <View
            backgroundColor="$blue10"
            borderRadius={8}
            p={'$4'}
            width="100%"
          >
            <Text paddingBottom={'$1'} variant="subtitle">
              Ship date
            </Text>
            <Text paddingBottom={'$4'} variant="regular">
              {shipmentInfo.shipment_date?.length > 0
                ? shipmentInfo.shipment_date
                : '-'}
            </Text>
            <Text paddingBottom={'$1'} variant="subtitle">
              Delivery estimate
            </Text>
            <Text paddingBottom={'$4'} variant="regular">
              {shipmentInfo.shipment_method_info ||
                ' 7-10 business days after shipping'}
            </Text>
            <Text paddingBottom={'$1'} variant="subtitle">
              Tracking number
            </Text>
            <View
              onPress={() => {
                shipmentInfo.tracking_number?.length > 0 &&
                  copyToClipboard({
                    text: shipmentInfo.tracking_number || '',
                    title: 'Tracking number',
                  });
              }}
              display={'flex'}
              justifyContent="space-between"
              flexDirection="row"
              testID="paychexpay.OrderNewCardStatusContent.button.copyIcon"
            >
              <Text variant="regular">
                {shipmentInfo.tracking_number?.length > 0
                  ? shipmentInfo.tracking_number
                  : '-'}
              </Text>
              {shipmentInfo.tracking_number?.length > 0 && <CopyIcon />}
            </View>
          </View>
        )}
      </ImageWithContent>
    </View>
  );
};

export default OrderNewCardStatusContent;
