import { createTypedThunk } from '@utils/createTypedThunk';
import transfersApi from '@store/features/transfers/api';
import {
  IExternalAccountTransferRequest,
  IExternalAccountTransferResponse,
  INetworkTransferRequest,
  ITransferRequest,
  ITransferResponse,
} from '@store/features/transfers/types';

const transfersThunks = {
  postTransfer: createTypedThunk<ITransferRequest, ITransferResponse>(
    'transfers/postTransfer',
    transfersApi.postTransfer
  ),
  postNetworkTransfer: createTypedThunk<
    INetworkTransferRequest,
    ITransferResponse
  >('transfers/postNetworkTransfer', transfersApi.postNetworkTransfer),
  postOutgoing: createTypedThunk<
    IExternalAccountTransferRequest,
    IExternalAccountTransferResponse
  >('transfers/postOutgoing', transfersApi.postOutgoing),
  postIncoming: createTypedThunk<
    IExternalAccountTransferRequest,
    IExternalAccountTransferResponse
  >('transfers/postIncoming', transfersApi.postIncoming),
};

export default transfersThunks;
