import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const userInfoSelector = (state: RootState) => state.fillInData.userInfo;
const addressSuggestionSelector = (state: RootState) =>
  state.fillInData.addressSuggestions;
const isActiveSelector = (state: RootState) =>
  state.fillInData.addressVerificationStatus;
const lockedInputsSelector = (state: RootState) =>
  state.fillInData.lockedInputs;

export const selectUserInfo = createSelector(userInfoSelector, (data) => data);
export const selectFillInDataFirst = createSelector(
  userInfoSelector,
  (data) => ({
    firstName: data.firstName,
    lastName: data.lastName,
    phoneNumber: data.phoneNumber,
    emailAddress: data.emailAddress,
  })
);

export const selectFillInDataSecond = createSelector(
  userInfoSelector,
  (data) => ({
    zipCode: data.zipCode,
    city: data.city,
    state: data.state,
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    ssn: data.ssn,
    date: data.date,
  })
);

export const selectSsnNumber = createSelector(
  userInfoSelector,
  (data) => data.ssn
);

export const selectMobilePhone = createSelector(userInfoSelector, (data) => {
  return { phoneNumber: data.phoneNumber };
});
export const selectPersonalEmail = createSelector(userInfoSelector, (data) => {
  return { emailAddress: data.emailAddress };
});

export const selectIsAddressVerified = createSelector(
  isActiveSelector,
  (state) => state
);

export const selectAddressSuggestions = createSelector(
  addressSuggestionSelector,
  (state) => state
);

export const selectIsDateLocked = createSelector(
  lockedInputsSelector,
  (state) => state.includes('date')
);

export const selectIsSsnLocked = createSelector(lockedInputsSelector, (state) =>
  state.includes('ssn')
);

export const selectIsFirstNameLocked = createSelector(
  lockedInputsSelector,
  (state) => state.includes('firstName')
);

export const selectIsLastNameLocked = createSelector(
  lockedInputsSelector,
  (state) => state.includes('lastName')
);
