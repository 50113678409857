import React, { Component, ReactNode } from 'react';
import ErrorGlobalPage from '../../../pages/Common/ErrorGlobalPage';
import errorThunks from '@store/features/errorStates/asyncThunks';
import { Platform } from 'react-native';
import { store } from '@store';

type propType = {
  children: ReactNode;
};
type stateType = {
  hasError: boolean;
};

class ErrorBoundary extends Component<propType, stateType> {
  constructor(props: propType) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    store.dispatch(
      errorThunks.postErrorInfo([
        {
          label: 'UI-ERROR',
          data: {
            OS: Platform.OS,
            error: { message: error.message, stack: error.stack },
          },
        },
      ])
    );
    return;
  }

  render() {
    if (this.state.hasError) {
      return <ErrorGlobalPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
