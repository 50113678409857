import {
  HeaderAndFooterMobileTemplate,
  SideBarTemplate,
  WebLogoTemplate,
} from '@templates';
import { View } from 'tamagui';
import { FooterButtonsRow, MobileWebHeader, ScrollBanner } from '@molecules';
import { Button, Line, TButton, Text } from '@atoms';
import { AgreementsContent } from '@organisms';
import { maxMediaQuery } from '@constants/ui';
import { Platform, useWindowDimensions } from 'react-native';
import WebView from 'react-native-webview';
import React, { useRef } from 'react';
import LottieView from 'lottie-react-native';
import { IAgreement } from '@store/features/agreements/types';
import LoadingEllipsis from '@utils/LoadingEllipsis';
import { Center, Image } from 'native-base';
import { customTheme } from '../../../styles/customTheme';
import DownloadButtonIcon from '../../../assets/download.svg';

interface AgreementsContentWrapperProps {
  onPressScrollArrow: () => void;
  agreementPdfLink?: string;
  agreementName?: string;
  agreements: Array<IAgreement>;
  currentVisibleIndex: number;
  setIsAgreeDisabled: (b: boolean) => void;
  setNumberOfPages: (n: number) => void;
  pdfRef: any;
  numberOfPages: number;
  scrollViewRef: any;
  screenType: 'single' | 'multiple';
  readOnly?: boolean;
  footerWrapperHeight?: number;
}
interface AgreementsViewProps extends AgreementsContentWrapperProps {
  onPressAgree?: () => void;
  onPressBack: () => void;
  onPressSave?: (params: {
    agreementPdfLink: string | undefined;
    title: string;
  }) => void;
  isSaveFileLoading?: boolean;
  isAgreeDisabled: boolean;
  title: string;
  isAgreeLoading?: boolean;
  isResettingToken: boolean;
  loginInfo: any;
  handleWebViewNavigationStateChange: () => void;
  isWebViewLoading: boolean;
  showSideBar?: boolean;
}

const OpenSavingsAccountLoaderContentWeb = () => {
  return (
    <Center flexGrow={1}>
      <Image
        source={require('../../../assets/lotties/moneyBagsLoader.gif')}
        width="250px"
        height="250px"
      />
      <Text
        fontSize={14}
        color="$gray50"
        marginTop="4"
        width={90}
        textAlign="center"
      >
        <View display="flex" alignItems="flex-start">
          <LoadingEllipsis text={'Processing'} />
        </View>
      </Text>
    </Center>
  );
};

const OpenSavingsAccountLoaderContentMobile = () => {
  return Platform.OS === 'web' ? (
    <OpenSavingsAccountLoaderContentWeb />
  ) : (
    <Center flexGrow={1}>
      <LottieView
        source={require('../../../assets/lotties/moneyBagsLoader.json')}
        testID={'paychexpay.loaderPaychex'}
        autoPlay
        loop
        style={{
          width: 296,
          height: 240,
        }}
      />
      <Text
        fontSize={14}
        color="$gray50"
        marginTop="4"
        width="310px"
        textAlign="center"
      >
        <LoadingEllipsis text={'Processing'} />
      </Text>
    </Center>
  );
};

const CreateCardholderLoaderContentModile = () => (
  <View justifyContent={'center'} alignItems={'center'} flexGrow={1}>
    <LottieView
      source={require('../../../assets/lotties/cardholder.json')}
      autoPlay
      loop
      style={{ width: 250, height: 250 }}
    />
    <Text
      fontSize={14}
      color="$gray50"
      marginTop="4"
      width="310px"
      textAlign="center"
    >
      <LoadingEllipsis text={'Processing'} />
    </Text>
  </View>
);

const getAgreementsText = (
  currentAgreementIndex: number,
  screenType: AgreementsContentWrapperProps['screenType']
) => {
  if (screenType === 'multiple') {
    switch (currentAgreementIndex) {
      case 0:
        return 'By tapping on or clicking “I agree”, I confirm that I have read and agree to the Electronic Communications (“ESIGN”) Agreement, which states all disclosures and communications will be provided electronically.';
      case 1:
        return 'By tapping on or clicking “I agree”, I confirm that I have read and agree with the Deposit Account Agreement.';
      case 2:
        return 'By tapping on or clicking “I agree”, I confirm that I have read and agree with the Fee Schedule.';
      case 3:
        return 'By tapping on or clicking “I agree”, I confirm that I have read and agree with the Privacy Policy.';
      case 4:
        return 'By tapping on or clicking “I agree”, I confirm that I have read and agree with the Paychex Pay Terms of Use.';
      default:
        return 'By tapping on or clicking “I agree”, I confirm that I have read and agree with the document above.';
    }
  } else {
    return 'By tapping on or clicking “I agree”, I confirm that I have read and agree with the Savings Account Agreement.';
  }
};

const AgreementContentWrapper = ({
  onPressScrollArrow,
  agreements,
  currentVisibleIndex,
  setNumberOfPages,
  pdfRef,
  agreementPdfLink,
  agreementName,
  setIsAgreeDisabled,
  numberOfPages,
  scrollViewRef,
  screenType = 'multiple',
  readOnly,
  footerWrapperHeight,
}: AgreementsContentWrapperProps) => (
  <>
    {Platform.OS === 'web' ? (
      <>
        <>{!readOnly && <ScrollBanner />}</>
        {screenType === 'single' ? (
          <View overflow="scroll">
            <AgreementsContent
              agreementPdfLink={agreementPdfLink}
              isVisible={true}
              agreementName={agreementName}
              setNumberOfPages={setNumberOfPages}
              scrollViewRef={scrollViewRef}
              setIsAgreeDisabled={setIsAgreeDisabled}
              numberOfPages={numberOfPages}
              onPressScrollArrow={onPressScrollArrow}
              showScrollToEndButton={!readOnly}
              showContentHeader={false}
              footerWrapperHeight={footerWrapperHeight || 0}
            />
          </View>
        ) : (
          <View overflow="scroll">
            {agreements.map((agreement, index) => (
              <AgreementsContent
                key={agreement.id}
                agreementPdfLink={agreement.document.url}
                isVisible={currentVisibleIndex === index}
                agreementName={agreement.name}
                setNumberOfPages={setNumberOfPages}
                scrollViewRef={scrollViewRef}
                setIsAgreeDisabled={setIsAgreeDisabled}
                numberOfPages={numberOfPages}
                onPressScrollArrow={onPressScrollArrow}
                showScrollToEndButton={!readOnly}
                footerWrapperHeight={footerWrapperHeight}
              />
            ))}
          </View>
        )}
      </>
    ) : (
      <View position="relative" flexGrow={1}>
        <>{!readOnly && <ScrollBanner />}</>
        {screenType === 'single' ? (
          <AgreementsContent
            isVisible={true}
            agreementPdfLink={agreementPdfLink}
            agreementName={agreementName}
            setNumberOfPages={setNumberOfPages}
            pdfRef={pdfRef}
            setIsAgreeDisabled={setIsAgreeDisabled}
            onPressScrollArrow={onPressScrollArrow}
            showScrollToEndButton={!readOnly}
            showContentHeader={false}
          />
        ) : (
          <>
            {agreements.map((agreement, index) => (
              <AgreementsContent
                key={agreement.id}
                isVisible={currentVisibleIndex === index}
                agreementPdfLink={agreement.document.url}
                agreementName={agreement.name}
                setNumberOfPages={setNumberOfPages}
                pdfRef={pdfRef}
                setIsAgreeDisabled={setIsAgreeDisabled}
                onPressScrollArrow={onPressScrollArrow}
                showScrollToEndButton={!readOnly}
              />
            ))}
          </>
        )}
      </View>
    )}
  </>
);

const mobile = ({
  onPressAgree,
  onPressBack,
  isAgreeDisabled,
  title,
  onPressScrollArrow,
  agreements,
  currentVisibleIndex,
  setNumberOfPages,
  pdfRef,
  agreementPdfLink,
  agreementName,
  setIsAgreeDisabled,
  numberOfPages,
  scrollViewRef,
  isAgreeLoading,
  isResettingToken,
  isWebViewLoading,
  loginInfo,
  handleWebViewNavigationStateChange,
  screenType = 'multiple',
  readOnly = false,
  onPressSave,
  isSaveFileLoading,
}: AgreementsViewProps) => {
  const footerWrapperRef = useRef(null);
  const [footerWrapperHeight, setFooterWrapperHeight] = React.useState(0);

  return (
    <>
      {isResettingToken ? (
        <View position="relative" flexGrow={1}>
          <View flexGrow={1} backgroundColor="white">
            {isAgreeLoading ? (
              <OpenSavingsAccountLoaderContentMobile />
            ) : (
              <CreateCardholderLoaderContentModile />
            )}
            <View height={0}>
              {loginInfo.authorizeUrl !== null && !isWebViewLoading && (
                <WebView
                  domStorageEnabled={true}
                  source={{ uri: loginInfo.authorizeUrl }}
                  startInLoadingState={true}
                  renderLoading={() => <></>}
                  onNavigationStateChange={handleWebViewNavigationStateChange}
                  scalesPageToFit={true}
                  style={{
                    height: 0,
                  }}
                />
              )}
            </View>
          </View>
        </View>
      ) : (
        <>
          {readOnly ? (
            <>
              {Platform.OS === 'web' && <MobileWebHeader />}
              <HeaderAndFooterMobileTemplate
                header={{
                  title: title,
                  isBackButton: true,
                  onPressBack: onPressBack,
                }}
                footerContent={
                  <View
                    ref={footerWrapperRef}
                    // @ts-ignore
                    onLayout={() => {
                      // @ts-ignore
                      footerWrapperRef.current?.measureInWindow(
                        // @ts-ignore
                        (x, y, width, height) => {
                          const calculatedHeight = height + 32; // 32 -> padding 16 top and bottom
                          setFooterWrapperHeight(calculatedHeight);
                        }
                      );
                    }}
                  >
                    <Button
                      onPress={() =>
                        onPressSave &&
                        onPressSave({
                          agreementPdfLink: agreementPdfLink,
                          title: title,
                        })
                      }
                      isLoading={isSaveFileLoading}
                      testID="paychexpay.mobile.agreementsView.button.saveButton"
                      leftIcon={
                        <DownloadButtonIcon
                          width="24px"
                          height="24px"
                          fill={customTheme.colors.white}
                        />
                      }
                    >
                      Save
                    </Button>
                  </View>
                }
              >
                <AgreementContentWrapper
                  onPressScrollArrow={onPressScrollArrow}
                  agreements={agreements}
                  currentVisibleIndex={currentVisibleIndex}
                  setNumberOfPages={setNumberOfPages}
                  pdfRef={pdfRef}
                  agreementPdfLink={agreementPdfLink}
                  agreementName={agreementName}
                  setIsAgreeDisabled={setIsAgreeDisabled}
                  numberOfPages={numberOfPages}
                  scrollViewRef={scrollViewRef}
                  screenType={screenType}
                  readOnly={readOnly}
                  footerWrapperHeight={footerWrapperHeight}
                />
              </HeaderAndFooterMobileTemplate>
            </>
          ) : (
            <>
              {isAgreeLoading ? (
                <View
                  display={'flex'}
                  flexDirection={'column'}
                  flexGrow={1}
                  bg="white"
                >
                  <OpenSavingsAccountLoaderContentMobile />
                </View>
              ) : (
                <HeaderAndFooterMobileTemplate
                  header={{
                    title: title,
                    isBackButton: true,
                    onPressBack: onPressBack,
                  }}
                  footerContent={
                    <View
                      ref={footerWrapperRef}
                      // @ts-ignore
                      onLayout={() => {
                        // @ts-ignore
                        footerWrapperRef.current?.measureInWindow(
                          // @ts-ignore
                          (x, y, width, height) => {
                            const calculatedHeight = height + 32; // 32 -> padding 16 top and bottom
                            setFooterWrapperHeight(calculatedHeight);
                          }
                        );
                      }}
                    >
                      <Text
                        variant={'caption'}
                        fontSize={12}
                        color={'$black'}
                        textAlign={'center'}
                        paddingBottom={16}
                      >
                        {getAgreementsText(currentVisibleIndex, screenType)}
                      </Text>
                      <TButton
                        onPress={() => onPressAgree && onPressAgree()}
                        disabled={isAgreeDisabled}
                        isLoading={isAgreeLoading}
                        testID="paychexpay.mobile.agreementsView.button.agreeButton"
                      >
                        I agree
                      </TButton>
                    </View>
                  }
                >
                  <AgreementContentWrapper
                    onPressScrollArrow={onPressScrollArrow}
                    agreements={agreements}
                    currentVisibleIndex={currentVisibleIndex}
                    setNumberOfPages={setNumberOfPages}
                    pdfRef={pdfRef}
                    agreementPdfLink={agreementPdfLink}
                    agreementName={agreementName}
                    setIsAgreeDisabled={setIsAgreeDisabled}
                    numberOfPages={numberOfPages}
                    scrollViewRef={scrollViewRef}
                    screenType={screenType}
                    readOnly={readOnly}
                    footerWrapperHeight={footerWrapperHeight}
                  />
                </HeaderAndFooterMobileTemplate>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

const DesktopContent = ({
  onPressAgree,
  onPressBack,
  onPressScrollArrow,
  isAgreeDisabled,
  isAgreeLoading,
  agreements,
  currentVisibleIndex,
  setNumberOfPages,
  setIsAgreeDisabled,
  numberOfPages,
  scrollViewRef,
  title,
  agreementPdfLink,
  agreementName,
  screenType = 'multiple',
  readOnly = false,
  onPressSave,
  isSaveFileLoading,
}: AgreementsViewProps) => {
  const { width: desktopWidth } = useWindowDimensions();

  return (
    <>
      {isAgreeLoading ? (
        <WebLogoTemplate withLogoIcon={desktopWidth > maxMediaQuery.laptop}>
          <View
            maxWidth={900}
            width="100%"
            minHeight={readOnly && onPressSave ? '555px' : '710px'}
            borderRadius={12}
            borderColor="$gray20"
            borderWidth={1}
            overflow="hidden"
            marginBottom={16}
            bg="white"
            display={'flex'}
            flexDirection={'column'}
            justifyContent="center"
            alignItems={'center'}
          >
            <OpenSavingsAccountLoaderContentWeb />
          </View>
        </WebLogoTemplate>
      ) : (
        <WebLogoTemplate withLogoIcon={desktopWidth > maxMediaQuery.laptop}>
          <Text variant={'title'} mb={32}>
            {title}
          </Text>
          <View
            maxWidth="900px"
            width="100%"
            minHeight={readOnly && onPressSave ? '555px' : '710px'}
            borderRadius={12}
            borderColor="$gray20"
            borderWidth={1}
            overflow="hidden"
            marginBottom={16}
          >
            <>{!readOnly && <ScrollBanner />}</>
            {screenType === 'single' ? (
              <AgreementsContent
                isVisible={true}
                agreementName={agreementName}
                agreementPdfLink={agreementPdfLink}
                setNumberOfPages={setNumberOfPages}
                scrollViewRef={scrollViewRef}
                setIsAgreeDisabled={setIsAgreeDisabled}
                numberOfPages={numberOfPages}
                onPressScrollArrow={onPressScrollArrow}
                showScrollToEndButton={!readOnly}
                showContentHeader={false}
              />
            ) : (
              <>
                {agreements.map((agreement, index) => (
                  <AgreementsContent
                    key={agreement.id}
                    isVisible={currentVisibleIndex === index}
                    agreementName={agreement.name}
                    agreementPdfLink={agreement.document.url}
                    setNumberOfPages={setNumberOfPages}
                    scrollViewRef={scrollViewRef}
                    setIsAgreeDisabled={setIsAgreeDisabled}
                    numberOfPages={numberOfPages}
                    onPressScrollArrow={onPressScrollArrow}
                    showScrollToEndButton={!readOnly}
                  />
                ))}
              </>
            )}
            {!readOnly && (
              <View
                minHeight={130}
                flex={1}
                flexGrow={1}
                maxWidth="900px"
                width="100%"
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Line />

                <Text
                  variant={'caption'}
                  fontSize={12}
                  color={'$black'}
                  textAlign={'center'}
                  paddingTop={16}
                >
                  {getAgreementsText(currentVisibleIndex, screenType)}
                </Text>
                <View
                  width={340}
                  display={'flex'}
                  flexDirection={'row'}
                  flex={1}
                  justifyContent={'center'}
                  alignItems={'center'}
                  paddingVertical={16}
                >
                  <FooterButtonsRow
                    isLoading={isAgreeLoading}
                    firstButton={{
                      label: 'Back',
                      handler: onPressBack,
                      testID:
                        'paychexpay.agreementsView.footerButtonsRow.button.backButton',
                    }}
                    secondButton={{
                      label: 'I agree',
                      handler: () => onPressAgree && onPressAgree(),
                      isDisabled: isAgreeDisabled,
                      testID:
                        'paychexpay.agreementsView.footerButtonsRow.button.agreeButton',
                    }}
                  />
                </View>
              </View>
            )}
            {readOnly && onPressSave && (
              <View
                maxWidth="900px"
                width="100%"
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Line />

                <View width={340} paddingVertical={16}>
                  <Button
                    onPress={() =>
                      onPressSave &&
                      onPressSave({
                        agreementPdfLink: agreementPdfLink,
                        title: title,
                      })
                    }
                    isLoading={isSaveFileLoading}
                    testID="paychexpay.mobile.agreementsView.button.saveButton"
                    leftIcon={
                      <DownloadButtonIcon
                        width="24px"
                        height="24px"
                        fill={customTheme.colors.white}
                      />
                    }
                  >
                    Save
                  </Button>
                </View>
              </View>
            )}
          </View>
        </WebLogoTemplate>
      )}
    </>
  );
};

const desktop = (props: AgreementsViewProps) => {
  return (
    <>
      {props.showSideBar ? (
        <SideBarTemplate>
          <DesktopContent {...props} />
        </SideBarTemplate>
      ) : (
        <DesktopContent {...props} />
      )}
    </>
  );
};

const AgreementsView = {
  mobile,
  desktop,
};

export default AgreementsView;
