import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCardUnfreezeCalledFrom,
  selectDebitCardStatus,
} from '@store/features/cards/selectors';
import { TOAST_POPUP_VISIBILITY_TIME } from '@constants/general';
import { Platform } from 'react-native';
import Toast from 'react-native-toast-message';
import { CARD_STATES } from '@constants/cardStates';

const useUnfreezeCardToastPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const debitCardStatus = useSelector(selectDebitCardStatus);
  const cardUnfreezeCalledFrom = useSelector(selectCardUnfreezeCalledFrom);
  const [isFrozen, setIsFrozen] = useState(false);

  useEffect(() => {
    if (!isFrozen && debitCardStatus === CARD_STATES.frozen) {
      setIsFrozen(true);
    }
  }, [isFrozen, debitCardStatus]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (
      cardUnfreezeCalledFrom === 'widget' &&
      isFrozen &&
      debitCardStatus === CARD_STATES.regular
    ) {
      if (Platform.OS !== 'web') {
        Toast.show({
          type: 'copySuccess',
          text1: 'Unfrozen! Your card is ready to use again.',
          visibilityTime: TOAST_POPUP_VISIBILITY_TIME,
        });
        setIsFrozen(false);
      } else {
        setIsOpen(true);
        timeout = setTimeout(() => {
          setIsOpen(false);
          setIsFrozen(false);
        }, TOAST_POPUP_VISIBILITY_TIME);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [cardUnfreezeCalledFrom, isFrozen, debitCardStatus]);

  return { isUnfrozenCardToastPopupOpen: isOpen };
};

export default useUnfreezeCardToastPopup;
