import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const homeWidgetsStateSelector = (state: RootState) => state.homeWidgets;

export const physicalCardDeliveryWidget = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.physicalCardDeliveryWidget.activeType
);

export const frozenCardWidget = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.frozenCardWidget.activeType
);

export const savingsWidget = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.isSavingsWidgetVisible
);

export const rewardsWidget = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.isRewardsWidgetVisible
);

export const selectPayBillsUrl = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.payBillsUrl
);

export const selectDdaEligibility = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.ddaEligibility
);

export const selectDdaEligibilityLoading = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.ddaEligibilityLoading
);

export const selectIngoData = createSelector(
  homeWidgetsStateSelector,
  (homeWidgetsState) => homeWidgetsState.ingoData
);
