import Clipboard from '@react-native-clipboard/clipboard';
import { Platform } from 'react-native';
import Toast from 'react-native-toast-message';
import { TOAST_POPUP_VISIBILITY_TIME } from '@constants/general';
import { useEffect, useState } from 'react';
import ToastPopup from '@atoms/ToastPopup/ToastPopup.web';

const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState<string>('');
  const [isWebToastOpen, setIsWebToastOpen] = useState(false);

  useEffect(() => {
    if (isWebToastOpen) {
      const timer = setTimeout(() => {
        setIsWebToastOpen(false);
      }, TOAST_POPUP_VISIBILITY_TIME);
      return () => clearTimeout(timer);
    }
  }, [isWebToastOpen]);

  const copyToClipboardHandler = ({
    text,
    title,
  }: {
    text: string;
    title: string;
  }): void => {
    Clipboard.setString(text);
    if (Platform.OS === 'web') {
      setCopiedText(`${title} has been copied to clipboard`);
      setIsWebToastOpen(true);
    } else {
      Toast.show({
        type: 'copySuccess',
        text1: `${title} has been copied to clipboard`,
        visibilityTime: TOAST_POPUP_VISIBILITY_TIME,
      });
    }
  };

  const ToastPopupWithProps = (
    <ToastPopup isOpen={isWebToastOpen} text={copiedText} />
  );

  return {
    copiedText,
    isWebToastOpen,
    copyToClipboardHandler,
    CopyTextToastPopup: ToastPopupWithProps,
  };
};

// @ts-ignore
export default useCopyToClipboard;
// @ts-ignore
