import { View, ScrollView, YStack } from 'tamagui';
import { FillInDataFirstForm } from '@organisms';
import HeaderAndFooterMobileTemplate from '@templates/HeaderAndFooterMobileTemplate/HeaderAndFooterMobileTemplate';
import { Control, FieldErrors } from 'react-hook-form';
import { IFillInDataFirstFormInputs } from '@organisms/FillInDataFirstForm/helper';
import { WebLogoTemplate } from '@templates';
import { TButton, Text } from '@atoms';
import { FillInDataSkeleton } from '@molecules';

interface FillInDataFirstViewProps {
  control: Control<IFillInDataFirstFormInputs>;
  onSubmit: () => void;
  errors: FieldErrors;
  isStateReady: boolean;
}

const mobile = ({
  control,
  onSubmit,
  errors,
  isStateReady,
}: FillInDataFirstViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      header={{
        // eslint-disable-next-line
        title: "Let's start with your basic info",
      }}
      footerContent={
        <TButton
          onPress={onSubmit}
          disabled={Object.keys(errors).length !== 0 || !isStateReady}
          testID="paychexpay.mobile.fillInDataFirstView.button.continueButton"
        >
          Continue
        </TButton>
      }
    >
      <View
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        marginHorizontal={16}
        marginTop={26}
        justifyContent={'space-between'}
      >
        <ScrollView bounces={false}>
          <YStack
            paddingTop={6}
            paddingHorizontal={4}
            backgroundColor="white"
            justifyContent="space-between"
            height="100%"
          >
            {isStateReady ? (
              <FillInDataFirstForm
                control={control}
                onSubmit={onSubmit}
                errors={errors}
                isStateReady={isStateReady}
              />
            ) : (
              <FillInDataSkeleton />
            )}
          </YStack>
        </ScrollView>
        <View flexGrow={0} paddingBottom={6} display={'flex'} flex={0}>
          <Text variant={'caption'} textAlign="center">
            By choosing to continue, I authorize account notifications to be
            sent to me via SMS and email. Message and data rates may apply.
          </Text>
        </View>
      </View>
    </HeaderAndFooterMobileTemplate>
  );
};

const desktop = ({
  control,
  onSubmit,
  errors,
  isStateReady,
}: FillInDataFirstViewProps) => {
  return (
    <WebLogoTemplate>
      <View width="325px">
        <Text variant="title" paddingBottom={32} textAlign={'center'}>
          Let&#39;s start with your basic info
        </Text>
        {isStateReady ? (
          <FillInDataFirstForm
            control={control}
            onSubmit={onSubmit}
            errors={errors}
            isStateReady={isStateReady}
          />
        ) : (
          <FillInDataSkeleton />
        )}
        <TButton
          onPress={onSubmit}
          disabled={Object.keys(errors).length !== 0 || !isStateReady}
          my={'$6'}
          testID="paychexpay.desktop.fillInDataFirstView.button.continueButton"
        >
          Continue
        </TButton>
        <Text variant="caption" textAlign="center">
          By choosing to continue, I authorize account notifications to be sent
          to me via SMS and email. Message and data rates may apply.
        </Text>
      </View>
    </WebLogoTemplate>
  );
};

const FillInDataFirstView = {
  mobile,
  desktop,
};

export default FillInDataFirstView;
