import { Separator, View, XStack } from 'tamagui';
import useIsPhone from '@hooks/useIsPhone';
import MenuArrow from '../../../assets/menuArrow.svg';
import MenuArrowDisabled from '../../../assets/menuArrowDisabled.svg';
import { Loader, Text } from '@atoms';
import React from 'react';

export interface DebitCardMenuItemProps {
  title: string;
  subtitle?: string;
  hideArrow?: boolean;
  handler: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  testID?: string;
}

const DebitCardMenuItem = ({
  title,
  subtitle,
  hideArrow = false,
  handler,
  isDisabled = false,
  isLoading = false,
  testID,
}: DebitCardMenuItemProps) => {
  const isPhone = useIsPhone();
  return (
    <View
      onPress={handler}
      disabled={isDisabled}
      hoverStyle={{ bg: isDisabled ? 'white' : '$blue10' }}
      pressStyle={{ opacity: isDisabled ? 1 : 0.4 }}
      borderRadius="$1"
      px={isPhone ? 0 : '$4'}
      accessible
      accessibilityLabel={`Debit card menu ${title}`}
      testID={testID}
    >
      <View>
        <XStack
          h={isPhone ? 64 : 56}
          justifyContent="space-between"
          alignItems="center"
        >
          <View>
            <Text
              color={isDisabled ? '$gray50' : '$black'}
              fontWeight={isPhone ? '$regular' : '$medium'}
              fontSize={isPhone ? 16 : 14}
              lineHeight={isPhone ? 22 : 20}
            >
              {title}
            </Text>
            {subtitle && <Text variant="caption">{subtitle}</Text>}
          </View>
          {isLoading && !isPhone ? (
            <View flex={0}>
              <Loader width={24} height={24} />
            </View>
          ) : (
            !hideArrow && (isDisabled ? <MenuArrowDisabled /> : <MenuArrow />)
          )}
        </XStack>
        {isPhone && <Separator borderColor="$gray20" />}
      </View>
    </View>
  );
};

export default DebitCardMenuItem;
