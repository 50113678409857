import faq from '@constants/faq';
import { TPressable, Text } from '@atoms';
import ArrowDown from '../../../assets/arrowDown.svg';
import { useEffect, useState } from 'react';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { customTheme } from '../../../styles/customTheme';
import useIsPhone from '@hooks/useIsPhone';
import textToTextElements from '@utils/textToTextElements';
import { Hyperlink } from 'react-native-hyperlink';
import { linkNameMap } from '@utils/redirectsHelper';
import { ScrollView, Separator, View, XStack } from 'tamagui';
import { Platform } from 'react-native';
import { getWindowHeightWeb } from '@utils/getWindowHeightWeb';
import { MOBILE_WEB_HEADER_HEIGHT } from '@constants/general';
import { useSelector } from 'react-redux';
import { selectVariableATMFee } from '@store/features/general/selectors';
import { IVariable } from '@store/features/general/types';

interface FaqContentProps {
  faqData: typeof faq;
  navigationMap: (link: string) => void;
}

interface FaqItemProps {
  title: string;
  text: string;
  isActive: boolean;
  onPress: () => void;
  isOpenDefault?: boolean;
  navigationMap: (link: string) => void;
  variableATMFee: IVariable | undefined;
}

const animationDuration = 500;

const FaqItem = ({
  title,
  text,
  isActive,
  onPress,
  isOpenDefault = false,
  navigationMap,
  variableATMFee,
}: FaqItemProps) => {
  const [textHeight, setTextHeight] = useState(0);
  const itemHeight = useSharedValue(0);
  const animatedHeightStyle = useAnimatedStyle(() => {
    return {
      height: itemHeight.value,
    };
  });
  const animatedRotationStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: (itemHeight.value * 180) / textHeight + 'deg',
        },
      ],
    };
  });
  useEffect(() => {
    if (isActive) {
      itemHeight.value = withTiming(textHeight, {
        duration: animationDuration,
        easing: Easing.bezier(0.65, 0, 0.35, 1),
      });
    } else {
      itemHeight.value = withTiming(0, {
        duration: animationDuration,
        easing: Easing.bezier(0.65, 0, 0.35, 1),
      });
    }
  }, [isActive, textHeight]);

  useEffect(() => {
    if (isOpenDefault) {
      itemHeight.value = withTiming(textHeight, {
        duration: animationDuration,
        easing: Easing.bezier(0.65, 0, 0.35, 1),
      });
    }
  }, [isOpenDefault, textHeight]);
  return (
    <View>
      <TPressable
        onPress={onPress}
        testID={title}
        accessible
        accessibilityLabel={`Faq item: ${title}, ${
          isActive ? 'tap to collapse' : 'tap to expand'
        }`}
        px={'$4'}
      >
        <XStack
          justifyContent="space-between"
          paddingTop={17}
          paddingBottom={13}
        >
          <View flex={1}>
            <Text variant="regular">{title}</Text>
          </View>
          <Animated.View
            style={[animatedRotationStyle, { height: 24, marginLeft: 14 }]}
          >
            <ArrowDown
              width="24px"
              height="24px"
              fill={
                isActive
                  ? customTheme.colors.primary.normal
                  : customTheme.colors.gray[40]
              }
            />
          </Animated.View>
        </XStack>
      </TPressable>
      <Separator borderColor="$gray20" />
      <Animated.View
        style={[
          animatedHeightStyle,
          { overflow: 'hidden', position: 'relative' },
        ]}
        accessible
      >
        <Hyperlink
          linkText={linkNameMap}
          linkStyle={{
            color: customTheme.colors.primary.normal,
            textDecorationLine: 'underline',
          }}
          onPress={navigationMap}
          injectViewProps={(url) => ({
            testID: `paychexpay.faqItem.button.${url}`,
          })}
        >
          <View
            position="absolute"
            w={'100%'}
            pt={'$3'}
            pl={'$4'}
            onLayout={(event) => {
              setTextHeight(event.nativeEvent.layout.height);
            }}
          >
            {textToTextElements(text, variableATMFee?.value ?? '')}
          </View>
        </Hyperlink>
      </Animated.View>
    </View>
  );
};

const FaqContent = ({ faqData, navigationMap }: FaqContentProps) => {
  const isPhone = useIsPhone();
  const [activeIndex, setActiveIndex] = useState<number | null>(0);
  const variableATMFee = useSelector(selectVariableATMFee);
  const onPressItem = (index: number) => () => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <ScrollView
      contentContainerStyle={{
        marginBottom: isPhone ? 120 : 0,
        paddingTop: 5,
      }}
      style={
        Platform.OS === 'web' && {
          height: isPhone
            ? getWindowHeightWeb() - MOBILE_WEB_HEADER_HEIGHT
            : '100%',
          flexGrow: 1,
        }
      }
      showsVerticalScrollIndicator={false}
    >
      {faqData.map((item, index) => (
        <FaqItem
          {...item}
          isActive={activeIndex === index}
          key={index}
          onPress={onPressItem(index)}
          isOpenDefault={!index}
          navigationMap={navigationMap}
          variableATMFee={variableATMFee}
        />
      ))}
    </ScrollView>
  );
};

export default FaqContent;
