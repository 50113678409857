import { ScrollView, View, XStack } from 'tamagui';
import AccountMenuItem from '@molecules/AccountMenuItem/AccountMenuItem';
import { AccountMenuItemsType, CopyToClipboardType } from '@devTypes/accounts';
import { ACCOUNT_NAMES } from '@constants/accounts';
import { AccountInfoItem } from '@molecules';
import { Text } from '@atoms';
import { IAccountPreview } from '@devTypes/accountTypes';
import React from 'react';
import { Dimensions, Platform } from 'react-native';
import { maxMediaQuery } from '@constants/ui';
import { PAYCHEX_PAY_DEPOSIT_ACCOUNT } from '@constants/policies';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export interface AccountDetailsProps {
  setIsScrollViewScrolled?: (f: boolean) => void;
  account: IAccountPreview;
  isDesktop?: boolean;
  menuItems: AccountMenuItemsType;
  copyToClipboard: CopyToClipboardType;
}

interface ScroollContainerProps {
  onTopHit?: () => void;
  onScroll?: (translateY: number) => void;
  isDesktop?: boolean;
  children: React.ReactNode;
}

const ScrollContainer = ({ isDesktop, children }: ScroollContainerProps) => {
  return Platform.OS === 'web' &&
    Dimensions.get('window').width > maxMediaQuery.tablet ? (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        flexGrow: 1,
      }}
    >
      {children}
    </ScrollView>
  ) : (
    <ScrollView
      contentContainerStyle={{
        paddingBottom: !isDesktop ? 100 : 20,
        marginBottom: 20,
        justifyContent: 'space-between',
      }}
      showsVerticalScrollIndicator={Platform.OS === 'web'}
      style={
        Platform.OS === 'web' && {
          height:
            Dimensions.get('screen').width < 768
              ? Dimensions.get('screen').height - 372
              : '100%',
          flexGrow: 1,
        }
      }
    >
      {children}
    </ScrollView>
  );
};

const AccountDetails = ({
  account,
  isDesktop = false,
  menuItems,
  copyToClipboard,
}: AccountDetailsProps) => {
  const { bottom: bottomInset } = useSafeAreaInsets();

  return (
    <View
      display={'flex'}
      flexDirection={'column'}
      pt={2}
      flexGrow={1}
      height="100%"
    >
      <ScrollContainer>
        <XStack
          backgroundColor="$blue10"
          padding={16}
          gap={16}
          display="flex"
          borderRadius={isDesktop ? 8 : 0}
          flexDirection="column"
        >
          <AccountInfoItem title="Bank name" text={account.bankName} />
          {account.type !== 'saving' && (
            <AccountInfoItem
              title="Routing number"
              text={account.routingNumber}
              onHoldHandler={copyToClipboard}
              testID="paychexpay.accountDetails.button.copyRoutingNumberButton"
            />
          )}

          <AccountInfoItem
            title="Account number"
            text={account.accountNumber}
            onHoldHandler={copyToClipboard}
            testID="paychexpay.accountDetails.button.copyAccountNumberButton"
            hideNumber
          />
        </XStack>
        <XStack
          display="flex"
          paddingHorizontal={isDesktop ? 0 : 16}
          paddingTop={isDesktop ? 16 : 0}
          flexGrow={1}
          flex={1}
          justifyContent="space-between"
          flexDirection="column"
        >
          <View>
            {menuItems.map((item, index) => (
              <AccountMenuItem
                key={index}
                handler={item.handler}
                name={item.label}
                isRed={item.isRed}
                hideArrow={item.hideArrow}
                testID={item.testID}
              />
            ))}
          </View>
        </XStack>
      </ScrollContainer>
      <View
        paddingBottom={bottomInset || 10}
        paddingTop={5}
        paddingHorizontal={Platform.OS === 'web' ? 0 : 16}
        accessible
        accessibilityLabel={`${ACCOUNT_NAMES.PaychexPaySpending.toLowerCase()}`}
        display={'flex'}
        flexDirection={'column'}
      >
        <Text color="$gray40" fontSize={9} textAlign="center" lineHeight={12}>
          {PAYCHEX_PAY_DEPOSIT_ACCOUNT}
        </Text>
      </View>
    </View>
  );
};

export default React.memo(AccountDetails);
