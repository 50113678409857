import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  IExternalAccountDeleteRequest,
  IExternalAccountRequest,
  IExternalAccountsResponse,
  IExternalBankAccount,
} from '@store/features/externalBankAccounts/types';
import { IAdditionalHeadersRequest } from '@store/api/types';
import { application, operations } from '@constants/traceability';

const externalBankAccountsAPI = {
  getExternalAccounts(request?: IAdditionalHeadersRequest | null | undefined) {
    return wrappedAxiosRequest<
      IAdditionalHeadersRequest,
      IExternalAccountsResponse
    >({
      method: 'get',
      url: endpoints.externalBankAccounts,
      headers: {
        ...request?.additionalHeaders,
        'x-payx-bizpn': application.bizpnPrefix + operations.ExternalAccount,
        'x-payx-comp': application.compPrefix + operations.ExternalAccount,
      },
    });
  },
  postExternalAccount(body: IExternalAccountRequest) {
    return wrappedAxiosRequest<IExternalAccountRequest, IExternalBankAccount>({
      method: 'post',
      url: endpoints.externalBankAccounts,
      data: body,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.AddExternalAccount,
        'x-payx-comp': application.compPrefix + operations.AddExternalAccount,
      },
    });
  },
  deleteExternalAccount({ accountId }: IExternalAccountDeleteRequest) {
    return wrappedAxiosRequest<null, undefined>({
      method: 'delete',
      url: endpoints.externalBankAccounts + '/' + accountId,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.DeleteExternalAccount,
        'x-payx-comp':
          application.compPrefix + operations.DeleteExternalAccount,
      },
    });
  },
};

export default externalBankAccountsAPI;
