import { createAsyncThunk } from '@reduxjs/toolkit';

const createCommonAsyncThunk = (
  name: string,
  request: (body: Record<string, any> | null) => Promise<any>
) => {
  return createAsyncThunk(
    name,
    async (body: Record<string, any> | null, { rejectWithValue }) => {
      const { data, error } = await request(body || null);

      if (error) {
        return rejectWithValue(error);
      }

      return data;
    }
  );
};

export { createCommonAsyncThunk };
