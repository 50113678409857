import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  ICardAuthInfo,
  ICardInfo,
  ICardInfoRequest,
  ICardStatusRequest,
  IChangePinRequest,
  IChangePinResponse,
} from '@store/features/cards/types';
import { application, operations } from '@constants/traceability';

const cardsAPI = {
  getCardInfo({ cardId, additionalHeaders = {} }: ICardInfoRequest) {
    return wrappedAxiosRequest<null, ICardInfo>({
      method: 'get',
      url: endpoints.getCardInfo.replace('{card_id}', cardId),
      headers: {
        ...additionalHeaders,
        'x-payx-bizpn': application.bizpnPrefix + operations.GetCardInfo,
        'x-payx-comp': application.compPrefix + operations.GetCardInfo,
      },
    });
  },
  getCardAuthInfo({ cardId }: ICardInfoRequest) {
    return wrappedAxiosRequest<null, ICardAuthInfo>({
      method: 'get',
      url: endpoints.getCardAuthInfo.replace('{card_id}', cardId),
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GetCardAuthInfo,
        'x-payx-comp': application.compPrefix + operations.GetCardAuthInfo,
      },
    });
  },
  putCardStatus({ cardId, status }: ICardStatusRequest) {
    return wrappedAxiosRequest<Record<string, unknown>, ICardInfo>({
      method: 'put',
      url: endpoints.putCardStatus
        .replace('{card_id}', cardId)
        .replace('{status}', status),
      data: {},
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.PutCardStatus,
        'x-payx-comp': application.compPrefix + operations.PutCardStatus,
      },
    });
  },
  createSavingAccount() {
    return wrappedAxiosRequest({
      method: 'post',
      url: endpoints.createSavingAccount,
      data: {},
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.CreateSavingsAccount,
        'x-payx-comp': application.compPrefix + operations.CreateSavingsAccount,
      },
    });
  },
  putActivePhysicalCard({ cardId }: ICardInfoRequest) {
    return wrappedAxiosRequest<Record<string, never>, ICardInfo>({
      method: 'put',
      url: endpoints.activePhysicalCard.replace('{card_id}', cardId),
      data: {},
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.ActivePhysicalCard,
        'x-payx-comp': application.compPrefix + operations.ActivePhysicalCard,
      },
    });
  },
  changePin({ card_id, language_code, cardholder_id }: IChangePinRequest) {
    return wrappedAxiosRequest<IChangePinRequest, IChangePinResponse>({
      method: 'post',
      url: endpoints.changePin,
      data: {
        card_id,
        language_code,
        cardholder_id,
      },
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.ChangePin,
        'x-payx-comp': application.compPrefix + operations.ChangePin,
      },
    });
  },
  postOrderNewCard({ cardId }: ICardInfoRequest) {
    return wrappedAxiosRequest<Record<string, never>, ICardInfo>({
      method: 'post',
      url: endpoints.orderCard.replace('{card_id}', cardId),
      data: {},
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.OrderNewCard,
        'x-payx-comp': application.compPrefix + operations.OrderNewCard,
      },
    });
  },
};

export default cardsAPI;
