import {
  Easing,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';

const animationOptions = {
  duration: 500,
  easing: Easing.bezier(0.65, 0, 0.35, 1),
};

const bounceAnimationOptions = {
  duration: 500,
  easing: Easing.bezier(0.34, 1.4, 0.64, 1),
};

const windowHeight = Dimensions.get('window').height;

const deltaExpressions = {
  delta: 20,
  velocity: 600,
};

const componentsAnimationPoints = {
  topBarHeight: 114,
  buttonHeight: 48,
  spaceYAroundButton: 32,
  topInset: 194,
  opacityAnimationRange: 80,
  arrowAnimationRange: 180,
  titleAnimationRange: 180,
};

interface useMarkerDetailsAnimationProps {
  topInset: number;
  currentMarkerId: string | null;
  unselectMarker: () => void;
  isListViewOpen: boolean;
}

const useMarkerDetailsAnimation = ({
  topInset,
  currentMarkerId,
  unselectMarker,
  isListViewOpen,
}: useMarkerDetailsAnimationProps) => {
  const [isFullscreenView, setIsFullscreenView] = useState(false);
  const [isPreviewClosed, setIsPreviewClosed] = useState(true);
  const detailsViewPoints = {
    start: 0,
    preview: isListViewOpen ? 0 : Platform.OS === 'web' ? 231 : 201,
    full:
      Platform.OS === 'ios' ? windowHeight - topInset - 41 : windowHeight - 41,
  };
  const detailsOffsetY = useSharedValue(detailsViewPoints.start);
  const positionOffsetY = useSharedValue(detailsViewPoints.start);
  const detailsViewStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: -detailsOffsetY.value }],
    };
  });
  const pageTitleStyle = useAnimatedStyle(() => {
    return {
      opacity:
        detailsOffsetY.value <
        detailsViewPoints.full - componentsAnimationPoints.titleAnimationRange
          ? 1
          : (detailsViewPoints.full - detailsOffsetY.value) /
            componentsAnimationPoints.titleAnimationRange,
    };
  });
  const arrowStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ:
            (detailsOffsetY.value <
            detailsViewPoints.full -
              componentsAnimationPoints.arrowAnimationRange
              ? 0
              : (detailsViewPoints.full -
                  componentsAnimationPoints.arrowAnimationRange -
                  detailsOffsetY.value) /
                2) + 'deg',
        },
      ],
    };
  });
  const locationOpacity = () => {
    'worklet';
    if (
      isListViewOpen ||
      detailsOffsetY.value >
        detailsViewPoints.full - componentsAnimationPoints.topInset
    )
      return 0;
    if (
      detailsOffsetY.value <
      detailsViewPoints.full -
        componentsAnimationPoints.topInset -
        componentsAnimationPoints.opacityAnimationRange
    ) {
      return 1;
    }
    return (
      (detailsViewPoints.full -
        componentsAnimationPoints.topInset -
        detailsOffsetY.value) /
      componentsAnimationPoints.opacityAnimationRange
    );
  };
  const locationStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY:
            detailsOffsetY.value < detailsViewPoints.preview
              ? (detailsOffsetY.value / detailsViewPoints.preview) * 16
              : 16,
        },
      ],
      opacity: locationOpacity(),
    };
  });

  const animateToTopPoint = () => {
    'worklet';
    detailsOffsetY.value = withTiming(detailsViewPoints.full, animationOptions);
    positionOffsetY.value = detailsViewPoints.full;
    runOnJS(setIsPreviewClosed)(false);
  };
  const animateToPreviewPoint = () => {
    'worklet';
    detailsOffsetY.value = withTiming(
      detailsViewPoints.preview,
      bounceAnimationOptions
    );
    positionOffsetY.value = detailsViewPoints.preview;
    runOnJS(setIsPreviewClosed)(isListViewOpen);
  };
  const animateToStartPoint = () => {
    'worklet';
    detailsOffsetY.value = withTiming(
      detailsViewPoints.start,
      animationOptions
    );
    positionOffsetY.value = detailsViewPoints.start;
    runOnJS(setIsPreviewClosed)(true);
  };

  useEffect(() => {
    if (currentMarkerId) {
      if (isListViewOpen) {
        animateToTopPoint();
        runOnJS(setIsFullscreenView)(true);
      } else {
        animateToPreviewPoint();
      }
    }
  }, [currentMarkerId]);

  const onPressArrowDown = () => {
    animateToPreviewPoint();
    runOnJS(setIsFullscreenView)(false);
    if (isListViewOpen) {
      unselectMarker();
    }
  };

  const closePreview = () => {
    animateToStartPoint();
    unselectMarker();
  };

  const detailsViewGesture = useAnimatedGestureHandler({
    onActive: (e) => {
      if (e.y < -30) return;
      const currentOffset = positionOffsetY.value - e.translationY;
      if (currentOffset > detailsViewPoints.full) {
        detailsOffsetY.value = detailsViewPoints.full;
      } else {
        detailsOffsetY.value = currentOffset;
      }
    },
    onEnd: (e) => {
      if (e.y < 0) return;
      const currentOffset = positionOffsetY.value - e.translationY;
      if (positionOffsetY.value === detailsViewPoints.preview) {
        // Position preview
        if (
          currentOffset > detailsViewPoints.preview + deltaExpressions.delta ||
          e.velocityY < -deltaExpressions.velocity
        ) {
          animateToTopPoint();
          runOnJS(setIsFullscreenView)(true);
        } else if (
          currentOffset < detailsViewPoints.preview - deltaExpressions.delta ||
          e.velocityY > deltaExpressions.velocity
        ) {
          animateToStartPoint();
          runOnJS(unselectMarker)();
        } else {
          animateToPreviewPoint();
        }
      } else {
        // Position full
        if (
          currentOffset < detailsViewPoints.full - deltaExpressions.delta ||
          e.velocityY > deltaExpressions.velocity
        ) {
          if (currentOffset < detailsViewPoints.preview) {
            animateToStartPoint();
            runOnJS(unselectMarker)();
          } else {
            if (isListViewOpen) runOnJS(unselectMarker)();
            animateToPreviewPoint();
            runOnJS(setIsFullscreenView)(false);
          }
        } else {
          animateToTopPoint();
        }
      }
    },
  });
  return {
    detailsViewStyle,
    detailsViewGesture,
    arrowStyle,
    pageTitleStyle,
    isFullscreenView,
    onPressArrowDown,
    locationStyle,
    closePreview,
    isPreviewClosed,
  };
};

export default useMarkerDetailsAnimation;
