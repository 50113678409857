import { Center } from 'native-base';
import { FillInDataSecondForm } from '@organisms';
import { HeaderAndFooterMobileTemplate, WebLogoTemplate } from '@templates';
import { Control, FieldErrors } from 'react-hook-form';
import { IFillInDataSecondFormInputs } from '@organisms/FillInDataSecondForm/helper';
import { FooterButtonsRow, SideModal } from '@molecules';
import React from 'react';
import AboutSsnContent from '@organisms/AboutSsnContent/AboutSsnContent';
import AboutDobContent from '@organisms/AboutDobContent/AboutDobContent';
import { sideModalNames } from '@constants/sideModalNames';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { IAddressSuggestion } from '@store/features/fillInData/types';
import { maxMediaQuery } from '@constants/ui';
import { useWindowDimensions } from 'react-native';
import { Button, TButton, Text } from '@atoms';
import Animated from 'react-native-reanimated';
import usePoBoxAlertModal from '@hooks/Animations/usePoBoxAlertModal';
import { ScrollView, Separator, View } from 'tamagui';

interface FillInDataSecondViewPropsMobile {
  onPressBack: () => void;
  control: Control<IFillInDataSecondFormInputs>;
  onSubmit: () => void;
  errors: FieldErrors;
  onPressSsn: () => void;
  onPressDob: () => void;
  addressSuggestions: IAddressSuggestion[];
  requestAddressSuggestions: (text: string) => void;
  addressSuggestionHandler: (address: IAddressSuggestion) => void;
  verifyStatus: null | boolean | 'pending';
  isPoBoxAlertVisible: boolean;
  hidePoBoxAlertModal: () => void;
}

interface FillInDataSecondViewPropsDesktop
  extends FillInDataSecondViewPropsMobile {
  toCloseModal: () => void;
  isModalOpen: boolean;
  modalName: string | undefined;
}

const mobile = ({
  onPressBack,
  control,
  onSubmit,
  errors,
  onPressSsn,
  onPressDob,
  addressSuggestions,
  requestAddressSuggestions,
  addressSuggestionHandler,
  verifyStatus,
  isPoBoxAlertVisible,
  hidePoBoxAlertModal,
}: FillInDataSecondViewPropsMobile) => {
  const { backgroundStyle, isModalVisible } = usePoBoxAlertModal({
    isPoBoxAlertVisible,
  });

  return (
    <>
      <HeaderAndFooterMobileTemplate
        header={{
          title: 'Complete your basic info',
          onPressBack,
          isBackButton: true,
        }}
        footerContent={
          <TButton
            onPress={onSubmit}
            disabled={Object.keys(errors).length !== 0}
            testID="paychexpay.mobile.fillInDataSecondView.button.signupButton"
            isLoading={verifyStatus === 'pending'}
          >
            Continue
          </TButton>
        }
      >
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={{
            px: '$4',
            pt: '$6',
          }}
        >
          <FillInDataSecondForm
            control={control}
            onSubmit={onSubmit}
            errors={errors}
            onPressSsn={onPressSsn}
            onPressDob={onPressDob}
            addressSuggestions={addressSuggestions}
            requestAddressSuggestions={requestAddressSuggestions}
            addressSuggestionHandler={addressSuggestionHandler}
          />
        </ScrollView>
      </HeaderAndFooterMobileTemplate>

      {isPoBoxAlertVisible && (
        <Center
          position="absolute"
          top={0}
          left={0}
          h="full"
          w="full"
          zIndex={10}
        >
          <Animated.View
            style={[
              backgroundStyle,
              {
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            ]}
          />
          {isModalVisible && (
            <View w="100%" px={'$4'}>
              <View p={'$4'} bg="white" borderRadius={12}>
                <Text variant="title" pt={'$2'} textAlign="center">
                  Please use another address
                </Text>
                <Text variant="subtitle" pt={'$4'} pb={'$5'} textAlign="center">
                  Please use another address instead of PO box
                </Text>
                <Separator borderColor="$gray20" />
                <View pt={'$4'}>
                  <Button onPress={hidePoBoxAlertModal}>Got it</Button>
                </View>
              </View>
            </View>
          )}
        </Center>
      )}
    </>
  );
};

const desktop = ({
  onPressBack,
  control,
  onSubmit,
  errors,
  onPressSsn,
  onPressDob,
  isModalOpen,
  toCloseModal,
  modalName,
  addressSuggestions,
  requestAddressSuggestions,
  addressSuggestionHandler,
  isPoBoxAlertVisible,
  hidePoBoxAlertModal,
}: FillInDataSecondViewPropsDesktop) => {
  const { width: desktopWidth } = useWindowDimensions();
  const { backgroundStyle, isModalVisible } = usePoBoxAlertModal({
    isPoBoxAlertVisible,
  });

  return (
    <>
      <WebLogoTemplate>
        <View
          w="325px"
          {...(desktopWidth < maxMediaQuery.tablet && { paddingTop: 8 })}
        >
          <Text
            variant="title"
            pb={desktopWidth < maxMediaQuery.tablet ? '$2' : '$6'}
            textAlign={'center'}
          >
            Complete your basic info
          </Text>
          <FillInDataSecondForm
            control={control}
            onSubmit={onSubmit}
            errors={errors}
            onPressSsn={onPressSsn}
            onPressDob={onPressDob}
            addressSuggestions={addressSuggestions}
            requestAddressSuggestions={requestAddressSuggestions}
            addressSuggestionHandler={addressSuggestionHandler}
          />
          <View pt={'$6'}>
            <FooterButtonsRow
              firstButton={{
                label: 'Back',
                handler: onPressBack,
                testID:
                  'paychexpay.desktop.fillInDataSecondView.footerButtonsRow.button.backButton',
              }}
              secondButton={{
                label: 'Continue',
                handler: onSubmit,
                isDisabled: Object.keys(errors).length !== 0,
                testID:
                  'paychexpay.desktop.fillInDataSecondView.footerButtonsRow.button.signupButton',
              }}
            />
          </View>
        </View>
      </WebLogoTemplate>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.ssn]: (
              <View
                testID={'paychexpay.desktop.fillInDataSecondView.SSNSideModal'}
              >
                <Text
                  variant="title"
                  textAlign="center"
                  paddingTop={42}
                  paddingBottom={8}
                >
                  SSN info
                </Text>
                <AboutSsnContent />
              </View>
            ),
            [sideModalNames.dob]: (
              <View
                testID={'paychexpay.desktop.fillInDataSecondView.DOBSideModal'}
              >
                <Text
                  variant="title"
                  textAlign="center"
                  paddingTop={42}
                  paddingBottom={8}
                >
                  Birth date info
                </Text>
                <AboutDobContent />
              </View>
            ),
          }}
        />
      </SideModal>
      {isPoBoxAlertVisible && (
        <Center
          position="absolute"
          top={0}
          left={0}
          h="full"
          w="full"
          zIndex={10}
        >
          <Animated.View
            style={[
              backgroundStyle,
              {
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            ]}
          />
          {isModalVisible && (
            <View
              w={desktopWidth <= maxMediaQuery.tablet ? '100%' : '33%'}
              px={'$4'}
            >
              <View p={'$4'} bg="white" borderRadius={12}>
                <Text variant="title" pt={'$2'} textAlign="center">
                  Please use another address
                </Text>
                <Text variant="subtitle" pt={'$4'} pb={'$5'} textAlign="center">
                  Please use another address instead of PO box
                </Text>
                <Separator borderColor="$gray20" />
                <View pt={'$4'}>
                  <Button onPress={hidePoBoxAlertModal}>Got it</Button>
                </View>
              </View>
            </View>
          )}
        </Center>
      )}
    </>
  );
};

const FillInDataSecondView = {
  mobile,
  desktop,
};

export default FillInDataSecondView;
