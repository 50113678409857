import { createTypedThunk } from '@utils/createTypedThunk';
import cardStatementsApi from './api';
import {
  IStatementRequest,
  IStatementResponse,
  IStatementsListRequest,
  IStatementsListResponse,
} from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAxiosResponse } from '@store/api';

const createTypedStatementsThunk = <ReqType, RespType>(
  name: string,
  request: (body: ReqType) => Promise<IAxiosResponse<RespType>>
) => {
  return createAsyncThunk(name, async (body: ReqType, { rejectWithValue }) => {
    const { error, data } = await request(body);

    if (error) {
      return rejectWithValue(error);
    }

    return { data, body: body };
  });
};

const cardStatementsThunks = {
  getSpendingStatementsList: createTypedThunk<
    IStatementsListRequest,
    IStatementsListResponse
  >(
    'cardStatements/getSpendingStatementsList',
    cardStatementsApi.getSpendingStatementsList
  ),
  getSavingStatementsList: createTypedThunk<
    IStatementsListRequest,
    IStatementsListResponse
  >(
    'cardStatements/getSavingStatementsList',
    cardStatementsApi.getSavingStatementsList
  ),
  getStatement: createTypedStatementsThunk<
    IStatementRequest,
    IStatementResponse
  >('cardStatements/getStatement', cardStatementsApi.getStatement),
};

export default cardStatementsThunks;
