import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import { IRewardsResponse } from './types';
import { application, operations } from '@constants/traceability';

const rewardsApi = {
  getRewardsUrl() {
    return wrappedAxiosRequest<Record<string, never>, IRewardsResponse>({
      method: 'post',
      url: endpoints.rewards,
      data: {},
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.RewardsURL,
        'x-payx-comp': application.compPrefix + operations.RewardsURL,
      },
    });
  },
};

export default rewardsApi;
