import { Button, Text } from '@atoms';
import StatementItem from '@molecules/StatementItem/StatementItem';
import { IStatementsList } from '@store/features/cardStatements/types';
import {
  HeaderAndFooterMobileTemplate,
  HeaderMobileTemplate,
} from '@templates';
import { dateStringToMonthYearLongFormat } from '@utils/dateHelpers';
import { LoadingScreen } from '@views/SignUp/PaychexLoginView';
import { Dimensions, Platform, Pressable } from 'react-native';
import WebView from 'react-native-webview';
import { customTheme } from '../../../../styles/customTheme';
import DownloadButtonIcon from '../../../../assets/download.svg';
import ShareButtonIcon from '../../../../assets/Share.svg';
import { MobileWebHeader } from '@molecules';
import { ScrollView, View, XStack } from 'tamagui';
import React from 'react';
import TCImage from '../../../../assets/TCupdate.svg';

interface StatementsListViewProps {
  statementsList: IStatementsList[];
  isSpendingStatements: boolean;
  navigateBack: () => void;
  onPdfIconPress: (documentUrl: string, title?: string) => void;
  isStatementWebViewOpened: boolean;
  documentUrl: string;
  onSaveButtonPress: () => void;
  documentTitle: string;
  onShareButtonPress: () => void;
  isSaveLoading: boolean;
}

const StatementsListView = ({
  statementsList,
  isSpendingStatements,
  navigateBack,
  onPdfIconPress,
  isStatementWebViewOpened,
  documentUrl,
  documentTitle,
  onSaveButtonPress,
  onShareButtonPress,
  isSaveLoading,
}: StatementsListViewProps) => {
  const screenWidth = Dimensions.get('window').width;

  return isStatementWebViewOpened ? (
    <HeaderAndFooterMobileTemplate
      header={{
        isBackButton: true,
        onPressBack: navigateBack,
        logo: (
          <XStack width="100%" justifyContent="flex-end" paddingRight="16px">
            <Text
              fontSize={16}
              fontWeight={'600'}
              width="250px"
              textAlign="center"
            >
              {documentTitle}
            </Text>
            <Pressable
              onPress={onShareButtonPress}
              testID="paychex.StatementsListView.button.shareButton"
            >
              <ShareButtonIcon />
            </Pressable>
          </XStack>
        ),
      }}
      footerContent={
        <Button
          isLoading={isSaveLoading}
          leftIcon={
            <DownloadButtonIcon
              width="24px"
              height="24px"
              fill={customTheme.colors.white}
            />
          }
          onPress={onSaveButtonPress}
        >
          Save
        </Button>
      }
    >
      <WebView
        domStorageEnabled={true}
        source={{ uri: documentUrl }}
        startInLoadingState={true}
        renderLoading={() => <LoadingScreen />}
        scalesPageToFit={true}
        javaScriptEnabled={true}
        allowFileAccess={true}
        style={{
          width: screenWidth,
          flex: 1,
        }}
      />
    </HeaderAndFooterMobileTemplate>
  ) : (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}
      <HeaderMobileTemplate
        header={{
          title: `${isSpendingStatements ? 'Spending' : 'Savings'} statements`,
          isBackButton: true,
          onPressBack: navigateBack,
        }}
      >
        <View display={'flex'} flexDirection={'column'} height={'100%'}>
          {statementsList.length > 0 ? (
            <ScrollView
              showsVerticalScrollIndicator={false}
              h={0}
              paddingBottom={8}
            >
              {statementsList.map((statement) => {
                return (
                  <StatementItem
                    title={dateStringToMonthYearLongFormat(
                      statement.start_date
                    )}
                    key={statement.id}
                    statementId={statement.id}
                    onPdfIconPress={onPdfIconPress}
                  />
                );
              })}
            </ScrollView>
          ) : (
            <View flexGrow={1} justifyContent={'center'} alignItems={'center'}>
              <TCImage />
              <Text variant="title" pb={'$2'} mt={'$7'}>
                No statements to display
              </Text>
              <Text variant="subtitle" pb={'$6'}>
                Statements will appear here once they’re available.
              </Text>
            </View>
          )}
        </View>
      </HeaderMobileTemplate>
    </>
  );
};

export default StatementsListView;
