import { ScrollView, Separator, useMedia, View, XStack, YStack } from 'tamagui';
import { DrawerItem } from '@molecules';
import HomeIcon from '../../../assets/webDrawer/home.svg';
import TransferIcon from '../../../assets/bottomTabs/Transfer.svg';
import CashBackIcon from '../../../assets/webDrawer/cashback.svg';
import LogoIcon from '../../../assets/logo.svg';
import React, { useEffect, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useRoute } from '@react-navigation/native';
import { LOGOUT_MENU_ITEM, MORE_SECTION_DESKTOP } from '@constants/menu';
import { DrawerItemWithNavigationProps } from '@devTypes/types';
import {
  MoreNavigatorProps,
  morePageType,
} from '@navigation/MoreNavigator/MoreNavigator.types';
import { bottomTabsPageType } from '@navigation/BottomsTabsNavigator/BottomTabsNavigator.types';
import { sideModalNames } from '@constants/sideModalNames';
import { ACCOUNT_NAMES } from '@constants/accounts';
import useIsTablet from '@hooks/useIsTablet';
import useRewardsWindow from '@hooks/useRewardsWindow';
import useLogoutModal from '@hooks/useLogoutModal';
import { homePageType } from '@navigation/HomeNavigator/HomeNavigator.types';
import useTransfers from '@hooks/useTransfers';
import { useSelector } from 'react-redux';
import { generalActions } from '@store/features/general/slice';
import {
  selectIsNotificationPreferencesUpdated,
  selectIsProfileSettingsUpdated,
  selectWebNavHelpSubsectionIsVisible,
  selectWebNavLegalSubsectionIsVisible,
  selectWebNavMasterCardSubsectionIsVisible,
  selectWebNavTransferSubsectionIsVisible,
} from '@store/features/general/selectors';
import { useAppDispatch } from '@store/hooks';
import useHelp from '@hooks/useHelp';
import useMastercardMenu from '@hooks/useMastercardMenu';
import DotIcon from '../../../assets/webDrawer/dot.svg';
import {
  selectAgreements,
  selectSavingAccountAgreement,
} from '@store/features/agreements/selectors';
import { IAgreement } from '@store/features/agreements/types';
import notificationsThunks from '@store/features/notifications/asyncThunks';
import useIsPhone from '@hooks/useIsPhone';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import { selectSavingsAccountId } from '@store/features/cardholder/selectors';

export interface DrawerItemsListInterface {
  firstSection: DrawerItemWithNavigationProps[];
  secondSection: DrawerItemWithNavigationProps[];
  thirdSection: DrawerItemWithNavigationProps[];
}

interface CustomDrawerInterface {
  onDrawerItemPress?: () => void;
  isFullScreenDrawer?: boolean;
}

const CustomDrawer = ({
  onDrawerItemPress,
  isFullScreenDrawer = false,
}: CustomDrawerInterface) => {
  const dispatch = useAppDispatch();
  const isTablet = useIsTablet();
  const navigation = useNavigation<MoreNavigatorProps['navigation']>();
  const route = useRoute();
  const { sh: isSmallScreen } = useMedia();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const agreements = useSelector(selectAgreements);
  const savingAccountAgreement = useSelector(selectSavingAccountAgreement);

  const webNavTransferSubsectionIsVisible = useSelector(
    selectWebNavTransferSubsectionIsVisible
  );

  const webNavHelpSubsectionIsVisible = useSelector(
    selectWebNavHelpSubsectionIsVisible
  );

  const webNavMasterCardSubsectionIsVisible = useSelector(
    selectWebNavMasterCardSubsectionIsVisible
  );

  const webNavLegalSubsectionIsVisible = useSelector(
    selectWebNavLegalSubsectionIsVisible
  );

  const notificationPreferncesUpdated = useSelector(
    selectIsNotificationPreferencesUpdated
  );

  const profileSettingsUpdated = useSelector(selectIsProfileSettingsUpdated);

  // @ts-ignore
  const { menuItems } = useTransfers({ navigation });

  // @ts-ignore
  const { menuItems: helpMenuItems, screenName: helpMenuScreenName } = useHelp({
    // @ts-ignore
    navigation,
    // @ts-ignore
    route,
  });

  const { menuItems: masterCardMenuItems } = useMastercardMenu({
    // @ts-ignore
    navigation,
  });

  const { handleRewardsButtonPress, isRedirectToRewardsLoading } =
    useRewardsWindow({ navigation });

  const { openLogoutModal } = useLogoutModal();

  const setWebNavTransferSubsectionIsVisible = (value: boolean) => {
    dispatch(generalActions.setWebNavTransferSubsectionIsVisible(value));
  };

  const setWebNavHelpSubsectionIsVisible = (value: boolean) => {
    dispatch(generalActions.setWebNavHelpSubsectionIsVisible(value));
  };

  const setWebNavMasterCardSubsectionIsVisible = (value: boolean) => {
    dispatch(generalActions.setWebNavMasterCardSubsectionIsVisible(value));
  };

  const setWebNavLegalSubsectionIsVisible = (value: boolean) => {
    dispatch(generalActions.setWebNavLegalSubsectionIsVisible(value));
  };

  const legalMenuItemHandler = (agreement: IAgreement) => {
    navigation.navigate('MoreNavigator', {
      screen: 'LegalAgreements',
      params: {
        document_id: agreement.id,
        showSideBar: true,
      },
    });
  };
  const savingsAccountId = useSelector(selectSavingsAccountId);

  const getLegalMenuItems = () => {
    const mergedAgreements = [...agreements];

    if (savingsAccountId) {
      mergedAgreements.push(savingAccountAgreement);
    }

    return mergedAgreements.map((agreement, index) => ({
      Icon: DotIcon,
      pageName: agreement.name,
      label: agreement.name,
      isSubsectionItem: true,
      contentName: agreement.id,
      name: agreement.name,
      handler: () => legalMenuItemHandler(agreement),
      testId: `paychexpay.legalMenu.button.${index}`,
    }));
  };

  const legalMenuItems = getLegalMenuItems();

  const MORE_SECTION_WITH_SUBSECTION = MORE_SECTION_DESKTOP.map((item) => {
    if (item.pageName === 'Help') {
      return { ...item, subsection: helpMenuItems };
    }

    if (item.pageName === 'MastercardFeatures') {
      return { ...item, subsection: masterCardMenuItems };
    }

    if (item.pageName === 'Legal') {
      return { ...item, subsection: legalMenuItems };
    }

    return item;
  });

  // @ts-ignore
  const DrawerItemsList: DrawerItemsListInterface = useMemo(
    () => ({
      firstSection: [
        {
          Icon: HomeIcon,
          label: 'Home',
          pageName: 'HomePage',
          testID: 'paychexpay.customDrawer.button.homeButton',
        },
        {
          Icon: TransferIcon,
          label: 'Transfer',
          pageName: 'Transfers',
          testID: 'paychexpay.customDrawer.button.transferButton',
          subsection: menuItems,
        },
        {
          Icon: CashBackIcon,
          label: 'Cash back',
          pageName: 'CashBack',
          testID: 'paychexpay.customDrawer.button.cashBackButton',
          isLoading: isRedirectToRewardsLoading,
        },
      ],
      secondSection: MORE_SECTION_WITH_SUBSECTION,
      thirdSection: [LOGOUT_MENU_ITEM],
    }),
    [isRedirectToRewardsLoading, MORE_SECTION_WITH_SUBSECTION, menuItems]
  );

  const resetNotificationsAndCloseModal = () => {
    dispatch(generalActions.setWebNotificationConfirmationModalVisible(false));
    dispatch(generalActions.setNotificationPreferencesUpdated(false));
    dispatch(generalActions.setProfileSettingsModalVisible(false));
    dispatch(generalActions.setProfileUpdated(false));
    if (route.name === 'Notifications') {
      dispatch(notificationsThunks.getNotifications())
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
  };

  const debitCardNavigate = () => {
    if (isTablet) {
      navigation.navigate('HomeNavigator', {
        screen: 'DebitCard',
        params: {
          isOpenedAsFullPage: true,
        },
      });
    } else {
      navigation.navigate('HomeNavigator', {
        screen: 'SpendingAccount',
        params: {
          modal: sideModalNames.debitCard,
          accountName: ACCOUNT_NAMES.PaychexPaySpending,
        },
      });
    }
    resetNotificationsAndCloseModal();
  };

  const navigate = (
    pageName: morePageType | bottomTabsPageType | homePageType
  ) => {
    return async () => {
      if (
        onDrawerItemPress &&
        pageName !== 'Transfers' &&
        pageName !== 'Help' &&
        pageName !== 'MastercardFeatures' &&
        pageName !== 'Legal'
      ) {
        if (notificationPreferncesUpdated || profileSettingsUpdated) {
          onDrawerItemPress();
          resetNotificationsAndCloseModal();
        } else {
          onDrawerItemPress();
        }
      }

      if (
        DrawerItemsList.firstSection.find((item) => item.pageName === pageName)
      ) {
        if (pageName === 'CashBack') {
          if (notificationPreferncesUpdated || profileSettingsUpdated) {
            dispatch(
              notificationPreferncesUpdated
                ? generalActions.setWebNotificationConfirmationModalVisible(
                    true
                  )
                : generalActions.setProfileSettingsModalVisible(true)
            );
            dispatch(
              generalActions.setModalSubmitHandler(async () => {
                await handleRewardsButtonPress();
                resetNotificationsAndCloseModal();
              })
            );
          } else {
            await handleRewardsButtonPress();
          }
        } else if (pageName === 'Transfers') {
          setWebNavTransferSubsectionIsVisible(
            !webNavTransferSubsectionIsVisible
          );
        } else {
          if (notificationPreferncesUpdated || profileSettingsUpdated) {
            dispatch(
              notificationPreferncesUpdated
                ? generalActions.setWebNotificationConfirmationModalVisible(
                    true
                  )
                : generalActions.setProfileSettingsModalVisible(true)
            );
            dispatch(
              generalActions.setModalSubmitHandler(() => {
                navigation.navigate('BottomTabsNavigator', {
                  screen: pageName as bottomTabsPageType,
                });
                resetNotificationsAndCloseModal();
              })
            );
          } else {
            navigation.reset({
              index: 0,
              routes: [
                {
                  // @ts-ignore
                  name: 'BottomTabsNavigator',
                  params: {
                    screen: pageName as bottomTabsPageType,
                  },
                },
              ],
            });
          }
        }
      } else {
        if (pageName === 'DebitCard') {
          if (notificationPreferncesUpdated || profileSettingsUpdated) {
            dispatch(
              notificationPreferncesUpdated
                ? generalActions.setWebNotificationConfirmationModalVisible(
                    true
                  )
                : generalActions.setProfileSettingsModalVisible(true)
            );

            dispatch(generalActions.setModalSubmitHandler(debitCardNavigate));
          } else {
            debitCardNavigate();
          }
        } else if (pageName === 'LogOut') {
          if (notificationPreferncesUpdated || profileSettingsUpdated) {
            dispatch(
              notificationPreferncesUpdated
                ? generalActions.setWebNotificationConfirmationModalVisible(
                    true
                  )
                : generalActions.setProfileSettingsModalVisible(true)
            );
            dispatch(
              generalActions.setModalSubmitHandler(() => {
                openLogoutModal();
                resetNotificationsAndCloseModal();
              })
            );
          } else {
            openLogoutModal();
          }
        } else {
          // @ts-ignore
          if (pageName === 'Help') {
            setWebNavHelpSubsectionIsVisible(!webNavHelpSubsectionIsVisible);
          } else if (pageName === 'MastercardFeatures') {
            setWebNavMasterCardSubsectionIsVisible(
              !webNavMasterCardSubsectionIsVisible
            );
          } else if (pageName === 'Legal') {
            setWebNavLegalSubsectionIsVisible(!webNavLegalSubsectionIsVisible);
          } else {
            if (notificationPreferncesUpdated || profileSettingsUpdated) {
              dispatch(
                notificationPreferncesUpdated
                  ? generalActions.setWebNotificationConfirmationModalVisible(
                      true
                    )
                  : generalActions.setProfileSettingsModalVisible(true)
              );
              dispatch(
                generalActions.setModalSubmitHandler(() => {
                  navigation.navigate('MoreNavigator', {
                    screen: pageName as morePageType,
                  });
                  resetNotificationsAndCloseModal();
                })
              );
            } else {
              navigation.navigate('MoreNavigator', {
                screen: pageName as morePageType,
              });
            }
          }
        }
      }

      if (pageName !== 'Transfers' && webNavTransferSubsectionIsVisible) {
        setWebNavTransferSubsectionIsVisible(false);
      }
      if (pageName !== 'Help' && webNavHelpSubsectionIsVisible) {
        setWebNavHelpSubsectionIsVisible(false);
      }
      if (
        pageName !== 'MastercardFeatures' &&
        webNavMasterCardSubsectionIsVisible
      ) {
        setWebNavMasterCardSubsectionIsVisible(false);
      }
      if (pageName !== 'Legal' && webNavLegalSubsectionIsVisible) {
        setWebNavLegalSubsectionIsVisible(false);
      }
    };
  };

  const DrawerItemWithNavigation = ({
    Icon,
    label,
    pageName,
    name = 'undefinedSubsectionName',
    contentName = 'undefinedContentName',
    testID,
    isLoading,
    isSubsectionItem,
    handler,
  }: DrawerItemWithNavigationProps) => {
    let isSelected = false;

    const pageNameLower = pageName.toLowerCase();
    const helpMenuScreenNameLower = helpMenuScreenName?.toLowerCase();

    if (pageName === 'Transfers') {
      if (webNavTransferSubsectionIsVisible) {
        isSelected = true;
      }
    } else if (pageName === 'Help') {
      if (webNavHelpSubsectionIsVisible) {
        isSelected = true;
      }
    } else if (pageName === 'MastercardFeatures') {
      if (webNavMasterCardSubsectionIsVisible) {
        isSelected = true;
      }
    } else if (pageName === 'Legal') {
      if (webNavLegalSubsectionIsVisible) {
        isSelected = true;
      }
    } else if (name === helpMenuScreenName) {
      isSelected = true;
    } else if (pageNameLower === helpMenuScreenNameLower) {
      isSelected = true;
    } else if (route.name.includes(pageName)) {
      isSelected = true;
    } else {
      // @ts-ignore
      if (route.params?.paragraph && route.params?.paragraph === contentName) {
        isSelected = true;
      } else if (route.name === pageName) {
        isSelected = true;
      } else if (
        // @ts-ignore
        route.params?.document_id &&
        // @ts-ignore
        route.params?.document_id === contentName
      ) {
        isSelected = true;
      }
    }

    const onPressSubsection = () => {
      if (
        onDrawerItemPress &&
        pageName !== 'Transfers' &&
        pageName !== 'Help' &&
        pageName !== 'MastercardFeatures' &&
        pageName !== 'Legal'
      )
        onDrawerItemPress();
      handler && handler();
    };

    return (
      <DrawerItem
        Icon={Icon}
        label={label}
        isSelected={isSelected}
        isSubsectionItem={isSubsectionItem}
        showDivider={pageName === 'TransfersHistory'}
        showLinkIcon={pageName === 'CashBack'}
        onPress={
          isSubsectionItem
            ? () => {
                if (notificationPreferncesUpdated || profileSettingsUpdated) {
                  dispatch(
                    notificationPreferncesUpdated
                      ? generalActions.setWebNotificationConfirmationModalVisible(
                          true
                        )
                      : generalActions.setProfileSettingsModalVisible(true)
                  );
                  dispatch(
                    generalActions.setModalSubmitHandler(() => {
                      onPressSubsection();
                      resetNotificationsAndCloseModal();
                    })
                  );
                } else {
                  onPressSubsection();
                }
              }
            : navigate(pageName)
        }
        testID={testID}
        isLoading={isLoading}
      />
    );
  };

  const drawerMap = (
    drawerItem: DrawerItemWithNavigationProps,
    index: number
  ) => {
    if (drawerItem.subsection) {
      return (
        <View key={index}>
          <DrawerItemWithNavigation
            key={index}
            Icon={drawerItem.Icon}
            label={drawerItem.label}
            pageName={drawerItem.pageName}
            name={drawerItem.name}
            contentName={drawerItem.contentName}
            testID={drawerItem.testID}
            isLoading={drawerItem.isLoading}
            isSubsectionItem={false}
          />
          {((drawerItem.pageName === 'Transfers' &&
            webNavTransferSubsectionIsVisible) ||
            (drawerItem.pageName === 'Help' && webNavHelpSubsectionIsVisible) ||
            (drawerItem.pageName === 'MastercardFeatures' &&
              webNavMasterCardSubsectionIsVisible) ||
            (drawerItem.pageName === 'Legal' &&
              webNavLegalSubsectionIsVisible)) && (
            <View
              backgroundColor={'$primaryNormal'}
              borderRadius={8}
              marginTop={1}
            >
              {drawerItem.subsection.map(drawerMap)}
            </View>
          )}
        </View>
      );
    } else {
      if (drawerItem.isDisabled) return null;
      return (
        <DrawerItemWithNavigation
          key={index}
          Icon={drawerItem.Icon}
          label={drawerItem.label}
          pageName={drawerItem.pageName}
          name={drawerItem.name}
          contentName={drawerItem.contentName}
          testID={drawerItem.testID}
          isLoading={drawerItem.isLoading}
          isSubsectionItem={drawerItem.isSubsectionItem}
          handler={drawerItem.handler}
        />
      );
    }
  };

  const isPhone = useIsPhone();

  useEffect(() => {
    !isPhone && resetNotificationsAndCloseModal();
  }, []);

  return (
    <View
      display="flex"
      flexGrow={1}
      height={isFullScreenDrawer ? 0 : '100%'}
      width="100%"
      flexDirection={'column'}
      position={'relative'}
      bg="$primaryDark"
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          paddingTop: isFullScreenDrawer ? 48 : 0,
          paddingBottom: 80,
        }}
      >
        <YStack flexGrow={1} h="100%">
          {!isFullScreenDrawer && (
            <XStack
              pt={isSmallScreen ? '$4' : '$6'}
              pl={'$5'}
              pb={isSmallScreen ? 'none' : '$3'}
            >
              <LogoIcon />
            </XStack>
          )}
          <View
            px={isFullScreenDrawer ? '$2' : '$3'}
            py={isSmallScreen || isFullScreenDrawer ? '$4' : 28}
          >
            {DrawerItemsList.firstSection.map(drawerMap)}
          </View>
          <XStack mx={isFullScreenDrawer ? '$2' : '$3'}>
            <Separator borderColor="$primaryLight" />
          </XStack>
          <View
            px={isFullScreenDrawer ? '$2' : '$3'}
            py={isSmallScreen || isFullScreenDrawer ? '$4' : 28}
          >
            {DrawerItemsList.secondSection.map(drawerMap)}
          </View>
        </YStack>
      </ScrollView>
      <View
        flexGrow={1}
        position={'absolute'}
        bottom={0}
        left={0}
        right={0}
        bg="$primaryDark"
      >
        <XStack mx={isFullScreenDrawer ? '$2' : '$3'}>
          <Separator borderColor="$primaryLight" />
        </XStack>
        <XStack
          flexGrow={1}
          w="100%"
          py={'$2'}
          px={isFullScreenDrawer ? '$2' : '$4'}
        >
          {DrawerItemsList.thirdSection.map(drawerMap)}
        </XStack>
      </View>
    </View>
  );
};

export default CustomDrawer;
