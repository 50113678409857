import { CashBackView } from '@views/BottomTabs';
import { CashBackProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { Platform } from 'react-native';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';
import { NativeModules } from 'react-native';
import { selectCardholder } from '@store/features/cardholder/selectors';
import Toast from 'react-native-toast-message';
import { TOAST_POPUP_VISIBILITY_TIME } from '@constants/general';
import Config from 'react-native-config';
const { RewardsModule } = NativeModules;

const CashBackPage = ({ navigation }: CashBackProps) => {
  const navigateBack = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'BottomTabsNavigator',
          params: {
            screen: 'HomePage',
          },
        },
      ],
    });
  };

  const cardholder = useSelector(selectCardholder);

  const isFocused = useIsFocused();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (isFocused) {
      const cardholder_id = cardholder.cardholder_id;
      const userName = `${cardholder.first_name} ${cardholder.last_name}`;

      if (Platform.OS === 'android') {
        RewardsModule.openRewardsModule(
          Config.DOSH_ORIGIN,
          cardholder_id,
          'Cash back',
          userName,
          null,
          () => {
            navigateBack();
          },

          (error: any) => {
            Toast.show({
              type: 'error',
              text1: 'Rewards fails:',
              text2: error,
              visibilityTime: TOAST_POPUP_VISIBILITY_TIME * 2,
            });

            navigateBack();
          }
        );
      } else {
        RewardsModule.openRewardsModule(
          Config.DOSH_ORIGIN,
          cardholder_id,
          'Cash back',
          userName,
          () => {
            //TODO: success callback executes straight away
            // Improve it to be executed only after rewards opening (or run navigateBack(); on view unmount on iOS native side)

            timeout = setTimeout(() => {
              navigateBack();
            }, 10000);
          },

          (error: any) => {
            Toast.show({
              type: 'error',
              text1: 'Rewards fails:',
              text2: error,
              visibilityTime: TOAST_POPUP_VISIBILITY_TIME * 2,
            });

            navigateBack();
          }
        );
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFocused]);

  return <CashBackView navigateBack={navigateBack} />;
};

export default CashBackPage;
