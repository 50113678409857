import Lottie from 'react-lottie';
import { LoaderProps } from '@atoms/Loader/Loader.types';

const defaultOptions = (isPrimary: boolean) => ({
  loop: true,
  autoplay: true,
  animationData: isPrimary
    ? require('../../../assets/lotties/loader.json')
    : require('../../../assets/lotties/whiteLoader.json'),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});
const Loader = ({
  width = 92,
  height = 97,
  variant = 'primary',
}: LoaderProps) => {
  return (
    // @ts-ignore
    <Lottie
      options={defaultOptions(variant === 'primary')}
      width={width}
      height={height}
    />
  );
};

export default Loader;
