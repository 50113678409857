import { Spinner, View, XStack } from 'tamagui';
import { customTheme } from '../../../styles/customTheme';
import { DrawerItemProps } from '@molecules/DrawerItem/DrawerItem.types';
import { Line, TPressable, Text } from '@atoms';
import { accessibleHitSlop } from '@constants/ui';
import { Platform } from 'react-native';
import LinkIcon from '../../../assets/atm/linkIcon.svg';
import React from 'react';

const DrawerItem = ({
  label,
  Icon,
  isSelected,
  onPress,
  testID,
  isLoading,
  isSubsectionItem,
  showDivider,
  showLinkIcon = false,
  ...rest
}: DrawerItemProps) => {
  let bgColor = isSelected
    ? Platform.OS === 'web'
      ? '#1C85CE'
      : '$primaryNormal'
    : '$primaryDark';

  if (isSubsectionItem) {
    if (isSelected) {
      bgColor = '#1C85CE';
    } else {
      bgColor = '$primaryNormal';
    }
  }

  return (
    <View py={1} w="100%">
      {showDivider && <Line color={'#1C85CE'} />}
      <TPressable
        w="100%"
        borderRadius="$1"
        hoverStyle={{
          bg: isSubsectionItem ? '#1C85CE' : '$primaryNormal',
        }}
        bg={bgColor}
        onPress={onPress}
        testID={testID}
        cursor={'pointer'}
        justifyContent={'space-between'}
        accessible
        accessibilityLabel={`Navigate to ${label}`}
        {...rest}
      >
        <XStack alignItems="center" py={10} pl={isSubsectionItem ? 8 : 12}>
          <Icon
            fill={isSelected ? 'white' : customTheme.colors.gray['20']}
            hitSlop={accessibleHitSlop}
          />
          <Text
            pl={isSubsectionItem ? '4px' : 12}
            fontSize="$h4"
            color={isSelected ? '$white' : '$blue10'}
          >
            {label}
          </Text>
          {isLoading && (
            <View
              ml="$4"
              display={'flex'}
              flexDirection={'row'}
              flex={1}
              paddingRight={13}
              justifyContent={'flex-end'}
            >
              <Spinner color="$white" />
            </View>
          )}
          {showLinkIcon && (
            <View
              display={'flex'}
              flexDirection={'row'}
              flex={1}
              paddingRight={13}
              justifyContent={'flex-end'}
            >
              <LinkIcon width="14px" height="14px" color={'white'} />
            </View>
          )}
        </XStack>
      </TPressable>
    </View>
  );
};

export default DrawerItem;
