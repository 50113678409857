import { Button as NButton, IButtonProps } from 'native-base';
import { customTheme } from '../../../styles/customTheme';

const variants = { ...customTheme.components.Button.variants } as const;
export type buttonVariantsType = keyof typeof variants;

interface ButtonProps extends Exclude<IButtonProps, 'variant'> {
  variant?: buttonVariantsType;
}

const Button = (props: ButtonProps) => {
  return <NButton {...props} />;
};

export default Button;
