import { ZeroLiabilityProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { ZeroLiabilityView } from '@views/MastercardFeatures';
import mastercardFeatures, {
  mastercardFeaturesNames,
} from '@constants/mastercardFeatures';
import { Platform } from 'react-native';

const ZeroLiabilityPage = ({ navigation }: ZeroLiabilityProps) => {
  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };
  return (
    <ZeroLiabilityView
      navigateBack={navigateBack}
      content={mastercardFeatures[mastercardFeaturesNames.zeroLiability]}
    />
  );
};

export default ZeroLiabilityPage;
