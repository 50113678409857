const maxMediaQuery = {
  mobile: 480,
  tablet: 768,
  laptop: 1024,
  smallDesktop: 1512,
};

const mobileIllustrationDimensions = {
  width: 295,
  height: 240,
};

const desktopIllustrationDimensions = {
  width: 220,
  height: 180,
  viewBox: `0 0 ${mobileIllustrationDimensions.width} ${mobileIllustrationDimensions.height}`,
};

const accessibleHitSlop = { top: 20, bottom: 20, left: 20, right: 20 };

export {
  accessibleHitSlop,
  maxMediaQuery,
  mobileIllustrationDimensions,
  desktopIllustrationDimensions,
};
