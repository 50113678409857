import { PayBillsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import PayBillsView from '@views/Widgets/PayBillsView';
import { Platform } from 'react-native';

const PayBillsPage = ({ navigation, route }: PayBillsProps) => {
  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };

  return (
    <MediaLayoutTemplate
      Mobile={PayBillsView.mobile}
      Desktop={PayBillsView.desktop}
      payBillsUrl={route.params.payBillsUrl}
      navigateBack={navigateBack}
    />
  );
};

export default PayBillsPage;
