import { Dimensions, Platform } from 'react-native';

export const getWindowHeightWeb = () => {
  if (Platform.OS !== 'web') {
    return Dimensions.get('window').height;
  }
  // Get zoom level of mobile Safari
  // Note, that such zoom detection might not work correctly in other browsers
  // We use width, instead of height, because there are no vertical toolbars :)
  const zoomLevel = document.documentElement.clientWidth / window.innerWidth;

  // window.innerHeight returns height of the visible area.
  // We multiply it by zoom and get out real height.
  return window.innerHeight * zoomLevel;
};

export const getHeightOfToolbarsWeb = () => {
  if (Platform.OS !== 'web') {
    return 0;
  }

  const toolbarHeight =
    (screen.orientation?.type.includes('portrait')
      ? screen.height
      : screen.width) - getWindowHeightWeb();

  return toolbarHeight > 1 ? toolbarHeight : 0;
};
