import { DigitalWalletPageProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { DigitalWalletView } from '@views/More';
import { Linking, Platform } from 'react-native';

const DigitalWalletPage = ({ navigation }: DigitalWalletPageProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };

  const openDigitalWallet = async () => {
    const appleWalletLink = 'wallet://';
    const googleWalletLink = 'https://www.android.com/payapp/';
    const link = Platform.OS === 'ios' ? appleWalletLink : googleWalletLink;

    await Linking.openURL(link); //Do not check if link is supported using Linking.canOpenURL(), because it returns false on Android (but actually it works)
  };

  return (
    <DigitalWalletView
      navigateBack={navigateBack}
      openDigitalWallet={openDigitalWallet}
    />
  );
};

export default DigitalWalletPage;
