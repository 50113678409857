import { ToOtherFriendsWebProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import {
  isValidBeneficiaryInfoFn,
  isValidBeneficiaryInfoLn,
  isValidBeneficiaryInfoEmail,
  isValidBeneficiaryInfoPhone,
} from '@utils/isValidBeneficiaryInfo';
import useBeneficiaryInput from '@hooks/useBeneficiaryInput';
import { useSelector } from 'react-redux';
import { selectSpendingAccountPreviewInfo } from '@store/features/cards/selectors';
import ToOtherFriendsWebView from '@views/Transfers/ToOtherFriendsWebView';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MAX_TRANSFER_COMMENT_LENGTH } from '@constants/general';
import { useAppDispatch } from '@store/hooks';
import { selectTransferStatus } from '@store/features/transfers/selectors';
import { transfersActions } from '@store/features/transfers/slice';
import cardsThunks from '@store/features/cards/asyncThunks';
import transfersThunks from '@store/features/transfers/asyncThunks';
import { P2P_TRANSFER_OPTIONS } from '@constants/transfersScreensNames';
import transactionThunks from '@store/features/transactions/asyncThunks';
import amountCommaCleaner from '@utils/amountCommaCleaner';
import { selectVariableTransferFee } from '@store/features/general/selectors';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';

const ToOtherFriendsWebPage = ({
  navigation,
  route,
}: ToOtherFriendsWebProps) => {
  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const spendingAccountId = useSelector(selectSpendingAccountId);

  const dispatch = useAppDispatch();
  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };
  const transferStatus = useSelector(selectTransferStatus);
  const [isCenterModalOpen, setIsCenterModalOpen] = useState(false);
  const onOpenCenterModal = () => {
    setIsCenterModalOpen(true);
  };
  const onCloseCenterModal = () => {
    setIsCenterModalOpen(false);
    dispatch(
      transactionThunks.getSpendingTransactions({
        cardId: spendingAccount.id,
        forceRefresh: true,
      })
    );
    navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
  };

  const [comment, setComment] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedMethod, setSelectedMethod] = useState<string>(
    P2P_TRANSFER_OPTIONS[0].value
  );

  const {
    beneficiaryInfoP2P: { benEmail, benFirstName, benLastName, benPhone },
    isErrorInputEmail,
    isErrorInputFn,
    isErrorInputLn,
    isErrorInputPhone,
    onChangebenFirstName,
    onChangebenLastName,
    onChangebenEmail,
    onChangebenPhone,
    setIsErrorInputEmail,
    setIsErrorInputPhone,
    setIsErrorInputFn,
    setIsErrorInputLn,
  } = useBeneficiaryInput({ transferOption: selectedMethod });

  const clearTransferData = useCallback(() => {
    setAmount('');
    setComment('');
  }, []);

  const isNotExceedBalance = useMemo(() => {
    return (
      spendingAccount.amount <
      (isNaN(parseFloat(amountCommaCleaner(amount)))
        ? 0
        : parseFloat(amountCommaCleaner(amount)))
    );
  }, [amount, route, spendingAccount]);

  const isButtonDisabled = useMemo(
    () =>
      comment.length > MAX_TRANSFER_COMMENT_LENGTH ||
      isNotExceedBalance ||
      isErrorInputFn ||
      isErrorInputLn ||
      (isNaN(parseFloat(amountCommaCleaner(amount)))
        ? 0
        : parseFloat(amountCommaCleaner(amount))) === 0,
    [amount, isNotExceedBalance, comment, isErrorInputFn, isErrorInputLn]
  );

  useEffect(() => {
    if (transferStatus === 'success' || transferStatus === 'error') {
      navigation.setParams({ isCompleted: transferStatus === 'success' });
      clearTransferData();
      onOpenCenterModal();
      dispatch(transfersActions.resetStatus());
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccount.id }))
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
  }, [transferStatus]);

  useEffect(() => {
    if (spendingAccountId)
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccountId }))
        .unwrap()
        .catch((err) => handleRequestError(err));
  }, [spendingAccountId]);

  const onPressTransfer = () => {
    if (
      (selectedMethod === 'Email address'
        ? isValidBeneficiaryInfoEmail(benEmail)
        : isValidBeneficiaryInfoPhone(benPhone)) &&
      isValidBeneficiaryInfoFn(benFirstName) &&
      isValidBeneficiaryInfoLn(benLastName)
    ) {
      dispatch(
        transfersThunks.postNetworkTransfer({
          fromAccountId: spendingAccount.id,
          amount: parseFloat(amountCommaCleaner(amount)),
          firstName: benFirstName.trim(),
          lastName: benLastName.trim(),
          description: comment,
          email: benEmail,
          mobile: benPhone,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
    } else {
      setIsErrorInputEmail(!isValidBeneficiaryInfoEmail(benEmail));
      setIsErrorInputFn(!isValidBeneficiaryInfoFn(benFirstName));
      setIsErrorInputPhone(!isValidBeneficiaryInfoPhone(benPhone));
      setIsErrorInputLn(!isValidBeneficiaryInfoLn(benLastName));
    }
  };

  const onRadioButtonSelect = (option: string) => {
    if (option === 'Phone number') {
      setIsErrorInputPhone(false);
    } else {
      setIsErrorInputEmail(false);
    }
    onChangebenEmail('');
    onChangebenPhone('');
    setSelectedMethod(option);
  };

  const [isInsufficientFundsAlertVisible, setIsInsufficientFundsAlertVisible] =
    useState(false);

  useEffect(() => {
    if (spendingAccount.amount === 0 && spendingAccount.id) {
      setIsInsufficientFundsAlertVisible(true);
    }
  }, [spendingAccount, spendingAccount.id]);

  const variableTransferFee = useSelector(selectVariableTransferFee);

  const hideInsufficientFundsAlertModal = () => {
    setIsInsufficientFundsAlertVisible(false);
    navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
  };

  return (
    <ToOtherFriendsWebView
      onPressTransfer={onPressTransfer}
      amount={amount}
      comment={comment}
      setAmount={setAmount}
      setComment={setComment}
      transferStatus={transferStatus}
      benInfo={{
        benEmail,
        benFirstName,
        benLastName,
        benPhone,
      }}
      variableTransferFee={variableTransferFee}
      onChangeBeneficiaryInfoFn={onChangebenFirstName}
      onChangeBeneficiaryInfoLn={onChangebenLastName}
      onChangeBeneficiaryInfoPhone={onChangebenPhone}
      onChangeBenInfoEmail={onChangebenEmail}
      account={spendingAccount}
      isErrorInputLn={isErrorInputLn}
      isErrorInputFn={isErrorInputFn}
      isErrorInputEmail={isErrorInputEmail}
      isErrorInputPhone={isErrorInputPhone}
      isNotExceedBalance={isNotExceedBalance}
      isButtonDisabled={isButtonDisabled}
      isCenterModalOpen={isCenterModalOpen}
      onCloseCenterModal={onCloseCenterModal}
      isCompleted={!!route.params?.isCompleted}
      onRadioButtonSelect={onRadioButtonSelect}
      selectedMethod={selectedMethod}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    />
  );
};

export default ToOtherFriendsWebPage;
