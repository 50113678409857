import { HeaderAndFooterMobileTemplate, WebLogoTemplate } from '@templates';
import { View, ScrollView } from 'tamagui';
import { ImageWithContent } from '@molecules';
import React from 'react';
import { Dimensions, Platform } from 'react-native';
import { Button, Line, TButton, Text } from '@atoms';
import PatriotActImage from '../../../assets/onbordingIllustrations/PatriotAct.svg';
import useIsTablet from '@hooks/useIsTablet';
import { PATRIOT_ACT_STATEMENT } from '@constants/policies';
interface UsaPatriotActViewProps {
  onPressContinue: () => void;
}

const mobile = ({ onPressContinue }: UsaPatriotActViewProps) => {
  const isTablet = useIsTablet();

  return (
    <>
      <HeaderAndFooterMobileTemplate
        header={{
          title: 'USA PATRIOT Act Notice',
          isBackButton: false,
        }}
        footerContent={
          <TButton
            onPress={onPressContinue}
            testID="paychexpay.mobile.UsaPatriotActView.button.continueButton"
          >
            Continue
          </TButton>
        }
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          h={Platform.OS === 'web' ? 0 : '100%'}
        >
          <>
            <View paddingTop={40} px={isTablet ? '$4' : '$7'} pb={'$6'}>
              <ImageWithContent
                image={<PatriotActImage />}
                space={isTablet ? 40 : 86}
                marginHorizontal={16}
              >
                <Text
                  fontSize={'$h4'}
                  color={'$black'}
                  fontWeight={'500'}
                  alignSelf={'flex-start'}
                >
                  USA PATRIOT Act Notice
                </Text>

                <Line flex={1} alignSelf={'center'} marginVertical={8} />

                <Text
                  fontSize="$h4"
                  color="$black"
                  fontWeight="500"
                  marginBottom={8}
                  alignSelf={'flex-start'}
                  accessible
                  accessibilityLabel="IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT:"
                >
                  IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW
                  ACCOUNT:
                </Text>
                <Text
                  variant="subtitle"
                  alignSelf={'flex-start'}
                  accessible
                  accessibilityLabel={`${PATRIOT_ACT_STATEMENT}`}
                >
                  {PATRIOT_ACT_STATEMENT}
                </Text>
              </ImageWithContent>
            </View>
          </>
        </ScrollView>
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

const desktop = ({ onPressContinue }: UsaPatriotActViewProps) => {
  const screenHeight = Dimensions.get('window').height;
  return (
    <WebLogoTemplate>
      <View w="325px">
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            height: screenHeight,
            justifyContent: 'center',
          }}
        >
          <>
            <View pt={40} px={7} pb={6}>
              <ImageWithContent
                image={<PatriotActImage />}
                space={86}
                marginHorizontal={16}
              >
                <Text
                  fontSize={'$h4'}
                  color={'$black'}
                  fontWeight={'500'}
                  alignSelf={'flex-start'}
                >
                  USA PATRIOT Act Notice
                </Text>

                <Line flex={1} alignSelf={'center'} marginVertical={8} />

                <Text
                  fontSize="$h4"
                  color="$black"
                  fontWeight="500"
                  marginBottom={8}
                  alignSelf={'flex-start'}
                  accessible
                  accessibilityLabel="IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT:"
                >
                  IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW
                  ACCOUNT:
                </Text>
                <Text
                  variant="subtitle"
                  alignSelf={'flex-start'}
                  accessible
                  accessibilityLabel={`${PATRIOT_ACT_STATEMENT}`}
                >
                  {PATRIOT_ACT_STATEMENT}
                </Text>
              </ImageWithContent>
            </View>
          </>
          <Button
            onPress={onPressContinue}
            my={6}
            testID="paychexpay.desktop.UsaPatriotActView.button.continueButton"
          >
            Continue
          </Button>
        </ScrollView>
      </View>
    </WebLogoTemplate>
  );
};

const UsaPatriotActView = {
  mobile,
  desktop,
};

export default UsaPatriotActView;
