import React, { useRef, useState } from 'react';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { Center } from 'native-base';
import { TButton, Text, TransferAmountInput } from '@atoms';
import amountToString from '@utils/amountToString';
import { formatTransferInput } from '@utils/validateDdaInputs';
import { DEFAULT_AMOUNT_INPUT_LENGTH } from '@constants/transfersScreensNames';
import { MobileWebHeader, TextInput } from '@molecules';
import { Platform } from 'react-native';
import { IAccountPreview } from '@devTypes/accountTypes';
import { IExternalAccountPreview } from '@store/features/externalBankAccounts/types';
import { XStack, YStack } from 'tamagui';

interface TransferAmountViewProps {
  navigateBack: () => void;
  onPressTransfer: () => void;
  setComment: (comment: string) => void;
  setAmount: (amount: string) => void;
  maxCommentLength: number;
  fee: string;
  comment: string;
  amount: string;
  toAccountName: string;
  fromAccountAmount: number;
  isNotExceedBalance: boolean;
  isButtonDisabled: boolean;
  isLoadingTransferButton: boolean;
  isFromExternalAccount: boolean;
  toAccount: IAccountPreview | IExternalAccountPreview;
}

const TransferAmountView = ({
  navigateBack,
  setComment,
  setAmount,
  fee,
  comment,
  maxCommentLength,
  amount,
  toAccountName,
  fromAccountAmount,
  isNotExceedBalance,
  onPressTransfer,
  isButtonDisabled,
  isLoadingTransferButton,
  isFromExternalAccount,
  toAccount,
}: TransferAmountViewProps) => {
  const [calculatedMaxLength, setMaxLength] = useState(
    DEFAULT_AMOUNT_INPUT_LENGTH
  );

  const setInputAmountAndLength = async (validatedValue: string) => {
    const dotIndex = validatedValue.indexOf('.');
    if (dotIndex !== -1) {
      setMaxLength(dotIndex + 3);
    } else {
      setMaxLength(DEFAULT_AMOUNT_INPUT_LENGTH);
    }
    await setAmount(validatedValue);
  };

  const commentInputRef = useRef(null);

  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderAndFooterMobileTemplate
        footerContent={
          <YStack gap="$5">
            <TextInput
              label="Note"
              styles={{ height: 80, gap: 6 }}
              isError={comment.length > maxCommentLength}
              errorMessage={`Should contain no more than ${maxCommentLength} digits`}
              args={{
                ref: commentInputRef,
                value: comment,
                onChangeText: (text: string) => setComment(text),
                placeholder: 'What’s this for?',
                onSubmitEditing: () => {
                  if (!isButtonDisabled) {
                    onPressTransfer();
                  }
                },
              }}
            />
            <TButton
              onPress={onPressTransfer}
              disabled={isButtonDisabled}
              isLoading={isLoadingTransferButton}
              testID="paychexpay.transferAmount.transferButton"
            >
              Transfer
            </TButton>
          </YStack>
        }
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title:
            'To ' +
            `${
              toAccount?.isExternal
                ? toAccountName +
                  ' ' +
                  (toAccount.type === 'spending' ? 'checking' : 'savings')
                : toAccountName.includes('Savings')
                ? 'Savings'
                : toAccountName.includes('Spending')
                ? 'Spending'
                : toAccountName
            }`,
        }}
      >
        {isNotExceedBalance && fromAccountAmount > 0 && (
          <Center
            bgColor="red.10"
            py="10px"
            width="100%"
            borderColor="red.20"
            borderTopWidth={1}
            borderBottomWidth={1}
            testID={'paychexPay.transferAmount.exceedBalanceText'}
          >
            <Text color="$red50" fontSize="$h5">
              You can transfer up to ${fromAccountAmount}
            </Text>
          </Center>
        )}
        <Center flexGrow={1} backgroundColor={'blue.10'}>
          <YStack
            justifyContent="space-between"
            height={isFromExternalAccount ? 90 : 120}
            width="100%"
            alignItems="center"
          >
            {!isFromExternalAccount && (
              <Text variant="subtitle">
                Available to transfer: {' ' + amountToString(fromAccountAmount)}
              </Text>
            )}
            <XStack
              alignItems="center"
              justifyContent="center"
              backgroundColor={'$blue10'}
            >
              <TransferAmountInput
                value={amount}
                onChange={(value: string) => {
                  const validatedValue = formatTransferInput(value);
                  setInputAmountAndLength(validatedValue);
                }}
                maxLength={calculatedMaxLength}
                onSubmitEditing={() => {
                  // @ts-ignore
                  commentInputRef.current?.focus();
                }}
              />
            </XStack>
            <Text fontSize="$h4" color={fee === 'No fee' ? '$gray40' : 'black'}>
              {fee}
            </Text>
          </YStack>
        </Center>
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

export default TransferAmountView;
