import { View } from 'tamagui';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { BIOMETRY_TYPE } from 'react-native-keychain';
import { FooterMobileTemplate } from '@templates';
import { FooterButtonsRow, IconWithDescription } from '@molecules';
import { getBiometryIcon, getBiometryName } from '@utils/biometricsHelper';
interface commonPropsInterface {
  enableHandler: () => void;
  notNowHandler: () => void;
  biometryType: BIOMETRY_TYPE | null | '';
}

interface mobilePropsInterface {
  commonProps: commonPropsInterface;
}

interface enableBiometricsPropsInterface {
  biometryType: BIOMETRY_TYPE | null | '';
}

const EnableBiometrics = ({ biometryType }: enableBiometricsPropsInterface) => {
  const BiometryIcon = getBiometryIcon(biometryType);
  const biometryName = getBiometryName(biometryType);

  return (
    <IconWithDescription
      Icon={BiometryIcon}
      description={'Well only use this to make \n logging in easier.'}
      title={`Allow ${biometryName || ''}?`} //Allow Face ID?
    />
  );
};

const mobile = ({ commonProps }: mobilePropsInterface) => {
  const { top: topOffset } = useSafeAreaInsets();

  return (
    <FooterMobileTemplate.default
      FooterContent={
        <FooterButtonsRow
          firstButton={{
            label: 'Not now',
            handler: commonProps.notNowHandler,
          }}
          secondButton={{
            label: 'Allow',
            handler: commonProps.enableHandler,
          }}
          isLoading={false}
        />
      }
    >
      <View backgroundColor="white" flexGrow={1} paddingTop={topOffset}>
        <View paddingTop={72} flexGrow={1} alignItems="center">
          <EnableBiometrics biometryType={commonProps.biometryType} />
        </View>
      </View>
    </FooterMobileTemplate.default>
  );
};

const BiometricsView = {
  mobile,
};

export default BiometricsView;
