import { HeaderAndFooterMobileTemplate, WebLogoTemplate } from '@templates';
import { Center } from 'native-base';
import { FooterButtonsRow, IconWithDescription } from '@molecules';
import VerificationLocationIcon from '../../../assets/verificationLocationIllustration.svg';
import { RadioGroup, View, YStack } from 'tamagui';
import RadioGroupItemWithLabel from '@molecules/RadioButtonWithLabel/RadioButtonWithLabel';
import { Text as RNText } from 'react-native';

interface AddressVerificationViewProps {
  onCancelPress: () => void;
  onSubmitPress: () => void;
  onRadioButtonSelect: (optionId: string) => void;
  radioButtonOptions: {
    label: string;
    value: string | number;
    additionalInfoLabel?: string;
  }[];
  value: string;
}

const mobile = ({
  onCancelPress,
  onSubmitPress,
  onRadioButtonSelect,
  radioButtonOptions,
  value,
}: AddressVerificationViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      footerContent={
        <FooterButtonsRow
          firstButton={{
            label: 'Go back',
            handler: onCancelPress,
            testID:
              'paychexpay.addressVerificationView.footerButtonsRow.button.cancelButton',
          }}
          secondButton={{
            label: 'Confirm',
            handler: onSubmitPress,
            testID:
              'paychexpay.addressVerificationView.footerButtonsRow.button.submitButton',
          }}
        />
      }
    >
      <Center px="16px">
        <IconWithDescription
          Icon={VerificationLocationIcon}
          description="Verified address found. Select which one you’d like to use."
          title="Confirm your address"
          titlePaddingTop="2"
        />
        <View marginVertical={32} width={'100%'}>
          <RadioGroup
            onValueChange={(text) => {
              onRadioButtonSelect(text);
            }}
            value={value}
            accessibilityLabel={`options for ${radioButtonOptions[0].label}`}
            name="form"
          >
            <YStack alignItems="flex-start">
              {radioButtonOptions.map((option, index) => (
                <View
                  key={index}
                  paddingVertical={'$2'}
                  backgroundColor={value === option.value ? '#F6F7F9' : 'white'}
                  {...(option.additionalInfoLabel && {
                    borderRadius: 8,
                    borderColor: '$gray20',
                    borderWidth: 1,
                    width: '100%',
                    padding: 16,
                    wordBreak: 'break-word',
                  })}
                  {...(option.additionalInfoLabel &&
                    index !== radioButtonOptions.length - 1 && {
                      marginBottom: 16,
                    })}
                >
                  <RadioGroupItemWithLabel
                    size="$7"
                    label={option.label}
                    titleStyles={{ fontSize: '$h4', fontWeight: '500' }}
                    //@ts-ignore
                    value={option.value}
                    testID={`paychexpay.mobile.AddressVerificationView.radioButton.${option.label}`}
                  />
                  {option.additionalInfoLabel && (
                    <RNText
                      style={{
                        marginLeft: 36,
                        fontSize: 14,
                        fontWeight: '500',
                        lineHeight: 20,
                        fontFamily: 'Inter-Regular, sans-serif',
                      }}
                    >
                      {option.additionalInfoLabel}
                    </RNText>
                  )}
                </View>
              ))}
            </YStack>
          </RadioGroup>
        </View>
      </Center>
    </HeaderAndFooterMobileTemplate>
  );
};

const desktop = ({
  onCancelPress,
  onSubmitPress,
  onRadioButtonSelect,
  radioButtonOptions,
  value,
}: AddressVerificationViewProps) => {
  return (
    <WebLogoTemplate>
      <Center width={325}>
        <IconWithDescription
          Icon={VerificationLocationIcon}
          description="Verified address found. Select which one you’d like to use."
          title="Confirm your address"
          titlePaddingTop="2"
        />
        <View marginVertical={32}>
          <RadioGroup
            onValueChange={(text) => {
              onRadioButtonSelect(text);
            }}
            value={value}
            accessibilityLabel={`options for ${radioButtonOptions[0].label}`}
            name="form"
          >
            <YStack alignItems="flex-start">
              {radioButtonOptions.map((option, index) => (
                <View
                  key={index}
                  paddingVertical={'$2'}
                  backgroundColor={value === option.value ? '#F6F7F9' : 'white'}
                  {...(option.additionalInfoLabel && {
                    borderRadius: 8,
                    borderColor: '$gray20',
                    borderWidth: 1,
                    width: '100%',
                    padding: 16,
                    wordBreak: 'break-word',
                  })}
                  {...(option.additionalInfoLabel &&
                    index !== radioButtonOptions.length - 1 && {
                      marginBottom: 16,
                    })}
                >
                  <RadioGroupItemWithLabel
                    size="$7"
                    label={option.label}
                    titleStyles={{ fontSize: '$h4', fontWeight: '500' }}
                    //@ts-ignore
                    value={option.value}
                    testID={`paychexpay.mobile.AddressVerificationView.radioButton.${option.label}`}
                  />
                  {option.additionalInfoLabel && (
                    <RNText
                      style={{
                        marginLeft: 36,
                        fontSize: 14,
                        fontWeight: '500',
                        lineHeight: 20,
                        fontFamily: 'Inter-Regular, sans-serif',
                      }}
                    >
                      {option.additionalInfoLabel}
                    </RNText>
                  )}
                </View>
              ))}
            </YStack>
          </RadioGroup>
        </View>
        <View width="100%">
          <FooterButtonsRow
            firstButton={{
              label: 'Go back',
              handler: onCancelPress,
              testID:
                'paychexpay.addressVerificationView.footerButtonsRow.button.cancelButton',
            }}
            secondButton={{
              label: 'Confirm',
              handler: onSubmitPress,
              testID:
                'paychexpay.addressVerificationView.footerButtonsRow.button.submitButton',
            }}
          />
        </View>
      </Center>
    </WebLogoTemplate>
  );
};

const AddressVerificationView = {
  mobile,
  desktop,
};

export default AddressVerificationView;
