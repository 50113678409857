import { TransfersHistoryProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { TransfersHistoryView } from '@views/Transfers';
import { useSelector } from 'react-redux';
import {
  selectAllTransfers,
  selectFilteredBy,
  selectIsLoadingSavingsTransactions,
  selectIsLoadingSpendingTransactions,
  selectSavingsAllTransactionsFiltered,
  selectSearchText,
  selectSpendingAllTransactionsFiltered,
} from '@store/features/transactions/selectors';
import { useEffect, useState } from 'react';
import { TransactionsOptionsType } from '@organisms/TransactionsFilter/TransactionsFilter';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import useIsPhone from '@hooks/useIsPhone';
import { Platform } from 'react-native';
import { selectAccountIdsToNames } from '@store/features/cards/selectors';
import { useAppDispatch } from '@store/hooks';
import cardsThunks from '@store/features/cards/asyncThunks';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';

const TransfersHistoryPage = ({ navigation, route }: TransfersHistoryProps) => {
  const isPhone = useIsPhone();
  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };

  const {
    isModalOpen,
    modalName,
    toOpenModal,
    toCloseModal,
    transactionId,
    toSetTransactionId,
  } = useSideModal({
    route,
    navigation,
  });
  const dispatch = useAppDispatch();
  const allTransfers = useSelector(selectAllTransfers);
  const spendingAccount = useSelector(selectSpendingAccountId);
  const savingsAccount = useSelector(selectSavingsAccountId);
  const filteredSpendingTransactions = useSelector(
    selectSpendingAllTransactionsFiltered
  );
  const filteredSavingsTransactions = useSelector(
    selectSavingsAllTransactionsFiltered
  );
  const transactionsSearchText = useSelector(selectSearchText);
  const transactionsFilteredBy = useSelector(selectFilteredBy);
  const isLoadingSpending = useSelector(selectIsLoadingSpendingTransactions);
  const isLoadingSavings = useSelector(selectIsLoadingSavingsTransactions);

  const [transfersSelect, setTransferSelect] =
    useState<TransactionsOptionsType>('all');

  const onOpenTransactionModal = (id: string) => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'TransactionDetails',
        params: { transactionId: id },
      });
    } else {
      toSetTransactionId(id);
      toOpenModal(sideModalNames.transaction)();
    }
  };

  const [transactions, setTransactions] = useState(allTransfers);

  useEffect(() => {
    if (transfersSelect !== 'all') {
      const mergedFilteredTransfers = [
        ...filteredSpendingTransactions,
        ...filteredSavingsTransactions,
      ];

      const sortedByDateFilteredTransfers = mergedFilteredTransfers.sort(
        (a, b) =>
          new Date(b.transaction_datetime).getTime() -
          new Date(a.transaction_datetime).getTime()
      );

      setTransactions(sortedByDateFilteredTransfers);
    }
    setTransactions(allTransfers);
  }, [
    transfersSelect,
    filteredSavingsTransactions,
    filteredSpendingTransactions,
    allTransfers,
  ]);

  const accountIdsToNames = useSelector(selectAccountIdsToNames);

  useEffect(() => {
    if (Object.keys(accountIdsToNames)[0] === 'undefined') {
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccount }));

      if (savingsAccount) {
        dispatch(cardsThunks.getCardInfo({ cardId: savingsAccount }));
      }
    }
  }, [accountIdsToNames]);

  return (
    <MediaLayoutTemplate
      Desktop={TransfersHistoryView.desktop}
      Mobile={TransfersHistoryView.mobile}
      navigateBack={navigateBack}
      transactions={transactions}
      transactionsSearchText={transactionsSearchText}
      transactionsFilteredBy={transactionsFilteredBy}
      onOpenTransactionModal={onOpenTransactionModal}
      isModalOpen={isModalOpen}
      toCloseModal={toCloseModal}
      modalName={modalName}
      transactionId={transactionId}
      transactionsIsLoading={isLoadingSpending || isLoadingSavings}
      setTransferFilterOption={setTransferSelect}
    />
  );
};

export default TransfersHistoryPage;
