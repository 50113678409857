import { IconWithDescriptionTypes } from '@molecules/IconWithDescription/IconWithDescription.types';
import useIsPhone from '@hooks/useIsPhone';
import {
  accessibleHitSlop,
  desktopIllustrationDimensions,
  mobileIllustrationDimensions,
} from '@constants/ui';
import { TButton, Text } from '@atoms';
import { View } from 'tamagui';

const IconWithDescription = ({
  Icon,
  title,
  description,
  showButton = false,
  buttonLabel,
  buttonVariant = 'primary',
  buttonHandler,
  titlePaddingTop = '7',
}: IconWithDescriptionTypes) => {
  const isPhone = useIsPhone();
  const iconDimensions = isPhone
    ? mobileIllustrationDimensions
    : desktopIllustrationDimensions;
  return (
    <View maxWidth={isPhone ? 295 : 325} alignItems={'center'}>
      <Icon {...iconDimensions} hitSlop={accessibleHitSlop} />
      <View pt={isPhone ? titlePaddingTop : titlePaddingTop || '$2'}>
        <Text
          textAlign="center"
          fontSize="$h3"
          fontWeight="$medium"
          color="black"
          accessible
          accessibilityLabel={`${title}`}
        >
          {title}
        </Text>
        <Text
          textAlign="center"
          fontSize={14}
          color="$gray50"
          pt="$2"
          accessible
          accessibilityLabel={`${description}`}
        >
          {description}
        </Text>
      </View>
      {!!showButton && (
        <View w="100%">
          <TButton
            // @ts-ignore
            variant={buttonVariant}
            mt="$6"
            onPress={buttonHandler}
            hitSlop={accessibleHitSlop}
            accessible
            accessibilityLabel={`${buttonLabel}`}
          >
            {buttonLabel}
          </TButton>
        </View>
      )}
    </View>
  );
};

export default IconWithDescription;
