/* eslint-disable no-useless-escape */
const initialsRegex = new RegExp(/\b\w{2,}\b/);
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^[1]{1} [0-9]{3}-[0-9]{3}-[0-9]{4}$/;
const isValidBeneficiaryInfoFn = (text: string) => {
  text = text.trim();
  return initialsRegex.test(text);
};

const isValidBeneficiaryInfoLn = (text: string) => {
  text = text.trim();
  return initialsRegex.test(text);
};

const isValidBeneficiaryInfoEmail = (text: string) => {
  text = text.trim();
  return emailRegex.test(text);
};

const isValidBeneficiaryInfoPhone = (text: string) => {
  text = text.trim();
  return phoneRegex.test(text);
};
const isValidBeneficiaryInfo = (text: string) => {
  text = text.trim();
  return (
    text.match(/^[0-9a-z_.-]+@[a-z]+(\.[a-z]+)?\.[a-z]+$/i) ||
    text.replace(/\s+/g, '').match(/^[1]{1} [0-9]{3}-[0-9]{3}-[0-9]{4}$/)
  );
};

export {
  isValidBeneficiaryInfoFn,
  isValidBeneficiaryInfoLn,
  isValidBeneficiaryInfoEmail,
  isValidBeneficiaryInfoPhone,
  isValidBeneficiaryInfo,
};
