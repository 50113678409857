import { Tile, TileSkeleton } from '@molecules';
import TILES from '@constants/tiles';
import CardDeliveryTileIcon from '../../../assets/tiles/cardDelivery.svg';
import CardDeliveredTileIcon from '../../../assets/tiles/cardDelivered.svg';
import CardFrozenTileIcon from '../../../assets/tiles/cardFrozen.svg';
import OnTheWayIcon from '../../../assets/tiles/onTheWay.svg';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Platform } from 'react-native';
import {
  selectActiveCardRequestStatus,
  selectDebitCardStatus,
  selectPhysicalCardStatus,
  selectShipmentInfo,
} from '@store/features/cards/selectors';
import { CARD_STATES } from '@constants/cardStates';
import cardsThunks from '@store/features/cards/asyncThunks';
import {
  selectShowDirectDepositPrompt,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import {
  dateStringToAfterFiveDays,
  dateStringToIsOnTheWay,
} from '@utils/dateHelpers';
import { useAppDispatch } from '@store/hooks';
import { WidgetWrapper } from '@views/BottomTabs/HomeView';
import useDebitCardMenu from '@hooks/useDebitCardMenu';
import { useNavigation } from '@react-navigation/native';
import { sideModalNames } from '@constants/sideModalNames';
import { HomeNavigatorParamsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { StoreReview } from '@utils/storeReview';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import { selectIsDeliveryWidgetHidden } from '@store/features/general/selectors';
import { generalActions } from '@store/features/general/slice';

interface PhysicalCardWidgetProps {
  navigateToDda: () => void;
  navigateToDebitCard: () => void;
  isLoading: boolean;
  ddaEligibilityLoading: boolean;
  toOpenModal?: (name: string) => () => void;
}

const PhysicalCardWidget = ({
  navigateToDda,
  navigateToDebitCard,
  isLoading,
  ddaEligibilityLoading,
  toOpenModal,
}: PhysicalCardWidgetProps) => {
  const physicalCardStatus = useSelector(selectPhysicalCardStatus);
  const debitCardStatus = useSelector(selectDebitCardStatus);
  const showDirectDepositPrompt = useSelector(selectShowDirectDepositPrompt);
  const activeCardRequestStatus = useSelector(selectActiveCardRequestStatus);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const shipmentInfo = useSelector(selectShipmentInfo);
  const isDeliveryWidgetHidden = useSelector(selectIsDeliveryWidgetHidden);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<HomeNavigatorParamsProps['navigation']>();
  const { unfreezeCard, cardStatusRequestStatus } = useDebitCardMenu({});

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const hideSetDda = () => {
    dispatch(
      cardholderThunks.updateCardholderAdditionalInfo({
        show_direct_deposit_prompt: false,
      })
    );
  };
  const toActiveCard = () => {
    dispatch(cardsThunks.putActivePhysicalCard({ cardId: spendingAccountId }))
      .unwrap()
      .then(() => {
        if (Platform.OS !== 'web') {
          StoreReview.requestReview();
        }
      })
      .catch((err) => handleRequestError(err));
  };
  const hideDelivery = () => {
    dispatch(generalActions.toHideDeliveryWidget());
  };

  const cardBlockedHandler = () => {
    if (toOpenModal) {
      toOpenModal(sideModalNames.blockedCard)();
    } else {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: { pageName: 'blockedCard' },
      });
    }
  };

  const cardStatus = useMemo(() => {
    if (isLoading) return 'skeleton';
    if (physicalCardStatus === 'ORDERED') {
      if (shipmentInfo) {
        if (dateStringToIsOnTheWay(shipmentInfo.shipment_date)) {
          if (isDeliveryWidgetHidden) {
            return null;
          }
          return 'onTheWay';
        } else {
          return 'deliveredSoon';
        }
      } else {
        if (isDeliveryWidgetHidden) {
          return null;
        }
        return 'onTheWay';
      }
    }
    if (
      debitCardStatus === CARD_STATES.regular &&
      (showDirectDepositPrompt || showDirectDepositPrompt === null)
    ) {
      return 'active';
    }
    if (debitCardStatus === CARD_STATES.frozen) {
      return 'frozen';
    }
    if (debitCardStatus === CARD_STATES.blocked) {
      return 'blocked';
    }
    return null;
  }, [
    physicalCardStatus,
    debitCardStatus,
    showDirectDepositPrompt,
    isDeliveryWidgetHidden,
    isLoading,
  ]);

  switch (cardStatus) {
    case 'skeleton':
      return (
        <WidgetWrapper>
          <TileSkeleton />
        </WidgetWrapper>
      );
    case 'onTheWay':
      return (
        <WidgetWrapper>
          <Tile
            {...TILES.physicalCard.onTheWay}
            subtitle={
              shipmentInfo
                ? TILES.physicalCard.onTheWay.subtitle.replace(
                    '{date}',
                    dateStringToAfterFiveDays(shipmentInfo.shipment_date)
                  )
                : TILES.physicalCard.onTheWay.subtitleNoShipmentInfo
            }
            leftButtonHandler={hideDelivery}
            rightButtonHandler={navigateToDebitCard}
            secondTestID="seeAccountDetails"
            icon={<OnTheWayIcon />}
            firstTestID="cardDeliveredSoonActivateButton"
            isLeftButtonLoading={activeCardRequestStatus === 'pending'}
            isLoading={isLoading}
          />
        </WidgetWrapper>
      );
    case 'deliveredSoon':
      return (
        <WidgetWrapper>
          <Tile
            variant="blueButton"
            {...TILES.physicalCard.deliveredSoon}
            leftButtonHandler={toActiveCard}
            icon={<CardDeliveryTileIcon />}
            firstTestID="cardDeliveredSoonActivateButton"
            isLeftButtonLoading={activeCardRequestStatus === 'pending'}
            isLoading={isLoading}
          />
        </WidgetWrapper>
      );
    case 'frozen':
      return (
        <WidgetWrapper>
          <Tile
            variant="blueButton"
            {...TILES.physicalCard.frozen}
            leftButtonHandler={() => unfreezeCard({ calledFrom: 'widget' })}
            icon={<CardFrozenTileIcon />}
            firstTestID="frozenCardUnfreezeButton"
            isLoading={isLoading || cardStatusRequestStatus === 'pending'}
          />
        </WidgetWrapper>
      );
    case 'blocked':
      return (
        <WidgetWrapper>
          <Tile
            variant="simpleButton"
            {...TILES.physicalCard.blocked}
            leftButtonHandler={cardBlockedHandler}
            icon={<CardFrozenTileIcon />}
            firstTestID="cardBlockedLearnMoreButton"
            isLoading={isLoading}
          />
        </WidgetWrapper>
      );
    case 'active':
      return (
        <WidgetWrapper>
          <Tile
            {...TILES.physicalCard.ready}
            leftButtonHandler={hideSetDda}
            rightButtonHandler={navigateToDda}
            icon={<CardDeliveredTileIcon width={48} height={48} />}
            firstTestID="setupDepositHideThisButton"
            secondTestID="setupDepositLearnMoreButton"
            isLoading={isLoading}
            isRightButtonLoading={ddaEligibilityLoading}
          />
        </WidgetWrapper>
      );
    default:
      return null;
  }
};

export default PhysicalCardWidget;
