import { IconWithDescription } from '@molecules';
import NotificationIcon from '../../../assets/notificationIllustration.svg';
import { View, XStack, YStack } from 'tamagui';
import { Text, Switch } from '@atoms';
import { Platform } from 'react-native';

interface StayInLoopNotificationSettingsProps {
  notificationsSettings: {
    mobilePushPaychexPay: boolean;
    text: boolean;
    email: boolean;
  };
  mobilePushPaychexPayChange: (f: boolean) => void;
  textChange: (f: boolean) => void;
  emailChange: (f: boolean) => void;
}

const StayInLoopNotificationSettings = ({
  notificationsSettings,
  mobilePushPaychexPayChange,
  textChange,
  emailChange,
}: StayInLoopNotificationSettingsProps) => {
  return (
    <View pt={2} flexGrow={1} alignItems="center" px={6}>
      <IconWithDescription
        Icon={NotificationIcon}
        description="Choose how to get updates on your account activity. Change this at any time."
        title="Stay in the loop"
        titlePaddingTop={0}
      />
      <YStack w="100%" paddingTop={24}>
        {Platform.OS !== 'web' && (
          <XStack justifyContent="space-between" py={3} alignItems="center">
            <Text>Push</Text>
            <Switch
              checked={notificationsSettings.mobilePushPaychexPay}
              onChange={mobilePushPaychexPayChange}
            />
          </XStack>
        )}
        <XStack
          justifyContent="space-between"
          paddingVertical={13}
          alignItems="center"
        >
          <Text fontSize={16}> Email</Text>
          <Switch
            testID="paychexpay.AllowNotificationsView.switch.emailSwitch"
            checked={notificationsSettings.email}
            onChange={emailChange}
          />
        </XStack>
        <XStack
          justifyContent="space-between"
          paddingVertical={13}
          alignItems="center"
        >
          <Text fontSize={16}>Text</Text>
          <Switch
            testID="paychexpay.AllowNotificationsView.switch.textSwitch"
            checked={notificationsSettings.text}
            onChange={textChange}
          />
        </XStack>
      </YStack>
    </View>
  );
};

export default StayInLoopNotificationSettings;
