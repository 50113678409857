import { ProfileSettingProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import ProfileSettingView from '@views/More/ProfileSettingView';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useSelector } from 'react-redux';
import { useBiometricSwitch } from '@hooks/useBiometricSwitchNative';
import { useSideModal } from '@hooks/useSideModal';
import useIsPhone from '@hooks/useIsPhone';
import { sideModalNames } from '@constants/sideModalNames';
import { selectCardholder } from '@store/features/cardholder/selectors';
import {
  selectSsnNumber,
  selectUserInfo,
} from '@store/features/fillInData/selectors';
import { selectBiometricsType } from '@store/features/biometrics/selectors';
import { useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
import { generalActions } from '@store/features/general/slice';
import { useIsFocused } from '@react-navigation/native';
import { useAppDispatch } from '@store/hooks';

const ProfileSettingPage = ({ navigation, route }: ProfileSettingProps) => {
  const isPhone = useIsPhone();
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };
  const navigateToEditPhone = () => {
    navigation.navigate('EditPhone');
  };
  const navigateToEditEmail = () => {
    navigation.navigate('EditEmail');
  };
  const navigateToEditAddress = () => {
    navigation.navigate('EditAddress');
  };

  const { isModalOpen, modalName, toOpenModal, toCloseModal } = useSideModal({
    route,
    navigation,
  });

  const toOpenSupport = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: { pageName: 'customerService' },
      });
    } else {
      toOpenModal(sideModalNames.customerService)();
    }
    if (Platform.OS === 'web') {
      dispatch(generalActions.setWebNavHelpSubsectionIsVisible(true));
    }
  };

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (Platform.OS === 'web') {
      dispatch(generalActions.setWebNavHelpSubsectionIsVisible(false));
    }
  }, [isFocused]);

  const { isTurnedOnBiometric, onToggleBiometric } = useBiometricSwitch({});

  const userProfileData = useSelector(selectCardholder);
  const userInfoData = useSelector(selectUserInfo);
  const biometryType = useSelector(selectBiometricsType);
  const userSSN = useSelector(selectSsnNumber);

  const showBiometricSection = useMemo(
    () => biometryType !== 'Face' && biometryType !== 'Iris', // hide section on android for face and iris
    [biometryType]
  );

  return (
    <MediaLayoutTemplate
      Mobile={ProfileSettingView.mobile}
      Desktop={ProfileSettingView.desktop}
      navigateBack={navigateBack}
      userProfileData={userProfileData}
      dateOfBirth={userInfoData.date}
      userSSN={userSSN}
      navigateToEditPhone={navigateToEditPhone}
      navigateToEditEmail={navigateToEditEmail}
      navigateToEditAddress={navigateToEditAddress}
      toOpenSupport={toOpenSupport}
      isTurnedOnBiometric={isTurnedOnBiometric}
      onToggleBiometric={onToggleBiometric}
      toCloseModal={toCloseModal}
      isModalOpen={isModalOpen}
      modalName={modalName}
      biometryType={biometryType}
      showBiometricSection={showBiometricSection}
    />
  );
};

export default ProfileSettingPage;
