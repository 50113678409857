import { ChangePinProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { ChangePinView } from '@views/Accounts/ChangePinView';

const ChangePinPage = ({ navigation, route }: ChangePinProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };

  return (
    <ChangePinView
      changePinUrl={route.params.changePinUrl}
      navigateBack={navigateBack}
    />
  );
};

export default ChangePinPage;
