import AgreementsView from '@views/SignUp/AgreementsView';
import { useEffect, useMemo, useRef, useState } from 'react';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import agreementsThunks from '@store/features/agreements/asyncThunks';
import { Alert, Platform, ScrollView } from 'react-native';
import { useAppDispatch } from '@store/hooks';
import { LegalAgreementsProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { useSelector } from 'react-redux';
import {
  selectAgreements,
  selectSavingAccountAgreement,
} from '@store/features/agreements/selectors';
import { Share } from '@utils/shareUtil';
import { ReactNativeBlobUtil } from '@utils/rnBlobUtil';
import { IAgreement } from '@store/features/agreements/types';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';

const LegalAgreementPage = ({ navigation, route }: LegalAgreementsProps) => {
  const [isAgreeDisabled, setIsAgreeDisabled] = useState(true);

  const agreements = useSelector(selectAgreements);
  const savingAccountAgreement = useSelector(selectSavingAccountAgreement);
  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const onPressBack = () => {
    navigation.goBack();
  };

  const [isSaveFileLoading, setIsSaveFileLoading] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(0);
  // eslint-disable-next-line no-console
  const pdfRef = useRef({ setPage: (n: number) => console.log(n) });
  const scrollViewRef = useRef<ScrollView | null>(null);

  const onPressScrollArrow = () => {
    pdfRef.current.setPage(numberOfPages);
    scrollViewRef?.current?.scrollToEnd({ animated: true });
  };

  const setScreenTitle = (agreement: IAgreement) => {
    navigation.setOptions({
      title: `Paychex Pay - Legal Agreements - ${agreement.name}`,
    });
  };

  useEffect(() => {
    if (route.params?.document_id !== savingAccountAgreement.id) {
      dispatch(
        agreementsThunks.getAgreementById({
          document_id: route.params?.document_id || '',
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
    } else {
      dispatch(agreementsThunks.getAgreementSaving({}))
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
  }, [route.params?.document_id]);

  const agreement = useMemo(() => {
    const agreementById = agreements.find((agreement) => {
      return agreement.id === route.params?.document_id;
    });

    if (agreementById) {
      return agreementById;
    } else {
      return savingAccountAgreement;
    }
  }, [agreements, savingAccountAgreement]);

  useEffect(() => {
    setScreenTitle(agreement);
  }, [agreement]);

  const sharePDFWithWeb = (fileUrl: string | undefined, title: string) => {
    setIsSaveFileLoading(true);
    const pdfUrl = fileUrl || '';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = title + '.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsSaveFileLoading(false);
  };

  const sharePDFWithIOS = (fileUrl: string | undefined, title: string) => {
    setIsSaveFileLoading(true);

    let filePath = '';
    const type = 'application/pdf';
    const dirs = ReactNativeBlobUtil.fs.dirs;
    const configOptions = {
      fileCache: true,
      path: dirs.DocumentDir + '/' + title + '.pdf',
    };

    ReactNativeBlobUtil.config(configOptions)
      .fetch('GET', fileUrl)
      .then(async (resp: any) => {
        filePath = resp.path();
        const options = {
          title: title,
          type: type,
          url: filePath,
          filename: title,
        };
        await Share.open(options);
        await ReactNativeBlobUtil.fs.unlink(filePath);
      })
      .finally(() => {
        setIsSaveFileLoading(false);
      });
  };

  const sharePDFWithAndroid = (fileUrl: string | undefined, title: string) => {
    setIsSaveFileLoading(true);

    let filePath = '';
    const type = 'application/pdf';
    const dirs = ReactNativeBlobUtil.fs.dirs;

    const downloadFileAndroid = async () => {
      ReactNativeBlobUtil.config({
        fileCache: true,
        appendExt: 'pdf',
        path: `${dirs.DocumentDir}/${title}.pdf`,
        addAndroidDownloads: {
          useDownloadManager: true,
          notification: true,
          title: title,
          description: 'File downloaded by download manager.',
          mime: 'application/pdf',
        },
      })
        .fetch('GET', fileUrl)
        .finally(() => {
          setIsSaveFileLoading(false);
        });
    };

    const shareFileAndroid = async () => {
      ReactNativeBlobUtil.config({ fileCache: true })
        .fetch('GET', fileUrl)
        .then((resp: any) => {
          filePath = resp.path();
          return resp.readFile('base64');
        })
        .then(async (base64Data: string) => {
          base64Data = `data:${type};base64,` + base64Data;
          await Share.open({
            url: base64Data,
            title: title,
            type: type,
            filename: title,
          });
          await ReactNativeBlobUtil.fs.unlink(filePath);
        })
        .finally(() => {
          setIsSaveFileLoading(false);
        });
    };

    const onCancelAlert = () => {
      setIsSaveFileLoading(false);
    };

    Alert.alert(
      'What to do?',
      'You can download the file to your device or share/print it right away.',
      [
        {
          text: 'Cancel',
          style: 'cancel',
          onPress: onCancelAlert,
        },
        {
          text: 'Download',
          onPress: downloadFileAndroid,
          isPreferred: true,
        },
        {
          text: 'Share (print)',
          onPress: shareFileAndroid,
        },
      ],
      {
        cancelable: true,
        onDismiss: onCancelAlert,
      }
    );
  };

  const onPressSave = ({
    agreementPdfLink,
    title,
  }: {
    agreementPdfLink: string | undefined;
    title: string;
  }) => {
    if (Platform.OS === 'web') {
      sharePDFWithWeb(agreementPdfLink, title);
    } else {
      if (Platform.OS === 'ios') {
        sharePDFWithIOS(agreementPdfLink, title);
      } else {
        sharePDFWithAndroid(agreementPdfLink, title);
      }
    }
  };

  return (
    <MediaLayoutTemplate
      Mobile={AgreementsView.mobile}
      Desktop={AgreementsView.desktop}
      screenType={'single'}
      readOnly={true}
      showSideBar={route.params?.showSideBar || false}
      onPressBack={onPressBack}
      onPressSave={onPressSave}
      isSaveFileLoading={isSaveFileLoading}
      onPressScrollArrow={onPressScrollArrow}
      isAgreeDisabled={isAgreeDisabled}
      agreementPdfLink={agreement?.document.url || ''}
      agreementName={agreement?.name || ''}
      title={agreement?.name || 'Terms and conditions'}
      setNumberOfPages={setNumberOfPages}
      setIsAgreeDisabled={setIsAgreeDisabled}
      pdfRef={pdfRef}
      numberOfPages={numberOfPages}
      scrollViewRef={scrollViewRef}
    />
  );
};

export default LegalAgreementPage;
