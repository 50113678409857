import { XStack, View } from 'tamagui';
import { Dimensions, StatusBar } from 'react-native';
import { CustomDrawer } from '@molecules';
import BurgerMenuIcon from '../../../assets/burgerMenu.svg';
import Logo from '../../../assets/logo.svg';
import HelpIcon from '../../../assets/helpIconWhite.svg';
import CloseIcon from '../../../assets/closeIcon.svg';
import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { MoreNavigatorProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { MOBILE_WEB_HEADER_HEIGHT } from '@constants/general';
import { TPressable } from '@atoms';

const MobileWebHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigation = useNavigation<MoreNavigatorProps['navigation']>();

  const screenHeight = Dimensions.get('window').height;

  const toMenuPress = () => {
    setMenuOpen((prevState) => !prevState);
  };
  const onPressHelp = () => {
    navigation.navigate('MoreNavigator', {
      screen: 'Help',
    });
  };

  return (
    <View marginBottom={MOBILE_WEB_HEADER_HEIGHT}>
      <StatusBar barStyle="light-content" />
      <View
        position="absolute"
        zIndex={103}
        w="100%"
        h={`${MOBILE_WEB_HEADER_HEIGHT}px`}
        backgroundColor="$primaryDark"
        px="$4"
        py="$3"
      >
        <XStack justifyContent="space-between">
          <XStack alignItems="center" height={24}>
            <TPressable
              pr="$4"
              height={24}
              onPress={toMenuPress}
              testID="paychexpay.mobileWebHeader.button.toMenuPress"
              accessible
              accessibilityLabel={
                isMenuOpen ? 'Close menu button' : 'Open menu button'
              }
            >
              {isMenuOpen ? (
                <CloseIcon height="24px" width="24px" fill="white" />
              ) : (
                <BurgerMenuIcon />
              )}
            </TPressable>
            <Logo accessible accessibilityLabel={'Paychex Pay logo'} />
          </XStack>
          <XStack>
            <TPressable
              onPress={onPressHelp}
              testID="paychexpay.mobileWebHeader.button.onPressHelp"
              accessible
              accessibilityLabel={'Help button'}
            >
              <HelpIcon height="24px" width="24px" fill="white" />
            </TPressable>
          </XStack>
        </XStack>
      </View>
      {isMenuOpen && (
        <View
          position={'absolute'}
          height={screenHeight}
          width="100%"
          display={'flex'}
          flexGrow={1}
          flexDirection={'column'}
          zIndex={102}
        >
          <CustomDrawer
            isFullScreenDrawer={true}
            onDrawerItemPress={toMenuPress}
          />
        </View>
      )}
    </View>
  );
};

export default MobileWebHeader;
