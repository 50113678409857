import { EditPhoneProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import EditPhoneView from '../../../components/views/More/EditPhoneView';
import { useForm } from 'react-hook-form';
import {
  IPhoneField,
  updateMobilePhoneSchema,
} from '@organisms/FillInDataFirstForm/helper';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { selectCardholder } from '@store/features/cardholder/selectors';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '@store/hooks';
import { selectProfileSettingsModalVisible } from '@store/features/general/selectors';
import { generalActions } from '@store/features/general/slice';

const EditPhonePage = ({ navigation }: EditPhoneProps) => {
  const [isSaveButtonLoading, setSaveButtonLoading] = useState(false);
  const userProfileData = useSelector(selectCardholder);
  const [mobilePhone] = userProfileData.phone_details.filter(
    (phone) => phone.phone_type === 'MOBILE'
  );

  const navigateBack = () => {
    navigation.navigate('ProfileSetting');
    dispatch(generalActions.setProfileSettingsModalVisible(false));
    dispatch(generalActions.setProfileUpdated(false));
  };

  const mobilePhoneView = useMemo(() => {
    return `(${mobilePhone.phone_number.slice(
      0,
      3
    )}) ${mobilePhone.phone_number.slice(
      3,
      6
    )}-${mobilePhone.phone_number.slice(6, 10)}`;
  }, [mobilePhone.phone_number]);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty: isFormDirty },
    reset,
  } = useForm<IPhoneField>({
    defaultValues: {
      phoneNumber: mobilePhoneView,
    },
    resolver: yupResolver(updateMobilePhoneSchema),
  });

  useEffect(() => {
    dispatch(generalActions.setModalSubmitHandler(() => navigateBack()));
    dispatch(generalActions.setProfileUpdated(isFormDirty));
  }, [isFormDirty]);

  const dispatch = useAppDispatch();

  const onSubmit = async (data: IPhoneField) => {
    setSaveButtonLoading(true);
    await dispatch(
      cardholderThunks.updateCardholderInfo({
        phone_details: [
          {
            phone_type: 'MOBILE',
            country_dialing_code: mobilePhone.country_dialing_code,
            phone_number: data.phoneNumber.replace(/[() -]/g, ''),
          },
        ],
      })
    );
    setSaveButtonLoading(false);
    navigateBack();
  };

  const profileSettingsModalVisible = useSelector(
    selectProfileSettingsModalVisible
  );

  const setConfirmationModalVisible = (value: boolean) => {
    dispatch(generalActions.setProfileSettingsModalVisible(value));
  };

  return (
    <MediaLayoutTemplate
      Mobile={EditPhoneView.mobile}
      Desktop={EditPhoneView.desktop}
      navigateBack={navigateBack}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      isSaveButtonLoading={isSaveButtonLoading}
      isFormDirty={isFormDirty}
      profileSettingsModalVisible={profileSettingsModalVisible}
      setConfirmationModalVisible={setConfirmationModalVisible}
      resetForm={reset}
    />
  );
};

export default EditPhonePage;
