import MapButtonsWrapper from '@organisms/Map/MapButtonsWrapper';
import { ReactNode, useEffect, useState } from 'react';
import { mockedLocation } from '@constants/markersList';
import GoogleMap, { MapOptions } from 'google-map-react';
import { Pressable, View } from 'react-native';
import GreenMarker from '../../../assets/atm/markerGreen.svg';
import WhiteCircle from '../../../assets/atm/whiteCircle.svg';
import atmObjectToIcon from '@utils/atmObjectToIcon';
import UserPinIcon from '../../../assets/atm/userPin.svg';
import { ICenter, IMarker } from '@store/features/atmLocations/types';

const GOOGLE_MAPS_API_KEY_WEB = '{{bank.paychexbank.google.api.key.web}}';

interface MarkerProps {
  lat: number;
  lng: number;
  children: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Marker = ({ lat, lng, children }: MarkerProps) => {
  return <>{children}</>;
};

interface MapProps {
  showThisAriaButton: () => void;
  markers: IMarker[];
  onPressMarker: (onPressEvent: {
    markerId: string;
    latitude: number;
    longitude: number;
  }) => () => void;
  currentMarkerId: string;
  currentLocation: typeof mockedLocation;
  setZoomLevel: (n: number) => void;
  center: ICenter;
  setCenter: (center: ICenter) => void;
}

export const Map = ({
  markers,
  onPressMarker,
  showThisAriaButton,
  currentMarkerId,
  currentLocation,
  setZoomLevel,
  center,
  setCenter,
}: MapProps) => {
  const createMapOptions = (): MapOptions => {
    return {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      minZoom: 14,
      maxZoom: 20,
    };
  };
  useEffect(() => {
    setCenter({
      lat: currentLocation.latitude,
      lng: currentLocation.longitude,
    });
  }, [currentLocation]);

  const markerCoordinate = markers[0]?.location?.coordinates;

  useEffect(() => {
    if (markers.length) {
      setCenter({
        lat: markerCoordinate.latitude,
        lng: markerCoordinate.longitude,
      });
    }
  }, [markers]);

  return (
    // @ts-ignore
    <GoogleMap
      bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY_WEB }}
      center={center}
      onDragEnd={(map) => {
        setCenter({
          lat: map.center.lat(),
          lng: map.center.lng(),
        });
        showThisAriaButton();
      }}
      defaultZoom={16}
      options={createMapOptions}
      onZoomAnimationStart={(zoom) => setZoomLevel(zoom)}
    >
      {markers.map((marker) => (
        <Marker
          key={marker.location.id}
          lat={marker.location.coordinates.latitude}
          lng={marker.location.coordinates.longitude}
        >
          <Pressable
            onPress={onPressMarker({
              markerId: marker.location.id,
              latitude: marker.location.coordinates.latitude,
              longitude: marker.location.coordinates.longitude,
            })}
          >
            <GreenMarker
              color={
                marker.location.id === currentMarkerId ? '#0073C4' : '#19AF92'
              }
            />
            <View style={{ position: 'absolute', zIndex: 1, top: 3, left: 3 }}>
              <WhiteCircle />
            </View>
            <View style={{ position: 'absolute', zIndex: 2, top: 6, left: 6 }}>
              {atmObjectToIcon(marker, {
                color:
                  marker.location.id === currentMarkerId
                    ? '#0073C4'
                    : '#19AF92',
              })}
            </View>
          </Pressable>
        </Marker>
      ))}
      <Marker
        key="user-location"
        lat={currentLocation.latitude}
        lng={currentLocation.longitude}
      >
        <UserPinIcon />
      </Marker>
    </GoogleMap>
  );
};

interface MapWithButtonsProps {
  markers: IMarker[];
  onPressMarker: (onPressEvent: {
    markerId: string;
    latitude: number;
    longitude: number;
  }) => () => void;
  currentMarkerId: string;
  getMarkers: (distance: string, coordinates: string) => void;
  mapCenter: ICenter;
  setMapCenter: (center: ICenter) => void;
  setIsShowThisAriaButton: (value: boolean) => void;
  isShowThisAriaButton: boolean;
  onPressSearchArea: (value: {
    zoomLevel: number;
    layout: { w: number; h: number };
  }) => void;
  isPermissionDenied: boolean;
  currentLocation: any;
  requestCurrentLocation: () => void;
  isRequestWithoutResponse: boolean;
}

const MapWithButtons = ({
  markers,
  onPressMarker,
  currentMarkerId,
  onPressSearchArea,
  mapCenter,
  setMapCenter,
  setIsShowThisAriaButton,
  isShowThisAriaButton,
  isPermissionDenied,
  currentLocation,
  requestCurrentLocation,
  isRequestWithoutResponse,
}: MapWithButtonsProps) => {
  const [zoomLevel, setZoomLevel] = useState(16);
  const [layout, setLayout] = useState({ w: 0, h: 0 });

  const showThisAriaButton = () => {
    setIsShowThisAriaButton(true);
  };

  useEffect(() => {
    requestCurrentLocation();
    showThisAriaButton();
  }, []);
  const onPressLocation = () => {
    requestCurrentLocation();
    showThisAriaButton();
  };

  return (
    <MapButtonsWrapper
      onPressSearchArea={() => onPressSearchArea({ zoomLevel, layout })}
      isShowThisAriaButton={isShowThisAriaButton}
      onPressLocation={onPressLocation}
      isRequestWithoutResponse={isRequestWithoutResponse}
      isPermissionDenied={isPermissionDenied}
    >
      <View
        style={{
          flexGrow: 1,
          height: '100%',
        }}
        onLayout={(e) =>
          setLayout({
            w: e.nativeEvent.layout.width,
            h: e.nativeEvent.layout.height,
          })
        }
      >
        <Map
          onPressMarker={onPressMarker}
          markers={markers}
          showThisAriaButton={showThisAriaButton}
          currentMarkerId={currentMarkerId}
          currentLocation={currentLocation}
          setZoomLevel={setZoomLevel}
          center={mapCenter}
          setCenter={setMapCenter}
        />
      </View>
    </MapButtonsWrapper>
  );
};

export default MapWithButtons;
