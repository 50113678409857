import { TransactionDetailsScreenProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { TransactionDetailsView } from '@views/Transfers';
import idToTransaction from '@utils/idToTransaction';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useSelector } from 'react-redux';
import {
  selectSavingsAllTransactions,
  selectSpendingAllTransactions,
} from '@store/features/transactions/selectors';
import { useMemo } from 'react';

const TransactionDetailsPage = ({
  navigation,
  route,
}: TransactionDetailsScreenProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };

  const spendingTransactions = useSelector(selectSpendingAllTransactions);
  const savingsTransactions = useSelector(selectSavingsAllTransactions);
  const transactions = useMemo(
    () => [...spendingTransactions, ...savingsTransactions],
    [spendingTransactions, savingsTransactions]
  );

  return (
    <MediaLayoutTemplate
      Mobile={TransactionDetailsView.mobile}
      navigateBack={navigateBack}
      transaction={idToTransaction(route.params?.transactionId, transactions)}
    />
  );
};

export default TransactionDetailsPage;
