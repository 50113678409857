import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';
import React, { useRef } from 'react';
import { ImageSourcePropType, Platform, Image as ImageRN } from 'react-native';
import { Image } from 'tamagui';

const FreezeLayer = ({
  layerId,
  imageSource,
  fadeAnimationsRef,
  isFrozenRef,
}: {
  layerId: number;
  imageSource: ImageSourcePropType;
  fadeAnimationsRef: any;
  isFrozenRef: any;
}) => {
  const fadeAnim = useRef(useSharedValue(isFrozenRef.current ? 1 : 0)).current;
  const animatedFadeAnim = useDerivedValue(() => fadeAnim.value);
  const freezeAnimatedStyleLayer = useAnimatedStyle(() => {
    return {
      opacity: animatedFadeAnim.value,
    };
  });
  fadeAnimationsRef[layerId] = fadeAnim;

  return (
    <Animated.View
      style={[{ position: 'absolute', zIndex: 3 }, freezeAnimatedStyleLayer]}
    >
      {Platform.OS === 'web' ? (
        <ImageRN
          source={imageSource}
          alt="frozen card"
          width={343}
          height={202}
        />
      ) : (
        <Image
          source={imageSource}
          alt="frozen card"
          width={343}
          height={202}
        />
      )}
    </Animated.View>
  );
};

export default FreezeLayer;
