import { Animated, StyleSheet } from 'react-native';
import { ReactNode, useEffect, useRef } from 'react';
import FocusAwareStatusBar from '@atoms/FocusAwareStatusBar/FocusAwareStatusBar';
import { TPressable } from '@atoms';

export interface CenterModalProps {
  isModalOpen: boolean;
  closeByOutsideClick?: boolean;
  closeModal?: () => void;
  children: ReactNode;
  maxWidth?: number;
}

const CenterModal = ({
  children,
  isModalOpen,
  closeModal,
  closeByOutsideClick,
  maxWidth,
}: CenterModalProps) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const animDuration = 700;

  useEffect(() => {
    if (isModalOpen) {
      fadeIn();
    } else {
      fadeAnim.setValue(0);
    }
  }, [isModalOpen]);

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 0.6,
      duration: animDuration,
      useNativeDriver: false,
    }).start();
  };

  return isModalOpen ? (
    <>
      <Animated.View
        style={[
          styles.back,
          {
            opacity: fadeAnim,
          },
        ]}
      />
      <TPressable
        position="absolute"
        h="100%"
        w="100%"
        zIndex={105}
        display="flex"
        justifyContent="center"
        alignItems="center"
        onPress={() => {
          closeByOutsideClick && closeModal && closeModal();
        }}
        cursor={closeByOutsideClick ? 'pointer' : 'default'}
        testID="closeOusidePressable"
      >
        <TPressable
          cursor={'default'}
          maxWidth={maxWidth ?? 343}
          width={'100%'}
        >
          {children}
        </TPressable>
      </TPressable>
      <FocusAwareStatusBar barStyle="light-content" />
    </>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  back: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    zIndex: 104,
  },
});

export default CenterModal;
