import React from 'react';
import { Route } from 'react-native-tab-view';
import useIsPhone from '@hooks/useIsPhone';
import { View, XStack } from 'tamagui';

export interface PaginationProps {
  navigationState: {
    index: number;
    routes: Route[];
  };
  small?: boolean;
}

const Pagination = ({ navigationState, small = false }: PaginationProps) => {
  const isPhone = useIsPhone();
  return (
    <XStack
      w="100%"
      justifyContent="center"
      pb={small ? 0 : isPhone ? '$7' : '$6'}
    >
      {navigationState.routes.map((route: Route, index: number) => {
        const tabIndex = navigationState.routes.indexOf(route);
        const isFocused = navigationState.index === tabIndex;
        return (
          <View
            key={index}
            mr={'$2'}
            bg={isFocused ? '$primaryNormal' : '$gray20'}
            borderRadius={10}
            h={small ? 6 : 8}
            w={small ? 6 : 8}
          />
        );
      })}
    </XStack>
  );
};

export default Pagination;
