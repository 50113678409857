import { PaychexPayCard } from '@molecules';
import { View } from 'tamagui';
import React, { useEffect, useState } from 'react';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { CARD_STATES } from '@constants/cardStates';
import useFreezeAnimation from '@hooks/useFreezeAnimation';
import { debitCardStatesType } from '@devTypes/cards';
import useBlockCardAnimation from '@hooks/Animations/useBlockCardAnimation';
import { Text } from '@atoms';
import { cardInfoType } from '@molecules/PaychexPayCard/PaychexPayCard.types';
import { ICardAuthInfo } from '@store/features/cards/types';
import { freezeDuration } from '@constants/cardAnimations';
import InfoIconFilled from '../../../assets/InfoIconFilled.svg';

export interface DebitCardPreviewProps {
  cardInfo: cardInfoType;
  cardAuthInfo: ICardAuthInfo;
  cardStatus: debitCardStatesType;
  learnMoreHandler: () => void;
  isBlockAnimated: boolean;
  cardImgSrc?: string;
}

const FREEZE_TEXT_HEIGHT = 45;
const BLOCK_TEXT_HEIGHT = 45;

const DebitCardPreview = ({
  cardInfo,
  cardAuthInfo,
  cardStatus,
  learnMoreHandler,
  isBlockAnimated,
  cardImgSrc,
}: DebitCardPreviewProps) => {
  const [, setLocalCardStatus] = useState<debitCardStatesType>(cardStatus);
  const { animateFreeze, animateUnfreeze, renderFreezeLayers } =
    useFreezeAnimation({ cardStatus });
  const [isCardBlocked, setIsCardBlocked] = useState(isBlockAnimated);
  const { animateBlock, animateUnblock, BlockedCardLayout } =
    useBlockCardAnimation({ isBlockAnimated });
  const freezeTextHeight = useSharedValue(
    cardStatus === CARD_STATES.frozen ? FREEZE_TEXT_HEIGHT : 0
  );
  const blockTextHeight = useSharedValue(
    cardStatus === CARD_STATES.blocked ? BLOCK_TEXT_HEIGHT : 0
  );
  const freezeTextStyle = useAnimatedStyle(() => {
    return {
      height: freezeTextHeight.value,
    };
  });
  const blockTextStyle = useAnimatedStyle(() => {
    return {
      height: blockTextHeight.value,
    };
  });

  useEffect(() => {
    setLocalCardStatus((prevState) => {
      if (
        prevState === CARD_STATES.blocked &&
        cardStatus === CARD_STATES.regular
      ) {
        animateUnblock();
        blockTextHeight.value = withTiming(0, {
          duration: freezeDuration,
        });
      }
      if (
        prevState === CARD_STATES.frozen &&
        cardStatus === CARD_STATES.regular
      ) {
        animateUnfreeze();
        freezeTextHeight.value = withTiming(0, {
          duration: freezeDuration,
        });
      }
      return cardStatus;
    });
    if (cardStatus === CARD_STATES.frozen) {
      animateFreeze();
      blockTextHeight.value = withTiming(0, {
        duration: freezeDuration,
      });
      freezeTextHeight.value = withTiming(FREEZE_TEXT_HEIGHT, {
        duration: freezeDuration,
      });
    } else if (cardStatus === CARD_STATES.blocked) {
      setIsCardBlocked(true);
      animateBlock();
      blockTextHeight.value = withTiming(FREEZE_TEXT_HEIGHT, {
        duration: freezeDuration,
      });
      freezeTextHeight.value = withTiming(0, {
        duration: freezeDuration,
      });
    }
  }, [cardStatus]);

  return (
    <View>
      <View
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        paddingVertical={'$6'}
      >
        {renderFreezeLayers && (
          <View
            width={343}
            height={202}
            position="absolute"
            zIndex={2}
            borderRadius={14}
            overflow="hidden"
          >
            {renderFreezeLayers}
          </View>
        )}
        {isCardBlocked && BlockedCardLayout}
        <PaychexPayCard
          cardInfo={cardInfo}
          cardAuthInfo={cardAuthInfo}
          cardImgSrc={cardImgSrc}
        />
      </View>
      <Animated.View style={[freezeTextStyle, { overflow: 'hidden' }]}>
        <View display={'flex'} justifyContent="center">
          <Text
            textAlign={'center'}
            variant="subtitle"
            testID={'paychexpay.debitCardPreview.yourCardIsFrozenText'}
            accessible
            accessibilityLabel={'Unfreeze your card to use it again.'}
          >
            Unfreeze your card to use it again
          </Text>
        </View>
      </Animated.View>
      <Animated.View style={[blockTextStyle, { overflow: 'hidden' }]}>
        <View
          display={'flex'}
          justifyContent="center"
          flexDirection="row"
          alignItems={'center'}
          overflow={'hidden'}
        >
          <Text
            variant="subtitle"
            accessible
            accessibilityLabel={'Your card is blocked.'}
          >
            Contact us to learn more
          </Text>
          <View
            display={'flex'}
            onPress={learnMoreHandler}
            accessible
            accessibilityLabel={'Learn more'}
            testID="paychexpay.debitCardPreview.learnMoreButton"
            marginLeft={'$1'}
          >
            <InfoIconFilled />
          </View>
        </View>
      </Animated.View>
    </View>
  );
};

export default DebitCardPreview;
