import {
  ITransferStatusContent,
  transfersScreensInfo,
  transfersScreensNames,
} from '@constants/transfersScreensNames';
import CompletedRequest from '../../../assets/transfers/Request sent.svg';
import CompletedTransfer from '../../../assets/transfers/Transfer sent.svg';
import { Text } from '@atoms';
import { ImageWithContent } from '@molecules';
import useIsPhone from '@hooks/useIsPhone';
import { useSelector } from 'react-redux';
import {
  selectErrorsStateTransfers,
  selectErrorState,
} from '@store/features/errorStates/selectors';
import { useMemo } from 'react';
import { View } from 'tamagui';

interface TransferCompletedContentProps {
  transferType: string;
}

const TransferCompletedContent = ({
  transferType,
}: TransferCompletedContentProps) => {
  const isPhone = useIsPhone();
  const image =
    transferType === transfersScreensNames.toOtherFriends ? (
      isPhone ? (
        <CompletedRequest width="295" height="240" />
      ) : (
        <CompletedRequest width="189" height="154" />
      )
    ) : isPhone ? (
      <CompletedTransfer width="295" height="240" />
    ) : (
      <CompletedTransfer width="189" height="154" />
    );
  const transferError = useSelector(selectErrorsStateTransfers);

  const errorState = useSelector(selectErrorState);

  const screeInfo = useMemo(() => {
    if (errorState.displayType === 'transfer')
      return transferError as ITransferStatusContent;

    return transfersScreensInfo[transferType]
      .completed as ITransferStatusContent;
  }, [errorState]);

  return (
    <ImageWithContent image={image} space={isPhone ? '$7' : 0}>
      <View px={isPhone ? '$6' : 0} w="100%">
        <Text variant="title" textAlign="center" pb={isPhone ? '$3' : '$2'}>
          {screeInfo.title}
        </Text>
        <Text variant="subtitle" textAlign="center">
          {screeInfo.subtitle}
        </Text>
        {screeInfo.hint && (
          <View
            mt={isPhone ? '$7' : '$2'}
            p={'$3'}
            bg="$blue10"
            borderRadius={12}
            w="100%"
          >
            <Text textAlign="center">{screeInfo.hint}</Text>
          </View>
        )}
      </View>
    </ImageWithContent>
  );
};

export default TransferCompletedContent;
