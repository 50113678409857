import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  IGetAtmLocationsParams,
  IGetAtmLocationsResponse,
} from '@store/features/atmLocations/types';
import { application, operations } from '@constants/traceability';

const atmLocationsAPI = {
  getAtmLocations(body: IGetAtmLocationsParams) {
    return wrappedAxiosRequest<null, IGetAtmLocationsResponse>({
      method: 'get',
      url: endpoints.atmLocations,
      params: {
        ...body,
        limit: 50,
      },
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GetATMLocations,
        'x-payx-comp': application.compPrefix + operations.GetATMLocations,
      },
    });
  },
};

export default atmLocationsAPI;
