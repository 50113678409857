import { baseUrl } from '@constants/endpoints';
import axios from 'axios';

// @ts-ignore
export function customAxiosAdapter(request) {
  // convert the Request into a config
  // that Axios understands -- e.g. axios
  // uses request.data instead of request.body

  return (
    axios({
      baseURL: baseUrl,
      data:
        request.method != 'GET' && request.method != 'get'
          ? request.body
          : undefined,
      method: request.method,
      headers: request.headers,
      url: request.url,
      responseType: request.responseType,
    })
      // always resolve with a Response,
      // regardless of any errors:
      // @ts-ignore
      .then((aResp) => {
        return {
          data: aResp.data,
          status: aResp.status,
          statusText: aResp.statusText,
          meta: {
            error: false,
            headers: aResp.headers,
          },
        };
      })
      // @ts-ignore
      .catch((aError) => {
        const aResp = aError.response;
        return {
          data: aResp?.data,
          status: aResp?.status,
          statusText: aResp?.statusText,
          meta: {
            error: true,
            headers: aResp?.headers,
          },
        };
      })
  );
}
