import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { CARD_STATES } from '@constants/cardStates';
import { debitCardStatesType } from '@devTypes/cards';
import { debitCardMenuItem } from '@organisms/DebitCardContent/DebitCardContent.types';
import useIsPhone from '@hooks/useIsPhone';
import { useSelector } from 'react-redux';
import {
  selectCardStatusRequestStatus,
  selectChangePinIsLoading,
  selectChangePinUrl,
  selectDebitCardStatus,
  selectIsAnimatedBlockCard,
  selectPhysicalCardStatus,
} from '@store/features/cards/selectors';
import {
  selectCardholder,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
import { cardsActions } from '@store/features/cards/slice';
import {
  DebitCardProps,
  SpendingAccountProps,
} from '@navigation/HomeNavigator/HomeNavigator.types';
import { useAppDispatch } from '@store/hooks';
import { requestStatusType, unfreezeParams } from '@store/features/cards/types';
import { Alert, Platform } from 'react-native';

const useDebitCardMenu = ({
  onOpenCardLostModal,
  onOpenOrderCardModal,
  navigation,
  isOpenedAsFullPage = false,
}: {
  onOpenCardLostModal?: () => void;
  onOpenOrderCardModal?: () => void;
  navigation?:
    | SpendingAccountProps['navigation']
    | DebitCardProps['navigation'];
  isOpenedAsFullPage?: boolean;
}) => {
  const isPhone = useIsPhone();
  const debitCardStatus = useSelector(selectDebitCardStatus);
  const cardStatusRequestStatus = useSelector(selectCardStatusRequestStatus);
  const isAnimatedBlockCard = useSelector(selectIsAnimatedBlockCard);
  const deliveryStatus = useSelector(selectPhysicalCardStatus);
  const [cardStatus, setCardStatus] = useState<debitCardStatesType>(
    !isAnimatedBlockCard && debitCardStatus === CARD_STATES.blocked
      ? CARD_STATES.regular
      : debitCardStatus
  );
  const dispatch = useAppDispatch();
  const spendingCardId = useSelector(selectSpendingAccountId);
  const cardholder = useSelector(selectCardholder);
  const changePinUrl = useSelector(selectChangePinUrl);
  const isLoadingChangePin = useSelector(selectChangePinIsLoading);
  const [isFreezeModalOpen, setIsFreezeModalOpen] = useState(false);
  const requestFreeze = () => {
    dispatch(
      cardsThunks.putCardStatus({ cardId: spendingCardId, status: 'BLOCK' })
    );
  };
  const requestActive = () => {
    dispatch(
      cardsThunks.putCardStatus({ cardId: spendingCardId, status: 'ACTIVE' })
    );
  };
  const freezeCardHandler = () => {
    if (Platform.OS !== 'web') {
      Alert.alert(
        '',
        'This action will prevent card transactions from being made, including purchases and ATM withdrawals.\nYou will need to unfreeze your card before you can use it again.',
        [
          {
            text: 'Cancel',
            style: 'cancel',
          },
          {
            text: 'Freeze',
            onPress: freezeCard,
            style: 'default',
            isPreferred: true,
          },
        ]
      );
    } else {
      if (isFreezeModalOpen) {
        freezeCard();
      } else {
        setIsFreezeModalOpen(true);
      }
    }
  };
  const unfreezeCardHandler = ({
    calledFrom,
  }: {
    calledFrom: unfreezeParams;
  }) => {
    if (Platform.OS !== 'web') {
      Alert.alert(
        'Unfreeze card?',
        'Are you sure you want to unfreeze your card? ',
        [
          {
            text: 'Cancel',
            style: 'cancel',
          },
          {
            text: 'Unfreeze',
            onPress: () => {
              unfreezeCard({ calledFrom });
            },
            style: 'default',
            isPreferred: true,
          },
        ]
      );
    } else {
      if (isFreezeModalOpen) {
        unfreezeCard({ calledFrom });
      } else {
        setIsFreezeModalOpen(true);
      }
    }
  };

  const freezeCard = () => {
    setCardStatus(CARD_STATES.frozen);
    requestFreeze();
    if (isFreezeModalOpen) {
      setIsFreezeModalOpen(false);
    }
  };

  const unfreezeCard = ({ calledFrom }: { calledFrom: unfreezeParams }) => {
    setCardStatus(CARD_STATES.regular);
    requestActive();
    dispatch(cardsActions.toSetCardUnfreezeCalledFrom(calledFrom));
    if (isFreezeModalOpen) {
      setIsFreezeModalOpen(false);
    }
  };

  useLayoutEffect(() => {
    if (debitCardStatus === CARD_STATES.blocked) {
      setCardStatus(CARD_STATES.blocked);
    } else if (debitCardStatus === CARD_STATES.regular) {
      setCardStatus(CARD_STATES.regular);
    }
  }, [debitCardStatus]);

  useEffect(() => {
    if (cardStatusRequestStatus === 'error') {
      setCardStatus(debitCardStatus);
    }
  }, [cardStatusRequestStatus, debitCardStatus]);

  useEffect(() => {
    if (changePinUrl) {
      navigation?.navigate('HomeNavigator', {
        screen: 'ChangePin',
        params: { changePinUrl },
      });
    }
    dispatch(cardsActions.resetChangePinUrl());
  }, [changePinUrl]);

  const changePinCodeHandler = async () => {
    await dispatch(
      cardsThunks.changePin({
        card_id: spendingCardId,
        language_code: 'en',
        cardholder_id: cardholder.cardholder_id,
      })
    );
  };

  const reportCardLostHandler = () => {
    if (isPhone || isOpenedAsFullPage) {
      navigation?.navigate('HomeNavigator', {
        screen: 'Support',
        params: {
          pageName: 'cardLost',
          isOpenedAsFullPage: isOpenedAsFullPage,
        },
      });
    } else {
      onOpenCardLostModal && onOpenCardLostModal();
    }
  };

  const digitalWalletHandler = () => {
    navigation?.navigate('MoreNavigator', {
      screen: 'DigitalWalletPage',
    });
  };

  const orderNewCardHandler = () => {
    if (isPhone || isOpenedAsFullPage) {
      if (deliveryStatus === 'ORDERED') {
        navigation?.navigate('MoreNavigator', {
          screen: 'OrderNewCardStatus',
          params: {
            status: 'success',
            isOpenedAsFullPage: isOpenedAsFullPage,
          },
        });
      } else {
        navigation?.navigate('MoreNavigator', {
          screen: 'OrderNewCardRequest',
          params: {
            isOpenedAsFullPage: isOpenedAsFullPage,
          },
        });
      }
    } else {
      onOpenOrderCardModal && onOpenOrderCardModal();
    }
  };
  type menuItem = {
    title: string;
    handler: () => void;
    isDisabled?: boolean;
    hideArrow?: boolean;
    subtitle?: string;
    isLoading?: boolean;
    testId?: string;
  };
  const allMenuItems = useMemo(
    () =>
      ({
        changePin: {
          title: 'Change PIN',
          handler: changePinCodeHandler,
          isDisabled: isLoadingChangePin || deliveryStatus === 'ORDERED',
          isLoading: isLoadingChangePin,
          testId: 'paychexpay.debitCardMenu.button.changePin',
        },
        digitalWallet: {
          title: 'Digital wallet',
          handler: digitalWalletHandler,
          testId: 'paychexpay.debitCardMenu.button.digitalWallet',
        },
        reportLostCard: {
          title: 'Report card lost or stolen',
          handler: reportCardLostHandler,
          testId: 'paychexpay.debitCardMenu.button.reportLostCard',
        },
        orderNewCard: {
          title:
            deliveryStatus === 'ORDERED'
              ? 'Card shipping status'
              : 'Order new card',
          handler: orderNewCardHandler,
          testId: 'paychexpay.debitCardMenu.button.orderNewCard',
        },
        freezeCard: {
          title: 'Freeze',
          handler: freezeCardHandler,
          hideArrow: true,
          isDisabled:
            cardStatusRequestStatus === 'pending' ||
            deliveryStatus === 'ORDERED',
          testId: 'paychexpay.debitCardMenu.button.freezeCard',
        },
        unfreezeCard: {
          title: 'Unfreeze',
          handler: () => unfreezeCardHandler({ calledFrom: 'debitCardMenu' }),
          hideArrow: true,
          isDisabled: cardStatusRequestStatus === 'pending',
          testId: 'paychexpay.debitCardMenu.button.unfreezeCard',
        },
      } as {
        [key: string]: menuItem;
      }),
    [deliveryStatus, cardStatusRequestStatus, isLoadingChangePin]
  );

  let commonMenuItemsState = [
    allMenuItems.changePin,
    allMenuItems.reportLostCard,
    allMenuItems.orderNewCard,
  ];

  if (Platform.OS !== 'web') {
    commonMenuItemsState = [
      allMenuItems.changePin,
      allMenuItems.digitalWallet,
      allMenuItems.reportLostCard,
      allMenuItems.orderNewCard,
    ];
  }

  const regularMenuItems = [allMenuItems.freezeCard, ...commonMenuItemsState];
  const frozenMenuItems = [allMenuItems.unfreezeCard, ...commonMenuItemsState];

  const menuItems = useMemo(() => {
    switch (cardStatus) {
      case CARD_STATES.regular:
        return regularMenuItems;
      case CARD_STATES.frozen:
        return frozenMenuItems;
      default:
        return regularMenuItems.map((item) => ({ ...item, isDisabled: true }));
    }
  }, [cardStatus, cardStatusRequestStatus, deliveryStatus, isLoadingChangePin]);

  return {
    menuItems,
    cardStatus,
    freezeCardHandler,
    unfreezeCardHandler,
    unfreezeCard,
    cardStatusRequestStatus,
    isFreezeModalOpen,
    setIsFreezeModalOpen,
  } as {
    menuItems: debitCardMenuItem[];
    cardStatus: debitCardStatesType;
    freezeCardHandler: () => void;
    unfreezeCardHandler: (params: { calledFrom: unfreezeParams }) => void;
    unfreezeCard: (params: { calledFrom: unfreezeParams }) => void;
    cardStatusRequestStatus: requestStatusType;
    isFreezeModalOpen: boolean;
    setIsFreezeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  };
};

export default useDebitCardMenu;
