/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
export const mastercardFeaturesNames = {
  identityTheft: 'identity-theft',
  zeroLiability: 'zero-liability',
  airportConcierge: 'airport-concierge',
};

const mastercardFeatures = {
  [mastercardFeaturesNames.identityTheft]: {
    title: 'Mastercard ID Theft Protection™',
    testId: 'paychexpay.mastercardFeatures.text.title.identityTheft',
    link: 'mastercardus.idprotectiononline.com',
    contentAfterLink:
      ' and enter your card number to start the registration process.*\n' +
      '\n' +
      'The benefit: \nMonitors your credit file for changes that could indicate fraud, such as new credit inquiries, an address change, or a new account opened in your name.\n' +
      '\n' +
      'Sends alert notifications whenever suspicious activity is detected so that you can take immediate action to minimize potential damage. The tool monitors suspicious activity related to the personal information you choose to track such as your name, address, email address, social security number, date of birth, debit/credit card numbers, or other sensitive data.\n' +
      '\n' +
      'Provides ID Theft Resolution, with white-glove service, assigning a resolution specialist to you when you require assistance resolving issues related to identity theft.\n' +
      '\n' +
      '*Certain terms, conditions, and exclusions apply. Cardholders need to register for this service. This service is provided by Generali Global Assistance, Inc. Please call 1-800-MASTERCARD for details.',
    text: 'Mastercard ID Theft Protection comes with your Mastercard at no cost. To activate your ID Theft Protection benefits, visit ',
    // howItWorks: {
    //   title: 'How it works',
    //   text:
    //     '• Simply contact 1-800-Mastercard if you believe you have been a victim of Identity Theft.\n' +
    //     '• Services provided are on a 24-hour basis, 365 days a year. In order to receive the following services, you must enroll at https://mastercardus.idprotectiononline.com/. The services include:\n' +
    //     '- Online Monitoring Dashboard (requires activation): The online monitoring dashboard is the primary user interface for cardholders. It serves as a repository of all the personally identifiable information (PII) data the cardholder wants to monitor, tracks and displays cardholders’ risk score, and provides access to identity protection tips. It is also the platform for cardholders to respond to identity monitoring alerts.\n' +
    //     '- Monthly Risk Alert / Newsletter: Cardholders will receive a monthly newsletter with information on the cardholder’s risk score, and articles pertaining to good identity protection practices.\n' +
    //     '- Identity Monitoring: IDT searches the internet to detect compromised credentials and potentially damaging use of your personal information, and alerts you via email so that you can take immediate action. This platform utilizes automated monitoring and human threat intelligence from cyber operations agents monitoring threat actors and their tactics on the deep and dark web where personal data is bought and sold. Data elements that can be monitored are:\n' +
    //     '• Email addresses\n' +
    //     '• Debit/credit cards/prepaid cards\n' +
    //     '• Bank accounts\n' +
    //     '• Web logins; username and password\n' +
    //     '• Medical insurance cards\n' +
    //     '• Drivers’ license\n' +
    //     '• Loyalty cards\n' +
    //     '• Affinity cards\n' +
    //     '• Passport number\n' +
    //     '• Vehicle insurance cards\n' +
    //     '• Social Security number\n' +
    //     '\n' +
    //     'To take advantage of this service, the cardholder must enter the personal information they wish to monitor on the dashboard.\n' +
    //     'Resolution Services: You will have access to a team of identity theft resolution specialists, available 24 hours a day, 365 days a year to help resolve your identity theft incident and prevent further damage. The resolution specialists are native speakers of English, French and Spanish, and are based out of Bethesda, Maryland. Cardholders are given the option to sign limited power of attorney (LPOA) to the specialist, to allow them to conduct resolution activities on the cardholders’ behalf, including contacting police, credit agencies, and other authorities, translating information, and closing and replacing breached accounts.\n' +
    //     'Lost Wallet Assistance: Cardholder’s will be provided assistance with notifying the appropriate issuing authorities to cancel and replace stolen or missing items, such as their debit/credit card, driver’s license, Social Security card, and passport.\n' +
    //     'Single Bureau Credit Monitoring: Cardholders’ TransUnion credit file will be monitored for changes that could indicate fraud such as new credit inquiries, an address change or new credit account(s) opened in their name. An alert notification via email will be sent anytime a hard inquiry is made on the cardholders’ TransUnion credit file so they can take immediate action to minimize damage. To take advantage of this service, the cardholder must enter their Social Security number on the dashboard and pass credit authentication.\n' +
    //     'Financial Account Takeover: IDT monitors cardholder’s high-risk transactions with more than 300 of the nation’s largest companies to uncover and thwart account takeover attempts. Monitored transactions include:\n' +
    //     '- Debit/credit cards/prepaid cards\n' +
    //     '- Bank accounts\n' +
    //     '- Brokerage accounts\n' +
    //     '- Healthcare portals\n' +
    //     '- Workplace intranets\n' +
    //     '- Other services (e.g. peer-to-peer fund transfers)\n' +
    //     '\n' +
    //     'To take advantage of this service, the cardholder must enter the accounts they wish to protect on the dashboard.\n' +
    //     'URL and Domain Monitoring: URL and Domain monitoring allows Cardholder to enter up to 10 domain or URL names related to cardholder’s business. This service will monitor the domain and URL names for any compromised email addresses associated with the domain or URL names and if compromised email addresses are found in a data breach, this service will alert the registered cardholder via email and provide information regarding the specific email address that was breached along with information about the date found and source (provided that this information is available). Does this apply to our program?\n' +
    //     'For more information regarding the services stated above and additional information, please visit https://mastercardus.idprotectiononline.com/.\n' +
    //     'See https://mastercardus.idprotectiononline.com/ for complete program details',
    // },
    // goodToKnow: {
    //   title: 'Good to Know',
    //   text:
    //     '• Upon receipt of a notice of claim, we will provide you with the necessary instructions for filing proof of loss.\n' +
    //     '• Written proof of loss must be submitted to our Administrator within 120 days of the loss or the claim may not be honored. Required documentation may include but is not limited to the following:\n' +
    //     '- Your card statement reflecting the monthly Eligible Cellular Wireless Telephone payments for the month preceding the date the Eligible Cellular Wireless Telephone was Stolen or suffered damage\n' +
    //     '- A copy of your current wireless service provider’s billing statement\n' +
    //     '- If a claim is due to damage, a copy of the repair estimate and photos of the damage\n' +
    //     '-  If the claim is due to theft, a copy of the police report filed within 48 hours of the theft\n' +
    //     '- Any other documentation or information reasonably requested by us to support the claim\n' +
    //     '• See www.mycardbenefits.com for complete program details',
    // },
    // notCovered: {
    //   title: 'Not covered',
    //   text:
    //     '• When it is determined you have committed any dishonest, criminal, malicious or fraudulent act.\n' +
    //     '• When your financial institution or card issuer which provides this Service, has investigated the event and deemed you are responsible for the charge or event.\n' +
    //     '• When any theft or unauthorized use of an account by a person to whom the account has been entrusted has been committed.',
    // },
  },
  [mastercardFeaturesNames.zeroLiability]: {
    title: 'Zero Liability',
    testId: 'paychexpay.mastercardFeatures.text.title.zeroLiability',
    text:
      "Have peace of mind knowing that the financial institution that issued your Mastercard won't hold you responsible for “unauthorized transactions.” As a Mastercard cardholder, Zero Liability applies to your purchases made in the store, over the telephone, online, or via a mobile device and ATM transactions. As a cardholder, you will not be held responsible for unauthorized transactions if:" +
      '\n' +
      '1. You have used reasonable care in protecting your card from loss or theft; and' +
      '\n' +
      '2. You promptly reported loss or theft to your financial institution.\n' +
      '\n' +
      'If you believe there has been unauthorized use of your account and you meet the conditions above, rest easy knowing you have the protection of Mastercard’s Zero Liability promise.',
  },
  [mastercardFeaturesNames.airportConcierge]: {
    title: 'Mastercard Airport Concierge™',
    testId: 'paychexpay.mastercardFeatures.text.title.airportConcierge',
    text: 'Mastercard Airport Concierge™ provides enhanced travel benefits for Mastercard cardholders worldwide. Enjoy exclusive 15% savings on VIP meet and assist services. A substantial range of luxury concierge services make the journey through the airport and beyond as stress-free and relaxing as possible. Whether you want to be met at the plane door and fast-tracked through immigration, need assistance with baggage on departure, or require a chauffeured luxury vehicle upon arrival – these services and more are just a click away at ',
    link: 'mastercard.com/globalairportconcierge.com.',
    contentAfterLink: ' Services are available at over 700 airports worldwide.',
  },
};

export default mastercardFeatures;
