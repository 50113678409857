import { createTypedThunk } from '@utils/createTypedThunk';
import { ISubmitOowQuestionsRequest } from '@store/features/oowQuestions/types';
import oowQuestionsAPI from '@store/features/oowQuestions/api';

const oowQuestionsThunks = {
  postSubmitOwwQuestions: createTypedThunk<ISubmitOowQuestionsRequest, any>(
    'oowQuestions/postSubmitOwwQuestions',
    oowQuestionsAPI.postSubmitOwwQuestions
  ),
};

export default oowQuestionsThunks;
