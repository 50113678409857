import { requestStatusType } from '@store/features/cards/types';
import { SideBarTemplate } from '@templates';
import { Center } from 'native-base';
import {
  CenterModal,
  InsufficientFundsAlertModal,
  InsufficientFundsAlertModalProps,
  SideModal,
  TextInput,
} from '@molecules';
import {
  transfersScreensInfo,
  transfersScreensNames,
} from '@constants/transfersScreensNames';
import { BackButton, TButton, Text } from '@atoms';
import AccountCard from '@molecules/AccountCard/AccountCard';
import amountToString from '@utils/amountToString';
import { formatTransferInput } from '@utils/validateDdaInputs';
import {
  AccountsCategory,
  ConnectAccountContent,
  DeleteAccountContent,
  TransferCompletedContent,
} from '@organisms';
import React, { useEffect, useMemo, useRef } from 'react';
import { IAccountTile } from '@utils/idToAccount';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import { Control } from 'react-hook-form';
import { IConnectAccountFormInputs } from '@organisms/ConnectAccountForm/helper';
import { ICategoryAccount } from '@devTypes/accountTypes';
import { IDeleteAccountFormInputs } from '@organisms/DeleteAccountForm/helper';
import { customTheme } from '../../../styles/customTheme';
import { MAX_TRANSFER_COMMENT_LENGTH } from '@constants/general';
import useIsLaptop from '@hooks/useIsLaptop';
import { ScrollView, Separator, View, XStack, YStack } from 'tamagui';

interface ToExternalBankWebViewProps extends InsufficientFundsAlertModalProps {
  fromAccount: IAccountTile;
  toAccount: IAccountTile;
  isNotExceedBalance: boolean;
  amount: string;
  setAmount: (s: string) => void;
  comment: string;
  setComment: (s: string) => void;
  onPressTransfer: () => void;
  isButtonDisabled: boolean;
  isCenterModalOpen: boolean;
  onCloseCenterModal: () => void;
  isDeleteExternalAccountModalOpen: boolean;
  onCloseDeleteExternalAccountModal: () => void;
  onOpenDeleteExternalAccountModal: () => void;
  transferStatus: requestStatusType | null;
  isCompleted: boolean;
  selectAccountType?: 'To' | 'From';
  accounts: any;
  navigateToSelect?: (selectAccount: 'From' | 'To') => () => void;
  selectAccount: (id: string) => void;
  navigateSelectBack: () => void;
  isModalOpen: boolean;
  modalName: string | undefined;
  toCloseModal: () => void;
  addExternalAccountHandler: () => void;
  deleteExternalAccountHandler: (account: ICategoryAccount) => void;
  control: Control<IConnectAccountFormInputs>;
  deleteAccountControl: Control<IDeleteAccountFormInputs>;
  onSubmit: () => void;
  onDeleteAccount: () => void;
  isAddAccountButtonDisabled: boolean;
  isButtonLoading: boolean;
  isAnimateClose: boolean;
  isBankAcntNameInputDisabled: boolean;
}

const defaultAccountId = '0000';

const ToExternalBankWebView = ({
  fromAccount,
  toAccount,
  isNotExceedBalance,
  amount,
  setAmount,
  comment,
  setComment,
  onPressTransfer,
  isButtonDisabled,
  isCenterModalOpen,
  onCloseCenterModal,
  isDeleteExternalAccountModalOpen,
  onCloseDeleteExternalAccountModal,
  onOpenDeleteExternalAccountModal,
  transferStatus,
  isCompleted,
  selectAccountType,
  accounts,
  selectAccount,
  navigateToSelect,
  navigateSelectBack,
  isModalOpen,
  modalName,
  toCloseModal,
  addExternalAccountHandler,
  deleteExternalAccountHandler,
  control,
  deleteAccountControl,
  onSubmit,
  onDeleteAccount,
  isButtonLoading,
  isAddAccountButtonDisabled,
  isAnimateClose,
  isBankAcntNameInputDisabled,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: ToExternalBankWebViewProps) => {
  const isLaptop = useIsLaptop();
  const amountInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const { amount: limitToTransfer } = fromAccount;

  const externalAccounts = useMemo(() => {
    const spendingExternalAccounts = accounts.spending.accounts.filter(
      (account: ICategoryAccount) => account.isExternal
    );
    const savingExternalAccounts = accounts.saving.accounts.filter(
      (account: ICategoryAccount) => account.isExternal
    );
    return [...spendingExternalAccounts, ...savingExternalAccounts];
  }, [accounts]);

  useEffect(() => {
    // @ts-ignore
    amountInputRef.current?.focus();
  }, []);

  return (
    <>
      <View flexGrow={1}>
        <SideBarTemplate disableScroll={true}>
          <View
            bg="none"
            height={'100%'}
            flexGrow={1}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {selectAccountType ? (
              <YStack
                p="20px"
                borderRadius={12}
                bg="white"
                w="460px"
                justifyContent="space-between"
                h="100%"
                maxHeight={'80%'}
                flexGrow={1}
              >
                <XStack pb={'$6'} pl={'$2'}>
                  <BackButton onPress={navigateSelectBack} />
                  <Text
                    variant="title"
                    textAlign="center"
                    flexGrow={1}
                    paddingRight={32}
                  >
                    {selectAccountType}
                  </Text>
                </XStack>
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  contentContainerStyle={{
                    flexGrow: 1,
                    pb: isLaptop ? 0 : '$5',
                    height: '100%',
                  }}
                >
                  <YStack
                    justifyContent="space-between"
                    h={'100%'}
                    flexGrow={1}
                  >
                    <View>
                      <YStack gap={'$5'}>
                        <AccountsCategory
                          category={{
                            type: 'external',
                            accounts: externalAccounts,
                          }}
                          selectAccount={selectAccount}
                          navigateToDeleteAccount={deleteExternalAccountHandler}
                          isSelect
                        />
                      </YStack>
                    </View>
                  </YStack>
                </ScrollView>
                <View>
                  <Separator my={'$4'} borderColor="$gray20" />
                  <TButton
                    onPress={addExternalAccountHandler}
                    testID="paychexpay.ToExternalBankWebView.button.connectAccountButton"
                  >
                    Add new account
                  </TButton>
                </View>
              </YStack>
            ) : (
              <ScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  justifyContent: 'center',
                  pb: isLaptop ? 0 : 5,
                  height: '100%',
                }}
              >
                <Center py={isLaptop ? 0 : 7}>
                  <YStack
                    p="20px"
                    borderRadius={12}
                    bg="white"
                    w="460px"
                    justifyContent="space-between"
                    h="100%"
                  >
                    <View pb={isLaptop ? '$2' : '$4'}>
                      <Text
                        variant="title"
                        textAlign="center"
                        pb={isLaptop ? '$2' : '$6'}
                      >
                        {
                          transfersScreensInfo[
                            transfersScreensNames.externalAccount
                          ].title
                        }
                      </Text>
                      <AccountCard
                        icon={fromAccount.icon}
                        title={fromAccount.name}
                        label="From"
                        amount={amountToString(fromAccount.amount)}
                        withArrow={false}
                        isDefault={false}
                      />
                      <View marginTop="$4" />
                      <AccountCard
                        icon={toAccount.icon}
                        title={toAccount.name}
                        label="To"
                        amount={amountToString(toAccount.amount)}
                        withArrow={true}
                        isDefault={
                          toAccount.id === defaultAccountId ||
                          toAccount.type === 'external'
                        }
                        isExternal={toAccount.type === 'external'}
                        accountType={toAccount.accountType}
                        onPress={navigateToSelect && navigateToSelect('To')}
                      />
                      <TextInput
                        styles={{ pt: '$4' }}
                        customLabel={
                          <Text
                            color="$gray40"
                            lineHeight="$h2"
                            fontSize="$h5"
                            mb={'$1'}
                          >
                            Available to transfer: ${limitToTransfer.toFixed(2)}
                          </Text>
                        }
                        errorMessage={`You can transfer up to $${limitToTransfer.toFixed(
                          2
                        )}`}
                        isError={isNotExceedBalance && limitToTransfer > 0}
                        isDisabled={limitToTransfer === 0}
                        placeholder="$0.00"
                        args={{
                          ref: amountInputRef,
                          value: amount !== '' ? amount : '',
                          onChangeText: (text: string) => {
                            const validatedValue = formatTransferInput(text);
                            setAmount(validatedValue);
                          },
                          onSubmitEditing: () => {
                            // @ts-ignore
                            commentInputRef.current?.focus();
                          },
                          testID:
                            'paychexpay.transfer.toExternalBankWebView.input.amount',
                        }}
                      />
                      <TextInput
                        styles={{ pt: '$4', pb: isLaptop ? '$2' : '$5' }}
                        label="Note"
                        placeholder="What’s this for?"
                        isError={comment.length > MAX_TRANSFER_COMMENT_LENGTH}
                        errorMessage={`Should contain no more than ${MAX_TRANSFER_COMMENT_LENGTH} digits`}
                        args={{
                          ref: commentInputRef,
                          value: comment,
                          onChangeText: (text: string) => setComment(text),
                          onSubmitEditing: () => {
                            if (!isButtonDisabled) {
                              onPressTransfer();
                            }
                          },
                        }}
                      />
                    </View>
                    <View>
                      <Separator borderColor="$gray20" />
                      <Text
                        p={'$3'}
                        fontSize={'$h5'}
                        color={'$gray50'}
                        textAlign="center"
                      >
                        Funds available in 2-3 days depending on the recipient’s
                        bank processing time.
                      </Text>
                      <TButton
                        disabled={isButtonDisabled}
                        onPress={onPressTransfer}
                        isLoading={transferStatus === 'pending'}
                        variant="darkGray"
                        testID="paychexpay.transfer.ToExternalBankWebView.button.transfer"
                      >
                        Transfer
                      </TButton>
                    </View>
                  </YStack>
                </Center>
              </ScrollView>
            )}
          </View>
        </SideBarTemplate>
        {isCompleted && (
          <CenterModal
            isModalOpen={isCenterModalOpen}
            closeByOutsideClick
            closeModal={onCloseCenterModal}
          >
            <View w="343px" p={'$4'} bg="white" borderRadius={24}>
              <TransferCompletedContent
                transferType={transfersScreensNames.externalAccount}
              />
              <Separator borderColor="$gray20" mb={'$4'} mt={'$5'} />
              <TButton
                onPress={onCloseCenterModal}
                testID="paychex.ToExternalBankWebView.button.okButton"
              >
                Finish
              </TButton>
            </View>
          </CenterModal>
        )}
        <CenterModal
          isModalOpen={isDeleteExternalAccountModalOpen}
          closeByOutsideClick
          closeModal={onCloseDeleteExternalAccountModal}
        >
          <View
            maxWidth={343}
            px={'$4'}
            bg="white"
            borderRadius={12}
            pt={'$6'}
            pb={'$4'}
          >
            <Text textAlign="center" fontSize={16} fontWeight="600" pb={'$2'}>
              Remove account?
            </Text>
            <Text
              fontSize={14}
              fontWeight="400"
              color={customTheme.colors.gray[60]}
              textAlign="center"
              pb={'$6'}
            >
              It will no longer be available through Paychex Pay.
            </Text>
            <Separator borderColor="$gray20" />
            <XStack alignItems={'center'} mt={'$4'}>
              <TButton
                flex={1}
                mr={'$2'}
                variant={'simple'}
                borderColor={'$gray20'}
                borderWidth={1}
                onPress={onCloseDeleteExternalAccountModal}
              >
                Cancel
              </TButton>
              <TButton
                flex={1}
                ml={'$2'}
                onPress={onDeleteAccount}
                isLoading={isButtonLoading}
                backgroundColor={'$red50'}
                borderWidth={0}
                testID="paychexpay.ToExternalBankWebView.button.confirmRemoveButton"
              >
                Remove
              </TButton>
            </XStack>
          </View>
        </CenterModal>
        <SideModal
          isModalOpen={isModalOpen}
          onPressModalClose={toCloseModal}
          isAnimateClose={isAnimateClose}
        >
          <ComponentsListProvider
            componentsName={modalName}
            components={{
              [sideModalNames.addExternalAccount]: (
                <View flexGrow={1}>
                  <Center px={7} py={6}>
                    <Text variant="title">Add new account</Text>
                  </Center>
                  <Separator borderColor="$gray20" />
                  <YStack p={'$7'} justifyContent="space-between" flexGrow={1}>
                    <ConnectAccountContent
                      control={control}
                      isBankAcntNameInputDisabled={isBankAcntNameInputDisabled}
                    />
                    <TButton
                      onPress={onSubmit}
                      disabled={isAddAccountButtonDisabled}
                      isLoading={isButtonLoading}
                      testID="paychexpay.transfer.toExternalBankWebView.button.addAccountButton"
                    >
                      Add account
                    </TButton>
                  </YStack>
                </View>
              ),
              [sideModalNames.deleteExternalAccount]: (
                <View flexGrow={1}>
                  <Center px={7} py={6}>
                    <Text variant="title">Account details</Text>
                  </Center>
                  <Separator borderColor="$gray20" />
                  <YStack p={'$7'} justifyContent="space-between" flexGrow={1}>
                    <DeleteAccountContent control={deleteAccountControl} />
                    <TButton
                      onPress={onOpenDeleteExternalAccountModal}
                      isLoading={isButtonLoading}
                      borderWidth={0}
                      backgroundColor={'$red50'}
                      testID="paychexpay.transfer.toExternalBankWebView.button.remove"
                    >
                      Remove
                    </TButton>
                  </YStack>
                </View>
              ),
            }}
          />
        </SideModal>
      </View>
      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  );
};

export default ToExternalBankWebView;
