import { SelectAccountProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { SelectAccountView } from '@views/Transfers';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectAccountsCategories } from '@store/features/cards/selectors';
import externalBankAccountsThunks from '@store/features/externalBankAccounts/asyncThunks';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from '@store/hooks';
import { ICategoryAccount } from '@devTypes/accountTypes';

const SelectAccountPage = ({ navigation, route }: SelectAccountProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };
  const prevScreen: any = useMemo(() => {
    const routes = navigation.getState().routes;
    return routes[routes.length - 2];
  }, [navigation]);
  const selectAccountType = useMemo(() => route.params.selectAccount, [route]);
  const selectAccount = (id: string) => {
    navigation.navigate('HomeNavigator', {
      screen: prevScreen.name,
      params:
        selectAccountType === 'From'
          ? // @ts-ignore
            { ...prevScreen.params, fromAccountId: id }
          : { ...prevScreen.params, toAccountId: id },
    });
  };

  const navigateToConnectAccount = () => {
    navigation.navigate('ConnectAccount');
  };

  const navigateToDeleteAccount = (account: ICategoryAccount) => {
    navigation.navigate('DeleteAccount', { account });
  };

  const allAccounts = useSelector(selectAccountsCategories);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingAccountId = useSelector(selectSavingsAccountId);

  const filteredAccounts = useMemo(() => {
    const currentAccounts = cloneDeep(allAccounts);

    const filterExternalAccounts = () => {
      currentAccounts.spending.accounts = currentAccounts.spending.accounts.map(
        (acc, index) => ({
          ...acc,
          isDisabled: !!index,
        })
      );
      currentAccounts.saving.accounts = currentAccounts.saving.accounts.map(
        (acc, index) => ({
          ...acc,
          isDisabled: !!index || !savingAccountId,
        })
      );
    };

    const filterPaychexPayAccounts = () => {
      currentAccounts.spending.accounts[0].isDisabled = true;
      if (savingAccountId) {
        currentAccounts.saving.accounts[0].isDisabled = true;
      }
    };

    if (selectAccountType === 'To') {
      if (
        prevScreen?.params?.fromAccountId === spendingAccountId ||
        prevScreen?.params?.fromAccountId === savingAccountId
      ) {
        filterPaychexPayAccounts();
      } else if (prevScreen?.params?.fromAccountId) {
        filterExternalAccounts();
      }
    } else {
      if (
        prevScreen?.params?.toAccountId === spendingAccountId ||
        prevScreen?.params?.toAccountId === savingAccountId
      ) {
        filterPaychexPayAccounts();
      } else if (prevScreen?.params?.toAccountId) {
        filterExternalAccounts();
      }
    }

    // Commented due to issue https://uptech.atlassian.net/browse/BLAZ-1655
    // if (currentAccounts.saving.accounts.length > 2) {
    //   currentAccounts.saving.accounts = currentAccounts.saving.accounts.filter(
    //     (acc) =>
    //       acc.id !==
    //         (prevScreen?.params?.toAccountId ||
    //           (route.params.testEnv && savingAccountId)) &&
    //       acc.id !==
    //         (prevScreen?.params?.fromAccountId ||
    //           (route.params.testEnv && spendingAccountId))
    //   );
    // }
    // if (currentAccounts.spending.accounts.length > 2) {
    //   currentAccounts.spending.accounts =
    //     currentAccounts.spending.accounts.filter(
    //       (acc) =>
    //         acc.id !==
    //           (prevScreen?.params?.toAccountId ||
    //             (route.params.testEnv && spendingAccountId)) &&
    //         acc.id !==
    //           (prevScreen?.params?.fromAccountId ||
    //             (route.params.testEnv && savingAccountId))
    //     );
    // }

    return currentAccounts;
  }, [allAccounts]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(externalBankAccountsThunks.getExternalAccounts());
  }, []);

  return (
    <SelectAccountView
      navigateBack={navigateBack}
      selectAccountType={selectAccountType}
      selectAccount={selectAccount}
      accounts={filteredAccounts}
      navigateToConnectAccount={navigateToConnectAccount}
      navigateToDeleteAccount={navigateToDeleteAccount}
    />
  );
};

export default SelectAccountPage;
