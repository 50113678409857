import { FooterMobileTemplate } from '@templates';
import { IconWithDescription } from '@molecules';
import CheckmarkIcon from '../../../assets/checkmarkIllustration.svg';
import { StatusBar } from 'native-base';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import WebLogoTemplate from '@templates/WebLogoTemplate/WebLogoTemplate';
import { TButton } from '@atoms';
import { View } from 'tamagui';

interface commonPropsInterface {
  buttonHandler: () => void;
}

interface mobilePropsInterface {
  commonProps: commonPropsInterface;
}

interface desktopPropsInterface {
  commonProps: commonPropsInterface;
}

const CIPMessageViewContent = () => {
  return (
    <IconWithDescription
      Icon={CheckmarkIcon}
      description="Help us confirm your identity by answering a few questions."
      title="Verify your identity"
      titlePaddingTop="2"
    />
  );
};

const mobile = ({ commonProps }: mobilePropsInterface) => {
  const { top: topOffset } = useSafeAreaInsets();
  return (
    <FooterMobileTemplate.withOneButton
      buttonLabel="Continue"
      buttonHandler={commonProps.buttonHandler}
      testID="paychexpay.mobile.CIPMessageView.button.continueButton"
    >
      <StatusBar barStyle="dark-content" />
      <View bg="white" flexGrow={1} paddingTop={topOffset} display="flex">
        <View pt="$9" flexGrow={1} display="flex" alignItems="center">
          <CIPMessageViewContent />
        </View>
      </View>
    </FooterMobileTemplate.withOneButton>
  );
};

const desktop = ({ commonProps }: desktopPropsInterface) => {
  return (
    <WebLogoTemplate>
      <CIPMessageViewContent />
      <TButton
        variant="primary"
        width={325}
        marginTop={'$6'}
        onPress={commonProps.buttonHandler}
        testID="paychexpay.desktop.CIPMessageView.button.continueButton"
      >
        Continue
      </TButton>
    </WebLogoTemplate>
  );
};

const CIPMessageView = {
  mobile,
  desktop,
};

export default CIPMessageView;
