import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import { IVariableResponse } from '@store/features/general/types';

const generalAPI = {
  getVariables() {
    return wrappedAxiosRequest<null, IVariableResponse>({
      method: 'get',
      url: endpoints.variables,
    });
  },
};

export default generalAPI;
