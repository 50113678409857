import { BetweenPaychexPayWebProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import BetweenPaychexPayWebView from '../../../components/views/Transfers/BetweenPaychexPayWebView';
import { useSelector } from 'react-redux';
import {
  selectSavingsAccountPreviewInfo,
  selectSpendingAccountPreviewInfo,
} from '@store/features/cards/selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { selectTransferStatus } from '@store/features/transfers/selectors';
import transfersThunks from '@store/features/transfers/asyncThunks';
import { transfersActions } from '@store/features/transfers/slice';
import cardsThunks from '@store/features/cards/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import { MAX_TRANSFER_COMMENT_LENGTH } from '@constants/general';
import transactionThunks from '@store/features/transactions/asyncThunks';
import amountCommaCleaner from '@utils/amountCommaCleaner';
import { useIsFocused } from '@react-navigation/native';
import { tracker } from '../../../eventCollector';
import { application, operations } from '@constants/traceability';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';

const BetweenPaychexPayWebPage = ({
  navigation,
  route,
}: BetweenPaychexPayWebProps) => {
  const isFocused = useIsFocused();
  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const savingsAccount = useSelector(selectSavingsAccountPreviewInfo);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingsAccountId = useSelector(selectSavingsAccountId);

  const [isCenterModalOpen, setIsCenterModalOpen] = useState(false);
  const onOpenCenterModal = () => {
    setIsCenterModalOpen(true);
  };
  const onCloseCenterModal = () => {
    setIsCenterModalOpen(false);
    dispatch(
      transactionThunks.getSpendingTransactions({
        cardId: spendingAccount.id,
        forceRefresh: true,
      })
    );
    if (savingsAccount) {
      dispatch(
        transactionThunks.getSavingsTransactions({
          cardId: savingsAccount.id,
          forceRefresh: true,
        })
      );
    }
    navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
  };

  const [isSwitched, setIsSwitched] = useState(false);
  const onPressSwitch = () => {
    setIsSwitched(!isSwitched);
    setIsInsufficientFundsAlertShown(false);
  };

  const [fromAccount, toAccount] = useMemo(
    () =>
      isSwitched
        ? [savingsAccount, spendingAccount]
        : [spendingAccount, savingsAccount],
    [isSwitched, savingsAccount, spendingAccount]
  );

  const [comment, setComment] = useState('');
  const [amount, setAmount] = useState('');

  const isNotExceedBalance = useMemo(() => {
    return (
      fromAccount.amount <
      (isNaN(parseFloat(amountCommaCleaner(amount)))
        ? 0
        : parseFloat(amountCommaCleaner(amount)))
    );
  }, [amount, route, fromAccount]);

  const isButtonDisabled = useMemo(
    () =>
      comment.length > MAX_TRANSFER_COMMENT_LENGTH ||
      isNotExceedBalance ||
      (isNaN(parseFloat(amountCommaCleaner(amount)))
        ? 0
        : parseFloat(amountCommaCleaner(amount))) === 0,
    [amount, isNotExceedBalance, comment]
  );

  const dispatch = useAppDispatch();
  const transferStatus = useSelector(selectTransferStatus);

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const onPressTransfer = () => {
    dispatch(
      transfersThunks.postTransfer({
        fromAccountId: fromAccount.id,
        toAccountId: toAccount.id,
        amount: parseFloat(amountCommaCleaner(amount)),
        description: comment,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
  };

  const clearTransferData = useCallback(() => {
    setAmount('');
    setComment('');
  }, []);
  useEffect(() => {
    if (transferStatus === 'success' || transferStatus === 'error') {
      navigation.setParams({ isCompleted: transferStatus === 'success' });
      clearTransferData();
      onOpenCenterModal();
      dispatch(transfersActions.resetStatus());
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccount.id }))
        .unwrap()
        .catch((err) => handleRequestError(err));
      dispatch(cardsThunks.getCardInfo({ cardId: savingsAccount.id }))
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
  }, [transferStatus]);

  useEffect(() => {
    // "Account to Account Transfer" CT
    const spendingTimer = tracker.start(
      'Between Paychex Pay Accounts (Web): Fetch Spending Account'
    );
    const savingsTimer = tracker.start(
      'Between Paychex Pay Accounts (Web): Fetch Savings Account'
    );
    const txid = tracker.uuid();
    const spendingBizpn = application.bizpnPrefix + operations.SpendingAccount;
    const savingsBizpn = application.bizpnPrefix + operations.SavingsAccount;
    const spendingComp = application.compPrefix + operations.SpendingAccount;
    const savingsComp = application.compPrefix + operations.SavingsAccount;
    if (savingsAccountId && spendingAccountId) {
      dispatch(
        cardsThunks.getCardInfo({
          cardId: spendingAccount.id,
          additionalHeaders: {
            'x-payx-txid': txid || tracker.uuid(),
            'x-payx-bizpn': spendingBizpn,
            'x-payx-comp': spendingComp,
            'x-payx-subtxnbr': '1',
          },
        })
      )
        .unwrap()
        .then(() => {
          spendingTimer({
            txid: txid,
            bizpn: spendingBizpn,
            subtxnbr: '1',
          });
        })
        .catch((err) => handleRequestError(err));
      dispatch(
        cardsThunks.getCardInfo({
          cardId: savingsAccount.id,
          additionalHeaders: {
            'x-payx-txid': txid || tracker.uuid(),
            'x-payx-bizpn': savingsBizpn,
            'x-payx-comp': savingsComp,
            'x-payx-subtxnbr': '2',
          },
        })
      )
        .unwrap()
        .then(() => {
          savingsTimer({
            txid: txid,
            bizpn: savingsBizpn,
            subtxnbr: '2',
          });
        })
        .catch((err) => handleRequestError(err));
    }
  }, [savingsAccountId, spendingAccountId]);

  const [isInsufficientFundsAlertVisible, setIsInsufficientFundsAlertVisible] =
    useState(false);

  const [isInsufficientFundsAlertShown, setIsInsufficientFundsAlertShown] =
    useState(false);

  useEffect(() => {
    if (
      isFocused &&
      fromAccount.amount === 0 &&
      !isInsufficientFundsAlertShown &&
      savingsAccount.id &&
      spendingAccount.id
    ) {
      setIsInsufficientFundsAlertVisible(true);
      setIsInsufficientFundsAlertShown(true);
    }
  }, [
    isFocused,
    fromAccount,
    isInsufficientFundsAlertShown,
    savingsAccount.id,
    spendingAccount.id,
  ]);

  useEffect(() => {
    if (!isFocused) {
      setIsInsufficientFundsAlertShown(false);
    }
  }, [isFocused]);

  const hideInsufficientFundsAlertModal = () => {
    setIsInsufficientFundsAlertVisible(false);
  };

  return (
    <BetweenPaychexPayWebView
      toAccount={toAccount}
      fromAccount={fromAccount}
      onPressTransfer={onPressTransfer}
      isButtonDisabled={isButtonDisabled}
      onPressSwitch={onPressSwitch}
      comment={comment}
      amount={amount}
      isCenterModalOpen={isCenterModalOpen}
      isFromSavings={fromAccount.id === savingsAccount.id}
      onCloseCenterModal={onCloseCenterModal}
      setComment={setComment}
      setAmount={setAmount}
      transferStatus={transferStatus}
      isCompleted={!!route.params?.isCompleted}
      isNotExceedBalance={isNotExceedBalance}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    />
  );
};

export default BetweenPaychexPayWebPage;
