import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveCardRequestStatus } from '@store/features/cards/selectors';
import { TOAST_POPUP_VISIBILITY_TIME } from '@constants/general';
import { Platform } from 'react-native';
import Toast from 'react-native-toast-message';
import { cardsActions } from '@store/features/cards/slice';
import { useAppDispatch } from '@store/hooks';

const useActiveCardToastPopup = () => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const activeCardRequestStatus = useSelector(selectActiveCardRequestStatus);
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (activeCardRequestStatus === 'success') {
      if (Platform.OS !== 'web') {
        Toast.show({
          type: 'copySuccess',
          text1: 'Activated! Your card is ready to use.',
          visibilityTime: TOAST_POPUP_VISIBILITY_TIME,
        });
        dispatch(cardsActions.resetActiveCardRequestStatus());
      } else {
        setIsOpen(true);
        timeout = setTimeout(() => {
          setIsOpen(false);
          dispatch(cardsActions.resetActiveCardRequestStatus());
        }, TOAST_POPUP_VISIBILITY_TIME);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [activeCardRequestStatus]);

  return { isActiveCardToastPopupOpen: isOpen };
};

export default useActiveCardToastPopup;
