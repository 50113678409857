import React from 'react';
import { TButton, Text } from '@atoms';
import { customTheme } from '../../../styles/customTheme';
import { CenterModal } from '@molecules';
import useLogoutModal from '@hooks/useLogoutModal';
import { Separator, View, XStack } from 'tamagui';

const LogoutModal = () => {
  const {
    isLogoutModalOpen,
    closeLogoutModal,
    onLogoutPress,
    isLogoutButtonLoading,
  } = useLogoutModal();

  return (
    <CenterModal isModalOpen={isLogoutModalOpen} closeModal={closeLogoutModal}>
      <View
        maxWidth={343}
        width={'100%'}
        px={'$4'}
        backgroundColor="white"
        borderRadius={12}
        pt={'$6'}
        pb={'$4'}
      >
        <Text textAlign="center" fontSize={16} fontWeight="600" pb={'$2'}>
          Are you sure?
        </Text>
        <Text
          fontSize={14}
          fontWeight="400"
          color={customTheme.colors.gray[60]}
          textAlign="center"
          pb={'$6'}
        >
          You will need to log in again.
        </Text>
        <Separator borderColor="$gray20" />
        <XStack alignItems={'center'} mt="$4">
          <TButton
            flex={1}
            mr={'$2'}
            variant={'simple'}
            borderColor={'$gray20'}
            borderWidth={1}
            onPress={closeLogoutModal}
          >
            Cancel
          </TButton>
          <TButton
            flex={1}
            ml={'$2'}
            onPress={onLogoutPress}
            isLoading={isLogoutButtonLoading}
            backgroundColor={'$primaryNormal'}
            variant="primary"
            testID="paychexpay.desktop.LogoutModal.button.logout"
          >
            Log out
          </TButton>
        </XStack>
      </View>
    </CenterModal>
  );
};

export default LogoutModal;
