import { ReactNode, useEffect, useRef } from 'react';
import { Animated, Dimensions, Easing, StyleSheet } from 'react-native';
import { ScrollView, View } from 'tamagui';
import { customTheme } from '../../../styles/customTheme';
import CloseIcon from '../../../assets/closeIcon.svg';
import { maxMediaQuery } from '@constants/ui';
import vhCorrection from '@utils/viewportHeightCorrection';
import useIsPhone from '@hooks/useIsPhone';
import { TPressable } from '@atoms';

export interface SideModalWebProps {
  isModalOpen: boolean;
  onPressModalClose: () => void;
  children: ReactNode;
  modalAnimationVariant?: 'sideModal' | 'menuModal';
  isAnimateClose?: boolean;
}

export const SIDE_MODAL_ANIMATION_DURATION = 700;

const SideModal = ({
  children,
  isModalOpen,
  onPressModalClose,
  modalAnimationVariant = 'sideModal',
  isAnimateClose,
}: SideModalWebProps) => {
  const isPhone = useIsPhone();
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const slideAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isModalOpen) {
      fadeIn();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (isAnimateClose) {
      fadeOut();
    }
  }, [isAnimateClose]);

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 0.6,
      duration: SIDE_MODAL_ANIMATION_DURATION,
      useNativeDriver: false,
    }).start();
    Animated.timing(slideAnim, {
      toValue: modalAnimationVariant === 'sideModal' ? -550 : 550,
      duration: SIDE_MODAL_ANIMATION_DURATION,
      delay: 200,
      useNativeDriver: false,
      easing: Easing.bezier(0.22, 1, 0.36, 1),
    }).start();
  };

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      useNativeDriver: false,
      toValue: 0,
      duration: SIDE_MODAL_ANIMATION_DURATION,
    }).start();
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: SIDE_MODAL_ANIMATION_DURATION,
      useNativeDriver: false,
      easing: Easing.bezier(0.22, 1, 0.36, 1),
    }).start();
    setTimeout(() => {
      onPressModalClose();
    }, SIDE_MODAL_ANIMATION_DURATION);
  };
  const onPressClose = () => {
    fadeOut();
  };
  return isModalOpen ? (
    <>
      <Animated.View
        style={[
          styles.back,
          {
            opacity: fadeAnim,
          },
        ]}
      >
        <TPressable
          width="100%"
          height="100%"
          cursor="default"
          onPress={onPressClose}
          testID={'paychexpay.SideModal.closeModal'}
          accessible
          accessibilityLabel={'Close side modal'}
        />
      </Animated.View>
      <Animated.View
        style={[
          styles[modalAnimationVariant],
          {
            transform: [{ translateX: slideAnim }],
          },
        ]}
      >
        <View position="relative" h={vhCorrection('100vh')}>
          {modalAnimationVariant === 'sideModal' ? (
            <>
              <View
                w={40}
                h={40}
                borderRadius="$1"
                bg="$white"
                ml={-60}
                mt={20}
                cursor="pointer"
                onPress={onPressClose}
                display="flex"
                position="absolute"
                left={0}
                justifyContent="center"
                alignItems="center"
                testID="paychexpay.sideModal.button.closeButton"
                accessible
                accessibilityLabel={'Close side modal'}
              >
                <CloseIcon
                  width="24px"
                  height="24px"
                  fill={customTheme.colors.gray[40]}
                />
              </View>
              <ScrollView
                showsVerticalScrollIndicator={!isPhone}
                contentContainerStyle={{ flexGrow: 1 }}
                height={'100%'}
              >
                {children}
              </ScrollView>
            </>
          ) : (
            <>
              <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ flexGrow: 1 }}
                height={'100%'}
              >
                {children}
              </ScrollView>
              <View
                w={40}
                h={40}
                borderRadius="$2"
                backgroundColor="$white"
                top={20}
                cursor="pointer"
                onPress={onPressClose}
                display="flex"
                position="absolute"
                right={-60}
                justifyContent="center"
                alignItems="center"
                testID="paychexpay.sideModal.button.closeButton"
                accessible
                accessibilityLabel={'Close side modal'}
              >
                <CloseIcon
                  width="24px"
                  height="24px"
                  color={customTheme.colors.gray[40]}
                />
              </View>
            </>
          )}
        </View>
      </Animated.View>
    </>
  ) : (
    <></>
  );
};

const sideModalWidth = () => {
  const desktopWidth = Dimensions.get('window').width;

  if (desktopWidth <= maxMediaQuery.tablet) return 330;
  if (desktopWidth <= maxMediaQuery.laptop) return 422;
  else return 480;
};

const styles = StyleSheet.create({
  back: {
    position: 'absolute',
    width: '100%',
    // @ts-ignore
    height: vhCorrection('100vh'),
    backgroundColor: 'black',
    zIndex: 10,
  },
  sideModal: {
    position: 'absolute',
    // @ts-ignore
    width: sideModalWidth(),
    // @ts-ignore
    height: vhCorrection('100vh'),
    backgroundColor: 'white',
    zIndex: 25,
    right: -550,
  },
  menuModal: {
    position: 'absolute',
    // @ts-ignore
    width: 228,
    // @ts-ignore
    height: vhCorrection('100vh'),
    backgroundColor: customTheme.colors.primary.dark,
    zIndex: 25,
    left: -550,
  },
});

export default SideModal;
