import { FaqProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { FaqView } from '@views/More';
import faq from '@constants/faq';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPayBillsUrl } from '@store/features/homeWidgets/selectors';
import homeWidgetsThunks from '@store/features/homeWidgets/asynkThunks';
import { useAppDispatch } from '@store/hooks';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';
import { widgetStateActions } from '@store/features/homeWidgets/slice';
import { Platform } from 'react-native';
import {
  selectDepositAccountAgreement,
  selectFeeAgreement,
} from '@store/features/agreements/selectors';
import { redirectsHelper } from '@utils/redirectsHelper';

const FaqPage = ({ navigation }: FaqProps) => {
  const dispatch = useAppDispatch();

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };

  const payBillsUrl = useSelector(selectPayBillsUrl);
  const spendingCardId = useSelector(selectSpendingAccountId);
  const feeAgreement = useSelector(selectFeeAgreement);
  const depositAgreement = useSelector(selectDepositAccountAgreement);

  const handlePayBillsPress = () => {
    dispatch(homeWidgetsThunks.billPay({ card_id: spendingCardId }));
  };

  useEffect(() => {
    if (payBillsUrl)
      navigation.navigate('HomeNavigator', {
        screen: 'PayBills',
        params: { payBillsUrl: payBillsUrl },
      });

    dispatch(widgetStateActions.resetPayBillsUrl());
  }, [payBillsUrl]);

  const navigationMap = useCallback(
    (link: string) => {
      redirectsHelper({
        navigation,
        link,
        handlePayBillsPress,
        feeAgreement,
        depositAgreement,
      });
    },
    [navigation]
  );
  return (
    <FaqView
      navigateBack={navigateBack}
      faqData={faq}
      navigationMap={navigationMap}
    />
  );
};

export default FaqPage;
