import { MastercardFeaturesProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { MastercardFeaturesView } from '@views/MastercardFeatures';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import useMastercardMenu from '@hooks/useMastercardMenu';
import mastercardFeatures, {
  mastercardFeaturesNames,
} from '@constants/mastercardFeatures';
import { useEffect, useRef, useState } from 'react';
import useIsTablet from '@hooks/useIsTablet';
import useIsLaptop from '@hooks/useIsLaptop';
import { Platform } from 'react-native';
import useIsPhone from '@hooks/useIsPhone';
import routesToTitlesMap from '../../navigation/routesToTitlesMap';

const MastercardFeaturesPage = ({
  navigation,
  route,
}: MastercardFeaturesProps) => {
  const isPhone = useIsPhone();
  const isTablet = useIsTablet();
  const isLaptop = useIsLaptop();

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };
  const { menuItems } = useMastercardMenu({ navigation, route });

  const [currentItem, setCurrentItem] = useState(
    mastercardFeaturesNames.airportConcierge
  );

  const setScreenTitle = () => {
    let title = routesToTitlesMap.AirportConcierge;

    if (route.params?.paragraph) {
      switch (route.params.paragraph) {
        case mastercardFeaturesNames.airportConcierge:
          title = routesToTitlesMap.AirportConcierge;
          break;
        case mastercardFeaturesNames.identityTheft:
          title = routesToTitlesMap.IdentityTheft;
          break;
        case mastercardFeaturesNames.zeroLiability:
          title = routesToTitlesMap.ZeroLiability;
          break;
      }

      navigation.setOptions({
        title: title,
      });
    }
  };

  useEffect(() => {
    if (!isTablet) {
      if (route.params?.paragraph) {
        setCurrentItem(route.params.paragraph);
      } else {
        navigation.setParams({
          paragraph: mastercardFeaturesNames.airportConcierge,
        });
      }
    }

    setScreenTitle();
  }, [route]);

  const scrollRef = useRef();
  useEffect(() => {
    // @ts-ignore
    scrollRef.current?.scrollTo({
      y: 0,
      animated: false,
    });
  }, [currentItem]);

  return (
    <MediaLayoutTemplate
      Mobile={MastercardFeaturesView.mobile}
      Desktop={
        isTablet
          ? MastercardFeaturesView.mobile
          : MastercardFeaturesView.desktop
      }
      navigateBack={navigateBack}
      menuItems={menuItems}
      currentItem={currentItem}
      content={mastercardFeatures}
      scrollRef={scrollRef}
      isLaptop={isLaptop}
      isPhone={isPhone}
    />
  );
};

export default MastercardFeaturesPage;
