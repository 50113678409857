import { OrderNewCardStatusProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { OrderNewCardStatusView } from '@views/More';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { cardsActions } from '@store/features/cards/slice';
import {
  selectPhysicalCardStatus,
  selectShipmentInfo,
} from '@store/features/cards/selectors';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { useAppDispatch } from '@store/hooks';

const OrderNewCardStatusPage = ({
  navigation,
  route,
}: OrderNewCardStatusProps) => {
  const dispatch = useAppDispatch();
  const navigateToCardPage = () => {
    navigation.navigate('DebitCard');
  };
  const orderStatus = useMemo(
    () => (route.params?.status === 'success' ? 'success' : 'error'),
    [route]
  );
  useEffect(() => {
    dispatch(cardsActions.resetOrderCardRequestStatus());
  }, []);
  const shipmentInfo = useSelector(selectShipmentInfo);
  const physicalCardStatus = useSelector(selectPhysicalCardStatus);

  const { copyToClipboardHandler, CopyTextToastPopup } = useCopyToClipboard();
  return (
    <OrderNewCardStatusView
      navigateToCardPage={navigateToCardPage}
      orderStatus={orderStatus}
      physicalCardStatus={physicalCardStatus}
      shipmentInfo={shipmentInfo}
      ToastPopup={CopyTextToastPopup}
      copyToClipboard={copyToClipboardHandler}
    />
  );
};

export default OrderNewCardStatusPage;
