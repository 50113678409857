import { ScrollView, View, YStack } from 'tamagui';
import { ImageWithContent } from '@molecules';
import FlexCredsInfo from '../../../assets/flexCredsInfo.svg';
import useIsPhone from '@hooks/useIsPhone';
import { Text } from '@atoms';
import { getWindowHeightWeb } from '@utils/getWindowHeightWeb';
import { MOBILE_HEADER_HEIGHT } from '@constants/general';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface AboutPFAccountContentProps {
  signUp?: () => void;
}

const AboutPFAccountContent = ({ signUp }: AboutPFAccountContentProps) => {
  const isPhone = useIsPhone();
  const { bottom: bottomInset } = useSafeAreaInsets();

  return (
    <ScrollView
      bounces={false}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingBottom: bottomInset,
      }}
      maxHeight={getWindowHeightWeb() - (isPhone ? MOBILE_HEADER_HEIGHT : 0)}
    >
      <YStack
        px={isPhone ? '$4' : '$7'}
        pt={isPhone ? '$7' : '$8'}
        flexGrow={1}
        justifyContent="space-between"
      >
        <ImageWithContent image={<FlexCredsInfo />} space={'$7'}>
          <Text
            w="94%"
            variant="subtitle"
            accessible
            accessibilityLabel={
              'To sign up for a Paychex Pay Deposit Account and Debit Mastercard, your company will need to allow it. You will also need a Paychex Flex account. '
            }
          >
            To sign up for a Paychex Pay Deposit Account and Debit Mastercard,
            your company will need to allow it. You will also need a Paychex
            Flex account.
          </Text>
          <Text
            variant="subtitle"
            w="94%"
            pt={'$5'}
            accessible
            accessibilityLabel={
              'Beyond that, the accounts will be separate. This means that within Paychex Pay:'
            }
          >
            Beyond that, the accounts will be separate. This means that within
            Paychex Pay:
          </Text>

          <View
            w="94%"
            pt={'$5'}
            paddingHorizontal={15}
            flexDirection="row"
            display={'flex'}
          >
            <Text variant="subtitle" marginRight={5}>
              •
            </Text>
            <View
              flexShrink={1}
              accessible
              accessibilityLabel={
                'Activity is private. Your company can’t see your money or what you do with it.'
              }
            >
              <Text variant="subtitle">Activity is private</Text>
              <Text width={'100%'} variant="subtitle">
                Your company can’t see your money or what you do with it.
              </Text>
            </View>
          </View>

          <View
            w="94%"
            pt={'$5'}
            paddingHorizontal={15}
            flexDirection="row"
            display={'flex'}
          >
            <Text variant="subtitle" marginRight={5}>
              •
            </Text>
            <View
              flexShrink={1}
              accessible
              accessibilityLabel={
                'The account stays yours. Keep this bank account even if you leave your company.'
              }
            >
              <Text variant="subtitle">The account stays yours</Text>
              <Text variant="subtitle">
                Keep this bank account even if you leave your company.
              </Text>
            </View>
          </View>
          <View w="94%" pt={'$5'} display={'flex'} flexDirection="row">
            <Text
              flex={0}
              variant="subtitle"
              textAlign="center"
              pb={'$5'}
              accessible
              accessibilityLabel={'Don’t have a Paychex Flex account?'}
            >
              Don’t have a Paychex Flex account?
            </Text>
            <Text
              cursor={'pointer'}
              flex={1}
              onPress={signUp}
              variant="subtitle"
              textAlign="center"
              pb={'$5'}
              color={'$primaryNormal'}
              accessible
              accessibilityLabel={'Sign up'}
            >
              Sign up
            </Text>
          </View>
        </ImageWithContent>
      </YStack>
    </ScrollView>
  );
};

export default AboutPFAccountContent;
