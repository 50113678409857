import { View, ScrollView } from 'tamagui';
import { Document, Page, pdfjs } from 'react-pdf';
import checkIfScrolledToBottom from '@utils/checkIfScrolledToBottom';
import { useWindowDimensions } from 'react-native';
import { maxMediaQuery } from '@constants/ui';
import useIsPhone from '@hooks/useIsPhone';
import SkeletonView from '@molecules/SkeletonView/SkeletonView';
import { useState } from 'react';
import { Line, ScrollToEndButton, Text } from '@atoms';
import { SKELETON_RENDER_SIZE } from './AgreementsContent.constants';
import {
  MOBILE_HEADER_HEIGHT,
  MOBILE_WEB_HEADER_HEIGHT,
} from '@constants/general';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
interface AgreementsContentProps {
  agreementPdfLink: string;
  setIsAgreeDisabled: (b: boolean) => void;
  setNumberOfPages: (n: number) => void;
  scrollViewRef: any;
  numberOfPages: number;
  onPressScrollArrow: () => void;
  agreementName: string;
  isVisible: boolean;
  showScrollToEndButton?: boolean;
  showContentHeader?: boolean;
  footerWrapperHeight?: number;
}

const AgreementsContent = ({
  agreementPdfLink,
  setNumberOfPages,
  scrollViewRef,
  setIsAgreeDisabled,
  numberOfPages,
  onPressScrollArrow,
  agreementName,
  isVisible,
  showScrollToEndButton = true,
  showContentHeader = true,
  footerWrapperHeight = 0,
}: AgreementsContentProps) => {
  const [isScrollEnabled, setScrollEnabled] = useState(false);
  const { width: desktopWidth, height: desktopHeight } = useWindowDimensions();
  const isPhone = useIsPhone();

  if (!isVisible) return null;

  const scrollContainerHeightOnPhone =
    desktopHeight -
    MOBILE_HEADER_HEIGHT -
    (showScrollToEndButton ? 76 : MOBILE_WEB_HEADER_HEIGHT) -
    (showContentHeader ? 55 : 0) -
    footerWrapperHeight;

  return (
    <View flexDirection={'column'} position={'relative'}>
      {showContentHeader && (
        <View
          marginTop={14}
          marginBottom={10}
          marginHorizontal={16}
          alignSelf={'flex-start'}
          width={'90%'}
        >
          <Text
            variant="semiTitle"
            fontSize={'$h4'}
            textAlign={'left'}
            numberOfLines={3}
          >
            {agreementName}
          </Text>
          <View marginTop={10}>
            <Line />
          </View>
        </View>
      )}
      <ScrollView
        height={
          isPhone
            ? scrollContainerHeightOnPhone
            : showContentHeader
            ? '450px'
            : '505px'
        }
        showsVerticalScrollIndicator={!isPhone}
        ref={scrollViewRef}
        scrollEventThrottle={3000} //to prevent issue with too fast "I agree" press, while onScroll is not finished
        onScroll={(event: any) => {
          const paddingToBottom = 150;
          if (checkIfScrolledToBottom(event, paddingToBottom)) {
            setTimeout(() => setIsAgreeDisabled(false), 1000);
          }
        }}
        scrollEnabled={isScrollEnabled}
        accessible
        accessibilityLabel={'agreement content'}
        testID={'paychexpay.agreementsContent'}
      >
        {agreementPdfLink ? (
          // @ts-ignore
          <Document
            file={agreementPdfLink}
            onLoadSuccess={({ numPages }: { numPages: number }) => {
              setScrollEnabled(true);
              setNumberOfPages(numPages);
            }}
            renderMode="canvas"
            loading={<SkeletonView />}
            noData={<SkeletonView />}
            options={{
              isEvalSupported: false, // keep it as false to fix vulnerability.
            }}
          >
            {numberOfPages > 0 &&
              [...Array(numberOfPages).keys()].map((pageNumber) => (
                // @ts-ignore
                <Page
                  key={pageNumber}
                  pageNumber={pageNumber + 1}
                  width={900}
                  {...(desktopWidth < maxMediaQuery.tablet && {
                    width: desktopWidth,
                  })}
                  {...(desktopWidth < 900 &&
                    desktopWidth > maxMediaQuery.tablet && {
                      width: desktopWidth - 24,
                    })}
                  loading={<SkeletonView />}
                  noData={<SkeletonView />}
                />
              ))}
          </Document>
        ) : (
          [...Array(SKELETON_RENDER_SIZE).keys()].map((pageNumber) => (
            <SkeletonView key={pageNumber} />
          ))
        )}
      </ScrollView>
      {showScrollToEndButton && (
        <ScrollToEndButton
          onPressScrollArrow={onPressScrollArrow}
          isDisabled={!isScrollEnabled}
        />
      )}
    </View>
  );
};

export default AgreementsContent;
