import { ToExternalBankProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import ToExternalBankView from '@views/Transfers/ToExternalBankView';
import { useEffect, useMemo, useState } from 'react';
import idToAccount from '@utils/idToAccount';
import {
  selectSavingsAccountPreviewInfo,
  selectSpendingAccountPreviewInfo,
} from '@store/features/cards/selectors';
import { useSelector } from 'react-redux';
import { selectExternalAccounts } from '@store/features/externalBankAccounts/selectors';
import { useAppDispatch } from '@store/hooks';
import externalBankAccountsThunks from '@store/features/externalBankAccounts/asyncThunks';
import { Platform } from 'react-native';
import { tracker } from '../../../eventCollector';
import { application, operations } from '@constants/traceability';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';

const ToExternalBankPage = ({ navigation, route }: ToExternalBankProps) => {
  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const savingsAccount = useSelector(selectSavingsAccountPreviewInfo);
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const externalAccounts = useSelector(selectExternalAccounts);

  useEffect(() => {
    if (spendingAccountId) {
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccountId }));
    }

    const timer = tracker.start('To External Bank: Fetch External Accounts');
    const txid = tracker.uuid();
    const bizpn = application.bizpnPrefix + operations.ExternalAccount;
    const comp = application.compPrefix + operations.ExternalAccount;
    dispatch(
      externalBankAccountsThunks.getExternalAccounts({
        additionalHeaders: {
          'x-payx-txid': txid || tracker.uuid(),
          'x-payx-bizpn': bizpn,
          'x-payx-comp': comp,
        },
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err))
      .then(() => {
        timer({
          txid: txid,
          bizpn: bizpn,
        });
      });
  }, [spendingAccountId]);

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'Transfers' });
    } else {
      navigation.goBack();
    }
  };

  const { toAccount, isTransferDisabled } = useMemo(() => {
    return {
      toAccount: idToAccount(
        route?.params?.toAccountId,
        spendingAccount,
        savingsAccount,
        externalAccounts
      ),
      isTransferDisabled: !route?.params?.toAccountId,
    };
  }, [route, externalAccounts]);
  const navigateToSelect = (selectAccount: 'From' | 'To') => () => {
    navigation.navigate('HomeNavigator', {
      screen: 'SelectAccount',
      params: { selectAccount },
    });
  };
  const navigateToAmount = () => {
    navigation.navigate('HomeNavigator', {
      screen: 'TransferAmount',
      params: { fromAccountId: spendingAccount.id, toAccountId: toAccount.id },
    });
  };

  const fromAccount = useMemo(
    () =>
      idToAccount(
        spendingAccountId,
        spendingAccount,
        savingsAccount,
        externalAccounts
      ),
    [spendingAccount, savingsAccount, externalAccounts, spendingAccountId]
  );

  const [isInsufficientFundsAlertVisible, setIsInsufficientFundsAlertVisible] =
    useState(false);

  useEffect(() => {
    if (fromAccount.amount === 0 && spendingAccount.id) {
      setIsInsufficientFundsAlertVisible(true);
    }
  }, [fromAccount, spendingAccount.id]);

  const hideInsufficientFundsAlertModal = () => {
    setIsInsufficientFundsAlertVisible(false);
    navigateBack();
  };

  return (
    <ToExternalBankView
      toAccount={toAccount}
      fromAccount={fromAccount}
      navigateBack={navigateBack}
      navigateToAmount={navigateToAmount}
      isTransferDisabled={isTransferDisabled}
      navigateToSelect={navigateToSelect}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    />
  );
};

export default ToExternalBankPage;
