import React from 'react';
import OnboardingSwiper from '@organisms/OnboardingSwiper/OnboardingSwiper';
import { BottomModal, CenterModal, FooterButtonsRow } from '@molecules';
import ONBOARDING_SLIDES from '@constants/onboardingSlides';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { SkipIntroContent } from '@organisms';
import { Text, View } from 'tamagui';

interface OnboardingMobileViewProps {
  isOpenHelpModal: boolean;
  onCloseHelpModal: () => void;
  onPressSkip: () => void;
  onPressNext: () => void;
  index: number;
  setIndex: (index: number) => void;
  isSkipIntroModalOpen: boolean;
  goToNextPage: () => void;
  isFromHelp: boolean;
}

const OnboardingMobileView = ({
  isOpenHelpModal,
  onCloseHelpModal,
  onPressSkip,
  onPressNext,
  index,
  setIndex,
  isSkipIntroModalOpen,
  goToNextPage,
  isFromHelp,
}: OnboardingMobileViewProps) => {
  return (
    <>
      <HeaderAndFooterMobileTemplate
        footerContent={
          <FooterButtonsRow
            firstButton={{
              label: isFromHelp ? 'Skip' : 'Skip intro',
              handler: onPressSkip,
              testID:
                'paychexpay.onboardingMobileView.footerButtonsRow.button.skipIntroButton',
            }}
            secondButton={{
              label: index === 2 ? 'Done' : 'Next',
              handler: onPressNext,
              testID:
                'paychexpay.onboardingMobileView.footerButtonsRow.button.nextButton',
            }}
          />
        }
        avoidKeyboard={false}
        withScrollView
      >
        <OnboardingSwiper index={index} setIndex={setIndex} />
      </HeaderAndFooterMobileTemplate>
      <BottomModal
        isModalOpen={isOpenHelpModal}
        onPressModalClose={onCloseHelpModal}
      >
        <Text fontSize={11}>{ONBOARDING_SLIDES.getPaidEarly.help}</Text>
      </BottomModal>
      <CenterModal
        isModalOpen={isSkipIntroModalOpen}
        closeByOutsideClick
        closeModal={goToNextPage}
      >
        <View flexGrow={1} bg="white" borderRadius={12} overflow="hidden">
          <SkipIntroContent onCloseSkipIntroModal={goToNextPage} />
        </View>
      </CenterModal>
    </>
  );
};

export default OnboardingMobileView;
