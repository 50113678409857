import { useState } from 'react';
import { mockedLocation } from '@constants/markersList';
import Geolocation from 'react-native-geolocation-service';
import { check, PERMISSIONS, request, RESULTS } from 'react-native-permissions';
import { Platform } from 'react-native';

const useCurrentLocation = () => {
  const [currentLocation, setCurrentLocation] = useState(mockedLocation);
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);
  const [isFirstTimeDenied, setIsFirstTimeDenied] = useState(true);
  const [isRequestWithoutResponse, setIsRequestWithoutResponse] =
    useState(false);

  const requestCurrentLocation = () => {
    setIsRequestWithoutResponse(true);
    Geolocation.getCurrentPosition(
      (position) => {
        setCurrentLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          latitudeDelta: mockedLocation.latitudeDelta,
          longitudeDelta: mockedLocation.longitudeDelta,
        });
        setIsRequestWithoutResponse(false);
      },
      (error) => {
        if (!isFirstTimeDenied) {
          if (error.code === 1) {
            setIsPermissionDenied(true);
          }
        } else {
          setIsPermissionDenied(true);
          setIsFirstTimeDenied(false);
        }
      },
      { enableHighAccuracy: true }
    );
  };
  const resetPermission = () => {
    setIsPermissionDenied(false);
  };

  const checkLocationWhenInUsePermission = ({
    onGranted,
    onDenied,
  }: {
    onGranted: () => void;
    onDenied: (value: { isRequestable?: boolean }) => void;
  }) => {
    request(
      Platform.OS === 'ios'
        ? PERMISSIONS.IOS.LOCATION_WHEN_IN_USE
        : PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
    )
      .then((result) => {
        switch (result) {
          case RESULTS.UNAVAILABLE:
            if (Platform.OS === 'android') {
              checkLocationCoarsePermissionAndroid({ onGranted, onDenied });
            } else {
              onDenied({ isRequestable: false });
            }
            break;
          case RESULTS.DENIED:
            if (Platform.OS === 'android') {
              checkLocationCoarsePermissionAndroid({ onGranted, onDenied });
            } else {
              onDenied({ isRequestable: true });
            }
            break;
          case RESULTS.LIMITED:
            if (Platform.OS === 'android') {
              checkLocationCoarsePermissionAndroid({ onGranted, onDenied });
            } else {
              onDenied({ isRequestable: false });
            }
            break;
          case RESULTS.GRANTED:
            onGranted();
            break;
          case RESULTS.BLOCKED:
            if (Platform.OS === 'android') {
              checkLocationCoarsePermissionAndroid({ onGranted, onDenied });
            } else {
              onDenied({ isRequestable: false });
            }
            break;
        }
      })
      .catch(() => {
        onDenied({ isRequestable: false });
      });
  };

  const checkLocationCoarsePermissionAndroid = ({
    onGranted,
    onDenied,
  }: {
    onGranted: () => void;
    onDenied: (value: { isRequestable?: boolean }) => void;
  }) => {
    check(PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION)
      .then((result) => {
        switch (result) {
          case RESULTS.UNAVAILABLE:
            onDenied({ isRequestable: false });
            break;
          case RESULTS.DENIED:
            onDenied({ isRequestable: true });
            break;
          case RESULTS.LIMITED:
            onDenied({ isRequestable: false });
            break;
          case RESULTS.GRANTED:
            onGranted();
            break;
          case RESULTS.BLOCKED:
            onDenied({ isRequestable: false });
            break;
        }
      })
      .catch(() => {
        onDenied({ isRequestable: false });
      });
  };

  return {
    currentLocation,
    requestCurrentLocation,
    isPermissionDenied,
    resetPermission,
    isRequestWithoutResponse,
    checkLocationWhenInUsePermission,
  };
};

export default useCurrentLocation;
