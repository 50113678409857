const RadioConfig = {
  baseStyle: {
    _light: {
      _text: {
        ml: 3,
      },
      borderColor: '$gray30',
      _hover: {
        borderColor: '$gray30',
      },
      _pressed: {
        borderColor: '$gray30',
        _interactionBox: {
          bg: 'rgba(0, 0, 0, 0)',
          p: '3',
        },
      },
      _checked: {
        borderColor: '$gray30',
        _icon: {
          color: 'primary.normal',
        },
        _pressed: {
          borderColor: '$gray30',
          _icon: {
            color: 'primary.normal',
          },
        },
        _hover: {
          borderColor: '$gray30',
          _icon: {
            color: 'primary.normal',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    sizes: 'primary',
  },
  sizes: {
    primary: {
      _icon: {
        size: '12px',
      },
    },
  },
};

export default RadioConfig;
