import MenuArrow from '../../../assets/menuArrow.svg';
import { AccountMenuItemProps } from '@molecules/AccountMenuItem/AccountMenuItem.types';
import useIsPhone from '@hooks/useIsPhone';
import { TouchableOpacity } from 'react-native';
import { Text } from '@atoms';
import { Separator, XStack } from 'tamagui';

const AccountMenuItem = ({
  name,
  handler,
  isRed,
  hideArrow,
  testID,
  ...rest
}: AccountMenuItemProps) => {
  const isPhone = useIsPhone();
  return (
    <TouchableOpacity
      onPress={handler}
      testID={testID}
      {...rest}
      accessible
      accessibilityLabel={`Account menu ${name}`}
    >
      <XStack paddingVertical={12} justifyContent="space-between">
        <Text
          paddingVertical={2}
          fontWeight={isPhone ? '$regular' : '$medium'}
          fontSize={isPhone ? 16 : 14}
          lineHeight={isPhone ? '$h1' : '$h2'}
          color={isRed ? '$red50' : 'black'}
        >
          {name}
        </Text>
        {!isRed && !hideArrow && <MenuArrow />}
      </XStack>
      {isPhone && <Separator borderColor="$gray20" />}
    </TouchableOpacity>
  );
};

export default AccountMenuItem;
