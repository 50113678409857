import { customTheme } from '../../../styles/customTheme';
import SwitchLib from 'react-switch';
import { Platform } from 'react-native';
import { Switch as MobileSwitch } from 'native-base';

interface SwitchProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  small?: boolean;
  isDisabled?: boolean;
  testID?: string;
}

const Switch = ({
  checked,
  onChange,
  small = false,
  isDisabled = false,
  testID,
}: SwitchProps) => {
  return Platform.OS === 'web' ? (
    <SwitchLib
      checked={checked}
      onChange={onChange}
      offColor={customTheme.colors.gray[20]}
      onColor={customTheme.colors.primary.normal}
      handleDiameter={small ? 16 : 20}
      uncheckedIcon={false}
      checkedIcon={false}
      height={small ? 20 : 24}
      width={small ? 36 : 40}
      borderRadius={40}
      disabled={isDisabled}
    />
  ) : (
    <MobileSwitch
      size={small ? 'sm' : 'md'}
      onTrackColor="primary.normal"
      isChecked={checked}
      testID={testID}
      // @ts-ignore
      onToggle={onChange}
      isDisabled={isDisabled}
      accessable
      accessibilityLabel="Switch"
      accessibilityValue={{ text: checked ? 'Enabled' : 'Disabled' }}
    />
  );
};

export default Switch;
