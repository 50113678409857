import { configureStore } from '@reduxjs/toolkit';
import { persistedReducer } from './persistReducer';
import { persistStore } from 'redux-persist';

const preloadedState = {};

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;

export const resetStore = () => setTimeout(() => persistor.purge(), 200);
