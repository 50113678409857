import mastercardFeatures from '@constants/mastercardFeatures';
import { MCFContent } from '@organisms';
import { HeaderMobileTemplate } from '@templates';
import { Linking, Platform } from 'react-native';
import { MobileWebHeader } from '@molecules';
import React from 'react';

interface AirportConciergeViewProps {
  navigateBack: () => void;
  content: typeof mastercardFeatures.airportConcierge;
}

const AirportConciergeView = ({
  navigateBack,
  content,
}: AirportConciergeViewProps) => {
  const onPressLink = () => {
    Linking.openURL('https://mastercard.globalairportconcierge.com');
  };
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}
      <HeaderMobileTemplate
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: content.title,
        }}
      >
        <MCFContent content={content} onPressLink={onPressLink} />
      </HeaderMobileTemplate>
    </>
  );
};

export default AirportConciergeView;
