import { WelcomeToPaychexPayView } from '@views/Onboarding';
import useIsPhone from '@hooks/useIsPhone';
import { WelcomeToPaychexPayPageProps } from '@navigation/OnboardingNavigator/OnboardingNavigator.types';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useSelector } from 'react-redux';
import { selectSpendingCardPreviewInfo } from '@store/features/cards/selectors';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
import emptyCard from '@constants/emptyCard';
import { useEffect } from 'react';
import { useAppDispatch } from '@store/hooks';

const WelcomeToPaychexPayPage = ({
  navigation,
  route,
}: WelcomeToPaychexPayPageProps) => {
  const isPhone = useIsPhone();
  const dispatch = useAppDispatch();

  const spendingAccountId = useSelector(selectSpendingAccountId);

  useEffect(() => {
    if (spendingAccountId) {
      dispatch(
        cardsThunks.getCardInfo({
          cardId: spendingAccountId,
        })
      );
    }
  }, []);

  const moveToNextPage = () => {
    if (isPhone) {
      navigation.navigate('OnboardingPage');
    } else {
      navigation.navigate('BottomTabsNavigator', {
        screen: 'HomePage',
        params: { onboardingModal: 'true' },
      });
    }
  };

  const { isModalOpen, modalName, toOpenModal, toCloseModal } = useSideModal({
    route,
    navigation,
  });

  const hintHandler = () => {
    if (isPhone) navigation.navigate('HowToUseCardPage');
    else toOpenModal(sideModalNames.howToUseDigitalCard)();
  };

  const spendingCardInfo = useSelector(selectSpendingCardPreviewInfo);

  return (
    <MediaLayoutTemplate
      Mobile={WelcomeToPaychexPayView.mobile}
      Desktop={WelcomeToPaychexPayView.desktop}
      cardInfo={spendingCardInfo || emptyCard}
      hintHandler={hintHandler}
      buttonHandler={moveToNextPage}
      toCloseModal={toCloseModal}
      isModalOpen={isModalOpen}
      modalName={modalName}
    />
  );
};

export default WelcomeToPaychexPayPage;
