import React, { FC } from 'react';
import { customTheme } from '../../../styles/customTheme';
import { SvgProps } from 'react-native-svg';
import { Pressable, Text } from '@atoms';
import { accessibleHitSlop } from '@constants/ui';
import { XStack } from 'tamagui';

interface MenuItemInterface {
  Icon: FC<SvgProps>;
  title: string;
  onPress: () => void;
  textColor?: string;
  iconColorInHex?: string;
  withBorder?: boolean;
  testID?: string;
}

const MenuItem = ({
  Icon,
  title,
  onPress,
  textColor = 'black',
  iconColorInHex = customTheme.colors.gray['30'],
  withBorder = true,
  testID,
}: MenuItemInterface) => {
  return (
    <Pressable onPress={onPress} testID={testID}>
      <XStack
        paddingVertical={20}
        borderBottomWidth={withBorder ? 1 : 0}
        borderBottomColor="$gray20"
      >
        <Icon fill={iconColorInHex} hitSlop={accessibleHitSlop} />
        <Text
          color={textColor}
          fontSize="$h3"
          paddingLeft={16}
          accessible
          accessibilityLabel={`Menu ${title}`}
        >
          {title}
        </Text>
      </XStack>
    </Pressable>
  );
};

export default MenuItem;
