// @ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import { pipeline } from '../../datalayer';
import { tracker } from '../../eventCollector';
import { AxiosError } from 'axios';
import { IUser } from '@hooks/useCustomAuth0/types';
import jwt from 'jwt-decode';

type MethodsType = 'get' | 'post' | 'put' | 'delete';

export interface AxiosRequestConfig<T> {
  url?: string;
  method?: MethodsType;
  data?: T | null;
  params?: Record<string, any>;
  headers?: any;
  responseType?: any;
}

export interface IAxiosError {
  message: unknown;
  status: number | undefined;
  headers?: any;
}

export type IAxiosResponse<T> =
  | { error: IAxiosError; data: null }
  | { data: T; error: null };

const wrappedAxiosRequest = async <ReqType, RespType>({
  url,
  method,
  data = null,
  params = {},
  responseType,
  headers = {},
}: AxiosRequestConfig<ReqType>) => {
  try {
    const accessToken = await getAccessToken();
    let flexId = 'unk';
    try {
      const user: IUser = jwt(accessToken);
      flexId = user['http://payx:com/sub'].split('|')[1].trim();
    } catch (e) {
      // Failed to obtain userId, continue with userId 'unk'
    }
    tracker.context({ user: flexId });

    const req_headers = {
      Authorization: 'Bearer ' + accessToken,
      'content-type': 'application/json',
      'x-payx-cnsmr': 'PaychexPay',
      'x-payx-user-untrusted': flexId,
      'Cache-Control': 'no-cache',
      ...headers,
    };

    const request = pipeline.createRequest(
      {
        method,
        path: url,
        headers: req_headers,
        responseType,
        ignore: {
          unwrap: true,
          tracking: true,
        },
      },
      params,
      data
    );
    const response = await pipeline.rest(request);

    const responseObj: IAxiosResponse<RespType> = {
      data: response.data,
      error: null,
    };
    return responseObj;
  } catch (error) {
    const handledError = errorHandler(error);
    const errorObj: IAxiosResponse<RespType> = {
      error: handledError,
      data: null,
    };
    return errorObj;
  }
};

const errorHandler = (error: any) => {
  const axiosError = error as AxiosError;
  if (typeof error === 'string') {
    return {
      message: error,
      status: 401,
    };
  }
  return {
    message: axiosError.response?.data,
    status: axiosError.response?.status,
    headers: axiosError.response?.headers,
  };
};

export { wrappedAxiosRequest };
