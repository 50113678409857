import { Text } from '@atoms';
import { RadioGroup, SizeTokens, XStack } from 'tamagui';

interface RadioGroupItemWithLabelProps {
  size: SizeTokens;
  value: string;
  label: string;
  testID: string;
  titleStyles?: any;
}

const RadioGroupItemWithLabel = ({
  size,
  label,
  value,
  testID,
  titleStyles = {},
}: RadioGroupItemWithLabelProps) => {
  const id = `radiogroup-${label}`;
  return (
    <XStack width={300} alignItems="center" gap="$4">
      <RadioGroup.Item
        value={value}
        id={id}
        size={size}
        testID={testID}
        borderColor="$gray30"
        borderWidth={2}
        hoverStyle={{
          backgroundColor: '$blue10',
        }}
        cursor={'pointer'}
      >
        <RadioGroup.Indicator borderWidth={6} borderColor="$primaryNormal" />
      </RadioGroup.Item>

      <Text {...titleStyles}>{label}</Text>
    </XStack>
  );
};

export default RadioGroupItemWithLabel;
