import { wrappedAxiosRequest } from '@store/api';
import {
  INotificationsList,
  INotificationsResponse,
  ISingleNotificationOption,
} from '@store/features/notifications/types';
import endpoints from '@constants/endpoints';
import { application, operations } from '@constants/traceability';

const notificationsAPI = {
  getNotifications() {
    return wrappedAxiosRequest<null, INotificationsList>({
      method: 'get',
      url: endpoints.notifications,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GetNotifications,
        'x-payx-comp': application.compPrefix + operations.GetNotifications,
      },
    });
  },
  postNotifications(body: INotificationsList | ISingleNotificationOption) {
    return wrappedAxiosRequest<
      INotificationsList | ISingleNotificationOption,
      INotificationsResponse
    >({
      method: 'post',
      url: endpoints.notifications,
      data: body,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.UpdateNotifications,
        'x-payx-comp': application.compPrefix + operations.UpdateNotifications,
      },
    });
  },
};

export default notificationsAPI;
