import { View } from 'tamagui';
import { SignInMobile } from '@organisms';
import { FooterMobileTemplate } from '@templates';
import { TButton, Text } from '@atoms';
import LoaderPaychex from '@atoms/LoaderPaychex/LoaderPaychex';
import { PAYCHEX_PAY_DEPOSIT_ACCOUNT } from '@constants/policies';
import { Dimensions } from 'react-native';

interface SignInViewMobileProps {
  onPressBiometrics: () => void;
  onPressHelp: () => void;
  onSignInPress: () => void;
  biometricsIcon: JSX.Element | Array<JSX.Element> | null;
  isBiometricPending: boolean;
  isSignInButtonDisabled: boolean;
  isLoading: boolean;
}

const mobile = ({
  biometricsIcon,
  onPressBiometrics,
  onSignInPress,
  onPressHelp,
  isSignInButtonDisabled,
  isBiometricPending = false,
  isLoading = false,
}: SignInViewMobileProps) => {
  return (
    <>
      {(isLoading || isBiometricPending) && (
        <View
          position={'absolute'}
          top={0}
          left={0}
          bottom={0}
          right={0}
          zIndex={10}
          backgroundColor={'rgba(0, 0, 0 , 0.25)'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <LoaderPaychex />
        </View>
      )}
      <FooterMobileTemplate.default
        footerBgColor="$primaryNormal"
        borderTopColor="$primaryLight"
        FooterContent={
          <View>
            <View
              display="flex"
              flexDirection="row"
              justifyContent="center"
              px={biometricsIcon ? '$6' : 0}
              maxWidth={Dimensions.get('screen').width - 24}
            >
              {biometricsIcon && (
                <TButton
                  onPress={onPressBiometrics}
                  variant="white"
                  mr="$4"
                  maxWidth={48}
                >
                  {biometricsIcon}
                </TButton>
              )}

              <TButton
                onPress={onSignInPress}
                disabled={isSignInButtonDisabled}
                variant="white"
                flexGrow={1}
                fontWeight="$medium"
                testID="paychexpay.mobile.signInView.button.clickSigninButton"
              >
                Sign in
              </TButton>
            </View>
            <Text
              fontWeight="500"
              fontSize={10}
              lineHeight={12}
              color="white"
              textAlign="center"
              marginTop={22}
            >
              {PAYCHEX_PAY_DEPOSIT_ACCOUNT}
            </Text>
          </View>
        }
      >
        <SignInMobile onPressHelp={onPressHelp} />
      </FooterMobileTemplate.default>
    </>
  );
};

const SignInView = {
  mobile,
};

export default SignInView;
