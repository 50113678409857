export interface ILoginInfo {
  email: string;
  accessToken: null | string;
  authorizeUrl: null | string;
  redirectUri: null | string;
}

export interface INewTransactionResponse {
  state: string;
  verifier: string;
  defaults: { [p: string]: any };
}

export interface IParameters {
  scope: string;
  connection: string;
  audience: string;
  state?: string;
  invitationUrl?: string;
  invitation?: string | string[];
  organization?: string | string[];
}

export interface INewNavState {
  url: string;
}

export interface IUser {
  'http://payx:com/sub': string;
  'http://payx:com/cpid': string;
  'http://payx:com/mxid': string;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
}

export const NO_USER_MATCH = 'NO_USER_MATCH';
