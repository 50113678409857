import { ReactNode, useEffect } from 'react';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from './accessTokenRenewCheck';
// @ts-ignore
import { getAccessToken } from './accessTokenHelper';

const AccessTokenProvider = ({
  currentRouteName,
  children,
}: {
  currentRouteName: string | undefined;
  children: ReactNode;
}) => {
  const { accessTokenRenew } = useAuth0();

  useEffect(() => {
    if (currentRouteName) {
      (async function () {
        try {
          const accessToken = await getAccessToken();

          const shouldBeRefreshed = await accessTokenRenewCheck(accessToken);
          if (shouldBeRefreshed) {
            accessTokenRenew();
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      })();
    }
  }, [currentRouteName]);

  return <>{children}</>;
};

export default AccessTokenProvider;
