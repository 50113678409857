import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const authSelect = (state: RootState) => state.navigationStates;

export const selectIsAuthenticated = createSelector(
  authSelect,
  (state) => state.isAuthenticated
);

export const selectIsAuthorizedCardholder = createSelector(
  authSelect,
  (state) => state.isAuthorizedCardholder
);

export const selectIsNavigationHistory = createSelector(
  authSelect,
  (state) => !!state.navigationHistory
);

export const selectLastScreenUrl = createSelector(
  authSelect,
  (state) => state.lastScreenUrl
);

export const selectIsLogoutModalOpen = createSelector(
  authSelect,
  (state) => state.isLogoutModalOpen
);

export const selectInitialUrl = createSelector(
  authSelect,
  (state) => state.initialUrl
);

export const selectCurrentRouteTitle = createSelector(
  authSelect,
  (state) => state.currentRouteTitle
);
