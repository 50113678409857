import { Center } from 'native-base';
import SkipIntroImage from '../../../assets/skipIntro.svg';
import { Button, Text } from '@atoms';
import { Separator, YStack } from 'tamagui';

export interface SkipIntroContentProps {
  onCloseSkipIntroModal: () => void;
}

const SkipIntroContent = ({ onCloseSkipIntroModal }: SkipIntroContentProps) => {
  return (
    <YStack p={'$4'} flexGrow={1}>
      <Text
        variant="title"
        textAlign="center"
        pt={'$2'}
        accessible
        accessibilityLabel={'Learn how to use PaychexPay features at any time!'}
      >
        Learn how to use PaychexPay{'\n'} features at any time!
      </Text>
      <Center>
        <SkipIntroImage />
      </Center>
      <Text
        variant="subtitle"
        textAlign="center"
        pb={2}
        accessible
        accessibilityLabel={'You can find this section in Help'}
      >
        You can find this section in <Text fontWeight="600">Help</Text>
      </Text>
      <Separator my={'$4'} borderColor="$gray20" borderWidth={0.5} />
      <Button
        onPress={onCloseSkipIntroModal}
        testID="paychexpay.skipIntroContent.button.gotItButtonSkipIntro"
        accessible
        accessibilityLabel="Got it, button"
      >
        Got it
      </Button>
    </YStack>
  );
};

export default SkipIntroContent;
