import { ITransaction } from '@store/features/transactions/types';
import { IAccountPreview } from '@devTypes/accountTypes';
import amountToString from '@utils/amountToString';
import { stripHtml } from 'string-strip-html';

export const accountsNames = {
  spending: 'Spending Account',
  savings: 'Savings Account',
};

export const transactionToCategoryName = (
  transaction: ITransaction | undefined
) => {
  const MCC = transaction?.merchant?.mcc || 0;

  if (MCC >= 1 && MCC <= 1499) {
    // 0001–1499: Agricultural services
    return 'Agriculture';
  } else if (MCC >= 1500 && MCC <= 2999) {
    // 1500–2999: Contracted services
    return 'Contractor services';
  } else if (MCC >= 4000 && MCC <= 4799) {
    // 4000–4799: Transportation services
    return 'Transportation';
  } else if (MCC >= 4800 && MCC <= 4999) {
    // 4800–4999: Utility services
    return 'Utilities';
  } else if (MCC >= 5000 && MCC <= 5599) {
    // 5000–5599: Retail outlet services
    return 'Retail';
  } else if (MCC >= 5600 && MCC <= 5699) {
    // 5600–5699: Clothing stores
    return 'Clothing';
  } else if (MCC >= 5700 && MCC <= 7299) {
    // 5700–7299: Miscellaneous stores
    return 'Miscellaneous';
  } else if (MCC >= 7300 && MCC <= 7999) {
    // 7300–7999: Business services
    return 'Business';
  } else if (MCC >= 8000 && MCC <= 8999) {
    // 8000–8999: Professional services and membership organizations
    return 'Professional services and memberships';
  } else if (MCC >= 9000 && MCC <= 9999) {
    // 9000–9999: Government services
    return 'Government';
  } else {
    return 'Miscellaneous';
  }
};

export const transactionToTitle = (
  transaction: ITransaction | undefined,
  accountIdsToNames: { [key: string]: string }
) => {
  if (!transaction) return '';
  if (transactionToIsMerchant(transaction)) {
    return stripHtml(
      (transaction.merchant.merchant_name || '')?.replace(/\s+/g, ' ')
    ).result;
  } else {
    return stripHtml(
      (accountIdsToNames[transaction.card_id] || 'Unknown account').replace(
        /\s+/g,
        ' '
      )
    ).result;
  }
};

export const transactionToCategory = (
  transaction: ITransaction | undefined,
  accountIdsToNames: { [key: string]: string }
) => {
  if (!transaction) return '';
  if (transaction.state !== 'COMPLETE') {
    if (transaction.state === 'PENDING' || transaction.state === 'INPROGRESS') {
      return 'Pending';
    } else {
      return transactionToStatus(transaction);
    }
  }
  if (transactionToIsMerchant(transaction)) {
    return transaction.merchant.mcc_group;
  } else {
    const extAccountName =
      accountIdsToNames[transaction.card_id] === accountsNames.spending
        ? accountsNames.savings
        : accountsNames.spending;
    if (transaction.transaction_type === 'P2P_SELF_CREDIT') {
      return 'From: ' + extAccountName;
    }
    if (transaction.transaction_type === 'P2P_SELF_DEBIT') {
      return 'To: ' + extAccountName;
    }
    return transaction.additional_data.transaction_details;
  }
};

export const transactionToAmount = (transaction: ITransaction | undefined) => {
  if (!transaction) return '';
  return amountToString(transaction.amount);
};

export const transactionToIsIncome = (
  transaction: ITransaction | undefined
) => {
  if (!transaction) return false;
  return transaction.amount > 0;
};

export const transactionToIsMerchant = (
  transaction: ITransaction | undefined
) => {
  if (!transaction) return false;
  return !!Object.keys(transaction.merchant).length;
};

export const transactionToStatus = (transaction: ITransaction | undefined) => {
  if (!transaction) return '';
  const transactionState =
    transaction.state === 'INPROGRESS' ? 'IN PROGRESS' : transaction.state;
  return transactionState[0] + transactionState.toLowerCase().slice(1);
};

export const transactionToPaymentMethod = (
  transaction: ITransaction | undefined,
  spendingAccount: IAccountPreview,
  savingsAccount: IAccountPreview
) => {
  if (!transaction) return '';
  if (spendingAccount.id === transaction.card_id) {
    return `PaychexPay debit card (**** ${spendingAccount.lastFour})`;
  } else {
    return `PaychexPay Saving (****  ${savingsAccount.lastFour})`;
  }
};
