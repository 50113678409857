import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { CashBackIntroProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import CashBackIntroView from '@views/Widgets/CashBackIntroView';
import useRewardsWindow from '@hooks/useRewardsWindow';
import { Platform } from 'react-native';

const CashBackIntroPage = ({ navigation }: CashBackIntroProps) => {
  const onPressBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };

  const { handleRewardsButtonPress, isRedirectToRewardsLoading } =
    useRewardsWindow({ navigation });

  return (
    <MediaLayoutTemplate
      Mobile={CashBackIntroView.mobile}
      Desktop={CashBackIntroView.desktop}
      onPressBack={onPressBack}
      mainButtonHandler={handleRewardsButtonPress}
      isRedirectToRewardsLoading={isRedirectToRewardsLoading}
    />
  );
};

export default CashBackIntroPage;
