import { TextInput } from '@molecules';
import { Pressable, TPressable, Text } from '@atoms';
import InfoIcon from '../../../assets/InfoIconFilled.svg';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  dateOnChange,
  IFillInDataSecondFormInputs,
  ssnOnChange,
  stateOnChange,
  zipCodeOnChange,
} from '@organisms/FillInDataSecondForm/helper';
import LockIcon from '../../../assets/lockIcon.svg';
import CalendarIcon from '../../../assets/calendarIcon.svg';
import { customTheme } from '../../../styles/customTheme';
import { IAddressSuggestion } from '@store/features/fillInData/types';
import React, { useEffect, useRef, useState } from 'react';
import useIsPhone from '@hooks/useIsPhone';
import { useSelector } from 'react-redux';
import {
  selectIsDateLocked,
  selectIsSsnLocked,
} from '@store/features/fillInData/selectors';
import EyeHideIcon from '../../../assets/eyeHide.svg';
import EyeShowIcon from '../../../assets/eyeShow.svg';
import { Platform } from 'react-native';
import { ScrollView, Separator, View, XStack } from 'tamagui';

interface FillInDataSecondFormProps {
  control: Control<IFillInDataSecondFormInputs>;
  onSubmit: () => void;
  errors: FieldErrors;
  isEditAddrPage?: boolean;
  isFormDirty?: boolean;
  onPressSsn?: () => void;
  onPressDob?: () => void;
  withAdditionalFields?: boolean;
  addressSuggestions: IAddressSuggestion[];
  requestAddressSuggestions: (text: string) => void;
  addressSuggestionHandler: (address: IAddressSuggestion) => void;
  navigateToCustomerService?: () => void;
}

const FillInDataSecondForm = ({
  control,
  onSubmit,
  errors,
  isEditAddrPage,
  isFormDirty,
  onPressSsn,
  onPressDob,
  withAdditionalFields = true,
  addressSuggestions,
  requestAddressSuggestions,
  addressSuggestionHandler,
  navigateToCustomerService,
}: FillInDataSecondFormProps) => {
  const isPhone = useIsPhone();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [addressLineValue, setAddressLineValue] = useState('');

  const addressInputRef = useRef<any>(null);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      requestAddressSuggestions(addressLineValue);
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [addressLineValue]);
  const onPressAddressSuggestion = (address: IAddressSuggestion) => () => {
    addressSuggestionHandler(address);
    addressInputRef?.current?.blur();
    setIsDropdownOpen(false);
  };
  const isDateDisabled = useSelector(selectIsDateLocked);
  const isNumberDisabled = useSelector(selectIsSsnLocked);

  const [isSsnVisible, setIsSsnVisible] = useState(true);
  const ssnHideHandler = () => {
    setIsSsnVisible(!isSsnVisible);
  };

  const line2InputRef = useRef(null);
  const zipInputRef = useRef(null);
  const cityInputRef = useRef(null);
  const stateInputRef = useRef(null);
  const dateInputRef = useRef(null);
  const numberInputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      addressInputRef.current?.focus();
    }, 1000);
  }, []);

  return (
    <View display="flex" flexGrow={1} zIndex={5} gap={2}>
      <View position="relative" zIndex={5} mb="$4">
        <Controller
          name="line1"
          control={control}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <TextInput
              label="Address line 1"
              args={{
                onChangeText: (text: any) => {
                  setAddressLineValue(text);
                  onChange(text);
                },
                onBlur: () => {
                  setTimeout(() => {
                    setIsDropdownOpen(false);
                  }, 200);

                  onBlur();
                },
                returnKeyType: 'next',
                onSubmitEditing: () => {
                  setIsDropdownOpen(false);
                  setTimeout(() => {
                    // @ts-ignore
                    line2InputRef.current?.focus();
                  }, 200);
                },
                blurOnSubmit: false,
                value,
                testID: 'paychexpay.fillInDataSecondForm.input.line1Input',
                onFocus: () => {
                  setIsDropdownOpen(true);
                },
                ref: addressInputRef,
                accessible: true,
                accessibilityLabel: 'Enter your address',
              }}
              isError={!!error}
              errorMessage={error?.message}
            />
          )}
        />
        {isDropdownOpen && !isPhone && (
          <ScrollView
            bg="white"
            position="absolute"
            paddingHorizontal={'$4'}
            borderColor="$gray20"
            borderRadius={8}
            borderWidth={1}
            left={0}
            top="100%"
            mt={'$2'}
            height={
              addressSuggestions?.length ? (isEditAddrPage ? 445 : 345) : 40
            }
            scrollEnabled={!!addressSuggestions?.length}
            width={325}
            showsVerticalScrollIndicator={false}
            zIndex={102}
          >
            {addressSuggestions?.length ? (
              addressSuggestions.map((suggestion, index) => {
                return (
                  <Pressable
                    key={index}
                    onPress={onPressAddressSuggestion(suggestion)}
                    testID="paychexpay.fillInDataSecondForm.button.onPressAddressSuggestion"
                  >
                    <Text paddingTop={10}>{suggestion.addressLine1}</Text>
                    <Text variant="caption" paddingBottom={10}>
                      {suggestion.city +
                        ', ' +
                        suggestion.countrySubdivision +
                        ' ' +
                        suggestion.postalCode +
                        ', USA'}
                    </Text>
                    <Separator borderWidth={1} borderColor="$gray20" />
                  </Pressable>
                );
              })
            ) : (
              <Text variant="subtitle" paddingVertical={10} px={'$4'}>
                Not found
              </Text>
            )}
          </ScrollView>
        )}
      </View>
      {isDropdownOpen && isPhone ? (
        <View flexGrow={1} bg="white">
          {addressSuggestions?.length ? (
            addressSuggestions.map((suggestion, index) => {
              return (
                <Pressable
                  key={index}
                  onPress={onPressAddressSuggestion(suggestion)}
                  testID="paychexpay.fillInDataSecondForm.button.onPressAddressSuggestion"
                >
                  <Text paddingTop={10}>{suggestion.addressLine1}</Text>
                  <Text variant="caption" paddingBottom={10} paddingTop={5}>
                    {suggestion.city +
                      ', ' +
                      suggestion.countrySubdivision +
                      ' ' +
                      suggestion.postalCode +
                      ', USA'}
                  </Text>
                  <Separator borderColor="$gray20" />
                </Pressable>
              );
            })
          ) : (
            <Text variant="subtitle" paddingVertical={10} px={'$'}>
              Not found
            </Text>
          )}
        </View>
      ) : (
        <>
          <Controller
            name="line2"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <TextInput
                label="Address line 2 (optional)"
                styles={{ marginBottom: 4, marginTop: 4 }}
                args={{
                  ref: line2InputRef,
                  onChangeText: onChange,
                  onBlur,
                  value,
                  returnKeyType: 'next',
                  onSubmitEditing: () => {
                    // @ts-ignore
                    zipInputRef.current?.focus();
                  },
                  blurOnSubmit: false,
                  testID: 'paychexpay.fillInDataSecondForm.input.line2Input',
                  accessible: true,
                  accessibilityLabel: 'Enter your address',
                }}
                isError={!!error}
                errorMessage={error?.message}
              />
            )}
          />
          <XStack my={'$4'} justifyContent="space-between">
            <Controller
              name="zipCode"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <TextInput
                  label="ZIP Code"
                  styles={{ width: '50%', paddingRight: 16 }}
                  args={{
                    ref: zipInputRef,
                    onChangeText: (value: string) => {
                      onChange(zipCodeOnChange(value));
                    },
                    onBlur,
                    value,
                    returnKeyType: 'next',
                    onSubmitEditing: () => {
                      // @ts-ignore
                      cityInputRef.current?.focus();
                    },
                    blurOnSubmit: false,
                    keyboardType: 'number-pad',
                    testID: 'paychexpay.fillInDataSecondForm.input.zipInput',
                    maxLength: 5,
                    accessible: true,
                    accessibilityLabel: 'Enter ZIP Code',
                  }}
                  isError={!!error}
                  errorMessage={error?.message}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <TextInput
                  label="City"
                  styles={{ width: '50%' }}
                  args={{
                    ref: cityInputRef,
                    onChangeText: onChange,
                    onBlur,
                    value,
                    returnKeyType: 'next',
                    onSubmitEditing: () => {
                      // @ts-ignore
                      stateInputRef.current?.focus();
                    },
                    blurOnSubmit: false,
                    testID: 'paychexpay.fillInDataSecondForm.input.cityInput',
                    accessible: true,
                    accessibilityLabel: 'Enter City',
                  }}
                  isError={!!error}
                  errorMessage={error?.message}
                />
              )}
            />
          </XStack>
          <XStack mb={'$4'} justifyContent="space-between">
            <Controller
              name="state"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <TextInput
                  label="State"
                  styles={{ width: '50%', paddingRight: 16 }}
                  args={{
                    ref: stateInputRef,
                    onChangeText: (value: string) => {
                      onChange(stateOnChange(value));
                    },
                    onBlur,
                    value,
                    testID: 'paychexpay.fillInDataSecondForm.input.stateInput',
                    accessible: true,
                    accessibilityLabel: 'Enter State',
                    onSubmitEditing: () => {
                      if (isDateDisabled && isNumberDisabled) {
                        if (
                          !(
                            Object.keys(errors).length !== 0 ||
                            (isEditAddrPage && !isFormDirty)
                          )
                        ) {
                          onSubmit();
                        }
                      } else {
                        if (!isDateDisabled) {
                          // @ts-ignore
                          dateInputRef.current?.focus();
                        } else {
                          // @ts-ignore
                          numberInputRef.current?.focus();
                        }
                      }
                    },
                    blurOnSubmit:
                      isDateDisabled && isNumberDisabled ? true : false,
                    returnKeyType:
                      isDateDisabled && isNumberDisabled ? 'done' : 'next',
                  }}
                  isError={!!error}
                  errorMessage={error?.message}
                />
              )}
            />
            {withAdditionalFields && (
              <View width={'50%'} zIndex={1}>
                <XStack mb={'$1'} alignItems="center">
                  <Text color="$gray40" lineHeight="$h2" fontSize="$h5">
                    Birth date
                  </Text>
                  <TPressable
                    marginLeft={8}
                    onPress={onPressDob}
                    testID="paychexpay.fillInDataSecondForm.button.DobInfoButton"
                    hitSlop={20}
                    accessible
                    accessibilityLabel={'Birth date info, tap to learn more'}
                  >
                    <InfoIcon height="16px" width="16px" />
                  </TPressable>
                </XStack>
                <Controller
                  name="date"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value },
                    fieldState: { error },
                  }) => (
                    <TextInput
                      // styles={{ paddingLeft: 4 }}
                      args={{
                        ref: dateInputRef,
                        onChangeText: (value: string) => {
                          onChange(dateOnChange(value));
                        },
                        onBlur,
                        value,
                        onSubmitEditing: () => {
                          if (isNumberDisabled) {
                            if (
                              !(
                                Object.keys(errors).length !== 0 ||
                                (isEditAddrPage && !isFormDirty)
                              )
                            ) {
                              onSubmit();
                            }
                          } else {
                            // @ts-ignore
                            numberInputRef.current?.focus();
                          }
                        },
                        blurOnSubmit: isNumberDisabled ? true : false,
                        returnKeyType: isNumberDisabled ? 'done' : 'next',
                        testID:
                          'paychexpay.fillInDataSecondForm.input.dateInput',
                        accessible: true,
                        accessibilityLabel: 'Enter Birth date',
                      }}
                      isError={!!error}
                      errorMessage={error?.message}
                      isDisabled={isDateDisabled}
                      InputRightElement={
                        <View p="$3" pr="$10">
                          <CalendarIcon fill={customTheme.colors.gray[30]} />
                        </View>
                      }
                    />
                  )}
                />
              </View>
            )}
          </XStack>
          {withAdditionalFields && (
            <View zIndex={1}>
              <XStack mb={'$1'} alignItems="center">
                <Text color="$gray40" lineHeight="$h2" fontSize="$h5">
                  SSN
                </Text>
                <TPressable
                  marginLeft={8}
                  onPress={onPressSsn}
                  testID="paychexpay.fillInDataSecondForm.button.SSNInfoButton"
                  hitSlop={20}
                  accessible
                  accessibilityLabel={'SSN Info, tap to learn more'}
                >
                  <InfoIcon height="16px" width="16px" />
                </TPressable>
              </XStack>
              <Controller
                name="ssn"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    args={{
                      ref: numberInputRef,
                      onChangeText: (value: string) => {
                        onChange(ssnOnChange(value));
                      },
                      onBlur,
                      value,
                      onSubmitEditing: () => {
                        if (
                          !(
                            Object.keys(errors).length !== 0 ||
                            (isEditAddrPage && !isFormDirty)
                          )
                        ) {
                          onSubmit();
                        }
                      },
                      testID: 'paychexpay.fillInDataSecondForm.input.ssnInput',
                      secureTextEntry: !isSsnVisible,
                      accessible: true,
                      accessibilityLabel: 'Enter SSN',
                    }}
                    isError={!!error}
                    errorMessage={error?.message}
                    isDisabled={isNumberDisabled}
                    InputRightElement={
                      isNumberDisabled ? (
                        <View mr={'$4'} p="$3">
                          <LockIcon color={'#B2BAC5'} />
                        </View>
                      ) : (
                        <Pressable
                          onPress={ssnHideHandler}
                          p={'$3'}
                          testID="paychexpay.fillInDataSecondForm.button.eyeSsn"
                          accessible
                          accessibilityLabel={`${
                            isSsnVisible
                              ? 'Hide SSN number, button'
                              : 'Show SSN number, button'
                          }`}
                        >
                          {isSsnVisible ? <EyeShowIcon /> : <EyeHideIcon />}
                        </Pressable>
                      )
                    }
                  />
                )}
              />
            </View>
          )}
          {isEditAddrPage && (
            <View
              flexGrow={1}
              paddingBottom={36}
              paddingHorizontal={22}
              justifyContent={'flex-end'}
            >
              <Text
                variant={'regular'}
                fontSize={'$h5'}
                textAlign={'center'}
                fontWeight={'400'}
              >
                If you’ve changed your address within the past 30 days, contact{' '}
                <Text
                  onPress={navigateToCustomerService}
                  fontSize={'$h5'}
                  color="$primaryNormal"
                  cursor={'pointer'}
                  display={Platform.OS === 'web' ? 'inline' : 'flex'}
                >
                  Customer Service
                </Text>{' '}
                if you need a new card.
              </Text>
            </View>
          )}
        </>
      )}
    </View>
  );
};

export default FillInDataSecondForm;
