import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';
import { IExternalAccountPreview } from '@store/features/externalBankAccounts/types';

const externalBankAccountsSelector = (state: RootState) =>
  state.externalBankAccounts;

export const selectExternalAccounts = createSelector(
  externalBankAccountsSelector,
  (state) => {
    return state.externalAccounts.map(
      (account) =>
        ({
          id: account.bank_account_id,
          name: account.account_title,
          type: account.account_type === 'CHECKING' ? 'spending' : 'saving',
          amount: 0,
          accountNumber: account.account_number,
          routingNumber: account.routing_number,
          accountOwnership: account.account_ownership,
          bankName: account.bank_name,
          isDisabled: false,
          isExternal: true,
        } as IExternalAccountPreview)
    );
  }
);
export const selectConnectAccountStatus = createSelector(
  externalBankAccountsSelector,
  (state) => state.connectAccountStatus
);
