import { useEffect, useState } from 'react';

interface useSideModalProps {
  route: any;
  navigation: any;
}

export const useSideModal = ({ route, navigation }: useSideModalProps) => {
  const [modalName, setModalName] = useState(undefined);
  const [transactionId, setTransactionId] = useState(undefined);
  const [markerId, setMarkerId] = useState(undefined);
  const toOpenModal = (name: string) => () => {
    navigation.setParams({ modal: name });
  };
  const toCloseModal = () => {
    navigation.setParams({
      modal: undefined,
      transaction: undefined,
      marker: undefined,
    });
  };
  const toSetTransactionId = (id: string) => {
    navigation.setParams({ transaction: id });
  };
  const toSetMarkerId = (id: string) => {
    navigation.setParams({ marker: id });
  };
  useEffect(() => {
    if (route.params?.modal) {
      setModalName(route.params.modal);
    } else {
      setModalName(undefined);
    }
    if (route.params?.transaction) {
      setTransactionId(route.params.transaction);
    } else {
      setTransactionId(undefined);
    }
    if (route.params?.marker) {
      setMarkerId(route.params.marker);
    } else {
      setMarkerId(undefined);
    }
  }, [route]);
  return {
    isModalOpen: modalName !== undefined,
    modalName,
    toOpenModal,
    toCloseModal,
    transactionId,
    toSetTransactionId,
    markerId,
    toSetMarkerId,
  };
};
