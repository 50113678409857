import TransactionsList from '@organisms/TransactionsList/TransactionsList';
import { Text } from '@atoms';
import React, { useMemo, useRef } from 'react';
import { TransactionsFilter } from '@organisms';
import { Platform, KeyboardAvoidingView, Dimensions } from 'react-native';
import {
  ITransaction,
  TransactionType,
} from '@store/features/transactions/types';
import { format } from 'date-fns';
import { YStack, ScrollView, View } from 'tamagui';
import { useSelector } from 'react-redux';
import { selectDateRange } from '@store/features/transactions/selectors';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getHeightOfToolbarsWeb } from '@utils/getWindowHeightWeb';

export interface AccountTransactionsProps {
  filteredTransactions: ITransaction[];
  transactions: ITransaction[];
  transactionsSearchText: string;
  transactionsFilteredBy: TransactionType;
  navigation: any;
  filterType: 'spending' | 'saving';
  setLoaderOverlay: (value: boolean) => void;
  isLoadingTransactions: boolean;
  onRefreshTransactionsList?: () => void;
  isRefreshLoading?: boolean;
}

const AccountTransactions = ({
  filteredTransactions,
  transactions,
  transactionsSearchText,
  transactionsFilteredBy,
  navigation,
  filterType,
  setLoaderOverlay,
  isLoadingTransactions,
  onRefreshTransactionsList,
  isRefreshLoading,
}: AccountTransactionsProps) => {
  const filterDateRange = useSelector(selectDateRange);
  const { bottom: bottomOffset } = useSafeAreaInsets();
  const onPressTransaction = (id: string) => {
    navigation.navigate('HomeNavigator', {
      screen: 'TransactionDetails',
      params: { transactionId: id },
    });
  };

  const scrollWrapperRef = useRef(null);
  const isNotDesktop = Dimensions.get('screen').width < 768;
  const accountScrollViewHeight =
    Dimensions.get('screen').height - getHeightOfToolbarsWeb() - 372;
  const accountScrollViewHeightHiddenFilters =
    Dimensions.get('screen').height - getHeightOfToolbarsWeb() - 280;

  const isFiltersVisible = useMemo(() => {
    return (
      transactions.length > 0 ||
      filteredTransactions.length > 0 ||
      transactionsSearchText.length > 0 ||
      transactionsFilteredBy !== ''
    );
  }, [transactions, filteredTransactions, transactionsSearchText]);

  const TransactionsElement = useMemo(() => {
    return (
      <View flexGrow={1} marginBottom={bottomOffset ? bottomOffset - 10 : 0}>
        <KeyboardAvoidingView
          style={{ flexGrow: 1 }}
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        >
          <View
            ref={scrollWrapperRef}
            display={'flex'}
            flexDirection={'column'}
            flex={1}
            flexGrow={1}
            maxHeight={
              isNotDesktop
                ? isFiltersVisible
                  ? accountScrollViewHeight
                  : accountScrollViewHeightHiddenFilters
                : '100%'
            }
          >
            {Platform.OS === 'web' ? (
              <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  paddingBottom: 10,
                }}
              >
                <TransactionsList
                  onPress={onPressTransaction}
                  transactions={filteredTransactions}
                  withHighlight={true}
                  setLoaderOverlay={setLoaderOverlay}
                  transactionsSearchText={transactionsSearchText}
                  transactionsFilteredBy={transactionsFilteredBy}
                  isLoading={isLoadingTransactions}
                />
              </ScrollView>
            ) : (
              <View display={'flex'} flexDirection={'column'} flexGrow={1}>
                <TransactionsList
                  onRefreshTransactionsList={onRefreshTransactionsList}
                  isRefreshLoading={isRefreshLoading}
                  onPress={onPressTransaction}
                  transactions={filteredTransactions}
                  withHighlight={true}
                  setLoaderOverlay={setLoaderOverlay}
                  transactionsSearchText={transactionsSearchText}
                  transactionsFilteredBy={transactionsFilteredBy}
                  isLoading={isLoadingTransactions}
                />
              </View>
            )}
          </View>

          <YStack
            backgroundColor={'white'}
            paddingHorizontal={16}
            paddingTop={2}
          >
            <View
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
            >
              {filterDateRange?.date_from ? (
                <Text
                  variant={'semiTitle'}
                  fontWeight={'400'}
                  fontSize={14}
                  paddingVertical={10}
                >
                  {filterDateRange?.date_from &&
                    format(
                      new Date(filterDateRange.date_from),
                      'MMM dd, yyyy'
                    )}{' '}
                  {filterDateRange?.date_to &&
                    filterDateRange.date_from !== filterDateRange.date_to &&
                    format(
                      new Date(filterDateRange.date_to),
                      ' - MMM dd, yyyy'
                    )}
                </Text>
              ) : (
                <>
                  {transactions.length > 0 && (
                    <Text
                      variant={'caption'}
                      color={'$gray60'}
                      fontSize={12}
                      paddingVertical={10}
                      accessible
                      accessibilityLabel={'Showing last 90 days'}
                    >
                      Showing last 90 days
                    </Text>
                  )}
                </>
              )}
            </View>
          </YStack>
        </KeyboardAvoidingView>
      </View>
    );
  }, [filteredTransactions, isLoadingTransactions]);

  return (
    <View
      display={'flex'}
      flexDirection={'column'}
      paddingHorizontal={16}
      flexGrow={1}
      flex={1}
    >
      {isFiltersVisible && (
        <TransactionsFilter.mobile filterType={filterType} />
      )}
      {TransactionsElement}
    </View>
  );
};

export default React.memo(AccountTransactions);
