import { useMediaQuery } from 'native-base';
import { maxMediaQuery } from '@constants/ui';

const useIsTablet = () => {
  const [isTablet] = useMediaQuery({
    maxWidth: maxMediaQuery.tablet,
  });

  return isTablet;
};

export default useIsTablet;
