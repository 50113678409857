import { IErrorsMap } from '@store/features/errorStates/types';

const errorsMap = {
  unhandled: {
    displayType: 'page',
    body: {
      title: 'Unhandled error',
      subtitle: 'Refresh the app',
      icon: 'default',
      ghostButtonText: 'Contact support',
      mainButtonText: 'Go back',
      ghostButtonAction: 'contactSupport',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Unauthorized.',
    },
  },
  unhandled500: {
    displayType: 'page',
    body: {
      title: 'Unhandled error',
      subtitle: 'Refresh the app',
      icon: 'default',
      ghostButtonText: 'Contact support',
      mainButtonText: 'Try again',
      ghostButtonAction: 'contactSupport',
      mainButtonAction: 'retry',
    },
    info: {
      description: 'Unauthorized.',
    },
  },
  internal: {
    displayType: 'page',
    body: {
      title: 'Internal error',
      subtitle: 'Refresh the app',
      icon: 'default',
      ghostButtonText: 'Contact support',
      mainButtonText: 'Try again',
      ghostButtonAction: 'contactSupport',
      mainButtonAction: 'retry',
    },
    info: {
      description: 'Unauthorized.',
    },
  },
  noTokenStored: {
    displayType: 'redirect',
    body: {
      redirectPageName: 'login',
    },
    info: {
      description: 'There are no token stored in the local storage',
    },
  },
  global: {
    displayType: 'page',
    body: {
      title: 'Oops, something went wrong',
      subtitle: 'Something went wrong, error message. Error description.',
      icon: 'default',
      ghostButtonText: '',
      mainButtonText: 'Try again',
      ghostButtonAction: null,
      mainButtonAction: 'retry',
    },
    info: {
      description: 'Error boundary',
    },
  },
  T0002: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0003: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0004: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0005: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0006: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0007: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0009: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0011: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0012: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0013: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0014: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0015: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0016: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0017: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0018: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0019: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Error processing transaction.',
    },
  },
  T0020: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Invalid/unconfigured cross program transfer.',
    },
  },
  T0022: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Invalid/unconfigured cross program transfer.',
    },
  },
  T0023: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Invalid/unconfigured cross program transfer.',
    },
  },
  T0024: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Invalid/unconfigured cross program transfer.',
    },
  },
  T0030: {
    displayType: 'transfer',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
    },
    info: {
      description: 'Insufficient balance for cascading transaction.',
    },
  },
  P0404: {
    displayType: 'page',
    body: {
      title: 'Oops, something went wrong',
      subtitle: 'Something went wrong, error message. Error description.',
      icon: 'default',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description:
        'Partner and its program default attributes not found. Contact support for setup.',
    },
  },
  B0001: {
    displayType: 'transfer',
    body: {
      title: 'Transfer not sent',
      subtitle: 'Something went wrong... Error message.',
    },
    info: {
      description:
        'Bank transfer is awaiting account verification. Query verification status and try again.',
    },
  },
  B0003: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0004: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0005: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0006: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0007: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0010: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0011: {
    displayType: 'page',
    body: {
      title: 'Bank account already exists',
      subtitle: 'You’ve already linked this bank account to  Paychex Pay.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0012: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0013: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0014: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0015: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0017: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0019: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0020: {
    displayType: 'page',
    body: {
      title: 'Bank account deletion declined',
      subtitle:
        'You may delete your bank account once pending transfers have cleared.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0901: {
    displayType: 'page',
    body: {
      title: 'Invalid bank routing number',
      subtitle: 'Re-enter bank routing number and try again.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1025: {
    displayType: 'page',
    body: {
      title: 'Could not load card',
      subtitle: 'Try again later.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  T0008: {
    displayType: 'page',
    body: {
      title: 'Insufficient funds',
      subtitle: 'Add funds and then try again.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  T0010: {
    displayType: 'page',
    body: {
      title: 'Duplicate transaction',
      subtitle: 'Edit your transaction details and try again.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  T0021: {
    displayType: 'page',
    body: {
      title: 'Current request not processed',
      subtitle: 'Previous attempt still in progress.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С0906: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later.',
      icon: 'cup',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С0907: {
    displayType: 'page',
    body: {
      title: 'Email address not valid',
      subtitle: 'Enter a valid email address and try again.',
      icon: 'default',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С0912: {
    displayType: 'page',
    body: {
      title: 'Amount can’t exceed available balance',
      subtitle: 'Change amount and try again.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С0915: {
    displayType: 'page',
    body: {
      title: 'Date of birth out of acceptable range',
      subtitle:
        'You must be at least 18 years old to sign up for a Paychex Pay account.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С0922: {
    displayType: 'page',
    body: {
      title: 'Blocked account',
      subtitle:
        'Account is in fraud status and cannot be modified. Contact Customer Service at 1-866-438-0579 for further assistance.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С1025: {
    displayType: 'page',
    body: {
      title: 'Your request cannot be completed',
      subtitle: 'Your card is already active.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С1016: {
    displayType: 'page',
    body: {
      title: 'Your card is not active',
      subtitle: 'Activate your card to begin using it now.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С0941: {
    displayType: 'page',
    body: {
      title: 'Your request cannot be completed',
      subtitle: 'Your card is already activated.',
      icon: 'default',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B00001: {
    displayType: 'page',
    body: {
      title: 'Bank transfer awaiting account verification',
      subtitle: 'Try again later.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0023: {
    displayType: 'page',
    body: {
      title: 'Maximum number of tries exceeded',
      subtitle:
        'Contact Customer Service at 1-866-438-0579 for further assistance.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0915: {
    displayType: 'page',
    body: {
      title: 'Error retrieving card balance',
      subtitle: 'Try again later.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  С1015: {
    displayType: 'page',
    body: {
      title: 'Your request cannot be completed',
      subtitle: 'Your card is already active.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0021: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0900: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  B0902: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0000: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0001: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0002: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0003: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0004: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0005: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0006: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0007: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0008: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0009: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0010: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0011: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0013: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0014: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0015: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0016: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0017: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0018: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0019: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0020: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0021: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0024: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1003: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1004: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1005: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1006: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1007: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1008: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1009: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1010: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1011: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1012: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1013: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1014: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1017: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1018: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1019: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1020: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1021: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1022: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1023: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1024: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1026: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1027: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1028: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1029: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1030: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1032: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1033: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1034: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1035: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1036: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1037: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1038: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1039: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1040: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1041: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1042: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C1043: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0900: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0901: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0902: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0905: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0908: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0909: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0910: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0911: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0913: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0907: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Email address not valid.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0914: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0916: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0917: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0918: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0919: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0921: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0923: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0924: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0925: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0926: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0927: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0928: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0929: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0930: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0931: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0932: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0933: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0934: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0935: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0936: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0937: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0938: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0939: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0940: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0942: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0943: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0944: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0945: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0946: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0947: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0948: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0949: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0950: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0951: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0952: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0953: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0954: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0955: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0956: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0957: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0958: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  C0022: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0002: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0003: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0004: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0005: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0006: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0007: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0008: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  E0009: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  G0000: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle: 'Try again later',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  A0420: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U0000: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U001: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U0003: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U0004: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U0005: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U0015: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U0103: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  U0104: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  NT0400: {
    displayType: 'page',
    body: {
      title: 'Transfer not sent',
      subtitle: 'Email address is not correct.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: 'Email address is not correct.',
    },
  },
  T0041: {
    displayType: 'page',
    body: {
      title: 'Transaction is not within allowed limits',
      subtitle: 'Try again later.',
      icon: 'error',
      mainButtonText: 'Go back',
      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
  transferUnhandled: {
    displayType: 'page',
    body: {
      title: 'Sorry, something went wrong',
      subtitle:
        'Contact Customer Service at 1-888-438-0579 for further assistance.',
      icon: 'cup',

      mainButtonText: 'Go back',

      mainButtonAction: 'goBack',
    },
    info: {
      description: '',
    },
  },
} as IErrorsMap;

export default errorsMap;
