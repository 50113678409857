import React from 'react';
import { View } from 'native-base';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { FooterButtonsRow, FooterMobile } from '@molecules';
import {
  FooterMobileTemplateProps,
  withOneButtonProps,
  withTwoButtonsProps,
} from './FooterMobileTemplate.types';
import { TButton } from '@atoms';

const dynamicFooter = ({
  children,
  footerBgColor = 'white',
  FooterContent,
  showFooter = true,
  borderTopColor = '$gray20',
  buttonBottomGap,
}: FooterMobileTemplateProps) => {
  const { bottom: bottomInset } = useSafeAreaInsets();
  return (
    <View display="flex" flexGrow={1}>
      <View display="flex" flexGrow={1}>
        {children}
      </View>
      {showFooter && (
        <FooterMobile
          buttonBottomGap={buttonBottomGap}
          footerBgColor={footerBgColor}
          FooterContent={FooterContent}
          bottomInset={bottomInset}
          borderTopColor={borderTopColor}
        />
      )}
    </View>
  );
};

const withOneButton = ({
  children,
  footerBgColor,
  buttonLabel,
  buttonHandler,
  buttonVariant = 'primary',
  isDisabled = false,
  borderTopColor,
  testID,
  ...rest
}: withOneButtonProps) => {
  return (
    <FooterMobileTemplate.default
      showFooter={true}
      borderTopColor={borderTopColor}
      FooterContent={
        <View flexGrow={1}>
          <TButton
            // @ts-ignore
            variant={buttonVariant}
            onPress={buttonHandler}
            disabled={isDisabled}
            testID={testID}
            {...rest}
          >
            {buttonLabel}
          </TButton>
        </View>
      }
      footerBgColor={footerBgColor}
    >
      {children}
    </FooterMobileTemplate.default>
  );
};

const withTwoButtons = ({
  children,
  footerBgColor,
  firstButton,
  secondButton,
  borderTopColor,
  isLoading = false,
}: withTwoButtonsProps) => {
  return (
    <FooterMobileTemplate.default
      showFooter={true}
      borderTopColor={borderTopColor}
      FooterContent={
        <FooterButtonsRow
          firstButton={firstButton}
          secondButton={secondButton}
          isLoading={isLoading}
        />
      }
      footerBgColor={footerBgColor}
    >
      {children}
    </FooterMobileTemplate.default>
  );
};

const FooterMobileTemplate = {
  default: dynamicFooter,
  withOneButton,
  withTwoButtons,
};

export default FooterMobileTemplate;
