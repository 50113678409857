import { SetupDdaView } from '@views/Accounts';
import { useEffect, useMemo, useState } from 'react';
import { SetupDdaProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { useSelector } from 'react-redux';
import { selectSpendingAccountPreviewInfo } from '@store/features/cards/selectors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ddaFormSchema,
  IDdaFormInputs,
} from '@organisms/DdaFormContent/helper';
import {
  selectDdaPdfString,
  selectIsPdfReady,
} from '@store/features/ddaForm/selectors';
import { Platform } from 'react-native';
import { ddaFormActions } from '@store/features/ddaForm/slice';
import ddaFormThunks from '@store/features/ddaForm/asyncThunks';
import { ReactNativeBlobUtil } from '@utils/rnBlobUtil';
import { getAccessToken } from '@utils/accessTokenHelper.native';
import endpoints, { baseUrl } from '@constants/endpoints';
import { Share } from '@utils/shareUtil';
import { useAppDispatch } from '@store/hooks';
import { generateDdaRequestType } from '@store/features/ddaForm/types';
import amountCommaCleaner from '@utils/amountCommaCleaner';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
const SetupDdaPage = ({ navigation, route }: SetupDdaProps) => {
  const dispatch = useAppDispatch();

  const defaultValues = {
    companyName: '',
    idNumber: '',
    option: '',
    percent: '',
    number: '',
  } as IDdaFormInputs;
  const { control, watch, resetField } = useForm<IDdaFormInputs>({
    defaultValues,
    resolver: yupResolver(ddaFormSchema),
  });
  const watchCurrentOption = watch('option');
  const watchPercent = watch('percent');
  const watchNumber = watch('number');
  const watchCompanyName = watch('companyName');
  const watchIdNumber = watch('idNumber');

  const isButtonDisabled = useMemo(() => {
    return !(
      watchCurrentOption === 'nothing' ||
      (watchCurrentOption === 'percent' &&
        watchPercent.includes('% ') &&
        watchPercent.length > 2) ||
      (watchCurrentOption === 'number' &&
        watchNumber.includes('$') &&
        watchNumber.length > 1)
    );
  }, [watchCurrentOption, watchPercent, watchNumber]);

  const formRequestBody: generateDdaRequestType = useMemo(() => {
    switch (watchCurrentOption) {
      case 'percent':
        return {
          percentageChk: true,
          percentageAmt: parseFloat(watchPercent.replace(/\D/g, '')),
        };
      case 'number':
        return {
          dollarAmtChk: true,
          dollarAmt: parseFloat(amountCommaCleaner(watchNumber)),
        };
      default:
        return {
          entireChk: true,
        };
    }
  }, [watchCurrentOption, watchPercent, watchNumber]);

  const navigateBack = () => {
    if (route.params?.stateName === 'homepage') {
      navigation.reset({
        index: 0,
        routes: [{ name: 'BottomTabsNavigator' }],
      });
    } else {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'BottomTabsNavigator' }],
        });
      }
    }
  };

  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);

  const pdfString = useSelector(selectDdaPdfString);
  const isPdfReady = useSelector(selectIsPdfReady);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [dollarAmtError, setDollarAmtError] = useState(false);

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  useEffect(() => {
    if (dollarAmtError && watchNumber.length > 1) {
      setDollarAmtError(false);
    }
  }, [watchNumber]);

  useEffect(() => {
    if (Platform.OS === 'web' && isPdfReady && pdfString) {
      const file = new Blob([pdfString], {
        type: 'application/pdf',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
    dispatch(ddaFormActions.resetStatus());
  }, [pdfString]);

  const onPressSave = async () => {
    if (
      watchCurrentOption === 'number' &&
      parseFloat(amountCommaCleaner(watchNumber)) < 1
    ) {
      setDollarAmtError(true);
      resetField('number');
      return;
    }

    setIsLoadingButton(true);
    if (Platform.OS === 'web') {
      await dispatch(
        ddaFormThunks.postGenerateDda({
          ...formRequestBody,
          companyName: watchCompanyName,
          employeeNumber: watchIdNumber,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
      setIsLoadingButton(false);
    } else {
      const configOptions = {
        ios: {
          fileCache: true,
          path: `${ReactNativeBlobUtil.fs.dirs.DocumentDir}/Direct_Deposit_Form.pdf`,
          notification: true,
          IOSDownloadTask: true,
        },
        android: {
          fileCache: true,
          path: `${ReactNativeBlobUtil.fs.dirs.DocumentDir}/Direct_Deposit_Form.pdf`,
          notification: true,
        },
      };
      const accessToken = await getAccessToken();
      const fetchHeaders = {
        Authorization: 'Bearer ' + accessToken,
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      };
      if (Platform.OS === 'ios') {
        ReactNativeBlobUtil.config(configOptions.ios)
          .fetch(
            'POST',
            baseUrl + endpoints.generateDda,
            fetchHeaders,
            JSON.stringify({
              ...formRequestBody,
              companyName: watchCompanyName,
              employeeNumber: watchIdNumber,
            })
          )
          .then((res: any) => {
            setIsLoadingButton(false);
            const filePath = res.path();
            const options = {
              type: 'application/pdf',
              url: filePath,
              saveToFiles: true,
            };
            Share.open(options);
          });
      } else {
        ReactNativeBlobUtil.config(configOptions.android)
          .fetch(
            'POST',
            baseUrl + endpoints.generateDda,
            fetchHeaders,
            JSON.stringify({
              ...formRequestBody,
              companyName: watchCompanyName,
              employeeNumber: watchIdNumber,
            })
          )
          .then((res: any) => {
            setIsLoadingButton(false);
            ReactNativeBlobUtil.android.actionViewIntent(
              res.path(),
              'application/pdf'
            );
          });
      }
    }
  };

  return (
    <SetupDdaView
      navigateBack={navigateBack}
      account={spendingAccount}
      control={control}
      stateName={route.params?.stateName}
      watchCurrentOption={watchCurrentOption}
      isButtonDisabled={isButtonDisabled}
      onPressSave={onPressSave}
      isButtonLoading={isLoadingButton}
      dollarAmtError={dollarAmtError}
    />
  );
};

export default SetupDdaPage;
