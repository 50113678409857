// import { Image } from 'native-base';
import AtmIcon from '../assets/atm/atmIcon.svg';
// import ReloadLocatorIcon from '../assets/atm/reloadLocatorIcon.svg';
import { IMarker } from '@store/features/atmLocations/types';

const atmObjectToIcon = (
  object: IMarker | undefined,
  { isLarge = false, isXLarge = false, color = '#19AF92' }
) => {
  let args = { height: '18px', width: '18px' };
  if (isLarge) args = { height: '30px', width: '30px' };
  if (isXLarge) args = { height: '40px', width: '40px' };

  // if (object?.is_link_icon) {
  //   return (
  //     <Image
  //       {...args}
  //       source={{ uri: object.icon_uri }}
  //       alt={object.type === 'atm' ? 'atm icon' : 'reload locator icon'}
  //     />
  //   );
  // }
  // if (object?.type !== 'atm') {
  //   return <ReloadLocatorIcon {...args} />;
  // }
  return <AtmIcon color={color} {...args} />;
};

export default atmObjectToIcon;
