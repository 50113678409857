import { McFeaturesMenuItem } from '@hooks/useMastercardMenu';
import { HeaderMobileTemplate, SideBarTemplate } from '@templates';
import { TPressable, Text } from '@atoms';
import mastercardFeatures from '@constants/mastercardFeatures';
import { Dimensions, Linking, Platform, Text as RNText } from 'react-native';
import { MobileWebHeader } from '@molecules';
import React from 'react';
import { ScrollView, Separator, View, XStack } from 'tamagui';

interface MastercardFeaturesViewProps {
  navigateBack: () => void;
  menuItems: McFeaturesMenuItem[];
  currentItem: string;
  content: typeof mastercardFeatures;
  scrollRef?: any;
  isLaptop?: boolean | undefined;
  isPhone?: boolean | undefined;
}

const mobile = ({ navigateBack, menuItems }: MastercardFeaturesViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderMobileTemplate
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: 'Mastercard features',
        }}
      >
        <View px={'$4'}>
          {menuItems.map((menuItem) => (
            <>
              <TPressable
                key={menuItem.name}
                paddingVertical={21}
                onPress={menuItem.handler}
                testID={menuItem.name}
              >
                <Text variant="regular">{menuItem.name}</Text>
              </TPressable>
              <Separator borderColor="$gray20" />
            </>
          ))}
        </View>
      </HeaderMobileTemplate>
    </>
  );
};

const desktop = ({
  currentItem,
  content,
  scrollRef,
  isPhone,
}: MastercardFeaturesViewProps) => {
  const screenHeight = Dimensions.get('window').height;
  const onPressLink = (title: string) => {
    switch (title) {
      case 'Mastercard Airport Concierge™':
        return Linking.openURL('https://mastercard.globalairportconcierge.com');
      case 'Mastercard ID Theft Protection™':
        return Linking.openURL('https://mastercardus.idprotectiononline.com');
    }
  };

  return (
    <SideBarTemplate disableScroll={true}>
      <View px={'$7'} pt={'$5'} flexGrow={1}>
        <Text
          variant="pageTitle"
          pb={'$7'}
          testID={'paychexpay.desktop.mastercardFeaturesView.text.title'}
        >
          Mastercard features
        </Text>
        <XStack h={screenHeight - 128 + 'px'}>
          <ScrollView
            showsVerticalScrollIndicator={!isPhone}
            flexGrow={1}
            contentContainerStyle={{ p: '20px' }}
            borderRadius={12}
            bg="white"
            ref={scrollRef}
          >
            <Text variant="regular" testID={content[currentItem].testId}>
              {content[currentItem].title}
            </Text>
            <Separator my={'$4'} borderColor="$gray20" />
            <RNText
              style={{
                color: '#666C7B',
                fontSize: 14,
                fontWeight: '500',
                lineHeight: 20,
                fontFamily: 'Inter-Regular, sans-serif',
              }}
            >
              {content[currentItem].text}
              {content[currentItem].link && (
                <RNText
                  style={{
                    color: '#0073C4',
                    fontSize: 14,
                    fontWeight: '400',
                    lineHeight: 20,
                  }}
                  onPress={() => onPressLink(content[currentItem].title)}
                >
                  {content[currentItem].link}
                </RNText>
              )}
              {content[currentItem].contentAfterLink && (
                <RNText
                  style={{
                    color: '#666C7B',
                    fontSize: 14,
                    fontWeight: '400',
                    lineHeight: 20,
                  }}
                >
                  {content[currentItem].contentAfterLink}
                </RNText>
              )}
            </RNText>
          </ScrollView>
        </XStack>
      </View>
    </SideBarTemplate>
  );
};

const MastercardFeaturesView = {
  mobile,
  desktop,
};

export default MastercardFeaturesView;
