import endpoints from '@constants/endpoints';
import { wrappedAxiosRequest } from '@store/api';
import { IErrorRequestBody } from './types';

const errorApi = {
  postErrorInfo(body: Array<IErrorRequestBody>) {
    return wrappedAxiosRequest<Array<IErrorRequestBody>, any>({
      method: 'post',
      url: endpoints.errorInfo,
      data: body,
    });
  },
};

export default errorApi;
