import { HeaderAndFooterMobileTemplate } from '@templates';
import { MobileWebHeader } from '@molecules';
import React from 'react';
import { setupDdaAccount } from '@constants/ddaScreens';
import SetupDdaContent from '@organisms/SetupDdaContent/SetupDdaContent';
import { Platform } from 'react-native';
import { TButton, Text } from '@atoms';
import { DdaFormContent } from '@organisms';
import { IAccountPreview } from '@devTypes/accountTypes';
import { Control } from 'react-hook-form';
import {
  ddaOptionType,
  IDdaFormInputs,
} from '@organisms/DdaFormContent/helper';
import { setupDdaStateName } from '@navigation/HomeNavigator/HomeNavigator.types';
import { ScrollView } from 'tamagui';

interface SetupDdaViewProps {
  navigateBack: () => void;
  account: IAccountPreview;
  control: Control<IDdaFormInputs>;
  watchCurrentOption: ddaOptionType;
  isButtonDisabled: boolean;
  onPressSave: () => void;
  isButtonLoading: boolean;
  stateName: setupDdaStateName | undefined;
  dollarAmtError: boolean;
}

const SetupDdaView = ({
  navigateBack,
  account,
  control,
  watchCurrentOption,
  isButtonDisabled,
  onPressSave,
  isButtonLoading,
  stateName,
  dollarAmtError,
}: SetupDdaViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && stateName !== 'homepage' && <MobileWebHeader />}

      <HeaderAndFooterMobileTemplate
        header={{
          title: setupDdaAccount.pageTitle,
          isBackButton: false,
          isRightButton: true,
          rightButtonText: 'Close',
          onPressRightButton: navigateBack,
        }}
        footerContent={
          <>
            {dollarAmtError && (
              <Text variant="error" textAlign="center" pb={'$1'}>
                Custom deposit amount should be more than $1
              </Text>
            )}
            <TButton
              onPress={onPressSave}
              disabled={isButtonDisabled}
              isLoading={isButtonLoading}
              testID={
                'paychexpay.mobile.ddaFormContent.button.completeFormButton'
              }
            >
              {setupDdaAccount.buttonText}
            </TButton>
          </>
        }
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          height={Platform.OS === 'web' ? 0 : '100%'}
        >
          <SetupDdaContent />
          <DdaFormContent.mobile
            account={account}
            control={control}
            watchCurrentOption={watchCurrentOption}
          />
        </ScrollView>
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

export default SetupDdaView;
