import useIsPhone from '@hooks/useIsPhone';
import {
  BetweenPaychexPayWebProps,
  BetweenPaychexPayProps,
  ToExternalBankWebProps,
  ToOtherFriendsWebProps,
  TransfersHistoryProps,
  TransfersPageProps,
} from '@navigation/HomeNavigator/HomeNavigator.types';
import { transfersScreensNames } from '@constants/transfersScreensNames';
import { useSelector } from 'react-redux';
import { selectSavingsAccountId } from '@store/features/cardholder/selectors';
import DotIcon from '../assets/webDrawer/dot.svg';
import { selectIsNotificationPreferencesUpdated } from '@store/features/general/selectors';
import { generalActions } from '@store/features/general/slice';
import notificationsThunks from '@store/features/notifications/asyncThunks';
import { useAppDispatch } from '@store/hooks';

const useTransfers = ({
  navigation,
}:
  | TransfersPageProps
  | BetweenPaychexPayWebProps
  | BetweenPaychexPayProps
  | ToExternalBankWebProps
  | TransfersHistoryProps
  | ToOtherFriendsWebProps) => {
  const isPhone = useIsPhone();

  const savingsAccountId = useSelector(selectSavingsAccountId);
  const notificationPreferncesUpdated = useSelector(
    selectIsNotificationPreferencesUpdated
  );
  const dispatch = useAppDispatch();

  const resetNotificationsAndCloseModal = () => {
    dispatch(generalActions.setWebNotificationConfirmationModalVisible(false));
    dispatch(generalActions.setNotificationPreferencesUpdated(false));
    dispatch(notificationsThunks.getNotifications());
  };

  const navigateToMyPaychexAccountsTransfer = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'BetweenPaychexPay',
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'HomeNavigator',
            params: {
              screen: 'BetweenPaychexPayWeb',
            },
          },
        ],
      });
    }
  };

  const betweenMyPaychexPayAccountsHandler = () => {
    if (notificationPreferncesUpdated) {
      dispatch(
        generalActions.setModalSubmitHandler(() => {
          navigateToMyPaychexAccountsTransfer();
          resetNotificationsAndCloseModal();
        })
      );
    } else {
      navigateToMyPaychexAccountsTransfer();
    }
  };
  const externalBankAccountsHandler = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'ToExternalBank',
        params: {},
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'HomeNavigator',
            params: {
              screen: 'ToExternalBankWeb',
            },
          },
        ],
      });
    }
  };
  const toOtherFriendsHandler = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'ToOtherFriends',
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'HomeNavigator',
            params: {
              screen: 'ToOtherFriendsWeb',
            },
          },
        ],
      });
    }
  };
  const toHistoryHandler = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'TransfersHistory',
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'HomeNavigator',
            params: {
              screen: 'TransfersHistory',
            },
          },
        ],
      });
    }
  };
  const menuItems = [
    {
      Icon: DotIcon,
      pageName: 'BetweenPaychexPay',
      label: 'My Paychex Pay accounts',
      isSubsectionItem: true,
      name: transfersScreensNames.betweenPaychexPay,
      title: 'My Paychex Pay accounts',
      handler: betweenMyPaychexPayAccountsHandler,
      isDisabled: !savingsAccountId,
    },
    {
      Icon: DotIcon,
      pageName: 'ToExternalBank',
      label: 'External accounts (by account number)',
      isSubsectionItem: true,
      name: transfersScreensNames.externalAccount,
      title: 'External accounts (by account number)',
      handler: externalBankAccountsHandler,
    },
    {
      Icon: DotIcon,
      pageName: 'ToOtherFriends',
      label: 'External accounts (by phone/email)',
      isSubsectionItem: true,
      name: transfersScreensNames.toOtherFriends,
      title: 'External accounts (by phone/email)',
      handler: toOtherFriendsHandler,
      isDisabled: false,
    },
    {
      Icon: DotIcon,
      pageName: 'TransfersHistory',
      label: 'Transfers history',
      isSubsectionItem: true,
      name: transfersScreensNames.history,
      title: 'Transfers history',
      handler: toHistoryHandler,
    },
  ];
  return {
    menuItems: menuItems,
  };
};

export default useTransfers;

export interface TransfersMenuItem {
  title: string;
  name: string;
  handler: () => void;
  isDisabled?: boolean;
  testId?: string;
}
