import { DigitalWalletContent } from '@organisms';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { Button } from '@atoms';
import React from 'react';

interface DigitalWalletViewProps {
  navigateBack: () => void;
  openDigitalWallet: () => void;
}

const DigitalWalletView = ({
  navigateBack,
  openDigitalWallet,
}: DigitalWalletViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      header={{
        isBackButton: true,
        onPressBack: navigateBack,
        title: 'Open digital wallet',
      }}
      footerContent={
        <Button
          testID="paychexpay.DigitalWalletView.button.openDigitalWallet"
          onPress={openDigitalWallet}
        >
          Go to wallet
        </Button>
      }
    >
      <DigitalWalletContent />
    </HeaderAndFooterMobileTemplate>
  );
};

export default DigitalWalletView;
