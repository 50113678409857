import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const agreementsSelector = (state: RootState) => state.agreements;

export const selectAgreementPdfLink = createSelector(
  agreementsSelector,
  (state) => state.savingAccountAgreement.document.url
);

export const selectAgreementId = createSelector(
  agreementsSelector,
  (state) => state.savingAccountAgreement.document.documentId
);

export const selectAgreementName = createSelector(
  agreementsSelector,
  (state) => state.savingAccountAgreement.name
);

export const selectSavingAccountAgreement = createSelector(
  agreementsSelector,
  (state) => state.savingAccountAgreement
);

export const selectAgreements = createSelector(
  agreementsSelector,
  (state) => state.agreements
);

export const selectFeeAgreement = createSelector(agreementsSelector, (state) =>
  state.agreements.find((agreement) =>
    agreement.id.toLowerCase().includes('fee')
  )
);

export const selectDepositAccountAgreement = createSelector(
  agreementsSelector,
  (state) =>
    state.agreements.find((agreement) =>
      agreement.id.toLowerCase().includes('deposit')
    )
);
