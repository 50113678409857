interface IAddressFormInputs {
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
}

export const checkAddressForChanges = (
  formAddressData: IAddressFormInputs,
  stateAddressData: IAddressFormInputs
): boolean => {
  return (
    formAddressData.addressLine1 === stateAddressData.addressLine1 &&
    formAddressData.city === stateAddressData.city &&
    formAddressData.state === stateAddressData.state &&
    formAddressData.zipCode === stateAddressData.zipCode
  );
};
