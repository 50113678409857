import { SafeAreaView } from 'react-native-safe-area-context';
import { ReactNode } from 'react';
import MobileHeader, {
  MobileHeaderProps,
} from '@molecules/MobileHeader/MobileHeader';
import { View } from 'tamagui';
import { customTheme } from '../../../styles/customTheme';
import { StatusBar } from 'react-native';

interface HeaderProps {
  children: ReactNode;
  header: MobileHeaderProps;
  isBlue?: boolean;
}

const HeaderMobileTemplate = ({
  header,
  children,
  isBlue = false,
}: HeaderProps) => {
  return (
    <SafeAreaView
      style={{
        backgroundColor: isBlue
          ? customTheme.colors.blue['10']
          : customTheme.colors.white,
        height: '100%',
        flex: 1,
      }}
    >
      <StatusBar barStyle="dark-content" />
      <MobileHeader {...header} />
      <View backgroundColor={isBlue ? '$blue10' : '$white'} h="100%" w="100%">
        {children}
      </View>
    </SafeAreaView>
  );
};

export default HeaderMobileTemplate;
