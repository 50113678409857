import { Alert, Platform } from 'react-native';
import {
  check,
  openSettings,
  PERMISSIONS,
  RESULTS,
} from 'react-native-permissions';
import Airship from '@utils/airship';

type PermissionResult = typeof RESULTS[keyof typeof RESULTS];

const notificationRequestedPermission =
  Platform.OS === 'ios'
    ? PERMISSIONS.IOS.REMINDERS
    : PERMISSIONS.ANDROID.POST_NOTIFICATIONS;

const showPermissionBlockedAlert = () => {
  Alert.alert(
    'Turn on push notifications',
    'Push notifications are turned off on your phone. Open Settings page to enable them',
    [
      {
        style: 'cancel',
        text: 'Cancel',
      },
      {
        onPress: openSettings,
        text: 'Open Settings',
      },
    ]
  );
};

export const handlePermissionCheck = async () => {
  if (Platform.OS !== 'web') {
    const airshipPermissionResult =
      await Airship.push.isUserNotificationsEnabled();

    const permissionResult: PermissionResult = await check(
      notificationRequestedPermission
    );

    if (
      (permissionResult === RESULTS.BLOCKED ||
        permissionResult === RESULTS.DENIED) &&
      airshipPermissionResult
    )
      showPermissionBlockedAlert();
  }
};
