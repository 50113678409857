import { useNavigation } from '@react-navigation/native';
import { HomeNavigatorParamsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { FeeScheduleView } from '@views/Accounts/FeeScheduleView';
import { FEE_SCHEDULE_URL } from '@constants/accounts';

const FeeSchedulePage = () => {
  const navigation = useNavigation<HomeNavigatorParamsProps['navigation']>();
  const navigateBack = () => {
    navigation.goBack();
  };

  return (
    <FeeScheduleView
      navigateBack={navigateBack}
      documentUrl={FEE_SCHEDULE_URL}
    />
  );
};

export default FeeSchedulePage;
