import { Alert, View } from 'native-base';
import { Text } from '@atoms';

const ToastPopup = ({ isOpen, text }: { isOpen: boolean; text: string }) => {
  return (
    <>
      {isOpen ? (
        <View
          width="100%"
          height={'45px'}
          position="absolute"
          top="0"
          zIndex={5}
        >
          <Alert justifyContent="center" status="success" flexDirection="row">
            <Text color="$green40" fontSize="$h4" pl="2">
              {text}
            </Text>
          </Alert>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

export default ToastPopup;
