import { HeaderAndFooterMobileTemplate } from '@templates';
import { TButton, TPressable, Text } from '@atoms';
import { Box, Center } from 'native-base';
import AccountCard from '@molecules/AccountCard/AccountCard';
import amountToString from '@utils/amountToString';
import SwitchAccountsIcon from '../../../assets/SwitchAccountsIcon.svg';
import { IAccountPreview } from '@devTypes/accountTypes';
import AccountIcon from '../../../assets/accounts/paychexpayAccountIcon.svg';
import { Platform } from 'react-native';
import {
  InsufficientFundsAlertModal,
  InsufficientFundsAlertModalProps,
  MobileWebHeader,
} from '@molecules';
import { customTheme } from '../../../styles/customTheme';
import BackButton from '@atoms/BackButton/BackButton';
import React from 'react';
import { Separator, View, XStack, YStack } from 'tamagui';

interface BetweenPaychexPayViewProps extends InsufficientFundsAlertModalProps {
  navigateBack: () => void;
  navigateToAmount: () => void;
  onPressSwitch: () => void;
  fromAccount: IAccountPreview;
  toAccount: IAccountPreview;
  availableTransfersFromSavingNumber: number;
  isSwitched: boolean;
}

interface BetweenPaychexPayViewHeaderProps
  extends InsufficientFundsAlertModalProps {
  children: React.ReactNode;
  navigateBack: () => void;
  navigateToAmount: () => void;
  isContinueDisabled: boolean;
}

const BetweenPaychexPayViewHeader = ({
  children,
  navigateBack,
  navigateToAmount,
  isContinueDisabled,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: BetweenPaychexPayViewHeaderProps) =>
  Platform.OS === 'web' ? (
    <>
      <MobileWebHeader />

      <Box bgColor="white" safeAreaBottom flexGrow={1}>
        <XStack px={'$4'} py={'$4'}>
          <BackButton onPress={navigateBack} fill={customTheme.colors.black} />
          <Center flexGrow={1} mr="24px">
            <Text variant="title" textAlign="center">
              My Paychex Pay accounts
            </Text>
          </Center>
          <View />
        </XStack>
        <Separator borderColor="$gray20" />
        <View flexGrow={1}>
          <View flex={1}>{children}</View>
        </View>
      </Box>

      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  ) : (
    <>
      <HeaderAndFooterMobileTemplate
        footerContent={
          <TButton disabled={isContinueDisabled} onPress={navigateToAmount}>
            Continue
          </TButton>
        }
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: 'My Paychex Pay accounts',
        }}
      >
        {children}
      </HeaderAndFooterMobileTemplate>

      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  );

const BetweenPaychexPayView = ({
  navigateBack,
  navigateToAmount,
  fromAccount,
  toAccount,
  onPressSwitch,
  isSwitched,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: BetweenPaychexPayViewProps) => {
  return (
    <BetweenPaychexPayViewHeader
      navigateBack={navigateBack}
      navigateToAmount={navigateToAmount}
      isContinueDisabled={fromAccount.amount === 0}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    >
      <YStack
        flexGrow={1}
        px={'$4'}
        pt={'$5'}
        pb={'$4'}
        justifyContent="space-between"
      >
        <View>
          <AccountCard
            icon={<AccountIcon />}
            title={fromAccount.name}
            label="From"
            amount={amountToString(fromAccount.amount)}
          />
          <Center pt={5}>
            <TPressable
              p={'$3'}
              borderColor="$gray20"
              borderRadius={12}
              borderWidth={1}
              onPress={onPressSwitch}
              testID="paychexpay.betweenPaychexPayView.button.switchAccountsButton"
            >
              <SwitchAccountsIcon />
            </TPressable>
          </Center>
          <AccountCard
            icon={<AccountIcon />}
            title={toAccount.name}
            label="To"
            amount={amountToString(toAccount.amount)}
          />
          {isSwitched && (
            <Text
              pt={'$8'}
              variant="subtitle"
              textAlign="center"
              testID="paychexpay.betweenPaychexPayView.button.fromSavingsText"
            >
              You are limited to 6 withdrawals per month.
            </Text>
          )}
        </View>
        <Text variant="subtitle" textAlign="center">
          Funds will be available immediately.
        </Text>
      </YStack>
      {Platform.OS === 'web' && (
        <View
          borderTopColor="$gray20"
          borderTopWidth={1}
          bg="white"
          px="$4"
          pt="$4"
          paddingBottom={16}
        >
          <TButton
            disabled={fromAccount.amount === 0}
            onPress={navigateToAmount}
          >
            Continue
          </TButton>
        </View>
      )}
    </BetweenPaychexPayViewHeader>
  );
};

export default BetweenPaychexPayView;
