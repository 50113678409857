import { Dimensions, Platform } from 'react-native';
import { SignInMobileTypes } from '@organisms/SignInMobile/SignInMobile.types';
import { SignInTitle } from '@molecules';
import { Image, View } from 'tamagui';
import { Image as ImageNB } from 'native-base';

const SignInMobile = ({ onPressHelp }: SignInMobileTypes) => {
  return (
    <View
      display="flex"
      flexGrow={1}
      flexDirection="column"
      overflow={'hidden'}
    >
      <View
        display="flex"
        flexGrow={1}
        flex={1}
        backgroundColor="$primaryNormal"
        flexDirection="row"
        maxWidth={Dimensions.get('screen').width}
        alignContent="flex-end"
        alignItems="center"
      >
        {Platform.OS !== 'web' ? (
          <Image
            width={545}
            height={'100%'}
            maxWidth={'unset'}
            resizeMode="contain"
            overflow={'hidden'}
            source={require('../../../assets/signIn/signInCardsBg.png')}
            alt={'Paychex cards'}
          />
        ) : (
          <ImageNB
            width={545}
            height={'100%'}
            maxHeight={435}
            maxWidth={'unset'}
            overflow={'hidden'}
            source={require('../../../assets/signIn/signInCardsBg.png')}
            alt={'Paychex cards'}
          />
        )}
      </View>
      <SignInTitle onPressHelp={onPressHelp} />
    </View>
  );
};

export default SignInMobile;
