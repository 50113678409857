import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import homeWidgetsThunks from './asynkThunks';
import {
  homeWidgetsState,
  WidgetStateInterface,
} from '@store/features/homeWidgets/types';

const initialState = {
  physicalCardDeliveryWidget: {
    activeType: 'onTheWay',
  },
  frozenCardWidget: {
    activeType: '',
  },
  isSavingsWidgetVisible: true,
  isRewardsWidgetVisible: true,
  payBillsUrl: null,
  ddaEligibility: false,
  ddaEligibilityLoading: false,
  ingoData: {
    vendor_customer_id: '',
    vendor_auth_token: '',
  },
} as homeWidgetsState;

const homeWidgetsSlice = createSlice({
  name: 'homeWidgets',
  initialState,
  reducers: {
    updatePhysicalCardDeliveryWidget(
      state,
      action: PayloadAction<WidgetStateInterface>
    ) {
      state.physicalCardDeliveryWidget = action.payload;
    },

    updateFrozenCardWidget(state, action: PayloadAction<WidgetStateInterface>) {
      state.physicalCardDeliveryWidget = action.payload;
    },
    updateSavingsWidgetVisible(state) {
      state.isSavingsWidgetVisible = false;
    },
    updateRewardsWidgetVisible(state) {
      state.isRewardsWidgetVisible = false;
    },
    resetPayBillsUrl(state) {
      state.payBillsUrl = null;
    },
    resetIngoData(state) {
      state.ingoData = {
        vendor_customer_id: '',
        vendor_auth_token: '',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      homeWidgetsThunks.billPay.fulfilled,
      (state, { payload }) => {
        state.payBillsUrl = payload.url;
      }
    );
    builder.addCase(
      homeWidgetsThunks.getIngoData.fulfilled,
      (state, { payload }) => {
        state.ingoData = {
          vendor_customer_id: payload.vendor_customer_id,
          vendor_auth_token: payload.vendor_auth_token,
        };
      }
    );
    builder.addCase(homeWidgetsThunks.ddaEligibility.pending, (state) => {
      state.ddaEligibilityLoading = true;
    });
    builder.addCase(
      homeWidgetsThunks.ddaEligibility.fulfilled,
      (state, { payload }) => {
        state.ddaEligibilityLoading = false;
        state.ddaEligibility = payload.content[0]?.direct_deposit_eligible;
      }
    );
    builder.addCase(homeWidgetsThunks.ddaEligibility.rejected, (state) => {
      state.ddaEligibilityLoading = false;
    });
  },
});

export const { reducer: widgetStateReducer, actions: widgetStateActions } =
  homeWidgetsSlice;
