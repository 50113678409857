/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef } from 'react';

interface OutsideClickProps {
  onOutsideClick?: () => void;
}

const useOutsideClick = ({ onOutsideClick }: OutsideClickProps) => {
  const ref = useRef(null);
  const refStopPropagation = useRef(null); //for button, that open modal

  useEffect(() => {
    if (onOutsideClick) {
      const handleClick = (event: { target: any }) => {
        if (
          refStopPropagation.current &&
          // @ts-ignore
          !refStopPropagation.current.contains(event.target)
        ) {
          // @ts-ignore
          if (ref.current && !ref.current.contains(event.target)) {
            onOutsideClick && onOutsideClick();
          }
        }
      };

      document.addEventListener('click', handleClick, true);

      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }
  }, []);

  return { ref, refStopPropagation };
};

export default useOutsideClick;
