import { Platform, Pressable } from 'react-native';
import { XStack, View, YStack } from 'tamagui';
import atmObjectToIcon from '@utils/atmObjectToIcon';
import { AtmTile } from '@organisms/AtmList/AtmList';
import React from 'react';
import { Button, Line, Text } from '@atoms';
import openMap from '@utils/openMap';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import getDirection from '@utils/getDirection';
import { IMarker } from '@store/features/atmLocations/types';
import addressObjToString from '@utils/addressObjToString';
import metersToMiles from '@utils/metersToMiles';
import GreenMarker from '../../../assets/atm/markerGreen.svg';
import WhiteCircle from '../../../assets/atm/whiteCircle.svg';

interface MarkerInfoProps {
  markerInfo: IMarker;
}

interface MarkerInfoMobileProps extends MarkerInfoProps {
  onPressDirection: () => void;
}

const mobile = ({ markerInfo, onPressDirection }: MarkerInfoMobileProps) => {
  const { bottom: bottomInset } = useSafeAreaInsets();

  const onPressAddress = () => {
    openMap(
      markerInfo.location.coordinates.latitude,
      markerInfo.location.coordinates.longitude
    );
  };
  return (
    <YStack height={'100%'} flexGrow={1} justifyContent="space-between">
      <View>
        <AtmTile
          icon={atmObjectToIcon(markerInfo, { isLarge: false })}
          title={markerInfo.location.business_name}
          subtitle={'ATM'}
          distance={metersToMiles(markerInfo.distance)}
          status={
            markerInfo.location.capabilities.includes('24hours-service')
              ? 'Open 24 hours'
              : ''
          }
          isClosed={false}
        />
        <View paddingVertical={16}>
          <Text variant="subtitle" paddingBottom={8}>
            Address
          </Text>
          <Pressable onPress={onPressAddress}>
            <Text
              color="$primaryNormal"
              numberOfLines={1}
              accessible
              accessibilityLabel={`Address ${addressObjToString(
                markerInfo.location.address
              )}`}
            >
              {addressObjToString(markerInfo.location.address)}
            </Text>
          </Pressable>
        </View>
        <Line />
      </View>
      <View paddingBottom={Platform.OS === 'ios' ? bottomInset : 0}>
        <Line />
        <Text
          textAlign={'center'}
          fontSize={14}
          marginTop={16}
          accessible
          accessibilityLabel={'Limits/fees may apply'}
        >
          Limits/fees may apply
        </Text>
        <View paddingVertical={16}>
          <Button
            onPress={onPressDirection}
            accessible
            accessibilityLabel={'Get directions'}
          >
            Get directions
          </Button>
        </View>
      </View>
    </YStack>
  );
};

const desktop = ({ markerInfo }: MarkerInfoProps) => {
  const onPressAddress = () => {
    openMap(
      markerInfo.location.coordinates.latitude,
      markerInfo.location.coordinates.longitude
    );
  };
  const onPressDirection = () => {
    getDirection({
      lat: markerInfo.location.coordinates.latitude,
      lng: markerInfo.location.coordinates.longitude,
    });
  };
  return (
    <YStack
      flexGrow={1}
      justifyContent="space-between"
      backgroundColor="$blue10"
    >
      <View>
        <View backgroundColor="$primaryNormal" height={76} />
        <View backgroundColor="white" paddingHorizontal={40} marginBottom={16}>
          <View
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            paddingBottom={14}
          >
            <View
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              width={58}
              height={58}
              marginTop={-29}
              padding={5}
              borderRadius={5}
              borderColor="$gray20"
              borderWidth={1}
              backgroundColor="white"
            >
              <View position="relative">
                <GreenMarker color="#19AF92" />
                <View position="absolute" zIndex={1} top={3} left={3}>
                  <WhiteCircle />
                </View>
                <View position="absolute" zIndex={2} top={6} left={6}>
                  {atmObjectToIcon(markerInfo, { isXLarge: false })}
                </View>
              </View>
            </View>
          </View>
          <YStack
            paddingBottom={16}
            flexGrow={1}
            flex={1}
            space={1}
            display={'flex'}
          >
            <XStack justifyContent="space-between" flex={1}>
              <Text flex={10} variant="title">
                {markerInfo.location.business_name}
              </Text>
              <Text
                flex={1}
                flexShrink={0}
                textAlign={'right'}
                fontWeight="$medium"
                color="$gray40"
              >
                {metersToMiles(markerInfo.distance)}
              </Text>
            </XStack>
            <XStack justifyContent="space-between">
              <Text fontWeight="400" color="$gray40">
                {'ATM'}
              </Text>
              <Text color={'$green40'}>
                {markerInfo.location.capabilities.includes('24hours-service')
                  ? 'Open 24 hours'
                  : ''}
              </Text>
            </XStack>
          </YStack>
        </View>
        <View backgroundColor="white" paddingHorizontal={40}>
          <Line />
          <View py="10px">
            <Text variant="subtitle" paddingBottom={1}>
              Address
            </Text>
            <Pressable onPress={onPressAddress}>
              <Text
                fontSize="$body"
                color="$primaryNormal"
                numberOfLines={1}
                accessible
                accessibilityLabel={`Address ${addressObjToString(
                  markerInfo.location.address
                )}`}
              >
                {addressObjToString(markerInfo.location.address)}
              </Text>
            </Pressable>
          </View>
          <Line />
        </View>
      </View>
      <View paddingHorizontal={40} paddingBottom={32}>
        <Button
          onPress={onPressDirection}
          accessible
          accessibilityLabel={'Get directions'}
        >
          Get directions
        </Button>
      </View>
    </YStack>
  );
};

const MarkerInfo = {
  mobile,
  desktop,
};

export default MarkerInfo;
