import { Center } from 'native-base';
import { SideBarTemplate } from '@templates';
import {
  CenterModal,
  InsufficientFundsAlertModal,
  InsufficientFundsAlertModalProps,
  TextInput,
} from '@molecules';
import {
  transfersScreensInfo,
  transfersScreensNames,
} from '@constants/transfersScreensNames';
import React, { useEffect, useRef } from 'react';
import { TButton, TPressable, Text } from '@atoms';
import AccountCard from '@molecules/AccountCard/AccountCard';
import AccountIcon from '../../../assets/accounts/paychexpayAccountIcon.svg';
import amountToString from '@utils/amountToString';
import SwitchAccountsIcon from '../../../assets/SwitchAccountsIcon.svg';
import { formatTransferInput } from '@utils/validateDdaInputs';
import { TransferCompletedContent } from '@organisms';
import { requestStatusType } from '@store/features/cards/types';
import { IAccountPreview } from '@devTypes/accountTypes';
import { MAX_TRANSFER_COMMENT_LENGTH } from '@constants/general';
import useIsLaptop from '@hooks/useIsLaptop';
import { ScrollView, Separator, View, YStack } from 'tamagui';

interface BetweenPaychexPayWebViewProps
  extends InsufficientFundsAlertModalProps {
  fromAccount: IAccountPreview;
  toAccount: IAccountPreview;
  onPressSwitch: () => void;
  isFromSavings: boolean;
  isNotExceedBalance: boolean;
  amount: string;
  setAmount: (s: string) => void;
  comment: string;
  setComment: (s: string) => void;
  onPressTransfer: () => void;
  isButtonDisabled: boolean;
  isCenterModalOpen: boolean;
  onCloseCenterModal: () => void;
  transferStatus: requestStatusType | null;
  isCompleted: boolean;
}

const defaultAccountId = '0000';

const BetweenPaychexPayWebView = ({
  fromAccount,
  toAccount,
  onPressSwitch,
  isFromSavings,
  isNotExceedBalance,
  amount,
  setAmount,
  comment,
  setComment,
  onPressTransfer,
  isButtonDisabled,
  isCenterModalOpen,
  onCloseCenterModal,
  transferStatus,
  isCompleted,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: BetweenPaychexPayWebViewProps) => {
  const isLaptop = useIsLaptop();
  const amountInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const { amount: limitToTransfer } = fromAccount;

  useEffect(() => {
    // @ts-ignore
    amountInputRef.current?.focus();
  }, []);

  return (
    <>
      <View flexGrow={1}>
        <SideBarTemplate disableScroll>
          <View bg="none" height={'100%'} flexGrow={1}>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: 'center',
                pb: isLaptop ? 0 : 5,
                height: '100%',
              }}
            >
              <Center py={isLaptop ? 0 : 7}>
                <YStack
                  p="20px"
                  borderRadius={12}
                  bg="white"
                  w="460px"
                  justifyContent="space-between"
                >
                  <>
                    <View pb={isLaptop ? 2 : 4}>
                      <Text
                        variant="title"
                        textAlign="center"
                        pb={isLaptop ? 2 : 6}
                      >
                        {
                          transfersScreensInfo[
                            transfersScreensNames.betweenPaychexPay
                          ].title
                        }
                      </Text>
                      <AccountCard
                        icon={<AccountIcon />}
                        title={fromAccount.name}
                        label="From"
                        amount={amountToString(fromAccount.amount)}
                        withArrow={false}
                        isDefault={fromAccount.id === defaultAccountId}
                      />
                      <Center pt={4}>
                        <TPressable
                          onPress={onPressSwitch}
                          testID={
                            'paychexpay.transfer.betweenPaychexPayWebView.button.switchAccounts'
                          }
                        >
                          <SwitchAccountsIcon />
                        </TPressable>
                      </Center>
                    </View>
                    <AccountCard
                      icon={<AccountIcon />}
                      title={toAccount.name}
                      label="To"
                      amount={amountToString(toAccount.amount)}
                      withArrow={false}
                      isDefault={toAccount.id === defaultAccountId}
                    />
                    <TextInput
                      customLabel={
                        <Text
                          color="$gray40"
                          lineHeight="$h2"
                          fontSize="$h5"
                          marginBottom={4}
                          paddingTop={16}
                        >
                          Available to transfer: ${limitToTransfer.toFixed(2)}{' '}
                          (No fee)
                        </Text>
                      }
                      errorMessage={`You can transfer up to $${limitToTransfer.toFixed(
                        2
                      )}`}
                      isError={isNotExceedBalance}
                      placeholder="$0.00"
                      args={{
                        ref: amountInputRef,
                        value: amount !== '' ? amount : '',
                        onChangeText: (text: string) => {
                          const validatedValue = formatTransferInput(text);
                          setAmount(validatedValue);
                        },
                        testID:
                          'paychexpay.transfer.betweenPaychexPayWebView.input.amount',
                        onSubmitEditing: () => {
                          // @ts-ignore
                          commentInputRef.current?.focus();
                        },
                      }}
                    />
                    <TextInput
                      styles={{ pt: '$4', pb: isLaptop ? '20px' : '80px' }}
                      label="Note"
                      placeholder="What’s this for?"
                      isError={comment.length > MAX_TRANSFER_COMMENT_LENGTH}
                      errorMessage={`Should contain no more than ${MAX_TRANSFER_COMMENT_LENGTH} digits`}
                      args={{
                        ref: commentInputRef,
                        value: comment,
                        onChangeText: (text: string) => setComment(text),
                        testID:
                          'paychexpay.transfer.betweenPaychexPayWebView.input.description',
                        onSubmitEditing: () => {
                          if (!isButtonDisabled) {
                            onPressTransfer();
                          }
                        },
                      }}
                    />
                    {isFromSavings && (
                      <Text py={'$4'} variant="subtitle" textAlign="center">
                        You are limited to 6 withdrawals per month.
                      </Text>
                    )}
                    <View>
                      <Separator borderColor="$gray20" />
                      <Text variant="subtitle" textAlign="center" py={'$4'}>
                        {
                          transfersScreensInfo[
                            transfersScreensNames.betweenPaychexPay
                          ].hint
                        }
                      </Text>

                      <TButton
                        disabled={isButtonDisabled}
                        onPress={onPressTransfer}
                        isLoading={transferStatus === 'pending'}
                        variant="darkGray"
                        testID={
                          'paychexpay.transfer.betweenPaychexPayWebView.button.transfer'
                        }
                      >
                        Transfer
                      </TButton>
                    </View>
                  </>
                </YStack>
              </Center>
            </ScrollView>
          </View>
        </SideBarTemplate>
        {isCompleted && (
          <CenterModal
            isModalOpen={isCenterModalOpen}
            closeByOutsideClick
            closeModal={onCloseCenterModal}
          >
            <View w="343px" p={'$4'} bg="white" borderRadius={24}>
              <TransferCompletedContent
                transferType={transfersScreensNames.betweenPaychexPay}
              />
              <Separator borderColor="$gray20" mb={'$4'} mt={'$5'} />
              <TButton
                onPress={onCloseCenterModal}
                testID={
                  'paychexpay.transfer.betweenPaychexPayWebView.button.transferCompletedOk'
                }
              >
                Finish
              </TButton>
            </View>
          </CenterModal>
        )}
      </View>
      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  );
};

export default BetweenPaychexPayWebView;
