import { EditAddressProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import EditAddressView from '../../../components/views/More/EditAddressView';
import { useForm } from 'react-hook-form';
import { updateAddressForm } from '@organisms/FillInDataSecondForm/helper';
import { useSelector } from 'react-redux';
import {
  selectAddressSuggestions,
  selectIsAddressVerified,
} from '@store/features/fillInData/selectors';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import fillInDataThunks from '@store/features/fillInData/asyncThunks';
import { useEffect, useState } from 'react';
import {
  IAddressEditForm,
  IAddressSuggestion,
} from '@store/features/fillInData/types';
import statesMap, { statesFull, statesShort } from '@constants/statesMap';
import { selectCardholder } from '@store/features/cardholder/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { checkAddressForChanges } from '@pages/SignUp/FillInDataSecondPage/FillinDataSecondPage.helpers';
import { useAppDispatch } from '@store/hooks';
import { helpScreensNames } from '@constants/helpScreensNames';
import useIsTablet from '@hooks/useIsTablet';
import { Platform } from 'react-native';
import { generalActions } from '@store/features/general/slice';
import { useIsFocused } from '@react-navigation/native';
import { selectProfileSettingsModalVisible } from '@store/features/general/selectors';

const EditAddressPage = ({ navigation }: EditAddressProps) => {
  const isTablet = useIsTablet();
  const isFocused = useIsFocused();
  const [isSaveButtonLoading, setSaveButtonLoading] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isPoBoxAlertVisible, setIsPoBoxAlertVisible] = useState(false);

  const userProfileData = useSelector(selectCardholder);
  const addressSuggestions = useSelector(selectAddressSuggestions);
  const verifyStatus = useSelector(selectIsAddressVerified);

  const [primaryAddress] = userProfileData.addresses.filter(
    (address) => address.address_type === 'PRIMARY'
  );

  const navigateToCustomerService = () => {
    if (isTablet) {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: { pageName: 'customerService' },
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'Help',
              params: {
                screenName: helpScreensNames.customerService,
              },
            },
          },
        ],
      });
    }
    if (Platform.OS === 'web') {
      dispatch(generalActions.setWebNavHelpSubsectionIsVisible(true));
    }
  };

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (Platform.OS === 'web') {
      dispatch(generalActions.setWebNavHelpSubsectionIsVisible(false));
    }
  }, [isFocused]);

  const requestAddressSuggestions = (text: string) => {
    if (text === '') return;
    dispatch(
      fillInDataThunks.postAddressSuggestions({
        search: text,
        limit: 10,
      })
    );
  };

  useEffect(() => {
    requestAddressSuggestions(primaryAddress.address1);
  }, []);

  const handleUpdateCardholder = async () => {
    const filledData = getValues();
    await dispatch(
      cardholderThunks.updateCardholderInfo({
        addresses: [
          {
            address1: filledData.line1,
            address2: filledData.line2,
            address_type: primaryAddress.address_type,
            city: filledData.city,
            country: primaryAddress.country,
            postal_code: filledData.zipCode,
            region: filledData.state,
          },
        ],
      })
    );
  };

  useEffect(() => {
    if (isFormSubmitted) {
      const filledData = getValues();
      const filledDataModified = {
        zipCode: filledData.zipCode,
        city: filledData.city,
        state: filledData.state,
        addressLine1: filledData.line1,
        addressLine2: filledData.line2,
      };
      switch (verifyStatus) {
        case 'notVerified': {
          setError('line1', {
            type: 'custom',
            message: 'This address is not verified',
          });
          break;
        }
        case 'PoBox': {
          setIsPoBoxAlertVisible(true);
          setSaveButtonLoading(false);
          break;
        }
        case 'changed': {
          handleUpdateCardholder();
          setSaveButtonLoading(false);
          navigateBack();
          break;
        }
        case 'verified': {
          if (
            checkAddressForChanges(filledDataModified, {
              addressLine1: primaryAddress.address1,
              zipCode: primaryAddress.postal_code,
              city: primaryAddress.city,
              state: primaryAddress.region,
            })
          ) {
            handleUpdateCardholder();
            setSaveButtonLoading(false);
            navigateBack();
          } else {
            navigation.navigate('HomeNavigator', {
              screen: 'AddressVerificationPage',
              params: {
                verifiedAddress: {
                  addressLine1: primaryAddress.address1,
                  addressLine2: primaryAddress.address2,
                  zipCode: primaryAddress.postal_code,
                  city: primaryAddress.city,
                  state: primaryAddress.region,
                  date: '',
                  ssn: '',
                  country: primaryAddress.country,
                },
                enteredAddress: filledDataModified,
                verificationFlow: 'editProfile',
              },
            });
            setSaveButtonLoading(false);
          }
        }
      }
    }
  }, [verifyStatus, isFormSubmitted]);

  const addressSuggestionHandler = (address: IAddressSuggestion) => {
    reset();
    setValue('line1', address.addressLine1);
    setValue('line2', address.addressLine2);
    setValue('zipCode', address.postalCode);
    setValue('city', address.city);
    setValue('state', statesMap[address.countrySubdivision]);
  };

  const navigateBack = () => {
    navigation.navigate('ProfileSetting');
    dispatch(generalActions.setProfileSettingsModalVisible(false));
    dispatch(generalActions.setProfileUpdated(false));
  };

  const hidePoBoxAlertModal = () => {
    setIsPoBoxAlertVisible(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty: isFormDirty },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm<IAddressEditForm>({
    defaultValues: {
      line1: primaryAddress.address1,
      line2: primaryAddress.address2,
      zipCode: primaryAddress.postal_code,
      city: primaryAddress.city,
      state: primaryAddress.region,
      country: primaryAddress.country,
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(updateAddressForm),
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      generalActions.setModalSubmitHandler(() => {
        navigateBack();
      })
    );
    dispatch(generalActions.setProfileUpdated(isFormDirty));
  }, [isFormDirty]);

  const onSubmit = async (data: IAddressEditForm) => {
    setSaveButtonLoading(true);

    const stateIndex = statesFull.indexOf(data.state.trim());
    const state = stateIndex ? statesShort[stateIndex] : data.state;

    await dispatch(
      fillInDataThunks.postAddressVerify({
        correlationId: '1',
        addressLine1: data.line1,
        addressLine2: data.line2,
        city: data.city,
        countrySubdivision: state,
        postalCode: data.zipCode,
      })
    );

    dispatch(cardholderThunks.getCardholderAdditionalInfo());

    setFormSubmitted(true);
  };

  const profileSettingsModalVisible = useSelector(
    selectProfileSettingsModalVisible
  );

  const setConfirmationModalVisible = (value: boolean) => {
    dispatch(generalActions.setProfileSettingsModalVisible(value));
  };

  return (
    <MediaLayoutTemplate
      Mobile={EditAddressView.mobile}
      Desktop={EditAddressView.desktop}
      navigateBack={navigateBack}
      navigateToCustomerService={navigateToCustomerService}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      isPoBoxAlertVisible={isPoBoxAlertVisible}
      hidePoBoxAlertModal={hidePoBoxAlertModal}
      addressSuggestions={addressSuggestions}
      requestAddressSuggestions={requestAddressSuggestions}
      addressSuggestionHandler={addressSuggestionHandler}
      isSaveButtonLoading={isSaveButtonLoading}
      isFormDirty={isFormDirty}
      profileSettingsModalVisible={profileSettingsModalVisible}
      setConfirmationModalVisible={setConfirmationModalVisible}
      resetForm={reset}
    />
  );
};

export default EditAddressPage;
