import { useMediaQuery } from 'native-base';
import { Platform } from 'react-native';
import { maxMediaQuery } from '@constants/ui';

const useIsPhone = () => {
  const [isPhone] = useMediaQuery({
    maxWidth: maxMediaQuery.mobile,
  });

  return isPhone || Platform.OS !== 'web';
};

export default useIsPhone;
