import { useEffect, useState } from 'react';
import {
  isValidBeneficiaryInfo,
  isValidBeneficiaryInfoFn,
  isValidBeneficiaryInfoLn,
  isValidBeneficiaryInfoEmail,
  isValidBeneficiaryInfoPhone,
} from '@utils/isValidBeneficiaryInfo';

interface BeneficiaryInputProps {
  screenName?: string;
  transferOption: string;
}

const useBeneficiaryInput = ({
  screenName,
  transferOption,
}: BeneficiaryInputProps) => {
  const [benInfo, setBeneficiaryInfo] = useState('');
  const [benFirstName, setbenFirstName] = useState('');
  const [benLastName, setbenLastName] = useState('');
  const [benEmail, setbenEmail] = useState('');
  const [benPhone, setbenPhone] = useState('');
  const [isErrorInput, setIsErrorInput] = useState(false);
  const [isErrorInputFn, setIsErrorInputFn] = useState(false);
  const [isErrorInputLn, setIsErrorInputLn] = useState(false);
  const [isErrorInputPhone, setIsErrorInputPhone] = useState(false);
  const [isErrorInputEmail, setIsErrorInputEmail] = useState(false);

  const isValidationFailed =
    isErrorInputLn ||
    isErrorInputFn ||
    benFirstName === '' ||
    benLastName === '' ||
    (transferOption === 'Email address'
      ? isErrorInputEmail || benEmail === ''
      : isErrorInputPhone || benPhone === '');

  useEffect(() => {
    setbenFirstName('');
    setbenLastName('');
    setbenEmail('');
    setbenPhone('');
  }, [screenName]);

  const onChangebenFirstName = (text: string) => {
    setbenFirstName(text);
    if (isValidBeneficiaryInfoFn(text)) setIsErrorInputFn(false);
  };

  const onChangebenLastName = (text: string) => {
    setbenLastName(text);
    if (isValidBeneficiaryInfoLn(text)) setIsErrorInputLn(false);
  };

  const onChangebenEmail = (text: string) => {
    const trimmedText = text.trim();
    setbenEmail(trimmedText);
    if (isValidBeneficiaryInfoEmail(trimmedText)) setIsErrorInputEmail(false);
  };

  const onChangebenPhone = (text: string) => {
    const cleanedText = text.trim().replace(/\D/g, '');

    const formattedText = cleanedText
      .replace(/^\D*(\d)/, '1')
      .replace(/^(\d{1})(\d)/, '$1 $2')
      .replace(/^(\d{1}) (\d{3})(\d)/, '$1 $2-$3')
      .replace(/^(\d{1}) (\d{3})-(\d{3})/, '$1 $2-$3')
      .replace(/^(\d{1}) (\d{3})-(\d{3})(\d)/, '$1 $2-$3-$4');

    setbenPhone(formattedText);
    if (isValidBeneficiaryInfoPhone(formattedText)) {
      setIsErrorInputPhone(false);
    }
  };

  const onChangeBeneficiaryInfo = (text: string) => {
    setBeneficiaryInfo(text);
    if (isValidBeneficiaryInfo(text)) setIsErrorInput(false);
  };

  return {
    beneficiaryInfoP2P: {
      benFirstName,
      benLastName,
      benEmail,
      benPhone,
    },
    benInfo,
    isErrorInput,
    isErrorInputFn,
    isErrorInputLn,
    isErrorInputEmail,
    isErrorInputPhone,
    isValidationFailed,
    onChangebenFirstName,
    onChangebenLastName,
    onChangebenEmail,
    onChangebenPhone,
    onChangeBeneficiaryInfo,
    setIsErrorInput,
    setIsErrorInputFn,
    setIsErrorInputLn,
    setIsErrorInputEmail,
    setIsErrorInputPhone,
  };
};

export default useBeneficiaryInput;
