import { Dimensions, StyleSheet } from 'react-native';
import { Route } from 'react-native-tab-view';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { TPressable, Text } from '@atoms';
import { useEffect } from 'react';
import { View, XStack } from 'tamagui';

interface AccountTabBarProps {
  navigationState: {
    index: number;
    routes: Route[];
  };
  setIndex: (index: number) => void;
}

const TOGGLE_WIDTH = Dimensions.get('window').width / 2 - 16 - 4;

const AccountTabBar = ({ navigationState, setIndex }: AccountTabBarProps) => {
  const offsetX = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: offsetX.value }],
    };
  });
  const toggleOffset = Dimensions.get('window').width / 2 - 16;
  const onPressDetails = () => {
    setIndex(1);
    offsetX.value = withTiming(toggleOffset, {
      duration: 500,
      easing: Easing.bezier(0.5, 0, 0.5, 1),
    });
  };
  const onPressTransactions = () => {
    setIndex(0);
    offsetX.value = withTiming(0, {
      duration: 500,
      easing: Easing.bezier(0.5, 0, 0.5, 1),
    });
  };
  useEffect(() => {
    if (navigationState.index === 1) {
      onPressDetails();
    }
  });
  return (
    <View px={'$4'} pb={'$4'}>
      <XStack
        bg="$blue20"
        borderRadius={8}
        paddingVertical={2}
        position="relative"
        height={40}
      >
        <Animated.View style={[styles.toggle, animatedStyle]} />
        <TPressable
          width={TOGGLE_WIDTH}
          height={36}
          justifyContent="center"
          alignItems="center"
          onPress={onPressTransactions}
          accessible
          accessibilityLabel={`Account tab ${navigationState.routes[0].title}`}
        >
          <Text
            fontWeight="500"
            color={navigationState.index ? 'black' : '$primaryNormal'}
            fontSize={15}
          >
            {navigationState.routes[0].title}
          </Text>
        </TPressable>
        <TPressable
          width={TOGGLE_WIDTH}
          height={36}
          justifyContent="center"
          alignItems="center"
          onPress={onPressDetails}
          accessible
          accessibilityLabel={`Account tab ${navigationState.routes[1].title}`}
        >
          <Text
            fontWeight="500"
            color={!navigationState.index ? 'black' : '$primaryNormal'}
            fontSize={15}
          >
            {navigationState.routes[1].title}
          </Text>
        </TPressable>
      </XStack>
    </View>
  );
};

const styles = StyleSheet.create({
  toggle: {
    position: 'absolute',
    width: TOGGLE_WIDTH,
    height: 36,
    backgroundColor: 'white',
    borderRadius: 8,
    top: 2,
    left: 2,
  },
});

export default AccountTabBar;
