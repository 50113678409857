import AgreementsView from '@views/SignUp/AgreementsView';
import { useEffect, useRef, useState } from 'react';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useSelector } from 'react-redux';
import {
  selectAgreementId,
  selectAgreementName,
  selectAgreementPdfLink,
} from '@store/features/agreements/selectors';
import agreementsThunks from '@store/features/agreements/asyncThunks';
import { Platform, ScrollView } from 'react-native';
import { SavingsAgreementsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import cardsThunks from '@store/features/cards/asyncThunks';
import { savingAccountSelector } from '@store/features/cards/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { useAppDispatch } from '@store/hooks';
//@ts-ignore
import { getAccessToken, saveAccessToken } from '@utils/accessTokenHelper';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';

const SavingsAgreementsPage = ({ navigation }: SavingsAgreementsProps) => {
  const [isAgreeDisabled, setIsAgreeDisabled] = useState(true);
  const [isAgreeLoading, setIsAgreeLoading] = useState(false);
  const [isResettingToken, setIsResettingToken] = useState(false);
  const [redirectTo, setRedirectTo] = useState<null | 'signUp' | 'other'>(null);

  const savingAccount = useSelector(savingAccountSelector);
  const documentId = useSelector(selectAgreementId);
  const agreementName = useSelector(selectAgreementName);
  const dispatch = useAppDispatch();

  const {
    loginInfo,
    generateNewAccessToken,
    handleWebViewNavigationStateChange,
    isWebViewLoading,
    getAccessTokenSilently,
    onLogin,
    accessTokenRenew,
    onLogout,
  } = useAuth0(setRedirectTo, false);

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const onPressBack = () => {
    navigation.goBack();
  };
  const onPressAgree = () => {
    setIsAgreeLoading(true);
    dispatch(
      agreementsThunks.postAgreementAcknowledgement({
        document_id: documentId,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
    dispatch(cardsThunks.createSavingAccount({}))
      .unwrap()
      .catch((err) => handleRequestError(err));
  };

  const [numberOfPages, setNumberOfPages] = useState(0);
  // eslint-disable-next-line no-console
  const pdfRef = useRef({ setPage: (n: number) => console.log(n) });
  const scrollViewRef = useRef<ScrollView | null>(null);

  const onPressScrollArrow = () => {
    pdfRef.current.setPage(numberOfPages);
    scrollViewRef?.current?.scrollToEnd({ animated: true });
  };

  const agreementPdfLink = useSelector(selectAgreementPdfLink);
  const timerRef = useRef(null);

  useEffect(() => {
    setIsAgreeLoading(false);
    dispatch(agreementsThunks.getAgreementSaving({}))
      .unwrap()
      .catch((err) => handleRequestError(err));
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isAgreeLoading) {
      //@ts-ignore
      timerRef.current = setTimeout(() => {
        setIsAgreeLoading(false);
        goToHome();
      }, 10000);
    }
  }, [isAgreeLoading]);

  const goToHome = () => {
    dispatch(cardholderThunks.getCardholder())
      .unwrap()
      .catch((err) => handleRequestError(err));
    dispatch(cardholderThunks.getCardholderAdditionalInfo());
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'BottomTabsNavigator',
        },
      ],
    });
  };

  useEffect(() => {
    if (redirectTo === 'other') {
      goToHome();
      setIsResettingToken(false);
    }
  }, [redirectTo]);

  useEffect(() => {
    if (savingAccount) {
      const getNewTokenAndClosePage = async () => {
        if (Platform.OS === 'web') {
          try {
            const accessToken = await getAccessTokenSilently({
              ignoreCache: true,
            });
            await saveAccessToken(accessToken);
          } catch (e: any) {
            if (
              e.error === 'login_required' ||
              e.error === 'consent_required'
            ) {
              await onLogin();
            }
            const accessToken = await getAccessTokenSilently({
              ignoreCache: true,
            });
            await saveAccessToken(accessToken);
          }
          await goToHome();
        } else {
          setIsResettingToken(true);
          generateNewAccessToken();
        }
      };

      getNewTokenAndClosePage();
    }
  }, [savingAccount]);

  return (
    <MediaLayoutTemplate
      Mobile={AgreementsView.mobile}
      Desktop={AgreementsView.desktop}
      screenType={'single'}
      onPressAgree={onPressAgree}
      onPressBack={onPressBack}
      onPressScrollArrow={onPressScrollArrow}
      isAgreeDisabled={isAgreeDisabled}
      isAgreeLoading={isAgreeLoading}
      agreementPdfLink={agreementPdfLink}
      agreementName={agreementName}
      title={'Savings Account Agreement and Disclosures'}
      setNumberOfPages={setNumberOfPages}
      setIsAgreeDisabled={setIsAgreeDisabled}
      pdfRef={pdfRef}
      numberOfPages={numberOfPages}
      scrollViewRef={scrollViewRef}
      loginInfo={loginInfo}
      handleWebViewNavigationStateChange={handleWebViewNavigationStateChange}
      isWebViewLoading={isWebViewLoading}
      isResettingToken={isResettingToken}
    />
  );
};

export default SavingsAgreementsPage;
