import { useNavigation } from '@react-navigation/native';
import { AboutDobView } from '@views/SignUp';

const AboutDobPage = () => {
  const navigation = useNavigation();
  const onPressRightButton = () => {
    navigation.goBack();
  };
  return <AboutDobView onPressRightButton={onPressRightButton} />;
};

export default AboutDobPage;
