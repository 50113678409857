import * as React from 'react';
import { StatusBar } from 'react-native';
import { useIsFocused } from '@react-navigation/native';

const FocusAwareStatusBar = ({
  barStyle,
}: {
  barStyle: 'light-content' | 'dark-content';
}) => {
  const isFocused = useIsFocused();

  return isFocused ? (
    <StatusBar
      barStyle={barStyle}
      backgroundColor="transparent"
      translucent={true}
      animated={true}
    />
  ) : null;
};

export default FocusAwareStatusBar;
