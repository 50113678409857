import linking from '../../../navigation/linking';

const authScreens = linking.config.screens.AuthNavigator.screens;
const cipScreens = linking.config.screens.CIPNavigator.screens;
const onboardingScreens = linking.config.screens.OnboardingNavigator.screens;

const protectedScreens = [
  authScreens.FillInDataFirst,
  authScreens.FillInDataSecond,
  authScreens.AgreementsPage,
  authScreens.AboutPFAccount,
  authScreens.AboutSsnPage,
  authScreens.AboutDobPage,
  authScreens.BiometricsPage,
  authScreens.AddressVerificationPage,
  cipScreens.CIPMessagePage,
  cipScreens.OutWalletQuestionsPage,
  cipScreens.VerificationFailedPage,
  onboardingScreens.OnboardingPage,
  onboardingScreens.WelcomeToPaychexPayPage,
  onboardingScreens.HowToUseCardPage,
  onboardingScreens.AllowNotificationsPage,
  onboardingScreens.CreateCardholderLoaderPage,
  linking.config.screens.ErrorPage,
];

export default protectedScreens;
