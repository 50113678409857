import { Text } from '@atoms';
import { WHY_WE_NEED_SSN } from '@constants/policies';
import useIsPhone from '@hooks/useIsPhone';
import { View } from 'tamagui';

interface AboutSsnContentProps {
  withTitle?: boolean;
}

const AboutSsnContent = ({ withTitle = false }: AboutSsnContentProps) => {
  const isPhone = useIsPhone();
  return (
    <>
      {withTitle && (
        <Text
          fontWeight="$medium"
          textAlign="center"
          fontSize="$h3"
          pt="7"
          accessible
          accessibilityLabel={'SSN info'}
        >
          SSN info
        </Text>
      )}
      <View bg="white" px={isPhone ? '$4' : '$10'} pt="$5">
        <Text
          variant="subtitle"
          pb="6"
          accessible
          accessibilityLabel={`${WHY_WE_NEED_SSN}`}
        >
          {WHY_WE_NEED_SSN}
        </Text>
      </View>
    </>
  );
};

export default AboutSsnContent;
