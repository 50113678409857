import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const ddaFormSelect = (state: RootState) => state.ddaForm;

export const selectDdaPdfString = createSelector(
  ddaFormSelect,
  (state) => state.pdfString
);

export const selectIsPdfReady = createSelector(
  ddaFormSelect,
  (state) => state.isPdfReady
);
