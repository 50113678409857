import { HeaderMobileTemplate } from '@templates';
import { FaqContent } from '@organisms';
import faq from '@constants/faq';
import { Platform } from 'react-native';
import { MobileWebHeader } from '@molecules';
import { ScrollView, View } from 'tamagui';

interface FaqViewProps {
  navigateBack: () => void;
  faqData: typeof faq;
  navigationMap: (link: string) => void;
}

const FaqView = ({ navigateBack, faqData, navigationMap }: FaqViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderMobileTemplate
        header={{
          title: 'FAQs',
          isBackButton: true,
          onPressBack: navigateBack,
        }}
      >
        <View height={Platform.OS === 'web' ? '100vh' : '100%'}>
          <ScrollView
            h={0}
            showsVerticalScrollIndicator={false}
            {...(Platform.OS === 'web' && {
              paddingBottom: '60px',
            })}
          >
            <View px={4} pt={2}>
              <FaqContent faqData={faqData} navigationMap={navigationMap} />
            </View>
          </ScrollView>
        </View>
      </HeaderMobileTemplate>
    </>
  );
};

export default FaqView;
