import { Center } from 'native-base';
import {
  DesktopAreaHeader,
  FooterButtonsRow,
  MobileWebHeader,
} from '@molecules';
import { HeaderAndFooterMobileTemplate, SideBarTemplate } from '@templates';
import { Control, FieldErrors } from 'react-hook-form';
import { IFillInDataSecondFormInputs } from '@organisms/FillInDataSecondForm/helper';
import React, { useState } from 'react';
import { FillInDataSecondForm } from '@organisms';
import { IAddressSuggestion } from '@store/features/fillInData/types';
import { Keyboard, Platform } from 'react-native';
import useIsTablet from '@hooks/useIsTablet';
import usePoBoxAlertModal from '@hooks/Animations/usePoBoxAlertModal';
import Animated from 'react-native-reanimated';
import { Button, Text } from '@atoms';
import ConfirmationModal from '@organisms/ConfirmationModal/ConfirmationModal';
import { ScrollView, Separator, View } from 'tamagui';

interface EditAddressViewProps {
  navigateBack: () => void;
  control: Control<IFillInDataSecondFormInputs>;
  onSubmit: () => void;
  errors: FieldErrors;
  addressSuggestions: IAddressSuggestion[];
  requestAddressSuggestions: (text: string) => void;
  addressSuggestionHandler: (address: IAddressSuggestion) => void;
  isSaveButtonLoading: boolean;
  isFormDirty: boolean;
  isPoBoxAlertVisible: boolean;
  hidePoBoxAlertModal: () => void;
  navigateToCustomerService: () => void;
  profileSettingsModalVisible: boolean;
  setConfirmationModalVisible: (value: boolean) => void;
  resetForm: () => void;
}

const mobile = ({
  navigateBack,
  control,
  onSubmit,
  errors,
  addressSuggestions,
  requestAddressSuggestions,
  addressSuggestionHandler,
  isSaveButtonLoading,
  isFormDirty,
  isPoBoxAlertVisible,
  hidePoBoxAlertModal,
  navigateToCustomerService,
  profileSettingsModalVisible,
  setConfirmationModalVisible,
}: EditAddressViewProps) => {
  const { backgroundStyle, isModalVisible } = usePoBoxAlertModal({
    isPoBoxAlertVisible,
  });

  const [isUnsavedChangesAlertVisible, setIsUnsavedChangesAlertVisible] =
    useState(false);

  const cancelHandler = () => {
    if (isFormDirty) {
      Keyboard.dismiss();
      setIsUnsavedChangesAlertVisible(true);
      return;
    }
    navigateBack();
  };

  const goBackHandler = () => {
    if (isUnsavedChangesAlertVisible) {
      setIsUnsavedChangesAlertVisible(false);
    }
    navigateBack();
  };

  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderAndFooterMobileTemplate
        footerContent={
          <FooterButtonsRow
            isLoading={isSaveButtonLoading}
            firstButton={{
              variant: 'gray',
              label: 'Cancel',
              handler: cancelHandler,
            }}
            secondButton={{
              label: 'Save',
              handler: onSubmit,
              isDisabled: Object.keys(errors).length !== 0,
              variant: 'darkGray',
              testID:
                'paychexpay.mobile.editAddressView.footerButtonsRow.button.saveAddress',
            }}
          />
        }
        header={{
          title: 'Address',
          isBackButton: true,
          onPressBack: cancelHandler,
        }}
      >
        <ScrollView
          keyboardShouldPersistTaps={'handled'}
          contentContainerStyle={{
            paddingHorizontal: '$4',
            paddingTop: 6,
            flexGrow: 1,
          }}
          bounces={false}
        >
          <FillInDataSecondForm
            control={control}
            onSubmit={onSubmit}
            errors={errors}
            isEditAddrPage={true}
            isFormDirty={isFormDirty}
            withAdditionalFields={false}
            addressSuggestions={addressSuggestions}
            requestAddressSuggestions={requestAddressSuggestions}
            addressSuggestionHandler={addressSuggestionHandler}
            navigateToCustomerService={navigateToCustomerService}
          />
        </ScrollView>
      </HeaderAndFooterMobileTemplate>

      {isPoBoxAlertVisible && (
        <Center
          position="absolute"
          top={0}
          left={0}
          h="full"
          w="full"
          zIndex={10}
        >
          <Animated.View
            style={[
              backgroundStyle,
              {
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            ]}
          />
          {isModalVisible && (
            <View w="100%" px={4}>
              <View p={'$4'} bg="white" borderRadius={12}>
                <Text variant="title" pt={'$2'} textAlign="center">
                  Please use another address
                </Text>
                <Text variant="subtitle" pt={'$4'} pb={'$5'} textAlign="center">
                  Please use another address instead of PO box
                </Text>
                <Separator borderColor="$gray20" />
                <View pt={'$4'}>
                  <Button onPress={hidePoBoxAlertModal}>Got it</Button>
                </View>
              </View>
            </View>
          )}
        </Center>
      )}

      <ConfirmationModal
        isModalOpen={
          isUnsavedChangesAlertVisible || profileSettingsModalVisible
        }
        setIsConfirmationModalOpen={(value: boolean) => {
          setConfirmationModalVisible(value);
          setIsUnsavedChangesAlertVisible(value);
        }}
        confirmHandler={goBackHandler}
      />
    </>
  );
};
const desktop = ({
  navigateBack,
  control,
  onSubmit,
  errors,
  addressSuggestions,
  requestAddressSuggestions,
  addressSuggestionHandler,
  isSaveButtonLoading,
  isFormDirty,
  isPoBoxAlertVisible,
  hidePoBoxAlertModal,
  navigateToCustomerService,
  profileSettingsModalVisible,
  setConfirmationModalVisible,
  resetForm,
}: EditAddressViewProps) => {
  const isTablet = useIsTablet();
  const { backgroundStyle, isModalVisible } = usePoBoxAlertModal({
    isPoBoxAlertVisible,
  });

  const [isUnsavedChangesAlertVisible, setIsUnsavedChangesAlertVisible] =
    useState(false);

  const cancelHandler = () => {
    if (isFormDirty) {
      Keyboard.dismiss();
      setIsUnsavedChangesAlertVisible(true);
      return;
    }
    navigateBack();
    resetForm();
  };

  const goBackHandler = () => {
    if (isUnsavedChangesAlertVisible) {
      setIsUnsavedChangesAlertVisible(false);
    }
    navigateBack();
    resetForm();
  };

  return (
    <>
      <SideBarTemplate>
        <View px={'$7'}>
          <DesktopAreaHeader
            title="Address"
            backButtonHandler={cancelHandler}
          />
          <View
            mt={'$7'}
            padding={20}
            bg="white"
            borderRadius={12}
            width={isTablet ? '100%' : 364}
          >
            <FillInDataSecondForm
              control={control}
              onSubmit={onSubmit}
              errors={errors}
              isEditAddrPage={true}
              isFormDirty={isFormDirty}
              withAdditionalFields={false}
              addressSuggestions={addressSuggestions}
              requestAddressSuggestions={requestAddressSuggestions}
              addressSuggestionHandler={addressSuggestionHandler}
              navigateToCustomerService={navigateToCustomerService}
            />
            <Separator mb={'$4'} borderColor="$gray20" />
            <FooterButtonsRow
              isLoading={isSaveButtonLoading}
              firstButton={{
                label: 'Cancel',
                handler: cancelHandler,
                variant: 'gray',
                testID:
                  'paychexpay.editAddressView.footerButtonsRow.button.cancel',
              }}
              secondButton={{
                label: 'Save',
                handler: onSubmit,
                isDisabled: Object.keys(errors).length !== 0,
                variant: 'darkGray',
                testID:
                  'paychexpay.editAddressView.footerButtonsRow.button.save',
              }}
            />
          </View>
        </View>
      </SideBarTemplate>
      {isPoBoxAlertVisible && (
        <Center
          position="absolute"
          top={0}
          left={0}
          h="full"
          w="full"
          zIndex={10}
        >
          <Animated.View
            style={[
              backgroundStyle,
              {
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            ]}
          />
          {isModalVisible && (
            <View w={isTablet ? '100%' : '33%'} px={'$4'}>
              <View p={'$4'} bg="white" borderRadius={12}>
                <Text variant="title" pt={'$2'} textAlign="center">
                  Please use another address
                </Text>
                <Text variant="subtitle" pt={'$4'} pb={'$5'} textAlign="center">
                  Please use another address instead of PO box
                </Text>
                <Separator borderColor="$gray20" />
                <View pt={'$4'}>
                  <Button onPress={hidePoBoxAlertModal}>Got it</Button>
                </View>
              </View>
            </View>
          )}
        </Center>
      )}
      <ConfirmationModal
        isModalOpen={
          isUnsavedChangesAlertVisible || profileSettingsModalVisible
        }
        setIsConfirmationModalOpen={(value: boolean) => {
          setConfirmationModalVisible(value);
          setIsUnsavedChangesAlertVisible(value);
        }}
        confirmHandler={goBackHandler}
      />
    </>
  );
};

const EditAddressView = {
  mobile,
  desktop,
};

export default EditAddressView;
