import { View, XStack } from 'tamagui';
import TransactionsList from '@organisms/TransactionsList/TransactionsList';
import { TButton, Text } from '@atoms';
import { ITransaction } from '@store/features/transactions/types';
import React from 'react';
import { TransactionHistorySkeleton } from '@molecules';

export interface TransactionsHistoryWidgetProps {
  transactions: ITransaction[] | null;
  isDesktop?: boolean;
  isLoading: boolean;
  onPressShow: () => void;
  onPressTransaction: (id: string) => void;
}

const TransactionsHistoryWidget = ({
  transactions,
  isDesktop = false,
  isLoading,
  onPressShow,
  onPressTransaction,
}: TransactionsHistoryWidgetProps) => {
  return isLoading ? (
    <TransactionHistorySkeleton />
  ) : (
    <>
      {transactions?.length ? (
        <View
          {...(isDesktop
            ? { padding: 20, backgroundColor: '$white', borderRadius: '$2' }
            : {})}
          marginBottom={isDesktop ? 20 : '$5'}
        >
          <XStack justifyContent="space-between" pb={isDesktop ? '$2' : 0}>
            <Text variant="title">Spending activity</Text>
            {isDesktop && (
              <TButton
                w="fit"
                h="fit"
                variant="simple"
                onPress={onPressShow}
                testID="paychexpay.transactionsHistoryWidget.button.transactionsShowMoreButton"
              >
                Show more
              </TButton>
            )}
          </XStack>
          <TransactionsList
            transactions={transactions}
            onPress={onPressTransaction}
          />
          {!isDesktop && (
            <TButton
              variant="white"
              mt="$5"
              onPress={onPressShow}
              testID="paychexpay.transactionsHistoryWidget.button.transactionsShowMoreButton"
              accessible
              accessibilityLabel={'Show more'}
            >
              Show more
            </TButton>
          )}
        </View>
      ) : null}
    </>
  );
};

export default TransactionsHistoryWidget;
