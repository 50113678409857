import React from 'react';
import { Box, Center } from 'native-base';
import { Platform, useWindowDimensions } from 'react-native';
import { DesktopAreaHeader, MobileWebHeader } from '@molecules';
import { HeaderMobileTemplate, SideBarTemplate } from '@templates';
import WebView from 'react-native-webview';
import useIsLaptop from '@hooks/useIsLaptop';
import { LoadingScreen } from '@views/SignUp/PaychexLoginView';
import BackButton from '@atoms/BackButton/BackButton';
import { customTheme } from '../../../styles/customTheme';
import { Separator, View, XStack } from 'tamagui';
import { Text } from '@atoms';

interface PayBillsViewProps {
  payBillsUrl: string;
  navigateBack: () => void;
}

const desktop = ({ payBillsUrl, navigateBack }: PayBillsViewProps) => {
  const isLaptop = useIsLaptop();
  const { height: desktopHeight } = useWindowDimensions();

  return (
    <SideBarTemplate>
      <View px={7} pb="56px" flexGrow={1}>
        <DesktopAreaHeader title="Pay bills" backButtonHandler={navigateBack} />
        <View
          flexGrow={1}
          bg="white"
          borderRadius={12}
          p="20px"
          mt="20px"
          height={isLaptop ? desktopHeight - 158 : desktopHeight - 128}
        >
          <XStack
            flexGrow={1}
            h="100%"
            testID={'paychexpay.payBillsView.content'}
          >
            <WebView
              originWhitelist={['*']}
              source={{ uri: payBillsUrl }}
              startInLoadingState={true}
              renderLoading={() => <LoadingScreen />}
              scalesPageToFit={true}
              javaScriptEnabled={true}
              javaScriptCanOpenWindowsAutomatically={true}
              cacheEnabled={false}
              style={{
                width: '100%',
              }}
            />
          </XStack>
        </View>
      </View>
    </SideBarTemplate>
  );
};

interface PayBillsMobileHeaderProps {
  navigateBack: () => void;
  children: React.ReactNode;
}

const PayBillsMobileHeader = ({
  navigateBack,
  children,
}: PayBillsMobileHeaderProps) =>
  Platform.OS === 'web' ? (
    <>
      <MobileWebHeader />
      <XStack px={4} py={4} bg={'white'}>
        <BackButton onPress={navigateBack} fill={customTheme.colors.black} />
        <Center flexGrow={1} mr="24px">
          <Text variant="title" textAlign="center">
            Pay bills
          </Text>
        </Center>
        <View />
      </XStack>
      <Separator borderColor="$gray20" />
      {children}
    </>
  ) : (
    <HeaderMobileTemplate
      header={{
        isBackButton: true,
        onPressBack: navigateBack,
        title: 'Pay bills',
      }}
    >
      {children}
    </HeaderMobileTemplate>
  );

const mobile = ({ payBillsUrl, navigateBack }: PayBillsViewProps) => {
  return (
    <PayBillsMobileHeader navigateBack={navigateBack}>
      <Box
        bgColor="white"
        safeAreaBottom={7}
        flexGrow={1}
        {...(Platform.OS === 'web' && { safeAreaBottom: true })}
      >
        <WebView
          originWhitelist={['*']}
          source={{ uri: payBillsUrl }}
          startInLoadingState={true}
          renderLoading={() => <LoadingScreen />}
          scalesPageToFit={true}
          javaScriptEnabled={true}
          javaScriptCanOpenWindowsAutomatically={true}
          cacheEnabled={false}
          style={{
            width: '100%',
          }}
        />
      </Box>
    </PayBillsMobileHeader>
  );
};

const PayBillsView = {
  desktop,
  mobile,
};

export default PayBillsView;
