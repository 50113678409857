export { default as SpendingAccountPage } from './SpendingAccountPage/SpendingAccountPage';
export { default as SavingAccountPage } from './SavingAccountPage/SavingAccountPage';
export { default as SetupDdaPage } from './SetupDdaPage/SetupDdaPage';
export { default as DebitCardPage } from './DebitCardPage/DebitCardPage';
export { default as SupportPage } from './SupportPage/SupportPage';
export { default as StatementsListPage } from './StatementsListPage/StatementsListPage';
export { default as FeeSchedulePage } from './FeeSchedulePage/FeeSchedulePage';
export { default as SavingsAgreementsPage } from './SavingsAgreementsPage/SavingsAgreementPage';
export { default as ChangePinPage } from './ChangePinPage/ChangePinPage';
export { default as OpenSavingsAccountPage } from './OpenSavingsAccountPage/OpenSavingsAccountPage';
