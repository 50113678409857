import { HeaderAndFooterMobileTemplate } from '@templates';
import { TButton } from '@atoms';
import { TransferCompletedContent } from '@organisms';
import { View } from 'tamagui';

interface TransferStatusViewProps {
  navigateHome: () => void;
  transferType: string;
}

const TransferStatusView = ({
  navigateHome,
  transferType,
}: TransferStatusViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      footerContent={
        <TButton
          onPress={navigateHome}
          testID="paychexpay.TransferStatusView.button.finishButton"
        >
          Finish
        </TButton>
      }
      avoidKeyboard={false}
    >
      <View justifyContent="center" flexGrow={1} display="flex">
        <TransferCompletedContent transferType={transferType} />
      </View>
    </HeaderAndFooterMobileTemplate>
  );
};

export default TransferStatusView;
