import * as yup from 'yup';

export interface IFillInDataFirstFormInputs {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface IEmailField {
  emailAddress: string;
}

export interface IPhoneField {
  phoneNumber: string;
}

const requiredErrorMessage = 'This field is required';

const phoneNumberResolver = yup
  .string()
  .required(requiredErrorMessage)
  .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Use this format: (XXX) XXX-XXXX');

const emailAddressResolver = yup
  .string()
  .required(requiredErrorMessage)
  .max(64)
  .matches(
    /^[0-9a-z_.-]+@[a-z]+(\.[a-z]+)?\.[a-z]+$/i,
    'Use this format: example@gmail.com'
  );

export const updateMobilePhoneSchema = yup
  .object()
  .shape({ phoneNumber: phoneNumberResolver })
  .required();

export const updatePersonaEmailSchema = yup
  .object()
  .shape({ emailAddress: emailAddressResolver })
  .required();

export const fillInDataFirstSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(requiredErrorMessage)
    .min(2, 'Include at least 2 characters'),
  lastName: yup
    .string()
    .required(requiredErrorMessage)
    .min(2, 'Include at least 2 characters'),
  phoneNumber: phoneNumberResolver,
  emailAddress: emailAddressResolver,
});

export const phoneOnChange = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{3})(\d)/, '$1) $2')
    .replace(/(\d{3}\) \d{3})(\d{1,4})/, '$1-$2')
    .replace(/(\(\d{3}\) \d{3}-\d{4})\d+?$/, '$1');
};

export const cleanUpPhoneField = (value: string) => {
  return value.replace(/\D/g, '');
};
