import React, { ReactNode } from 'react';
import { Center } from 'native-base';
import Arrow from '../../../assets/back.svg';
import ArrowRotated from '../../../assets/backRotated.svg';
import { customTheme } from '../../../styles/customTheme';
import { FooterButtonsRow } from '@molecules';
import { slidesArrayLength } from '@constants/onboardingSlides';
import { Text, TPressable } from '@atoms';
import { Platform } from 'react-native';
import { View, XStack, YStack } from 'tamagui';

interface SwiperDesktopWrapperProps {
  children: ReactNode;
  index: number;
  onPressPrev: () => void;
  onPressNext: () => void;
  onPressSkip: () => void;
  isModal?: boolean;
}

const SwiperDesktopWrapper = ({
  children,
  index,
  onPressPrev,
  onPressNext,
  onPressSkip,
  isModal = true,
}: SwiperDesktopWrapperProps) => {
  const isRightArrowDisabled = index >= slidesArrayLength;
  const isLeftArrowDisabled = index <= 0;

  const hint1 = (
    <>
      Explore nearby ATMs in the{' '}
      <Text
        fontSize={14}
        fontWeight={'700'}
        display={Platform.OS === 'web' ? 'inline' : 'flex'}
      >
        {Platform.OS === 'web' ? 'ATM finder' : 'More'}
      </Text>{' '}
      section at any time.
    </>
  );

  const hint2 = (
    <>
      Learn about benefits in the{' '}
      <Text
        fontSize={14}
        fontWeight={'700'}
        display={Platform.OS === 'web' ? 'inline' : 'flex'}
      >
        {Platform.OS === 'web' ? 'Mastercard features' : 'More'}
      </Text>{' '}
      section at any time.
    </>
  );

  return (
    <YStack>
      <View>
        <XStack justifyContent="space-between">
          <YStack justifyContent="center" alignItems="center">
            <TPressable
              alignSelf="center"
              height={40}
              width={40}
              marginLeft={55}
              marginTop={230}
              bg="$blue10"
              justifyContent="center"
              alignItems="center"
              borderRadius={20}
              onPress={onPressPrev}
              disabled={isLeftArrowDisabled}
              testID="paychexpay.swiperDesktopWrapper.button.backButton"
              accessible
              accessibilityLabel={'Previous slide'}
            >
              <Arrow
                fill={
                  isLeftArrowDisabled
                    ? customTheme.colors.gray['30']
                    : customTheme.colors.primary.normal
                }
              />
            </TPressable>
          </YStack>
          <View w={325}>{children}</View>
          <YStack justifyContent="center" alignItems="flex-start">
            <TPressable
              height={40}
              width={40}
              marginRight={55}
              marginTop={230}
              bg="$blue10"
              alignSelf="flex-start"
              justifyContent="center"
              alignItems="center"
              borderRadius={20}
              onPress={onPressNext}
              disabled={isRightArrowDisabled}
              testID="paychexpay.swiperDesktopWrapper.button.nextButton"
              accessible
              accessibilityLabel={'Next slide'}
            >
              <ArrowRotated
                fill={
                  isRightArrowDisabled
                    ? customTheme.colors.gray['30']
                    : customTheme.colors.primary.normal
                }
              />
            </TPressable>
          </YStack>
        </XStack>
        {isModal && (
          <XStack width="100%" justifyContent="center" paddingBottom={48}>
            <View width={325}>
              <FooterButtonsRow
                firstButton={{
                  label: 'Skip intro',
                  handler: onPressSkip,
                  testID:
                    'paychexpay.swiperDesktopWrapper.footerButtonsRow.button.skipIntroButton',
                }}
                secondButton={{
                  label: index === slidesArrayLength ? 'Done' : 'Next',
                  handler: onPressNext,
                  testID:
                    'paychexpay.swiperDesktopWrapper.footerButtonsRow.button.nextButton',
                }}
              />
            </View>
          </XStack>
        )}
      </View>
      {index > 0 && (
        <Center>
          <View
            bg="$blue10"
            p={'$4'}
            width="100%"
            borderBottomLeftRadius={12}
            borderBottomRightRadius={12}
          >
            <Text
              fontSize={14}
              fontWeight="400"
              color="black"
              textAlign="center"
            >
              {index === 1 ? hint1 : hint2}
            </Text>
          </View>
        </Center>
      )}
    </YStack>
  );
};

export default SwiperDesktopWrapper;
