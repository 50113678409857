import { TransactionDetailsContent, TransactionsFilter } from '@organisms';
import TransactionsList from '@organisms/TransactionsList/TransactionsList';
import React, { useRef, useState } from 'react';
import { HeaderMobileTemplate, SideBarTemplate } from '@templates';
import { MobileWebHeader, SideModal } from '@molecules';
import {
  ITransaction,
  TransactionType,
} from '@store/features/transactions/types';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import idToTransaction from '@utils/idToTransaction';
import { Text } from '@atoms';
import { useSelector } from 'react-redux';
import { selectDateRange } from '@store/features/transactions/selectors';
import { format } from 'date-fns';
import { ScrollView, View, YStack } from 'tamagui';
import { Dimensions, KeyboardAvoidingView, Platform } from 'react-native';
import { TransfersOptionsType } from '@organisms/TransactionsFilter/TransactionsFilter';
import useIsPhone from '@hooks/useIsPhone';

interface TransfersHistoryViewProps {
  navigateBack: () => void;
  transactions: ITransaction[];
  onOpenTransactionModal: (id: string) => void;
  isModalOpen: boolean;
  toCloseModal: () => void;
  modalName: string | undefined;
  transactionId: string | undefined;
  setTransferFilterOption: (option: TransfersOptionsType | string) => void;
  transactionsSearchText: string;
  transactionsFilteredBy: TransactionType;
  transactionsIsLoading: boolean;
}

interface TransferViewHeaderProps {
  children: React.ReactNode;
  navigateBack: () => void;
}

const TransferViewHeader = ({
  children,
  navigateBack,
}: TransferViewHeaderProps) =>
  Platform.OS === 'web' ? (
    <>
      <MobileWebHeader />
      <HeaderMobileTemplate
        header={{
          title: 'Transfers history',
          isBackButton: true,
          onPressBack: navigateBack,
        }}
      >
        {children}
      </HeaderMobileTemplate>
    </>
  ) : (
    <HeaderMobileTemplate
      header={{
        title: 'Transfers history',
        isBackButton: true,
        onPressBack: navigateBack,
      }}
    >
      {children}
    </HeaderMobileTemplate>
  );
const mobile = ({
  navigateBack,
  onOpenTransactionModal,
  transactions,
  setTransferFilterOption,
  transactionsSearchText,
  transactionsFilteredBy,
  transactionsIsLoading,
}: TransfersHistoryViewProps) => {
  const filterDateRange = useSelector(selectDateRange);
  const windowHeight = Dimensions.get('window').height;
  const scrollWrapperRef = useRef(null);
  const [scrollWrapperHeight, setScrollWrapperHeight] = useState(0);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{
        height: '100%',
      }}
    >
      <TransferViewHeader navigateBack={navigateBack}>
        <YStack px="$4" h="100%" pt="$5">
          <TransactionsFilter.mobile
            filterType={'transfers'}
            setTransferFilterOption={setTransferFilterOption}
          />
          <View
            ref={scrollWrapperRef}
            flexGrow={1}
            height={Platform.OS === 'web' ? scrollWrapperHeight - 40 : 'auto'}
            // @ts-ignore
            onLayout={() => {
              // @ts-ignore
              scrollWrapperRef.current?.measureInWindow((x, y) => {
                const calculatedHeight = windowHeight - y;
                setScrollWrapperHeight(calculatedHeight);
              });
            }}
          >
            <ScrollView
              showsVerticalScrollIndicator={Platform.OS === 'web'}
              contentContainerStyle={{
                flexGrow: 1,
                paddingBottom: 10,
                alignItems: transactions.length === 0 ? 'center' : 'stretch',
                justifyContent:
                  transactions.length === 0 ? 'center' : 'flex-start',
              }}
              style={
                Platform.OS === 'web' && {
                  height: '100%',
                  marginRight: -16,
                  paddingRight: 16,
                }
              }
            >
              <View flexGrow={1}>
                <View
                  flexGrow={1}
                  flexDirection={'column'}
                  display={'flex'}
                  height={'100%'}
                >
                  <TransactionsList
                    transactions={transactions}
                    onPress={onOpenTransactionModal}
                    withHighlight={true}
                    transactionsSearchText={transactionsSearchText}
                    transactionsFilteredBy={transactionsFilteredBy}
                    isLoading={transactionsIsLoading}
                  />
                </View>
              </View>
            </ScrollView>
          </View>
          <YStack
            flex={0}
            backgroundColor={'white'}
            paddingHorizontal={16}
            paddingTop={2}
            marginBottom={Platform.OS === 'android' ? 5 : 10}
          >
            <View
              justifyContent={'center'}
              alignContent={'center'}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
            >
              {filterDateRange?.date_from ? (
                <Text
                  variant={'semiTitle'}
                  fontWeight={'400'}
                  fontSize={14}
                  paddingVertical={10}
                >
                  {filterDateRange?.date_from &&
                    format(
                      new Date(filterDateRange.date_from),
                      'MMM dd, yyyy'
                    )}{' '}
                  {filterDateRange?.date_to &&
                    filterDateRange.date_from !== filterDateRange.date_to &&
                    format(
                      new Date(filterDateRange.date_to),
                      ' - MMM dd, yyyy'
                    )}
                </Text>
              ) : (
                <>
                  {transactions.length > 0 && (
                    <Text
                      variant={'caption'}
                      color={'$gray60'}
                      fontSize={12}
                      paddingTop={2}
                    >
                      Showing last 90 days
                    </Text>
                  )}
                </>
              )}
            </View>
          </YStack>
        </YStack>
      </TransferViewHeader>
    </KeyboardAvoidingView>
  );
};

const desktop = ({
  transactions,
  isModalOpen,
  toCloseModal,
  modalName,
  transactionId,
  onOpenTransactionModal,
  setTransferFilterOption,
  transactionsSearchText,
  transactionsFilteredBy,
  transactionsIsLoading,
}: TransfersHistoryViewProps) => {
  const isPhone = useIsPhone();
  const filterDateRange = useSelector(selectDateRange);

  return (
    <View flexGrow={1}>
      <SideBarTemplate disableScroll={true}>
        <View bg="white" height={'100%'} flexGrow={1}>
          <View h="100%" w="100%" pt="24px" px="20px" flexGrow={1}>
            <TransactionsFilter.desktop
              filterType={'transfers'}
              setTransferFilterOption={setTransferFilterOption}
            />
            <ScrollView
              showsVerticalScrollIndicator={!isPhone}
              contentContainerStyle={{
                pb: 7,
                flexGrow: 1,
                alignItems: transactions.length === 0 ? 'center' : 'stretch',
                justifyContent:
                  transactions.length === 0 ? 'center' : 'flex-start',
              }}
              style={{
                marginRight: -20,
                paddingRight: 20,
              }}
            >
              <TransactionsList
                transactions={transactions}
                onPress={onOpenTransactionModal}
                withHighlight={true}
                transactionsSearchText={transactionsSearchText}
                transactionsFilteredBy={transactionsFilteredBy}
                isLoading={transactionsIsLoading}
              />
            </ScrollView>
            <YStack
              flex={0}
              backgroundColor={'white'}
              paddingHorizontal={16}
              paddingTop={10}
              marginBottom={20}
            >
              <View
                flex={1}
                justifyContent={'center'}
                alignContent={'center'}
                alignItems={'center'}
                display={'flex'}
                flexDirection={'column'}
              >
                {filterDateRange?.date_from ? (
                  <Text
                    variant={'semiTitle'}
                    fontWeight={'400'}
                    fontSize={14}
                    paddingVertical={10}
                  >
                    {filterDateRange?.date_from &&
                      format(
                        new Date(filterDateRange.date_from),
                        'MMM dd, yyyy'
                      )}{' '}
                    {filterDateRange?.date_to &&
                      filterDateRange.date_from !== filterDateRange.date_to &&
                      format(
                        new Date(filterDateRange.date_to),
                        ' - MMM dd, yyyy'
                      )}
                  </Text>
                ) : (
                  <>
                    {transactions.length > 0 && (
                      <Text
                        variant={'caption'}
                        color={'$gray60'}
                        fontSize={12}
                        paddingVertical={10}
                      >
                        Showing last 90 days
                      </Text>
                    )}
                  </>
                )}
              </View>
            </YStack>
          </View>
        </View>
      </SideBarTemplate>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.transaction]: (
              <View bg="$blue10" height="100vh">
                <View w="100%" h="76px" bg="$primaryNormal" mb="-28px" />
                <TransactionDetailsContent
                  transaction={idToTransaction(transactionId, transactions)}
                  isDesktop
                />
              </View>
            ),
          }}
        />
      </SideModal>
    </View>
  );
};

const TransfersHistoryView = {
  mobile,
  desktop,
};

export default TransfersHistoryView;
