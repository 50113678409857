import { Linking, Platform } from 'react-native';

export const callPhone = (phone: string) => {
  let phoneNumber = '';
  if (Platform.OS === 'ios') {
    phoneNumber = `telprompt:${parseInt(phone.replace(/\D/g, ''))}`;
  } else {
    phoneNumber = `tel:${parseInt(phone.replace(/\D/g, ''))}`;
  }
  Linking.openURL(phoneNumber);
};

export const sendEmail = (email: string) => Linking.openURL(`mailto:${email}`);
