import { View } from 'tamagui';
import { ImageWithContent } from '@molecules';
import { TButton, Text } from '@atoms';
import SendFeedbackIcon from '../../../assets/sendFeedback.svg';
import useIsTablet from '@hooks/useIsTablet';

interface SendFeedbackContentProps {
  onSendFeedback?: () => void;
}

const SendFeedbackContent = ({ onSendFeedback }: SendFeedbackContentProps) => {
  const isTablet = useIsTablet();

  return (
    <View
      paddingTop={isTablet ? 40 : 0}
      accessible={false}
      accessibilityLabel="Give Feedback"
    >
      {!isTablet && (
        <Text
          variant="title"
          paddingBottom={40}
          textAlign="center"
          testID={'paychexpay.sendFeedbackContent.sendFeedbackTitle'}
        >
          Give feedback
        </Text>
      )}
      <ImageWithContent image={<SendFeedbackIcon />} space={'$7'}>
        <View px="$4">
          <Text variant="title" paddingBottom={20}>
            Tell us your thoughts
          </Text>
          <Text variant="subtitle">
            Love certain features of the app? Have a suggestion for improvement?
            Good or bad, we want to know.
          </Text>
        </View>
      </ImageWithContent>
      {!isTablet && (
        <TButton
          marginTop={40}
          onPress={onSendFeedback}
          testID={'paychexpay.sendFeedbackContent.sendFeedbackButton'}
        >
          Give feedback
        </TButton>
      )}
    </View>
  );
};

export default SendFeedbackContent;
