import { widgetStateActions } from '@store/features/homeWidgets/slice';
import { useAppDispatch } from '@store/hooks';
import cardholderThunks from '@store/features/cardholder/asyncThunks';

const useTileButtonHandler = () => {
  const dispatch = useAppDispatch();
  return (type: string, activeType: string) => {
    switch (type) {
      case 'physicalCard':
        dispatch(
          widgetStateActions.updatePhysicalCardDeliveryWidget({
            activeType,
          })
        );
        break;
      case 'bills':
        dispatch(
          cardholderThunks.updateCardholderAdditionalInfo({
            show_bill_pay_prompt: false,
          })
        );
        break;
      case 'rewards':
        dispatch(widgetStateActions.updateRewardsWidgetVisible());
        break;
      default:
        break;
    }
  };
};

export default useTileButtonHandler;
