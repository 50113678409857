import { debitCardStatesType } from '@devTypes/cards';
import React from 'react';
import { unfreezeParams } from '@store/features/cards/types';
import { CenterModal } from '@molecules';
import { View, XStack } from 'tamagui';
import { Line, TButton, Text } from '@atoms';
import { customTheme } from '../../../styles/customTheme';

const FreezeCardWebModal = ({
  state,
  isFreezeModalOpen,
  setIsFreezeModalOpen,
  freezeCardHandler,
  unfreezeCardHandler,
}: {
  state: debitCardStatesType;
  isFreezeModalOpen: boolean;
  setIsFreezeModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  freezeCardHandler: () => void;
  unfreezeCardHandler: (params: { calledFrom: unfreezeParams }) => void;
}) => {
  const closeModal = () => {
    setIsFreezeModalOpen(false);
  };

  const onActionPress = () => {
    if (state === 'BLOCK') {
      unfreezeCardHandler({ calledFrom: 'debitCardMenu' });
    } else {
      freezeCardHandler();
    }
  };

  return (
    <CenterModal
      isModalOpen={isFreezeModalOpen}
      closeModal={closeModal}
      closeByOutsideClick
    >
      <View
        display={'flex'}
        flexDirection={'column'}
        maxWidth={343}
        width={'100%'}
        px={'$4'}
        backgroundColor="white"
        borderRadius={12}
        pt={'$6'}
        pb={'$4'}
      >
        {state === 'BLOCK' && (
          <Text textAlign="center" fontSize={16} fontWeight={'600'} pb={'$2'}>
            Unfreeze card?
          </Text>
        )}
        <Text
          fontSize={14}
          fontWeight={'400'}
          color={customTheme.colors.gray[60]}
          textAlign="center"
          pb={'$6'}
          whiteSpace={'pre-line'}
        >
          {state === 'BLOCK'
            ? 'Are you sure you want to unfreeze your card?'
            : 'This action will prevent card transactions from being made, including purchases and ATM withdrawals.\nYou will need to unfreeze your card before you can use it again.'}
        </Text>
        <Line />
        <XStack alignItems={'center'} mt={'$4'}>
          <TButton
            flex={1}
            mr={'$2'}
            variant={'simple'}
            borderColor={'$gray20'}
            borderWidth={1}
            onPress={closeModal}
          >
            Cancel
          </TButton>
          <TButton
            flex={1}
            ml={'$2'}
            onPress={onActionPress}
            backgroundColor={'$primaryNormal'}
            variant={'primary'}
            testID="paychexpay.FreezeCardWebModal.button.ctaButton"
          >
            {state === 'BLOCK' ? 'Unfreeze' : 'Freeze'}
          </TButton>
        </XStack>
      </View>
    </CenterModal>
  );
};

export default FreezeCardWebModal;
