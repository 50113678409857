/* eslint-disable no-constant-condition */
import React, { useMemo } from 'react';
import RightArrow from '../../../assets/homepage/rightArrow.svg';
import amountToString from '@utils/amountToString';
import { navigateToAccountType } from '@devTypes/accounts';
import { Line, TButton, TPressable, Text } from '@atoms';
import { useSelector } from 'react-redux';
import {
  selectSavingsAccountPreviewInfo,
  selectSpendingAccountPreviewInfo,
} from '@store/features/cards/selectors';
import { AccountsWidgetSkeleton } from '@molecules';
import { View, XStack } from 'tamagui';
import { accessibleHitSlop } from '@constants/ui';

export interface AccountsWidgetProps {
  isDesktop?: boolean;
  isLoading: boolean;
  onPressShow: () => void;
  navigateToAccount: navigateToAccountType;
}

const AccountsWidget = ({
  isDesktop = false,
  isLoading,
  onPressShow,
  navigateToAccount,
}: AccountsWidgetProps) => {
  const onPressAccount = (accountType: string) => () => {
    navigateToAccount(accountType);
  };
  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const savingsAccount = useSelector(selectSavingsAccountPreviewInfo);

  const accountsPreviews = useMemo(() => {
    if (savingsAccount.id) {
      return [spendingAccount, savingsAccount];
    } else {
      return [spendingAccount];
    }
  }, [spendingAccount, savingsAccount]);

  return (
    <View>
      <View
        backgroundColor="$white"
        pt={isDesktop ? 20 : 24}
        pb={isDesktop ? (!savingsAccount.id ? 6 : 20) : 0}
        px={isDesktop ? 20 : 16}
        borderRadius="$2"
        borderColor="$gray20"
        borderWidth={1}
      >
        {!isLoading ? (
          <>
            <Text
              variant="title"
              accessible
              accessibilityLabel={'Available balance'}
            >
              Available balance
            </Text>
            <Line mt={8} mb={24} />
            {React.Children.toArray(
              accountsPreviews.map((accountInfo, index: number, arr) =>
                accountInfo ? (
                  <>
                    <TPressable
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      pb={isDesktop ? 0 : 24}
                      cursor="pointer"
                      onPress={onPressAccount(accountInfo.type)}
                      testID={
                        index === 0
                          ? 'paychexpay.accountsWidget.button.spendingAccountButton'
                          : 'paychexpay.accountsWidget.button.savingAccountButton'
                      }
                      accessible
                      accessibilityLabel={
                        index === 0
                          ? `Spending Account, ${amountToString(
                              accountInfo.amount
                            )}`
                          : `Saving Account ${amountToString(
                              accountInfo.amount
                            )}`
                      }
                    >
                      <Text
                        variant="semiTitle"
                        accessible
                        accessibilityLabel={`${accountInfo.name}`}
                      >
                        {accountInfo.name}
                      </Text>
                      <XStack alignItems="center">
                        <Text
                          variant="title"
                          fontWeight="$semiBold"
                          pr="$4"
                          testID={
                            index === 0
                              ? 'paychexpay.accountsWidget.text.spendingAccountAmount'
                              : 'paychexpay.accountsWidget.text.savingAccountAmount'
                          }
                        >
                          {amountToString(accountInfo.amount)}
                        </Text>
                        <View
                          onPress={onPressAccount(accountInfo.type)}
                          hitSlop={accessibleHitSlop}
                        >
                          <RightArrow width="24px" />
                        </View>
                      </XStack>
                    </TPressable>
                    {!index &&
                      isDesktop &&
                      arr.length > 1 &&
                      arr[1] !== null && <Line my={16} />}
                  </>
                ) : (
                  <></>
                )
              )
            )}
            {!savingsAccount.id && <Line mt={isDesktop ? 16 : 0} />}
            {!savingsAccount.id && (
              <TButton
                variant="simple"
                w="100%"
                onPress={onPressShow}
                testID="paychexpay.accountsWidget.button.createASavingsAccountButton"
                accessible
                accessibilityLabel="Open Savings Account, button"
              >
                Open Savings Account
              </TButton>
            )}
          </>
        ) : (
          <AccountsWidgetSkeleton />
        )}
      </View>
    </View>
  );
};

export default AccountsWidget;
