export const transfersScreensNames = {
  history: 'history',
  betweenPaychexPay: 'between-paychex-pay',
  externalAccount: 'external-account',
  toPaychexPayFriends: 'to-paychex-pay-friends',
  toOtherFriends: 'to-other-friends',
};

export interface ITransferStatusContent {
  title: string;
  subtitle: string;
  hint?: string;
}

export const transfersScreensInfo = {
  [transfersScreensNames.betweenPaychexPay]: {
    title: 'My Paychex Pay accounts',
    fee: 'No fee',
    hint: 'Transfers between Paychex Pay accounts occur immediately.',
    completed: {
      title: 'Success!',
      subtitle: 'Your money was transferred.',
    },
    error: {
      title: 'Transfer not sent',
      subtitle:
        'Something went wrong while sending your funds. Try again later.',
    },
  },
  [transfersScreensNames.externalAccount]: {
    title: 'Transfer by account number',
    fee: 'No fee',
    hint: 'Transfers may take up to 3 business days.',
    completed: {
      title: 'Your money is on its way!',
      subtitle: 'It should arrive within the next 3 business days.',
    },
    error: {
      title: 'Transfer not sent',
      subtitle:
        'Something went wrong while sending your funds. Try again later.',
    },
  },
  [transfersScreensNames.toOtherFriends]: {
    title: 'Transfer by phone/email',
    fee: '',
    hint: 'Recipients will receive a link to access their money. Transfers may take up to 3 business days.',
    completed: {
      title: 'Transfer request sent',
      subtitle:
        'The recipient will receive a link requesting them to enter their debit card number to access their money.',
      hint: 'They’ll have 1 day to accept.',
    },
    error: {
      title: 'Transfer not sent',
      subtitle:
        'Something went wrong while sending your funds. Try again later.',
    },
  },
};

export const P2P_TRANSFER_OPTIONS = [
  { value: 'Phone number', label: 'Phone number' },
  { value: 'Email address', label: 'Email address' },
];

export interface IBeneficiaryInput {
  benFirstName: string;
  benLastName: string;
  benEmail: string;
  benPhone: string;
}

export const DEFAULT_AMOUNT_INPUT_LENGTH = 12; // 11+1 because we have '$' in input during the check
