import { View, YStack } from 'tamagui';
import { DebitCardContentProps } from '@organisms/DebitCardContent/DebitCardContent.types';
import { DebitCardPreview } from '@molecules';
import React from 'react';
import DebitCardMenuItem from '@molecules/DebitCardMenuItem/DebitCardMenuItem';
import Animated, {
  Easing,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { PanGestureHandler } from 'react-native-gesture-handler';
import { Line, Text } from '@atoms';
import { PAYCHEX_PAY_DEPOSIT_ACCOUNT } from '@constants/policies';
import { CARD_STATES } from '@constants/cardStates';

const ANIMATION_DURATION = 500;
const VELOCITY = 600;

const mobile = ({
  menuItems,
  state,
  cardInfo,
  cardAuthInfo,
  learnMoreHandler,
  isBlockAnimated,
  cardImgSrc,
}: DebitCardContentProps) => {
  const sliderSpacings = {
    top: 10,
    bottom: 25,
    height:
      state === CARD_STATES.blocked || state === CARD_STATES.frozen ? 310 : 264,
    thresholdRange: 120,
  };
  const offsetY = useSharedValue(0);
  const swiperOffsetY = useSharedValue(0);
  const startY = useSharedValue(0);
  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: offsetY.value }],
    };
  });
  const swiperAnimatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: swiperOffsetY.value - offsetY.value }],
    };
  });
  const gesture = useAnimatedGestureHandler({
    onActive: (e) => {
      const position = e.translationY + startY.value;
      const topBorder = -(sliderSpacings.height + sliderSpacings.top);
      const bottomBorder = sliderSpacings.bottom;
      if (position > 0) {
        offsetY.value = 0;
        if (position > bottomBorder) {
          swiperOffsetY.value = bottomBorder;
        } else {
          swiperOffsetY.value = position;
        }
      } else {
        if (position < topBorder) {
          offsetY.value = topBorder;
          swiperOffsetY.value = topBorder;
        } else {
          offsetY.value = position;
          swiperOffsetY.value = position;
        }
      }
    },
    onEnd: (e) => {
      const position = e.translationY + startY.value;
      if (startY.value === 0 && position > 0) {
        swiperOffsetY.value = withTiming(0, {
          duration: ANIMATION_DURATION,
          easing: Easing.bezier(0.34, 1.4, 0.64, 1),
        });
      } else {
        if (
          (startY.value === 0 &&
            (position < -sliderSpacings.thresholdRange ||
              e.velocityY < -VELOCITY)) ||
          (startY.value !== 0 &&
            position <
              -(sliderSpacings.height - sliderSpacings.thresholdRange) &&
            e.velocityY < VELOCITY)
        ) {
          offsetY.value = withTiming(-sliderSpacings.height, {
            duration: ANIMATION_DURATION,
            easing: Easing.bezier(0.34, 1.4, 0.64, 1),
          });
          swiperOffsetY.value = withTiming(-sliderSpacings.height, {
            duration: ANIMATION_DURATION,
            easing: Easing.bezier(0.34, 1.4, 0.64, 1),
          });
          startY.value = -sliderSpacings.height;
        } else {
          offsetY.value = withTiming(0, {
            duration: ANIMATION_DURATION,
            easing: Easing.bezier(0.34, 1.15, 0.64, 1),
          });
          swiperOffsetY.value = withTiming(0, {
            duration: ANIMATION_DURATION,
            easing: Easing.bezier(0.34, 1.4, 0.64, 1),
          });
          startY.value = 0;
        }
      }
    },
  });
  return (
    <View display={'flex'} flexDirection={'column'} height={'100%'}>
      <View
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        overflow="hidden"
        accessible={false}
        accessibilityLabel="Debit card"
      >
        <Animated.View style={[animatedStyles, { flexGrow: 1 }]}>
          <DebitCardPreview
            cardInfo={cardInfo}
            cardAuthInfo={cardAuthInfo}
            cardImgSrc={cardImgSrc}
            cardStatus={state}
            learnMoreHandler={learnMoreHandler}
            isBlockAnimated={isBlockAnimated}
          />
          <PanGestureHandler
            onGestureEvent={gesture}
            testID="panGestureDebitCardContent"
          >
            <Animated.View style={[swiperAnimatedStyles, { height: '100%' }]}>
              <YStack
                display={'flex'}
                flexGrow={1}
                flex={1}
                borderTopRightRadius={24}
                borderTopLeftRadius={24}
                backgroundColor="white"
                px={'$4'}
                h={'100%'}
                alignItems="center"
              >
                <View
                  h={3}
                  w={23}
                  backgroundColor="$gray20"
                  borderRadius={25}
                  mt={8}
                  mb={5}
                />
                <View width={'100%'}>
                  {menuItems.map((item, index) => (
                    <DebitCardMenuItem
                      key={index}
                      handler={item.handler}
                      title={item.title}
                      subtitle={item.subtitle}
                      hideArrow={item.hideArrow}
                      isDisabled={item.isDisabled}
                    />
                  ))}
                </View>
              </YStack>
            </Animated.View>
          </PanGestureHandler>
        </Animated.View>
      </View>
    </View>
  );
};

const desktop = ({
  menuItems,
  state,
  cardInfo,
  cardAuthInfo,
  learnMoreHandler,
  cardImgSrc,
  isBlockAnimated = false,
}: DebitCardContentProps) => {
  return (
    <YStack flexGrow={1}>
      <View
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        h={88}
      >
        <Text
          variant="title"
          accessible
          accessibilityLabel={'Paychex Pay Debit Card'}
        >
          Paychex Pay Debit Card
        </Text>
      </View>
      <Line color={'$gray20'} />
      <View backgroundColor="$blue10">
        <DebitCardPreview
          cardInfo={cardInfo}
          cardAuthInfo={cardAuthInfo}
          cardStatus={state}
          cardImgSrc={cardImgSrc}
          learnMoreHandler={learnMoreHandler}
          isBlockAnimated={isBlockAnimated}
        />
      </View>
      <YStack px={5} pt={4} pb={7} flexGrow={1} justifyContent="space-between">
        <YStack>
          {menuItems.map((item, index) => (
            <DebitCardMenuItem
              key={index}
              handler={item.handler}
              title={item.title}
              subtitle={item.subtitle}
              hideArrow={item.hideArrow}
              isDisabled={item.isDisabled}
              isLoading={item.isLoading}
              testID={item.testId}
            />
          ))}
        </YStack>
        <Text
          pt={18}
          textAlign="center"
          fontSize={10}
          color="$gray40"
          accessible
          accessibilityLabel={PAYCHEX_PAY_DEPOSIT_ACCOUNT}
        >
          {PAYCHEX_PAY_DEPOSIT_ACCOUNT}
        </Text>
      </YStack>
    </YStack>
  );
};

const DebitCardContent = {
  mobile,
  desktop,
};

export default DebitCardContent;
