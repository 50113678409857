import { Button, Spinner } from 'tamagui';
import { ButtonProps } from '@tamagui/button/types/Button';
import { useMemo } from 'react';
import { Platform } from 'react-native';

const buttonConfig = {
  size: {
    w: '100%',
    h: 48,
    px: 8,
    borderRadius: 8,
    fontWeight: '$semiBold',
    fontSize: '$body',
  },
  variants: {
    white: {
      color: '$primaryNormal',
      bg: '$white',
      borderColor: '$gray20',
      hoverStyle: {
        bg: '$blue10',
      },
      pressStyle: {
        bg: '$blue10',
      },
      focusStyle: {
        outlineWidth: 0,
      },
    },
    primary: {
      color: '$white',
      bg: '$primaryNormal',
      borderColor: '$primaryNormal',
      hoverStyle: {
        bg: '$primaryLight',
        borderColor: '$primaryLight',
      },
      pressStyle: {
        bg: '$primaryDark',
        borderColor: '$primaryDark',
      },
      focusStyle: {
        outlineWidth: 0,
      },
    },
    simple: {
      borderWidth: 0,
      color: '$primaryNormal',
      hoverStyle: {
        color: '$primaryLight',
      },
      pressStyle: {
        color: '$primaryDark',
      },
      focusStyle: {
        outlineWidth: 0,
      },
    },
    gray: {
      bg: '$gray10',
      color: '$gray60',
      hoverStyle: {
        bg: '$blue10',
      },
      pressStyle: {
        bg: '$blue10',
        color: '#0059A2',
      },
    },
    darkGray: {
      borderColor: '$primaryNormal',
      bg: '$primaryNormal',
      color: 'white',
      hoverStyle: {
        bg: '$primaryLight',
        borderColor: '$primaryLight',
      },
      pressStyle: {
        bg: '$primaryDark',
        borderColor: '$primaryDark',
      },
    },
    red: {
      borderWidth: 1,
      bg: 'white',
      borderColor: '$gray10',
      color: '$red50',
      hoverStyle: {
        bg: '$primaryLight',
        borderColor: '$primaryLight',
      },
      pressStyle: {
        bg: '$primaryDark',
        borderColor: '$primaryDark',
      },
    },
  },
  disabledVariants: {
    white: {
      color: '$gray30',
      bg: '$gray10',
      borderColor: '$gray10',
    },
    primary: {
      color: '$white',
      bg: '$gray30',
      borderColor: '$gray30',
    },
    simple: {
      borderWidth: 0,
      color: '$gray30',
    },
    darkGray: {
      bg: '#808797',
      color: '$white',
      borderColor: '#808797',
    },
    gray: {},
    red: {},
  },
};

const variants = { ...buttonConfig.variants } as const;
type buttonVariantsType = keyof typeof variants;

// @ts-ignore
interface TButtonProps extends ButtonProps {
  variant?: buttonVariantsType;
  disabled?: boolean;
  isLoading?: boolean;
  loaderIconColor?: string;
}

const getWebFontProps = ({ fontWeight, buttonConfig }: any) => {
  if (Platform.OS === 'web') {
    if (fontWeight === '100' || fontWeight === '200' || fontWeight === '300') {
      return {
        fontFamily: '$interLight',
        fontWeight: 'normal',
      };
    } else if (
      buttonConfig.size.fontWeight === '$medium' ||
      fontWeight === '$medium' ||
      fontWeight === '500'
    ) {
      return {
        fontFamily: '$interMedium',
        fontWeight: 'normal',
      };
    } else if (buttonConfig.size.fontWeight === '$semiBold') {
      return {
        fontFamily: '$interSemiBold',
        fontWeight: 'normal',
      };
    }
  }
};

const TButton = ({
  variant = 'primary',
  disabled = false,
  isLoading = false,
  fontWeight,
  loaderIconColor = '$white',
  ...props
}: TButtonProps) => {
  const colorConfig = useMemo(
    () =>
      !disabled || isLoading
        ? buttonConfig.variants[variant]
        : buttonConfig.disabledVariants[variant],
    [disabled, variant, isLoading]
  );

  return (
    // @ts-ignore
    <Button
      {...buttonConfig.size}
      {...colorConfig}
      disabled={disabled || isLoading}
      {...props}
      icon={
        isLoading ? (
          <Spinner size="small" color={loaderIconColor} />
        ) : (
          props.icon
        )
      }
      opacity={isLoading ? 0.8 : props.opacity}
      textAlign={'center'}
      textProps={{
        whiteSpace: 'wrap',
        numberOfLines: 0,
      }}
      {...(Platform.OS === 'web' &&
        getWebFontProps({ fontWeight, buttonConfig }))}
      {...(Platform.OS !== 'web' && fontWeight && { fontWeight: fontWeight })}
    />
  );
};

export default TButton;
