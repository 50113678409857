import { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useAuth0 } from '@auth0/auth0-react';
import { resetStore } from '@store';
import { cardholderActions } from '@store/features/cardholder/slice';
import { cardsActions } from '@store/features/cards/slice';
import { transactionsActions } from '@store/features/transactions/slice';
import jwt from 'jwt-decode';
import { IUser, NO_USER_MATCH } from '@hooks/useCustomAuth0/types';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import { saveAccessToken } from '@utils/accessTokenHelper.web';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import fillInDataThunks from '@store/features/fillInData/asyncThunks';
import { oowQuestionsActions } from '@store/features/oowQuestions/slice';
import { useNavigation } from '@react-navigation/native';
import jwtDecode from 'jwt-decode';
import { generalActions } from '@store/features/general/slice';
import { agreementsActions } from '@store/features/agreements/slice';
import homeWidgetsThunks from '@store/features/homeWidgets/asynkThunks';
import generalThunks from '@store/features/general/asyncThunks';

const useAuth0Web = (
  setRedirectTo?: (to: null | 'signUp' | 'other' | 'unableToSignup') => void,
  clearState = true,
  isSkipLanding = false
) => {
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
    isAuthenticated,
    getAccessTokenWithPopup,
  } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [isAccessTokenAvailable, setIsAccessTokenAvailable] = useState(false);
  const dispatch = useAppDispatch();

  const navigation = useNavigation();

  const resetState = () => {
    if (clearState) {
      dispatch(cardholderActions.resetState());
      dispatch(cardsActions.resetState());
      dispatch(transactionsActions.resetState());
      dispatch(oowQuestionsActions.resetState());
      dispatch(generalActions.resetState());
      dispatch(agreementsActions.resetState());
    }
  };

  const onLogin = async (isSilent = false) => {
    try {
      let isExistingCardholder = false;
      setIsLoading(true);
      if (!isSilent) {
        await loginWithRedirect();
      }
      if (isSkipLanding) {
        await loginWithRedirect();
      }
      const accessToken = await getAccessTokenSilently({ ignoreCache: true });
      const user: IUser = jwt(accessToken);
      setIsAccessTokenAvailable(true);
      await saveAccessToken(accessToken);

      resetState();

      dispatch(navigationStatesActions.setIsAuthenticated(true));
      dispatch(navigationStatesActions.setNavigationHistory('#'));
      const getCardholderResponse = await dispatch(
        cardholderThunks.getCardholder()
      );
      dispatch(cardholderThunks.getCardholderAdditionalInfo());
      // @ts-ignore
      if (!getCardholderResponse.payload?.message?.errors)
        isExistingCardholder = true;
      dispatch(fillInDataThunks.getUserInfo());
      dispatch(homeWidgetsThunks.ddaEligibility());
      dispatch(generalThunks.getVariables());
      if (setRedirectTo) {
        if (!isExistingCardholder) {
          const { content } = await dispatch(
            fillInDataThunks.checkIsEligible()
          ).unwrap();
          if (content[0].paychex_pay_eligible === false) {
            setRedirectTo('unableToSignup');
            return;
          }
        }

        if (user['http://payx:com/cpid'] === NO_USER_MATCH) {
          setRedirectTo('signUp');
        } else {
          setRedirectTo('other');
        }
      }
    } catch (error) {
      setError(error);
    }
  };

  const onLogout = async (withLogin = false) => {
    await dispatch(fillInDataThunks.logoutFromSessions());
    secureLocalStorage.removeItem('accessToken');
    await resetStore();
    localStorage.clear();
    sessionStorage.clear();
    logout({
      returnTo: `${window.location.origin}`,
      localOnly: false,
      federated: true,
    });
    if (withLogin) {
      await onLogin(); // fix for safari, where we need to open new tab by user action
    } else {
      // @ts-ignore
      navigation.navigate('AuthNavigator', {
        screen: 'SignInPage',
      });
    }
  };

  const resetAccessToken = () => {
    onLogin(true).then(() => {
      dispatch(cardholderActions.accessTokenReset());
    });
  };

  const getAccessToken = (props: any) => {
    return getAccessTokenSilently(props);
  };

  const handleRedirectAfterLogin = async () => {
    let isExistingCardholder = false;
    const accessToken = await getAccessTokenSilently();
    const user: IUser = jwtDecode(accessToken);
    saveAccessToken(accessToken);
    dispatch(navigationStatesActions.setIsAuthenticated(true));
    dispatch(navigationStatesActions.setNavigationHistory('#'));
    dispatch(navigationStatesActions.setIsAuthenticated(true));
    dispatch(navigationStatesActions.setNavigationHistory('#'));
    try {
      // try catch stands for double requesting access token if firstly provided is not synced with BE yet
      const getCardholderResponse = await dispatch(
        cardholderThunks.getCardholder()
      );
      dispatch(cardholderThunks.getCardholderAdditionalInfo());
      // @ts-ignore
      if (!getCardholderResponse.payload?.message?.errors)
        isExistingCardholder = true;
    } catch (error) {
      const accessTokenRenewed = await getAccessTokenSilently({
        ignoreCache: true,
      });
      await saveAccessToken(accessTokenRenewed);
      const getCardholderResponse = await dispatch(
        cardholderThunks.getCardholder()
      );
      dispatch(cardholderThunks.getCardholderAdditionalInfo());
      // @ts-ignore
      if (!getCardholderResponse.payload?.message?.errors)
        isExistingCardholder = true;
    }
    try {
      // try catch stands for double requesting access token if firstly provided is not synced with BE yet
      await dispatch(fillInDataThunks.getUserInfo());
    } catch (error) {
      const accessTokenRenewed = await getAccessTokenSilently({
        ignoreCache: true,
      });
      await saveAccessToken(accessTokenRenewed);
      await dispatch(fillInDataThunks.getUserInfo());
    }
    dispatch(homeWidgetsThunks.ddaEligibility());
    dispatch(generalThunks.getVariables());
    if (setRedirectTo) {
      if (!isExistingCardholder) {
        const { content } = await dispatch(
          fillInDataThunks.checkIsEligible()
        ).unwrap();
        if (content[0].paychex_pay_eligible === false) {
          setRedirectTo('unableToSignup');
          return;
        }
      }

      if (user['http://payx:com/cpid'] === NO_USER_MATCH) {
        await setRedirectTo('signUp');
        setIsLoading(false);
      } else {
        await setRedirectTo('other');
        setIsLoading(false);
      }
    }
  };

  const accessTokenRenew = async () => {
    await loginWithRedirect().then(async () => {
      const newAccessToken = await getAccessTokenSilently();
      await setIsAccessTokenAvailable(true);
      await saveAccessToken(newAccessToken);
      await dispatch(cardholderThunks.getCardholder());
      await dispatch(cardholderThunks.getCardholderAdditionalInfo());
      await dispatch(fillInDataThunks.getUserInfo());
    });
  };

  return {
    onLogin,
    handleWebViewNavigationStateChange: () => false,
    loginInfo: {
      error,
      isAccessTokenAvailable,
    },
    loading: isLoading,
    onLogout,
    isAuthenticated,
    resetAccessToken,
    getAccessTokenSilently: getAccessToken,
    getAccessTokenWithPopup,
    handleRedirectAfterLogin,
    accessTokenRenew,
  };
};

export default useAuth0Web;
