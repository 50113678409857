import { createSlice } from '@reduxjs/toolkit';
import agreementsThunks from '@store/features/agreements/asyncThunks';
import { IAgreements } from '@store/features/agreements/types';

const initialState = {
  agreements: [
    {
      id: 'Paychex Pay Electronic Consent Agreement',
      name: 'Electronic Communications Agreement',
      isAcknowledged: false,
      document: {
        id: 0,
        documentId: '',
        url: '',
        version: 0,
        name: '',
        activeDate: '',
        createdByUserName: '',
        createOn: '',
        createdTransactionId: '',
      },
    },
    {
      id: 'Paychex Pay Deposit Account Agreement',
      name: 'Deposit Account Agreement',
      isAcknowledged: false,
      document: {
        id: 1,
        documentId: '',
        url: '',
        version: 0,
        name: '',
        activeDate: '',
        createdByUserName: '',
        createOn: '',
        createdTransactionId: '',
      },
    },
    {
      id: 'Paychex Pay Fee Schedule',
      name: 'Deposit Account Fee Schedule',
      isAcknowledged: false,
      document: {
        id: 2,
        documentId: '',
        url: '',
        version: 0,
        name: '',
        activeDate: '',
        createdByUserName: '',
        createOn: '',
        createdTransactionId: '',
      },
    },
    {
      id: 'Paychex Pay Privacy Policy',
      name: 'Central Bank of Kansas City Privacy Policy',
      isAcknowledged: false,
      document: {
        id: 3,
        documentId: '',
        url: '',
        version: 0,
        name: '',
        activeDate: '',
        createdByUserName: '',
        createOn: '',
        createdTransactionId: '',
      },
    },
    {
      id: 'Paychex Pay Terms Of Use',
      name: 'Paychex Pay Terms Of Use',
      isAcknowledged: false,
      document: {
        id: 5,
        documentId: '',
        url: '',
        version: 0,
        name: '',
        activeDate: '',
        createdByUserName: '',
        createOn: '',
        createdTransactionId: '',
      },
    },
  ],
  savingAccountAgreement: {
    id: 'Paychex Pay Savings Account Agreement',
    name: 'Savings Account Agreement and Disclosures',
    isAcknowledged: false,
    document: {
      id: 4,
      documentId: '',
      url: '',
      version: 0,
      name: '',
      activeDate: '',
      createdByUserName: '',
      createOn: '',
      createdTransactionId: '',
    },
  },
} as IAgreements;

const agreementsSlice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      agreementsThunks.postAgreementAcknowledgement.fulfilled,
      (state, { meta }) => {
        const indexToChange = state.agreements.findIndex(
          (agreement) => agreement.id === meta.arg.document_id
        );

        if (indexToChange !== -1) {
          const newAgreements = [...state.agreements];

          newAgreements[indexToChange] = {
            ...newAgreements[indexToChange],
            isAcknowledged: true,
          };

          state.agreements = newAgreements;
        } else {
          if (state.savingAccountAgreement.id === meta.arg.document_id) {
            state.savingAccountAgreement.isAcknowledged = true;
          }
        }
      }
    );
    builder.addCase(
      agreementsThunks.getAgreementById.fulfilled,
      (state, { payload, meta }) => {
        const indexToChange = state.agreements.findIndex(
          (agreement) => agreement.id === meta.arg.document_id
        );

        if (indexToChange !== -1) {
          const newAgreements = [...state.agreements];

          newAgreements[indexToChange] = {
            ...newAgreements[indexToChange],
            document: payload,
          };

          state.agreements = newAgreements;
        }
      }
    );
    builder.addCase(
      agreementsThunks.getAgreementSaving.fulfilled,
      (state, { payload }) => {
        state.savingAccountAgreement.document = payload;
      }
    );
  },
});

export const { reducer: agreementsReducer, actions: agreementsActions } =
  agreementsSlice;
