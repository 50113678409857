const addressObjToString = (
  address:
    | {
        address1: string;
        city: string;
        region: string;
        postal_code: string;
      }
    | undefined
) => {
  return address
    ? address.address1 +
        ', ' +
        address.city +
        ', ' +
        address.region +
        ', ' +
        address.postal_code
    : '';
};

export default addressObjToString;
