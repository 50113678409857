import { BIOMETRY_TYPE } from 'react-native-keychain';
import FaceIdIllustration from '../assets/signIn/FaceIdIllustration.svg';
import TouchIdIllustration from '../assets/signIn/TouchIdIllustration.svg';
import BiometricsIllustration from '../assets/signIn/BiometricsIllustration.svg';
import FingerprintIllustration from '../assets/signIn/FingerprintIllustration.svg';

export const getBiometryIcon = (
  biometryType: BIOMETRY_TYPE | null | '' | undefined
) => {
  switch (biometryType) {
    case 'FaceID':
      return FaceIdIllustration;
    case 'TouchID':
      return TouchIdIllustration;
    case 'Face':
      return BiometricsIllustration;
    case 'Fingerprint':
      return FingerprintIllustration;
    default:
      return BiometricsIllustration;
  }
};

export const getBiometryName = (
  biometryType: BIOMETRY_TYPE | null | '' | undefined
) => {
  switch (biometryType) {
    case 'FaceID':
      return 'Face ID';
    case 'TouchID':
      return 'Touch ID';
    case 'Face':
      return 'Face';
    case 'Fingerprint':
      return 'Fingerprint';
    default:
      return 'Biometrics';
  }
};

export default { getBiometryIcon, getBiometryName };
