import { YStack } from 'tamagui';
import { HintTypes } from '@molecules/Hint/Hint.types';
import useIsPhone from '@hooks/useIsPhone';
import { Button, Text } from '@atoms';
import { accessibleHitSlop } from '@constants/ui';

const Hint = ({ description, hint, hintHandler }: HintTypes) => {
  const isPhone = useIsPhone();
  return (
    <YStack
      flexGrow={0}
      // alignItems="center"
      // justifyContent="center"
      width="100%"
    >
      {isPhone ? (
        <>
          <Text
            fontSize="$h5"
            textAlign="center"
            accessible
            accessibilityLabel={`${description}`}
          >
            {description}
          </Text>
          <Button
            variant="simple"
            onPress={hintHandler}
            p="0"
            testID="paychexpay.hint.button.infoButton"
            hitSlop={accessibleHitSlop}
            accessible
            accessibilityLabel={`${hint}`}
          >
            <Text fontSize="$h4" fontWeight="500" color="$primaryNormal">
              {hint}
            </Text>
          </Button>
        </>
      ) : (
        <Text
          fontSize="$h5"
          textAlign="center"
          accessible
          accessibilityLabel={`${description}`}
        >
          {description}
          <Button
            variant="simple"
            onPress={hintHandler}
            p="0"
            testID="paychexpay.hint.button.infoButton"
            hitSlop={accessibleHitSlop}
            accessible
            accessibilityLabel={`${hint}`}
          >
            <Text fontSize="$h4" fontWeight="500" color="$primaryNormal">
              {' '}
              {hint}
            </Text>
          </Button>
        </Text>
      )}
    </YStack>
  );
};

export default Hint;
