import rewardsApi from './api';
import { IRewardsResponse } from './types';
import { createTypedThunk } from '@utils/createTypedThunk';

const rewardsThunks = {
  getRewardsUrl: createTypedThunk<undefined, IRewardsResponse>(
    'rewards/getRewardsUrl',
    rewardsApi.getRewardsUrl
  ),
};

export default rewardsThunks;
