import { Platform } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import { HomeView } from '@views/BottomTabs';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
import { useIsFocused } from '@react-navigation/native';
import useSwiper from '@hooks/useSwiper';
import { slidesArrayLength } from '@constants/onboardingSlides';
import useNavigateToAccount from '@hooks/useNavigateToAccount';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import useIsPhone from '@hooks/useIsPhone';
import useHomepageRequests from '@hooks/Api/useHomepageRequests';
import { ITrackerData } from '@hooks/Api/useHomepageRequests';
import { useSelector } from 'react-redux';
import { selectSpendingRecentTransactions } from '@store/features/transactions/selectors';
import { widgetStateActions } from '@store/features/homeWidgets/slice';
import useActiveCardToastPopup from '@hooks/useActiveCardToastPopup';
import { agreementsActions } from '@store/features/agreements/slice';
import homeWidgetsThunks from '@store/features/homeWidgets/asynkThunks';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import {
  selectPayBillsUrl,
  selectDdaEligibility,
} from '@store/features/homeWidgets/selectors';
import { ACCOUNT_NAMES } from '@constants/accounts';
import { useAppDispatch } from '@store/hooks';
import { HomePageProps } from '@navigation/BottomsTabsNavigator/BottomTabsNavigator.types';
import { PayBillsButtonTypePressedType } from '@devTypes/home';
import useRewardsWindow from '@hooks/useRewardsWindow';
import useIsTablet from '@hooks/useIsTablet';
import openLinkInAppBrowser from '@utils/openLinkInAppBrowser';
import { directDepositEligibleLink } from '@constants/endpoints';
import notificationsThunks from '@store/features/notifications/asyncThunks';
import { generalActions } from '@store/features/general/slice';
import useUnfreezeCardToastPopup from '@hooks/useUnfreezeCardToastPopup';
import { helpScreensNames } from '@constants/helpScreensNames';
import {
  selectInitialFetchSpending,
  selectInitialFetchSaving,
} from '@store/features/general/selectors';
import { tracker } from '../../eventCollector';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

const HomePage = ({ navigation, route }: HomePageProps) => {
  const spendingCardId = useSelector(selectSpendingAccountId);
  const payBillsUrl = useSelector(selectPayBillsUrl);
  const savingsAccountId = useSelector(selectSavingsAccountId);
  const initialFetchSpending = useSelector(selectInitialFetchSpending);
  const initialFetchSaving = useSelector(selectInitialFetchSaving);
  const ddaEligibility = useSelector(selectDdaEligibility);

  const isFocused = useIsFocused();
  const isPhone = useIsPhone();
  const isTablet = useIsTablet();

  const [isTextConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState(false);

  const [isRedirectToPayBillsLoading, setRedirectToPayBillsLoading] =
    useState(false);
  const [
    redirectToPayBillsButtonTypePressed,
    setRedirectToPayBillsButtonTypePressed,
  ] = useState<PayBillsButtonTypePressedType>('CTA');
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState<boolean>(
    route.params?.onboardingModal?.toLowerCase() === 'true' || false
  );

  const [isOnboardingDDAModalOpen, setIsOnboardingDDAModalOpen] =
    useState(false);
  const [isPayBillButtonDisabled, setPayBillButtonDisabled] = useState(true);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);

  const loadedInitialSavings = useRef(false);

  const {
    isModalOpen,
    modalName,
    toOpenModal,
    toCloseModal,
    transactionId,
    toSetTransactionId,
  } = useSideModal({
    route,
    navigation,
  });

  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const [notificationsSettings, setNotificationsSettings] = useState({
    mobilePushPaychexPay: false,
    text: false,
    email: false,
  });
  const mobilePushPaychexPayChange = (pushValue: boolean) => {
    setNotificationsSettings({
      ...notificationsSettings,
      mobilePushPaychexPay: pushValue,
    });
  };
  const textChange = (textValue: boolean) => {
    setNotificationsSettings({ ...notificationsSettings, text: textValue });
  };
  const emailChange = (emailValue: boolean) => {
    setNotificationsSettings({ ...notificationsSettings, email: emailValue });
  };

  const onAllowPress = async () => {
    await dispatch(
      notificationsThunks.postNotifications({
        'paychexbank.card.status.unblocked': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.bank_transfer.return': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.status.closed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.status.fraud_blocked': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.pin.changed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.bank_transfer.success': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.card.status.blocked': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.auth.failed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.large_atm_withdrawal': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.large_purchase': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.trans.low_balance': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
        'paychexbank.reward.payout_completed': {
          mobilePushPaychexPay: notificationsSettings.mobilePushPaychexPay,
          email: notificationsSettings.email,
          text: notificationsSettings.text,
        },
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
    setIsConfirmationModalOpen(false);
    setIsNotificationsModalOpen(false);
    setIsOnboardingDDAModalOpen(true);
  };

  const onCloseOnboardingModal = () => {
    setIsOnboardingModalOpen(false);
    navigation.setParams({ onboardingModal: 'false' });
    onCloseSkipIntroModal();
  };
  const onPressHelp = () => {
    dispatch(generalActions.setWebNavHelpSubsectionIsVisible(true));
    if (isTablet || isPhone) {
      navigation.navigate('MoreNavigator', { screen: 'Help' });
    } else {
      navigation.navigate({
        // @ts-ignore
        name: 'MoreNavigator',
        params: {
          screen: 'Help',
          params: {
            screenName: helpScreensNames.faq,
          },
        },
      });
    }
  };
  const onPressTransfer = () => {
    if (isPhone) {
      navigation.navigate('BottomTabsNavigator', {
        screen: 'Transfers',
      });
    } else {
      dispatch(generalActions.setWebNavTransferSubsectionIsVisible(true));
      if (!savingsAccountId) {
        navigation.navigate('HomeNavigator', { screen: 'ToExternalBankWeb' });
      } else {
        navigation.navigate('HomeNavigator', {
          screen: 'BetweenPaychexPayWeb',
        });
      }
    }
  };

  const navigateToDda = () => {
    if (ddaEligibility) {
      if (Platform.OS !== 'web') {
        openLinkInAppBrowser(directDepositEligibleLink);
      } else {
        const ddaTab = window.open('', '_blank');
        if (ddaTab) {
          ddaTab.opener = null;
          ddaTab.location.href = directDepositEligibleLink;
        }
      }
    } else {
      if (isTablet) {
        navigation.navigate('HomeNavigator', {
          screen: 'SetupDda',
          params: { stateName: 'homepage' },
        });
      } else {
        toOpenModal(sideModalNames.setupDda)();
      }
    }

    if (isOnboardingDDAModalOpen) {
      setIsOnboardingDDAModalOpen(false);
    }
  };

  const onPressOpenSavingsAccount = () => {
    navigation.navigate('HomeNavigator', { screen: 'OpenSavingsAccount' });
  };

  const navigateToAccount = useNavigateToAccount(navigation);
  const navigateToSpending = () => {
    navigateToAccount('spending');
  };

  useEffect(() => {
    if (isFocused && Platform.OS !== 'web') {
      changeNavigationBarColor('white', true, false);
      //TransparentStatusAndNavigationBar.setBarsStyle(true, 'light-content');
    }
  }, [isFocused]);

  useEffect(() => {
    if (payBillsUrl && !isPayBillButtonDisabled)
      navigation.navigate('HomeNavigator', {
        screen: 'PayBills',
        params: { payBillsUrl: payBillsUrl },
      });

    dispatch(widgetStateActions.resetPayBillsUrl());
    setRedirectToPayBillsLoading(false);
  }, [payBillsUrl, isPayBillButtonDisabled]);

  useEffect(() => {
    if (spendingCardId) {
      setPayBillButtonDisabled(false);
    }
  }, [spendingCardId]);

  const [isSkipIntroModalOpen, setIsSkipIntroModalOpen] = useState(false);
  const onOpenSkipIntroModal = () => {
    if (isOnboardingModalOpen) {
      setIsOnboardingModalOpen(false);
      navigation.setParams({ onboardingModal: 'false' });
      setIsSkipIntroModalOpen(true);
    } else {
      onCloseSkipIntroModal();
    }
  };
  const onCloseSkipIntroModal = () => {
    setIsSkipIntroModalOpen(false);
    setIsNotificationsModalOpen(true);
  };

  const onPressCloseOnboardingDDA = () => {
    setIsOnboardingDDAModalOpen(false);
  };

  const onOpenTransactionModal = (id: string) => {
    toSetTransactionId(id);
    toOpenModal(sideModalNames.transaction)();
  };
  const { onPressNext, onPressPrev, index, setIndex } = useSwiper({
    onPressLastNext: onCloseOnboardingModal,
    componentsArrayLength: slidesArrayLength,
    toOpenModal,
  });
  const handleHideSavingsWidget = () => {
    dispatch(widgetStateActions.updateSavingsWidgetVisible());
  };

  const handleOpenSavings = () => {
    dispatch(agreementsActions.resetState());
    navigation.navigate('HomeNavigator', { screen: 'OpenSavingsAccount' });
  };

  const handleHidePayBillsWidget = () => {
    dispatch(
      cardholderThunks.updateCardholderAdditionalInfo({
        show_bill_pay_prompt: false,
      })
    );
  };

  const handleHideRewardsWidget = () => {
    dispatch(widgetStateActions.updateRewardsWidgetVisible());
  };

  const { handleRewardsButtonPress, isRedirectToRewardsLoading } =
    useRewardsWindow({ navigation });

  const handlePayBillsButtonPress = ({
    buttonType,
  }: {
    buttonType: PayBillsButtonTypePressedType;
  }) => {
    setRedirectToPayBillsButtonTypePressed(buttonType);
    setRedirectToPayBillsLoading(true);
    dispatch(homeWidgetsThunks.billPay({ card_id: spendingCardId }));
  };

  const navigateToDebitCard = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', { screen: 'DebitCard' });
    } else {
      navigation.navigate('HomeNavigator', {
        screen: 'SpendingAccount',
        params: {
          modal: sideModalNames.debitCard,
          accountName: ACCOUNT_NAMES.PaychexPaySpending,
        },
      });
    }
  };

  const showConfirmationModal = () => {
    if (notificationsSettings.text) {
      setIsConfirmationModalOpen(true);
    } else {
      onAllowPress();
    }
  };

  const { fetchSavingData, fetchSpendingData } = useHomepageRequests();

  useEffect(() => {
    if (
      savingsAccountId &&
      !initialFetchSaving &&
      loadedInitialSavings.current
    ) {
      dispatch(generalActions.setInitialFetchSaving(true));
      fetchSavingData();
    }
    // it's handles the case, where user just open a savings account.
    // Without it homepage will stack on infinity loading
  }, [savingsAccountId]);

  //@ts-ignore
  useEffect(() => {
    // View Accounts CT (Home Page Accounts Widget)
    const loadAccountsTxid = tracker.uuid();
    let loadAccountsSubtxnbrSpending;
    let loadAccountsSubtxnbrSavings;
    if (savingsAccountId && !initialFetchSaving) {
      loadAccountsSubtxnbrSpending = '1';
      loadAccountsSubtxnbrSavings = '2';
    }
    if (spendingCardId && !initialFetchSpending) {
      const spendingAccountTimer = tracker.start(
        'Load Accounts Widget: Fetch Spending Account'
      );
      const spendingAccountBizpn = 'Fetch Spending Account';

      const spendingTrackerData: ITrackerData = {
        timer: spendingAccountTimer,
        txid: loadAccountsTxid,
        bizpn: spendingAccountBizpn,
        subtxnbr: loadAccountsSubtxnbrSpending,
      };
      dispatch(generalActions.setInitialFetchSpending(true));
      fetchSpendingData(spendingTrackerData);
    }
    if (savingsAccountId && !initialFetchSaving) {
      const savingsAccountTimer = tracker.start(
        'Load Accounts Widget: Fetch Savings Account'
      );
      const savingsAccountBizpn = 'Fetch Savings Account';
      const savingsTrackerData: ITrackerData = {
        timer: savingsAccountTimer,
        txid: loadAccountsTxid,
        bizpn: savingsAccountBizpn,
        subtxnbr: loadAccountsSubtxnbrSavings,
      };
      dispatch(generalActions.setInitialFetchSaving(true));
      fetchSavingData(savingsTrackerData);
    }
    loadedInitialSavings.current = true;
    return () => {
      dispatch(generalActions.setInitialFetchSpending(false));
      dispatch(generalActions.setInitialFetchSaving(false));
    };
  }, []);

  useEffect(() => {
    if (!isFocused) {
      dispatch(generalActions.setInitialFetchSpending(false));
      dispatch(generalActions.setInitialFetchSaving(false));
      return;
    }
  }, [isFocused]);

  const transactions = useSelector(selectSpendingRecentTransactions);

  const { isActiveCardToastPopupOpen } = useActiveCardToastPopup();
  const { isUnfrozenCardToastPopupOpen } = useUnfreezeCardToastPopup();
  return (
    <MediaLayoutTemplate
      Mobile={HomeView.mobile}
      Desktop={HomeView.desktop}
      notificationsSettings={notificationsSettings}
      mobilePushPaychexPayChange={mobilePushPaychexPayChange}
      emailChange={emailChange}
      textChange={textChange}
      onAllowPress={showConfirmationModal}
      onPressHelp={onPressHelp}
      onPressOpenSavingsAccount={onPressOpenSavingsAccount}
      onPressTransfer={onPressTransfer}
      navigateToAccount={navigateToAccount}
      onPressShowTransactions={navigateToSpending}
      navigateToDda={navigateToDda}
      isOnboardingModalOpen={isOnboardingModalOpen}
      onPressSkip={onOpenSkipIntroModal}
      onPressNext={onPressNext}
      onPressPrev={onPressPrev}
      index={index}
      setIndex={setIndex}
      isNotificationsModalOpen={isNotificationsModalOpen}
      isSkipIntroModalOpen={isSkipIntroModalOpen}
      onCloseSkipIntroModal={onCloseSkipIntroModal}
      onOpenTransactionModal={onOpenTransactionModal}
      transactionId={transactionId}
      isOnboardingDDAModalOpen={isOnboardingDDAModalOpen}
      onPressCloseOnboardingDDA={onPressCloseOnboardingDDA}
      toCloseModal={toCloseModal}
      isModalOpen={isModalOpen}
      modalName={modalName}
      toOpenModal={toOpenModal}
      transactions={transactions}
      onOpenSavings={handleOpenSavings}
      onHideSavingsWidget={handleHideSavingsWidget}
      isActiveCardToastPopupOpen={isActiveCardToastPopupOpen}
      isUnfrozenCardToastPopupOpen={isUnfrozenCardToastPopupOpen}
      onHidePayBillsWidget={handleHidePayBillsWidget}
      onHideRewardsWidget={handleHideRewardsWidget}
      onRewardsButtonPress={handleRewardsButtonPress}
      isRedirectToRewardsLoading={isRedirectToRewardsLoading}
      onPayBillsButtonPress={handlePayBillsButtonPress}
      isPayBillButtonDisabled={isPayBillButtonDisabled}
      isRedirectToPayBillsLoading={isRedirectToPayBillsLoading}
      redirectToPayBillsButtonTypePressed={redirectToPayBillsButtonTypePressed}
      navigateToDebitCard={navigateToDebitCard}
      isTextConfirmationModalOpen={isTextConfirmationModalOpen}
      setIsConfirmationModalOpen={setIsConfirmationModalOpen}
      confirmHandler={onAllowPress}
    />
  );
};

export default HomePage;
