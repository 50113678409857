import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INavigationStatesState } from '@store/features/navigationStates/types';

const initialState = {
  isAuthenticated: false,
  isAuthorizedCardholder: false,
  navigationHistory: null,
  lastScreenUrl: null,
  isLogoutModalOpen: false,
  initialUrl: '',
  currentRouteTitle: '',
} as INavigationStatesState;

const navigationStatesSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setIsAuthorizedCardholder(state, action: PayloadAction<boolean>) {
      state.isAuthorizedCardholder = action.payload;
    },
    setNavigationHistory(state, action: PayloadAction<string>) {
      state.navigationHistory = action.payload;
    },
    setLastScreenUrl(state, action: PayloadAction<string | null>) {
      state.lastScreenUrl = action.payload;
    },
    setIsLogoutModalOpen(state, action: PayloadAction<boolean>) {
      state.isLogoutModalOpen = action.payload;
    },
    setInitialUrl(state, action: PayloadAction<string>) {
      state.initialUrl = action.payload;
    },
    setCurrentRouteTitle(state, action: PayloadAction<string>) {
      state.currentRouteTitle = action.payload;
    },
  },
});

export const {
  reducer: navigationStatesReducer,
  actions: navigationStatesActions,
} = navigationStatesSlice;
