import { ReactNode, useMemo } from 'react';
import { ActivityIndicator, Platform, Pressable } from 'react-native';
import { View } from 'tamagui';
import { Text } from '@atoms';
import useIsPhone from '@hooks/useIsPhone';
import NavigationArrow from '../../../assets/navigationArrow.svg';
import { customTheme } from '../../../styles/customTheme';

interface MapButtonsWrapperProps {
  onPressSearchArea: () => void;
  onPressLocation?: () => void;
  children: ReactNode;
  isShowThisAriaButton: boolean;
  isRequestWithoutResponse?: boolean;
  isPermissionDenied?: boolean;
}

const MapButtonsWrapper = ({
  children,
  onPressSearchArea,
  onPressLocation,
  isShowThisAriaButton,
  isRequestWithoutResponse,
  isPermissionDenied,
}: MapButtonsWrapperProps) => {
  const isPhone = useIsPhone();

  const showLocationButton = useMemo(() => {
    if (isPhone) {
      return false;
    } else {
      return !(Platform.OS === 'web' && isPermissionDenied);
    }
  }, [isPhone, isPermissionDenied]);

  return (
    <View
      flexGrow={1}
      position="relative"
      {...(Platform.OS === 'web' && { height: '100%' })}
    >
      {isShowThisAriaButton && (
        <View
          zIndex={1}
          position="absolute"
          top={0}
          left={0}
          mt={44}
          flex={0}
          w="100%"
          h={0}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Pressable
            style={{
              borderRadius: 8,
              backgroundColor: 'white',
              paddingHorizontal: 16,
              height: 40,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={onPressSearchArea}
          >
            <Text fontWeight="400" color="$primaryNormal">
              Search this area
            </Text>
          </Pressable>
        </View>
      )}
      {showLocationButton && (
        <Pressable
          style={{
            position: 'absolute',
            bottom: 16,
            right: 16,
            zIndex: 1,
            borderRadius: 8,
            backgroundColor: 'white',
            width: 48,
            height: 48,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={onPressLocation}
          disabled={isRequestWithoutResponse}
        >
          {isRequestWithoutResponse && !isPermissionDenied ? (
            <ActivityIndicator color={customTheme.colors.primary.normal} />
          ) : (
            <NavigationArrow />
          )}
        </Pressable>
      )}
      {children}
    </View>
  );
};

export default MapButtonsWrapper;
