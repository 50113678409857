import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';
import { ITransaction } from '@store/features/transactions/types';
import { transactionToIsMerchant } from '@utils/transactionsHelper';

const transactionsSelector = (state: RootState) => state.transactions;

const filterTransactions = (item: ITransaction) =>
  item.transaction_type !== 'DIRECT_LOADS' &&
  item.transaction_type !== 'PERMANENT_CREDIT' &&
  item.transaction_type !== 'PERMANENT_DEBIT' &&
  item.transaction_type !== 'PRE_AUTH' &&
  item.transaction_type !== 'PRE_AUTH_COMPLETE' &&
  item.transaction_type !== 'WRITE_OFF' &&
  item.transaction_type !== 'GREENDOT_LOAD' &&
  item.transaction_type !== 'CREDIT_AUTH' &&
  !transactionToIsMerchant(item);

export const selectSearchText = createSelector(
  transactionsSelector,
  (state) => {
    return state.searchText;
  }
);

export const selectFilteredBy = createSelector(
  transactionsSelector,
  (state) => {
    return state.filteredBy;
  }
);

export const selectDateRange = createSelector(transactionsSelector, (state) => {
  return state.dateRange;
});

export const selectSpendingRecentTransactions = createSelector(
  transactionsSelector,
  (state) => {
    if (state.spending.transactions.length === 0) return [];
    return state.spending.transactions.slice(0, 5);
  }
);

export const selectSpendingAllTransactions = createSelector(
  transactionsSelector,
  (state) => {
    return state.spending.transactions;
  }
);

export const selectSpendingAllTransactionsItemsCount = createSelector(
  transactionsSelector,
  (state) => {
    return state.spending.transactionsItemCount;
  }
);

export const selectSavingAllTransactionsItemsCount = createSelector(
  transactionsSelector,
  (state) => {
    return state.saving.transactionsItemCount;
  }
);

export const selectSpendingAllTransactionsFiltered = createSelector(
  transactionsSelector,
  (state) => {
    return state.spending.filteredTransactions;
  }
);

export const selectSavingsRecentTransactions = createSelector(
  transactionsSelector,
  (state) => {
    return state.saving.transactions.slice(0, 5);
  }
);

export const selectSavingsAllTransactions = createSelector(
  transactionsSelector,
  (state) => {
    return state.saving.transactions;
  }
);

export const selectSavingsAllTransactionsFiltered = createSelector(
  transactionsSelector,
  (state) => {
    return state.saving.filteredTransactions;
  }
);

export const selectAllTransfers = createSelector(
  transactionsSelector,
  (state) => {
    return [
      ...state.spending.filteredTransactions.filter(filterTransactions),
      ...state.saving.filteredTransactions.filter(filterTransactions),
    ].sort((a, b) =>
      a.transaction_datetime < b.transaction_datetime ? 1 : -1
    );
  }
);

export const selectSpendingTransfers = createSelector(
  transactionsSelector,
  (state) => {
    return [...state.spending.filteredTransactions.filter(filterTransactions)];
  }
);

export const selectSavingsTransfers = createSelector(
  transactionsSelector,
  (state) => {
    return [...state.saving.filteredTransactions.filter(filterTransactions)];
  }
);

export const selectIsLoadingSpendingTransactions = createSelector(
  transactionsSelector,
  (state) => state.spending.isLoading
);
export const selectIsLoadingSavingsTransactions = createSelector(
  transactionsSelector,
  (state) => state.saving.isLoading
);

export const selectIsFetchedInitiallySpendingTransactions = createSelector(
  transactionsSelector,
  (state) => state.spending.fetchedInitially
);
export const selectIsFetchedInitiallySavingsTransactions = createSelector(
  transactionsSelector,
  (state) => state.saving.fetchedInitially
);
