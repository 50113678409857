import React, { ReactNode } from 'react';

import { StatusBar } from 'native-base';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import CloseIcon from '../../../assets/closeIcon.svg';
import { customTheme } from '../../../styles/customTheme';
import { Platform } from 'react-native';
import BackArrowIcon from '../../../assets/back.svg';
import useIsPhone from '@hooks/useIsPhone';
import { View, XStack } from 'tamagui';
import { TPressable } from '@atoms';

interface TransactionDetailsTemplateProps {
  children: ReactNode;
  navigateBack: () => void;
}

const TransactionDetailsTemplate = ({
  children,
  navigateBack,
}: TransactionDetailsTemplateProps) => {
  const { top: topInset } = useSafeAreaInsets();
  const isPhone = useIsPhone();
  const isWebStyle = Platform.OS === 'web' && !isPhone;
  const isWebMobileStyle = Platform.OS === 'web' && isPhone;
  return (
    <View
      paddingTop={isWebStyle ? 20 : isWebMobileStyle ? 20 : topInset}
      bg="$primaryNormal"
    >
      <StatusBar barStyle="light-content" />
      <XStack
        px={4}
        py={isWebStyle ? 2 : 0}
        justifyContent={isWebStyle ? 'flex-end' : 'flex-start'}
      >
        <TPressable
          onPress={navigateBack}
          hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}
          accessible
          accessibilityLabel={`${isWebStyle ? 'Close' : 'Back'} button`}
        >
          {isWebStyle ? (
            <CloseIcon
              width="24px"
              height="24px"
              fill={customTheme.colors.white}
              testID="paychexpay.TransactionDetailsTemplate.button.closeButton"
            />
          ) : (
            <BackArrowIcon
              width="24px"
              height="24px"
              fill={customTheme.colors.white}
              testID="paychexpay.TransactionDetailsTemplate.button.backButton"
            />
          )}
        </TPressable>
      </XStack>
      {children}
    </View>
  );
};

export default TransactionDetailsTemplate;
