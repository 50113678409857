import React from 'react';
import { AccountTemplate } from '@templates';
import AccountTransactions from '@organisms/AccountTransactions/AccountTransactions';
import AccountDetails from '@organisms/AccountDetails/AccountDetails';
import {
  AccountViewProps,
  SavingAccountDesktopProps,
} from '@devTypes/accounts';
import AccountDesktopTemplate from '@templates/AccountDesktopTemplate/AccountDesktopTemplate';
import { useNavigation } from '@react-navigation/native';
import { HomeNavigatorParamsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { RootNavigatorParamProps } from '@navigation/RootNavigator/RootNavigator.types';
import { SideModal } from '@molecules';
import { TransactionDetailsContent } from '@organisms';
import idToTransaction from '@utils/idToTransaction';
import SupportScreenContent from '@organisms/SupportScreenContent/SupportScreenContent';
import SUPPORT_PAGES from '@constants/support';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import StatementItem from '@molecules/StatementItem/StatementItem';
import { dateStringToMonthYearLongFormat } from '@utils/dateHelpers';
import TCImage from '../../../assets/TCupdate.svg';
import { Text } from '@atoms';
import { Separator, View } from 'tamagui';

const mobile = ({
  navigateBack,
  account,
  transactions,
  filteredTransactions,
  transactionsSearchText,
  transactionsFilteredBy,
  menuItems,
  copyToClipboard,
  ToastPopup,
  navigation,
  filterType,
  loaderOverlay,
  setLoaderOverlay,
  isLoadingTransactions,
  isRefreshLoading,
  onRefreshTransactionsList,
}: AccountViewProps) => {
  return (
    <>
      {ToastPopup}
      <AccountTemplate
        withWebHeader
        navigateBack={navigateBack}
        accountName={account.name}
        accountAmount={account.amount}
        isClosedAccount={account.status === 'CLOSED'}
        // @ts-ignore
        TransactionsElement={AccountTransactions}
        loaderOverlay={loaderOverlay}
        transactionsElementProps={{
          isRefreshLoading,
          onRefreshTransactionsList,
          setLoaderOverlay,
          transactions,
          filteredTransactions,
          transactionsSearchText,
          transactionsFilteredBy,
          navigation,
          filterType,
          isLoadingTransactions,
        }}
        // @ts-ignore
        AccountDetailsElement={AccountDetails}
        accountDetailsElementProps={{
          account,
          menuItems,
          copyToClipboard,
        }}
        navigation={navigation}
      />
    </>
  );
};

const desktop = ({
  navigateBack,
  account,
  transactions,
  filteredTransactions,
  transactionsSearchText,
  transactionsFilteredBy,
  menuItems,
  copyToClipboard,
  ToastPopup,
  onOpenTransactionModal,
  transactionId,
  isModalOpen,
  toCloseModal,
  modalName,
  statementsList,
  onPdfIconPress,
  filterType,
  loaderOverlay,
  setLoaderOverlay,
  isLoadingTransactions,
}: SavingAccountDesktopProps) => {
  const navigation = useNavigation<
    HomeNavigatorParamsProps['navigation'] &
      RootNavigatorParamProps['navigation']
  >();
  const onPressTransaction = (id: string) => {
    navigation.navigate('HomeNavigator', {
      screen: 'TransactionDetails',
      params: { transactionId: id },
    });
  };

  return (
    <>
      {ToastPopup}
      <AccountDesktopTemplate
        account={account}
        isClosedAccount={account.status === 'CLOSED'}
        menuItems={menuItems}
        navigateBack={navigateBack}
        transactions={transactions}
        filteredTransactions={filteredTransactions}
        transactionsSearchText={transactionsSearchText}
        transactionsFilteredBy={transactionsFilteredBy}
        copyToClipboard={copyToClipboard}
        onPressTransaction={onPressTransaction}
        onOpenTransactionModal={onOpenTransactionModal}
        navigation={navigation}
        filterType={filterType}
        loaderOverlay={loaderOverlay}
        setLoaderOverlay={setLoaderOverlay}
        isLoadingTransactions={isLoadingTransactions}
      />
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.transaction]: (
              <View bg="$blue10" height="100vh">
                <View width="100%" h="76px" bg="$primaryNormal" mb="-28px" />
                <TransactionDetailsContent
                  transaction={idToTransaction(
                    transactionId,
                    filteredTransactions
                  )}
                  isDesktop
                />
              </View>
            ),
            [sideModalNames.closeAccount]: (
              <SupportScreenContent {...SUPPORT_PAGES.closeSaving} />
            ),
            [sideModalNames.statementsList]: (
              <>
                <View py="$4">
                  <Text
                    fontWeight="600"
                    fontSize={16}
                    textAlign="center"
                    paddingVertical={6}
                  >
                    Savings statements
                  </Text>
                </View>
                <Separator borderColor="$gray20" />
                <View>
                  {statementsList.length > 0 ? (
                    <>
                      {statementsList.map((statement) => {
                        return (
                          <StatementItem
                            title={dateStringToMonthYearLongFormat(
                              statement.start_date
                            )}
                            key={statement.id}
                            statementId={statement.id}
                            onPdfIconPress={onPdfIconPress}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <View
                      flexGrow={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                      pt={'$7'}
                    >
                      <TCImage />
                      <Text variant="title" pb={'$2'} mt={'$7'}>
                        No statements to display
                      </Text>
                      <Text variant="subtitle" pb={'$6'}>
                        Statements will appear here once they’re available.
                      </Text>
                    </View>
                  )}
                </View>
              </>
            ),
          }}
        />
      </SideModal>
    </>
  );
};

const SavingAccountView = {
  mobile,
  desktop,
};

export default SavingAccountView;
