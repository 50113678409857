import { SignInView } from '@views/SignUp';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import useSignIn from '@hooks/useSignIn';

const SignInPage = () => {
  const {
    onSignInPress,
    isLoading,
    onPressHelp,
    isBiometricPending,
    biometricsIcon,
    onPressBiometrics,
    isSignInButtonDisabled,
  } = useSignIn();
  return (
    <MediaLayoutTemplate
      Mobile={SignInView.mobile}
      onPressBiometrics={onPressBiometrics}
      biometricsIcon={biometricsIcon}
      isBiometricPending={isBiometricPending}
      onPressHelp={onPressHelp}
      onSignInPress={onSignInPress}
      isSignInButtonDisabled={isSignInButtonDisabled}
      isLoading={isLoading}
    />
  );
};

export default SignInPage;
