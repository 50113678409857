import React from 'react';
import { TransactionDetailsTemplate } from '@templates';
import { TransactionDetailsViewProps } from '@devTypes/transaction';
import { TransactionDetailsContent } from '@organisms';

const mobile = ({ navigateBack, transaction }: TransactionDetailsViewProps) => {
  return (
    <TransactionDetailsTemplate navigateBack={navigateBack}>
      <TransactionDetailsContent transaction={transaction} />
    </TransactionDetailsTemplate>
  );
};

const TransactionDetailsView = {
  mobile,
};

export default TransactionDetailsView;
