// @ts-nocheck
import { useMediaQuery } from 'native-base';
import { View, YStack } from 'tamagui';
import useIsPhone from '@hooks/useIsPhone';
import OnboardingImage1 from '../../../assets/onbordingIllustrations/onboarding1.svg';
import OnboardingImage2 from '../../../assets/onbordingIllustrations/onboarding2.svg';
import OnboardingImage3 from '../../../assets/onbordingIllustrations/onboarding3.svg';
import { Text } from '@atoms';
import CardDeliveredTileIcon from '../../../assets/tiles/cardDelivered.svg';
import React from 'react';
import TCImage from '../../../assets/TCupdate.svg';

export interface OnboardingSlideProps {
  index: number;
  title: string;
  subtitle: string | JSX.Element;
  hint?: string | JSX.Element;
  space?: number | string;
  description?: string;
}

const OnboardingSlide = ({
  //TODO: fix tamagui values (crash issue)
  index,
  title,
  subtitle,
  hint,
  description,
}: OnboardingSlideProps) => {
  const isPhone = useIsPhone();
  const [isSmallScreen] = useMediaQuery({
    maxHeight: 640,
  });
  const [isLaptop] = useMediaQuery({
    maxWidth: 1024,
  });

  const imageStyles = {
    height: isPhone ? (isSmallScreen ? 138 : 240) : isLaptop ? 166 : 249,
    width: isPhone ? (isSmallScreen ? 170 : 295) : isLaptop ? 208 : 312,
  };

  return (
    <View
      display={'flex'}
      flexGrow={1}
      backgroundColor="white"
      justifyContent={'center'}
      alignItems="center"
      pt={isLaptop ? 64 : 48}
      pb={isLaptop ? 8 : 25}
    >
      <YStack
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        flex={0}
        justifyContent={'center'}
        alignItems="center"
      >
        {index === 1 && (
          <View testID={'paychexpay.onboardingImage.getPaidImage'}>
            <OnboardingImage1 style={imageStyles} />
          </View>
        )}
        {index === 2 && (
          <View testID={'paychexpay.onboardingImage.getCashImage'}>
            <OnboardingImage2 style={imageStyles} />
          </View>
        )}
        {index === 3 && (
          <View testID={'paychexpay.onboardingImage.mastercardImage'}>
            <OnboardingImage3 style={imageStyles} />
          </View>
        )}
        {index === 4 && (
          <View
            backgroundColor={'$blue10'}
            width={160}
            height={160}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            borderRadius={14}
            testID={'paychexpay.onboardingImage.ddaImage'}
          >
            <CardDeliveredTileIcon width={114} height={114} />
          </View>
        )}
        {index === 5 && (
          <View
            backgroundColor={'$blue10'}
            width={160}
            height={160}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}
            borderRadius={14}
            testID={'paychexpay.onboardingImage.reAgreementsImage'}
          >
            <TCImage width={'114px'} height={'114px'} />
          </View>
        )}
        <YStack
          flexGrow={1}
          w="100%"
          alignItems="center"
          justifyContent={isPhone ? 'center' : 'flex-start'}
          px={40}
          pt={isPhone ? 50 : isLaptop ? 32 : 48}
        >
          <View position="relative" mb={8} alignItems="center">
            <Text variant="title" textAlign="center">
              {title}
            </Text>
          </View>
          <Text
            variant="subtitle"
            textAlign="center"
            w={isPhone ? '100%' : 325}
            whiteSpace={'pre-line'}
          >
            {subtitle}
          </Text>
          {description && (
            <Text
              variant="subtitle"
              fontSize={10}
              lineHeight={12}
              textAlign="center"
              color={'$gray40'}
              paddingVertical={36}
              w={isPhone ? '100%' : 325}
              whiteSpace={'pre-line'}
            >
              {description}
            </Text>
          )}
        </YStack>
        {isPhone && hint && (
          <View px={5} w="80%" pt={50}>
            <View backgroundColor="$blue10" borderRadius={12} p={12}>
              <Text fontSize={14} textAlign="center">
                {hint}
              </Text>
            </View>
          </View>
        )}
      </YStack>
    </View>
  );
};

export default OnboardingSlide;
