import {
  createTypedThunk,
  createTypedThunkOptionalParams,
} from '@utils/createTypedThunk';
import externalBankAccountsAPI from '@store/features/externalBankAccounts/api';
import {
  IExternalAccountDeleteRequest,
  IExternalAccountRequest,
  IExternalAccountsResponse,
  IExternalBankAccount,
} from '@store/features/externalBankAccounts/types';
import { IAdditionalHeadersRequest } from '@store/api/types';

const externalBankAccountsThunks = {
  getExternalAccounts: createTypedThunkOptionalParams<
    IAdditionalHeadersRequest,
    IExternalAccountsResponse
  >(
    'externalBankAccounts/getExternalAccounts',
    externalBankAccountsAPI.getExternalAccounts
  ),
  postExternalAccount: createTypedThunk<
    IExternalAccountRequest,
    IExternalBankAccount
  >(
    'externalBankAccount/postExternalAccount',
    externalBankAccountsAPI.postExternalAccount
  ),
  deleteExternalAccount: createTypedThunk<
    IExternalAccountDeleteRequest,
    undefined
  >(
    'externalBankAccount/deleteExternalAccount',
    externalBankAccountsAPI.deleteExternalAccount
  ),
};

export default externalBankAccountsThunks;
