import { useCallback, useState } from 'react';
import { Platform } from 'react-native';
import rewardsApi from '@store/features/rewards/api';
import useIsTablet from '@hooks/useIsTablet';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '@store/hooks';
import { selectShowCashBackPrompt } from '@store/features/cardholder/selectors';
import cardholderThunks from '@store/features/cardholder/asyncThunks';

const useRewardsWindow = ({ navigation }: { navigation: any }) => {
  const dispatch = useAppDispatch();
  const isTablet = useIsTablet();
  const showCashBackPrompt = useSelector(selectShowCashBackPrompt);
  const [isRedirectToRewardsLoading, setRedirectToRewardsLoading] =
    useState(false);

  const handleRewardsButtonPress = useCallback(async () => {
    if (showCashBackPrompt || showCashBackPrompt === null) {
      dispatch(
        cardholderThunks.updateCardholderAdditionalInfo({
          show_cash_back_prompt: false,
        })
      );
      navigation.navigate('HomeNavigator', { screen: 'CashBackIntro' });
    } else {
      setRedirectToRewardsLoading(true);

      if (Platform.OS == 'web') {
        if (isTablet) {
          const rewardsUrlTab = window.open('', '_blank');
          //magic: on mobile safari we need to open window first and then do the async code

          const url = await rewardsApi.getRewardsUrl();
          const rewardsUrl = url.data?.data.secure_url;
          if (rewardsUrl) {
            if (rewardsUrlTab) {
              rewardsUrlTab.opener = null;
              rewardsUrlTab.location.href = rewardsUrl;
            }
          }
        } else {
          const url = await rewardsApi.getRewardsUrl();
          const rewardsUrl = url.data?.data.secure_url;
          if (rewardsUrl) {
            const rewardsUrlTab = window.open('', '_blank');

            if (rewardsUrlTab) {
              rewardsUrlTab.opener = null;
              rewardsUrlTab.location.href = rewardsUrl;
            }
          }
        }

        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [
              {
                name: 'BottomTabsNavigator',
                params: {
                  screen: 'HomePage',
                },
              },
            ],
          });
        }, 500);
      } else {
        navigation.navigate('HomeNavigator', { screen: 'CashBack' });
      }

      setRedirectToRewardsLoading(false);
    }
  }, [showCashBackPrompt]);

  return {
    handleRewardsButtonPress,
    isRedirectToRewardsLoading,
  };
};

export default useRewardsWindow;
