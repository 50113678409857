import CardLogo from '../../assets/CardLogo.svg';
import LockIcon from '../../assets/lockIcon.svg';
import BlockedCardTop from '../../assets/cards/blockedCardTopCurtain.svg';
import BlockedCardBottom from '../../assets/cards/blockedCardBottomCurtain.svg';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import {
  blockCardAnimationDuration,
  blockedCardBottomCurtainHeight,
  blockedCardTopCurtainHeight,
} from '@constants/cardAnimations';
import { View } from 'tamagui';
import { Text } from '@atoms';

const useBlockCardAnimation = ({
  isBlockAnimated,
}: {
  isBlockAnimated: boolean;
}) => {
  const topCurtainPosition = useSharedValue(
    isBlockAnimated ? 0 : -blockedCardTopCurtainHeight
  );
  const bottomCurtainPosition = useSharedValue(
    isBlockAnimated ? 0 : -blockedCardBottomCurtainHeight
  );

  const animatedTopCurtainStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      top: topCurtainPosition.value,
    };
  }, []);

  const animatedBottomCurtainStyle = useAnimatedStyle(() => {
    return {
      position: 'absolute',
      bottom: bottomCurtainPosition.value,
    };
  }, []);

  const animate = (node: any, toValue: number) => {
    node.value = withTiming(toValue, {
      duration: blockCardAnimationDuration,
      easing: Easing.bounce,
    });
  };

  const animateBlock = () => {
    animate(topCurtainPosition, 0);
    animate(bottomCurtainPosition, 0);
  };

  const animateUnblock = () => {
    topCurtainPosition.value = withSpring(-blockedCardTopCurtainHeight);
    bottomCurtainPosition.value = withSpring(-blockedCardBottomCurtainHeight);
  };

  const BlockedCardLayout = (
    <View
      position="absolute"
      zIndex={3}
      width={343}
      height={202}
      overflow="hidden"
      borderRadius={14}
    >
      <View position="relative" width={343} height={202} overflow="hidden">
        <Animated.View style={[animatedTopCurtainStyle]}>
          <View
            position="absolute"
            width={109}
            height={16}
            top={0}
            zIndex={4}
            padding={20}
          >
            <View>
              <CardLogo />
            </View>
          </View>
          <View position="absolute" zIndex={2} top={0} overflow="hidden">
            <BlockedCardTop />
          </View>
        </Animated.View>
        <Animated.View style={[animatedBottomCurtainStyle]}>
          <View position="absolute" zIndex={3} bottom={0} overflow="hidden">
            <BlockedCardBottom />
          </View>
          <View
            position="absolute"
            zIndex={4}
            bottom="0"
            overflow="hidden"
            paddingBottom={30}
          >
            <View justifyContent="center" width={343} flexDirection="row">
              <LockIcon color={'#fff'} />
              <Text
                color="white"
                fontWeight="$semiBold"
                fontSize="$h3"
                paddingLeft={10}
                lineHeight="$h1"
              >
                Card blocked
              </Text>
            </View>
          </View>
        </Animated.View>
      </View>
    </View>
  );

  return { animateBlock, animateUnblock, BlockedCardLayout };
};

export default useBlockCardAnimation;
