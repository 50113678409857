import { useNavigation } from '@react-navigation/native';
import { HowToUseCardView } from '@views/Onboarding';

const HowToUseCardPage = () => {
  const navigation = useNavigation();

  const onPressRightButton = () => {
    navigation.goBack();
  };

  return <HowToUseCardView onPressRightButton={onPressRightButton} />;
};

export default HowToUseCardPage;
