import { HeaderMobileTemplate } from '@templates';
import { HowToUseCardContent } from '@organisms';
import { View } from 'tamagui';

interface HowToUseCardViewProps {
  onPressRightButton: () => void;
}

const HowToUseCardView = ({ onPressRightButton }: HowToUseCardViewProps) => {
  return (
    <HeaderMobileTemplate
      header={{
        title: 'Getting started',
        isRightButton: true,
        onPressRightButton: onPressRightButton,
      }}
    >
      <View bg="white" flexGrow={1}>
        <HowToUseCardContent />
      </View>
    </HeaderMobileTemplate>
  );
};

export default HowToUseCardView;
