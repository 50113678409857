import { Loader, Pressable, Text } from '@atoms';
import DownloadButtonIcon from '../../../assets/download.svg';
import PdfButtonIcon from '../../../assets/pdf.svg';
import useIsPhone from '@hooks/useIsPhone';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cardStatementsThunks from '@store/features/cardStatements/asyncThunks';
import { selectStatementsByIds } from '@store/features/cardStatements/selectors';
import { customTheme } from '../../../styles/customTheme';
import { useAppDispatch } from '@store/hooks';
import { Separator, View, XStack } from 'tamagui';
import url from 'url';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

export interface StatementItemProps {
  title: string;
  statementId: string;
  onPdfIconPress: (documentUrl: string, title?: string) => void;
}

const StatementItem = ({
  title,
  onPdfIconPress,
  statementId,
}: StatementItemProps) => {
  const statementDocs = useSelector(selectStatementsByIds);

  const [downloadState, setDownloadState] = useState('unloaded');
  const [pdfIconPressOnExpired, setPdfIconPressOnExpired] = useState(false);

  const dispatch = useAppDispatch();
  const isPhone = useIsPhone();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const handleDownloadIconPress = () => {
    setDownloadState('pending');
    dispatch(cardStatementsThunks.getStatement({ statementId: statementId }))
      .unwrap()
      .catch((err) => handleRequestError(err));
  };

  const handlePdfIconPress = () => {
    const isExpired = checkIsExpiredAMZUrl(statementDocs[statementId]);

    if (isExpired) {
      handleDownloadIconPress();
      setPdfIconPressOnExpired(true);
    } else {
      onPdfIconPress(statementDocs[statementId], title);
    }
  };

  useEffect(() => {
    if (statementDocs[statementId]) {
      setDownloadState('loaded');
      if (pdfIconPressOnExpired) {
        onPdfIconPress(statementDocs[statementId], title);
      }
    }
  }, [statementDocs[statementId]]);

  const parseAMZDate = (input: string): Date => {
    const pattern = /^(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z$/;
    const parts = input?.match(pattern);

    if (!parts) {
      // returning a date in the past, so the isExpired will be true
      // Sun Jan 23 2022 12:00:00 GMT+0200
      return new Date('2022-01-23T12:00:00');
    }
    // parts[1] -> year, parts[2] -> month, parts[3] -> day
    // parts[4] -> hours, parts[5] -> minutes, parts[6] -> seconds
    return new Date(
      Date.UTC(
        Number(parts[1]),
        Number(parts[2]) - 1,
        Number(parts[3]),
        Number(parts[4]),
        Number(parts[5]),
        Number(parts[6])
      )
    );
  };

  const checkIsExpiredAMZUrl = (amzUrl: string) => {
    if (amzUrl) {
      const query = url.parse(amzUrl, true).query;

      const expireTimeoutInSeconds = parseInt(
        (query['X-Amz-Expires'] as string) || '1'
      );
      const dateRequested = query['X-Amz-Date'] as string;
      const parsedAmzDate = parseAMZDate(dateRequested);
      return (
        new Date(parsedAmzDate).getTime() + expireTimeoutInSeconds * 1000 <
        new Date().getTime()
      );
    } else {
      return true;
    }
  };

  useEffect(() => {
    const isExpired = checkIsExpiredAMZUrl(statementDocs[statementId]);

    if (isExpired) {
      setDownloadState('unloaded');
    }
  }, []);

  const renderIconComponentByState = () => {
    switch (downloadState) {
      case 'unloaded':
        return (
          <Pressable
            onPress={handleDownloadIconPress}
            testID="paychexpay.statementItem.button.downloadButton"
            accessible
            accessibilityLabel={`Download ${title}`}
          >
            <DownloadButtonIcon
              width="24px"
              height="24px"
              fill={customTheme.colors.primary.normal}
            />
          </Pressable>
        );
        break;
      case 'pending':
        return (
          <View>
            <Loader width={24} height={24} />
          </View>
        );
        break;
      case 'loaded':
        return (
          <Pressable
            onPress={handlePdfIconPress}
            testID="paychexpay.statementItem.button.pdfIconButton"
            accessible
            accessibilityLabel={`Open ${title}`}
          >
            <PdfButtonIcon width="24px" height="24px" />
          </Pressable>
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <XStack
        padding={isPhone ? 16 : 56}
        justifyContent="space-between"
        paddingVertical={isPhone ? 20 : 18}
      >
        <Text
          {...(isPhone
            ? { fontWeight: '400', fontSize: 16 }
            : { fontWeight: '500', fontSize: 14 })}
          accessible
          accessibilityLabel={`${title}`}
        >
          {title}
        </Text>
        {renderIconComponentByState()}
      </XStack>
      {isPhone && <Separator borderColor="$gray20" />}
    </>
  );
};

export default StatementItem;
