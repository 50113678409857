import jwtDecode from 'jwt-decode';

const accessTokenRenewCheck = (accessToken: string | boolean) => {
  if (typeof accessToken === 'string') {
    const accessTokenDecr: any = jwtDecode(accessToken);
    const accessTokenExp = new Date(accessTokenDecr.exp * 1000);

    const nowDateTime = new Date();

    const diffMs: number = accessTokenExp.valueOf() - nowDateTime.valueOf();

    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    return diffMins < 2;
  } else {
    return false;
  }
};

export const isExpiredToken = (accessToken: string | boolean) => {
  if (typeof accessToken === 'string') {
    const accessTokenDecr: any = jwtDecode(accessToken);
    if (accessTokenDecr.exp === undefined) return true;

    const accessTokenExp = new Date(accessTokenDecr.exp * 1000);
    const nowDateTime = new Date();

    const diffMs: number = accessTokenExp.valueOf() - nowDateTime.valueOf();
    return diffMs < 0;
  } else {
    return true;
  }
};

export default accessTokenRenewCheck;
