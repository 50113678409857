import { Animated, Easing, StyleSheet } from 'react-native';
import { ReactNode, useEffect, useRef } from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import GestureRecognizer from 'react-native-swipe-gestures';
import FocusAwareStatusBar from '@atoms/FocusAwareStatusBar/FocusAwareStatusBar';
import { Button, TPressable } from '@atoms';
import { View } from 'tamagui';

export interface BottomModalProps {
  isModalOpen: boolean;
  onPressModalClose: () => void;
  children: ReactNode;
  buttonText?: string;
}

const BottomModal = ({
  children,
  isModalOpen,
  onPressModalClose,
  buttonText = 'Done',
}: BottomModalProps) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const slideAnim = useRef(new Animated.Value(0)).current;
  const animDuration = 700;
  const { bottom: bottomInset } = useSafeAreaInsets();
  const onSwipeDown = () => {
    fadeOut();
  };

  useEffect(() => {
    if (isModalOpen) {
      fadeIn();
    }
  }, [isModalOpen]);

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      toValue: 0.6,
      duration: animDuration,
      useNativeDriver: false,
    }).start();
    Animated.timing(slideAnim, {
      toValue: -400 - bottomInset,
      duration: animDuration,
      delay: 200,
      useNativeDriver: false,
      easing: Easing.bezier(0.22, 1, 0.36, 1),
    }).start();
  };

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      useNativeDriver: false,
      toValue: 0,
      duration: animDuration,
    }).start();
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: animDuration,
      useNativeDriver: false,
      easing: Easing.bezier(0.22, 1, 0.36, 1),
    }).start();
    setTimeout(() => {
      onPressModalClose();
    }, animDuration);
  };
  return isModalOpen ? (
    <>
      <Animated.View
        style={[
          styles.back,
          {
            opacity: fadeAnim,
          },
        ]}
      />
      <TPressable
        position="absolute"
        h="100%"
        w="100%"
        zIndex={10}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        onPress={() => {
          onSwipeDown();
        }}
        cursor={'pointer'}
        testID="paychexpay.BottomModal.button"
      >
        <TPressable cursor={'default'} width={'100%'}>
          <Animated.View
            style={[
              styles.slider,
              {
                transform: [{ translateY: slideAnim }],
              },
            ]}
          >
            <GestureRecognizer onSwipeDown={onSwipeDown}>
              <View px="10px" pb="10px">
                <View borderRadius={14} bg="white" p={5} mb="10px">
                  {children}
                </View>
                <Button
                  borderRadius={14}
                  borderWidth={0}
                  variant="white"
                  onPress={onSwipeDown}
                  accessible
                  accessibilityLabel={buttonText}
                >
                  {buttonText}
                </Button>
              </View>
            </GestureRecognizer>
          </Animated.View>
        </TPressable>
      </TPressable>

      <FocusAwareStatusBar barStyle="light-content" />
    </>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  back: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
    zIndex: 5,
  },
  slider: {
    position: 'absolute',
    width: '100%',
    bottom: -400,
    zIndex: 10,
  },
});

export default BottomModal;
