import { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { auth0Config } from '../../../auth0config';

interface Auth0WrapperProps {
  children: ReactNode;
}

const Auth0Wrapper = ({ children }: Auth0WrapperProps) => {
  return (
    <Auth0Provider
      domain={'{{bank.paychexbank.auth.domain}}'}
      clientId={'{{bank.paychexbank.auth.web.clientId}}'}
      audience={'{{bank.paychexbank.auth.audience}}'}
      scope={auth0Config.scope.web}
      connection={auth0Config.connection}
      redirectUri={`${window.location.origin}`}
      key={'webn2a'}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0Wrapper;
