import { MenuItem } from '@molecules';
import { MoreSectionContentInterface } from '@organisms/MoreSectionContent/MoreSectionContent.types';
import { customTheme } from '../../../styles/customTheme';
import { MoreItemWithNavigationProps } from '@devTypes/types';

const MoreSectionContent = ({
  menuItems,
  onItemPress,
  lastMenuItem,
  logoutHandler,
}: MoreSectionContentInterface) => {
  return (
    <>
      {menuItems.map((item: MoreItemWithNavigationProps, index) => {
        return (
          <MenuItem
            key={index}
            onPress={onItemPress(item.pageName)}
            title={item.label}
            Icon={item.Icon}
            testID={item.label}
          />
        );
      })}
      <MenuItem
        testID={lastMenuItem.testID}
        onPress={logoutHandler}
        title={lastMenuItem.label}
        Icon={lastMenuItem.Icon}
        withBorder={false}
        iconColorInHex={customTheme.colors.red['40']}
        textColor={customTheme.colors.red['50']}
      />
    </>
  );
};

export default MoreSectionContent;
