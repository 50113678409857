import { Linking, Platform } from 'react-native';
import { showLocation } from 'react-native-map-link';

const ANDROID_ZOOM = 20;

const openMap = (lat: number, lng: number) => {
  if (Platform.OS === 'android') {
    Linking.openURL(`geo:${lat},${lng}?q=${lat},${lng}?z=${ANDROID_ZOOM}`);
  } else if (Platform.OS === 'ios') {
    showLocation({ latitude: lat, longitude: lng });
  } else {
    const mapTab = window.open('', '_blank');
    if (mapTab) {
      mapTab.opener = null;
      mapTab.location.href = `https://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lng}`;
    }
  }
};

export default openMap;
