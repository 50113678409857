import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const oowQuestionsSelector = (state: RootState) => state.oowQuestions;

export const selectOowQuestions = createSelector(
  oowQuestionsSelector,
  (state) => state.questions
);

export const selectOowStatus = createSelector(
  oowQuestionsSelector,
  (state) => state.status
);

export const selectOowExpTime = createSelector(
  oowQuestionsSelector,
  (state) => state.oowExpTime
);
