import { SideBarTemplate } from '@templates';
import { LoadingScreen } from '@views/SignUp/PaychexLoginView';
import { Platform, useWindowDimensions } from 'react-native';
import WebView from 'react-native-webview';
import React, { useState } from 'react';
import { XStack, View, Separator } from 'tamagui';
import { DesktopAreaHeader, MobileWebHeader } from '@molecules';
import useIsLaptop from '@hooks/useIsLaptop';
import { Center } from 'native-base';
import BackButton from '@atoms/BackButton/BackButton';
import { customTheme } from '../../../../styles/customTheme';
import useIsPhone from '@hooks/useIsPhone';
import { Text } from '@atoms';

interface ChangePinViewProps {
  changePinUrl: string;
  navigateBack: () => void;
}

const ChangePinView = ({ changePinUrl, navigateBack }: ChangePinViewProps) => {
  const isPhone = useIsPhone();
  const isLaptop = useIsLaptop();
  const { height: desktopHeight } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false); //improving UX, because startInLoadingState not showing loader on web for some reason
  }, 2000);

  const ChangePinViewContent = () => {
    return (
      <View
        flexGrow={1}
        backgroundColor="white"
        borderRadius={12}
        p={20}
        mt={20}
        height={isLaptop ? desktopHeight - 158 : desktopHeight - 128}
      >
        <XStack flexGrow={1} h="100%">
          <View
            backgroundColor="white"
            safeAreaBottom={'$7'}
            flexGrow={1}
            {...(Platform.OS === 'web' && { safeAreaBottom: true })}
            testID={'paychexpay.changePinView.content'}
          >
            {isLoading && (
              <View
                position={'absolute'}
                top={0}
                bottom={0}
                left={0}
                right={0}
                backgroundColor={'white'}
              >
                <LoadingScreen />
              </View>
            )}
            <WebView
              source={{ uri: changePinUrl }}
              startInLoadingState={true}
              renderLoading={() => <LoadingScreen />}
              scalesPageToFit={true}
              javaScriptEnabled={true}
              cacheEnabled={false}
              style={{
                width: '100%',
              }}
            />
          </View>
        </XStack>
      </View>
    );
  };

  if (isPhone) {
    return (
      <>
        <MobileWebHeader />
        <XStack px={4} py={4} bg={'white'}>
          <BackButton onPress={navigateBack} fill={customTheme.colors.black} />
          <Center flexGrow={1} mr="24px">
            <Text variant="title" textAlign="center">
              Change PIN
            </Text>
          </Center>
          <View />
        </XStack>
        <Separator borderColor="$gray20" />
        <ChangePinViewContent />
      </>
    );
  } else {
    return (
      <SideBarTemplate>
        <View px={'$7'} pb={26} flexGrow={1}>
          <DesktopAreaHeader
            title="Change PIN"
            backButtonHandler={navigateBack}
          />
          <ChangePinViewContent />
        </View>
      </SideBarTemplate>
    );
  }
};
export default ChangePinView;
