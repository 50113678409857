import React from 'react';
import { IconWithDescription } from '@molecules';
import { FooterMobileTemplate, WebLogoTemplate } from '@templates';
import FailedVerification from '../../../assets/failedSignupVerification.svg';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { TButton } from '@atoms';
import { YStack } from 'tamagui';

interface commonPropsInterface {
  logoutHandler: () => void;
}

interface UnableToSignupViewMobileProps {
  commonProps: commonPropsInterface;
}

interface UnableToSignupViewDesktopProps {
  commonProps: commonPropsInterface;
}

const UnableToSignupContent = () => {
  return (
    <IconWithDescription
      Icon={FailedVerification}
      description={<>This service is currently not offered by your employer.</>}
      title="Unable to sign up"
      titlePaddingTop="2"
    />
  );
};

const mobile = ({ commonProps }: UnableToSignupViewMobileProps) => {
  const { top: topOffset } = useSafeAreaInsets();
  return (
    <FooterMobileTemplate.withOneButton
      buttonVariant="red"
      buttonHandler={commonProps.logoutHandler}
      buttonLabel="Close"
      testID="paychexpay.mobile.verificationFailedView.button.logOutButton"
    >
      <YStack bg="white" flexGrow={1} pt={topOffset}>
        <YStack pt="$9" flexGrow={1} alignItems="center">
          <UnableToSignupContent />
        </YStack>
      </YStack>
    </FooterMobileTemplate.withOneButton>
  );
};

const desktop = ({ commonProps }: UnableToSignupViewDesktopProps) => {
  return (
    <WebLogoTemplate>
      <YStack minHeight="440px" justifyContent="space-between">
        <UnableToSignupContent />
        <TButton
          variant="red"
          onPress={commonProps.logoutHandler}
          testID="paychexpay.desktop.verificationFailedView.button.logOutButton"
        >
          Close
        </TButton>
      </YStack>
    </WebLogoTemplate>
  );
};

const UnableToSignupView = {
  mobile,
  desktop,
};

export default UnableToSignupView;
