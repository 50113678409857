import { createStackNavigator } from '@react-navigation/stack';
import { DEFAULT_SCREEN_OPTIONS } from '@constants/navigation';
import { HomeNavigatorParamsList } from '@navigation/HomeNavigator/HomeNavigator.types';
import {
  ChangePinPage,
  DebitCardPage,
  SavingAccountPage,
  SavingsAgreementsPage,
  OpenSavingsAccountPage,
  SetupDdaPage,
  SpendingAccountPage,
  StatementsListPage,
  FeeSchedulePage,
  SupportPage,
} from '@pages/Accounts';
import {
  BetweenPaychexPayPage,
  BetweenPaychexPayWebPage,
  ConnectAccountPage,
  DeleteAccountPage,
  SelectAccountPage,
  ToExternalBankPage,
  ToExternalBankWebPage,
  ToOtherFriendsPage,
  TransactionDetailsPage,
  TransferAmountPage,
  TransfersHistoryPage,
} from '@pages/Transfers';
import { CashBackPage } from '@pages/BottomTabs';
import PayBillsPage from '@pages/Widgets/PayBillsPage';
import ToOtherFriendsWebPage from '@pages/Transfers/ToOtherFriendsWebPage/ToOtherFriendsWebPage';
import CashBackIntroPage from '@pages/Widgets/CashbackIntroPage/CashBackIntroPage';
import routesToTitlesMap from '../routesToTitlesMap';
import { AddressVerificationPage } from '@pages/SignUp';

const HomeStack = createStackNavigator<HomeNavigatorParamsList>();

const HomeNavigator = () => {
  return (
    <HomeStack.Navigator screenOptions={{ ...DEFAULT_SCREEN_OPTIONS }}>
      <HomeStack.Screen
        name="SpendingAccount"
        component={SpendingAccountPage}
        options={{
          title: routesToTitlesMap.SpendingAccount,
        }}
      />
      <HomeStack.Screen
        name="SavingAccount"
        component={SavingAccountPage}
        options={{
          title: routesToTitlesMap.SavingAccount,
        }}
      />
      <HomeStack.Screen
        name="TransactionDetails"
        component={TransactionDetailsPage}
        options={{
          title: routesToTitlesMap.TransactionDetails,
        }}
      />
      <HomeStack.Screen
        name="DebitCard"
        component={DebitCardPage}
        options={{
          title: routesToTitlesMap.DebitCard,
        }}
      />

      <HomeStack.Screen
        name="SetupDda"
        component={SetupDdaPage}
        options={{
          title: routesToTitlesMap.SetupDda,
        }}
      />
      <HomeStack.Screen
        name="CashBackIntro"
        component={CashBackIntroPage}
        options={{
          title: routesToTitlesMap.CashBackIntro,
        }}
      />
      <HomeStack.Screen
        name="CashBack"
        component={CashBackPage}
        options={{
          title: routesToTitlesMap.CashBack,
        }}
      />
      <HomeStack.Screen
        name="Support"
        component={SupportPage}
        options={{
          title: routesToTitlesMap.Support,
        }}
      />
      <HomeStack.Screen
        name="BetweenPaychexPay"
        component={BetweenPaychexPayPage}
        options={{
          title: routesToTitlesMap.BetweenPaychexPay,
        }}
      />
      <HomeStack.Screen
        name="TransferAmount"
        component={TransferAmountPage}
        options={{
          title: routesToTitlesMap.TransferAmount,
        }}
      />
      <HomeStack.Screen
        name="ToExternalBank"
        component={ToExternalBankPage}
        options={{
          title: routesToTitlesMap.ToExternalBank,
        }}
      />
      <HomeStack.Screen
        name="SelectAccount"
        component={SelectAccountPage}
        options={{
          title: routesToTitlesMap.SelectAccount,
        }}
      />
      <HomeStack.Screen
        name="ToOtherFriends"
        component={ToOtherFriendsPage}
        options={{
          title: routesToTitlesMap.ToOtherFriends,
        }}
      />
      <HomeStack.Screen
        name="StatementsList"
        component={StatementsListPage}
        options={{
          title: routesToTitlesMap.StatementsList,
        }}
      />
      <HomeStack.Screen
        name="FeeSchedule"
        component={FeeSchedulePage}
        options={{
          title: routesToTitlesMap.FeeSchedule,
        }}
      />
      <HomeStack.Screen
        name="SavingsAgreements"
        component={SavingsAgreementsPage}
        options={{
          title: routesToTitlesMap.SavingsAgreements,
        }}
      />
      <HomeStack.Screen
        name="OpenSavingsAccount"
        component={OpenSavingsAccountPage}
        options={{
          title: routesToTitlesMap.OpenSavingsAccount,
        }}
      />
      <HomeStack.Screen
        name="ChangePin"
        component={ChangePinPage}
        options={{
          title: routesToTitlesMap.ChangePin,
        }}
      />
      <HomeStack.Screen
        name="PayBills"
        component={PayBillsPage}
        options={{
          title: routesToTitlesMap.PayBills,
        }}
      />
      <HomeStack.Screen
        name="ConnectAccount"
        component={ConnectAccountPage}
        options={{
          title: routesToTitlesMap.ConnectAccount,
        }}
      />
      <HomeStack.Screen
        name="DeleteAccount"
        component={DeleteAccountPage}
        options={{
          title: routesToTitlesMap.DeleteAccount,
        }}
      />
      <HomeStack.Screen
        name="BetweenPaychexPayWeb"
        component={BetweenPaychexPayWebPage}
        options={{
          title: routesToTitlesMap.BetweenPaychexPayWeb,
        }}
      />
      <HomeStack.Screen
        name="ToExternalBankWeb"
        component={ToExternalBankWebPage}
        options={{
          title: routesToTitlesMap.ToExternalBankWeb,
        }}
      />
      <HomeStack.Screen
        name="ToOtherFriendsWeb"
        component={ToOtherFriendsWebPage}
        options={{
          title: routesToTitlesMap.ToOtherFriendsWeb,
        }}
      />
      <HomeStack.Screen
        name="TransfersHistory"
        component={TransfersHistoryPage}
        options={{
          title: routesToTitlesMap.TransfersHistory,
        }}
      />
      <HomeStack.Screen
        name="AddressVerificationPage"
        component={AddressVerificationPage}
        options={{
          title: routesToTitlesMap.AddressVerificationPage,
        }}
      />
    </HomeStack.Navigator>
  );
};

export default HomeNavigator;
