import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const transfersSelector = (state: RootState) => state.transfers;

export const selectTransferStatus = createSelector(
  transfersSelector,
  (state) => state.transferStatus
);

export const selectShouldForceRefreshNextTransactionsFetch = createSelector(
  transfersSelector,
  (state) => state.shouldForceRefreshNextTransactionsFetch
);
