import { View } from 'tamagui';
import { FooterButtonsRow, IconWithDescription } from '@molecules';
import DdaSetupIcon from '../../../assets/ddaOnboardingSetupIllustration.svg';
import { HeaderAndFooterMobileTemplate } from '@templates';
import ONBOARDING_SLIDES from '@constants/onboardingSlides';
import { Text } from '@atoms';
import React from 'react';
import useIsPhone from '@hooks/useIsPhone';

interface SetupDdaOnboardingViewProps {
  onNotNowPress: () => void;
  onGetStartedPress: () => void;
}

const SetupDdaOnboardingView = ({
  onNotNowPress,
  onGetStartedPress,
}: SetupDdaOnboardingViewProps) => {
  const isPhone = useIsPhone();
  return (
    <HeaderAndFooterMobileTemplate
      footerContent={
        <FooterButtonsRow
          firstButton={{
            label: 'Not now',
            handler: onNotNowPress,
            testID: 'paychexpay.SetupDdaOnboardingView.button.notNowButton',
          }}
          secondButton={{
            label: 'Get started',
            handler: onGetStartedPress,
            testID: 'paychexpay.SetupDdaOnboardingView.button.getStarted',
          }}
          isLoading={false}
        />
      }
    >
      <View display={'flex'} flexDirection={'column'} flexGrow={1} bg="white">
        <View
          display={'flex'}
          flexDirection={'column'}
          pt="$10"
          flexGrow={1}
          alignItems="center"
          px={'$6'}
        >
          <IconWithDescription
            Icon={DdaSetupIcon}
            description={ONBOARDING_SLIDES.dda.subtitle}
            title={ONBOARDING_SLIDES.dda.title}
            titlePaddingTop={0}
          />
          <Text
            variant="subtitle"
            fontSize={10}
            lineHeight={12}
            textAlign="center"
            color={'$gray40'}
            paddingVertical={30}
            w={isPhone ? '100%' : 325}
            whiteSpace={'pre-line'}
          >
            {ONBOARDING_SLIDES.dda.description}
          </Text>
        </View>
      </View>
    </HeaderAndFooterMobileTemplate>
  );
};

export default SetupDdaOnboardingView;
