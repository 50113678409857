import { ReactNode, useEffect, useState } from 'react';
import { Alert, Platform } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { useSelector } from 'react-redux';
import { generalActions } from '@store/features/general/slice';
import Toast, { ToastShowParams } from 'react-native-toast-message';
import {
  selectErrorState,
  selectErrorStateToast,
} from '@store/features/errorStates/selectors';
import { ErrorToastWeb } from '@molecules';
import useErrorHandler from '@hooks/useErrorHandler';
import { errorStatesActions } from '@store/features/errorStates/slice';
import { useAppDispatch } from '@store/hooks';
import { JailMonkey } from '@utils/jailMonkey';

const toastDisplayTime = 10 * 1000;

const ErrorsProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [isConnected, setIsConnected] = useState<boolean>(true);
  const isJailBroken = __DEV__ ? false : JailMonkey.isJailBroken();

  if (isJailBroken) {
    Alert.alert(
      'Device is jail-broken/rooted',
      'Some functionality can be limited or fully locked',
      [
        {
          text: 'Ok',
        },
      ]
    );
  }

  useEffect(() => {
    const removeNetworkSubscription = NetInfo.addEventListener(
      (networkState) => {
        setIsConnected(!!networkState.isConnected);
      }
    );

    return () => {
      removeNetworkSubscription();
    };
  }, []);

  useEffect(() => {
    dispatch(generalActions.addObj({ isConnectedToNetwork: isConnected }));
    if (Platform.OS !== 'web') {
      if (isConnected) {
        Toast.hide();
      } else {
        Toast.show({
          type: 'error',
          text1: 'No internet connection',
          autoHide: false,
        });
      }
    }
  }, [isConnected]);

  const errorState = useSelector(selectErrorState);
  const toastError = useSelector(selectErrorStateToast);
  useEffect(() => {
    if (Platform.OS !== 'web') {
      if (isConnected && errorState.displayType === 'toast') {
        if (errorState.isError) {
          const toastConfig: ToastShowParams = {
            type: 'error',
            text1: toastError.title ? toastError.title : 'Unhandled error',
            autoHide: true,
            visibilityTime: toastDisplayTime,
          };
          if (toastError.subtitle) {
            toastConfig.text2 = toastError.subtitle;
          }

          Toast.show(toastConfig);
          setTimeout(() => {
            dispatch(errorStatesActions.resetError());
          }, toastDisplayTime);
        } else {
          Toast.hide();
        }
      }
    } else {
      if (errorState.displayType === 'toast') {
        setTimeout(() => {
          dispatch(errorStatesActions.resetError());
        }, toastDisplayTime);
      }
    }
  }, [isConnected, errorState]);

  useErrorHandler();

  return (
    <>
      {Platform.OS === 'web' && (
        <>
          <ErrorToastWeb
            isOpen={!isConnected}
            title={'No Internet Connection'}
          />
          <ErrorToastWeb
            isOpen={isConnected && errorState.displayType === 'toast'}
            title={toastError.title}
          />
        </>
      )}
      {children}
    </>
  );
};

export default ErrorsProvider;
