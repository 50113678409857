import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { AppStateStatus } from 'react-native';
import { IGeneral } from '@store/features/general/types';
import generalThunks from '@store/features/general/asyncThunks';

const initialState = {
  appState: 'unknown',
  webNavTransferSubsectionIsVisible: false,
  webNavHelpSubsectionIsVisible: false,
  webNavMasterCardSubsectionIsVisible: false,
  setWebNavLegalSubsectionIsVisible: false,
  webNotificationConfirmationModalVisible: false,
  notificationPreferencesUpdated: false,
  profileSettingsUpdated: false,
  profileSettingsModalVisible: false,
  initialFetchSpending: false,
  initialFetchSaving: false,
  modalSubmitHandler: () => false,
  isHideDeliveryWidget: false,
  variables: [
    {
      id: 'Annual_Percentage_Yield',
      variable_name: 'Annual_Percentage_Yield',
      value: '',
    },
    {
      id: 'Savings_Agreement_Date',
      variable_name: 'Savings_Agreement_Date',
      value: '',
    },
    {
      id: 'Transfer_Fee',
      variable_name: 'Transfer_Fee',
      value: '',
    },
    {
      id: 'Savings_Interest_Rate',
      variable_name: 'Savings_Interest_Rate',
      value: '',
    },
    {
      id: 'ATM_Fee',
      variable_name: 'ATM_Fee',
      value: '',
    },
  ],
} as IGeneral;

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    addObj(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    resetState(state) {
      return { ...initialState, appState: state.appState };
    },
    setAppState(state, action: PayloadAction<AppStateStatus>) {
      state.appState = action.payload;
    },
    setWebNavTransferSubsectionIsVisible(
      state,
      action: PayloadAction<boolean>
    ) {
      state.webNavTransferSubsectionIsVisible = action.payload;
    },
    setWebNavHelpSubsectionIsVisible(state, action: PayloadAction<boolean>) {
      state.webNavHelpSubsectionIsVisible = action.payload;
    },
    setWebNavMasterCardSubsectionIsVisible(
      state,
      action: PayloadAction<boolean>
    ) {
      state.webNavMasterCardSubsectionIsVisible = action.payload;
    },
    setWebNavLegalSubsectionIsVisible(state, action: PayloadAction<boolean>) {
      state.setWebNavLegalSubsectionIsVisible = action.payload;
    },
    setWebNotificationConfirmationModalVisible(
      state,
      action: PayloadAction<boolean>
    ) {
      state.webNotificationConfirmationModalVisible = action.payload;
    },
    setNotificationPreferencesUpdated(state, action: PayloadAction<boolean>) {
      state.notificationPreferencesUpdated = action.payload;
    },
    setProfileUpdated(state, action: PayloadAction<boolean>) {
      state.profileSettingsUpdated = action.payload;
    },
    setProfileSettingsModalVisible(state, action: PayloadAction<boolean>) {
      state.profileSettingsModalVisible = action.payload;
    },
    setModalSubmitHandler(state, action: PayloadAction<() => void>) {
      state.modalSubmitHandler = action.payload;
    },
    setInitialFetchSpending(state, action: PayloadAction<boolean>) {
      state.initialFetchSpending = action.payload;
    },
    setInitialFetchSaving(state, action: PayloadAction<boolean>) {
      state.initialFetchSaving = action.payload;
    },
    toHideDeliveryWidget(state) {
      state.isHideDeliveryWidget = true;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IGeneral>) => {
    builder.addCase(
      generalThunks.getVariables.fulfilled,
      (state, { payload }) => {
        state.variables = payload;
      }
    );
  },
});

export const { reducer: generalReducer, actions: generalActions } =
  generalSlice;
