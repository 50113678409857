import { DesktopAreaHeader } from '@molecules';
import amountToString from '@utils/amountToString';
import TransactionsList from '@organisms/TransactionsList/TransactionsList';
import AccountDetails from '@organisms/AccountDetails/AccountDetails';
import { SideBarTemplate } from '@templates';
import React from 'react';
import { AccountViewProps } from '@devTypes/accounts';
import { TransactionsFilter } from '@organisms';
import { Text } from '@atoms';
import PaychexPaySmall from '../../../assets/accounts/BlazSmall.svg';
import { useWindowDimensions } from 'react-native';
import { maxMediaQuery } from '@constants/ui';
import { format } from 'date-fns';
import { ScrollView, View, XStack, YStack } from 'tamagui';
import { useSelector } from 'react-redux';
import { selectDateRange } from '@store/features/transactions/selectors';

interface AccountDesktopTemplateProps extends AccountViewProps {
  onOpenTransactionModal: (id: string) => void;
  transactionsSearchText: string;
  isClosedAccount?: boolean;
}

const AccountDesktopTemplate = ({
  navigateBack,
  account,
  isClosedAccount,
  transactions,
  filteredTransactions,
  transactionsSearchText,
  transactionsFilteredBy,
  menuItems,
  copyToClipboard,
  onOpenTransactionModal,
  filterType,
  isLoadingTransactions,
}: AccountDesktopTemplateProps) => {
  const { width: desktopWidth } = useWindowDimensions();
  const filterDateRange = useSelector(selectDateRange);

  return (
    <SideBarTemplate>
      <YStack h="100vh">
        <View px={'$7'} position="absolute" zIndex={1}>
          <DesktopAreaHeader
            title={account.name}
            backButtonHandler={navigateBack}
          />
        </View>
        <XStack pt="88px" pl={'$7'} height="100%" flex={1}>
          <YStack
            height="100%"
            pb={desktopWidth <= maxMediaQuery.smallDesktop ? '60px' : '134px'}
            maxWidth="750px"
            flex={1}
          >
            <View
              bg="white"
              borderRadius={12}
              px="20px"
              pt="20px"
              height="100%"
              flex={1}
            >
              <XStack
                justifyContent="space-between"
                alignItems="center"
                flexWrap="wrap"
              >
                <XStack alignItems="center">
                  <PaychexPaySmall />
                  <Text variant="title" ml={'$4'}>
                    {account.name}
                  </Text>
                </XStack>
                {isClosedAccount ? (
                  <Text
                    fontSize="$h2"
                    fontWeight={'600'}
                    pr={'$4'}
                    color={'$red50'}
                  >
                    • Closed
                  </Text>
                ) : (
                  <Text variant="pageTitle">
                    {amountToString(account.amount)}
                  </Text>
                )}
              </XStack>

              <>
                {(transactions.length > 0 ||
                  filteredTransactions.length > 0 ||
                  transactionsSearchText.length > 0 ||
                  transactionsFilteredBy !== '') && (
                  <TransactionsFilter.desktop filterType={filterType} />
                )}
                <ScrollView
                  scrollEventThrottle={20}
                  showsVerticalScrollIndicator={false}
                  contentContainerStyle={{ pb: '$7', flex: 1 }}
                >
                  <TransactionsList
                    transactions={filteredTransactions}
                    onPress={onOpenTransactionModal}
                    withHighlight={true}
                    transactionsSearchText={transactionsSearchText}
                    transactionsFilteredBy={transactionsFilteredBy}
                    isLoading={isLoadingTransactions}
                  />
                </ScrollView>
                <YStack
                  flex={0}
                  backgroundColor={'white'}
                  paddingHorizontal={16}
                  paddingTop={10}
                  marginBottom={20}
                >
                  <View
                    flex={1}
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                  >
                    {filterDateRange?.date_from ? (
                      <Text
                        variant={'semiTitle'}
                        fontWeight={'400'}
                        fontSize={14}
                        paddingVertical={10}
                      >
                        {filterDateRange?.date_from &&
                          format(
                            new Date(filterDateRange.date_from),
                            'MMM dd, yyyy'
                          )}{' '}
                        {filterDateRange?.date_to &&
                          filterDateRange.date_from !==
                            filterDateRange.date_to &&
                          format(
                            new Date(filterDateRange.date_to),
                            ' - MMM dd, yyyy'
                          )}
                      </Text>
                    ) : (
                      <>
                        {transactions.length > 0 && (
                          <Text
                            variant={'caption'}
                            color={'$gray60'}
                            fontSize={12}
                            paddingVertical={10}
                          >
                            Showing last 90 days
                          </Text>
                        )}
                      </>
                    )}
                  </View>
                </YStack>
              </>
            </View>
          </YStack>
          <YStack
            height="100%"
            pb={desktopWidth <= maxMediaQuery.smallDesktop ? '60px' : '134px'}
          >
            <View
              height="100%"
              bg="white"
              borderRadius={12}
              p="20px"
              w={desktopWidth <= maxMediaQuery.laptop ? '301px' : '364px'}
              marginLeft="20px"
              marginRight="40px"
            >
              <View height="100%">
                <Text variant="title" pb={'$2'}>
                  Account details
                </Text>
                <AccountDetails
                  account={account}
                  isDesktop
                  menuItems={menuItems}
                  copyToClipboard={copyToClipboard}
                />
              </View>
            </View>
          </YStack>
        </XStack>
      </YStack>
    </SideBarTemplate>
  );
};

export default AccountDesktopTemplate;
