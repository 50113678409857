import { createSlice } from '@reduxjs/toolkit';
import transfersThunks from '@store/features/transfers/asyncThunks';
import { ITransfersState } from '@store/features/transfers/types';

const initialState = {
  transferStatus: null,
  shouldForceRefreshNextTransactionsFetch: false,
} as ITransfersState;

const transfersSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    resetStatus(state) {
      state.transferStatus = null;
    },
    resetForceRefresh(state) {
      state.shouldForceRefreshNextTransactionsFetch = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(transfersThunks.postTransfer.fulfilled, (state) => {
      state.transferStatus = 'success';
      state.shouldForceRefreshNextTransactionsFetch = true;
    });
    builder.addCase(transfersThunks.postTransfer.rejected, (state) => {
      state.transferStatus = 'error';
    });
    builder.addCase(transfersThunks.postTransfer.pending, (state) => {
      state.transferStatus = 'pending';
    });
    builder.addCase(transfersThunks.postNetworkTransfer.fulfilled, (state) => {
      state.transferStatus = 'success';
      state.shouldForceRefreshNextTransactionsFetch = true;
    });
    builder.addCase(transfersThunks.postNetworkTransfer.rejected, (state) => {
      state.transferStatus = 'error';
    });
    builder.addCase(transfersThunks.postNetworkTransfer.pending, (state) => {
      state.transferStatus = 'pending';
    });
    builder.addCase(transfersThunks.postOutgoing.fulfilled, (state) => {
      state.transferStatus = 'success';
      state.shouldForceRefreshNextTransactionsFetch = true;
    });
    builder.addCase(transfersThunks.postOutgoing.rejected, (state) => {
      state.transferStatus = 'error';
    });
    builder.addCase(transfersThunks.postOutgoing.pending, (state) => {
      state.transferStatus = 'pending';
    });
    builder.addCase(transfersThunks.postIncoming.fulfilled, (state) => {
      state.transferStatus = 'success';
      state.shouldForceRefreshNextTransactionsFetch = true;
    });
    builder.addCase(transfersThunks.postIncoming.rejected, (state) => {
      state.transferStatus = 'error';
    });
    builder.addCase(transfersThunks.postIncoming.pending, (state) => {
      state.transferStatus = 'pending';
    });
  },
});

export const { reducer: transfersReducer, actions: transfersActions } =
  transfersSlice;
