import { ScrollView, Separator, View, XStack } from 'tamagui';
import SearchIcon from '../../../assets/search.svg';
import CalendarIcon from '../../../assets/calendarIcon.svg';
import ArrowDownIcon from '../../../assets/arrowDown.svg';
import ArrowUpIcon from '../../../assets/ArrowUp.svg';
import FilterIcon from '../../../assets/filter.svg';
import React, { useEffect, useRef, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import {
  DEFAULT_SCREEN_HEIGHT,
  MAX_TRANSFER_SEARCH_LENGTH,
  TOAST_POPUP_VISIBILITY_TIME,
} from '@constants/general';
import { TPressable, Text } from '@atoms';
import { customTheme } from '../../../styles/customTheme';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import CheckIcon from '../../../assets/Check.svg';
import useOutsideClick from '@hooks/useOutsideClick';
import { accessibleHitSlop } from '@constants/ui';
import useFiltersTransactions from '@hooks/useFiltersTransactions';
import DeleteIcon from '../../../assets/Delete.svg';
import { CalendarDatePicker, TextInput } from '@molecules';
import CloseIcon from '../../../assets/closeIcon.svg';
import ToastPopup from '@atoms/ToastPopup/ToastPopup.web';
import { useNavigation } from '@react-navigation/native';
import { DEFAULT_TAB_BAR_OPTIONS } from '@constants/navigation';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import useIsLaptop from '@hooks/useIsLaptop';

export type TransactionsOptionsType =
  | 'all'
  | 'savings'
  | 'spending'
  | 'fees'
  | 'interest';

export type TransfersOptionsType = 'all' | 'savings' | 'spending';

interface TransactionsFilterProps {
  filterType: 'spending' | 'saving' | 'transfers';
  setTransferFilterOption?: (option: 'savings' | 'spending' | 'all') => void;
}

const options: { [key: string]: { [key: string]: string } } = {
  spending: {
    all: 'All transactions',
    spending: 'Money out',
    savings: 'Money in',
    fees: 'Fees',
  },
  saving: {
    all: 'All transactions',
    spending: 'Money out',
    savings: 'Money in',
    interest: 'Interest',
  },
  transfers: {
    all: 'All transfers',
    spending: 'Money out',
    savings: 'Money in',
    fees: 'Fees',
  },
};

const animationDuration = 500;
const offsetHeight = 700;
const windowHeight = Dimensions.get('window').height;

const mobile = ({
  filterType,
  setTransferFilterOption,
}: TransactionsFilterProps) => {
  const [toClearCalendar, setToClearCalendar] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);

  const searchRef = useRef(null);
  const navigation = useNavigation();

  const { bottom: bottomInset } = useSafeAreaInsets();
  const toOpenSelectDate = () => {
    // @ts-ignore
    searchRef.current?.blur();
    setIsSelectDateOpen(true);
  };

  const toCloseSelectDate = () => {
    setIsSelectDateOpen(false);
  };
  const offsetCalendarY = useSharedValue(0);
  const selectDateStyle = useAnimatedStyle(() => {
    return {
      height: offsetCalendarY.value,
    };
  });

  const offsetY = useSharedValue(0);
  const optionSelectStyle = useAnimatedStyle(() => {
    return {
      height: offsetY.value,
      zIndex: offsetY.value > 0 ? 100 : 0,
    };
  });
  const arrowStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: offsetY.value * (180 / offsetHeight) + 'deg',
        },
      ],
    };
  });

  const {
    searchText,
    changeSearchTextHandler,
    isSelectDateOpen,
    setIsSelectDateOpen,
    selectedFilterOption,
    isCalendarClearPressed,
    setIsCalendarClearPressed,
    isSelectOpen,
    selectOption,
    toOpenOptions,
    setDateRange,
    dateRange,
  } = useFiltersTransactions({
    filterType: filterType,
    setTransferFilterOption,
  });

  useEffect(() => {
    if (isSelectOpen) {
      navigation.setOptions({
        tabBarStyle: { ...DEFAULT_TAB_BAR_OPTIONS, display: 'none' },
      });
      offsetY.value = withTiming(offsetHeight, {
        duration: animationDuration,
      });
    } else {
      navigation.setOptions({
        tabBarStyle: { ...DEFAULT_TAB_BAR_OPTIONS, display: 'flex' },
      });
      offsetY.value = withTiming(0, {
        duration: animationDuration,
      });
    }
  }, [isSelectOpen]);

  useEffect(() => {
    if (isSelectDateOpen) {
      navigation.setOptions({
        tabBarStyle: { ...DEFAULT_TAB_BAR_OPTIONS, display: 'none' },
      });
      offsetCalendarY.value = withTiming(offsetHeight, {
        duration: animationDuration,
      });
    } else {
      navigation.setOptions({
        tabBarStyle: { ...DEFAULT_TAB_BAR_OPTIONS, display: 'flex' },
      });
      offsetCalendarY.value = withTiming(0, {
        duration: animationDuration,
      });
    }
  }, [isSelectDateOpen]);

  const filterOptions = options[filterType];

  const clearSearch = () => {
    changeSearchTextHandler('');
  };

  useEffect(() => {
    if (selectedFilterOption === 'all') {
      setToClearCalendar(true);
    }
  }, [selectedFilterOption]);

  const calendarWrapperRef = useRef(null);
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (Platform.OS === 'web') {
      if (dateRange.date_from) {
        setIsToastPopupOpen(true);
        timeout = setTimeout(() => {
          setIsToastPopupOpen(false);
        }, TOAST_POPUP_VISIBILITY_TIME * 2);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [dateRange]);

  return (
    <View zIndex={5}>
      <XStack pb="$4" width="100%">
        {Platform.OS === 'web' && (
          <ToastPopup
            isOpen={isToastPopupOpen}
            text={'Filter applied. Adding a new one will replace this one.'}
          />
        )}
        <TextInput
          styles={{ flex: 1, height: 49 }}
          args={{
            ref: searchRef,
            fontSize: '$body',
            flexGrow: 1,
            placeholder: 'Search',
            returnKeyType: 'search',
            value: searchText,
            onChangeText: (text: string) => {
              changeSearchTextHandler(text);
              !toClearCalendar &&
                dateRange?.date_from &&
                setToClearCalendar(true);
            },
            onFocus: () => {
              isSelectDateOpen && toCloseSelectDate();
            },
            maxLength: MAX_TRANSFER_SEARCH_LENGTH,
          }}
          InputLeftElement={
            <View pl="$3" pt="$3">
              <SearchIcon />
            </View>
          }
          InputRightElement={
            searchText !== '' && searchText !== undefined ? (
              <TPressable
                onPress={clearSearch}
                paddingHorizontal={'$3'}
                pb="$4"
                hitSlop={accessibleHitSlop}
                testID="paychexpay.mobile.transactionsFilter.button.clearSearch"
              >
                <DeleteIcon />
              </TPressable>
            ) : undefined
          }
        />
        {searchText?.length === MAX_TRANSFER_SEARCH_LENGTH && (
          <Text
            position="absolute"
            bottom={-20}
            right={0}
            variant="error"
            accessible
            accessibilityLabel={'35 character limit'}
          >
            35 character limit
          </Text>
        )}
        {selectedFilterOption !== 'all' && ( //TODO: temp hiding calendar filter button, because we can't filter by dates if selectedFilterOption === "all". Remove it when backend ready.
          <View
            display={'flex'}
            ml="$2"
            bg={
              isSelectDateOpen
                ? '$primaryNormal'
                : dateRange?.date_from
                ? '$primaryNormal'
                : '$blue10'
            }
            borderRadius="$1"
            justifyContent="center"
            alignItems="center"
            w={
              Dimensions.get('window').height < DEFAULT_SCREEN_HEIGHT ? 57 : 48
            }
          >
            <TPressable
              onPress={() => {
                isSelectDateOpen ? toCloseSelectDate() : toOpenSelectDate();
              }}
              testID="paychexpay.mobile.transactionsFilter.button.selectDate"
              hitSlop={accessibleHitSlop}
              minWidth={48}
              alignItems="center"
              alignContent="center"
              justifyContent="center"
              display="flex"
            >
              {isSelectDateOpen ? (
                <CloseIcon height="24px" width="24px" fill="white" />
              ) : (
                <CalendarIcon
                  fill={
                    dateRange?.date_from ? 'white' : customTheme.colors.gray[30]
                  }
                />
              )}
            </TPressable>
          </View>
        )}
      </XStack>

      <View
        position="relative"
        style={{
          zIndex: 10,
        }}
      >
        <Animated.View
          ref={calendarWrapperRef}
          style={[
            {
              position: 'absolute',
              top: -15,
              left: 0,
              width: Dimensions.get('window').width,
              marginLeft: -16,
              backgroundColor: 'white',
              overflow: 'hidden',
            },
            selectDateStyle,
          ]}
          accessibilityViewIsModal={isSelectDateOpen}
          accessibilityElementsHidden={isSelectOpen}
          onLayout={() => {
            // @ts-ignore
            calendarWrapperRef.current?.measureInWindow((x, y) => {
              const calculatedHeight =
                windowHeight - y - (Platform.OS === 'web' ? 0 : bottomInset);
              setContainerHeight(calculatedHeight);
            });
          }}
        >
          <View flexGrow={1} maxHeight={containerHeight}>
            <ScrollView
              bounces={false}
              contentContainerStyle={{
                flex: 1,
                flexGrow: 1,
              }}
            >
              <CalendarDatePicker
                dateRange={dateRange}
                setDateRange={setDateRange}
                toCloseSelectDate={toCloseSelectDate}
                toClearCalendar={toClearCalendar}
                setToClearCalendar={setToClearCalendar}
                isClearPressed={isCalendarClearPressed}
                setIsClearPressed={setIsCalendarClearPressed}
              />
            </ScrollView>
          </View>
        </Animated.View>
      </View>

      <View
        position="relative"
        accessibilityViewIsModal={isSelectOpen}
        accessibilityElementsHidden={isSelectDateOpen}
      >
        <TPressable
          onPress={() => {
            isSelectOpen ? selectOption(selectedFilterOption) : toOpenOptions();
          }}
          testID="paychexpay.mobile.transactionsFilter.button.selectOption"
        >
          <XStack pb="$4">
            <Text
              fontWeight="$medium"
              color={
                isSelectOpen || selectedFilterOption !== 'all'
                  ? '$primaryNormal'
                  : '$gray40'
              }
              pr={4}
              testID={
                'paychexpay.mobile.transactionsFilter.selectedFilterOption'
              }
            >
              {filterOptions[selectedFilterOption]}
            </Text>
            <Animated.View style={arrowStyle}>
              <ArrowDownIcon
                width="20px"
                height="20px"
                fill={
                  isSelectOpen || selectedFilterOption !== 'all'
                    ? customTheme.colors.primary.normal
                    : customTheme.colors.gray[40]
                }
              />
            </Animated.View>
          </XStack>
        </TPressable>
        <Animated.View
          style={[
            optionSelectStyle,
            {
              position: 'absolute',
              top: '100%',
              left: 0,
              width: Dimensions.get('window').width,
              marginLeft: -16,
              backgroundColor: customTheme.colors.white,
              overflow: 'hidden',
            },
          ]}
        >
          <Separator borderColor="$gray20" />
          {Object.entries(filterOptions).map((item) => (
            <TPressable
              key={item[0]}
              // @ts-ignore
              onPress={() => selectOption(item[0])}
              bg={selectedFilterOption === item[0] ? '$blue20' : '$white'}
              px={4}
              testID={`paychexpay.mobile.transactionsFilter.button.selectOptionFromList_${item[0]}`}
            >
              <XStack justifyContent="space-between" alignItems="center">
                <Text py={14}>{item[1]}</Text>
                {selectedFilterOption === item[0] && <CheckIcon />}
              </XStack>
            </TPressable>
          ))}
        </Animated.View>
      </View>
    </View>
  );
};

const desktop = ({
  filterType,
  setTransferFilterOption,
}: TransactionsFilterProps) => {
  const [filterHover, setFilterHover] = useState<null | number>(null);
  const {
    searchText,
    changeSearchTextHandler,
    isSelectOpen,
    selectOption,
    isSelectDateOpen,
    setIsSelectDateOpen,
    selectedFilterOption,
    isCalendarClearPressed,
    setIsCalendarClearPressed,
    toOpenOptions,
    toCloseOptions,
    setDateRange,
    dateRange,
  } = useFiltersTransactions({
    filterType: filterType,
    setTransferFilterOption,
  });
  const {
    ref: outsideClickRef,
    refStopPropagation: outsideClickRefStopPropagation,
  } = useOutsideClick({
    onOutsideClick: toCloseOptions,
  });

  const toCloseSelectDate = () => {
    setIsSelectDateOpen(false);
  };

  const toOpenSelectDate = () => {
    // @ts-ignore
    // searchRef.current?.blur();
    setIsSelectDateOpen(true);
  };

  const {
    ref: outsideCalendarClickRef,
    refStopPropagation: outsideCalendarClickRefStopPropagation,
  } = useOutsideClick({
    onOutsideClick: toCloseSelectDate,
  });
  const [toClearCalendar, setToClearCalendar] = useState(false);

  const filterOptions = options[filterType];

  const clearSearch = () => {
    changeSearchTextHandler('');
  };

  useEffect(() => {
    if (selectedFilterOption === 'all') {
      setToClearCalendar(true);
      setDateRange({
        date_from: null,
        date_to: null,
      });
    }
  }, [selectedFilterOption]);
  const [isToastPopupOpen, setIsToastPopupOpen] = useState(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (dateRange.date_from) {
      setIsToastPopupOpen(true);
      timeout = setTimeout(() => {
        setIsToastPopupOpen(false);
      }, TOAST_POPUP_VISIBILITY_TIME * 2);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [dateRange]);

  const isLaptop = useIsLaptop();

  return (
    <XStack flexWrap="wrap" py="$2" zIndex={5}>
      <ToastPopup
        isOpen={isToastPopupOpen}
        text={'Filter applied. Adding a new one will replace this one.'}
      />
      <View minWidth={180} flex={1} mr="$2" display="flex">
        <TextInput
          styles={{ flex: 1, height: 49 }}
          args={{
            fontSize: '$body',
            flexGrow: 1,
            placeholder: 'Search',
            returnKeyType: 'search',
            value: searchText,
            onChangeText: (text: string) => {
              changeSearchTextHandler(text);
              !toClearCalendar &&
                dateRange?.date_from &&
                setToClearCalendar(true);
            },
            onFocus: () => {
              isSelectDateOpen && toCloseSelectDate();
            },
            maxLength: MAX_TRANSFER_SEARCH_LENGTH,
          }}
          InputLeftElement={
            <View pl="$3" pt="$3">
              <SearchIcon />
            </View>
          }
          InputRightElement={
            searchText !== '' && searchText !== undefined ? (
              <TPressable
                onPress={clearSearch}
                paddingHorizontal={'$3'}
                pt="$4"
                height={48}
                hitSlop={accessibleHitSlop}
                testID="paychexpay.desktop.transactionsFilter.button.clearSearch"
              >
                <DeleteIcon />
              </TPressable>
            ) : undefined
          }
        />
        {searchText?.length === MAX_TRANSFER_SEARCH_LENGTH && (
          <Text
            position="absolute"
            variant="error"
            accessible
            bottom={-12}
            accessibilityLabel={'35 character limit'}
            {...(isLaptop && {
              bottom: -30,
              right: 0,
            })}
          >
            35 character limit
          </Text>
        )}
      </View>
      {selectedFilterOption !== 'all' && ( //TODO: temp hiding calendar filter button, because we can't filter by dates if selectedFilterOption === "all". Remove it when backend ready.
        <View
          minWidth={180}
          position="relative"
          mr="$2"
          marginBottom={10}
          height={48}
          // @ts-ignore
          ref={outsideCalendarClickRefStopPropagation}
        >
          <TPressable
            borderRadius="$1"
            py="$3"
            onPress={() => {
              isSelectDateOpen ? toCloseSelectDate() : toOpenSelectDate();
            }}
            testID="paychexpay.desktop.transactionsFilter.button.selectDateOption"
            backgroundColor={
              isSelectDateOpen
                ? '$primaryNormal'
                : dateRange?.date_from
                ? '$blue20'
                : '$blue10'
            }
          >
            <XStack
              flexGrow={1}
              pl="$2"
              paddingRight={10}
              justifyContent="space-between"
              alignItems="center"
            >
              <XStack alignItems="center">
                <CalendarIcon
                  fill={
                    isSelectDateOpen
                      ? customTheme.colors.white
                      : dateRange?.date_from
                      ? customTheme.colors.primary.normal
                      : customTheme.colors.gray[30]
                  }
                />
                <Text
                  pl={8}
                  pr={'$6'}
                  fontSize="$h5"
                  color={
                    isSelectDateOpen
                      ? '$white'
                      : dateRange?.date_from
                      ? '$primaryNormal'
                      : '$gray40'
                  }
                  testID={
                    'paychexpay.desktop.transactionsFilter.selectedDateOption'
                  }
                  selectable={false}
                >
                  Date range
                </Text>
              </XStack>
              {isSelectDateOpen ? (
                <ArrowUpIcon
                  width="20px"
                  height="20px"
                  fill={customTheme.colors.white}
                />
              ) : (
                <ArrowDownIcon
                  width="20px"
                  height="20px"
                  fill={
                    dateRange?.date_from
                      ? customTheme.colors.primary.normal
                      : customTheme.colors.gray[40]
                  }
                />
              )}
            </XStack>
          </TPressable>
        </View>
      )}
      {isSelectDateOpen && (
        <View
          zIndex={10}
          // @ts-ignore
          ref={outsideCalendarClickRef}
          position="absolute"
          top={40}
          left={0}
          mt="$2"
          backgroundColor="$white"
          borderColor="$gray20"
          borderRadius="$1"
          borderWidth={1}
          w="100%"
          overflow={'hidden'}
          shadowOffset={{ width: 0, height: 4 }}
          shadowRadius={10}
          shadowOpacity={1}
          shadowColor={'rgba(40, 59, 78, 0.1)'}
        >
          <CalendarDatePicker
            setDateRange={setDateRange}
            toCloseSelectDate={toCloseSelectDate}
            toClearCalendar={toClearCalendar}
            setToClearCalendar={setToClearCalendar}
            dateRange={dateRange}
            isClearPressed={isCalendarClearPressed}
            setIsClearPressed={setIsCalendarClearPressed}
          />
        </View>
      )}
      <View
        // h={'auto'}
        position="relative"
        w={178}
        marginBottom={10}
        // @ts-ignore
        ref={outsideClickRefStopPropagation}
        height={48}
      >
        <TPressable
          borderRadius="$1"
          py="$3"
          onPress={() => {
            isSelectOpen ? selectOption(selectedFilterOption) : toOpenOptions();
          }}
          testID="paychexpay.desktop.transactionsFilter.button.selectOption"
          {...(isSelectOpen
            ? {
                bg: '$primaryNormal',
              }
            : selectedFilterOption === 'all'
            ? {
                bg: '$blue10',
              }
            : { bg: '$blue20' })}
        >
          <XStack
            flexGrow={1}
            pl="$2"
            pr={10}
            justifyContent="space-between"
            alignItems="center"
          >
            <XStack alignItems="center">
              <FilterIcon
                fill={
                  isSelectOpen
                    ? customTheme.colors.white
                    : selectedFilterOption === 'all'
                    ? customTheme.colors.gray[30]
                    : customTheme.colors.primary.normal
                }
              />
              <Text
                pl={'$2'}
                pr={'$6'}
                // fontSize="$h5"
                color={
                  isSelectOpen
                    ? '$white'
                    : selectedFilterOption === 'all'
                    ? '$gray40'
                    : '$primaryNormal'
                }
                testID={
                  'paychexpay.desktop.transactionsFilter.selectedFilterOption'
                }
              >
                {filterOptions[selectedFilterOption]}
              </Text>
            </XStack>
            {isSelectOpen ? (
              <ArrowUpIcon
                width="20px"
                height="20px"
                fill={customTheme.colors.white}
              />
            ) : (
              <ArrowDownIcon
                width="20px"
                height="20px"
                fill={
                  selectedFilterOption === 'all'
                    ? customTheme.colors.gray[40]
                    : customTheme.colors.primary.normal
                }
              />
            )}
          </XStack>
        </TPressable>
        {isSelectOpen && (
          <View
            // @ts-ignore
            ref={outsideClickRef}
            position="absolute"
            top={40}
            left={0}
            mt="$2"
            backgroundColor="$white"
            borderColor="$gray20"
            borderRadius="$1"
            borderWidth={1}
            w="100%"
            shadowOffset={{ width: 0, height: 4 }}
            shadowRadius={10}
            shadowOpacity={1}
            shadowColor={'rgba(40, 59, 78, 0.1)'}
          >
            {Object.keys(filterOptions).map((option, index) => (
              <TPressable
                key={option}
                onHoverIn={() => setFilterHover(index)}
                onHoverOut={() => setFilterHover(null)}
                // @ts-ignore
                onPress={() => selectOption(option)}
                testID={`paychexpay.desktop.transactionsFilter.button.selectOptionFromList_${option}`}
                pl="$4"
                pr="$2"
                {...(selectedFilterOption === option && {
                  bg: '$blue20',
                })}
                {...(filterHover === index &&
                  selectedFilterOption !== option && {
                    bg: '$blue10',
                  })}
                {...(index === 0 && {
                  borderTopLeftRadius: '$1',
                  borderTopRightRadius: '$1',
                })}
                {...(index === Object.keys(filterOptions).length - 1 && {
                  borderBottomLeftRadius: '$1',
                  borderBottomRightRadius: '$1',
                })}
              >
                <XStack alignItems="center" justifyContent="space-between">
                  <Text py={10}>{filterOptions[option]}</Text>
                  {selectedFilterOption === option && (
                    <CheckIcon width={20} height={20} />
                  )}
                </XStack>
                <Separator
                  borderColor={
                    index === Object.keys(filterOptions).length - 1
                      ? 'transparent'
                      : '$gray20'
                  }
                />
              </TPressable>
            ))}
          </View>
        )}
      </View>
    </XStack>
  );
};

const TransactionsFilter = {
  mobile,
  desktop,
};

export default TransactionsFilter;
