import { useMediaQuery } from 'native-base';
import { maxMediaQuery } from '@constants/ui';

const useIsLaptop = () => {
  const [isLaptop] = useMediaQuery({
    maxWidth: maxMediaQuery.laptop,
  });

  return isLaptop;
};

export default useIsLaptop;
