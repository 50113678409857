import { Platform } from 'react-native';

const vhCorrection = (vh: string): string => {
  const vhNumber = parseFloat(vh);
  if (Platform.OS !== 'web' || !vhNumber) {
    return vh;
  } else {
    return `calc(var(--vh, 1vh) * ${vhNumber})`;
  }
};

export default vhCorrection;
