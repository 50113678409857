import { EditEmailProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import EditEmailView from '../../../components/views/More/EditEmailView';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  IEmailField,
  updatePersonaEmailSchema,
} from '@organisms/FillInDataFirstForm/helper';
import { useEffect, useState } from 'react';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { selectCardholder } from '@store/features/cardholder/selectors';
import { useAppDispatch } from '@store/hooks';
import { generalActions } from '@store/features/general/slice';
import { selectProfileSettingsModalVisible } from '@store/features/general/selectors';

const EditEmailPage = ({ navigation }: EditEmailProps) => {
  const userProfileData = useSelector(selectCardholder);
  const [isSaveButtonLoading, setSaveButtonLoading] = useState(false);

  const navigateBack = () => {
    navigation.navigate('ProfileSetting');
    dispatch(generalActions.setProfileSettingsModalVisible(false));
    dispatch(generalActions.setProfileUpdated(false));
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty: isFormDirty },
    reset,
  } = useForm<IEmailField>({
    defaultValues: {
      emailAddress: userProfileData.email,
    },
    resolver: yupResolver(updatePersonaEmailSchema),
  });

  useEffect(() => {
    dispatch(generalActions.setModalSubmitHandler(() => navigateBack()));
    dispatch(generalActions.setProfileUpdated(isFormDirty));
  }, [isFormDirty]);

  const dispatch = useAppDispatch();

  const onSubmit = (data: IEmailField) => {
    setSaveButtonLoading(true);
    dispatch(
      cardholderThunks.updateCardholderInfo({
        email: data.emailAddress,
      })
    )
      .unwrap()
      .then(() => {
        setSaveButtonLoading(false);
        navigateBack();
      })
      .catch(() => {
        reset();
        setSaveButtonLoading(false);
      });
  };

  const profileSettingsModalVisible = useSelector(
    selectProfileSettingsModalVisible
  );

  const setConfirmationModalVisible = (value: boolean) => {
    dispatch(generalActions.setProfileSettingsModalVisible(value));
  };

  return (
    <MediaLayoutTemplate
      Mobile={EditEmailView.mobile}
      Desktop={EditEmailView.desktop}
      navigateBack={navigateBack}
      control={control}
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      isSaveButtonLoading={isSaveButtonLoading}
      isFormDirty={isFormDirty}
      profileSettingsModalVisible={profileSettingsModalVisible}
      setConfirmationModalVisible={setConfirmationModalVisible}
      resetForm={reset}
    />
  );
};

export default EditEmailPage;
