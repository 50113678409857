import * as yup from 'yup';
export type ddaOptionType = 'nothing' | 'percent' | 'number' | '';

export interface IDdaFormInputs {
  companyName: string;
  idNumber: string;
  option: ddaOptionType;
  percent: string;
  number: string;
}

export const ddaFormSchema = yup.object().shape({
  companyName: yup.string(),
  idNumber: yup.string(),
  option: yup.number().required(),
  percent: yup.string().required(),
  number: yup.string().required(),
});
