import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { PaychexLoginView } from '@views/SignUp';
import { SplashScreenWeb } from '@atoms';
import useSignIn from '@hooks/useSignIn';

const PaychexLoginPage = () => {
  const {
    onLogin,
    onAppStorePress,
    onPressHelp,
    onGooglePlayPress,
    onPressBack,
    onSignUpPress,
    loginInfo,
    isLoading,
    modalName,
    toCloseModal,
    isModalOpen,
    handleWebViewNavigationStateChange,
    loading,
    isRetry,
  } = useSignIn();

  if (isLoading || loading) {
    return <SplashScreenWeb />;
  }

  return (
    <MediaLayoutTemplate
      Mobile={PaychexLoginView.mobile}
      Desktop={PaychexLoginView.desktop}
      onPressBack={onPressBack}
      onPressHelp={onPressHelp}
      toCloseModal={toCloseModal}
      isModalOpen={isModalOpen}
      modalName={modalName}
      onGooglePlayPress={onGooglePlayPress}
      onAppStorePress={onAppStorePress}
      loginInfo={loginInfo}
      handleWebViewNavigationStateChange={handleWebViewNavigationStateChange}
      isLoading={loading && isLoading}
      loginWithPopup={onLogin}
      onSignUpPress={onSignUpPress}
      isRetry={isRetry}
    />
  );
};

export default PaychexLoginPage;
