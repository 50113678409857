import { SectionTitleTypes } from '@molecules/SectionTitle/SectionTitle.types';
import { Text } from '@atoms';
import { Separator, View } from 'tamagui';

const SectionTitle = ({ title, dividerMb, dividerMt }: SectionTitleTypes) => {
  return (
    <View>
      <Text
        fontSize="$h5"
        color="black"
        fontWeight="$medium"
        accessibilityLabel={`${title}`}
        accessible
      >
        {title}
      </Text>
      <Separator
        borderColor="$gray20"
        borderWidth={0.5}
        mt={dividerMt}
        mb={dividerMb}
      />
    </View>
  );
};

export default SectionTitle;
