import { AboutPFAccountView } from '@views/SignUp';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useEffect } from 'react';
import changeNavigationBarColor from 'react-native-navigation-bar-color';
import { AuthNavigatorParamsProps } from '@navigation/AuthNavigator';
import useSignIn from '@hooks/useSignIn';
import { SplashScreenWeb } from '@atoms';

const AboutPFAccountPage = () => {
  const navigation = useNavigation<AuthNavigatorParamsProps['navigation']>();
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      changeNavigationBarColor('white', true, false);
    }
  }, [isFocused]);

  const onPressRightButton = () => {
    navigation.goBack();
  };

  const { onSignUpPress, isLoading } = useSignIn();

  if (isLoading) {
    return <SplashScreenWeb />;
  }

  return (
    <AboutPFAccountView
      onSignUpPress={onSignUpPress}
      onPressRightButton={onPressRightButton}
    />
  );
};

export default AboutPFAccountPage;
