import { createSlice } from '@reduxjs/toolkit';
import {
  IAtmLocationsInitialSate,
  IMarkerResponse,
} from '@store/features/atmLocations/types';
import atmLocationsThunks from '@store/features/atmLocations/asyncThunks';

const initialState = {
  markers: [],
  isPending: false,
} as IAtmLocationsInitialSate;

const atmLocationsSlice = createSlice({
  name: 'atmLocations',
  initialState,
  reducers: {
    cleanUpMarkersList(state) {
      state.markers = [];
      state.isPending = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      atmLocationsThunks.getAtmLocations.fulfilled,
      (state, { payload }) => {
        state.markers.push(
          ...payload.locations.map((marker: IMarkerResponse) => {
            const coordinates = {
              latitude: parseFloat(marker.location.coordinates.latitude),
              longitude: parseFloat(marker.location.coordinates.longitude),
            };
            return {
              distance_unit: marker.distance_unit,
              distance: parseFloat(marker.distance),
              location: {
                ...marker.location,
                coordinates,
              },
            };
          })
        );
        state.isPending = false;
      }
    );
    builder.addCase(atmLocationsThunks.getAtmLocations.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(atmLocationsThunks.getAtmLocations.rejected, (state) => {
      state.isPending = false;
    });
  },
});

export const { reducer: atmLocationsReducer, actions: atmLocationsActions } =
  atmLocationsSlice;
