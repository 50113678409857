import WebView from 'react-native-webview';
import { CenterModal, FooterButtonsRow } from '@molecules';
import { FooterMobileTemplate, WebLogoTemplate } from '@templates';
import HeaderWithLineAnim from '@molecules/HeaderWithLineAnim/HeaderWithLineAnim';
import React from 'react';
import { TButton, Text } from '@atoms';
import { IOowQuestion } from '@store/features/oowQuestions/types';
import { RadioGroup, ScrollView, Separator, View, YStack } from 'tamagui';
import RadioGroupItemWithLabel from '@molecules/RadioButtonWithLabel/RadioButtonWithLabel';
import useIsPhone from '@hooks/useIsPhone';

interface commonPropsInterface
  extends OutWalletQuestionsViewContentInterface,
    OutWalletQuestionsFooterContent {}

interface OutWalletQuestionsViewContentInterface {
  progressBarValue: number;
  onRadioButtonSelect: (optionId: string) => void | any[];
  currentQuestion: IOowQuestion;
  currentAnswer: number | undefined;
}

interface OutWalletQuestionsFooterContent {
  onNextPress: (answersArray?: any[]) => void;
  onBackPress: () => void;
  isFirstQuestion: boolean;
  isNextButtonDisabled: boolean;
  isLoading: boolean;
  showSkipButton?: boolean;
  isSkipAnswerAvailable?: boolean;
  onRadioButtonSelect: (optionId: string) => void | any[];
  minutesToExpire: number;
  secondsToExpire: number;
}

interface OutWalletQuestionsViewMobileProps extends commonPropsInterface {
  loginInfo: any;
  handleWebViewNavigationStateChange: () => void;
  isWebViewLoading: boolean;
  minutesToExpire: number;
  secondsToExpire: number;
  isExpireModalOpened: boolean;
  onExpireModalClose: () => void;
}

interface multiPlatformPropsWrapperInterface
  extends OutWalletQuestionsViewMobileProps {}

const formatTime = (time: number) => {
  return String(time).padStart(2, '0');
};

const ExpirationErrorComponent = ({
  onExpireModalClose,
}: {
  onExpireModalClose: () => void;
}) => {
  return (
    <YStack p={16} backgroundColor={'white'} borderRadius={12} gap="$4">
      <Text variant="title" textAlign="center">
        Your time has expired
      </Text>
      <Text variant="caption" textAlign="center">
        Sign in again to verify your identity.
      </Text>
      <Separator borderWidth={0.5} borderColor="$gray20" />
      <TButton variant="primary" onPress={onExpireModalClose}>
        OK
      </TButton>
    </YStack>
  );
};

const OutWalletQuestionsViewContent = ({
  progressBarValue,
  onRadioButtonSelect,
  currentQuestion,
  currentAnswer,
}: OutWalletQuestionsViewContentInterface) => {
  const currentQuestionsWithoutSkipAnswer = currentQuestion.answers.filter(
    (item) => !item.answer.toLowerCase().includes('skip')
  );

  return (
    <>
      <HeaderWithLineAnim
        title={currentQuestion.question}
        progressBarValue={progressBarValue}
      />
      <ScrollView bg="white" px="$4" paddingTop={25}>
        <RadioGroup
          onValueChange={onRadioButtonSelect}
          value={currentAnswer?.toString() || ''}
          name="form"
          accessibilityLabel={`options for ${currentQuestion.question}`}
        >
          <YStack gap={'$5'} alignItems="flex-start">
            {currentQuestionsWithoutSkipAnswer.map((item) => (
              <RadioGroupItemWithLabel
                size="$7"
                key={item.id}
                label={item.answer}
                value={item.id.toString()}
                testID={`paychexpay.OutWalletQuestionsViewContent.radio.${item.answer}`}
              />
            ))}
          </YStack>
        </RadioGroup>
      </ScrollView>
    </>
  );
};

const OutWalletQuestionsFooterContent = ({
  onNextPress,
  onBackPress,
  isFirstQuestion,
  isNextButtonDisabled,
  isLoading,
  showSkipButton = true,
  isSkipAnswerAvailable = false,
  onRadioButtonSelect,
  minutesToExpire,
  secondsToExpire,
}: OutWalletQuestionsFooterContent) => {
  const isPhone = useIsPhone();
  return (
    <View>
      {showSkipButton && isSkipAnswerAvailable && (
        <View
          position={'absolute'}
          top={-60}
          justifyContent={'center'}
          alignItems={'center'}
          left={0}
          right={0}
          zIndex={10}
        >
          <Text
            fontSize={16}
            fontWeight={'600'}
            color={'$primaryNormal'}
            textDecorationStyle={'solid'}
            textDecorationLine={'underline'}
            onPress={async () => {
              const updatedAnswers = onRadioButtonSelect('6') || [];
              await onNextPress(updatedAnswers);
            }}
            testID="paychexpay.outWalletQuestionsViewContent.button.skipButton"
          >
            Skip question
          </Text>
        </View>
      )}
      <>
        {isPhone && (
          <View p={16} pt={0}>
            <Text
              textAlign="center"
              variant="caption"
              textDecorationStyle={'solid'}
            >
              Time remaining: {formatTime(minutesToExpire)}:
              {formatTime(secondsToExpire)}
            </Text>
          </View>
        )}

        {!isFirstQuestion ? (
          <FooterButtonsRow
            firstButton={{
              label: 'Back',
              handler: onBackPress,
              isDisabled: isLoading,
              testID:
                'paychexpay.outWalletQuestionsFooterContent.footerButtonsRow.button.backButton',
            }}
            secondButton={{
              label: 'Next',
              handler: () => onNextPress(),
              isDisabled: isLoading || isNextButtonDisabled,
              testID:
                'paychexpay.outWalletQuestionsFooterContent.footerButtonsRow.button.nextButton',
            }}
            isLoading={isLoading}
          />
        ) : (
          <View flexGrow={1}>
            <TButton
              variant="primary"
              onPress={() => onNextPress()}
              disabled={isNextButtonDisabled}
              testID="paychexpay.outWalletQuestionsFooterContent.button.nextButton"
            >
              Next
            </TButton>
          </View>
        )}
      </>
    </View>
  );
};

const mobile = ({
  progressBarValue,
  onNextPress,
  onBackPress,
  onRadioButtonSelect,
  currentQuestion,
  isFirstQuestion,
  isNextButtonDisabled,
  isLoading,
  currentAnswer,
  loginInfo,
  handleWebViewNavigationStateChange,
  isWebViewLoading,
  isExpireModalOpened,
  minutesToExpire,
  secondsToExpire,
  onExpireModalClose,
}: multiPlatformPropsWrapperInterface) => {
  const isSkipAnswerAvailable = currentQuestion.answers.some((item) =>
    item.answer.toLowerCase().includes('skip')
  );

  return (
    <>
      <FooterMobileTemplate.default
        FooterContent={
          <OutWalletQuestionsFooterContent
            isFirstQuestion={isFirstQuestion}
            isNextButtonDisabled={isNextButtonDisabled}
            isLoading={isLoading}
            onNextPress={onNextPress}
            onBackPress={onBackPress}
            isSkipAnswerAvailable={isSkipAnswerAvailable}
            onRadioButtonSelect={onRadioButtonSelect}
            minutesToExpire={minutesToExpire}
            secondsToExpire={secondsToExpire}
          />
        }
      >
        <OutWalletQuestionsViewContent
          currentQuestion={currentQuestion}
          currentAnswer={currentAnswer}
          progressBarValue={progressBarValue}
          onRadioButtonSelect={onRadioButtonSelect}
        />
        <View h={0}>
          {loginInfo.authorizeUrl !== null && !isWebViewLoading && (
            <WebView
              domStorageEnabled={true}
              source={{ uri: loginInfo.authorizeUrl }}
              startInLoadingState={true}
              renderLoading={() => <></>}
              onNavigationStateChange={handleWebViewNavigationStateChange}
              scalesPageToFit={true}
              style={{
                height: 0,
              }}
            />
          )}
        </View>
      </FooterMobileTemplate.default>

      <CenterModal
        isModalOpen={isExpireModalOpened}
        closeModal={onExpireModalClose}
      >
        <ExpirationErrorComponent onExpireModalClose={onExpireModalClose} />
      </CenterModal>
    </>
  );
};

const desktop = ({
  progressBarValue,
  onNextPress,
  onBackPress,
  onRadioButtonSelect,
  currentQuestion,
  isFirstQuestion,
  isNextButtonDisabled,
  isLoading,
  currentAnswer,
  isExpireModalOpened,
  minutesToExpire,
  secondsToExpire,
  onExpireModalClose,
}: multiPlatformPropsWrapperInterface) => {
  const isSkipAnswerAvailable = currentQuestion.answers.some((item) =>
    item.answer.toLowerCase().includes('skip')
  );

  return (
    <>
      <WebLogoTemplate>
        <View
          borderRadius={12}
          width={441}
          overflow="hidden"
          minHeight={430}
          maxHeight={600}
          borderWidth={1}
          borderColor="$gray20"
        >
          <ScrollView>
            <OutWalletQuestionsViewContent
              currentQuestion={currentQuestion}
              currentAnswer={currentAnswer}
              progressBarValue={progressBarValue}
              onRadioButtonSelect={onRadioButtonSelect}
            />
          </ScrollView>
          <View p="$4">
            <OutWalletQuestionsFooterContent
              minutesToExpire={minutesToExpire}
              secondsToExpire={secondsToExpire}
              isFirstQuestion={isFirstQuestion}
              isNextButtonDisabled={isNextButtonDisabled}
              isLoading={isLoading}
              onNextPress={onNextPress}
              onBackPress={onBackPress}
              showSkipButton={false}
              onRadioButtonSelect={onRadioButtonSelect}
            />
          </View>
        </View>
        <Text
          textAlign="center"
          variant="caption"
          textDecorationStyle={'solid'}
          marginTop={32}
        >
          Time remaining: {formatTime(minutesToExpire)}:
          {formatTime(secondsToExpire)}
        </Text>
        {isSkipAnswerAvailable && (
          <View
            marginTop={32}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Text
              cursor={'pointer'}
              fontSize={16}
              fontWeight={'600'}
              color={'$primaryNormal'}
              textDecorationStyle={'solid'}
              textDecorationLine={'underline'}
              onPress={async () => {
                const updatedAnswers = onRadioButtonSelect('6') || [];
                await onNextPress(updatedAnswers);
              }}
              testID="paychexpay.outWalletQuestionsViewContent.button.skipButton"
            >
              Skip question
            </Text>
          </View>
        )}
      </WebLogoTemplate>
      <CenterModal
        isModalOpen={isExpireModalOpened}
        closeModal={onExpireModalClose}
      >
        <ExpirationErrorComponent onExpireModalClose={onExpireModalClose} />
      </CenterModal>
    </>
  );
};

const OutWalletQuestionsView = {
  mobile,
  desktop,
};

export default OutWalletQuestionsView;
