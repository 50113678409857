import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { ErrorView } from '@views/Common';
import errorsMap from '@store/features/errorStates/errorsMap';
import { Platform } from 'react-native';
import RNRestart from 'react-native-restart';

const ErrorGlobalPage = () => {
  const reloadApp = async () => {
    if (Platform.OS === 'web') {
      window.location.reload();
    } else {
      RNRestart.restart();
    }
  };
  return (
    <MediaLayoutTemplate
      Desktop={ErrorView.desktop}
      Mobile={ErrorView.mobile}
      pageContent={errorsMap.global.body}
      mainButtonHandler={reloadApp}
    />
  );
};

export default ErrorGlobalPage;
