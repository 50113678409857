import { createStackNavigator } from '@react-navigation/stack';
import {
  AllowNotificationsPage,
  CreateCardholderLoaderPage,
  HowToUseCardPage,
  OnboardingPage,
  SetupDdaOnboardingPage,
  WelcomeToPaychexPayPage,
} from '@pages/Onboarding';
import { OnboardingNavigatorParamsList } from '@navigation/OnboardingNavigator/OnboardingNavigator.types';
import { DEFAULT_SCREEN_OPTIONS } from '@constants/navigation';
import { useSelector } from 'react-redux';
import { selectCardholderCurrentStatus } from '@store/features/cardholder/selectors';
import routesToTitlesMap from '../routesToTitlesMap';

const Stack = createStackNavigator<OnboardingNavigatorParamsList>();

const OnboardingNavigator = () => {
  const cardholderCurrentStatus = useSelector(selectCardholderCurrentStatus);
  return (
    <Stack.Navigator
      // @ts-ignore
      defaultScreenOptions={{ headerShown: false }}
      initialRouteName={'CreateCardholderLoaderPage'}
      screenOptions={{ ...DEFAULT_SCREEN_OPTIONS }}
    >
      <Stack.Screen
        name="CreateCardholderLoaderPage"
        component={CreateCardholderLoaderPage}
        options={{
          title: routesToTitlesMap.CreateCardholderLoaderPage,
        }}
      />
      <Stack.Screen
        name="OnboardingPage"
        component={OnboardingPage}
        options={{
          title: routesToTitlesMap.OnboardingPage,
        }}
      />
      {cardholderCurrentStatus !== 'notCreated' && (
        <Stack.Screen
          name="WelcomeToPaychexPayPage"
          component={WelcomeToPaychexPayPage}
          options={{
            title: routesToTitlesMap.WelcomeToPaychexPayPage,
          }}
        />
      )}
      <Stack.Screen
        name="HowToUseCardPage"
        component={HowToUseCardPage}
        options={{
          title: routesToTitlesMap.HowToUseCardPage,
        }}
      />
      <Stack.Screen
        name="AllowNotificationsPage"
        component={AllowNotificationsPage}
        options={{
          title: routesToTitlesMap.AllowNotificationsPage,
        }}
      />
      <Stack.Screen
        name="SetupDdaOnboardingPage"
        component={SetupDdaOnboardingPage}
        options={{
          title: routesToTitlesMap.SetupDdaOnboardingPage,
        }}
      />
    </Stack.Navigator>
  );
};

export default OnboardingNavigator;
