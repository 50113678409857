import { HeaderAndFooterMobileTemplate } from '@templates';
import { AccountsCategory } from '@organisms';
import { ScrollView, View, YStack } from 'tamagui';
import { TButton } from '@atoms';
import { Platform, useWindowDimensions } from 'react-native';
import { MobileWebHeader } from '@molecules';
import React, { useMemo } from 'react';
import { ICategoryAccount } from '@devTypes/accountTypes';
import {
  MOBILE_HEADER_HEIGHT,
  MOBILE_WEB_HEADER_HEIGHT,
} from '@constants/general';

interface SelectAccountViewProps {
  navigateBack: () => void;
  selectAccountType: 'From' | 'To';
  selectAccount: (id: string) => void;
  accounts: any;
  navigateToConnectAccount: () => void;
  navigateToDeleteAccount: (account: ICategoryAccount) => void;
}

const SelectAccountView = ({
  navigateBack,
  selectAccountType,
  selectAccount,
  accounts,
  navigateToConnectAccount,
  navigateToDeleteAccount,
}: SelectAccountViewProps) => {
  const { height: screenHeight } = useWindowDimensions();
  const externalAccounts = useMemo(() => {
    const spendingExternalAccounts = accounts.spending.accounts.filter(
      (account: ICategoryAccount) => account.isExternal
    );
    const savingExternalAccounts = accounts.saving.accounts.filter(
      (account: ICategoryAccount) => account.isExternal
    );
    return [...spendingExternalAccounts, ...savingExternalAccounts];
  }, [accounts]);

  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderAndFooterMobileTemplate
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: selectAccountType,
        }}
        footerContent={
          <TButton
            onPress={navigateToConnectAccount}
            testID="paychexpay.SelectAccountView.button.connectAccountButton"
          >
            Add new account
          </TButton>
        }
      >
        <View
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          {...(Platform.OS === 'web' && {
            height:
              screenHeight -
              MOBILE_WEB_HEADER_HEIGHT -
              MOBILE_HEADER_HEIGHT -
              80,
          })}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              paddingBottom: 20,
            }}
            height={'100%'}
          >
            <YStack px={'$4'} space={'$6'} pt={'$6'}>
              <AccountsCategory
                category={{
                  type: 'external',
                  accounts: externalAccounts,
                }}
                selectAccount={selectAccount}
                navigateToDeleteAccount={navigateToDeleteAccount}
                isSelect
              />
            </YStack>
          </ScrollView>
        </View>
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

export default SelectAccountView;
