import { MastercardFeaturesProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { mastercardFeaturesNames } from '@constants/mastercardFeatures';
import useIsTablet from '@hooks/useIsTablet';
import DotIcon from '../assets/webDrawer/dot.svg';

export interface McFeaturesMenuItem {
  Icon: any;
  pageName: string;
  label: string;
  isSubsectionItem: boolean;
  name: string;
  handler: () => void;
  contentName: string;
  testId?: string;
}

const useMastercardMenu = ({ navigation }: MastercardFeaturesProps) => {
  const isTablet = useIsTablet();

  const navigateToIdentityTheft = () => {
    if (isTablet) {
      navigation.navigate('MoreNavigator', { screen: 'IdentityTheft' });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'MastercardFeatures',
              params: {
                paragraph: mastercardFeaturesNames.identityTheft,
              },
            },
          },
        ],
      });
    }
  };
  const navigateToZeroLiability = () => {
    if (isTablet) {
      navigation.navigate('MoreNavigator', { screen: 'ZeroLiability' });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'MastercardFeatures',
              params: {
                paragraph: mastercardFeaturesNames.zeroLiability,
              },
            },
          },
        ],
      });
    }
  };
  const navigateToAirportConcierge = () => {
    if (isTablet) {
      navigation.navigate('MoreNavigator', { screen: 'AirportConcierge' });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          {
            // @ts-ignore
            name: 'MoreNavigator',
            params: {
              screen: 'MastercardFeatures',
              params: {
                paragraph: mastercardFeaturesNames.airportConcierge,
              },
            },
          },
        ],
      });
    }
  };

  const menuItems = [
    {
      Icon: DotIcon,
      pageName: 'AirportConcierge',
      label: 'Mastercard Airport Concierge™',
      isSubsectionItem: true,
      contentName: mastercardFeaturesNames.airportConcierge,
      name: 'Mastercard Airport Concierge™',
      handler: navigateToAirportConcierge,
      testId: 'paychexpay.mastercardMenu.button.airportConcierge',
    },
    {
      Icon: DotIcon,
      pageName: 'IdentityTheft',
      label: 'Mastercard ID Theft Protection™',
      isSubsectionItem: true,
      contentName: mastercardFeaturesNames.identityTheft,
      name: 'Mastercard ID Theft Protection™',
      handler: navigateToIdentityTheft,
      testId: 'paychexpay.mastercardMenu.button.identityTheft',
    },
    {
      Icon: DotIcon,
      pageName: 'ZeroLiability',
      label: 'Zero Liability',
      isSubsectionItem: true,
      contentName: mastercardFeaturesNames.zeroLiability,
      name: 'Zero Liability',
      handler: navigateToZeroLiability,
      testId: 'paychexpay.mastercardMenu.button.zeroLiability',
    },
  ] as McFeaturesMenuItem[];

  return { menuItems };
};

export default useMastercardMenu;
