import { TransferAmountProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import TransferAmountView from '@views/Transfers/TransferAmountView';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectSavingsAccountPreviewInfo,
  selectSpendingAccountPreviewInfo,
} from '@store/features/cards/selectors';
import { selectTransferStatus } from '@store/features/transfers/selectors';
import transfersThunks from '@store/features/transfers/asyncThunks';
import { selectExternalAccounts } from '@store/features/externalBankAccounts/selectors';
import { IAccountPreview } from '@devTypes/accountTypes';
import { IExternalAccountPreview } from '@store/features/externalBankAccounts/types';
import { useAppDispatch } from '@store/hooks';
import { MAX_TRANSFER_COMMENT_LENGTH } from '@constants/general';
import { selectSpendingAllTransactions } from '@store/features/transactions/selectors';
import { StoreReview } from '@utils/storeReview';
import { Platform } from 'react-native';
import transactionThunks from '@store/features/transactions/asyncThunks';
import cardsThunks from '@store/features/cards/asyncThunks';
import amountCommaCleaner from '@utils/amountCommaCleaner';
import { transfersActions } from '@store/features/transfers/slice';
import { tracker } from '../../../eventCollector';
import { application, operations } from '@constants/traceability';
import { selectVariableTransferFee } from '@store/features/general/selectors';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

const TransferAmountPage = ({ navigation, route }: TransferAmountProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };
  const [comment, setComment] = useState('');
  const [amount, setAmount] = useState<string>('');
  const routesLength = navigation.getState().routes.length;

  const variableTransferFee = useSelector(selectVariableTransferFee);

  const fee = useMemo(() => {
    const routes = navigation.getState().routes;
    switch (routes[routesLength - 2]?.name) {
      case 'BetweenPaychexPay':
        return 'No fee';
      case 'ToExternalBank':
        return 'No fee';
      case 'ToOtherFriends':
        return `A $${variableTransferFee?.value ?? ''} fee applies`;
      default:
        return 'No fee';
    }
  }, [navigation]);
  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const savingsAccount = useSelector(selectSavingsAccountPreviewInfo);
  const externalAccounts = useSelector(selectExternalAccounts);
  const spendingTransactions = useSelector(selectSpendingAllTransactions);
  const [fromAccount, toAccount] = useMemo(() => {
    const accountsPair: (IAccountPreview | IExternalAccountPreview)[] = [];
    const accounts = [spendingAccount, savingsAccount, ...externalAccounts];
    accounts.forEach((account) => {
      if (route.params.fromAccountId === account.id) accountsPair[0] = account;
      if (route.params.toAccountId === account.id) accountsPair[1] = account;
    });
    return accountsPair;
  }, [route]);

  const benInfo =
    // @ts-ignore
    navigation.getState().routes[routesLength - 1].params?.benInfo;

  const isFromExternalAccount = useMemo(() => {
    return (
      fromAccount.id !== spendingAccount.id &&
      fromAccount.id !== savingsAccount.id
    );
  }, [fromAccount]);
  const isToExternalAccount = useMemo(() => {
    if (toAccount) {
      return (
        toAccount.id !== spendingAccount.id &&
        toAccount.id !== savingsAccount.id
      );
    }
  }, [toAccount]);
  const isNotExceedBalance = useMemo(() => {
    return (
      !isFromExternalAccount &&
      fromAccount.amount <
        (isNaN(parseFloat(amountCommaCleaner(amount)))
          ? 0
          : parseFloat(amountCommaCleaner(amount)))
    );
  }, [amount, fromAccount]);

  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const transferStatus = useSelector(selectTransferStatus);
  const [isPressedOnce, setIsPressedOnce] = useState(false);
  const onPressTransfer = () => {
    try {
      if (!isPressedOnce) {
        let toShowReviewModal = false;
        if (spendingTransactions.length === 0) {
          toShowReviewModal = true;
        }
        setIsPressedOnce(true);
        if (isToExternalAccount) {
          const timer = tracker.start(
            'Transfer Amount: Post Outgoing Transfer'
          );
          const txid = tracker.uuid();
          const bizpn = application.bizpnPrefix + operations.OutgoingTransfer;
          const comp = application.compPrefix + operations.OutgoingTransfer;
          dispatch(
            transfersThunks.postOutgoing({
              amount: parseFloat(amountCommaCleaner(amount)),
              card_id: fromAccount.id,
              bank: {
                account_number: toAccount.accountNumber,
                routing_number: toAccount.routingNumber,
                account_type:
                  toAccount.type === 'spending' ? 'CHECKING' : 'SAVINGS',
                account_title: toAccount.name,
                name: toAccount.bankName,
                account_id: toAccount.id,
              },
              same_day_transfer: false,
              description: comment,
              additionalHeaders: {
                'x-payx-txid': txid || tracker.uuid(),
                'x-payx-bizpn': bizpn,
                'x-payx-comp': comp,
              },
            })
          )
            .unwrap()
            .then(() => {
              if (toShowReviewModal && Platform.OS !== 'web') {
                StoreReview.requestReview();
              }
            })
            .catch((err) => handleRequestError(err))
            .finally(() => {
              timer({
                txid: txid,
                bizpn: bizpn,
              });
            });
        } else if (benInfo) {
          dispatch(
            transfersThunks.postNetworkTransfer({
              fromAccountId: fromAccount.id,
              amount: parseFloat(amountCommaCleaner(amount)),
              description: comment,
              email: benInfo.email,
              mobile: benInfo.mobile,
              firstName: benInfo.firstName,
              lastName: benInfo.lastName,
            })
          )
            .unwrap()
            .then(() => {
              if (toShowReviewModal && Platform.OS !== 'web') {
                StoreReview.requestReview();
              }
            })
            .catch((err) => handleRequestError(err));
        } else {
          dispatch(
            transfersThunks.postTransfer({
              fromAccountId: fromAccount.id,
              toAccountId: toAccount.id,
              amount: parseFloat(amountCommaCleaner(amount)),
              description: comment,
            })
          )
            .unwrap()
            .then(() => {
              if (toShowReviewModal && Platform.OS !== 'web') {
                StoreReview.requestReview();
              }
            })
            .catch((err) => handleRequestError(err));
        }
      }
    } catch (e) {
      //handle error
    }
  };

  useEffect(() => {
    const routes = navigation.getState().routes;
    if (transferStatus === 'success') {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'TransferStatus',
            params: {
              status: 'completed',
              transferType: routes[routes.length - 2].name,
            },
          },
        ],
      });
      dispatch(
        transactionThunks.getSpendingTransactions({
          cardId: spendingAccount.id,
          forceRefresh: true,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
      dispatch(cardsThunks.getCardInfo({ cardId: spendingAccount.id }))
        .unwrap()
        .catch((err) => handleRequestError(err));

      if (savingsAccount) {
        dispatch(
          transactionThunks.getSavingsTransactions({
            cardId: savingsAccount.id,
            forceRefresh: true,
          })
        )
          .unwrap()
          .catch((err) => handleRequestError(err));
        dispatch(cardsThunks.getCardInfo({ cardId: savingsAccount.id }))
          .unwrap()
          .catch((err) => handleRequestError(err));
      }
    }
  }, [transferStatus]);

  const isButtonDisabled = useMemo(
    () =>
      comment.length > MAX_TRANSFER_COMMENT_LENGTH ||
      isNotExceedBalance ||
      (isNaN(parseFloat(amountCommaCleaner(amount)))
        ? 0
        : parseFloat(amountCommaCleaner(amount))) === 0,
    [amount, isNotExceedBalance, comment]
  );

  useEffect(() => {
    if (transferStatus !== null) {
      setTimeout(() => {
        dispatch(transfersActions.resetStatus());
      }, 5000);
    }
  }, [transferStatus]);

  return (
    <TransferAmountView
      navigateBack={navigateBack}
      comment={comment}
      setComment={setComment}
      maxCommentLength={MAX_TRANSFER_COMMENT_LENGTH}
      amount={amount}
      setAmount={setAmount}
      fee={fee}
      toAccountName={toAccount?.name || benInfo?.email || benInfo?.mobile || ''}
      fromAccountAmount={fromAccount.amount}
      toAccount={toAccount}
      isNotExceedBalance={isNotExceedBalance}
      onPressTransfer={onPressTransfer}
      isButtonDisabled={isButtonDisabled}
      isLoadingTransferButton={transferStatus !== null}
      isFromExternalAccount={isFromExternalAccount}
    />
  );
};

export default TransferAmountPage;
