import { Switch, Text } from '@atoms';
import { INotificationsOption } from '@store/features/notifications/types';
import { Platform } from 'react-native';
import { Separator, View, XStack } from 'tamagui';

interface NotificationsOptionProps {
  options: INotificationsOption;
  onChangeOption: (
    option: 'email' | 'text' | 'mobilePushPaychexPay'
  ) => (value: boolean) => void;
  title: string;
  topDivider?: boolean;
  isTogglesDisabled?: boolean;
}

const mobile = ({
  options,
  onChangeOption,
  title,
  topDivider = false,
  isTogglesDisabled = false,
}: NotificationsOptionProps) => {
  return (
    <>
      {options && (
        <View bg="white" px={'$4'}>
          {topDivider && <Separator borderColor="$gray20" />}
          <Text
            pt={'$3'}
            pb={'$4'}
            variant="regular"
            accessible
            accessibilityLabel={`${title}`}
          >
            {title}
          </Text>
          <XStack paddingBottom={20} justifyContent="space-between">
            {Platform.OS !== 'web' && (
              <XStack alignItems="center">
                <Text
                  variant="subtitle"
                  pr={'$3'}
                  accessible
                  accessibilityLabel={'Push'}
                >
                  Push
                </Text>
                <Switch
                  checked={options.mobilePushPaychexPay}
                  onChange={onChangeOption('mobilePushPaychexPay')}
                  isDisabled={isTogglesDisabled}
                />
              </XStack>
            )}
            <XStack alignItems="center">
              <Text
                variant="subtitle"
                pr={'$3'}
                accessible
                accessibilityLabel={'Email'}
              >
                Email
              </Text>
              <Switch
                checked={options.email}
                onChange={onChangeOption('email')}
                isDisabled={isTogglesDisabled}
              />
            </XStack>

            <XStack alignItems="center">
              <Text
                variant="subtitle"
                pr={'$3'}
                accessible
                accessibilityLabel={'SMS'}
              >
                Text
              </Text>
              <Switch
                checked={options.text}
                onChange={onChangeOption('text')}
                isDisabled={isTogglesDisabled}
              />
            </XStack>
            {Platform.OS === 'web' && <View w="100px" />}
          </XStack>
          <Separator borderColor="$gray20" />
        </View>
      )}
    </>
  );
};
const desktop = ({
  options,
  onChangeOption,
  title,
  isTogglesDisabled = false,
}: NotificationsOptionProps) => {
  return (
    <>
      {options && (
        <>
          <XStack px={'$4'} justifyContent="space-between" alignItems="center">
            <Text variant="regular" accessible accessibilityLabel={`${title}`}>
              {title}
            </Text>
            <XStack gap={72} pb={'$4'} pt={'$4'}>
              <Switch
                checked={options.email}
                onChange={onChangeOption('email')}
                isDisabled={isTogglesDisabled}
              />
              <Switch
                checked={options.text}
                onChange={onChangeOption('text')}
                isDisabled={isTogglesDisabled}
              />
            </XStack>
          </XStack>
          <Separator borderColor={'$gray20'} borderWidth={0.5} />
        </>
      )}
    </>
  );
};

const NotificationsOption = {
  mobile,
  desktop,
};

export default NotificationsOption;
