import { createTypedThunk } from '@utils/createTypedThunk';
import { generateDdaRequestType } from '@store/features/ddaForm/types';
import ddaFormAPI from '@store/features/ddaForm/api';

const ddaFormThunks = {
  postGenerateDda: createTypedThunk<generateDdaRequestType, any>(
    'ddaForm/postGenerateDda',
    ddaFormAPI.postGenerateDda
  ),
};

export default ddaFormThunks;
