import { useNavigation } from '@react-navigation/native';
import { SetupDdaOnboardingView } from '@views/Onboarding';
import { RootNavigatorParamProps } from '@navigation/RootNavigator/RootNavigator.types';
import { Platform } from 'react-native';
import openLinkInAppBrowser from '@utils/openLinkInAppBrowser';
import { directDepositEligibleLink } from '@constants/endpoints';
import { useSelector } from 'react-redux';
import { selectDdaEligibility } from '@store/features/homeWidgets/selectors';

const SetupDdaOnboardingPage = () => {
  const navigation = useNavigation<RootNavigatorParamProps['navigation']>();
  const ddaEligibility = useSelector(selectDdaEligibility);

  const goToHome = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'BottomTabsNavigator',
        },
      ],
    });
  };

  const goToSetupDda = () => {
    if (ddaEligibility) {
      if (Platform.OS !== 'web') {
        openLinkInAppBrowser(directDepositEligibleLink);
      } else {
        const ddaTab = window.open('', '_blank');
        if (ddaTab) {
          ddaTab.opener = null;
          ddaTab.location.href = directDepositEligibleLink;
        }
      }
      goToHome();
    } else {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'HomeNavigator',
            params: { screen: 'SetupDda', params: { stateName: 'homepage' } },
          },
        ],
      });
    }
  };

  return (
    <SetupDdaOnboardingView
      onGetStartedPress={goToSetupDda}
      onNotNowPress={goToHome}
    />
  );
};

export default SetupDdaOnboardingPage;
