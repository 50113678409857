import { TransfersView } from '@views/Transfers';
import useTransfers from '@hooks/useTransfers';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAllTransfers,
  selectFilteredBy,
  selectIsLoadingSavingsTransactions,
  selectIsLoadingSpendingTransactions,
  selectSavingsAllTransactionsFiltered,
  selectSearchText,
  selectSpendingAllTransactionsFiltered,
} from '@store/features/transactions/selectors';
import { TransfersOptionsType } from '@organisms/TransactionsFilter/TransactionsFilter';
import transactionThunks from '@store/features/transactions/asyncThunks';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import { useAppDispatch } from '@store/hooks';
import { selectShouldForceRefreshNextTransactionsFetch } from '@store/features/transfers/selectors';
import { transfersActions } from '@store/features/transfers/slice';
import { TransfersPageProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { transfersScreensNames } from '@constants/transfersScreensNames';
import { useAuth0 } from '@hooks/useCustomAuth0';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

const TransfersPage = ({ navigation, route }: TransfersPageProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };
  const onPressTransaction = (id: string) => {
    navigation.navigate('HomeNavigator', {
      screen: 'TransactionDetails',
      params: { transactionId: id },
    });
  };

  const { menuItems } = useTransfers({ navigation, route });

  const menuItemsFiltered = menuItems.filter(
    (item) => item.name !== transfersScreensNames.history
  );

  const allTransfers = useSelector(selectAllTransfers);
  const filteredSpendingTransactions = useSelector(
    selectSpendingAllTransactionsFiltered
  );
  const filteredSavingsTransactions = useSelector(
    selectSavingsAllTransactionsFiltered
  );
  const transactionsSearchText = useSelector(selectSearchText);
  const transactionsFilteredBy = useSelector(selectFilteredBy);

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [transfersSelect, setTransferSelect] =
    useState<TransfersOptionsType>('all');
  const selectTransfersOption = (option: TransfersOptionsType) => {
    setTransferSelect(option);
    setIsSelectOpen(false);
  };

  const [transactions, setTransactions] = useState(allTransfers);

  useEffect(() => {
    if (transfersSelect !== 'all') {
      const mergedFilteredTransfers = [
        ...filteredSpendingTransactions,
        ...filteredSavingsTransactions,
      ];

      const sortedByDateFilteredTransfers = mergedFilteredTransfers.sort(
        (a, b) =>
          new Date(b.transaction_datetime).getTime() -
          new Date(a.transaction_datetime).getTime()
      );

      setTransactions(sortedByDateFilteredTransfers);
    }
    setTransactions(allTransfers);
  }, [
    transfersSelect,
    filteredSavingsTransactions,
    filteredSpendingTransactions,
    allTransfers,
  ]);

  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    setRefreshLoading(false);
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingsAccountId = useSelector(selectSavingsAccountId);
  const shouldForceRefreshNextTransactionsFetch = useSelector(
    selectShouldForceRefreshNextTransactionsFetch
  );
  const isLoadingSpending = useSelector(selectIsLoadingSpendingTransactions);
  const isLoadingSavings = useSelector(selectIsLoadingSavingsTransactions);

  useEffect(() => {
    if (!transactions.length) {
      dispatch(
        transactionThunks.getSpendingTransactions({
          cardId: spendingAccountId,
          forceRefresh: shouldForceRefreshNextTransactionsFetch,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
      if (savingsAccountId) {
        dispatch(
          transactionThunks.getSavingsTransactions({
            cardId: savingsAccountId,
            forceRefresh: shouldForceRefreshNextTransactionsFetch,
          })
        )
          .unwrap()
          .catch((err) => handleRequestError(err));
      }

      dispatch(transfersActions.resetForceRefresh());
    }
  }, []);

  const [isRefreshLoading, setRefreshLoading] = useState(false);

  const handleRefreshTransactionsList = () => {
    setRefreshLoading(true);
    dispatch(
      transactionThunks.getSpendingTransactions({
        cardId: spendingAccountId,
        forceRefresh: shouldForceRefreshNextTransactionsFetch,
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));

    if (savingsAccountId) {
      dispatch(
        transactionThunks.getSavingsTransactions({
          cardId: savingsAccountId,
          forceRefresh: shouldForceRefreshNextTransactionsFetch,
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
    }

    setRefreshLoading(false);
  };

  return (
    <MediaLayoutTemplate
      Mobile={TransfersView.mobile}
      isLoadingSpending={isLoadingSpending}
      navigateBack={navigateBack}
      menuItems={menuItemsFiltered}
      onPressTransaction={onPressTransaction}
      transactions={transactions}
      transactionsSearchText={transactionsSearchText}
      transactionsFilteredBy={transactionsFilteredBy}
      isSelectOpen={isSelectOpen}
      selectTransfersOption={selectTransfersOption}
      transfersSelect={transfersSelect}
      isLoadingTransactions={isLoadingSpending || isLoadingSavings}
      isRefreshLoading={isRefreshLoading}
      onRefreshTransactionsList={handleRefreshTransactionsList}
    />
  );
};

export default TransfersPage;
