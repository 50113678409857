import atmLocationsAPI from '@store/features/atmLocations/api';
import {
  IGetAtmLocationsParams,
  IGetAtmLocationsResponse,
} from '@store/features/atmLocations/types';
import { createTypedThunk } from '@utils/createTypedThunk';

const atmLocationsThunks = {
  getAtmLocations: createTypedThunk<
    IGetAtmLocationsParams,
    IGetAtmLocationsResponse
  >('atmLocations/atmLocations', atmLocationsAPI.getAtmLocations),
};

export default atmLocationsThunks;
