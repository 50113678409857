const WHY_WE_NEED_SSN =
  'To sign up for Paychex Pay, you’ll need a valid Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN).';
const PATRIOT_ACT_STATEMENT =
  'To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.  What this means for you:  When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you.  We may also ask to see a copy of your driver’s license or other identifying documents.';
const PAYCHEX_PAY_DEPOSIT_ACCOUNT =
  'The Paychex Pay Deposit Account is established by Central Bank of Kansas City, Member FDIC. The Paychex Pay Debit Mastercard is issued by Central Bank of Kansas City.';
const APPLE_DISCLAIMER =
  'Apple and the Apple logo are trademarks of Apple, Inc., registered in the U.S and other countries. App store is a service mark of Apple Inc., registered in the U.S. and the other countries.';
const GOOGLE_DISCLAIMER =
  'Google Play and Google Play logo are trademarks of Google LLC.';
const BIRTH_DATE_INFO =
  'If your birth date is incorrect, you must update this info in Paychex Flex.';

export {
  WHY_WE_NEED_SSN,
  PATRIOT_ACT_STATEMENT,
  PAYCHEX_PAY_DEPOSIT_ACCOUNT,
  BIRTH_DATE_INFO,
  APPLE_DISCLAIMER,
  GOOGLE_DISCLAIMER,
};
