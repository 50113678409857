/* eslint-disable */
import {
  AnimatedGHContext,
  SlideProps,
  TileCarouselLoopedProps,
} from '@organisms/TileCarouselLooped/TileCarouselLooped.types';
import { StyleSheet, View } from 'react-native';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  DEFAULT_ANIMATION_CONFIG,
  OPACITY_OF_OTHER_SLIDES,
  SCALE_OF_OTHER_SLIDES,
  VELOCITY_THRESHOLD,
} from '@organisms/TileCarouselLooped/constants';
import Svg, { Circle } from 'react-native-svg';
import { v4 } from 'uuid';

const Slide = ({
  item,
  itemWidth,
  renderSlide,
  slidesAnimatedHeight,
  slidesAnimatedOpacity,
  index,
}: SlideProps) => {
  const height = useRef(useSharedValue(1)).current;
  const opacity = useRef(useSharedValue(1)).current;
  // // @ts-ignore
  slidesAnimatedHeight[index] = height;
  slidesAnimatedOpacity[index] = opacity;
  //
  const animatedHeight = useDerivedValue(() => height.value);
  const animatedOpacity = useDerivedValue(() => opacity.value);

  const animatedCarouselProps = useAnimatedStyle(() => {
    return {
      transform: [{ scaleY: animatedHeight.value }],
      opacity: animatedOpacity.value,
    };
  }, []);

  // @ts-ignore
  return (
    <Animated.View style={[animatedCarouselProps]}>
      <View style={{ width: itemWidth }}>{renderSlide(item)}</View>
    </Animated.View>
  );
};

const TileCarouselLooped = ({
  renderSlide,
  data,
  // loop,
  sliderWidth,
  itemWidth,
  withSpringConfig = DEFAULT_ANIMATION_CONFIG,
  scaleOfOtherSlides = SCALE_OF_OTHER_SLIDES,
  opacityOfOtherSlides = OPACITY_OF_OTHER_SLIDES,
}: TileCarouselLoopedProps) => {
  const loop = false; //TODO: remove hotfix
  const [isLooped] = useState(loop);
  const carouselOffsetStep = (selectedIndex: number) => {
    'worklet';
    return itemWidth * -1 * selectedIndex;
  };
  const [carouselData, setCarouselData] = useState(
    !loop ? data : [data[data.length - 1], ...data, data[0]]
  );
  const [slideIndex, setSlideIndex] = useState(0);
  const carouselDataLength = useSharedValue(carouselData.length);
  const [index, setIndex] = useState(loop ? 1 : 0);
  const carouselOffset = useRef(
    useSharedValue(loop ? carouselOffsetStep(1) : 0)
  ).current;
  const slidesAnimatedHeight = useRef<any>([]).current;
  const slidesAnimatedOpacity = useRef<any>([]).current;
  const styles = StyleSheet.create({
    container: {
      width: sliderWidth,
      flexDirection: 'row',
      position: 'relative',
    },
  });

  useEffect(() => {
    runOnJS(setCarouselData)(
      !loop ? data : [data[data.length - 1], ...data, data[0]]
    );
  }, [data]);

  useEffect(() => {
    carouselDataLength.value = carouselData.length;
  }, [carouselData]);

  useEffect(() => {
    setSlideIndex(isLooped ? index - 1 : index);
  }, [index]);

  const animatedCarouselProps = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: carouselOffset.value }],
    };
  }, []);

  const moveToSlide = (newIndex: number) => {
    'worklet';

    if (newIndex === 0 && isLooped) {
      const offsetFromNewIndex =
        carouselOffsetStep(newIndex) - carouselOffset.value;
      carouselOffset.value =
        carouselOffsetStep(carouselDataLength.value - 2) - offsetFromNewIndex;
      slidesAnimatedHeight[carouselDataLength.value - 2].value =
        slidesAnimatedHeight[newIndex].value;
      slidesAnimatedOpacity[carouselDataLength.value - 2].value =
        slidesAnimatedOpacity[newIndex].value;
      carouselOffset.value = withSpring(
        carouselOffsetStep(carouselDataLength.value - 2),
        withSpringConfig
      );
      slidesAnimatedHeight[carouselDataLength.value - 2].value = withSpring(
        1,
        withSpringConfig
      );
      slidesAnimatedOpacity[carouselDataLength.value - 2].value = withSpring(
        1,
        withSpringConfig
      );
      runOnJS(setIndex)(carouselDataLength.value - 2);
    } else if (newIndex === carouselDataLength.value - 1 && isLooped) {
      const offsetFromNewIndex =
        carouselOffsetStep(newIndex) - carouselOffset.value;
      carouselOffset.value = carouselOffsetStep(1) - offsetFromNewIndex;
      slidesAnimatedHeight[1].value = slidesAnimatedHeight[newIndex].value;
      slidesAnimatedOpacity[1].value = slidesAnimatedOpacity[newIndex].value;
      carouselOffset.value = withSpring(
        carouselOffsetStep(1),
        withSpringConfig
      );
      slidesAnimatedHeight[1].value = withSpring(1, withSpringConfig);
      slidesAnimatedOpacity[1].value = withSpring(1, withSpringConfig);
      runOnJS(setIndex)(1);
    } else {
      carouselOffset.value = withSpring(
        carouselOffsetStep(newIndex),
        withSpringConfig
      );
      slidesAnimatedHeight[newIndex].value = withSpring(1, withSpringConfig);
      slidesAnimatedOpacity[newIndex].value = withSpring(1, withSpringConfig);
      runOnJS(setIndex)(newIndex);
    }
  };

  const onGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    AnimatedGHContext
  >({
    onStart: (event, ctx) => {
      ctx.startX = event.x;
      ctx.startCarouselOffset = carouselOffset.value;
      ctx.carouselOffsetStep = carouselOffsetStep;
      //ctx.carouselDataLength = carouselData.length;
    },
    onActive: ({ x }, ctx) => {
      const { carouselOffsetStep } = ctx;
      const xWithOffset = x - ctx.startX;
      if (xWithOffset >= 0) {
        carouselOffset.value = interpolate(
          xWithOffset,
          [0, 360],
          [carouselOffsetStep(index), carouselOffsetStep(index - 1)]
        );
        slidesAnimatedHeight[index].value = interpolate(
          xWithOffset,
          [0, 360],
          [1, scaleOfOtherSlides]
        );
        slidesAnimatedOpacity[index].value = interpolate(
          xWithOffset,
          [0, 360],
          [1, opacityOfOtherSlides]
        );
        if (slidesAnimatedHeight[index - 1]) {
          slidesAnimatedHeight[index - 1].value = interpolate(
            xWithOffset,
            [0, 360],
            [scaleOfOtherSlides, 1]
          );
          slidesAnimatedOpacity[index - 1].value = interpolate(
            xWithOffset,
            [0, 360],
            [opacityOfOtherSlides, 1]
          );
        }
      } else {
        carouselOffset.value = interpolate(
          xWithOffset,
          [-360, 0],
          [carouselOffsetStep(index + 1), carouselOffsetStep(index)]
        );
        slidesAnimatedHeight[index].value = interpolate(
          xWithOffset,
          [-360, 0],
          [scaleOfOtherSlides, 1]
        );
        slidesAnimatedOpacity[index].value = interpolate(
          xWithOffset,
          [-360, 0],
          [opacityOfOtherSlides, 1]
        );
        if (slidesAnimatedHeight[index + 1]) {
          slidesAnimatedHeight[index + 1].value = interpolate(
            xWithOffset,
            [-360, 0],
            [1, scaleOfOtherSlides]
          );
          slidesAnimatedOpacity[index + 1].value = interpolate(
            xWithOffset,
            [-360, 0],
            [1, opacityOfOtherSlides]
          );
        }
      }
      ctx.xWithOffset = xWithOffset;
    },
    onEnd: (e, ctx) => {
      const normalizeSlideIndexValue = (
        currentIndex: number,
        newIndex: number,
        carouselDataLength: number
      ) => {
        if (newIndex >= 0 && newIndex < carouselDataLength) {
          return newIndex;
        } else {
          return currentIndex;
        }
      };
      if (
        ctx.xWithOffset < sliderWidth / -1.5 ||
        e.velocityX < -VELOCITY_THRESHOLD
      ) {
        const newIndex = normalizeSlideIndexValue(
          index,
          index + 1,
          carouselDataLength.value
        );
        moveToSlide(newIndex);
      } else if (
        ctx.xWithOffset > sliderWidth / 1.5 ||
        e.velocityX > VELOCITY_THRESHOLD
      ) {
        const newIndex = normalizeSlideIndexValue(
          index,
          index - 1,
          carouselDataLength.value
        );
        moveToSlide(newIndex);
      } else {
        moveToSlide(index);
      }
    },
  });

  const slidesMap = useMemo(() => {
    return (
      <Animated.View style={[animatedCarouselProps]}>
        <View style={styles.container}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ flexDirection: 'row' }}>
              {carouselData.map((item, index) => {
                return (
                  <Slide
                    key={index}
                    item={item}
                    itemWidth={itemWidth}
                    renderSlide={renderSlide}
                    slidesAnimatedHeight={slidesAnimatedHeight}
                    slidesAnimatedOpacity={slidesAnimatedOpacity}
                    index={index}
                  />
                );
              })}
            </View>
          </View>
        </View>
      </Animated.View>
    );
  }, [carouselData, renderSlide, slidesAnimatedHeight, slidesAnimatedOpacity]);

  const dotsMap = useMemo(
    () => (
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        {data.map((item: any, itemIndex: number) => (
          <Svg
            height="6"
            width="6"
            key={v4()}
            style={{ marginLeft: itemIndex ? 8 : 0 }}
          >
            <Circle
              cx={'3'}
              cy={'3'}
              r={'3'}
              fill={itemIndex === slideIndex ? '#0073C4' : '#DEE3EC'}
            />
          </Svg>
        ))}
      </View>
    ),
    [data, slideIndex]
  );

  return (
    <>
      {/*<GestureHandlerRootView>*/}
      {/*  <PanGestureHandler*/}
      {/*    onGestureEvent={onGestureEvent}*/}
      {/*    activeOffsetX={[-4, 4]}*/}
      {/*    activeOffsetY={[-100, 100]}*/}
      {/*  >*/}
      {/*    <Animated.View style={{ width: sliderWidth }}>*/}
      {/*      {slidesMap}*/}
      {/*      {dotsMap}*/}
      {/*    </Animated.View>*/}
      {/*  </PanGestureHandler>*/}
      {/*</GestureHandlerRootView>*/}

      {carouselData.map((item, index) => { //TODO: remove hotfix
        return (
          <Slide
            key={index}
            item={item}
            itemWidth={itemWidth}
            renderSlide={renderSlide}
            slidesAnimatedHeight={slidesAnimatedHeight}
            slidesAnimatedOpacity={slidesAnimatedOpacity}
            index={index}
          />
        );
      })}
    </>
  );
};

export default TileCarouselLooped;
