import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';
import { ACCOUNT_NAMES } from '@constants/accounts';
import { IAccountPreview } from '@devTypes/accountTypes';
import addressObjToString from '@utils/addressObjToString';
import { accountsNames } from '@utils/transactionsHelper';
import { selectExternalAccounts } from '@store/features/externalBankAccounts/selectors';

export const accountsSelector = (state: RootState) => state.cards;
const cardholderSelector = (state: RootState) => state.cardholder;
const spendingAccountSelector = (state: RootState) => {
  if (!state.cards.spendingAccount.info) return null;
  return state.cards.spendingAccount.info;
};
export const savingAccountSelector = (state: RootState) => {
  if (Object.keys(state.cards.savingAccount.info).length === 0) return null;
  return state.cards.savingAccount.info;
};

export const selectSpendingCardAuthInfo = (state: RootState) => {
  if (!state.cards.spendingAccount.authInfo) return null;
  return state.cards.spendingAccount.authInfo;
};

const cardImageSelector = (state: RootState) => {
  if (!state.cards.spendingAccount.authInfo) return null;
  return state.cards;
};

export const selectSpendingCardPreviewInfo = createSelector(
  spendingAccountSelector,
  (spendingAccount) => {
    if (!spendingAccount?.card_id) {
      return null;
    }
    const cardExpiration = spendingAccount.additional_data.card_expiration;
    return {
      number: '**** **** **** ' + spendingAccount.last_four,
      name: spendingAccount.name_on_card,
      expDate: {
        month: cardExpiration.slice(4, 6),
        year: cardExpiration.slice(2, 4),
      },
      type: 'spending',
    };
  }
);

export const selectSpendingAccountPreviewInfo = createSelector(
  spendingAccountSelector,
  cardholderSelector,
  (spendingAccount, cardholder) => {
    return {
      id: spendingAccount?.card_id || '',
      name: ACCOUNT_NAMES.PaychexPaySpending,
      type: 'spending',
      isExternal: false,
      amount: spendingAccount?.available_balance || 0,
      accountNumber: spendingAccount?.additional_data?.account_number || '',
      routingNumber: spendingAccount?.additional_data?.routing_number || '',
      bankName: 'Central Bank of Kansas City',
      nameOnCard: spendingAccount?.name_on_card || 'Name',
      address: addressObjToString(cardholder.addresses[0]),
      lastFour: spendingAccount?.last_four || '',
    } as IAccountPreview;
  }
);

export const selectSavingsAccountPreviewInfo = createSelector(
  savingAccountSelector,
  cardholderSelector,
  (savingAccount, cardholder) => {
    return {
      id: savingAccount?.card_id || '',
      name: ACCOUNT_NAMES.PaychexPaySaving,
      type: 'saving',
      isExternal: false,
      status: savingAccount?.status,
      amount: savingAccount?.available_balance || 0,
      accountNumber: savingAccount?.additional_data?.account_number || '',
      routingNumber: savingAccount?.additional_data?.routing_number || '',
      bankName: 'Central Bank of Kansas City',
      nameOnCard: savingAccount?.name_on_card || 'Name',
      address: addressObjToString(cardholder.addresses[0]),
      lastFour: savingAccount?.last_four || '',
    } as IAccountPreview;
  }
);

export const selectAccountsCategories = createSelector(
  spendingAccountSelector,
  savingAccountSelector,
  selectExternalAccounts,
  (spendingAccount, savingAccount, externalAccounts) => {
    const externalSavingsAccounts = externalAccounts.filter(
      (account) => account.type === 'saving'
    );
    const externalSpendingAccounts = externalAccounts.filter(
      (account) => account.type === 'spending'
    );
    const spending = {
      type: 'spending',
      amount: spendingAccount?.available_balance || 0,
      accounts: [
        {
          id: spendingAccount?.card_id || '',
          name: ACCOUNT_NAMES.PaychexPaySpending,
          type: 'spending',
          isExternal: false,
          amount: spendingAccount?.available_balance || 0,
          accountNumber: spendingAccount?.additional_data?.account_number || '',
          routingNumber: spendingAccount?.additional_data?.routing_number || '',
          bankName: 'Central Bank of Kansas City',
          isDisabled: false,
        },
        ...externalSpendingAccounts,
      ],
    };
    const saving = {
      type: 'saving',
      amount: savingAccount?.available_balance || 0,
      accounts: savingAccount
        ? [
            {
              id: savingAccount?.card_id || '',
              name: ACCOUNT_NAMES.PaychexPaySaving,
              type: 'saving',
              isExternal: false,
              amount: savingAccount?.available_balance || 0,
              accountNumber:
                savingAccount?.additional_data.account_number || '',
              routingNumber:
                savingAccount?.additional_data.routing_number || '',
              bankName: 'Central Bank of Kansas City',
              isDisabled: savingAccount?.status === 'CLOSED',
            },
            ...externalSavingsAccounts,
          ]
        : externalSavingsAccounts,
    };
    const other = {
      type: 'other',
      amount: 0,
      accounts: [],
    };
    return {
      spending,
      saving,
      other,
    };
  }
);

export const selectPaychexAccountsCategories = createSelector(
  spendingAccountSelector,
  savingAccountSelector,
  (spendingAccount, savingAccount) => {
    const spending = {
      type: 'spending',
      amount: spendingAccount?.available_balance || 0,
      accounts: [
        {
          id: spendingAccount?.card_id || '',
          name: ACCOUNT_NAMES.PaychexPaySpending,
          type: 'spending',
          isExternal: false,
          amount: spendingAccount?.available_balance || 0,
          accountNumber: spendingAccount?.additional_data?.account_number || '',
          routingNumber: spendingAccount?.additional_data?.routing_number || '',
          bankName: 'Central Bank of Kansas City',
          isDisabled: false,
        },
      ],
    };
    const saving = {
      type: 'saving',
      amount: savingAccount?.available_balance || 0,
      accounts: savingAccount
        ? [
            {
              id: savingAccount?.card_id || '',
              name: ACCOUNT_NAMES.PaychexPaySaving,
              type: 'saving',
              isExternal: false,
              amount: savingAccount?.available_balance || 0,
              accountNumber:
                savingAccount?.additional_data.account_number || '',
              routingNumber:
                savingAccount?.additional_data.routing_number || '',
              bankName: 'Central Bank of Kansas City',
              isDisabled: false,
            },
          ]
        : [],
    };
    const other = {
      type: 'other',
      amount: 0,
      accounts: [],
    };
    return {
      spending,
      saving,
      other,
    };
  }
);

export const selectDebitCardStatus = createSelector(
  spendingAccountSelector,
  (spendingAccount) => {
    return spendingAccount?.status || 'ACTIVE';
  }
);

export const selectIsAnimatedBlockCard = createSelector(
  accountsSelector,
  (accounts) => {
    return accounts.spendingAccount.isAnimatedBlockCard;
  }
);

export const selectCardStatusRequestStatus = createSelector(
  accountsSelector,
  (state) => state.cardStatusRequestStatus
);

export const selectPhysicalCardStatus = createSelector(
  spendingAccountSelector,
  (state) => state?.physical_card_status
);

export const selectActiveCardRequestStatus = createSelector(
  accountsSelector,
  (state) => state.activeCardRequestStatus
);
export const selectRequestCardRequestStatus = createSelector(
  accountsSelector,
  (state) => state.orderCardRequestStatue
);

export const selectAccountIdsToNames = createSelector(
  accountsSelector,
  (state) => {
    return {
      [state.spendingAccount.info.card_id]: accountsNames.spending,
      [state.savingAccount.info.card_id]: accountsNames.savings,
    };
  }
);

export const selectChangePinUrl = createSelector(
  accountsSelector,
  (state) => state.changePinUrl
);

export const selectChangePinIsLoading = createSelector(
  accountsSelector,
  (state) => state.changePinIsLoading
);

export const selectSpendingRequestStatus = createSelector(
  accountsSelector,
  (state) => state.spendingAccount.requestStatus
);

export const selectSavingsRequestStatus = createSelector(
  accountsSelector,
  (state) => state.savingAccount.requestStatus
);

export const selectShipmentInfo = createSelector(
  spendingAccountSelector,
  (state) => state?.shipment_info
);

export const cardImage = createSelector(
  cardImageSelector,
  (state) => state?.cardImageSrc
);

export const selectCardUnfreezeCalledFrom = createSelector(
  accountsSelector,
  (state) => state.cardUnfreezeCalledFrom
);
