import { HeaderAndFooterMobileTemplate, SideBarTemplate } from '@templates';
import {
  DesktopAreaHeader,
  FooterButtonsRow,
  MobileWebHeader,
  TextInput,
} from '@molecules';
import {
  IPhoneField,
  phoneOnChange,
} from '@organisms/FillInDataFirstForm/helper';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import EyeHideIcon from '../../../assets/eyeHide.svg';
import EyeShowIcon from '../../../assets/eyeShow.svg';
import usePhoneNumberInputCursorController from '@hooks/usePhoneNumberInputCursorController';
import { Keyboard, Platform } from 'react-native';
import useIsTablet from '@hooks/useIsTablet';
import { accessibleHitSlop } from '@constants/ui';
import ConfirmationModal from '@organisms/ConfirmationModal/ConfirmationModal';
import { Separator, View } from 'tamagui';
import { TPressable } from '@atoms';

interface EditPhoneViewProps {
  navigateBack: () => void;
  control: Control<IPhoneField>;
  onSubmit: () => void;
  errors: FieldErrors;
  isSaveButtonLoading: boolean;
  isFormDirty: boolean;
  profileSettingsModalVisible: boolean;
  setConfirmationModalVisible: (value: boolean) => void;
  resetForm: () => void;
}

const mobile = ({
  navigateBack,
  control,
  onSubmit,
  errors,
  isSaveButtonLoading,
  isFormDirty,
  profileSettingsModalVisible,
  setConfirmationModalVisible,
}: EditPhoneViewProps) => {
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const phoneHideHandler = () => {
    setIsPhoneVisible(!isPhoneVisible);
  };
  const phoneNumberInputRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    phoneNumberInputRef.current?.focus();
  }, []);

  const [isUnsavedChangesAlertVisible, setIsUnsavedChangesAlertVisible] =
    useState(false);

  const cancelHandler = () => {
    if (isFormDirty) {
      Keyboard.dismiss();
      setIsUnsavedChangesAlertVisible(true);
      return;
    }
    navigateBack();
  };

  const goBackHandler = () => {
    if (isUnsavedChangesAlertVisible) {
      setIsUnsavedChangesAlertVisible(false);
    }
    navigateBack();
  };

  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderAndFooterMobileTemplate
        footerContent={
          <FooterButtonsRow
            isLoading={isSaveButtonLoading}
            firstButton={{
              label: 'Cancel',
              handler: cancelHandler,
              variant: 'gray',
              testID: 'paychexpay.editPhoneView.footerButtonsRow.button.cancel',
            }}
            secondButton={{
              label: 'Save',
              handler: onSubmit,
              isDisabled: Object.keys(errors).length !== 0 || !isFormDirty,
              variant: 'darkGray',
              testID:
                'paychexpay.editPhoneView.footerButtonsRow.button.saveMobilePhone',
            }}
          />
        }
        header={{
          title: 'Mobile phone',
          isBackButton: true,
          onPressBack: cancelHandler,
        }}
      >
        <Controller
          name="phoneNumber"
          control={control}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <View flex={1} flexGrow={1} p="$4" flexDirection="row">
              <TextInput
                label="Mobile phone"
                placeholder="(XXX) XXX-XXXX"
                styles={{ width: '100%' }}
                args={{
                  ref: phoneNumberInputRef,
                  onChangeText: (value: string) => {
                    onChange(phoneOnChange(value));
                  },
                  onSubmitEditing: () => {
                    if (!(Object.keys(errors).length !== 0 || !isFormDirty)) {
                      onSubmit();
                    }
                  },
                  onBlur,
                  value,
                  keyboardType: 'number-pad',
                  testID:
                    'paychexpay.mobile.editPhoneView.input.phoneNumberInput',
                  secureTextEntry: !isPhoneVisible,
                }}
                isError={!!error}
                errorMessage={error?.message}
                InputRightElement={
                  <TPressable
                    onPress={phoneHideHandler}
                    p={'$3'}
                    hitSlop={accessibleHitSlop}
                    testID="paychexpay.editPhoneView.button.eyeIcon"
                  >
                    {isPhoneVisible ? <EyeShowIcon /> : <EyeHideIcon />}
                  </TPressable>
                }
              />
            </View>
          )}
        />
      </HeaderAndFooterMobileTemplate>

      <ConfirmationModal
        isModalOpen={
          isUnsavedChangesAlertVisible || profileSettingsModalVisible
        }
        setIsConfirmationModalOpen={(value: boolean) => {
          setConfirmationModalVisible(value);
          setIsUnsavedChangesAlertVisible(value);
        }}
        confirmHandler={goBackHandler}
      />
    </>
  );
};
const desktop = ({
  navigateBack,
  control,
  onSubmit,
  errors,
  isSaveButtonLoading,
  isFormDirty,
  profileSettingsModalVisible,
  setConfirmationModalVisible,
  resetForm,
}: EditPhoneViewProps) => {
  const isTablet = useIsTablet();

  const { selection, saveLastCursorPosition, phoneNumberInputHandler } =
    usePhoneNumberInputCursorController({});

  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const phoneHideHandler = () => {
    setIsPhoneVisible(!isPhoneVisible);
  };

  const phoneNumberInputRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    phoneNumberInputRef.current?.focus();
  }, []);

  const [isUnsavedChangesAlertVisible, setIsUnsavedChangesAlertVisible] =
    useState(false);

  const cancelHandler = () => {
    if (isFormDirty) {
      Keyboard.dismiss();
      setIsUnsavedChangesAlertVisible(true);
      return;
    }
    navigateBack();
    resetForm();
  };

  const goBackHandler = () => {
    if (isUnsavedChangesAlertVisible) {
      setIsUnsavedChangesAlertVisible(false);
    }
    navigateBack();
    resetForm();
  };

  return (
    <>
      <SideBarTemplate>
        <View px={'$7'}>
          <DesktopAreaHeader
            title="Mobile phone"
            backButtonHandler={cancelHandler}
          />
          <View
            mt={'$7'}
            p="20px"
            bg="white"
            borderRadius={12}
            width={isTablet ? '100%' : 364}
          >
            <Controller
              name="phoneNumber"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <>
                  <TextInput
                    label="Mobile phone"
                    placeholder="(XXX) XXX-XXXX"
                    styles={{ mb: '$4' }}
                    args={{
                      ref: phoneNumberInputRef,
                      onChangeText: (newValue: any) => {
                        phoneNumberInputHandler(newValue, value, onChange);
                      },
                      selection: selection,
                      onSelectionChange: ({
                        nativeEvent: {
                          selection: { start },
                        },
                      }: any) => saveLastCursorPosition({ start }),
                      onBlur,
                      value,
                      onSubmitEditing: () => {
                        if (
                          !(Object.keys(errors).length !== 0 || !isFormDirty)
                        ) {
                          onSubmit();
                        }
                      },
                      keyboardType: 'number-pad',
                      testID:
                        'paychexpay.desktop.editPhoneView.input.phoneNumberInput',
                      secureTextEntry: !isPhoneVisible,
                    }}
                    isError={!!error}
                    errorMessage={error?.message}
                    InputRightElement={
                      <TPressable
                        onPress={phoneHideHandler}
                        p={'$3'}
                        testID="paychexpay.editPhoneView.button.eyeIcon"
                      >
                        {isPhoneVisible ? <EyeShowIcon /> : <EyeHideIcon />}
                      </TPressable>
                    }
                  />
                  <Separator mb={'$4'} borderColor="$gray20" />
                  <FooterButtonsRow
                    isLoading={isSaveButtonLoading}
                    firstButton={{
                      label: 'Cancel',
                      handler: cancelHandler,
                      variant: 'gray',
                      testID:
                        'paychexpay.editPhoneView.footerButtonsRow.button.cancel',
                    }}
                    secondButton={{
                      label: 'Save',
                      handler: onSubmit,
                      isDisabled:
                        Object.keys(errors).length !== 0 || !isFormDirty,
                      variant: 'darkGray',
                      testID:
                        'paychexpay.editPhoneView.footerButtonsRow.button.save',
                    }}
                  />
                </>
              )}
            />
          </View>
        </View>
      </SideBarTemplate>
      <ConfirmationModal
        isModalOpen={
          isUnsavedChangesAlertVisible || profileSettingsModalVisible
        }
        setIsConfirmationModalOpen={(value: boolean) => {
          setConfirmationModalVisible(value);
          setIsUnsavedChangesAlertVisible(value);
        }}
        confirmHandler={goBackHandler}
      />
    </>
  );
};

const EditPhoneView = {
  mobile,
  desktop,
};

export default EditPhoneView;
