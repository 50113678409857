const InputConfig = {
  baseStyle: {
    borderWidth: '1px',
    borderRadius: '8px',
    px: '12px',
    py: '14px',
    textAlignVertical: 'center',
    _web: {
      lineHeight: '19px',
    },
    _light: {
      placeholderTextColor: '$gray40',
      bg: 'blue.10',
      borderColor: 'blue.10',
      _hover: {
        borderColor: 'blue.10',
      },
      _focus: {
        bg: 'white',
        borderColor: '$gray20',
        _hover: {
          borderColor: '$gray20',
        },
        _stack: {
          style: {
            outlineWidth: '0px',
          },
        },
      },
      _ios: {
        selectionColor: 'primary.light',
      },
      _android: {
        selectionColor: 'primary.light',
      },
      _invalid: {
        bg: 'red.10',
        borderColor: 'red.50',
        _hover: {
          borderColor: 'red.50',
        },
        _focus: {
          borderColor: 'red.50',
        },
        _stack: {
          style: {
            outlineWidth: '0px',
          },
        },
      },
      _disabled: {
        opacity: '100',
        bg: '$gray10',
        borderColor: '$gray10',
        placeholderTextColor: '$gray30',
        _hover: {
          borderColor: '$gray10',
        },
        color: '$gray40',
      },
    },
  },
  sizes: {
    body: {
      fontSize: 'body',
      py: '13px',
      px: '12px',
    },
  },
  defaultProps: {
    sizes: 'body',
  },
};

export default InputConfig;
