import React, { ReactElement } from 'react';
import { Text } from '@atoms';
import BackButton from '@atoms/BackButton/BackButton';
import { View, XStack } from 'tamagui';

export interface DesktopAreaHeaderProps {
  withButton?: boolean;
  button?: ReactElement;
  title: string;
  backButtonHandler: () => void;
}

const DesktopAreaHeader = ({
  withButton = false,
  button,
  title,
  backButtonHandler,
}: DesktopAreaHeaderProps) => {
  return (
    <XStack
      justifyContent="space-between"
      pt="12px"
      accessible
      accessibilityLabel={`Header ${title || ''}`}
    >
      <XStack pt="13px">
        <BackButton onPress={backButtonHandler} />
        <Text paddingLeft={'$3'} variant="title">
          {title}
        </Text>
      </XStack>
      <View>{withButton && button}</View>
    </XStack>
  );
};

export default DesktopAreaHeader;
