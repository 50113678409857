import { SupportPageProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { SupportView } from '@views/Accounts';
import { supportPageNameToInfo } from '@utils/supportPageNameToInfo';
import { Platform } from 'react-native';
import { useEffect } from 'react';
import { SplashScreenWeb } from '@atoms';

const SupportPage = ({ navigation, route }: SupportPageProps) => {
  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web' && route?.params?.pageName === undefined) {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
      return;
    }
  }, []);

  if (route?.params?.pageName === undefined) {
    return <SplashScreenWeb />;
  }
  return (
    <SupportView
      navigateBack={navigateBack}
      pageInfo={supportPageNameToInfo(route.params.pageName)}
    />
  );
};

export default SupportPage;
