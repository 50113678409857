import useSwiper from '@hooks/useSwiper';
import OnboardingMobileView from '@views/Onboarding/OnboardingMobileView';
import { useMemo, useState } from 'react';
import { slidesArrayLength } from '@constants/onboardingSlides';
import { OnboardingPageProps } from '@navigation/OnboardingNavigator/OnboardingNavigator.types';

const OnboardingPage = ({ navigation }: OnboardingPageProps) => {
  const [isSkipIntroModalOpen, setIsSkipIntroModalOpen] = useState(false);
  const isFromHelp = useMemo(
    () => navigation.getState().routes.length === 1,
    [navigation]
  );

  const onPressSkip = () => {
    if (isFromHelp) {
      navigation.navigate('MoreNavigator', {
        screen: 'Help',
      });
    } else {
      setIsSkipIntroModalOpen(true);
    }
  };
  const goToNextPage = () => {
    setIsSkipIntroModalOpen(false);

    if (isFromHelp) {
      navigation.navigate('MoreNavigator', {
        screen: 'Help',
      });
    } else {
      navigation.navigate('AllowNotificationsPage');
    }
  };
  const { isOpenHelpModal, onCloseHelpModal, onPressNext, index, setIndex } =
    useSwiper({
      onPressLastNext: goToNextPage,
      componentsArrayLength: slidesArrayLength,
    });

  return (
    <OnboardingMobileView
      isOpenHelpModal={isOpenHelpModal}
      onCloseHelpModal={onCloseHelpModal}
      onPressSkip={onPressSkip}
      onPressNext={onPressNext}
      index={index}
      setIndex={setIndex}
      isSkipIntroModalOpen={isSkipIntroModalOpen}
      goToNextPage={goToNextPage}
      isFromHelp={isFromHelp}
    />
  );
};

export default OnboardingPage;
