export { default as HeaderMobileTemplate } from './HeaderMobileTemplate/HeaderMobileTemplate';
export { default as FooterMobileTemplate } from './FooterMobileTemplate/FooterMobileTemplate';
export { default as WebLogoTemplate } from './WebLogoTemplate/WebLogoTemplate';
export { default as HeaderAndFooterMobileTemplate } from './HeaderAndFooterMobileTemplate/HeaderAndFooterMobileTemplate';
export { default as SwiperDesktopWrapper } from './SwiperDesktopWrapper/SwiperDesktopWrapper';
export { default as HomepageLayout } from './HomepageLayout/HomepageLayout';
export { default as SideBarTemplate } from './SideBarTemplate/SideBarTemplate';
export { default as AccountTemplate } from './AccountTemplate/AccountTemplate';
export { default as AccountDesktopTemplate } from './AccountDesktopTemplate/AccountDesktopTemplate';
export { default as TransactionDetailsTemplate } from './TransactionDetailsTemplate/TransactionDetailsTemplate';
