import { createCommonAsyncThunk } from '@utils/commonAsyncThunk';
import agreementsApi from '@store/features/agreements/api';
import { createTypedThunk } from '@utils/createTypedThunk';
import {
  IAgreementsRequest,
  IAgreementsResponse,
  IAgreementsResponseById,
} from './types';

const agreementsThunks = {
  getAgreementById: createTypedThunk<
    IAgreementsRequest,
    IAgreementsResponseById
  >('agreements/getAgreementById', agreementsApi.getAgreementById),
  getAgreementSaving: createCommonAsyncThunk(
    'agreements/getAgreementSaving',
    agreementsApi.getAgreementSaving
  ),
  postAgreementAcknowledgement: createTypedThunk<
    IAgreementsRequest,
    IAgreementsResponse
  >(
    'agreements/postAgreementAcknowledgement',
    agreementsApi.postAgreementAcknowledgement
  ),
};

export default agreementsThunks;
