import { IconWithDescription } from '@molecules';
import { FooterMobileTemplate, WebLogoTemplate } from '@templates';
import ErrorIcon from '../../../assets/error.svg';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { TButton, Text } from '@atoms';
import React from 'react';
import { callPhone } from '@utils/communicationServices';
import { View } from 'tamagui';
import { supportInfo } from '@constants/support';

interface commonPropsInterface {
  logoutHandler: () => void;
}

interface VerificationFailedViewMobileProps {
  commonProps: commonPropsInterface;
}

interface VerificationFailedViewDesktopProps {
  commonProps: commonPropsInterface;
}

const VerificationFailedViewContent = () => {
  const onPressPhone = () => {
    callPhone('+1 (866) 438-0579');
  };

  return (
    <IconWithDescription
      Icon={ErrorIcon}
      description={
        <>
          Have questions? Contact Customer Service at{' '}
          <Text onPress={onPressPhone} color="$primaryNormal" fontSize={14}>
            {supportInfo.phone}
          </Text>
        </>
      }
      title="We are unable to approve your application at this time"
      titlePaddingTop="2"
    />
  );
};

const mobile = ({ commonProps }: VerificationFailedViewMobileProps) => {
  const { top: topOffset } = useSafeAreaInsets();
  return (
    <FooterMobileTemplate.withOneButton
      buttonVariant="red"
      buttonHandler={commonProps.logoutHandler}
      buttonLabel="Close"
      testID="paychexpay.mobile.verificationFailedView.button.logOutButton"
    >
      <View display="flex" bg="white" flexGrow={1} pt={topOffset}>
        <View display="flex" paddingTop="$9" flexGrow={1} alignItems="center">
          <VerificationFailedViewContent />
        </View>
      </View>
    </FooterMobileTemplate.withOneButton>
  );
};

const desktop = ({ commonProps }: VerificationFailedViewDesktopProps) => {
  return (
    <WebLogoTemplate>
      <View display="flex" minHeight="440px" justifyContent="space-between">
        <VerificationFailedViewContent />
        <TButton
          color="red"
          variant="white"
          onPress={commonProps.logoutHandler}
          testID="paychexpay.desktop.verificationFailedView.button.logOutButton"
        >
          Close
        </TButton>
      </View>
    </WebLogoTemplate>
  );
};

const VerificationFailedView = {
  mobile,
  desktop,
};

export default VerificationFailedView;
