import { createTypedThunk } from '@utils/createTypedThunk';
import {
  INotificationsList,
  INotificationsResponse,
  ISingleNotificationOption,
} from '@store/features/notifications/types';
import notificationsAPI from '@store/features/notifications/api';

const notificationsThunks = {
  getNotifications: createTypedThunk<undefined, INotificationsList>(
    'notifications/getNotifications',
    notificationsAPI.getNotifications
  ),
  postNotifications: createTypedThunk<
    INotificationsList | ISingleNotificationOption,
    INotificationsResponse
  >('notifications/postNotifications', notificationsAPI.postNotifications),
};

export default notificationsThunks;
