import { Input } from 'tamagui';
import { useWindowDimensions } from 'react-native';

export interface TransferAmountInputProps {
  value: string;
  onChange: (event: any) => void;
  maxLength?: number;
  onSubmitEditing?: () => void;
}

const TransferAmountInput = ({
  value,
  onChange,
  maxLength,
  onSubmitEditing,
}: TransferAmountInputProps) => {
  const { width: phoneWidth } = useWindowDimensions();

  return (
    <Input
      testID="paychexpay.transferAmountInput"
      autoFocus
      value={value}
      onChangeText={onChange}
      onSubmitEditing={onSubmitEditing}
      {...(maxLength && { maxLength: maxLength })}
      width={phoneWidth}
      backgroundColor={'$blue10'}
      fontSize={36}
      lineHeight={36}
      height={36}
      placeholder="$ 0.00"
      fontWeight="500"
      textAlign={'center'}
      borderWidth={0}
      color="black"
      selectionColor={'$gray40'}
      placeholderTextColor={'$gray40'}
      keyboardType="decimal-pad"
      defaultValue="0"
      returnKeyType="next"
      blurOnSubmit={true}
      focusStyle={{ borderWidth: 0, outlineWidth: 0 }}
      accessible
      accessibilityLabel="Transfer amount input"
    />
  );
};

export default TransferAmountInput;
