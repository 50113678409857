import { Text } from '@atoms';
import React, { ReactNode, useState } from 'react';
import { Platform } from 'react-native';
import { View, Input, XStack } from 'tamagui';

export interface TextInputProps {
  label?: string;
  customLabel?: ReactNode;
  placeholder?: string;
  errorMessage?: string;
  styles?: any;
  args?: any & React.RefAttributes<unknown>;
  isError?: boolean;
  isDisabled?: boolean;
  InputRightElement?: ReactNode;
  InputLeftElement?: ReactNode;
}

const TextInput = ({
  label,
  customLabel,
  placeholder,
  errorMessage,
  isError = false,
  isDisabled = false,
  styles,
  args,
  InputRightElement,
  InputLeftElement,
}: TextInputProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const backgroundColorHandler = () => {
    const color = 'rgb(246, 247, 249)';
    if (isDisabled) return '$gray10';
    if (isInputFocused && Platform.OS !== 'web') return 'white';
    if (isInputFocused && !isHovered) return 'white';
    if (isInputFocused && isHovered) return 'rgb(246, 247, 249)';
    if (!isInputFocused && isHovered) return '#EFEFEF';

    return color;
  };

  return (
    <View {...styles} flexDirection="column" display="flex">
      {customLabel && customLabel}
      {label && (
        <Text
          color="$gray40"
          lineHeight="$h2"
          fontSize={'$h5'}
          marginBottom={1}
        >
          {label}
        </Text>
      )}
      <XStack
        borderWidth={0.5}
        borderColor={isInputFocused ? '$gray20' : 'transparent'}
        flex={1}
        backgroundColor={backgroundColorHandler()}
        borderRadius={8}
        height={48}
        maxHeight={49}
        onHoverIn={() => setIsHovered(true)}
        onHoverOut={() => setIsHovered(false)}
        disabled={isDisabled}
        disabledStyle={{
          opacity: 1,
          bg: '$gray10',
          borderColor: '$gray10',
        }}
      >
        {InputLeftElement && (
          <View flex={0} maxWidth={48} position="absolute" left={0} zIndex={5}>
            {InputLeftElement}
          </View>
        )}
        <Input
          isInvalid={isError}
          placeholder={placeholder}
          editable={!isDisabled}
          borderWidth={0}
          flex={1}
          padding={9.5}
          paddingLeft={InputLeftElement ? 48 : 9.5}
          paddingRight={InputRightElement ? 48 : 9.5}
          placeholderTextColor="#6F7686"
          color={isDisabled ? '$gray40' : '$black'}
          backgroundColor={'transparent'}
          borderColor="$gray20"
          borderRadius={8}
          minHeight={47}
          disabled={isDisabled}
          fontSize={'$body'}
          {...args}
          style={{
            textAlignVertical: 'center',
            justifyContent: 'center',
            borderWidth: 0,
          }}
          onFocus={() => {
            args.onFocus && args.onFocus();
            setIsInputFocused(true);
          }}
          onBlur={() => {
            args.onBlur && args.onBlur();
            setIsInputFocused(false);
          }}
          focusVisibleStyle={
            !isDisabled
              ? !InputRightElement && !InputLeftElement
                ? {
                    borderWidth: 0.5,
                    borderStyle: 'none !important',
                    outlineStyle: 'none',
                  }
                : {
                    borderWidth: 0,
                    borderStyle: 'none !important',
                    outlineStyle: 'none',
                  }
              : {
                  borderWidth: 0,
                  borderStyle: 'none !important',
                  outlineStyle: 'none',
                }
          }
          accessible
          accessibilityLabel={`${label}`}
        />
        {InputRightElement && (
          <View
            alignSelf="flex-end"
            flex={0}
            maxWidth={48}
            position="absolute"
            right={0}
            zIndex={5}
          >
            {InputRightElement}
          </View>
        )}
      </XStack>
      {isError && (
        <Text
          variant="error"
          accessible
          accessibilityLabel={`${errorMessage}`}
          marginTop={5}
        >
          {errorMessage}
        </Text>
      )}
    </View>
  );
};

export default TextInput;
