import { Platform } from 'react-native';
import open from 'react-native-open-maps';

interface getDirectionProps {
  lat?: number;
  lng?: number;
  address?: string;
  provider?: 'google' | 'apple';
}

const getDirection = ({ lat, lng, address, provider }: getDirectionProps) => {
  if (Platform.OS === 'web') {
    const getDirectionTab = window.open('', '_blank');
    if (getDirectionTab) {
      getDirectionTab.opener = null;
      getDirectionTab.location.href = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    }
  } else {
    open({ end: address, provider: provider });
  }
};

export default getDirection;
