import { useSelector } from 'react-redux';
import transactionThunks from '@store/features/transactions/asyncThunks';
import { useEffect, useState } from 'react';
import {
  selectSavingsAccountId,
  selectSpendingAccountId,
} from '@store/features/cardholder/selectors';
import { TransactionsOptionsType } from '@organisms/TransactionsFilter/TransactionsFilter';
import { transactionsActions } from '@store/features/transactions/slice';
import { useAppDispatch } from '@store/hooks';
import usePrevious from './usePrevious';
import { useIsFocused } from '@react-navigation/native';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
import { useAuth0 } from './useCustomAuth0';
import { tracker } from '../eventCollector';
import { TimerStopFunction } from '@paychex/core/types/trackers';
import { application, operations } from '@constants/traceability';
import cardsThunks from '@store/features/cards/asyncThunks';
import { ITransactionsResponse } from '@store/features/transactions/types';
import {
  selectFilteredBy,
  selectSavingAllTransactionsItemsCount,
  selectSpendingAllTransactionsItemsCount,
} from '@store/features/transactions/selectors';
import { Platform } from 'react-native';

interface useSearchTransactions {
  filterType: 'saving' | 'spending' | 'transfers';
  setTransferFilterOption?: (option: 'savings' | 'spending' | 'all') => void;
}

const transactionTypeFilter = (option: TransactionsOptionsType) => {
  switch (option) {
    case 'spending':
      return 'DEBIT';
    case 'savings':
      return 'CREDIT';
    case 'fees':
      return 'FEE';
    case 'interest':
      return 'INTEREST';
    default:
      return '';
  }
};

const useFiltersTransactions = ({
  filterType,
  setTransferFilterOption,
}: useSearchTransactions) => {
  const { onLogout, accessTokenRenew } = useAuth0();
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();
  const spendingAccountId = useSelector(selectSpendingAccountId);
  const savingsAccountId = useSelector(selectSavingsAccountId);
  const transactionsFilteredBy = useSelector(selectFilteredBy);

  const [searchText, setSearchText] = useState<string | undefined>();
  const [validatedText, setValidatedText] = useState<string>(
    searchText?.trim() || ''
  );
  const [isCalendarClearPressed, setIsCalendarClearPressed] = useState(false);

  const changeSearchTextHandler = (text: string) => {
    setSearchText(text);
  };

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectDateOpen, setIsSelectDateOpen] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<TransactionsOptionsType>('all');
  const [dateRange, setDateRange] = useState({
    date_from: null,
    date_to: null,
  });
  const prevSelectedFilterOption: TransactionsOptionsType | undefined =
    usePrevious(selectedFilterOption);
  const prevValidatedText: string | undefined = usePrevious(validatedText);
  const prevIsSelectDateOpen: boolean | undefined =
    usePrevious(isSelectDateOpen);

  useEffect(() => {
    if (transactionsFilteredBy === '' && Platform.OS !== 'web') {
      selectOption('all');
    }
  }, [transactionsFilteredBy]);

  useEffect(() => {
    if (setTransferFilterOption) {
      if (selectedFilterOption === 'savings')
        setTransferFilterOption('savings');
      else if (selectedFilterOption === 'spending')
        setTransferFilterOption('spending');
      else setTransferFilterOption('all');
    }
  }, [selectedFilterOption]);

  useEffect(() => {
    if (isFocused) {
      dispatch(
        transactionsActions.setDateRange({
          date_from: null,
          date_to: null,
        })
      );
    }
  }, [isFocused]);

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      const shouldBeRefreshed = await accessTokenRenewCheck(accessToken);
      if (shouldBeRefreshed) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const spendingAllTransactionsItemsCount = useSelector(
    selectSpendingAllTransactionsItemsCount
  );
  const savingAllTransactionsItemsCount = useSelector(
    selectSavingAllTransactionsItemsCount
  );

  const fetchCardInfoForUpdatingTheBalance = async (
    responseData: ITransactionsResponse,
    cardId: string,
    type?: 'spending' | 'saving'
  ) => {
    const transactionsItemsCount =
      type === 'saving'
        ? savingAllTransactionsItemsCount
        : spendingAllTransactionsItemsCount;

    if (responseData.metadata.contentItemCount !== transactionsItemsCount) {
      dispatch(cardsThunks.getCardInfo({ cardId }))
        .unwrap()
        .catch((err) => handleRequestError(err));
    }
  };

  const toOpenOptions = () => {
    setIsSelectOpen(true);
  };

  const toCloseOptions = () => {
    setIsSelectOpen(false);
  };

  const selectOption = (option: TransactionsOptionsType) => {
    setSelectedFilterOption(option);
    setIsSelectOpen(false);
  };

  const getFilteredTransactions = () => {
    if (spendingAccountId && filterType === 'spending') {
      // "Spending Transaction Date Searching" CT
      const spendingTransactionsDateTimer = tracker.start(
        'Spending Account: Fetch Filtered Spending Transactions'
      );
      const spendingTransactionsDateBizpn =
        application.bizpnPrefix + operations.FilteredSpendingTransactions;
      const spendingTransactionsDateComp =
        application.compPrefix + operations.FilteredSpendingTransactions;
      const spendingTransactionsDateTxid = tracker.uuid();
      if (selectedFilterOption !== 'all') {
        dispatch(
          transactionThunks.getSpendingTransactionsByFilter({
            cardId: spendingAccountId,
            transactionType: transactionTypeFilter(selectedFilterOption),
            date_from: dateRange.date_from || '',
            date_to: dateRange.date_to || '',
            additionalHeaders: {
              'x-payx-txid': spendingTransactionsDateTxid || tracker.uuid(),
              'x-payx-bizpn': spendingTransactionsDateBizpn,
              'x-payx-comp': spendingTransactionsDateComp,
            },
          })
        )
          .unwrap()
          .then(() => {
            spendingTransactionsDateTimer({
              txid: spendingTransactionsDateTxid,
              bizpn: spendingTransactionsDateBizpn,
            });
          })
          .catch((err) => handleRequestError(err));
        dispatch(transactionsActions.setSearchText(''));
      } else {
        // "Load Spending Transactions" CT - occurs upon initial load of spending account page
        const spendingTransactionsTimer = tracker.start(
          'Spending Account: Fetch All Spending Transactions'
        );
        const spendingTransactionsBizpn =
          application.bizpnPrefix + operations.AllSpendingTransactions;
        const spendingTransactionsComp =
          application.compPrefix + operations.AllSpendingTransactions;
        const spendingTransactionsTxid = tracker.uuid();
        dispatch(
          transactionThunks.getSpendingTransactions({
            cardId: spendingAccountId,
            additionalHeaders: {
              'x-payx-txid': spendingTransactionsTxid || tracker.uuid(),
              'x-payx-bizpn': spendingTransactionsBizpn,
              'x-payx-comp': spendingTransactionsComp,
            },
          })
        )
          .unwrap()
          .then((responseData) => {
            fetchCardInfoForUpdatingTheBalance(
              responseData,
              spendingAccountId,
              'spending'
            );
            spendingTransactionsTimer({
              txid: spendingTransactionsTxid,
              bizpn: spendingTransactionsBizpn,
            });
          })
          .catch((err) => handleRequestError(err));
      }
    }

    if (savingsAccountId && filterType === 'saving') {
      if (selectedFilterOption !== 'all') {
        dispatch(
          transactionThunks.getSavingsTransactionsByFilter({
            cardId: savingsAccountId,
            transactionType: transactionTypeFilter(selectedFilterOption),
            date_from: dateRange.date_from || '',
            date_to: dateRange.date_to || '',
          })
        )
          .unwrap()
          .catch((err) => handleRequestError(err));
        dispatch(transactionsActions.setSearchText(''));
      } else {
        dispatch(
          transactionThunks.getSavingsTransactions({
            cardId: savingsAccountId,
          })
        )
          .unwrap()
          .then((responseData) =>
            fetchCardInfoForUpdatingTheBalance(
              responseData,
              savingsAccountId,
              'saving'
            )
          )
          .catch((err) => handleRequestError(err));
      }
    }
  };

  const getFilteredSpendingAndSavingTransactions = () => {
    // This gets called on initial load of transfers
    let spendingSubtxnbr: string | undefined;
    let savingsSubtxnbr: string | undefined;
    let spendingTimer: TimerStopFunction | undefined;
    let savingsTimer: TimerStopFunction | undefined;
    let transactionsTxid: string | undefined;
    let spendingBizpn: string | undefined;
    let savingsBizpn: string | undefined;
    let spendingComp: string | undefined;
    let savingsComp: string | undefined;
    if (
      spendingAccountId &&
      savingsAccountId &&
      transactionTypeFilter(selectedFilterOption) === ''
    ) {
      // Parallel calls are invoked - "Recent Transaction Display" CT
      spendingTimer = tracker.start(
        'Transfer History: Fetch All Spending Transactions'
      );
      savingsTimer = tracker.start(
        'Transfer History: Fetch All Savings Transactions'
      );
      spendingSubtxnbr = '1';
      savingsSubtxnbr = '2';
      transactionsTxid = tracker.uuid();
      spendingBizpn =
        application.bizpnPrefix + operations.AllSpendingTransactions;
      savingsBizpn =
        application.bizpnPrefix + operations.AllSavingsTransactions;
      spendingComp =
        application.compPrefix + operations.AllSpendingTransactions;
      savingsComp = application.compPrefix + operations.AllSavingsTransactions;
    } else if (
      spendingAccountId &&
      transactionTypeFilter(selectedFilterOption) === ''
    ) {
      // "Recent Transaction Display" CT - case where only spending account exists
      spendingTimer = tracker.start(
        'Transfer History: Fetch All Spending Transactions'
      );
      transactionsTxid = tracker.uuid();
      spendingBizpn =
        application.bizpnPrefix + operations.AllSpendingTransactions;
      spendingComp =
        application.compPrefix + operations.AllSpendingTransactions;
    }
    if (
      spendingAccountId &&
      transactionTypeFilter(selectedFilterOption) !== ''
    ) {
      dispatch(
        transactionThunks.getSpendingTransactionsByFilter({
          cardId: spendingAccountId,
          transactionType: transactionTypeFilter(selectedFilterOption),
          date_from: dateRange.date_from || '',
          date_to: dateRange.date_to || '',
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
    } else if (spendingAccountId) {
      dispatch(
        transactionThunks.getSpendingTransactions({
          cardId: spendingAccountId,
          additionalHeaders: {
            'x-payx-txid': transactionsTxid || tracker.uuid(),
            'x-payx-bizpn': spendingBizpn,
            'x-payx-comp': spendingComp,
            'x-payx-subtxnbr': spendingSubtxnbr,
          },
        })
      )
        .unwrap()
        .then((responseData) => {
          fetchCardInfoForUpdatingTheBalance(
            responseData,
            spendingAccountId,
            'spending'
          );
          if (spendingTimer) {
            spendingTimer({
              txid: transactionsTxid,
              bizpn: spendingBizpn,
              subtxnbr: spendingSubtxnbr,
            });
          }
        })
        .catch((err) => handleRequestError(err));
    }
    if (
      savingsAccountId &&
      transactionTypeFilter(selectedFilterOption) !== ''
    ) {
      dispatch(
        transactionThunks.getSavingsTransactionsByFilter({
          cardId: savingsAccountId,
          transactionType: transactionTypeFilter(selectedFilterOption),
          date_from: dateRange.date_from || '',
          date_to: dateRange.date_to || '',
        })
      );
    } else if (savingsAccountId) {
      dispatch(
        transactionThunks.getSavingsTransactions({
          cardId: savingsAccountId,
          additionalHeaders: {
            'x-payx-txid': transactionsTxid || tracker.uuid(),
            'x-payx-bizpn': savingsBizpn,
            'x-payx-comp': savingsComp,
            'x-payx-subtxnbr': savingsSubtxnbr,
          },
        })
      )
        .unwrap()
        .then((responseData) => {
          if (savingsTimer) {
            savingsTimer({
              txid: transactionsTxid,
              bizpn: savingsBizpn,
              subtxnbr: savingsSubtxnbr,
            });
          }
          fetchCardInfoForUpdatingTheBalance(
            responseData,
            savingsAccountId,
            'saving'
          );
        })
        .catch((err) => handleRequestError(err));
    }

    dispatch(transactionsActions.setSearchText(''));
  };

  const searchTransactions = (validatedText: string) => {
    if (filterType !== 'saving') {
      // "Spending Transaction text searching" CT
      const searchTransactionsTimer = tracker.start(
        'Spending Account: Search Spending Transactions'
      );
      const searchSpendingBizpn =
        application.bizpnPrefix + operations.SearchSpendingTransactions;
      const searchSpendingComp =
        application.compPrefix + operations.SearchSpendingTransactions;
      const searchSpendingTxid = tracker.uuid();
      dispatch(
        transactionThunks.getSpendingTransactionsBySearch({
          cardId: spendingAccountId,
          searchText: validatedText,
          additionalHeaders: {
            'x-payx-txid': searchSpendingTxid || tracker.uuid(),
            'x-payx-bizpn': searchSpendingBizpn,
            'x-payx-comp': searchSpendingComp,
          },
        })
      )
        .unwrap()
        .then(() => {
          searchTransactionsTimer({
            txid: searchSpendingTxid,
            bizpn: searchSpendingBizpn,
          });
        })
        .catch((err) => handleRequestError(err));
      dispatch(transactionsActions.setSearchText(validatedText));
    }
    if (savingsAccountId && filterType !== 'spending') {
      dispatch(
        transactionThunks.getSavingsTransactionsBySearch({
          cardId: savingsAccountId,
          searchText: validatedText,
        })
      );
      dispatch(transactionsActions.setSearchText(validatedText));
    }
  };

  const getAllTransactions = () => {
    if (filterType !== 'saving') {
      dispatch(
        transactionThunks.getSpendingTransactions({
          cardId: spendingAccountId,
        })
      )
        .unwrap()
        .then((responseData) =>
          fetchCardInfoForUpdatingTheBalance(
            responseData,
            spendingAccountId,
            'spending'
          )
        )
        .catch((err) => handleRequestError(err));
      dispatch(transactionsActions.setSearchText(''));
    }
    if (savingsAccountId && filterType !== 'spending') {
      dispatch(
        transactionThunks.getSavingsTransactions({
          cardId: savingsAccountId,
        })
      )
        .unwrap()
        .then((responseData) =>
          fetchCardInfoForUpdatingTheBalance(
            responseData,
            savingsAccountId,
            'saving'
          )
        )
        .catch((err) => handleRequestError(err));
      dispatch(transactionsActions.setSearchText(''));
    }
  };

  const getTransactions = (
    validatedText: string,
    type: 'filterByDate' | 'filterByText' | 'filterByType'
  ) => {
    let timeoutId: string | number | NodeJS.Timeout | undefined = undefined;

    if (validatedText === '') {
      if (
        selectedFilterOption === 'all' &&
        prevSelectedFilterOption !== undefined
      ) {
        getAllTransactions();
      } else if (filterType === 'transfers') {
        getFilteredSpendingAndSavingTransactions();
      } else {
        getFilteredTransactions();
      }
    } else {
      if (selectedFilterOption === 'all') {
        timeoutId = setTimeout(() => {
          searchTransactions(validatedText);
        }, 500);
      } else {
        if (type === 'filterByType') {
          setSearchText('');
        }

        if (type === 'filterByText') {
          timeoutId = setTimeout(() => {
            setSelectedFilterOption('all');
            searchTransactions(validatedText);
          }, 500);
        }
      }
    }

    return timeoutId;
  };

  useEffect(() => {
    let timeoutId: string | number | NodeJS.Timeout | undefined = undefined;
    if (!isSelectDateOpen && prevIsSelectDateOpen && isCalendarClearPressed) {
      if (dateRange.date_to === null && dateRange.date_from === null) {
        dispatch(transactionsActions.setDateRange(dateRange));
        timeoutId = getTransactions(validatedText, 'filterByDate');
      }
      setIsCalendarClearPressed(false);
    }

    return () => clearTimeout(timeoutId);
  }, [isSelectDateOpen, isCalendarClearPressed, dateRange, validatedText]);

  useEffect(() => {
    if (dateRange.date_to !== null && dateRange.date_from !== null) {
      dispatch(transactionsActions.setDateRange(dateRange));
      const timeoutId = getTransactions(validatedText, 'filterByDate');

      return () => clearTimeout(timeoutId);
    }
  }, [dateRange, validatedText]);

  useEffect(() => {
    if (
      validatedText !== prevValidatedText &&
      ((prevValidatedText !== undefined && prevValidatedText.length > 0) ||
        (validatedText.length > 0 &&
          (prevValidatedText === undefined || prevValidatedText.length > 0)) ||
        (validatedText.length > 0 &&
          (prevValidatedText === undefined || prevValidatedText.length === 0)))
    ) {
      const timeoutId = getTransactions(validatedText, 'filterByText');

      return () => clearTimeout(timeoutId);
    }
  }, [searchText, validatedText, prevValidatedText]);

  useEffect(() => {
    const validatedTextTimeout = setTimeout(() => {
      setValidatedText(searchText?.trim() || '');
    }, 300);

    return () => clearTimeout(validatedTextTimeout);
  }, [searchText]);

  useEffect(() => {
    if (selectedFilterOption !== prevSelectedFilterOption) {
      const timeoutId = getTransactions(validatedText, 'filterByType');

      return () => clearTimeout(timeoutId);
    }
  }, [selectedFilterOption, prevSelectedFilterOption, validatedText]);

  return {
    searchText,
    changeSearchTextHandler,
    isSelectOpen,
    selectOption,
    isSelectDateOpen,
    setIsSelectDateOpen,
    isCalendarClearPressed,
    setIsCalendarClearPressed,
    selectedFilterOption,
    toOpenOptions,
    toCloseOptions,
    setDateRange,
    dateRange,
  };
};

export default useFiltersTransactions;
