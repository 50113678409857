import React, { useState } from 'react';
import PaychexPayAccountIcon from '../../../assets/accounts/paychexpayAccountIcon.svg';
import ExternalLink from '../../../assets/externalLink.svg';
import amountToString from '@utils/amountToString';
import { navigateToAccountType } from '@devTypes/accounts';
import { Button, Pressable, TPressable, Text } from '@atoms';
import useIsPhone from '@hooks/useIsPhone';
import { IAccountsCategory, ICategoryAccount } from '@devTypes/accountTypes';
import { agreementsActions } from '@store/features/agreements/slice';
import ExternalAccountIcon from '../../../assets/accounts/default.svg';
import { useAppDispatch } from '@store/hooks';
import { customTheme } from '../../../styles/customTheme';
import InfoIcon from '../../../assets/infoIconSignIn.svg';
import { accessibleHitSlop } from '@constants/ui';
import { View, XStack, YStack } from 'tamagui';

export interface AccountsCategoryProps {
  category: IAccountsCategory;
  openPaychexPaySavings?: () => void;
  navigateToAccount?: navigateToAccountType;
  selectAccount?: (id: string) => void;
  isSelect?: boolean;
  navigateToAgreement?: () => void;
  navigateToDeleteAccount?: (account: ICategoryAccount) => void;
  openHintModal?: () => void;
}

const AccountsCategory = ({
  category,
  openPaychexPaySavings,
  navigateToAccount,
  selectAccount,
  isSelect = false,
  navigateToAgreement,
  navigateToDeleteAccount,
  openHintModal,
}: AccountsCategoryProps) => {
  const isPhone = useIsPhone();
  const dispatch = useAppDispatch();
  const [isArrowPressed, setIsArrowPressed] = useState(false);

  const isSavingCategory = category.type === 'saving';
  const isPaychexPaySavingAccountExists = category.accounts.find(
    (item) => item.type === 'saving'
  );

  if (openPaychexPaySavings) {
    openPaychexPaySavings();
  }
  const onPressOpenSaving = () => {
    dispatch(agreementsActions.resetState());
    navigateToAgreement && navigateToAgreement();
  };

  return (
    <View>
      <XStack
        justifyContent="space-between"
        accessible
        accessibilityLabel={`${
          category.type === 'spending'
            ? 'Spendings'
            : category.type === 'saving'
            ? 'Savings'
            : ''
        }, ${
          !isSelect && category.accounts.length !== 0
            ? amountToString(category.amount || 0)
            : ''
        }`}
      >
        <View flexDirection={'row'} alignItems={'center'}>
          <Text variant={!isSelect || isPhone ? 'title' : 'semiTitle'}>
            {category.type === 'spending' && 'Spendings'}
            {category.type === 'saving' && 'Savings'}
            {category.type === 'external' && 'External'}
            {category.type === 'paychexPay' && 'Paychex Pay'}
          </Text>

          {category.type === 'paychexPay' && (
            <Pressable
              hitSlop={accessibleHitSlop}
              onPress={openHintModal}
              testID="paychexpay.accountsCategory.button.hintButton"
              accessibilityLabel="Hint button"
              accessible
              marginLeft={'5px'}
            >
              <InfoIcon
                width={17}
                height={17}
                viewBox={'0 0 21 20'}
                fill={customTheme.colors.primary.normal}
              />
            </Pressable>
          )}
        </View>
        {!isSelect && category.accounts.length !== 0 && (
          <Text variant="title">{amountToString(category.amount || 0)}</Text>
        )}
      </XStack>
      {category.accounts.length > 0 ? (
        <YStack
          gap={isPhone || !isSelect ? '$4' : '$2'}
          pt={isPhone || !isSelect ? '$5' : '$3'}
        >
          {category.accounts.map((account) => {
            return (
              <TPressable
                disabled={account.isDisabled}
                zIndex={1}
                onPress={() => {
                  if (!isArrowPressed) {
                    isSelect
                      ? selectAccount && selectAccount(account.id)
                      : navigateToAccount && navigateToAccount(account.type);
                  }
                }}
                key={account.id}
                hoverStyle={
                  !isPhone && isSelect
                    ? {
                        bg: '$blue10',
                      }
                    : {}
                }
                borderRadius={8}
                p={isPhone || !isSelect ? 0 : '$2'}
                accessible
                accessibilityLabel={`Open ${account.name} account`}
              >
                <XStack opacity={account.isDisabled ? 0.5 : 1}>
                  <View>
                    {account.isExternal ? (
                      <ExternalAccountIcon />
                    ) : (
                      <PaychexPayAccountIcon />
                    )}
                  </View>
                  <XStack
                    justifyContent="space-between"
                    alignItems="center"
                    pl={'$4'}
                    flexGrow={1}
                  >
                    <View
                      accessible
                      accessibilityLabel={`${account.name}, ${
                        account.isExternal ? '' : amountToString(account.amount)
                      }`}
                      flex={1}
                    >
                      <Text variant="regular" pb={1}>
                        {account.name}{' '}
                        {account.isExternal && account.type === 'spending'
                          ? 'checking'
                          : account.type === 'saving'
                          ? 'savings'
                          : ''}
                      </Text>
                      {!account.isExternal && (
                        <Text>{amountToString(account.amount)}</Text>
                      )}
                    </View>
                    {account.isExternal && (
                      <TPressable
                        onPressIn={() => setIsArrowPressed(true)}
                        onPress={() => {
                          navigateToDeleteAccount &&
                            navigateToDeleteAccount(account);
                          setIsArrowPressed(false);
                        }}
                        position="absolute"
                        right={0}
                        hitSlop={accessibleHitSlop}
                        hoverStyle={
                          !isPhone && isSelect
                            ? {
                                bg: '$blue10',
                              }
                            : {}
                        }
                        ml={'$3'}
                        borderRadius={8}
                        p={isPhone || !isSelect ? 0 : 2}
                        zIndex={100}
                        accessible
                        accessibilityLabel={`Open the delete account modal ${account.name}`}
                        testID={`paychexpay.accountsCategory.button.deleteModal.${account.name}`}
                      >
                        <ExternalLink />
                      </TPressable>
                    )}
                  </XStack>
                </XStack>
              </TPressable>
            );
          })}
          {isSavingCategory && !isSelect && !isPaychexPaySavingAccountExists && (
            <View key="0000">
              <XStack paddingBottom={20}>
                <View>
                  <PaychexPayAccountIcon />
                </View>
                <View pl={4} flexShrink={1}>
                  <Text
                    variant="title"
                    pb={2}
                    accessible
                    accessibilityLabel={'Get 3% interest on savings'}
                  >
                    Get 3% interest on savings
                  </Text>
                  <Text
                    variant="subtitle"
                    accessible
                    accessibilityLabel={
                      'We know you have big plans for your money. Let us make it worthwhile.'
                    }
                  >
                    We know you have big plans for your money. Let us make it
                    worthwhile.
                  </Text>
                </View>
              </XStack>
              <Button
                variant="white"
                onPress={onPressOpenSaving}
                testID="paychexpay.accountsCategory.button.openPaychexPaySavingsButton"
                accessible
                accessibilityLabel={'Open Savings'}
              >
                Open Savings
              </Button>
            </View>
          )}
        </YStack>
      ) : (
        <Text fontSize={16} color={'black'} marginTop={24}>
          No accounts added
        </Text>
      )}
    </View>
  );
};

export default AccountsCategory;
