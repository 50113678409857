import { HeaderMobileTemplate, SideBarTemplate } from '@templates';
import { ProfileSettingContent } from '@organisms';
import NameIcon from '../../../assets/webDrawer/profile.svg';
import SsnIcon from '../../../assets/lockIcon.svg';
import CalendarIcon from '../../../assets/calendarIcon.svg';
import InfoIcon from '../../../assets/info.svg';
import { customTheme } from '../../../styles/customTheme';
import { Text } from '@atoms';
import { MobileWebHeader, SideModal } from '@molecules';
import { sideModalNames } from '@constants/sideModalNames';
import SupportScreenContent from '@organisms/SupportScreenContent/SupportScreenContent';
import SUPPORT_PAGES from '@constants/support';
import React from 'react';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { ICardholder } from '@store/features/cardholder/types';
import { Platform } from 'react-native';
import useIsTablet from '@hooks/useIsTablet';
import { BIOMETRY_TYPE } from 'react-native-keychain';
import { ScrollView, View } from 'tamagui';

interface ProfileSettingViewPropsDesktop {
  userProfileData: ICardholder;
  dateOfBirth: string;
  userSSN: string;
  navigateToEditPhone: () => void;
  navigateToEditEmail: () => void;
  navigateToEditAddress: () => void;
  toOpenSupport: () => void;
  isModalOpen: boolean;
  toCloseModal: () => void;
  modalName: string | undefined;
  toOpenModal: (name: string) => () => void;
}

interface ProfileSettingViewPropsMobile extends ProfileSettingViewPropsDesktop {
  navigateBack: () => void;
  isTurnedOnBiometric: null | boolean;
  onToggleBiometric: () => void;
  showBiometricSection: boolean;
  biometryType: '' | BIOMETRY_TYPE | null;
}

const mobile = ({
  navigateBack,
  userProfileData,
  dateOfBirth,
  userSSN,
  navigateToEditPhone,
  navigateToEditEmail,
  navigateToEditAddress,
  toOpenSupport,
  isTurnedOnBiometric,
  onToggleBiometric,
  showBiometricSection,
  biometryType,
}: ProfileSettingViewPropsMobile) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderMobileTemplate
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: 'Profile',
        }}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ paddingBottom: '$9' }}
        >
          <ProfileSettingContent
            userProfileData={userProfileData}
            dateOfBirth={dateOfBirth}
            userSSN={userSSN}
            icons={{
              nameIcon: <NameIcon fill={customTheme.colors.gray[30]} />,
              calendarIcon: <CalendarIcon fill={customTheme.colors.gray[30]} />,
              ssnIcon: <SsnIcon color={'#B2BAC5'} />,
              infoIcon: (
                <InfoIcon
                  fill={customTheme.colors.gray[40]}
                  height="24px"
                  width="24px"
                />
              ),
            }}
            navigateToEditPhone={navigateToEditPhone}
            navigateToEditEmail={navigateToEditEmail}
            navigateToEditAddress={navigateToEditAddress}
            toOpenSupport={toOpenSupport}
            isTurnedOnBiometric={isTurnedOnBiometric}
            onToggleBiometric={onToggleBiometric}
            showBiometricSection={showBiometricSection}
            biometryType={biometryType}
          />
        </ScrollView>
      </HeaderMobileTemplate>
    </>
  );
};

const desktop = ({
  userProfileData,
  dateOfBirth,
  userSSN,
  navigateToEditPhone,
  navigateToEditEmail,
  navigateToEditAddress,
  toOpenSupport,
  isModalOpen,
  toCloseModal,
  modalName,
}: ProfileSettingViewPropsDesktop) => {
  const isTablet = useIsTablet();

  return (
    <>
      <SideBarTemplate>
        <View paddingHorizontal={'$7'} pt={'$5'}>
          <Text variant="pageTitle" pb={'$7'}>
            Profile
          </Text>
          <View
            padding={20}
            borderRadius={12}
            width={isTablet ? '100%' : 364}
            bg="white"
          >
            <Text variant="title" pb={'$4'}>
              Profile
            </Text>
            <ProfileSettingContent
              userProfileData={userProfileData}
              dateOfBirth={dateOfBirth}
              userSSN={userSSN}
              icons={{
                nameIcon: <NameIcon fill={customTheme.colors.gray[30]} />,
                calendarIcon: (
                  <CalendarIcon fill={customTheme.colors.gray[30]} />
                ),
                ssnIcon: <SsnIcon color={customTheme.colors.gray[30]} />,
                infoIcon: (
                  <InfoIcon
                    fill={customTheme.colors.gray[40]}
                    height="24px"
                    width="24px"
                  />
                ),
              }}
              navigateToEditPhone={navigateToEditPhone}
              navigateToEditEmail={navigateToEditEmail}
              navigateToEditAddress={navigateToEditAddress}
              toOpenSupport={toOpenSupport}
            />
          </View>
        </View>
      </SideBarTemplate>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.customerService]: (
              <SupportScreenContent {...SUPPORT_PAGES.customerService} />
            ),
          }}
        />
      </SideModal>
    </>
  );
};

const ProfileSettingView = {
  mobile,
  desktop,
};

export default ProfileSettingView;
