import { useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import { store } from '@store';
import linking from '../navigation/linking';
import protectedScreens from '@store/features/navigationStates/protectedScreensMap';
// @ts-ignore
import { saveAccessToken } from '@utils/accessTokenHelper';
import routesToTitlesMap from '../navigation/routesToTitlesMap';
import getCurrentRouteFromNavState from '@utils/getCurrentRouteFromNavState';

const useHandleNavigation = () => {
  const [isReady, setIsReady] = useState(Platform.OS !== 'web');
  const [initialState, setInitialState] = useState();
  const [currentRouteName, setCurrentRouteName] = useState<string>();
  let initialUrl: string;

  useEffect(() => {
    const restoreState = async () => {
      try {
        if (Platform.OS === 'web') {
          initialUrl = (await Linking.getInitialURL()) || '';
          const prefix =
            linking.prefixes.find((prefix) => initialUrl.includes(prefix)) ||
            '';

          const initialLink = initialUrl.replace(prefix, '').replace(/^\//, '');

          if (
            protectedScreens.find(
              (protectedUrl) => protectedUrl === initialLink
            )
          ) {
            const savedStateString =
              store.getState().navigationStates.navigationHistory;
            if (savedStateString) {
              const state = JSON.parse(savedStateString);
              setInitialState(state);
            }
          }
        }
      } finally {
        setIsReady(true);
      }
    };

    if (!isReady) {
      restoreState();
    }
  }, [isReady]);

  const onStateChange = (state: any) => {
    if (Platform.OS === 'web') {
      const accessToken = localStorage.getItem('@secure.s.accessToken'); // to check if access token was removed from local storage manually
      if (!accessToken) {
        saveAccessToken('');
        store.dispatch(navigationStatesActions.setIsAuthenticated(false));
      }
    }
    if (initialUrl === store.getState().navigationStates.lastScreenUrl) return;
    store.dispatch(
      navigationStatesActions.setNavigationHistory(JSON.stringify(state))
    );

    if (state !== undefined) {
      const currentRoute = getCurrentRouteFromNavState(state);
      setCurrentRouteName(currentRoute);
      store.dispatch(
        navigationStatesActions.setCurrentRouteTitle(
          // @ts-ignore
          routesToTitlesMap[currentRoute || 'unknown']
        )
      );
    }
  };

  return {
    isReady,
    initialState,
    onStateChange,
    currentRouteName,
  };
};

export default useHandleNavigation;
