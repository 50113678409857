import {
  RadioGroup,
  ScrollView,
  Separator,
  View,
  XStack,
  YStack,
} from 'tamagui';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { BackButton, TButton, Text } from '@atoms';
import AccountCard from '@molecules/AccountCard/AccountCard';
import amountToString from '@utils/amountToString';
import {
  InsufficientFundsAlertModal,
  InsufficientFundsAlertModalProps,
  MobileWebHeader,
  TextInput,
} from '@molecules';
import { IAccountPreview } from '@devTypes/accountTypes';
import { customTheme } from '../../../styles/customTheme';
import AccountIcon from '../../../assets/accounts/paychexpayAccountIcon.svg';
import { Platform } from 'react-native';
import { Box, Center } from 'native-base';
import {
  IBeneficiaryInput,
  P2P_TRANSFER_OPTIONS,
} from '@constants/transfersScreensNames';
import RadioGroupItemWithLabel from '@molecules/RadioButtonWithLabel/RadioButtonWithLabel';
import React from 'react';
import { IVariable } from '@store/features/general/types';

interface ToOtherFriendsViewProps extends InsufficientFundsAlertModalProps {
  navigateBack: () => void;
  navigateToAmount: () => void;
  onChangeBeneficiaryInfoFn: (text: string) => void;
  onChangeBeneficiaryInfoLn: (text: string) => void;
  onChangeBeneficiaryInfoPhone: (text: string) => void;
  onChangeBenInfoEmail: (text: string) => void;
  onRadioButtonSelect: (option: string) => void;
  account: IAccountPreview;
  benInfo: IBeneficiaryInput;
  isButtonDisabled: boolean;
  selectedMethod: string;
  isErrorInputLn: boolean;
  isErrorInputFn: boolean;
  isErrorInputEmail: boolean;
  isErrorInputPhone: boolean;
  variableTransferFee: IVariable | undefined;
}

interface ToOtherFriendsViewHeaderProps
  extends InsufficientFundsAlertModalProps {
  children: React.ReactNode;
  navigateBack: () => void;
  navigateToAmount: () => void;
  isTransferDisabled: boolean;
}

const ToOtherFriendsHeader = ({
  children,
  navigateBack,
  navigateToAmount,
  isTransferDisabled,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
}: ToOtherFriendsViewHeaderProps) =>
  Platform.OS === 'web' ? (
    <>
      <MobileWebHeader />
      <Box bgColor="white" safeAreaBottom flexGrow={1}>
        <XStack px={'$4'} py={'$4'}>
          <BackButton onPress={navigateBack} fill={customTheme.colors.black} />
          <Center flexGrow={1} mr="24px">
            <Text variant="title" textAlign="center">
              Transfer by phone/email
            </Text>
          </Center>
          <View />
        </XStack>
        <Separator borderColor="$gray20" />
        <View flexGrow={1}>
          <View flex={1}>{children}</View>
        </View>
      </Box>

      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  ) : (
    <>
      <HeaderAndFooterMobileTemplate
        footerContent={
          <TButton
            onPress={navigateToAmount}
            disabled={isTransferDisabled}
            testID="paychexpay.ToOtherFriendsView.button.continueButton"
          >
            Continue
          </TButton>
        }
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: 'Transfer by phone/email',
        }}
      >
        {children}
      </HeaderAndFooterMobileTemplate>
      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  );

const ToOtherFriendsView = ({
  navigateBack,
  navigateToAmount,
  benInfo: { benEmail, benLastName, benFirstName, benPhone },
  onChangeBeneficiaryInfoFn,
  onChangeBeneficiaryInfoLn,
  onChangeBeneficiaryInfoPhone,
  onChangeBenInfoEmail,
  onRadioButtonSelect,
  account,
  isButtonDisabled,
  selectedMethod,
  isErrorInputLn,
  isErrorInputFn,
  isErrorInputEmail,
  isErrorInputPhone,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
  variableTransferFee,
}: ToOtherFriendsViewProps) => {
  const isTransferDisabled =
    isErrorInputLn || isErrorInputFn || isButtonDisabled;
  return (
    <ToOtherFriendsHeader
      navigateBack={navigateBack}
      navigateToAmount={navigateToAmount}
      isTransferDisabled={isTransferDisabled}
      isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
      hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        h={Platform.OS === 'web' ? 0 : '100%'}
        contentContainerStyle={{ flexGrow: 1, padding: 16, display: 'flex' }}
      >
        <YStack flexGrow={1} pb={'$4'} justifyContent="space-between">
          <View pb={'$6'} flexGrow={1}>
            <AccountCard
              icon={<AccountIcon />}
              title={account.name}
              label="From"
              amount={amountToString(account.amount)}
            />
            <Text color="$gray40" py={'$4'} variant="subtitle">
              To
            </Text>
            <XStack gap={16}>
              <TextInput
                styles={{ flex: 1 }}
                label="First name"
                args={{
                  value: benFirstName,
                  onChangeText: onChangeBeneficiaryInfoFn,
                }}
                errorMessage="Include at least 2 characters"
                isError={isErrorInputFn}
              />
              <TextInput
                styles={{ flex: 1 }}
                label="Last name"
                args={{
                  value: benLastName,
                  onChangeText: onChangeBeneficiaryInfoLn,
                }}
                errorMessage="Include at least 2 characters"
                isError={isErrorInputLn}
              />
            </XStack>
            <Text color="$gray40" pt={'$4'} variant="subtitle">
              Choose method
            </Text>
            <XStack gap={16} my={'$4'}>
              <RadioGroup
                onValueChange={(value) => {
                  onRadioButtonSelect(value);
                }}
                defaultValue="Phone number"
                accessibilityLabel="options"
                name="form"
              >
                <YStack gap={'$6'} alignItems="flex-start">
                  <RadioGroupItemWithLabel
                    size="$7"
                    label={P2P_TRANSFER_OPTIONS[0].label}
                    value={P2P_TRANSFER_OPTIONS[0].value}
                    testID={
                      'paychexpay.mobile.ToOtherFriendsView.radioButton.email'
                    }
                  />
                  <RadioGroupItemWithLabel
                    size="$7"
                    label={P2P_TRANSFER_OPTIONS[1].label}
                    value={P2P_TRANSFER_OPTIONS[1].value}
                    testID={
                      'paychexpay.mobile.ToOtherFriendsView.radioButton.phoneNumber'
                    }
                  />
                </YStack>
              </RadioGroup>
            </XStack>
            <YStack mt="$2">
              {selectedMethod === 'Email address' ? (
                <TextInput
                  styles={{ flex: 1 }}
                  placeholder="Email"
                  args={{
                    value: benEmail,
                    onChangeText: onChangeBenInfoEmail,
                  }}
                  errorMessage="Use this format: example@gmail.com"
                  isError={isErrorInputEmail}
                />
              ) : (
                <TextInput
                  styles={{ flex: 1 }}
                  placeholder="U.S. mobile phone number"
                  args={{
                    value: benPhone,
                    onChangeText: onChangeBeneficiaryInfoPhone,
                    maxLength: 14,
                  }}
                  errorMessage="Use this format: X XXX-XXX-XXXX"
                  isError={isErrorInputPhone}
                />
              )}
            </YStack>
          </View>
          <YStack>
            <Text
              flex={1}
              textAlign="center"
              color="$gray40"
              variant="subtitle"
            >
              A ${variableTransferFee?.value ?? ''} fee applies. {'\n'}{' '}
              Transaction approvals depend on the applicable payment network and
              receiving financial network. Actual posting times depend on the
              receiving financial institution.
            </Text>
          </YStack>
          {Platform.OS === 'web' && (
            <View
              borderTopColor="$gray20"
              borderTopWidth={1}
              backgroundColor="white"
              px={'$4'}
              pt={'$4'}
            >
              <TButton
                onPress={navigateToAmount}
                disabled={isTransferDisabled}
                testID="paychexpay.ToOtherFriendsView.button.continueButton"
              >
                Continue
              </TButton>
            </View>
          )}
        </YStack>
      </ScrollView>
    </ToOtherFriendsHeader>
  );
};

export default ToOtherFriendsView;
