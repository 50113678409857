import { HeaderAndFooterMobileTemplate } from '@templates';
import { SendFeedbackContent } from '@organisms';
import { Button } from '@atoms';
import { Platform } from 'react-native';
import { MobileWebHeader } from '@molecules';
import { View } from 'tamagui';

interface SendFeedbackViewProps {
  onSendFeedback: () => void;
  navigateBack: () => void;
}

const SendFeedbackView = ({
  navigateBack,
  onSendFeedback,
}: SendFeedbackViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderAndFooterMobileTemplate
        header={{
          title: 'Give feedback',
          isBackButton: true,
          onPressBack: navigateBack,
        }}
        footerContent={<Button onPress={onSendFeedback}>Give feedback</Button>}
      >
        <View px={4}>
          <SendFeedbackContent />
        </View>
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

export default SendFeedbackView;
