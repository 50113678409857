/* eslint-disable no-constant-condition */
import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import {
  AccountsWidget,
  AccountsWidget as AccountsWidgetDesktop,
  DdaFormContent,
  HomeDesktopLayout,
  OnboardingSlide,
  OnboardingSwiper,
  SkipIntroContent,
  StayInLoopNotificationSettings,
  TileCarousel,
  TransactionDetailsContent,
  TransactionsHistoryWidget,
  TransactionsHistoryWidget as TransactionsHistoryWidgetDesktop,
} from '@organisms';
import {
  AddSavingsWidget,
  AddSavingsWidget as AddSavingsWidgetDesktop,
  CenterModal,
  CTAsButtons,
  CTAsButtons as CTAsButtonsDesktop,
  FooterButtonsRow,
  HomePageSkeleton,
  PhysicalCardWidget,
  PhysicalCardWidget as PhysicalCardWidgetDesktop,
  SideModal,
  Tile as TileDesktop,
} from '@molecules';
import {
  HomepageLayout,
  SideBarTemplate,
  SwiperDesktopWrapper,
} from '@templates';
import ONBOARDING_SLIDES from '@constants/onboardingSlides';
import TILES from '@constants/tiles';
import HelpIcon from '../../../assets/helpIconWhite.svg';
import EarnRewardsTileIcon from '../../../assets/tiles/cashback.svg';
import PayBillsTileIcon from '../../../assets/tiles/payBills.svg';
import { FocusAwareStatusBar, TButton, Text } from '@atoms';
import { useNavigation } from '@react-navigation/native';
import { HomeNavigatorParamsProps } from '@navigation/HomeNavigator/HomeNavigator.types';
import { RootNavigatorParamProps } from '@navigation/RootNavigator/RootNavigator.types';
import { navigateToAccountType } from '@devTypes/accounts';
import idToTransaction from '@utils/idToTransaction';
import SetupDdaContent from '@organisms/SetupDdaContent/SetupDdaContent';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import { IAccountPreview } from '@devTypes/accountTypes';
import { ITransaction } from '@store/features/transactions/types';
import { useSelector } from 'react-redux';
import {
  rewardsWidget,
  selectDdaEligibilityLoading,
} from '@store/features/homeWidgets/selectors';
import ToastPopup from '@atoms/ToastPopup/ToastPopup.web';
import { maxMediaQuery } from '@constants/ui';
import useIsPhone from '@hooks/useIsPhone';
import useHomePageSkeleton from '@hooks/useHomePageSkeleton';
import { PayBillsButtonTypePressedType } from '@devTypes/home';
import SupportScreenContent from '@organisms/SupportScreenContent/SupportScreenContent';
import SUPPORT_PAGES from '@constants/support';
import ConfirmationModal from '@organisms/ConfirmationModal/ConfirmationModal';
import { ScrollView, View, XStack, YStack } from 'tamagui';
import { selectShowBillPayPrompt } from '@store/features/cardholder/selectors';
import { signal } from '../../../eventCollector';

interface HomeDesktopViewProps {
  isOnboardingModalOpen: boolean;
  isNotificationsModalOpen: boolean;
  isSkipIntroModalOpen: boolean;
  onAllowPress: () => void;
  notificationsSettings: {
    mobilePushPaychexPay: boolean;
    text: boolean;
    email: boolean;
  };
  mobilePushPaychexPayChange: (f: boolean) => void;
  textChange: (f: boolean) => void;
  emailChange: (f: boolean) => void;
  navigateToAccount: navigateToAccountType;
  onPressOpenSavingsAccount: () => void;
  onPressSkip: () => void;
  onPressNext: () => void;
  onPressPrev: () => void;
  onPressTransfer: () => void;
  index: number;
  setIndex: (index: number) => void;
  onCloseSkipIntroModal: () => void;
  onPressShowTransactions: () => void;
  onOpenTransactionModal: (id: string) => void;
  transactionId: string | undefined;
  navigateToDda: () => void;
  isModalOpen: boolean;
  toCloseModal: () => void;
  navigateToDebitCard: () => void;
  modalName: string | undefined;
  toOpenModal: (name: string) => () => void;
  onPressHelp: () => void;
  transactions: ITransaction[];
  onOpenSavings: () => void;
  onHideSavingsWidget: () => void;
  isActiveCardToastPopupOpen: boolean;
  isUnfrozenCardToastPopupOpen: boolean;
  onHidePayBillsWidget: () => void;
  onRewardsButtonPress: () => void;
  isRedirectToRewardsLoading: boolean;
  onPayBillsButtonPress: ({
    buttonType,
  }: {
    buttonType: PayBillsButtonTypePressedType;
  }) => void;
  isPayBillButtonDisabled: boolean;
  isRedirectToPayBillsLoading: boolean;
  redirectToPayBillsButtonTypePressed: PayBillsButtonTypePressedType;
  isSavingsPending: boolean;
  onPressCloseOnboardingDDA: () => void;
  isOnboardingDDAModalOpen: boolean;
  isTextConfirmationModalOpen: boolean;
  setIsConfirmationModalOpen?: (flag: boolean) => void;
  confirmHandler?: () => void;
}

interface HomeMobileViewProps {
  navigateToAccount: navigateToAccountType;
  onPressHelp: () => void;
  onPressOpenSavingsAccount: () => void;
  onPressTransfer: () => void;
  onPressShowTransactions: () => void;
  isActiveCardToastPopupOpen: boolean;
  isUnfrozenCardToastPopupOpen: boolean;
  navigateToDda: () => void;
  accountsPreviews: IAccountPreview[];
  transactions: ITransaction[] | null;
  onOpenSavings: () => void;
  onHideSavingsWidget: () => void;
  onRewardsButtonPress: () => void;
  isRedirectToRewardsLoading: boolean;
  navigateToDebitCard: () => void;
  onPayBillsButtonPress: ({
    buttonType,
  }: {
    buttonType: PayBillsButtonTypePressedType;
  }) => void;
  isPayBillButtonDisabled: boolean;
  isRedirectToPayBillsLoading: boolean;
  redirectToPayBillsButtonTypePressed: PayBillsButtonTypePressedType;
}

export const COLUMN_WIDTH = 364;

export const WidgetWrapper = ({ children }: { children: ReactNode }) => {
  const isPhone = useIsPhone();
  return (
    <View
      width={isPhone ? '100%' : COLUMN_WIDTH}
      pb={isPhone ? '$5' : 20}
      marginRight={isPhone ? 0 : 20}
    >
      {children}
    </View>
  );
};

interface ColumnProps {
  children: React.ReactNode;
}

const Column = ({ children }: ColumnProps) => (
  <>
    {React.Children.map(
      children,
      (child, index) =>
        child && (
          <View key={index} style={{ width: 364 }} mb="20px">
            {child}
          </View>
        )
    )}
  </>
);

const desktop = ({
  isOnboardingModalOpen,
  isNotificationsModalOpen,
  onAllowPress,
  textChange,
  emailChange,
  mobilePushPaychexPayChange,
  notificationsSettings,
  onPressSkip,
  onPressNext,
  onPressPrev,
  onPressTransfer,
  onPressOpenSavingsAccount,
  index,
  setIndex,
  isSkipIntroModalOpen,
  onCloseSkipIntroModal,
  navigateToAccount,
  onPressShowTransactions,
  onOpenTransactionModal,
  transactionId,
  navigateToDda,
  isModalOpen,
  toCloseModal,
  modalName,
  toOpenModal,
  onPressHelp,
  transactions,
  onOpenSavings,
  onHideSavingsWidget,
  isActiveCardToastPopupOpen,
  isUnfrozenCardToastPopupOpen,
  onHidePayBillsWidget,
  onRewardsButtonPress,
  isRedirectToRewardsLoading,
  onPayBillsButtonPress,
  isPayBillButtonDisabled,
  isRedirectToPayBillsLoading,
  redirectToPayBillsButtonTypePressed,
  navigateToDebitCard,
  onPressCloseOnboardingDDA,
  isOnboardingDDAModalOpen,
  isTextConfirmationModalOpen = false,
  setIsConfirmationModalOpen = () => false,
  confirmHandler = () => false,
}: HomeDesktopViewProps) => {
  const showBillPayPrompt = useSelector(selectShowBillPayPrompt);
  const isRewardsWidgetVisible = useSelector(rewardsWidget);
  const ddaEligibilityLoading = useSelector(selectDdaEligibilityLoading);

  const { isLoadingHomePage } = useHomePageSkeleton();
  const { width: desktopWidth } = useWindowDimensions();

  useEffect(() => {
    if (!isLoadingHomePage) {
      setTimeout(() => {
        // wait to send first log (set of logs) after login more so that we can batch up more logs (10s)
        signal.set();
        // send subsequent logs every 5s
        setInterval(() => {
          signal.set();
        }, 5000);
      }, 10000);
    }
  }, [isLoadingHomePage]);

  return (
    <>
      <ToastPopup
        isOpen={isActiveCardToastPopupOpen}
        text={'Activated! Your card is ready to use.'}
      />
      <ToastPopup
        isOpen={isUnfrozenCardToastPopupOpen}
        text={'Unfrozen! Your card is ready to use again.'}
      />
      <SideBarTemplate isModalOpen={false}>
        {isLoadingHomePage ? (
          <HomePageSkeleton />
        ) : (
          <ScrollView
            width="100%"
            height="100vh"
            paddingVertical={24}
            paddingLeft={40}
          >
            <XStack pr={40} pb={40} justifyContent="space-between">
              <Text variant="pageTitle">Overview</Text>
              <XStack
                onPress={onPressHelp}
                alignItems="center"
                cursor="pointer"
              >
                <Text color="$gray40" px="13px">
                  Help
                </Text>
                <HelpIcon height="20px" width="20px" fill="#808797" />
              </XStack>
            </XStack>
            <XStack flexGrow={1}>
              <HomeDesktopLayout>
                <Column>
                  <AccountsWidgetDesktop
                    isDesktop
                    isLoading={false}
                    onPressShow={onPressOpenSavingsAccount}
                    navigateToAccount={navigateToAccount}
                  />
                  <CTAsButtonsDesktop
                    transferHandler={onPressTransfer}
                    billPayHandler={() =>
                      onPayBillsButtonPress({ buttonType: 'CTA' })
                    }
                    isPayBillButtonDisabled={isPayBillButtonDisabled}
                    isRedirectToPayBillsLoading={
                      redirectToPayBillsButtonTypePressed === 'CTA' &&
                      isRedirectToPayBillsLoading
                    }
                  />
                  {transactions.length > 0 && (
                    <TransactionsHistoryWidgetDesktop
                      transactions={transactions}
                      isDesktop
                      isLoading={false}
                      onPressShow={onPressShowTransactions}
                      onPressTransaction={onOpenTransactionModal}
                    />
                  )}
                </Column>

                <PhysicalCardWidgetDesktop
                  isLoading={false}
                  navigateToDda={navigateToDda}
                  navigateToDebitCard={navigateToDebitCard}
                  ddaEligibilityLoading={ddaEligibilityLoading}
                  toOpenModal={toOpenModal}
                />

                <AddSavingsWidgetDesktop
                  isLoading={false}
                  onHideSavingsWidget={onHideSavingsWidget}
                  onOpenSavings={onOpenSavings}
                />

                {isRewardsWidgetVisible && (
                  <WidgetWrapper>
                    <TileDesktop
                      {...TILES.earnRewards}
                      icon={<EarnRewardsTileIcon />}
                      leftButtonHandler={onRewardsButtonPress}
                      firstTestID="paychexpay.homeView.earnRewardsTile.hideThisButton"
                      secondTestID="paychexpay.homeView.earnRewardsTile.startEarningButton"
                      isLeftButtonLoading={isRedirectToRewardsLoading}
                      isLoading={false}
                      variant={'simpleButton'}
                    />
                  </WidgetWrapper>
                )}
                {(showBillPayPrompt || showBillPayPrompt === null) && (
                  <WidgetWrapper>
                    <TileDesktop
                      {...TILES.payBills}
                      icon={<PayBillsTileIcon />}
                      rightButtonHandler={() =>
                        onPayBillsButtonPress({ buttonType: 'widget' })
                      }
                      leftButtonHandler={onHidePayBillsWidget}
                      firstTestID="paychexpay.homeView.payBillsTile.hideThisButton"
                      secondTestID="paychexpay.homeView.payBillsTile.startPayingBillsButton"
                      isDisabled={isPayBillButtonDisabled}
                      isRightButtonLoading={
                        redirectToPayBillsButtonTypePressed === 'widget' &&
                        isRedirectToPayBillsLoading
                      }
                      isLoading={false}
                    />
                  </WidgetWrapper>
                )}
              </HomeDesktopLayout>
            </XStack>
          </ScrollView>
        )}
      </SideBarTemplate>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.aboutPaidEarly]: (
              <View px={80} pt="$8">
                <Text
                  variant="subtitle"
                  testID={'paychexpay.homeView.onboarding.getPaidEarly.help'}
                  fontSize={12}
                >
                  {ONBOARDING_SLIDES.getPaidEarly.help}
                </Text>
              </View>
            ),
            [sideModalNames.transaction]: (
              <View bg="$blue10" height="100vh">
                <View w="100%" h={76} bg="$primaryNormal" mb={-28} />
                <TransactionDetailsContent
                  transaction={idToTransaction(transactionId, transactions)}
                  isDesktop
                />
              </View>
            ),
            [sideModalNames.setupDda]: (
              <View height="100vh">
                <SetupDdaContent />
                <DdaFormContent.desktop
                  onPressBack={toOpenModal(sideModalNames.setupDda)}
                />
              </View>
            ),
            [sideModalNames.blockedCard]: (
              <SupportScreenContent {...SUPPORT_PAGES.blockedCard} />
            ),
          }}
        />
      </SideModal>

      <CenterModal
        isModalOpen={isOnboardingModalOpen || isSkipIntroModalOpen}
        closeModal={onPressSkip}
        // closeByOutsideClick // breaking next button behaviour
        maxWidth={
          isOnboardingModalOpen
            ? desktopWidth <= maxMediaQuery.laptop
              ? 596
              : 670
            : 343
        }
      >
        {isOnboardingModalOpen ? (
          <View
            bg="$white"
            borderRadius="$2"
            w={670}
            h={index === 0 ? 750 : 775}
            {...(desktopWidth <= maxMediaQuery.laptop && {
              w: 596,
              h: index === 0 ? 596 : 670,
            })}
          >
            <SwiperDesktopWrapper
              index={index}
              onPressNext={onPressNext}
              onPressPrev={onPressPrev}
              onPressSkip={onPressSkip}
            >
              <OnboardingSwiper index={index} setIndex={setIndex} />
            </SwiperDesktopWrapper>
          </View>
        ) : (
          <View bg="$white" borderRadius="$2" w={343} overflow="hidden">
            <SkipIntroContent onCloseSkipIntroModal={onCloseSkipIntroModal} />
          </View>
        )}
      </CenterModal>

      <CenterModal
        isModalOpen={isOnboardingDDAModalOpen}
        closeModal={onPressCloseOnboardingDDA}
        closeByOutsideClick
        maxWidth={
          isOnboardingDDAModalOpen
            ? desktopWidth <= maxMediaQuery.laptop
              ? 596
              : 670
            : 343
        }
      >
        <View
          bg="$white"
          borderRadius="$2"
          w={670}
          h={670}
          display={'flex'}
          justifyContent={'center'}
          {...(desktopWidth <= maxMediaQuery.laptop && {
            w: 596,
            h: 596,
          })}
        >
          <View alignSelf={'center'}>
            <OnboardingSlide {...ONBOARDING_SLIDES.dda} index={4} />
            <XStack
              width={'100%'}
              justifyContent="center"
              paddingBottom="48px"
              paddingTop="48px"
            >
              <View width={325}>
                <FooterButtonsRow
                  firstButton={{
                    label: 'Not now',
                    handler: onPressCloseOnboardingDDA,
                    testID:
                      'paychexpay.ddaOnboardingDesktopWrapper.footerButtonsRow.button.notNowButton',
                  }}
                  secondButton={{
                    label: 'Get started',
                    handler: navigateToDda,
                    testID:
                      'paychexpay.ddaOnboardingDesktopWrapper.footerButtonsRow.button.getStartedButton',
                  }}
                />
              </View>
            </XStack>
          </View>
        </View>
      </CenterModal>

      <CenterModal
        isModalOpen={isNotificationsModalOpen}
        closeModal={onPressSkip}
        closeByOutsideClick
        maxWidth={
          isNotificationsModalOpen
            ? desktopWidth <= maxMediaQuery.laptop
              ? 596
              : 670
            : 343
        }
      >
        <View
          bg="$white"
          borderRadius="$2"
          alignContent="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
          width={670}
          height={670}
          {...(desktopWidth <= maxMediaQuery.laptop && {
            width: 596,
            height: index === 0 ? 596 : 670,
          })}
        >
          <View
            alignSelf={'center'}
            width={325}
            display="flex"
            flex={1}
            flexDirection="column"
            paddingVertical="48px"
          >
            <StayInLoopNotificationSettings
              textChange={textChange}
              mobilePushPaychexPayChange={mobilePushPaychexPayChange}
              emailChange={emailChange}
              notificationsSettings={notificationsSettings}
            />
            <TButton
              onPress={onAllowPress}
              testID="paychexpay.HomeView.button.done"
            >
              Done
            </TButton>
          </View>
          <View justifyContent="flex-end">
            <View
              backgroundColor="$blue10"
              paddingVertical="$3"
              borderBottomRightRadius="$2"
              borderBottomLeftRadius="$2"
            >
              <Text textAlign="center" fontSize={14}>
                We’ll always send you updates on things like password resets or
                negative balances.
              </Text>
            </View>
          </View>
        </View>
        <ConfirmationModal
          variant="textStatement"
          isModalOpen={isTextConfirmationModalOpen}
          setIsConfirmationModalOpen={setIsConfirmationModalOpen}
          confirmHandler={confirmHandler}
        />
      </CenterModal>
    </>
  );
};

const mobile = ({
  onPressHelp,
  onPressOpenSavingsAccount,
  onPressTransfer,
  navigateToAccount,
  onPressShowTransactions,
  navigateToDda,
  transactions,
  onOpenSavings,
  onHideSavingsWidget,
  onRewardsButtonPress,
  isRedirectToRewardsLoading,
  onPayBillsButtonPress,
  isPayBillButtonDisabled,
  isRedirectToPayBillsLoading,
  redirectToPayBillsButtonTypePressed,
  navigateToDebitCard,
  isActiveCardToastPopupOpen,
  isUnfrozenCardToastPopupOpen,
}: HomeMobileViewProps) => {
  const navigation = useNavigation<
    HomeNavigatorParamsProps['navigation'] &
      RootNavigatorParamProps['navigation']
  >();

  const ddaEligibilityLoading = useSelector(selectDdaEligibilityLoading);

  const onPressTransaction = (id: string) => {
    navigation.navigate('HomeNavigator', {
      screen: 'TransactionDetails',
      params: { transactionId: id },
    });
  };
  const [isHere, setIsHere] = useState(false);
  useEffect(() => {
    setIsHere(true);
  }, []);

  const { isLoadingHomePage } = useHomePageSkeleton();

  useEffect(() => {
    if (!isLoadingHomePage) {
      setTimeout(() => {
        // wait to send first log (set of logs) after login more so that we can batch up more logs (10s)
        signal.set();
        // send subsequent logs every 5s
        setInterval(() => {
          signal.set();
        }, 5000);
      }, 10000);
    }
  }, [isLoadingHomePage]);

  return (
    <HomepageLayout
      onPressHelp={onPressHelp}
      isUnfrozenCardToastPopupOpen={isUnfrozenCardToastPopupOpen}
      isActiveCardToastPopupOpen={isActiveCardToastPopupOpen}
    >
      {isLoadingHomePage ? (
        <HomePageSkeleton />
      ) : (
        <>
          <YStack>
            <View pb="$5">
              <Suspense
                fallback={<View h={172} bg="$blue20" borderRadius="$2" />}
              >
                <AccountsWidget
                  isLoading={false}
                  onPressShow={onPressOpenSavingsAccount}
                  navigateToAccount={navigateToAccount}
                />
              </Suspense>
            </View>
            <View pb="$5">
              <Suspense
                fallback={<View h={48} bg="$blue20" borderRadius="$2" />}
              >
                <CTAsButtons
                  transferHandler={onPressTransfer}
                  billPayHandler={() =>
                    onPayBillsButtonPress({ buttonType: 'CTA' })
                  }
                  isPayBillButtonDisabled={isPayBillButtonDisabled}
                  isRedirectToPayBillsLoading={
                    redirectToPayBillsButtonTypePressed === 'CTA' &&
                    isRedirectToPayBillsLoading
                  }
                />
              </Suspense>
            </View>

            <Suspense
              fallback={<View h={182} bg="$blue20" borderRadius="$2" />}
            >
              <PhysicalCardWidget
                isLoading={false}
                navigateToDda={navigateToDda}
                navigateToDebitCard={navigateToDebitCard}
                ddaEligibilityLoading={ddaEligibilityLoading}
              />
            </Suspense>
          </YStack>
          {isHere && (
            <YStack pb="$4">
              <View pb="$5">
                <Suspense
                  fallback={<View h={182} bg="$blue20" borderRadius="$2" />}
                >
                  <AddSavingsWidget
                    isLoading={false}
                    onHideSavingsWidget={onHideSavingsWidget}
                    onOpenSavings={onOpenSavings}
                  />
                </Suspense>
              </View>
              <Suspense
                fallback={<View h={448} bg="$blue20" borderRadius="$2" />}
              >
                <TransactionsHistoryWidget
                  isLoading={false}
                  transactions={transactions}
                  onPressTransaction={onPressTransaction}
                  onPressShow={onPressShowTransactions}
                />
              </Suspense>
              <Suspense
                fallback={<View h={212} bg="$blue20" borderRadius="$2" />}
              >
                <View mx={-16}>
                  <TileCarousel
                    onPayBillsButtonPress={() =>
                      onPayBillsButtonPress({ buttonType: 'widget' })
                    }
                    isPayBillButtonDisabled={isPayBillButtonDisabled}
                    isRedirectToPayBillsLoading={
                      redirectToPayBillsButtonTypePressed === 'widget' &&
                      isRedirectToPayBillsLoading
                    }
                    onRewardsButtonPress={onRewardsButtonPress}
                    isRedirectToRewardsLoading={isRedirectToRewardsLoading}
                  />
                </View>
              </Suspense>
            </YStack>
          )}
        </>
      )}
      <FocusAwareStatusBar barStyle="light-content" />
    </HomepageLayout>
  );
};

const HomeView = {
  desktop,
  mobile,
};

export default HomeView;
