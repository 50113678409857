import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import cardsThunks from '@store/features/cards/asyncThunks';
import {
  ICardsState,
  requestStatusType,
  unfreezeParams,
} from '@store/features/cards/types';
import { CARD_STATES } from '@constants/cardStates';

const initialState = {
  spendingAccount: {
    info: {},
    authInfo: {},
    isAnimatedBlockCard: false,
    requestStatus: null,
  },
  savingAccount: {
    info: {},
    authInfo: {},
    requestStatus: null,
  },
  cardStatusRequestStatus: 'success',
  activeCardRequestStatus: null,
  orderCardRequestStatue: null,
  changePinUrl: null,
  changePinIsLoading: false,
  cardImageSrc: '',
  cardUnfreezeCalledFrom: null,
} as ICardsState;

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    setCardStatusRequestStatus(
      state,
      action: PayloadAction<requestStatusType>
    ) {
      state.cardStatusRequestStatus = action.payload;
    },
    blockCardAnimated(state) {
      state.spendingAccount.isAnimatedBlockCard = true;
    },
    toSetCardUnfreezeCalledFrom(state, action: PayloadAction<unfreezeParams>) {
      state.cardUnfreezeCalledFrom = action.payload;
    },
    resetChangePinUrl(state) {
      state.changePinUrl = null;
    },
    resetOrderCardRequestStatus(state) {
      state.orderCardRequestStatue = null;
    },
    resetActiveCardRequestStatus(state) {
      state.activeCardRequestStatus = null;
    },
    setRequestSpendingRequestStatus(
      state,
      action: PayloadAction<requestStatusType | null>
    ) {
      state.spendingAccount.requestStatus = action.payload;
    },
    setRequestSavingsRequestStatus(
      state,
      action: PayloadAction<requestStatusType | null>
    ) {
      state.savingAccount.requestStatus = action.payload;
    },
    resetState() {
      return initialState;
    },
    setCardImage(state, action: PayloadAction<string>) {
      state.cardImageSrc = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cardsThunks.getCardInfo.fulfilled, (state, { payload }) => {
      if (payload.program_id === '{{bank.paychexpay.spending.program.id}}') {
        state.spendingAccount.info = payload;
      }
      if (payload.program_id === '{{bank.paychexpay.savings.program.id}}') {
        state.savingAccount.info = payload;
      }
    });
    builder.addCase(
      cardsThunks.getCardAuthInfo.fulfilled,
      (state, { payload }) => {
        if (state.spendingAccount.info.card_id === payload.card_id) {
          state.spendingAccount.authInfo = payload;
        }
        if (state.savingAccount.info.card_id === payload.card_id) {
          state.savingAccount.authInfo = payload;
        }
      }
    );
    builder.addCase(
      cardsThunks.putCardStatus.fulfilled,
      (state, { payload }) => {
        if (state.spendingAccount.info.card_id === payload.card_id) {
          if (
            state.spendingAccount.info.status !== CARD_STATES.blocked &&
            payload.status === CARD_STATES.blocked
          ) {
            state.spendingAccount.isAnimatedBlockCard = false;
          }
          state.spendingAccount.info = payload;
          state.cardStatusRequestStatus = 'success';
        }
      }
    );
    builder.addCase(cardsThunks.putCardStatus.rejected, (state) => {
      state.cardStatusRequestStatus = 'error';
    });
    builder.addCase(cardsThunks.putCardStatus.pending, (state) => {
      state.cardStatusRequestStatus = 'pending';
    });
    builder.addCase(
      cardsThunks.createSavingAccount.fulfilled,
      (state, { payload }) => {
        state.savingAccount.info = payload;
      }
    );
    builder.addCase(
      cardsThunks.putActivePhysicalCard.fulfilled,
      (state, { payload }) => {
        state.spendingAccount.info = payload;
        state.activeCardRequestStatus = 'success';
      }
    );
    builder.addCase(cardsThunks.putActivePhysicalCard.pending, (state) => {
      state.activeCardRequestStatus = 'pending';
    });
    builder.addCase(cardsThunks.putActivePhysicalCard.rejected, (state) => {
      state.activeCardRequestStatus = 'error';
    });
    builder.addCase(cardsThunks.changePin.pending, (state) => {
      state.changePinIsLoading = true;
    });
    builder.addCase(cardsThunks.changePin.fulfilled, (state, { payload }) => {
      state.changePinUrl = payload.data.secure_url;
      state.changePinIsLoading = false;
    });
    builder.addCase(cardsThunks.changePin.rejected, (state) => {
      state.changePinIsLoading = false;
    });
    builder.addCase(
      cardsThunks.postOrderNewCard.fulfilled,
      (state, { payload }) => {
        state.spendingAccount.info = payload;
        state.orderCardRequestStatue = 'success';
      }
    );
    builder.addCase(cardsThunks.postOrderNewCard.rejected, (state) => {
      state.orderCardRequestStatue = 'error';
    });
    builder.addCase(cardsThunks.postOrderNewCard.pending, (state) => {
      state.orderCardRequestStatue = 'pending';
    });
  },
});

export const { reducer: cardsReducer, actions: cardsActions } = cardsSlice;
