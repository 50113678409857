import React from 'react';
import { FooterMobileTypes } from './FooterMobile.types';
import { View } from 'tamagui';

const FooterMobile = ({
  FooterContent,
  footerBgColor,
  bottomInset,
  borderTopColor,
  buttonBottomGap,
}: FooterMobileTypes) => {
  return (
    <View
      bg={footerBgColor}
      style={{ paddingBottom: bottomInset }}
      borderTopWidth={1}
      borderTopColor={borderTopColor}
    >
      <View
        padding="$4"
        {...(buttonBottomGap && { paddingBottom: buttonBottomGap })}
      >
        {FooterContent}
      </View>
    </View>
  );
};

export default FooterMobile;
