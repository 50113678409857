import { CustomDrawer, SideModal } from '@molecules';
import { ScrollView, Stack, View, XStack } from 'tamagui';
import React, { useState } from 'react';
import { maxMediaQuery } from '@constants/ui';
import { useWindowDimensions } from 'react-native';
import useIsLaptop from '@hooks/useIsLaptop';
import useIsPhone from '@hooks/useIsPhone';
import LogoIcon from '../../../assets/logo.svg';
import BurgerMenuIcon from '../../../assets/burgerMenu.svg';
import { LARGE_SIDE_BAR_VIEW, SIDE_BAR_VIEW } from './constants';
import vhCorrection from '@utils/viewportHeightCorrection';

interface SideBarTemplate {
  children: React.ReactNode;
  disableScroll?: boolean;
  isModalOpen?: boolean;
}

const SideBarTemplate = ({
  children,
  disableScroll = false,
  isModalOpen = false,
}: SideBarTemplate) => {
  const [isSideModalOpen, setSideModalOpen] = useState(false);

  const { width: desktopWidth } = useWindowDimensions();
  const isLaptop = useIsLaptop();
  const isPhone = useIsPhone();

  const toCloseModal = () => {
    setSideModalOpen(false);
  };

  const toOpenModal = () => {
    setSideModalOpen(true);
  };

  return isLaptop || isPhone ? (
    <Stack height={vhCorrection('100vh')}>
      <XStack w="100%" bg="$primaryDark" py="$3" px="$7" alignItems="center">
        <View p={0} pr="$4" onPress={toOpenModal}>
          <BurgerMenuIcon />
        </View>
        <LogoIcon />
      </XStack>
      <SideModal
        isModalOpen={isSideModalOpen}
        onPressModalClose={toCloseModal}
        modalAnimationVariant="menuModal"
      >
        <View width={SIDE_BAR_VIEW} h="100%">
          <CustomDrawer onDrawerItemPress={toCloseModal} />
        </View>
      </SideModal>
      <ScrollView
        h="100%"
        contentContainerStyle={{ height: '100%', flexGrow: 1 }}
        scrollEnabled={!disableScroll}
      >
        {children}
      </ScrollView>
    </Stack>
  ) : (
    <XStack flexGrow={1} height={vhCorrection('100vh')}>
      {isModalOpen && !isLaptop && (
        <View
          width={SIDE_BAR_VIEW}
          h="100%"
          position="absolute"
          backgroundColor="$black"
          opacity={0.6}
          zIndex={10}
        />
      )}
      <View
        width={
          desktopWidth <= maxMediaQuery.smallDesktop
            ? SIDE_BAR_VIEW
            : LARGE_SIDE_BAR_VIEW
        }
        h="100%"
        {...(desktopWidth <= maxMediaQuery.laptop && {
          display: 'none',
        })}
        flexShrink={0}
      >
        <CustomDrawer />
      </View>
      <ScrollView
        h="100%"
        contentContainerStyle={{ height: '100%', flexGrow: 1 }}
        scrollEnabled={!disableScroll}
      >
        {children}
      </ScrollView>
    </XStack>
  );
};

export default SideBarTemplate;
