import { useNavigation } from '@react-navigation/native';
import { RootNavigatorParamProps } from '@navigation/RootNavigator/RootNavigator.types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  selectErrorState,
  selectErrorStatesRedirect,
} from '@store/features/errorStates/selectors';
import useIsPhone from '@hooks/useIsPhone';
import { navigationStatesActions } from '@store/features/navigationStates/slice';

const useErrorHandler = () => {
  const navigation = useNavigation<RootNavigatorParamProps['navigation']>();
  const errorState = useSelector(selectErrorState);
  const redirectErrorState = useSelector(selectErrorStatesRedirect);
  const isPhone = useIsPhone();
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorState.isError) {
      switch (errorState.displayType) {
        case 'page':
          navigation.navigate('ErrorPage');
          break;
        case 'redirect':
          if (redirectErrorState.redirectPageName === 'logout') {
            //todo logout
          }
          if (redirectErrorState.redirectPageName === 'login') {
            dispatch(navigationStatesActions.setIsAuthenticated(false));
            dispatch(navigationStatesActions.setIsAuthorizedCardholder(false));
            navigation.navigate('AuthNavigator', {
              screen: isPhone ? 'SignInPage' : 'PaychexLoginPage',
            });
          }
          break;
      }
    }
  }, [errorState]);
};

export default useErrorHandler;
