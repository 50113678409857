import { disputeCharge } from '@constants/support';
import SupportScreenContent from '@organisms/SupportScreenContent/SupportScreenContent';
import { HeaderMobileTemplate } from '@templates';
import { Platform } from 'react-native';
import { MobileWebHeader } from '@molecules';
import React from 'react';

interface SupportViewProps {
  navigateBack: () => void;
  pageInfo: typeof disputeCharge;
}

const SupportView = ({ navigateBack, pageInfo }: SupportViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderMobileTemplate
        header={{
          isBackButton: true,
          onPressBack: navigateBack,
          title: pageInfo.headerTitle,
        }}
      >
        <SupportScreenContent {...pageInfo} />
      </HeaderMobileTemplate>
    </>
  );
};

export default SupportView;
