const Checkbox = {
  baseStyle: {
    _light: {
      borderColor: '$gray30',
      _hover: {
        borderColor: '$gray30',
      },
      _pressed: {
        borderColor: '$gray30',
      },
      _checked: {
        bg: 'primary.normal',
        borderColor: 'primary.normal',
        _hover: {
          bg: 'primary.normal',
          borderColor: 'primary.normal',
        },
        _pressed: {
          borderColor: 'primary.normal',
          bg: 'primary.normal',
        },
      },
    },
  },
  defaultProps: {
    defaultIsChecked: false,
    size: 'primary',
  },
  sizes: {
    primary: {
      _icon: {
        size: '14px',
      },
    },
  },
};

export default Checkbox;
