import useIsPhone from '@hooks/useIsPhone';
import {
  APPLE_DISCLAIMER,
  GOOGLE_DISCLAIMER,
  PAYCHEX_PAY_DEPOSIT_ACCOUNT,
} from '@constants/policies';
import { Text } from '@atoms';
import { View } from 'tamagui';

const LoginFooterContent = () => (
  <Text
    color="$gray50"
    fontSize={10}
    fontWeight={'500'}
    lineHeight={12}
    textAlign="center"
    paddingVertical={10}
    paddingHorizontal={10}
  >
    {PAYCHEX_PAY_DEPOSIT_ACCOUNT} {APPLE_DISCLAIMER} {GOOGLE_DISCLAIMER}
  </Text>
);

const LoginFooter = () => {
  const isPhone = useIsPhone();
  return isPhone ? (
    <LoginFooterContent />
  ) : (
    <View backgroundColor="$blue10" borderTopColor="$gray20" borderTopWidth={1}>
      <LoginFooterContent />
    </View>
  );
};

export default LoginFooter;
