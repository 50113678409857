import { SideBarTemplate } from '@templates';
import { Center, StatusBar } from 'native-base';
import { View, XStack, ScrollView } from 'tamagui';
import { AtmList, MapView } from '@organisms';
import { BackButton, Line, TButton, Text } from '@atoms';
import SearchIcon from '../../../assets/search.svg';
import React, { useMemo, useRef, useState } from 'react';
import LinkIcon from '../../../assets/atm/linkIcon.svg';
import ListIcon from '../../../assets/atm/listView.svg';
import MapIcon from '../../../assets/atm/mapView.svg';
import { mockedLocation } from '@constants/markersList';
import Animated from 'react-native-reanimated';
import { Dimensions, Keyboard, Platform, Pressable } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { PanGestureHandler } from 'react-native-gesture-handler';
import useMarkerDetailsAnimation from '@hooks/Animations/useMarkerDetailsAnimation';
import useListViewAnimation from '@hooks/Animations/useListViewAnimation';
import {
  BottomModal,
  MarkerInfo,
  MobileWebHeader,
  SideModal,
  TextInput,
} from '@molecules';
import NavigationArrow from '../../../assets/navigationArrow.svg';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import useLocationPermissionModal from '@hooks/Animations/useLocationPermissionModal';
import AppleMapsIcon from '../../../assets/atm/appleMapsIcon.svg';
import GoogleMapsIcon from '../../../assets/atm/googleMapsIcon.svg';
import {
  ICenter,
  IZipSearch,
  IMarker,
} from '@store/features/atmLocations/types';
import useIsLaptop from '@hooks/useIsLaptop';
import VerificationLocationIcon from '../../../assets/atm/verificationLocationIcon.svg';
import { Region } from 'react-native-maps';
import LoaderPaychex from '@atoms/LoaderPaychex/LoaderPaychex';
import useIsPhone from '@hooks/useIsPhone';
import { MOBILE_HEADER_HEIGHT } from '@constants/general';

interface AtmAndReloadViewProps {
  markers: IMarker[];
  isMarkersPending: boolean;
  onPressMarker: (onPressEvent: {
    markerId: string;
    latitude: number;
    longitude: number;
  }) => () => void;
  onPressDepositCash: () => void;
  currentMarkerInfo: IMarker;
  onPressLocation: () => void;
  currentLocation: typeof mockedLocation;
  locationPermissionGranted: boolean;
  mapRef: any;
  onPressSearchArea: (region: Region) => void;
  isPermissionDenied: boolean;
  resetPermission: () => void;
  requestCurrentLocation: () => void;
  isZipValidationError: boolean;
  setIsZipValidationError: (a: boolean) => void;
}

interface AtmAndReloadViewMobileProps extends AtmAndReloadViewProps {
  unselectMarker: () => void;
  isListViewOpen: boolean;
  setIsListViewOpen: (f: boolean) => void;
  navigateBack: () => void;
  onOpenSettings: () => void;
  onPressAppleMaps: () => void;
  onPressGoogleMaps: () => void;
  isBottomModalOpen: boolean;
  onPressBottomModalClose: () => void;
  onPressDirection: () => void;
  isGoogleMapsAvailableIos: boolean;
  mapCenter: ICenter;
  setMapCenter: (center: ICenter) => void;
  onPressZipSuggestion: ({ zipCode }: IZipSearch) => void;
  setIsShowThisAriaButton: () => void;
  isShowThisAriaButton: boolean;
  zipSearchValue: string;
  onZipSearch: (value: string) => void;
  currentZipCode: string;
  setCurrentZipCode: (value?: string) => void;
}

interface AtmAndReloadViewDesktopProps extends AtmAndReloadViewProps {
  isModalOpen: boolean;
  modalName: string;
  toCloseModal: () => void;
  zipSearchValue: string;
  onZipSearch: (value: string) => void;
  mapCenter: ICenter;
  setMapCenter: (center: ICenter) => void;
  onPressZipSuggestion: ({ zipCode }: IZipSearch) => void;
  setIsShowThisAriaButton: () => void;
  isShowThisAriaButton: boolean;
  isRequestWithoutResponse: boolean;
}

const windowHeight = Dimensions.get('window').height;

const mobile = ({
  navigateBack,
  isListViewOpen,
  setIsListViewOpen,
  onPressDepositCash,
  markers,
  isMarkersPending,
  onPressMarker,
  unselectMarker,
  currentMarkerInfo,
  mapRef,
  locationPermissionGranted,
  onPressLocation,
  currentLocation,
  isPermissionDenied,
  resetPermission,
  onOpenSettings,
  onPressAppleMaps,
  onPressGoogleMaps,
  isBottomModalOpen,
  onPressBottomModalClose,
  onPressDirection,
  isGoogleMapsAvailableIos,
  onPressSearchArea,
  mapCenter,
  setMapCenter,
  onPressZipSuggestion,
  setIsShowThisAriaButton,
  isShowThisAriaButton,
  zipSearchValue,
  onZipSearch,
  currentZipCode,
  setCurrentZipCode,
  isZipValidationError,
  setIsZipValidationError,
  requestCurrentLocation,
}: AtmAndReloadViewMobileProps) => {
  const isPhone = useIsPhone();
  const { top: topInset, bottom: bottomInset } = useSafeAreaInsets();
  const {
    detailsViewStyle,
    detailsViewGesture,
    arrowStyle,
    pageTitleStyle,
    isFullscreenView,
    onPressArrowDown,
    locationStyle,
    closePreview,
    isPreviewClosed,
  } = useMarkerDetailsAnimation({
    topInset,
    currentMarkerId: currentMarkerInfo?.location.id || null,
    unselectMarker,
    isListViewOpen,
  });

  const { listViewStyle, onPressListView } = useListViewAnimation({
    isListViewOpen,
    setIsListViewOpen,
    closePreview,
  });

  const { backgroundStyle, isModalVisible } = useLocationPermissionModal({
    isPermissionDenied,
  });

  const showLocationButton = useMemo(() => {
    if (isPhone) {
      const isLocationAvailable =
        Platform.OS === 'web'
          ? !isPermissionDenied
          : locationPermissionGranted || markers.length !== 0;
      return (
        isLocationAvailable &&
        !isMarkersPending &&
        isPreviewClosed &&
        !isListViewOpen
      );
    } else {
      return false;
    }
  }, [
    isPhone,
    isPermissionDenied,
    locationPermissionGranted,
    markers,
    isMarkersPending,
    isPreviewClosed,
    isListViewOpen,
  ]);

  const contentWrapperRef = useRef(null);
  const [contentWrapperHeight, setContentWrapperHeight] = useState(0);

  const LocationDeniedPlaceholder = () => {
    return (
      <View
        zIndex={100}
        backgroundColor="white"
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        flex={1}
        flexGrow={1}
        paddingHorizontal={18}
        paddingBottom={Platform.OS === 'ios' ? bottomInset : 5}
      >
        <VerificationLocationIcon />
        <View flex={1} paddingBottom={5} justifyContent={'space-between'}>
          <View
            marginHorizontal={14}
            padding={12}
            borderRadius={12}
            backgroundColor={'$blue10'}
          >
            <Text
              variant={'regular'}
              fontSize={14}
              textAlign={'center'}
              onPress={onOpenSettings}
            >
              Enter your ZIP Code, or enable your{' '}
              <Text variant={'regular'} fontSize={14} color={'$primaryNormal'}>
                location
              </Text>{' '}
              for more accurate results
            </Text>
          </View>
          <View>
            <TButton
              onPress={onOpenSettings}
              accessible
              accessibilityLabel={'Allow, button'}
            >
              Allow
            </TButton>
          </View>
        </View>
      </View>
    );
  };

  const MarkersEmptyPlaceholder = () => {
    return (
      <View
        zIndex={100}
        backgroundColor="white"
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        flex={1}
        flexGrow={1}
        paddingHorizontal={18}
      >
        <VerificationLocationIcon />
        <View flex={1} paddingBottom={10} justifyContent={'space-between'}>
          <View
            marginHorizontal={14}
            padding={12}
            borderRadius={12}
            backgroundColor={'$blue10'}
          >
            <Text variant={'regular'} fontSize={14} textAlign={'center'}>
              No results found.{'\n'}Try changing your search parameters
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <>
      {Platform.OS !== 'web' && isPermissionDenied && (
        <View
          position="absolute"
          top={0}
          left={0}
          h="100%"
          w="100%"
          zIndex={10}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Animated.View
            style={[
              backgroundStyle,
              {
                backgroundColor: 'black',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
            ]}
          />
          {isModalVisible && (
            <View w="100%" px={16}>
              <View p={16} backgroundColor="white" borderRadius={12}>
                <Text variant="title" paddingVertical={8} textAlign="center">
                  Allow location services?
                </Text>
                <Text variant="subtitle" pt={16} pb={24} textAlign="center">
                  You’ll be able to find nearby ATMs.
                </Text>
                <Line />
                <XStack pt={16}>
                  <View w="50%" paddingRight={8}>
                    <TButton variant="white" onPress={resetPermission}>
                      No, thanks
                    </TButton>
                  </View>
                  <View w="50%" paddingLeft={8}>
                    <TButton onPress={onOpenSettings}>Allow</TButton>
                  </View>
                </XStack>
              </View>
            </View>
          )}
        </View>
      )}
      <View
        backgroundColor="white"
        paddingTop={Platform.OS === 'web' ? topInset : topInset + 8}
        flexGrow={1}
        position="relative"
        {...(Platform.OS === 'web' && { overflow: 'hidden' })}
      >
        {Platform.OS === 'web' ? (
          <>
            <MobileWebHeader />
            <XStack px={16} py={8}>
              <Animated.View style={[arrowStyle]}>
                <BackButton
                  onPress={isFullscreenView ? onPressArrowDown : navigateBack}
                />
              </Animated.View>
              <Center flexGrow={1}>
                <Animated.View style={[pageTitleStyle]}>
                  <Text variant="title" pr="24px">
                    ATM finder
                  </Text>
                </Animated.View>
              </Center>
            </XStack>
            <Line />
          </>
        ) : (
          <>
            <StatusBar barStyle="dark-content" />
            <XStack px={16} py={4}>
              <Animated.View style={[arrowStyle]}>
                <BackButton
                  onPress={isFullscreenView ? onPressArrowDown : navigateBack}
                />
              </Animated.View>
              <Center flexGrow={1}>
                <Animated.View style={[pageTitleStyle]}>
                  <Text variant="title" pr="24px">
                    ATM finder
                  </Text>
                </Animated.View>
              </Center>
            </XStack>
            <Line />
          </>
        )}
        <ScrollView backgroundColor="white" flexGrow={1} height={'100%'}>
          <View p={16}>
            <View
              borderWidth={1}
              borderColor={isZipValidationError ? '$red50' : 'white'}
              borderRadius={8}
            >
              <TextInput
                args={{
                  flexGrow: 1,
                  fontSize: '$body',
                  placeholder: 'Search by ZIP Code',
                  placeholderTextColor: '#656C7B',
                  value: zipSearchValue,
                  onChangeText: (value: string) => {
                    const modifiedValue = value.replace(/\D/g, '');
                    onZipSearch(modifiedValue);
                  },
                  onFocus: () => {
                    setIsZipValidationError(false);
                    closePreview();
                  },
                  returnKeyType: 'search',
                  blurOnSubmit: true,
                  onSubmitEditing: () => {
                    onPressZipSuggestion({ zipCode: zipSearchValue });
                  },
                  maxLength: 5,
                  keyboardType: Platform.select({
                    ios: 'numbers-and-punctuation',
                    android: 'number-pad',
                  }),
                }}
                InputLeftElement={
                  <View pt={'$3'} pl="$3">
                    <SearchIcon />
                  </View>
                }
              />
            </View>
            {isZipValidationError && (
              <Text fontSize={12} color={'$red50'}>
                ZIP code must contain 5 digits
              </Text>
            )}
            <XStack justifyContent="space-between" pt={10}>
              <Pressable onPress={onPressDepositCash}>
                <XStack alignItems="center">
                  <Text
                    color="$primaryNormal"
                    fontSize={14}
                    fontWeight={'500'}
                    marginRight={4}
                  >
                    Need to deposit cash?
                  </Text>
                  <View p={3} backgroundColor={'$blue20'} borderRadius={4}>
                    <LinkIcon width="14px" height="14px" color={'#0073C4'} />
                  </View>
                </XStack>
              </Pressable>
              <Pressable onPress={onPressListView}>
                <XStack alignItems="center">
                  {isListViewOpen ? <MapIcon /> : <ListIcon />}
                  <Text color="$primaryNormal" pl={1}>
                    {isListViewOpen ? 'Map View' : 'List View'}
                  </Text>
                </XStack>
              </Pressable>
            </XStack>
          </View>
          <Line />
          <View
            ref={contentWrapperRef}
            height={contentWrapperHeight ? contentWrapperHeight : '78%'}
            position="relative"
            // @ts-ignore
            onLayout={() => {
              // @ts-ignore
              contentWrapperRef.current?.measureInWindow((x, y) => {
                const calculatedHeight = windowHeight - y;
                setContentWrapperHeight(calculatedHeight);
              });
            }}
          >
            {isMarkersPending && (
              <View
                position={'absolute'}
                top={0}
                left={0}
                bottom={0}
                right={0}
                zIndex={35}
                paddingTop={windowHeight / 3}
                backgroundColor={'rgba(0, 0, 0 , 0.25)'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                onPress={Keyboard.dismiss}
              >
                <LoaderPaychex />
              </View>
            )}

            {Platform.OS !== 'web' &&
              !locationPermissionGranted &&
              markers.length === 0 &&
              !isMarkersPending && (
                <View
                  position={'absolute'}
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  zIndex={34}
                  backgroundColor={'white'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  onPress={Keyboard.dismiss}
                >
                  <LocationDeniedPlaceholder />
                </View>
              )}

            <MapView
              markers={markers}
              onPressMarker={onPressMarker}
              currentMarkerId={currentMarkerInfo?.location.id}
              mapRef={mapRef}
              currentLocation={currentLocation}
              onPressSearchArea={onPressSearchArea}
              setIsShowThisAriaButton={setIsShowThisAriaButton}
              isShowThisAriaButton={isShowThisAriaButton}
              requestCurrentLocation={requestCurrentLocation}
              isPermissionDenied={isPermissionDenied}
              onPanDrag={isPreviewClosed ? null : closePreview}
              scrollEnabled={isPreviewClosed}
              {...(Platform.OS === 'web'
                ? {
                    mapCenter: mapCenter,
                    setMapCenter: setMapCenter,
                  }
                : {
                    currentZipCode: currentZipCode,
                    setCurrentZipCode: setCurrentZipCode,
                    setZipSearchValue: onZipSearch,
                  })}
            />

            <Animated.View
              style={[
                listViewStyle,
                {
                  display: 'flex',
                  flex: 1,
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  zIndex: 100,
                },
              ]}
            >
              {isMarkersPending && (
                <View
                  position={'absolute'}
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  zIndex={35}
                  paddingTop={windowHeight / 3}
                  backgroundColor={'rgba(0, 0, 0 , 0.25)'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  onPress={Keyboard.dismiss}
                >
                  <LoaderPaychex />
                </View>
              )}

              {Platform.OS !== 'web' &&
                !locationPermissionGranted &&
                markers.length === 0 &&
                !isMarkersPending && (
                  <View
                    position={'absolute'}
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    zIndex={34}
                    backgroundColor={'white'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    onPress={Keyboard.dismiss}
                  >
                    <LocationDeniedPlaceholder />
                  </View>
                )}

              {!isMarkersPending && markers.length === 0 && (
                <View
                  position={'absolute'}
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  zIndex={33}
                  backgroundColor={'white'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  onPress={Keyboard.dismiss}
                >
                  <MarkersEmptyPlaceholder />
                </View>
              )}

              <ScrollView
                display={'flex'}
                flex={1}
                backgroundColor="white"
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                  paddingBottom: 210,
                }}
              >
                <AtmList markers={markers} onPressMarker={onPressMarker} />
              </ScrollView>
            </Animated.View>
          </View>
        </ScrollView>
        <View
          position="absolute"
          width="100%"
          height="100%"
          left={0}
          top={
            Platform.OS === 'ios'
              ? windowHeight
              : Platform.OS === 'android'
              ? windowHeight + topInset
              : windowHeight + topInset + MOBILE_HEADER_HEIGHT
          }
          backgroundColor={'white'}
          zIndex={100}
        >
          <PanGestureHandler onGestureEvent={detailsViewGesture}>
            <Animated.View style={[detailsViewStyle]}>
              <View
                h={
                  Platform.OS === 'ios'
                    ? windowHeight - topInset - 40
                    : Platform.OS === 'android'
                    ? windowHeight - 40
                    : windowHeight - topInset - 40 - MOBILE_HEADER_HEIGHT
                }
                backgroundColor="white"
                borderTopLeftRadius={24}
                borderTopRightRadius={24}
                px={16}
                pt={6.7}
                position="relative"
              >
                {showLocationButton && (
                  <Animated.View style={[locationStyle]}>
                    <TButton
                      w={48}
                      h={48}
                      variant={'simple'}
                      position="absolute"
                      bottom={Platform.OS !== 'web' ? bottomInset + 16 : 80}
                      right={16}
                      zIndex={1}
                      p={12}
                      borderRadius={8}
                      backgroundColor="white"
                      onPress={onPressLocation}
                    >
                      <NavigationArrow />
                    </TButton>
                  </Animated.View>
                )}
                <Center>
                  <Animated.View style={[pageTitleStyle]}>
                    <View
                      backgroundColor="$gray20"
                      w={23}
                      h={2.51}
                      borderRadius={20}
                    />
                  </Animated.View>
                </Center>
                {currentMarkerInfo?.location.id && (
                  <MarkerInfo.mobile
                    markerInfo={currentMarkerInfo}
                    onPressDirection={onPressDirection}
                  />
                )}
              </View>
            </Animated.View>
          </PanGestureHandler>
        </View>
      </View>
      {isGoogleMapsAvailableIos && (
        <BottomModal
          isModalOpen={isBottomModalOpen}
          onPressModalClose={onPressBottomModalClose}
          buttonText="Cancel"
        >
          <Text variant="title" pb={4} alignItems="center">
            Open In
          </Text>
          <XStack space={4}>
            <Pressable
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onPress={onPressAppleMaps}
            >
              <View
                backgroundColor="white"
                borderRadius={12}
                borderColor="$gray20"
                overflow="hidden"
                borderWidth={1}
              >
                <AppleMapsIcon width="48px" height="48px" />
              </View>
              <Text fontSize={12} pt={2}>
                Maps
              </Text>
            </Pressable>
            <Pressable
              style={{
                flexDirection: 'column',
                alignItems: 'center',
              }}
              onPress={onPressGoogleMaps}
            >
              <View
                backgroundColor="white"
                borderRadius={12}
                borderColor="$gray20"
                overflow="hidden"
                borderWidth={1}
                p={6}
              >
                <GoogleMapsIcon width="36px" height="36px" />
              </View>
              <Text fontSize={12} pt={2}>
                Google Maps
              </Text>
            </Pressable>
          </XStack>
        </BottomModal>
      )}
    </>
  );
};

const desktop = ({
  markers,
  onPressMarker,
  isModalOpen,
  modalName,
  toCloseModal,
  currentMarkerInfo,
  zipSearchValue,
  onZipSearch,
  mapCenter,
  setMapCenter,
  onPressZipSuggestion,
  onPressSearchArea,
  setIsShowThisAriaButton,
  isShowThisAriaButton,
  isPermissionDenied,
  currentLocation,
  requestCurrentLocation,
  isRequestWithoutResponse,
  onPressDepositCash,
  isZipValidationError,
  setIsZipValidationError,
}: AtmAndReloadViewDesktopProps) => {
  const isLaptop = useIsLaptop();
  const screenHeight = Dimensions.get('window').height;

  return (
    <>
      <SideBarTemplate>
        <View
          paddingHorizontal={40}
          paddingTop={24}
          paddingBottom={40}
          flexGrow={1}
          flex={1}
        >
          <Text fontSize={20} fontWeight={'600'} paddingBottom={40}>
            ATM finder
          </Text>
          <View
            flex={1}
            flexGrow={1}
            backgroundColor="white"
            borderRadius={12}
            padding={20}
            minWidth={690}
            height={isLaptop ? screenHeight - 158 : screenHeight - 128}
          >
            <XStack flex={1} flexGrow={1} height={'100%'}>
              <View
                w={isLaptop ? '42%' : '46%'}
                pr={20}
                maxWidth={500}
                minWidth={270}
                flex={1}
                flexGrow={1}
                height={'100%'}
                overflow={'hidden'}
              >
                <View pb={10}>
                  <View
                    borderWidth={1}
                    borderColor={isZipValidationError ? '$red50' : 'white'}
                    borderRadius={8}
                  >
                    <TextInput
                      placeholder="Search by ZIP Code"
                      args={{
                        value: zipSearchValue,
                        onChangeText: (value: string) => {
                          const modifiedValue = value.replace(/\D/g, '');
                          onZipSearch(modifiedValue);
                        },
                        onFocus: () => {
                          setIsZipValidationError(false);
                        },
                        returnKeyType: 'search',
                        blurOnSubmit: true,
                        onSubmitEditing: () => {
                          onPressZipSuggestion({ zipCode: zipSearchValue });
                        },
                        testID: 'paychexpay.desktop.more.input.searchInput',
                        maxLength: 5,
                      }}
                      InputLeftElement={
                        <View pt={'$3'} pl="$3">
                          <SearchIcon />
                        </View>
                      }
                    />
                  </View>
                  {isZipValidationError && (
                    <Text fontSize={12} color={'$red50'}>
                      Zip code must contain 5 digits
                    </Text>
                  )}
                </View>
                <XStack justifyContent="flex-end" pb={10}>
                  <Pressable onPress={onPressDepositCash}>
                    <XStack alignItems="center">
                      <Text
                        color="$primaryNormal"
                        fontSize={14}
                        fontWeight={'500'}
                        marginRight={4}
                      >
                        Need to deposit cash?
                      </Text>
                      <View p={3} backgroundColor={'$blue20'} borderRadius={4}>
                        <LinkIcon
                          width="14px"
                          height="14px"
                          color={'#0073C4'}
                        />
                      </View>
                    </XStack>
                  </Pressable>
                </XStack>
                <View
                  height={'90%'}
                  overflow={'hidden'}
                  display={'flex'}
                  flex={1}
                  flexGrow={1}
                  flexDirection={'column'}
                >
                  <Line marginTop={20} />
                  <ScrollView showsVerticalScrollIndicator={false}>
                    <AtmList markers={markers} onPressMarker={onPressMarker} />
                  </ScrollView>
                </View>
              </View>
              <View
                flex={1}
                flexGrow={1}
                minWidth={350}
                borderRadius={12}
                overflow="hidden"
                height={'100%'}
              >
                <MapView
                  markers={markers}
                  onPressMarker={onPressMarker}
                  currentMarkerId={currentMarkerInfo?.location.id}
                  mapCenter={mapCenter}
                  setMapCenter={setMapCenter}
                  currentLocation={currentLocation}
                  onPressSearchArea={onPressSearchArea}
                  setIsShowThisAriaButton={setIsShowThisAriaButton}
                  isShowThisAriaButton={isShowThisAriaButton}
                  isPermissionDenied={isPermissionDenied}
                  requestCurrentLocation={requestCurrentLocation}
                  isRequestWithoutResponse={isRequestWithoutResponse}
                />
              </View>
            </XStack>
          </View>
        </View>
      </SideBarTemplate>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.markerDetails]: (
              <MarkerInfo.desktop markerInfo={currentMarkerInfo} />
            ),
          }}
        />
      </SideModal>
    </>
  );
};

const AtmAndReloadView = {
  mobile,
  desktop,
};

export default AtmAndReloadView;
