import { createTypedThunk } from '@utils/createTypedThunk';
import transactionsApi from '@store/features/transactions/api';
import {
  ITransactionsByFilterResponse,
  ITransactionsRequest,
  ITransactionsResponse,
} from '@store/features/transactions/types';

const transactionThunks = {
  getSpendingTransactions: createTypedThunk<
    ITransactionsRequest,
    ITransactionsResponse
  >(
    'transactions/getSpendingTransactions',
    transactionsApi.getSpendingTransactions
  ),
  getSavingsTransactions: createTypedThunk<
    ITransactionsRequest,
    ITransactionsResponse
  >(
    'transactions/getSavingsTransactions',
    transactionsApi.getSavingsTransactions
  ),
  getSpendingTransactionsByFilter: createTypedThunk<
    ITransactionsRequest,
    ITransactionsByFilterResponse
  >(
    'transactions/getSpendingTransactionsByFilter',
    transactionsApi.getSpendingTransactionsByFilter
  ),
  getSavingsTransactionsByFilter: createTypedThunk<
    ITransactionsRequest,
    ITransactionsByFilterResponse
  >(
    'transactions/getSavingsTransactionsByFilter',
    transactionsApi.getSavingsTransactionsByFilter
  ),
  getSpendingTransactionsBySearch: createTypedThunk<
    ITransactionsRequest,
    ITransactionsResponse
  >(
    'transactions/getSpendingTransactionsBySearch',
    transactionsApi.getSpendingTransactionsBySearch
  ),
  getSavingsTransactionsBySearch: createTypedThunk<
    ITransactionsRequest,
    ITransactionsResponse
  >(
    'transactions/getSavingsTransactionsBySearch',
    transactionsApi.getSavingsTransactionsBySearch
  ),
};

export default transactionThunks;
