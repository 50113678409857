import { IAccountPreview } from '@devTypes/accountTypes';
import { IExternalAccountPreview } from '@store/features/externalBankAccounts/types';
import PaychexPayAccountIcon from '../assets/accounts/paychexpayAccountIcon.svg';
import ExternalAccountIcon from '../assets/accounts/default.svg';
import SelectAccountIcon from '../assets/accounts/selectAccountIcon.svg';
import { ReactElement } from 'react';

export interface IAccountTile {
  id: string;
  name: string;
  amount: number;
  type: 'paychex' | 'external' | null;
  accountType: IAccountPreview['type'];
  icon: ReactElement;
}

const idToAccount = (
  id: string | undefined,
  spendingAccount: IAccountPreview,
  savingsAccount: IAccountPreview,
  externalAccounts: IExternalAccountPreview[]
): IAccountTile => {
  let account;
  if (id === spendingAccount.id) {
    account = spendingAccount;
  } else if (id === savingsAccount.id) {
    account = savingsAccount;
  } else {
    account = externalAccounts.find((account) => account.id === id);
  }
  if (account) {
    return {
      id: account.id,
      name: account.name,
      amount: account.amount,
      type: account.isExternal ? 'external' : 'paychex',
      accountType: account.type,
      icon: account.isExternal ? (
        <ExternalAccountIcon />
      ) : (
        <PaychexPayAccountIcon />
      ),
    };
  } else {
    return {
      id: '0000',
      name: 'Select account',
      amount: 0,
      type: null,
      accountType: 'other',
      icon: <SelectAccountIcon />,
    };
  }
};

export default idToAccount;
