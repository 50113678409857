const baseDuration = 800;
const randomRangeDuration = 250;
const freezeLayersNumber = 3;
const freezeDuration = baseDuration;
const unfreezeDuration = baseDuration + randomRangeDuration;
const randomFreezeDurationRange = [
  baseDuration,
  baseDuration + randomRangeDuration,
];
const randomUnfreezeDurationRange = [
  baseDuration,
  baseDuration + randomRangeDuration,
];
const layersImages = [
  {
    uri: require('../assets/cards/freezeCardLayer1.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer2.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer3.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer4.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer5.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer6.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer7.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer8.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer9.png'),
    width: 343,
    height: 202,
  },
  {
    uri: require('../assets/cards/freezeCardLayer10.png'),
    width: 343,
    height: 202,
  },
];

const blockedCardTopCurtainHeight = 132;
const blockedCardBottomCurtainHeight = 110;
const blockCardAnimationDuration = 1000;
const unblockCardAnimationDuration = 1000;

export {
  baseDuration,
  randomRangeDuration,
  freezeDuration,
  unfreezeDuration,
  randomFreezeDurationRange,
  randomUnfreezeDurationRange,
  layersImages,
  freezeLayersNumber,
  blockedCardTopCurtainHeight,
  blockedCardBottomCurtainHeight,
  blockCardAnimationDuration,
  unblockCardAnimationDuration,
};
