import { Control, Controller, useForm } from 'react-hook-form';
import { IConnectAccountFormInputs } from '@organisms/ConnectAccountForm/helper';
import { TextInput } from '@molecules';
import { Text, TPressable } from '@atoms';
import React, { useEffect, useRef, useState } from 'react';
import ArrowDownIcon from '../../../assets/arrowDown.svg';
import ArrowUpIcon from '../../../assets/ArrowUp.svg';
import { customTheme } from '../../../styles/customTheme';
import useOutsideClick from '@hooks/useOutsideClick';
import { Platform } from 'react-native';
import EyeShowIcon from '../../../assets/eyeShow.svg';
import EyeHideIcon from '../../../assets/eyeHide.svg';
import { SIDE_MODAL_ANIMATION_DURATION } from '@molecules/SideModal/SideModal';
import { Separator, View, XStack } from 'tamagui';

interface connectBankFormProps {
  control?: Control<IConnectAccountFormInputs>;
  isBankAcntNameInputDisabled: boolean;
}

const connectBankForm = ({
  control: controlProp,
  isBankAcntNameInputDisabled,
}: connectBankFormProps) => {
  const { control } = useForm<IConnectAccountFormInputs>();
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isAccOwnershipSelectOpen, setIsAccOwnershipSelectOpen] =
    useState(false);
  const onOpenSelect = () => {
    blurInputs();
    onCloseAccOwnershipSelect();
    setIsSelectOpen(true);
  };
  const onCloseSelect = () => {
    setIsSelectOpen(false);
  };

  const onOpenAccOwnershipSelect = () => {
    blurInputs();
    onCloseSelect();
    setIsAccOwnershipSelectOpen(true);
  };
  const onCloseAccOwnershipSelect = () => {
    setIsAccOwnershipSelectOpen(false);
  };

  const closeSelectors = () => {
    onCloseSelect();
    onCloseAccOwnershipSelect();
  };

  const {
    ref: outsideClickRef,
    refStopPropagation: outsideClickRefStopPropagation,
  } =
    Platform.OS === 'web'
      ? useOutsideClick({
          onOutsideClick: onCloseSelect,
        })
      : { ref: null, refStopPropagation: null };
  const [isBankNumberVisible, setIsBankNumberVisible] = useState(false);
  const [isRoutingNumberVisible, setIsRoutingNumberVisible] = useState(false);
  const bankHideHandler = () => {
    setIsBankNumberVisible(!isBankNumberVisible);
  };
  const routingHideHandler = () => {
    setIsRoutingNumberVisible(!isRoutingNumberVisible);
  };

  const bankNumberInputRef = useRef(null);
  const routingNumberInputRef = useRef(null);
  const bankNameInputRef = useRef(null);
  const bankAcntNameInputRef = useRef(null);

  const blurInputs = () => {
    // @ts-ignore
    bankAcntNameInputRef.current?.blur();
    // @ts-ignore
    bankNameInputRef.current?.blur();
    // @ts-ignore
    routingNumberInputRef.current?.blur();
    // @ts-ignore
    bankNumberInputRef.current?.blur();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // @ts-ignore
      bankNumberInputRef.current?.focus();
    }, SIDE_MODAL_ANIMATION_DURATION + 300);
    // making sure the modal is fully open before focusing,
    // otherwise we have a visual issue during sidemodal opening
    return () => clearTimeout(timer);
  }, []);

  return (
    <View zIndex={1} accessible accessibilityLabel={'Connect bank form'}>
      <Controller
        name="accountNumber"
        control={controlProp || control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <TextInput
            label="Account number"
            styles={{ marginBottom: 16 }}
            args={{
              ref: bankNumberInputRef,
              onChangeText: onChange,
              onBlur,
              value,
              keyboardType: 'number-pad',
              testID: 'paychexpay.connectBankForm.input.bankNumberInput',
              onFocus: closeSelectors,
              secureTextEntry: !isBankNumberVisible,
              accessible: true,
              accessibilityLabel: 'Bank number input',
              returnKeyType: 'next',
              blurOnSubmit: false,
              onSubmitEditing: () => {
                // @ts-ignore
                routingNumberInputRef.current?.focus();
              },
            }}
            isError={!!error}
            errorMessage={error?.message}
            InputRightElement={
              <TPressable
                onPress={bankHideHandler}
                p={'$3'}
                testID="paychexpay.connectBankForm.button.eyeIconNumber"
              >
                {isBankNumberVisible ? <EyeShowIcon /> : <EyeHideIcon />}
              </TPressable>
            }
          />
        )}
      />
      <Controller
        name="routingNumber"
        control={controlProp || control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <TextInput
            label="Routing number"
            styles={{ marginBottom: 16 }}
            args={{
              ref: routingNumberInputRef,
              onChangeText: onChange,
              onBlur,
              value,
              keyboardType: 'number-pad',
              testID: 'paychexpay.connectBankForm.input.routingNumberInput',
              onFocus: closeSelectors,
              secureTextEntry: !isRoutingNumberVisible,
              accessible: true,
              accessibilityLabel: 'Routing number input',
              returnKeyType: 'next',
              blurOnSubmit: false,
              onSubmitEditing: () => {
                // @ts-ignore
                bankNameInputRef.current?.focus();
              },
            }}
            isError={!!error}
            errorMessage={error?.message}
            InputRightElement={
              <TPressable
                onPress={routingHideHandler}
                p={'$3'}
                testID="paychexpay.connectBankForm.button.eyeIconRouting"
              >
                {isRoutingNumberVisible ? <EyeShowIcon /> : <EyeHideIcon />}
              </TPressable>
            }
          />
        )}
      />
      <Controller
        name="bankName"
        control={controlProp || control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <TextInput
            label="Bank name"
            styles={{ marginBottom: 16 }}
            args={{
              ref: bankNameInputRef,
              onChangeText: onChange,
              onBlur,
              value,
              keyboardType: 'default',
              testID: 'paychexpay.connectBankForm.input.bankNameInput',
              onFocus: closeSelectors,
              accessible: true,
              accessibilityLabel: 'Bank name input',
              returnKeyType: 'next',
              blurOnSubmit: false,
              onSubmitEditing: () => {
                // @ts-ignore
                bankAcntNameInputRef.current?.focus();
              },
            }}
            isError={!!error}
            errorMessage={error?.message}
          />
        )}
      />
      <Controller
        name="accountType"
        control={controlProp || control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <View
            marginBottom={isSelectOpen ? 120 : 16}
            // @ts-ignore
            ref={outsideClickRefStopPropagation}
          >
            <Text
              color="$gray40"
              lineHeight="$h2"
              fontSize="$h5"
              mb={'$1'}
              accessible
              accessibilityLabel={'Account type'}
            >
              Account type
            </Text>
            <TPressable
              borderRadius={8}
              backgroundColor="$blue10"
              paddingHorizontal={12}
              paddingVertical={13}
              testID="paychexpay.connectBankForm.button.selectAccountType"
              onPress={isSelectOpen ? onCloseSelect : onOpenSelect}
            >
              <XStack justifyContent="space-between">
                <Text fontSize="$body" color={value ? 'black' : '$gray40'}>
                  {value}
                </Text>
                {isSelectOpen ? (
                  <ArrowUpIcon
                    width="24px"
                    height="24px"
                    fill={customTheme.colors.gray['30']}
                  />
                ) : (
                  <ArrowDownIcon
                    width="24px"
                    height="24px"
                    fill={customTheme.colors.gray['30']}
                  />
                )}
              </XStack>
            </TPressable>
            {!!error && (
              <Text variant="error" mt={'$1'}>
                {error.message}
              </Text>
            )}
            {isSelectOpen && (
              <View
                ref={outsideClickRef}
                mt={'$2'}
                borderRadius={8}
                borderWidth={1}
                borderColor="$gray20"
                px={'$2'}
                paddingVertical={10}
                position="absolute"
                width="100%"
                top="100%"
                backgroundColor={'white'}
              >
                <TPressable
                  px={2}
                  onPress={() => {
                    onChange('Checking');
                    onCloseSelect();
                  }}
                  testID="paychexpay.connectBankForm.button.selectAccountTypeSpending"
                  accessible
                  accessibilityLabel={'select Checking account type'}
                >
                  <Text fontSize="$body">Checking</Text>
                </TPressable>
                <Separator marginVertical={10} borderColor="$gray20" />
                <TPressable
                  px={2}
                  onPress={() => {
                    onChange('Savings');
                    onCloseSelect();
                  }}
                  testID="paychexpay.connectBankForm.button.selectAccountTypeSaving"
                  accessible
                  accessibilityLabel={'select Saving account type'}
                >
                  <Text fontSize="$body">Savings</Text>
                </TPressable>
              </View>
            )}
          </View>
        )}
      />
      <Controller
        name="accountOwnership"
        control={controlProp || control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <View
            mb={isAccOwnershipSelectOpen ? 120 : 16}
            // @ts-ignore
            ref={outsideClickRefStopPropagation}
          >
            <Text
              color="$gray40"
              lineHeight="$h2"
              fontSize="$h5"
              mb={'$1'}
              accessible
              accessibilityLabel={'Account ownership'}
            >
              Account ownership
            </Text>
            <TPressable
              borderRadius={8}
              backgroundColor="$blue10"
              testID="paychexpay.connectBankForm.button.selectAccountOwnership"
              paddingHorizontal={12}
              paddingVertical={13}
              onPress={
                isAccOwnershipSelectOpen
                  ? onCloseAccOwnershipSelect
                  : onOpenAccOwnershipSelect
              }
              accessible
              accessibilityLabel={'select Account ownership'}
            >
              <XStack justifyContent="space-between">
                <Text fontSize="$body" color={value ? 'black' : '$gray40'}>
                  {value ? value.charAt(0) + value.toLowerCase().slice(1) : ''}
                </Text>
                {isAccOwnershipSelectOpen ? (
                  <ArrowUpIcon
                    width="24px"
                    height="24px"
                    fill={customTheme.colors.gray['30']}
                  />
                ) : (
                  <ArrowDownIcon
                    width="24px"
                    height="24px"
                    fill={customTheme.colors.gray['30']}
                  />
                )}
              </XStack>
            </TPressable>
            {!!error && (
              <Text variant="error" mt={1}>
                {error.message}
              </Text>
            )}
            {isAccOwnershipSelectOpen && (
              <View
                ref={outsideClickRef}
                mt={'$2'}
                borderRadius={8}
                borderWidth={1}
                borderColor="$gray20"
                px={'$2'}
                py={10}
                position="absolute"
                width="100%"
                top="100%"
                backgroundColor={'white'}
              >
                <TPressable
                  px={2}
                  onPress={() => {
                    onChange('SELF');
                    onCloseAccOwnershipSelect();
                  }}
                  testID="paychexpay.connectBankForm.button.selectAccountSelf"
                  accessible
                  accessibilityLabel={'select Self account ownership'}
                >
                  <Text fontSize="$body">Self</Text>
                </TPressable>
                <Separator marginVertical={10} borderColor="$gray20" />
                <TPressable
                  px={2}
                  onPress={() => {
                    onChange('OTHER');
                    onCloseAccOwnershipSelect();
                  }}
                  testID="paychexpay.connectBankForm.button.selectAccountOther"
                  accessible
                  accessibilityLabel={'select Other account ownership'}
                >
                  <Text fontSize="$body">Other</Text>
                </TPressable>
              </View>
            )}
          </View>
        )}
      />
      <Controller
        name="achNick"
        control={controlProp || control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <TextInput
            label="Account nickname"
            styles={{ marginBottom: 4 }}
            args={{
              ref: bankAcntNameInputRef,
              onChangeText: onChange,
              onBlur,
              value,
              keyboardType: 'default',
              testID: 'paychexpay.connectBankForm.input.bankAcntNameInput',
              onFocus: () => {
                if (isAccOwnershipSelectOpen || isSelectOpen) closeSelectors();
              },
              accessible: true,
              accessibilityLabel: 'Bank nickname input',
              returnKeyType: 'next',
              blurOnSubmit: true,
            }}
            isError={!!error}
            errorMessage={error?.message}
            isDisabled={isBankAcntNameInputDisabled}
          />
        )}
      />
    </View>
  );
};

export default connectBankForm;
