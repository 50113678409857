import { View } from 'tamagui';
import ScrollArrowBlue from '../../../assets/scrollArrowBlue.svg';
import useIsPhone from '@hooks/useIsPhone';

export interface ScrollToEndButtonProps {
  onPressScrollArrow: () => void;
  isDisabled: boolean;
}

const ScrollToEndButton = ({
  onPressScrollArrow,
  isDisabled,
}: ScrollToEndButtonProps) => {
  const isPhone = useIsPhone();

  return (
    <View
      borderRadius={12}
      borderColor="$gray20"
      borderWidth={1}
      backgroundColor="$blue20"
      w={48}
      h={48}
      position="absolute"
      right={24}
      bottom={isPhone ? 24 : 16}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onPress={onPressScrollArrow}
      testID="paychexpay.scrollToEndButton.button.anchorButton"
      disabled={isDisabled}
      accessible
      accessibilityLabel="Scroll to the end, button"
    >
      {!isDisabled && <ScrollArrowBlue />}
    </View>
  );
};

export default ScrollToEndButton;
