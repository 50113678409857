import { Text } from '@atoms';
import { HeaderMobileTemplate } from '@templates';
import { LoadingScreen } from '@views/SignUp/PaychexLoginView';
import { Dimensions } from 'react-native';
import WebView from 'react-native-webview';
import { XStack } from 'tamagui';

interface FeeScheduleViewProps {
  navigateBack: () => void;
  documentUrl: string;
}

const FeeScheduleView = ({
  navigateBack,
  documentUrl,
}: FeeScheduleViewProps) => {
  const screenWidth = Dimensions.get('window').width;

  return (
    <HeaderMobileTemplate
      header={{
        isBackButton: true,
        onPressBack: navigateBack,
        logo: (
          <XStack width="100%" justifyContent="flex-end" pr="50px" pl="50px">
            <Text
              fontSize={16}
              fontWeight={'600'}
              width="100%"
              textAlign="center"
            >
              Fee schedule
            </Text>
          </XStack>
        ),
      }}
    >
      <WebView
        domStorageEnabled={true}
        source={{ uri: documentUrl }}
        startInLoadingState={true}
        renderLoading={() => <LoadingScreen />}
        scalesPageToFit={true}
        javaScriptEnabled={true}
        allowFileAccess={true}
        style={{
          width: screenWidth,
          flex: 1,
        }}
      />
    </HeaderMobileTemplate>
  );
};

export default FeeScheduleView;
