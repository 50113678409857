export const sideModalNames = {
  signInFlex: 'sign-in-flex',
  ssn: 'ssn',
  dob: 'dob',
  howToUseDigitalCard: 'digital-card',
  aboutPaidEarly: 'about-paid-early',
  setupDda: 'setup-dda',
  ddaForm: 'ddaForm',
  ddaHelp: 'dda-help',
  disputeCharge: 'dispute-charge',
  blockedCard: 'blocked-card',
  cardLost: 'card-lost',
  closeAccount: 'close-account',
  transaction: 'transaction',
  debitCard: 'debit-card',
  markerDetails: 'marker-details',
  customerService: 'customer-service',
  statementsList: 'statements-list',
  orderCardRequest: 'order-card-request',
  orderCardStatus: 'order-card-status',
  addExternalAccount: 'add-external-account',
  deleteExternalAccount: 'delete-external-account',
};
