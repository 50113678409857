const ACCOUNT_NAMES = {
  PaychexPaySpending: 'Spending Account',
  PaychexPaySaving: 'Savings Account',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCOUNT_DETAILS_OPTIONS = {
  Deposit: 'Direct Deposit Account Info',
  ViewCard: 'View card',
  FeeSchedule: 'Fee schedule',
  DisputeCharge: 'Dispute a transaction',
  GetStatement: 'Get statement',
  CloseAccount: (accountName: string) => `Close ${accountName} account`,
};

const ACCOUNT_TEMPLATE_ANIMATION_DURATION = 500;

const FEE_SCHEDULE_URL = 'https://centralaccesscard.com/PaychexPayFeeSchedule';

export { ACCOUNT_NAMES, ACCOUNT_TEMPLATE_ANIMATION_DURATION, FEE_SCHEDULE_URL };
