import { XStack, View } from 'tamagui';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React, { useMemo, useRef, useState } from 'react';
import amountToString from '@utils/amountToString';
import { TabView } from 'react-native-tab-view';
import AccountTabBar from '@molecules/AccountTabBar/AccountTabBar';
import { AccountDetailsProps } from '@organisms/AccountDetails/AccountDetails';
import { AccountTransactionsProps } from '@organisms/AccountTransactions/AccountTransactions';
import { LoaderPaychex, Text } from '@atoms';
import { Platform, StatusBar } from 'react-native';
import BackButton from '@atoms/BackButton/BackButton';
import { customTheme } from '../../../styles/customTheme';
import { MobileWebHeader } from '@molecules';
import { MOBILE_WEB_HEADER_HEIGHT } from '@constants/general';
import { getWindowHeightWeb } from '@utils/getWindowHeightWeb';

interface AccountTemplateProps {
  navigateBack: () => void;
  accountAmount: number;
  isClosedAccount: boolean;
  accountName: string;
  TransactionsElement: React.ReactNode;
  AccountDetailsElement: React.ReactNode;
  accountDetailsElementProps?: AccountDetailsProps;
  transactionsElementProps?: AccountTransactionsProps;
  navigation: any;
  withWebHeader?: boolean;
  loaderOverlay: boolean;
}

const AccountTemplate = ({
  accountName,
  navigateBack,
  accountAmount,
  isClosedAccount,
  TransactionsElement,
  AccountDetailsElement,
  transactionsElementProps,
  accountDetailsElementProps,
  navigation,
  withWebHeader = false,
  loaderOverlay,
}: AccountTemplateProps) => {
  const [tabViewIndex, setTabViewIndex] = useState(
    navigation.getState().routes[0].name === 'SetupDda' ? 1 : 0
  );
  const indexRef = useRef(0);
  const { top: topInset } = useSafeAreaInsets();

  const routes = useMemo(
    () => [
      { index: 0, key: 'first', title: 'Activity' },
      { index: 1, key: 'second', title: 'Account details' },
    ],
    []
  );

  const changeTabIndex = (index: number) => {
    setTabViewIndex(index);
    // indexRef is used to pass info about index to gesture handler
    indexRef.current = index;
  };

  const renderScene = ({
    route,
  }: {
    route: { index: number; key: string; title: string };
  }) => {
    switch (route.key) {
      case 'first':
        return (
          // @ts-ignore
          <TransactionsElement {...transactionsElementProps} />
        );
      case 'second':
        return (
          // @ts-ignore
          <AccountDetailsElement {...accountDetailsElementProps} />
        );
      default:
        return null;
    }
  };

  const TabViewMemoized = () => {
    return (
      <TabView
        onIndexChange={changeTabIndex}
        navigationState={{ index: tabViewIndex, routes }}
        style={{ flexGrow: 1 }}
        renderScene={renderScene}
        renderTabBar={({ navigationState }) => (
          <AccountTabBar
            navigationState={navigationState}
            setIndex={changeTabIndex}
          />
        )}
        swipeEnabled={false}
      />
    );
  };

  return (
    <>
      {Platform.OS === 'web' && withWebHeader && <MobileWebHeader />}
      <View
        display={'flex'}
        flexDirection={'column'}
        backgroundColor="$primaryNormal"
        paddingTop={Platform.OS !== 'web' && withWebHeader ? topInset : 4}
        flexGrow={1}
        {...(Platform.OS === 'web' && {
          maxHeight: getWindowHeightWeb() - MOBILE_WEB_HEADER_HEIGHT,
          overflow: 'hidden',
        })}
      >
        {Platform.OS !== 'web' && withWebHeader && (
          <StatusBar barStyle="light-content" />
        )}
        <XStack paddingHorizontal={9} paddingBottom={9}>
          {withWebHeader && (
            <BackButton
              onPress={navigateBack}
              fill={customTheme.colors.white}
            />
          )}
          <View
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexGrow={1}
            marginRight={24}
          >
            <Text textAlign="center" variant="title" color="white">
              {accountName}
            </Text>
          </View>
          <View />
        </XStack>
        <View
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {isClosedAccount ? (
            <Text fontSize="$h1" fontWeight="700" color="white">
              Closed
            </Text>
          ) : (
            <>
              <Text
                fontSize="$h1"
                lineHeight="$h1"
                fontWeight="700"
                color="white"
              >
                {amountToString(accountAmount)}
              </Text>
              <Text fontSize="$h4" fontWeight="500" color="white" marginTop={6}>
                Available balance
              </Text>
            </>
          )}
        </View>
        {loaderOverlay && (
          <View
            position={'absolute'}
            top={0}
            left={0}
            bottom={0}
            right={0}
            zIndex={35}
            paddingTop={300}
            backgroundColor={'rgba(0, 0, 0 , 0.25)'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            <LoaderPaychex />
          </View>
        )}

        <View
          display={'flex'}
          flexDirection={'column'}
          flexGrow={1}
          paddingTop={16}
        >
          <View
            display={'flex'}
            flexDirection={'column'}
            flexGrow={1}
            backgroundColor="white"
            borderTopLeftRadius={12}
            borderTopRightRadius={12}
          >
            <View
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              paddingTop={2}
              paddingBottom={13}
            >
              <View height={3} />
            </View>
            <View display={'flex'} flexDirection={'column'} flexGrow={1}>
              {TabViewMemoized()}
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

export default AccountTemplate;
