import styled, { keyframes } from 'styled-components';
import LogoIcon from '../../../assets/PaychexLogo.svg';

const SplashScreenWebWeb = () => {
  return (
    <Wrapper>
      <Logo>
        <LogoIcon width={238} />
        <LoadingBar>
          <ProgressLine />
        </LoadingBar>
      </Logo>
    </Wrapper>
  );
};

const isDesktop = window.innerWidth > 420;
const loadingBarHeight = isDesktop ? '6px' : '5px';
const progressAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const LoadingBar = styled.div`
  width: 100%;
  height: ${loadingBarHeight};
  background-color: #4c84ea;
  margin-top: 24px;
  border-radius: 6px;
  overflow: hidden;
`;
const ProgressLine = styled.div`
  width: 100%;
  height: ${loadingBarHeight};
  background-color: #fff;
  border-radius: 6px;
  animation: 0.5s ease-in ${progressAnimation};
`;
const Wrapper = styled.div`
  background-color: #0073c4;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Logo = styled.div`
  width: 238px;
`;

export default SplashScreenWebWeb;
