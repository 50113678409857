import { XStack, View } from 'tamagui';
import { SignInTitleTypes } from '@molecules/SignInTitle/SignInTitle.types';
import useIsPhone from '@hooks/useIsPhone';
import InfoIcon from '../../../assets/infoIconSignIn.svg';
import InfoIconFilled from '../../../assets/InfoIconFilled.svg';
import { Text } from '@atoms';
import { customTheme } from '../../../styles/customTheme';

const SignInTitle = ({ onPressHelp }: SignInTitleTypes) => {
  const isPhone = useIsPhone();
  return (
    <View
      display={'flex'}
      backgroundColor={isPhone ? '$primaryNormal' : 'white'}
      paddingBottom={isPhone ? '3%' : 28}
      paddingHorizontal={'$4'}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      {...(isPhone && {
        paddingTop: '3%',
      })}
    >
      <View display={'flex'} flexDirection={'row'}>
        <Text
          variant={'title'}
          fontWeight={isPhone ? '700' : '600'}
          color={isPhone ? 'white' : 'black'}
          fontSize={isPhone ? '$h1' : '$h2'}
          lineHeight={isPhone ? '$h1' : '$h2'}
          paddingBottom={12}
          accessible
          accessibilityLabel="Welcome to Paychex Pay"
          testID={'paychexpay.signInTitle.welcomeToPaychexPay'}
        >
          Welcome to Paychex Pay
        </Text>
        <Text
          fontWeight={isPhone ? '700' : '600'}
          color={isPhone ? 'white' : 'black'}
          fontSize={isPhone ? 12 : 10}
          marginTop={-3}
        >
          SM
        </Text>
      </View>
      <XStack display={'flex'} alignItems="center">
        <Text
          color={isPhone ? 'white' : '$gray40'}
          fontSize={isPhone ? '$body' : '$h4'}
          accessible
          accessibilityLabel="Sign in with your Paychex Flex® account"
          textAlign={'center'}
        >
          Sign in with your Paychex Flex® account
        </Text>
        <View
          display={'flex'}
          onPress={onPressHelp}
          testID="paychexpay.signInTitle.button.questionMarkButton"
          paddingLeft={'$2'}
          accessible
          accessibilityLabel="Help button"
        >
          {isPhone ? (
            <InfoIcon
              fill={customTheme.colors.white}
              height="20px"
              width="20px"
            />
          ) : (
            <InfoIconFilled />
          )}
        </View>
      </XStack>
    </View>
  );
};

export default SignInTitle;
