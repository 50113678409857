import { View } from 'tamagui';
import { FooterMobileTemplate, WebLogoTemplate } from '@templates';
import { Hint, PaychexPayCard, SideModal } from '@molecules';
import { PAYCHEX_PAY_DEPOSIT_ACCOUNT } from '@constants/policies';
import CardBackgroundIcon from '../../../assets/paychexpayCardBackground.svg';
import useIsPhone from '@hooks/useIsPhone';
import React from 'react';
import { HowToUseCardContent } from '@organisms';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import { Platform, useWindowDimensions } from 'react-native';
import useIsTablet from '@hooks/useIsTablet';
import { maxMediaQuery } from '@constants/ui';
import { cardInfoType } from '@molecules/PaychexPayCard/PaychexPayCard.types';
import { ICardAuthInfo } from '@store/features/cards/types';
import { Button, Text } from '@atoms';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

interface commonProps {
  buttonHandler: () => void;
  hintHandler: () => void;
  cardInfo: cardInfoType;
  cardAuthInfo: ICardAuthInfo;
}

interface WelcomeToPaychexPayMobileView extends commonProps {}

interface WelcomeToPaychexPayDesktopView extends commonProps {
  isModalOpen: boolean;
  toCloseModal: () => void;
  modalName: string | undefined;
}

const WelcomeToPaychexPayViewContent = ({
  buttonHandler,
  cardInfo,
  cardAuthInfo,
  hintHandler,
}: commonProps) => {
  const isPhone = useIsPhone();
  const { top: topOffset } = useSafeAreaInsets();

  return (
    <View
      display={'flex'}
      flexDirection={'column'}
      flexGrow={1}
      flex={1}
      bg="white"
      px="$6"
      pt={isPhone ? (Platform.OS === 'web' ? '$4' : topOffset + 25) : '$6'}
      pb={isPhone ? (Platform.OS === 'web' ? 0 : '$2') : '$4'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <View
        display={'flex'}
        flexShrink={1}
        flexGrow={1}
        maxHeight={110}
        height={!isPhone ? '100%' : 'auto'}
        justifyContent="flex-end"
        flexDirection={'column'}
      >
        <Text
          fontSize="$h2"
          fontWeight="$semiBold"
          textAlign="center"
          pb={'$3'}
        >
          Congratulations!
        </Text>
        <Text
          variant="subtitle"
          textAlign="center"
          maxWidth={isPhone ? '80%' : '325px'}
          alignSelf="center"
          color={'$gray50'}
        >
          Your account is ready to use once you add funds.
        </Text>
      </View>

      <View
        display={'flex'}
        flex={Platform.OS === 'web' ? 1 : 0}
        flexGrow={1}
        flexShrink={1}
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
      >
        <View
          display={'flex'}
          flexGrow={1}
          flex={Platform.OS === 'web' ? 1 : 0}
          flexShrink={1}
          alignItems="center"
          justifyContent="center"
          width="100%"
          testID={'paychexpay.welcomeToPaychexPayView.cardImageContainer'}
        >
          {isPhone ? (
            <PaychexPayCard
              cardInfo={cardInfo}
              cardAuthInfo={cardAuthInfo}
              hideNumbers
            />
          ) : (
            <View
              display={'flex'}
              flexDirection={'column'}
              position="relative"
              flexShrink={1}
              justifyContent="center"
            >
              <View
                testID={'paychexpay.welcomeToPaychexPayView.cardBackgroundIcon'}
              >
                <CardBackgroundIcon />
              </View>
              <View
                display={'flex'}
                flexDirection={'column'}
                position="absolute"
                zIndex={2}
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
              >
                <PaychexPayCard
                  cardInfo={cardInfo}
                  cardAuthInfo={cardAuthInfo}
                  hideNumbers
                />
              </View>
            </View>
          )}
        </View>
        <View
          display={'flex'}
          flexGrow={1}
          flexShrink={1}
          alignItems="center"
          justifyContent="center"
          flexDirection={'column'}
          width="100%"
        >
          <View
            display={'flex'}
            flexGrow={Platform.OS === 'web' ? 1 : 0}
            flexDirection={'column'}
            justifyContent="flex-end"
            alignItems="center"
          >
            <View maxWidth={isPhone ? '100%' : '325px'} pb={'$6'}>
              <Text
                textAlign="center"
                color="$black"
                fontWeight={'700'}
                marginBottom={20}
              >
                Some quick need-to-knows:
              </Text>
              <Text textAlign="center" fontSize={'$h5'} color="$black">
                Your card is on the way.
              </Text>
              <Text textAlign="center" fontSize={'$h5'} color="$black">
                It should arrive in 7-10 business days.
              </Text>
            </View>

            {!isPhone && (
              <View
                width="440px"
                alignItems="center"
                flexGrow={isPhone ? 0 : 1}
                justifyContent="flex-end"
              >
                <Hint
                  hintHandler={hintHandler}
                  hint="Here’s how to get started"
                  description="You can use your account online meanwhile."
                />
              </View>
            )}

            {!isPhone && (
              <View
                flexGrow={1}
                maxWidth={isPhone ? '100%' : '325px'}
                width="100%"
                justifyContent="center"
              >
                <Button
                  variant="primary"
                  onPress={buttonHandler}
                  justifySelf="center"
                  mt={'3'}
                  testID="paychexpay.desktop.welcomeToPaychexPayView.button.gotItButton"
                >
                  Got it!
                </Button>
              </View>
            )}
          </View>
          {isPhone && (
            <View
              display={'flex'}
              flexGrow={1}
              flex={1}
              justifyContent="flex-start"
              flexDirection={'column'}
              width={isPhone ? '100%' : '440px'}
              alignItems="center"
            >
              <Hint
                hintHandler={hintHandler}
                hint="Here’s how to get started"
                description="You can use your account online meanwhile."
              />
            </View>
          )}
        </View>
      </View>
      <View
        display={'flex'}
        width={isPhone ? '100%' : '480px'}
        flex={0}
        flexGrow={0}
        flexShrink={0}
        alignItems="center"
        alignSelf={'center'}
        justifyContent="flex-end"
        paddingHorizontal={'$4'}
        paddingVertical={'$2'}
      >
        <Text
          color="$gray50"
          fontSize={10}
          fontWeight={'500'}
          lineHeight={12}
          textAlign="center"
        >
          {PAYCHEX_PAY_DEPOSIT_ACCOUNT}
        </Text>
      </View>
    </View>
  );
};

const desktop = ({
  buttonHandler,
  cardInfo,
  cardAuthInfo,
  hintHandler,
  isModalOpen,
  toCloseModal,
  modalName,
}: WelcomeToPaychexPayDesktopView) => {
  const { width: desktopWidth } = useWindowDimensions();
  const isTablet = useIsTablet();

  return (
    <WebLogoTemplate bg="$blue10" withLogoIcon={!isTablet}>
      <View
        display={'flex'}
        maxWidth={900}
        maxHeight={740}
        bg="white"
        flexGrow={1}
        my="5"
        overflow="hidden"
        borderRadius={12}
        width="100%"
        height="100%"
        {...(desktopWidth < 1265 &&
          desktopWidth > 915 && {
            maxWidth: desktopWidth - maxMediaQuery.tablet / 2,
            width: '100%',
          })}
        {...(desktopWidth < 915 && {
          maxWidth: '95%',
          width: '100%',
        })}
      >
        <View
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          flexGrow={1}
        >
          <WelcomeToPaychexPayViewContent
            hintHandler={hintHandler}
            buttonHandler={buttonHandler}
            cardInfo={cardInfo}
            cardAuthInfo={cardAuthInfo}
          />
        </View>
      </View>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.howToUseDigitalCard]: <HowToUseCardContent />,
          }}
        />
      </SideModal>
    </WebLogoTemplate>
  );
};

const mobile = ({
  buttonHandler,
  cardInfo,
  cardAuthInfo,
  hintHandler,
}: WelcomeToPaychexPayMobileView) => {
  return (
    <FooterMobileTemplate.withOneButton
      buttonHandler={buttonHandler}
      buttonLabel="Got it"
      testID="paychexpay.mobile.welcomeToPaychexPayView.button.gotItButton"
    >
      <WelcomeToPaychexPayViewContent
        hintHandler={hintHandler}
        buttonHandler={buttonHandler}
        cardInfo={cardInfo}
        cardAuthInfo={cardAuthInfo}
      />
    </FooterMobileTemplate.withOneButton>
  );
};

const WelcomeToPaychexPayView = {
  mobile,
  desktop,
};

export default WelcomeToPaychexPayView;
