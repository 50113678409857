import { SideBarTemplate } from '@templates';
import { TButton, Text } from '@atoms';
import AccountCard from '@molecules/AccountCard/AccountCard';
import amountToString from '@utils/amountToString';
import { Center } from 'native-base';
import {
  CenterModal,
  TextInput,
  InsufficientFundsAlertModalProps,
  InsufficientFundsAlertModal,
} from '@molecules';
import { IAccountPreview } from '@devTypes/accountTypes';
import AccountIcon from '../../../assets/accounts/paychexpayAccountIcon.svg';
import useIsLaptop from '@hooks/useIsLaptop';
import {
  IBeneficiaryInput,
  P2P_TRANSFER_OPTIONS,
  transfersScreensInfo,
  transfersScreensNames,
} from '@constants/transfersScreensNames';
import React, { useRef } from 'react';
import { formatTransferInput } from '@utils/validateDdaInputs';
import { MAX_TRANSFER_COMMENT_LENGTH } from '@constants/general';
import { requestStatusType } from '@store/features/cards/types';
import {
  RadioGroup,
  ScrollView,
  Separator,
  View,
  XStack,
  YStack,
} from 'tamagui';
import { TransferCompletedContent } from '@organisms';
import usePhoneNumberInputCursorController from '@hooks/usePhoneNumberInputCursorController';
import RadioGroupItemWithLabel from '@molecules/RadioButtonWithLabel/RadioButtonWithLabel';
import { IVariable } from '@store/features/general/types';

interface ToOtherFriendsViewProps extends InsufficientFundsAlertModalProps {
  benInfo: IBeneficiaryInput;
  onChangeBeneficiaryInfoFn: (text: string) => void;
  onChangeBeneficiaryInfoLn: (text: string) => void;
  onChangeBeneficiaryInfoPhone: (text: string) => void;
  onChangeBenInfoEmail: (text: string) => void;
  account: IAccountPreview;
  isErrorInputLn: boolean;
  isErrorInputFn: boolean;
  isErrorInputEmail: boolean;
  isErrorInputPhone: boolean;
  isButtonDisabled: boolean;
  isNotExceedBalance: boolean;
  isCenterModalOpen: boolean;
  amount: string;
  setAmount: (s: string) => void;
  comment: string;
  setComment: (comment: string) => void;
  onPressTransfer: () => void;
  onCloseCenterModal: () => void;
  transferStatus: requestStatusType | null;
  isCompleted: boolean;
  onRadioButtonSelect: (option: string) => void;
  selectedMethod: string;
  variableTransferFee: IVariable | undefined;
}

const ToOtherFriendsWebView = ({
  amount,
  comment,
  isButtonDisabled,
  isNotExceedBalance,
  benInfo: { benEmail, benFirstName, benLastName, benPhone },
  onChangeBeneficiaryInfoFn,
  onChangeBeneficiaryInfoLn,
  onChangeBeneficiaryInfoPhone,
  onChangeBenInfoEmail,
  account,
  isErrorInputLn,
  isErrorInputFn,
  isErrorInputEmail,
  isErrorInputPhone,
  setAmount,
  setComment,
  onPressTransfer,
  transferStatus,
  isCenterModalOpen,
  onCloseCenterModal,
  isCompleted,
  onRadioButtonSelect,
  selectedMethod,
  isInsufficientFundsAlertVisible,
  hideInsufficientFundsAlertModal,
  variableTransferFee,
}: ToOtherFriendsViewProps) => {
  const {
    selection,
    saveLastCursorPosition,
    phoneNumberInputHandlerForTransfers,
  } = usePhoneNumberInputCursorController({});
  const isLaptop = useIsLaptop();
  const amountInputRef = useRef(null);
  const commentInputRef = useRef(null);
  const { amount: limitToTransfer } = account;
  return (
    <>
      <View flexGrow={1}>
        <SideBarTemplate disableScroll>
          <View bg="none" height={'100%'} flexGrow={1}>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: 'center',
                pb: isLaptop ? 0 : '$5',
              }}
            >
              <Center py={isLaptop ? 0 : 7}>
                <YStack
                  flexGrow={1}
                  pt={'$5'}
                  pb={'$4'}
                  p={20}
                  borderRadius="$2"
                  bg="white"
                  w={460}
                  justifyContent="space-between"
                  h="100%"
                >
                  <YStack
                    flexGrow={1}
                    px={'$4'}
                    pt={'$5'}
                    pb={'$4'}
                    justifyContent="space-between"
                  >
                    <View>
                      <Text
                        variant="title"
                        textAlign="center"
                        pb={isLaptop ? '$2' : '$6'}
                      >
                        {
                          //TODO: do we need it on desktop only?
                          transfersScreensInfo[
                            transfersScreensNames.toOtherFriends
                          ].title
                        }
                      </Text>
                      <AccountCard
                        icon={<AccountIcon />}
                        title={account.name}
                        label="From"
                        amount={amountToString(account.amount)}
                      />
                      <Text
                        fontSize={'$h4'}
                        color={'$gray40'}
                        fontWeight={'500'}
                        paddingVertical={12}
                      >
                        To
                      </Text>
                      <XStack gap={16} flex={1}>
                        <TextInput
                          styles={{ flex: 1 }}
                          label="First name"
                          args={{
                            value: benFirstName,
                            onChangeText: onChangeBeneficiaryInfoFn,
                          }}
                          errorMessage="Include at least 2 characters"
                          isError={isErrorInputFn}
                        />
                        <TextInput
                          styles={{ flex: 1 }}
                          label="Last name"
                          args={{
                            value: benLastName,
                            onChangeText: onChangeBeneficiaryInfoLn,
                          }}
                          errorMessage="Include at least 2 characters"
                          isError={isErrorInputLn}
                        />
                      </XStack>
                      <Text
                        fontSize={'$h4'}
                        color={'$gray40'}
                        fontWeight={'500'}
                        pt={'$4'}
                      >
                        Choose method
                      </Text>
                      <XStack gap={12} flex={1} marginVertical={12}>
                        <RadioGroup
                          onValueChange={(value) => {
                            onRadioButtonSelect(value);
                          }}
                          defaultValue="Phone number"
                          accessibilityLabel="options"
                          name="form"
                        >
                          <YStack gap={'$4'} alignItems="flex-start">
                            <RadioGroupItemWithLabel
                              size="$7"
                              label={P2P_TRANSFER_OPTIONS[0].label}
                              value={P2P_TRANSFER_OPTIONS[0].value}
                              testID={
                                'paychexpay.mobile.ToOtherFriendsView.radioButton.email'
                              }
                            />
                            <RadioGroupItemWithLabel
                              size="$7"
                              label={P2P_TRANSFER_OPTIONS[1].label}
                              value={P2P_TRANSFER_OPTIONS[1].value}
                              testID={
                                'paychexpay.mobile.ToOtherFriendsView.radioButton.phoneNumber'
                              }
                            />
                          </YStack>
                        </RadioGroup>
                      </XStack>
                      <YStack>
                        {selectedMethod === 'Email address' ? (
                          <TextInput
                            styles={{ flex: 1 }}
                            placeholder="Email"
                            args={{
                              value: benEmail,
                              onChangeText: onChangeBenInfoEmail,
                              testID:
                                'paychexpay.transfer.toOtherFriendsWebView.input.email',
                            }}
                            errorMessage="Use this format: example@gmail.com"
                            isError={isErrorInputEmail}
                          />
                        ) : (
                          <TextInput
                            styles={{ flex: 1 }}
                            placeholder="U.S. mobile phone number"
                            args={{
                              value: benPhone,
                              // onChangeText: onChangeBeneficiaryInfoPhone,
                              onChangeText: (newValue: string) => {
                                phoneNumberInputHandlerForTransfers(
                                  newValue,
                                  benPhone,
                                  onChangeBeneficiaryInfoPhone
                                );
                              },
                              selection: selection,
                              onSelectionChange: ({
                                nativeEvent: {
                                  selection: { start },
                                },
                              }: any) => saveLastCursorPosition({ start }),
                              maxLength: 14,
                              testID:
                                'paychexpay.transfer.toOtherFriendsWebView.input.phone',
                            }}
                            errorMessage="Use this format: X XXX-XXX-XXXX"
                            isError={isErrorInputPhone}
                          />
                        )}
                      </YStack>
                    </View>
                    <TextInput
                      customLabel={
                        <Text
                          color="$gray40"
                          lineHeight="$h2"
                          fontSize="$h5"
                          mb={'$1'}
                          pt={'$4'}
                        >
                          Available to transfer: ${limitToTransfer.toFixed(2)}
                        </Text>
                      }
                      errorMessage={`You can transfer up to $${limitToTransfer.toFixed(
                        2
                      )}`}
                      isError={isNotExceedBalance && limitToTransfer > 0}
                      isDisabled={limitToTransfer === 0}
                      placeholder="$0.00"
                      args={{
                        ref: amountInputRef,
                        value: amount !== '' ? amount : '',
                        onChangeText: (text: string) => {
                          const validatedValue = formatTransferInput(text);
                          setAmount(validatedValue);
                        },
                        testID:
                          'paychexpay.transfer.toOtherFriendsWebView.input.amount',
                        onSubmitEditing: () => {
                          // @ts-ignore
                          commentInputRef.current?.focus();
                        },
                      }}
                    />
                    <TextInput
                      styles={{ paddingTop: 12, paddingBottom: 10 }}
                      label="Note"
                      placeholder="What’s this for?"
                      isError={comment.length > MAX_TRANSFER_COMMENT_LENGTH}
                      errorMessage={`Should contain no more than ${MAX_TRANSFER_COMMENT_LENGTH} digits`}
                      args={{
                        ref: commentInputRef,
                        value: comment,
                        onChangeText: (text: string) => setComment(text),
                        testID:
                          'paychexpay.transfer.toOtherFriendsWebView.input.description',
                        onSubmitEditing: () => {
                          if (!isButtonDisabled) {
                            onPressTransfer();
                          }
                        },
                      }}
                    />
                    <View>
                      <Separator borderColor="$gray20" my={'$2'} />
                      <YStack>
                        <Text
                          flex={1}
                          textAlign="center"
                          paddingHorizontal={12}
                          paddingBottom={12}
                          color="$gray40"
                          fontSize={'$h5'}
                          fontWeight={'400'}
                        >
                          A ${variableTransferFee?.value ?? ''} fee applies.{' '}
                          {'\n'} Transaction approvals depend on the applicable
                          payment network and receiving financial network.{' '}
                          {'\n'}Actual posting times depend on the receiving{' '}
                          {'\n'}financial institution.
                        </Text>
                      </YStack>

                      <TButton
                        disabled={isButtonDisabled}
                        onPress={onPressTransfer}
                        isLoading={transferStatus === 'pending'}
                        testID={
                          'paychexpay.transfer.toOtherFriendsWebView.button.transfer'
                        }
                      >
                        Transfer
                      </TButton>
                    </View>
                  </YStack>
                </YStack>
              </Center>
            </ScrollView>
          </View>
        </SideBarTemplate>
        {isCompleted && (
          <CenterModal
            isModalOpen={isCenterModalOpen}
            closeByOutsideClick
            closeModal={onCloseCenterModal}
          >
            <View w="343px" p={'$4'} bg="white" borderRadius={24}>
              <TransferCompletedContent
                transferType={transfersScreensNames.toOtherFriends}
              />
              <Separator borderColor="$gray20" mb={'$4'} mt={'$5'} />
              <TButton
                onPress={onCloseCenterModal}
                testID={
                  'paychexpay.transfer.toOtherFriendsWebView.button.transferCompletedOk'
                }
              >
                Finish
              </TButton>
            </View>
          </CenterModal>
        )}
      </View>
      <InsufficientFundsAlertModal
        isInsufficientFundsAlertVisible={isInsufficientFundsAlertVisible}
        hideInsufficientFundsAlertModal={hideInsufficientFundsAlertModal}
      />
    </>
  );
};

export default ToOtherFriendsWebView;
