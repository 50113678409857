import { OrderNewCardStatusContent } from '@organisms';
import { HeaderAndFooterMobileTemplate } from '@templates';
import { Button } from '@atoms';
import { ICardInfo, IShipmentInfo } from '@store/features/cards/types';
import React, { ReactNode } from 'react';
import { CopyToClipboardType } from '@devTypes/accounts';
import { Platform } from 'react-native';
import { MobileWebHeader } from '@molecules';

interface OrderNewCardStatusViewProps {
  navigateToCardPage: () => void;
  orderStatus: 'success' | 'error';
  physicalCardStatus?: ICardInfo['physical_card_status'];
  shipmentInfo?: IShipmentInfo;
  copyToClipboard: CopyToClipboardType;
  ToastPopup?: ReactNode;
}

const OrderNewCardStatusView = ({
  navigateToCardPage,
  orderStatus,
  physicalCardStatus,
  shipmentInfo,
  copyToClipboard,
  ToastPopup,
}: OrderNewCardStatusViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}
      {ToastPopup}
      <HeaderAndFooterMobileTemplate
        footerContent={<Button onPress={navigateToCardPage}>Got it</Button>}
        header={{
          isBackButton: true,
          onPressBack: navigateToCardPage,
          title:
            physicalCardStatus === 'ORDERED'
              ? 'Card shipping status'
              : 'Order a new card',
        }}
      >
        <OrderNewCardStatusContent
          orderStatus={orderStatus}
          shipmentInfo={shipmentInfo}
          copyToClipboard={copyToClipboard}
        />
      </HeaderAndFooterMobileTemplate>
    </>
  );
};

export default OrderNewCardStatusView;
