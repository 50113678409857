import { createStackNavigator } from '@react-navigation/stack';
import {
  CIPMessagePage,
  OutWalletQuestionsPage,
  VerificationFailedPage,
} from '@pages/CIP';
import { CIPNavigatorParamsList } from './CIPNavigator.types';
import { DEFAULT_SCREEN_OPTIONS } from '@constants/navigation';
import routesToTitlesMap from '../routesToTitlesMap';

const Stack = createStackNavigator<CIPNavigatorParamsList>();

const CIPNavigator = () => {
  return (
    <Stack.Navigator
      initialRouteName="CIPMessagePage"
      screenOptions={{ ...DEFAULT_SCREEN_OPTIONS, gestureEnabled: false }}
    >
      <Stack.Screen
        name="CIPMessagePage"
        component={CIPMessagePage}
        options={{
          title: routesToTitlesMap.CIPMessagePage,
        }}
      />
      <Stack.Screen
        name="OutWalletQuestionsPage"
        component={OutWalletQuestionsPage}
        options={{
          title: routesToTitlesMap.OutWalletQuestionsPage,
        }}
      />
      <Stack.Screen
        name="VerificationFailedPage"
        component={VerificationFailedPage}
        options={{
          title: routesToTitlesMap.VerificationFailedPage,
        }}
      />
    </Stack.Navigator>
  );
};

export default CIPNavigator;
