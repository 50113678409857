import React, { useCallback, useState } from 'react';
import { OnboardingSlide } from '@organisms';
import ONBOARDING_SLIDES from '@constants/onboardingSlides';
import { Pagination } from '@molecules';
import { SceneMap, TabView } from 'react-native-tab-view';
import useIsPhone from '@hooks/useIsPhone';
import { Text } from '@atoms';
import { Platform } from 'react-native';

export interface OnboardingSwiperProps {
  index: number;
  setIndex: (index: number) => void;
}

const OnboardingSwiper = ({ index, setIndex }: OnboardingSwiperProps) => {
  const isPhone = useIsPhone();
  const [routes] = useState([
    { key: 'first', title: 'GetPaidEarly', index: 0 },
    { key: 'second', title: 'GradCash', index: 1 },
    { key: 'third', title: 'EnjoyMastercard', index: 2 },
  ]);
  const First = useCallback(
    () => (
      <>
        <OnboardingSlide
          {...ONBOARDING_SLIDES.getPaidEarly}
          index={1}
          subtitle={
            <>
              Set up direct deposit with your Paychex Pay Account in{' '}
              <Text
                variant={'subtitle'}
                fontWeight={'700'}
                display={Platform.OS === 'web' ? 'inline' : 'flex'}
              >
                Account Details.
              </Text>
            </>
          }
        />
      </>
    ),
    []
  );
  const Second = useCallback(
    () => (
      <OnboardingSlide
        {...ONBOARDING_SLIDES.gradCash}
        index={2}
        hint={
          <>
            Explore nearby ATMs in the{' '}
            <Text
              fontSize={14}
              fontWeight={'700'}
              display={Platform.OS === 'web' ? 'inline' : 'flex'}
            >
              {Platform.OS === 'web' ? 'ATM finder' : 'More'}
            </Text>{' '}
            section at any time.
          </>
        }
      />
    ),
    []
  );
  const Third = useCallback(
    () => (
      <OnboardingSlide
        {...ONBOARDING_SLIDES.enjoyMastercard}
        index={3}
        hint={
          <>
            Learn about benefits in the{' '}
            <Text
              fontSize={14}
              fontWeight={'700'}
              display={Platform.OS === 'web' ? 'inline' : 'flex'}
            >
              {Platform.OS === 'web' ? 'Mastercard features' : 'More'}
            </Text>{' '}
            section at any time.
          </>
        }
      />
    ),
    []
  );
  return (
    <>
      <TabView
        navigationState={{ index, routes }}
        onIndexChange={setIndex}
        renderTabBar={() => {
          return <Pagination navigationState={{ index, routes }} />;
        }}
        tabBarPosition="bottom"
        swipeEnabled={isPhone}
        renderScene={SceneMap({
          first: First,
          second: Second,
          third: Third,
        })}
      />
    </>
  );
};

export default OnboardingSwiper;
