import Default from '../assets/transactions/Default.svg';
import Auto from '../assets/transactions/Auto & Transport.svg';
import Retail from '../assets/transactions/Retail outlet services.svg';
import Bills from '../assets/transactions/Bills & Utilities.svg';
import Business from '../assets/transactions/Business Services.svg';
import Entertainment from '../assets/transactions/Entertainment.svg';
import Health from '../assets/transactions/Health & Fitness.svg';
import Home from '../assets/transactions/Home.svg';
import Income from '../assets/transactions/Income.svg';
import Investments from '../assets/transactions/Investments.svg';
import Fees from '../assets/transactions/Fees & Charges.svg';
import Kids from '../assets/transactions/Kids.svg';
import Personal from '../assets/transactions/Personal Care.svg';
import Transfer from '../assets/transactions/Transfer.svg';
import Pending from '../assets/transactions/Pending.svg';
import Declined from '../assets/transactions/Declined.svg';
import { ITransaction } from '@store/features/transactions/types';
import {
  transactionToIsIncome,
  transactionToIsMerchant,
} from '@utils/transactionsHelper';

export const transactionsToIcon = (
  transaction: ITransaction,
  isLarge = false
) => {
  const size = isLarge
    ? { height: '56px', width: '56px' }
    : { height: '40px', width: '40px' };

  if (transaction.state !== 'COMPLETE') {
    if (transaction.state === 'PENDING' || transaction.state === 'INPROGRESS') {
      return <Pending {...size} />;
    } else {
      return <Declined {...size} />;
    }
  } else {
    if (!transactionToIsMerchant(transaction)) {
      if (transactionToIsIncome(transaction)) {
        return <Income {...size} />;
      }
      if (transaction.transaction_type.toLowerCase().includes('fee')) {
        return <Fees {...size} />;
      }
      return <Transfer {...size} fill={'#B2BAC5'} />;
    } else {
      const MCC = transaction.merchant?.mcc || 0;

      if (transaction.transaction_type.toLowerCase().includes('fee')) {
        return <Fees {...size} />;
      } else {
        if (MCC >= 1 && MCC <= 1499) {
          // 0001–1499: Agricultural services
          return <Personal {...size} />;
        } else if (MCC >= 1500 && MCC <= 2999) {
          // 1500–2999: Contracted services
          return <Bills {...size} />;
        } else if (MCC >= 4000 && MCC <= 4799) {
          // 4000–4799: Transportation services
          return <Auto {...size} />;
        } else if (MCC >= 4800 && MCC <= 4999) {
          // 4800–4999: Utility services
          return <Home {...size} />;
        } else if (MCC >= 5000 && MCC <= 5599) {
          // 5000–5599: Retail outlet services
          return <Retail {...size} />;
        } else if (MCC >= 5600 && MCC <= 5699) {
          // 5600–5699: Clothing stores
          return <Health {...size} />;
        } else if (MCC >= 5700 && MCC <= 7299) {
          // 5700–7299: Miscellaneous stores
          return <Kids {...size} />;
        } else if (MCC >= 7300 && MCC <= 7999) {
          // 7300–7999: Business services
          return <Business {...size} />;
        } else if (MCC >= 8000 && MCC <= 8999) {
          // 8000–8999: Professional services and membership organizations
          return <Entertainment {...size} />;
        } else if (MCC >= 9000 && MCC <= 9999) {
          // 9000–9999: Government services
          return <Investments {...size} />;
        } else {
          return <Default {...size} />;
        }
      }
    }
  }
};
