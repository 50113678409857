import React from 'react';
import { HeaderMobileTemplate } from '@templates';
import { Box, Center, Spinner } from 'native-base';

const MobileDepositView = () => {
  return (
    <HeaderMobileTemplate
      header={{
        isBackButton: false,
        title: 'Check Deposit',
      }}
    >
      <Box bgColor="white" safeAreaBottom={3} flexGrow={1}>
        <Center flex={1}>
          <Spinner color={'primary.normal'} />
        </Center>
      </Box>
    </HeaderMobileTemplate>
  );
};

export default MobileDepositView;
