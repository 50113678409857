import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const atmLocationsSelector = (state: RootState) => state.atmLocations;

export const selectMarkers = createSelector(
  atmLocationsSelector,
  (state) => state.markers
);

export const selectIsMarkersPending = createSelector(
  atmLocationsSelector,
  (state) => state.isPending
);
