export const NOTIFICATIONS_GROUPS = [
  {
    title: 'Transfers',
    notificationKeysIncluded: [
      'paychexbank.bank_transfer.success',
      'paychexbank.bank_transfer.return',
    ],
  },
  {
    title: 'Failed transactions',
    notificationKeysIncluded: ['paychexbank.trans.auth.failed'],
  },
  {
    title: 'Purchases over $750',
    notificationKeysIncluded: ['paychexbank.trans.large_purchase'],
  },
  {
    title: 'Withdrawals over $250',
    notificationKeysIncluded: ['paychexbank.trans.large_atm_withdrawal'],
  },
  {
    title: 'Balance under $25',
    notificationKeysIncluded: ['paychexbank.trans.low_balance'],
  },
  {
    title: 'PIN changes',
    notificationKeysIncluded: ['paychexbank.card.pin.changed'],
  },
  {
    title: 'Card closures',
    notificationKeysIncluded: ['paychexbank.card.status.closed'],
  },
  {
    title: 'Card blocks and freezes',
    notificationKeysIncluded: [
      'paychexbank.card.status.fraud_blocked',
      'paychexbank.card.status.blocked',
      'paychexbank.card.status.unblocked',
    ],
  },
  {
    title: 'Cash back rewards',
    notificationKeysIncluded: ['paychexbank.reward.payout_completed'],
  },
];

export const STATIC_NOTIFICATION_GROUPS = [
  {
    title: 'Username requests',
    notificationKeysIncluded: [],
  },
  {
    title: 'Username changes',
    notificationKeysIncluded: [],
  },
  {
    title: 'Password resets ',
    notificationKeysIncluded: [],
  },
];
