import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { customTheme } from '../../../styles/customTheme';
import React from 'react';
import { Separator, View, XStack, YStack } from 'tamagui';

const TileSkeleton = () => {
  return (
    <SkeletonTheme
      baseColor={customTheme.colors.blue['20']}
      highlightColor={customTheme.colors.blue['10']}
    >
      <View
        p={16}
        bg="white"
        borderRadius={12}
        borderWidth={1}
        borderColor="$gray20"
      >
        <XStack justifyContent="space-between" space={3}>
          <Skeleton height={48} width={48} borderRadius={4} />
          <YStack flexGrow={1} space={2}>
            <Skeleton height={16} width="75%" borderRadius={4} />
            <Skeleton height={16} borderRadius={4} />
            <Skeleton height={16} width="60%" borderRadius={4} />
          </YStack>
        </XStack>
        <Separator mt="20px" mb={4} borderColor="$gray20" />
        <XStack justifyContent="space-around" alignItems={'center'} space={3}>
          <Skeleton height={20} width={120} borderRadius={4} />
          <Separator bg="$gray20" vertical h="32px" borderColor="$gray20" />
          <Skeleton height={20} width={120} borderRadius={4} />
        </XStack>
      </View>
    </SkeletonTheme>
  );
};

export default TileSkeleton;
