import { TextInput } from '@molecules';
import { Control, Controller, FieldErrors, useForm } from 'react-hook-form';
import {
  IFillInDataFirstFormInputs,
  phoneOnChange,
} from '@organisms/FillInDataFirstForm/helper';
import { useSelector } from 'react-redux';
import {
  selectIsFirstNameLocked,
  selectIsLastNameLocked,
} from '@store/features/fillInData/selectors';
import React, { useEffect, useRef, useState } from 'react';
import usePhoneNumberInputCursorController from '@hooks/usePhoneNumberInputCursorController';
import useIsPhone from '@hooks/useIsPhone';
import { accessibleHitSlop } from '@constants/ui';
import EyeHideIcon from '../../../assets/eyeHide.svg';
import EyeShowIcon from '../../../assets/eyeShow.svg';
import { View, XStack } from 'tamagui';
import { TPressable } from '@atoms';

interface FillInDataFirstFormProps {
  control?: Control<IFillInDataFirstFormInputs>;
  onSubmit: () => void;
  errors: FieldErrors;
  isStateReady: boolean;
}

const FillInDataFirstForm = ({
  control: controlProp,
  onSubmit,
  errors,
  isStateReady,
}: FillInDataFirstFormProps) => {
  const { control } = useForm<IFillInDataFirstFormInputs>();
  const isPhone = useIsPhone();
  const { selection, saveLastCursorPosition, phoneNumberInputHandler } =
    usePhoneNumberInputCursorController({});
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const phoneHideHandler = () => {
    setIsPhoneVisible(!isPhoneVisible);
  };

  const isFirstNameDisabled = useSelector(selectIsFirstNameLocked);
  const isLastNameDisabled = useSelector(selectIsLastNameLocked);
  const firstNameInputRef = useRef(null);
  const lastNameInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  const personalEmailInputRef = useRef(null);

  useEffect(() => {
    if (!isFirstNameDisabled) {
      // @ts-ignore
      firstNameInputRef.current?.focus();
    } else if (!isLastNameDisabled) {
      // @ts-ignore
      lastNameInputRef.current?.focus();
    } else {
      // @ts-ignore
      phoneNumberInputRef.current?.focus();
    }
  }, []);

  return (
    <View accessible={false} accessibilityLabel="Fill In Data Form">
      <XStack mb={'$4'} justifyContent="space-between">
        <Controller
          name="firstName"
          control={controlProp || control}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <TextInput
              label="First name"
              placeholder="First name"
              styles={{ width: '50%', paddingRight: '$2' }}
              args={{
                ref: firstNameInputRef,
                onChangeText: onChange,
                onBlur,
                value,
                returnKeyType: 'next',
                onSubmitEditing: () => {
                  if (isLastNameDisabled) {
                    // @ts-ignore
                    phoneNumberInputRef.current?.focus();
                  } else {
                    // @ts-ignore
                    lastNameInputRef.current?.focus();
                  }
                },
                blurOnSubmit: false,
                testID: 'paychexpay.fillInDataFirstForm.input.firstNameInput',
                accessible: true,
                accessibilityLabel: 'Enter First name',
              }}
              isError={!!error}
              errorMessage={error?.message}
              isDisabled={isFirstNameDisabled}
            />
          )}
        />
        <Controller
          name="lastName"
          control={controlProp || control}
          render={({
            field: { onChange, onBlur, value },
            fieldState: { error },
          }) => (
            <TextInput
              label="Last name"
              placeholder="Last name"
              styles={{ width: '50%', paddingLeft: '$2' }}
              args={{
                ref: lastNameInputRef,
                onChangeText: onChange,
                onBlur,
                value,
                returnKeyType: 'next',
                onSubmitEditing: () => {
                  // @ts-ignore
                  phoneNumberInputRef.current?.focus();
                },
                blurOnSubmit: false,
                testID: 'paychexpay.fillInDataFirstForm.input.lastNameInput',
                accessible: true,
                accessibilityLabel: 'Enter First name',
              }}
              isError={!!error}
              errorMessage={error?.message}
              isDisabled={isLastNameDisabled}
            />
          )}
        />
      </XStack>
      <Controller
        name="phoneNumber"
        control={controlProp || control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <>
            {isPhone ? (
              <TextInput
                label="Mobile phone"
                styles={{ mb: '$4' }}
                args={{
                  ref: phoneNumberInputRef,
                  onChangeText: (value: string) => {
                    onChange(phoneOnChange(value)); //
                  },
                  onBlur,
                  value,
                  keyboardType: 'number-pad',
                  returnKeyType: 'next',
                  onSubmitEditing: () => {
                    // @ts-ignore
                    personalEmailInputRef.current?.focus();
                  },
                  blurOnSubmit: false,
                  testID:
                    'paychexpay.fillInDataFirstForm.input.phoneNumberInput',
                  secureTextEntry: !isPhoneVisible,
                  accessible: true,
                  accessibilityLabel: 'Enter Mobile phone',
                }}
                isError={!!error}
                errorMessage={error?.message}
                InputRightElement={
                  <TPressable
                    onPress={phoneHideHandler}
                    p={'$3'}
                    accessible
                    testID="paychexpay.fillInDataFirstForm.button.hidePhone"
                    accessibilityLabel={`${
                      isPhoneVisible
                        ? 'Hide Mobile phone, button'
                        : 'Show Mobile phone, button'
                    }`}
                  >
                    {isPhoneVisible ? <EyeShowIcon /> : <EyeHideIcon />}
                  </TPressable>
                }
              />
            ) : (
              <TextInput
                label="Mobile phone"
                styles={{ mb: '$4' }}
                args={{
                  ref: phoneNumberInputRef,
                  onChangeText: (newValue: any) => {
                    phoneNumberInputHandler(newValue, value, onChange);
                  },
                  selection: selection,
                  onSelectionChange: ({
                    nativeEvent: {
                      selection: { start },
                    },
                  }: any) => saveLastCursorPosition({ start }),
                  onBlur,
                  value,
                  returnKeyType: 'next',
                  onSubmitEditing: () => {
                    // @ts-ignore
                    personalEmailInputRef.current?.focus();
                  },
                  blurOnSubmit: false,
                  keyboardType: 'number-pad',
                  testID:
                    'paychexpay.fillInDataFirstForm.input.phoneNumberInput',

                  secureTextEntry: !isPhoneVisible,
                  accessible: true,
                  accessibilityLabel: 'Enter Mobile phone',
                }}
                isError={!!error}
                errorMessage={error?.message}
                InputRightElement={
                  <TPressable
                    onPress={phoneHideHandler}
                    hitSlop={accessibleHitSlop}
                    p={'$3'}
                    accessible
                    testID="paychexpay.fillInDataFirstForm.button.hidePhone"
                    accessibilityLabel={`${
                      isPhoneVisible
                        ? 'Hide Mobile phone, button'
                        : 'Show Mobile phone, button'
                    }`}
                  >
                    {isPhoneVisible ? <EyeShowIcon /> : <EyeHideIcon />}
                  </TPressable>
                }
              />
            )}
          </>
        )}
      />
      <Controller
        name="emailAddress"
        control={controlProp || control}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error },
        }) => (
          <TextInput
            label="Personal email"
            args={{
              ref: personalEmailInputRef,
              onChangeText: onChange,
              onBlur,
              value,
              testID: 'paychexpay.fillInDataFirstForm.input.emailInput',
              accessible: true,
              accessibilityLabel: 'Enter Personal email',
              onSubmitEditing: () => {
                if (!(Object.keys(errors).length !== 0 || !isStateReady)) {
                  onSubmit();
                }
              },
            }}
            isError={!!error}
            errorMessage={error?.message}
          />
        )}
      />
    </View>
  );
};

export default FillInDataFirstForm;
