import { useEffect, useState } from 'react';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import { useAppDispatch } from '@store/hooks';
import { useSelector } from 'react-redux';
import { selectIsLogoutModalOpen } from '@store/features/navigationStates/selectors';
import { useAuth0 } from './useCustomAuth0';
import fillInDataThunks from '@store/features/fillInData/asyncThunks';

const useLogoutModal = () => {
  const dispatch = useAppDispatch();
  const isLogoutModalOpen = useSelector(selectIsLogoutModalOpen);
  const { onLogout } = useAuth0();

  const [isLogoutButtonLoading, setIsLogoutButtonLoading] = useState(false);

  useEffect(() => {
    dispatch(navigationStatesActions.setIsLogoutModalOpen(false));
  }, []);

  const asyncLogoutHandler = async () => {
    await onLogout();
    await dispatch(navigationStatesActions.setIsAuthenticated(false));
    await dispatch(navigationStatesActions.setIsAuthorizedCardholder(false));
  };

  const onLogoutPress = async () => {
    await dispatch(fillInDataThunks.logoutFromSessions());
    setIsLogoutButtonLoading(true);
    await asyncLogoutHandler();

    setTimeout(() => {
      closeLogoutModal();
    }, 1000);
  };

  const closeLogoutModal = () => {
    dispatch(navigationStatesActions.setIsLogoutModalOpen(false));
    setIsLogoutButtonLoading(false);
  };

  const openLogoutModal = () => {
    dispatch(navigationStatesActions.setIsLogoutModalOpen(true));
  };

  return {
    isLogoutModalOpen,
    isLogoutButtonLoading,
    onLogoutPress,
    closeLogoutModal,
    openLogoutModal,
  };
};

export default useLogoutModal;
