import { createTypedThunk } from '@utils/createTypedThunk';
import { IVariableResponse } from '@store/features/general/types';
import generalAPI from '@store/features/general/api';

const generalThunks = {
  getVariables: createTypedThunk<undefined, IVariableResponse>(
    'general/getVariables',
    generalAPI.getVariables
  ),
};

export default generalThunks;
