import { FillInDataSecondView } from '@views/SignUp';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  fillInDataSecondSchema,
  IFillInDataSecondFormInputs,
} from '@organisms/FillInDataSecondForm/helper';
import {
  selectAddressSuggestions,
  selectFillInDataSecond,
  selectIsAddressVerified,
} from '@store/features/fillInData/selectors';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import useIsPhone from '@hooks/useIsPhone';
import { FillInDataSecondProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { fillInDataActions } from '@store/features/fillInData/slice';
import { useEffect, useMemo, useState } from 'react';
import fillInDataThunks from '@store/features/fillInData/asyncThunks';
import { IAddressSuggestion } from '@store/features/fillInData/types';
import statesMap, { statesFull, statesShort } from '@constants/statesMap';
import { checkAddressForChanges } from './FillinDataSecondPage.helpers';
import { Keyboard } from 'react-native';
import { useAppDispatch } from '@store/hooks';

const FillInDataSecondPage = ({ navigation, route }: FillInDataSecondProps) => {
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isPoBoxAlertVisible, setIsPoBoxAlertVisible] = useState(false);

  const formValues = useSelector(selectFillInDataSecond);
  const formValuesModified = useMemo(
    () => ({
      zipCode: formValues.zipCode,
      city: formValues.city,
      state: formValues.state,
      line1: formValues.addressLine1,
      line2: formValues.addressLine2,
      ssn: formValues.ssn,
      date: formValues.date,
    }),
    [formValues]
  );
  const verifyStatus = useSelector(selectIsAddressVerified);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    getValues,
  } = useForm<IFillInDataSecondFormInputs>({
    defaultValues: formValuesModified,
    resolver: yupResolver(fillInDataSecondSchema),
  });

  const dispatch = useAppDispatch();
  const isPhone = useIsPhone();

  useEffect(() => {
    reset(formValuesModified);
  }, [formValuesModified]);

  useEffect(() => {
    if (isFormSubmitted) {
      const filledData = getValues();
      const filledDataModified = {
        zipCode: filledData.zipCode,
        city: filledData.city,
        state: filledData.state,
        addressLine1: filledData.line1,
        addressLine2: filledData.line2,
        ssn: filledData.ssn,
        date: filledData.date,
      };
      switch (verifyStatus) {
        case 'notVerified': {
          setError('line1', {
            type: 'custom',
            message: 'This address is not verified',
          });
          break;
        }
        case 'PoBox': {
          setIsPoBoxAlertVisible(true);
          break;
        }
        case 'changed': {
          dispatch(fillInDataActions.localUpdateSecond(filledDataModified));
          navigation.navigate('AgreementsIntro');
          break;
        }
        case 'verified': {
          if (checkAddressForChanges(filledDataModified, formValues)) {
            dispatch(fillInDataActions.localUpdateSecond(filledDataModified));
            navigation.navigate('AgreementsIntro');
          } else {
            navigation.navigate('AuthNavigator', {
              screen: 'AddressVerificationPage',
              params: {
                verifiedAddress: formValues,
                enteredAddress: filledDataModified,
                verificationFlow: 'signUp',
              },
            });
          }
        }
      }
    }
  }, [isFormSubmitted, verifyStatus]);

  const onSubmit = async (data: IFillInDataSecondFormInputs) => {
    const stateIndex = statesFull.indexOf(data.state.trim());
    const state = stateIndex ? statesShort[stateIndex] : data.state;
    dispatch(
      fillInDataThunks.postAddressVerify({
        correlationId: '1',
        addressLine1: data.line1,
        addressLine2: data.line2,
        city: data.city,
        countrySubdivision: state,
        postalCode: data.zipCode,
      })
    );
    setFormSubmitted(true);
  };

  const { isModalOpen, modalName, toOpenModal, toCloseModal } = useSideModal({
    route,
    navigation,
  });
  const onPressBack = () => {
    navigation.goBack();
  };

  const hidePoBoxAlertModal = () => {
    setIsPoBoxAlertVisible(false);
  };

  const onPressSsn = () => {
    if (isPhone) {
      navigation.navigate('AboutSsnPage');
    } else {
      toOpenModal(sideModalNames.ssn)();
    }
  };

  const onPressDob = () => {
    if (isPhone) {
      navigation.navigate('AboutDobPage');
    } else {
      toOpenModal(sideModalNames.dob)();
    }
  };

  const addressSuggestions = useSelector(selectAddressSuggestions);
  const requestAddressSuggestions = (text: string) => {
    if (text === '') return;
    dispatch(
      fillInDataThunks.postAddressSuggestions({
        search: text,
        limit: 10,
      })
    );
  };

  useEffect(() => {
    requestAddressSuggestions(formValues.addressLine1);
  }, []);

  const addressSuggestionHandler = (address: IAddressSuggestion) => {
    const newState = {
      zipCode: address.postalCode,
      city: address.city,
      state: statesMap[address.countrySubdivision],
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
    };
    dispatch(fillInDataActions.localUpdateSecond(newState));
  };

  const submitHandler = () => {
    Keyboard.dismiss();
    handleSubmit(onSubmit)();
  };

  return (
    <MediaLayoutTemplate
      Mobile={FillInDataSecondView.mobile}
      Desktop={FillInDataSecondView.desktop}
      onPressBack={onPressBack}
      control={control}
      onSubmit={submitHandler}
      errors={errors}
      onPressSsn={onPressSsn}
      onPressDob={onPressDob}
      toCloseModal={toCloseModal}
      isModalOpen={isModalOpen}
      isPoBoxAlertVisible={isPoBoxAlertVisible}
      hidePoBoxAlertModal={hidePoBoxAlertModal}
      modalName={modalName}
      addressSuggestions={addressSuggestions}
      requestAddressSuggestions={requestAddressSuggestions}
      addressSuggestionHandler={addressSuggestionHandler}
      verifyStatus={verifyStatus}
    />
  );
};

export default FillInDataSecondPage;
