import { IOowQuestionsState } from '@store/features/oowQuestions/types';
import { createSlice } from '@reduxjs/toolkit';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import oowQuestionsThunks from '@store/features/oowQuestions/asynkThunks';

const MINUTES_TO_EXPIRE_OOW = 4;

const initialState = {
  questions: [],
  status: 'pending',
  oowExpTime: new Date(
    Date.now() + MINUTES_TO_EXPIRE_OOW * 60 * 1000
  ).getTime(),
} as IOowQuestionsState;

const oowQuestionsSlice = createSlice({
  name: 'oowQuestions',
  initialState,
  reducers: {
    resetState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      cardholderThunks.postCreateCardholder.fulfilled,
      (state, { payload }) => {
        if (payload.kyc_status === 'PENDING_OOW_QUESTIONS') {
          // @ts-ignore
          state.questions = payload.oow_questions;
          state.oowExpTime = new Date(
            Date.now() + MINUTES_TO_EXPIRE_OOW * 60 * 1000
          ).getTime();
        }
      }
    );
    builder.addCase(
      cardholderThunks.getCardholder.fulfilled,
      (state, { payload }) => {
        if (payload.kyc_status === 'PENDING_OOW_QUESTIONS') {
          // @ts-ignore
          state.questions = payload.oow_questions;
          state.oowExpTime = new Date(
            Date.now() + MINUTES_TO_EXPIRE_OOW * 60 * 1000
          ).getTime();
        }
      }
    );
    builder.addCase(
      oowQuestionsThunks.postSubmitOwwQuestions.fulfilled,
      (state, { payload }) => {
        if (payload.kyc_status === 'APPROVED') {
          state.status = 'approved';
        } else if (payload.kyc_status === 'DENIED') {
          state.status = 'denied';
        } else {
          state.status = 'error';
        }
      }
    );
    builder.addCase(
      oowQuestionsThunks.postSubmitOwwQuestions.rejected,
      (state) => {
        state.status = 'error';
      }
    );
  },
});

export const { reducer: oowQuestionsReducer, actions: oowQuestionsActions } =
  oowQuestionsSlice;
