import { HeaderAndFooterMobileTemplate, WebLogoTemplate } from '@templates';
import { FooterButtonsRow, ImageWithContent } from '@molecules';
import CashbackIntroIcon from '../../../assets/cashbackIntroIcon.svg';
import { ScrollView, View, YStack } from 'tamagui';
import { TButton, Text } from '@atoms';
import React from 'react';
import { Platform } from 'react-native';
import { supportInfo } from '@constants/support';

interface CashBackIntroViewProps {
  mainButtonHandler: () => void;
  onPressBack: () => void;
  isRedirectToRewardsLoading?: boolean;
}

const CashBackIntroTexts = {
  title: 'Get paid to spend',
  content: `†Participating in Paychex Pay Rewards is optional, and you may opt-out at any time by calling ${supportInfo.phone}. Offers are based on your shopping habits. Cash back is earned by using your Paychex Pay Debit Mastercard for qualifying purchases and is credited to your account once the qualified purchase transaction is settled, depending upon the merchant. Paychex Pay Rewards is provided exclusively by Dosh Holdings LLC, an unaffiliated third party of Central Bank of Kansas City or Paychex or their affiliates. For complete details, including the use of transaction data, see the Paychex Pay Rewards Terms of Service provided to you with the Deposit Account Agreement.`,
};

const mobile = ({
  mainButtonHandler,
  isRedirectToRewardsLoading,
  onPressBack,
}: CashBackIntroViewProps) => {
  return (
    <HeaderAndFooterMobileTemplate
      header={{
        title: CashBackIntroTexts.title,
        isBackButton: true,
        onPressBack: onPressBack,
      }}
      footerContent={
        <TButton
          onPress={mainButtonHandler}
          isLoading={isRedirectToRewardsLoading}
        >
          Continue
        </TButton>
      }
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        h={Platform.OS === 'web' ? 0 : '100%'}
        bounces={false}
      >
        <YStack
          paddingTop={16}
          paddingBottom={16}
          paddingHorizontal={'$6'}
          flexGrow={1}
          display={'flex'}
          justifyContent="space-between"
        >
          <ImageWithContent image={<CashbackIntroIcon />} space={'$3'}>
            <Text variant="title" textAlign="center">
              {CashBackIntroTexts.title}
            </Text>

            <Text
              variant={'caption'}
              textAlign={'center'}
              fontSize={'$h5'}
              lineHeight={20}
              marginTop={12}
            >
              {CashBackIntroTexts.content}
            </Text>
          </ImageWithContent>
        </YStack>
      </ScrollView>
    </HeaderAndFooterMobileTemplate>
  );
};

const desktop = ({
  mainButtonHandler,
  isRedirectToRewardsLoading,
  onPressBack,
}: CashBackIntroViewProps) => {
  return (
    <>
      <WebLogoTemplate>
        <View
          w="325px"
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <ImageWithContent image={<CashbackIntroIcon />} space={'$3'}>
            <Text variant="title" textAlign="center">
              {CashBackIntroTexts.title}
            </Text>
            <Text
              variant={'caption'}
              textAlign={'center'}
              fontSize={'$h5'}
              lineHeight={20}
              marginTop={12}
            >
              {CashBackIntroTexts.content}
            </Text>
          </ImageWithContent>

          <View marginTop={'$6'}>
            <FooterButtonsRow
              firstButton={{
                label: 'Back',
                handler: onPressBack,
                testID:
                  'paychexpay.CashBackIntroView.footerButtonsRow.button.backButton',
              }}
              secondButton={{
                // eslint-disable-next-line
                  label: "Continue",
                handler: mainButtonHandler,
                testID:
                  'paychexpay.CashBackIntroView.footerButtonsRow.button.openSavingsButton',
              }}
              isLoading={isRedirectToRewardsLoading}
            />
          </View>
        </View>
      </WebLogoTemplate>
    </>
  );
};

const CashBackIntroView = {
  mobile,
  desktop,
};

export default CashBackIntroView;
