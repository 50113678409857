import AtmIcon from '../assets/webDrawer/atm.svg';
import MasterCardIcon from '../assets/webDrawer/mastercard.svg';
import DebitCardIcon from '../assets/webDrawer/card.svg';
import ProfileIcon from '../assets/webDrawer/profile.svg';
import NotificationsIcon from '../assets/webDrawer/notifications.svg';
import HelpIcon from '../assets/webDrawer/help.svg';
import LegalIcon from '../assets/webDrawer/legal.svg';
import { MoreItemWithNavigationProps } from '@devTypes/types';
import LogoutIcon from '../assets/webDrawer/logout.svg';

type MoreSectionType = MoreItemWithNavigationProps[];

const MORE_SECTION: MoreSectionType = [
  {
    Icon: ProfileIcon,
    label: 'Profile',
    pageName: 'ProfileSetting',
    testID: 'paychexpay.menuMoreSection.button.profileSettingsButton',
  },
  {
    Icon: NotificationsIcon,
    label: 'Notifications',
    pageName: 'Notifications',
    testID: 'paychexpay.menuMoreSection.button.notificationsButton',
  },
  {
    Icon: AtmIcon,
    label: 'ATM finder',
    pageName: 'Atm',
    testID: 'paychexpay.menuMoreSection.button.atmButton',
  },
  {
    Icon: DebitCardIcon,
    label: 'Debit card',
    pageName: 'DebitCard',
    testID: 'paychexpay.menuMoreSection.button.debitCardButton',
  },
  {
    Icon: MasterCardIcon,
    label: 'Mastercard features',
    pageName: 'MastercardFeatures',
    testID: 'paychexpay.menuMoreSection.button.mastercardFeaturesButton',
  },
  {
    Icon: LegalIcon,
    label: 'Document Center',
    pageName: 'Legal',
    testID: 'paychexpay.menuMoreSection.button.legalButton',
  },
  {
    Icon: HelpIcon,
    label: 'Help',
    pageName: 'Help',
    testID: 'paychexpay.menuMoreSection.button.helpButton',
  },
];

const MORE_SECTION_DESKTOP: MoreSectionType = [
  {
    Icon: AtmIcon,
    label: 'ATM finder',
    pageName: 'Atm',
    testID: 'paychexpay.menuMoreSection.button.atmButton',
  },
  {
    Icon: MasterCardIcon,
    label: 'Mastercard features',
    pageName: 'MastercardFeatures',
    testID: 'paychexpay.menuMoreSection.button.mastercardFeaturesButton',
  },
  {
    Icon: DebitCardIcon,
    label: 'Debit card',
    pageName: 'DebitCard',
    testID: 'paychexpay.menuMoreSection.button.debitCardButton',
  },
  {
    Icon: ProfileIcon,
    label: 'Profile',
    pageName: 'ProfileSetting',
    testID: 'paychexpay.menuMoreSection.button.profileSettingsButton',
  },
  {
    Icon: NotificationsIcon,
    label: 'Notifications',
    pageName: 'Notifications',
    testID: 'paychexpay.menuMoreSection.button.notificationsButton',
  },
  {
    Icon: HelpIcon,
    label: 'Help',
    pageName: 'Help',
    testID: 'paychexpay.menuMoreSection.button.helpButton',
  },
  {
    Icon: LegalIcon,
    label: 'Document Center',
    pageName: 'Legal',
    testID: 'paychexpay.menuMoreSection.button.legalButton',
  },
];
const LOGOUT_MENU_ITEM: MoreItemWithNavigationProps = {
  Icon: LogoutIcon,
  label: 'Log out',
  pageName: 'LogOut',
  testID: 'paychexpay.menuLogoutItem.button.logOutButton',
};

export { MORE_SECTION, MORE_SECTION_DESKTOP, LOGOUT_MENU_ITEM };
