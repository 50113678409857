import { useState } from 'react';
import { phoneOnChange } from '@organisms/FillInDataFirstForm/helper';

interface Selection {
  start: number;
  end?: number;
}

interface InitialSelection {
  start?: number;
  end?: number;
}

const usePhoneNumberInputCursorController = ({
  start = 0,
  end = 0,
}: InitialSelection) => {
  const [selection, setSelection] = useState<Selection | undefined>(undefined);

  const [selectionWatcher, setSelectionWatcher] = useState<Selection>({
    start,
    end,
  });

  const phoneNumberInputHandler = (
    newValue: string,
    prevValue: string,
    onChange: (text: string) => void
  ) => {
    onChange(phoneOnChange(newValue));

    let cursorStepModifier: number;
    if (prevValue.length > newValue.length) {
      // char deleted
      switch (selectionWatcher.start) {
        case 1:
          cursorStepModifier = 0;
          break;
        case 6:
          cursorStepModifier = 2;
          break;
        case 12:
          cursorStepModifier = 3;
          break;
        default:
          cursorStepModifier = 1;
      }
      saveLastCursorPosition({
        start: selectionWatcher.start - cursorStepModifier,
      });
    } else {
      // char added
      switch (selectionWatcher.start) {
        case 0:
          cursorStepModifier = 2;
          break;
        case 4:
          cursorStepModifier = 3;
          break;
        case 9:
          cursorStepModifier = 4;
          break;
        default:
          cursorStepModifier = 1;
      }

      saveLastCursorPosition({
        start: selectionWatcher.start + cursorStepModifier,
      });
    }
  };

  const phoneNumberInputHandlerForTransfers = (
    newValue: string,
    prevValue: string,
    onChange: (text: string) => void
  ) => {
    onChange(newValue);

    let cursorStepModifier: number;
    if (prevValue.length > newValue.length) {
      // char deleted
      switch (selectionWatcher.start) {
        case 7:
          cursorStepModifier = 1;
          break;
        case 12:
          cursorStepModifier = 1;
          break;
        default:
          cursorStepModifier = 1;
      }
      saveLastCursorPosition({
        start: selectionWatcher.start - cursorStepModifier,
      });
    } else {
      // char added
      switch (selectionWatcher.start) {
        case 1:
          cursorStepModifier = 2;
          break;
        case 5:
          cursorStepModifier = 2;
          break;
        case 9:
          cursorStepModifier = 4;
          break;
        default:
          cursorStepModifier = 1;
      }
      saveLastCursorPosition({
        start: selectionWatcher.start + cursorStepModifier,
      });
    }
  };

  const saveLastCursorPosition = ({ start }: Selection) => {
    setSelectionWatcher({ start, end: start });
    setSelection({ start, end: start });
  };

  return {
    selection,
    phoneNumberInputHandler,
    saveLastCursorPosition,
    phoneNumberInputHandlerForTransfers,
  };
};

export default usePhoneNumberInputCursorController;
