import {
  DesktopAreaHeader,
  FooterButtonsRow,
  MobileWebHeader,
  TextInput,
} from '@molecules';
import { HeaderAndFooterMobileTemplate, SideBarTemplate } from '@templates';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import React, { useEffect, useRef, useState } from 'react';
import { IEmailField } from '@organisms/FillInDataFirstForm/helper';
import { Keyboard, Platform } from 'react-native';
import useIsTablet from '@hooks/useIsTablet';
import ConfirmationModal from '@organisms/ConfirmationModal/ConfirmationModal';
import { Separator, View } from 'tamagui';

interface EditEmailViewProps {
  navigateBack: () => void;
  control: Control<IEmailField>;
  onSubmit: () => void;
  errors: FieldErrors;
  isSaveButtonLoading: boolean;
  isFormDirty: boolean;
  profileSettingsModalVisible: boolean;
  setConfirmationModalVisible: (value: boolean) => void;
  resetForm: () => void;
}

const mobile = ({
  navigateBack,
  control,
  onSubmit,
  errors,
  isSaveButtonLoading,
  isFormDirty,
  profileSettingsModalVisible,
  setConfirmationModalVisible,
}: EditEmailViewProps) => {
  const personalEmailInputRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    personalEmailInputRef.current?.focus();
  }, []);

  const [isUnsavedChangesAlertVisible, setIsUnsavedChangesAlertVisible] =
    useState(false);

  const cancelHandler = () => {
    if (isFormDirty) {
      Keyboard.dismiss();
      setIsUnsavedChangesAlertVisible(true);
      return;
    }
    navigateBack();
  };

  const goBackHandler = () => {
    if (isUnsavedChangesAlertVisible) {
      setIsUnsavedChangesAlertVisible(false);
    }
    navigateBack();
  };

  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderAndFooterMobileTemplate
        footerContent={
          <FooterButtonsRow
            isLoading={isSaveButtonLoading}
            firstButton={{
              variant: 'gray',
              label: 'Cancel',
              handler: cancelHandler,
              testID: 'paychexpay.editEmailView.footerButtonsRow.button.cancel',
            }}
            secondButton={{
              label: 'Save',
              handler: onSubmit,
              isDisabled: Object.keys(errors).length !== 0 || !isFormDirty,
              variant: 'darkGray',
              testID:
                'paychexpay.editEmailView.footerButtonsRow.button.saveEmail',
            }}
          />
        }
        header={{
          title: 'Personal email',
          isBackButton: true,
          onPressBack: cancelHandler,
        }}
      >
        <View p={'$4'} flexDirection="column">
          <Controller
            name="emailAddress"
            control={control}
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <TextInput
                label="Personal email"
                placeholder="example@gmail.com"
                styles={{ minHeight: 100 }}
                args={{
                  ref: personalEmailInputRef,
                  onChangeText: onChange,
                  onBlur,
                  value,
                  onSubmitEditing: () => {
                    if (!(Object.keys(errors).length !== 0 || !isFormDirty)) {
                      onSubmit();
                    }
                  },
                  testID: 'paychexpay.mobile.editEmailView.input.emailInput',
                }}
                isError={!!error}
                errorMessage={error?.message}
              />
            )}
          />
        </View>
      </HeaderAndFooterMobileTemplate>

      <ConfirmationModal
        isModalOpen={
          isUnsavedChangesAlertVisible || profileSettingsModalVisible
        }
        setIsConfirmationModalOpen={(value: boolean) => {
          setConfirmationModalVisible(value);
          setIsUnsavedChangesAlertVisible(value);
        }}
        confirmHandler={goBackHandler}
      />
    </>
  );
};
const desktop = ({
  navigateBack,
  control,
  onSubmit,
  errors,
  isSaveButtonLoading,
  isFormDirty,
  profileSettingsModalVisible,
  setConfirmationModalVisible,
  resetForm,
}: EditEmailViewProps) => {
  const isTablet = useIsTablet();
  const personalEmailInputRef = useRef(null);
  useEffect(() => {
    // @ts-ignore
    personalEmailInputRef.current?.focus();
  }, []);

  const [isUnsavedChangesAlertVisible, setIsUnsavedChangesAlertVisible] =
    useState(false);

  const cancelHandler = () => {
    if (isFormDirty) {
      Keyboard.dismiss();
      setIsUnsavedChangesAlertVisible(true);
      return;
    }
    navigateBack();
    resetForm();
  };

  const goBackHandler = () => {
    if (isUnsavedChangesAlertVisible) {
      setIsUnsavedChangesAlertVisible(false);
    }
    navigateBack();
    resetForm();
  };

  return (
    <>
      <SideBarTemplate>
        <View px={'$7'}>
          <DesktopAreaHeader
            title="Personal email"
            backButtonHandler={cancelHandler}
          />
          <View mt={'$7'} padding={20} bg="white" w={isTablet ? '100%' : 364}>
            <Controller
              name="emailAddress"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <>
                  <TextInput
                    label="Personal email"
                    placeholder="example@gmail.com"
                    styles={{ mb: '$4' }}
                    args={{
                      ref: personalEmailInputRef,
                      onChangeText: onChange,
                      onBlur,
                      value,
                      onSubmitEditing: () => {
                        if (
                          !(Object.keys(errors).length !== 0 || !isFormDirty)
                        ) {
                          onSubmit();
                        }
                      },
                      testID:
                        'paychexpay.desktop.editEmailView.input.emailInput',
                    }}
                    isError={!!error}
                    errorMessage={error?.message}
                  />
                  <Separator mb={'$4'} borderColor="$gray20" />
                  <FooterButtonsRow
                    isLoading={isSaveButtonLoading}
                    firstButton={{
                      label: 'Cancel',
                      handler: cancelHandler,
                      variant: 'gray',
                      testID:
                        'paychexpay.editEmailView.footerButtonsRow.button.cancel',
                    }}
                    secondButton={{
                      label: 'Save',
                      handler: onSubmit,
                      isDisabled:
                        Object.keys(errors).length !== 0 || !isFormDirty,
                      variant: 'darkGray',
                      testID:
                        'paychexpay.editEmailView.footerButtonsRow.button.save',
                    }}
                  />
                </>
              )}
            />
          </View>
        </View>
      </SideBarTemplate>
      <ConfirmationModal
        isModalOpen={
          isUnsavedChangesAlertVisible || profileSettingsModalVisible
        }
        setIsConfirmationModalOpen={(value: boolean) => {
          setConfirmationModalVisible(value);
          setIsUnsavedChangesAlertVisible(value);
        }}
        confirmHandler={goBackHandler}
      />
    </>
  );
};

const EditEmailView = {
  mobile,
  desktop,
};

export default EditEmailView;
