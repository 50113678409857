import { AtmProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import AtmAndReloadView from '@views/More/AtmAndReloadView';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSideModal } from '@hooks/useSideModal';
import { sideModalNames } from '@constants/sideModalNames';
import useIsPhone from '@hooks/useIsPhone';
import useCurrentLocation from '@hooks/useCurrentLocation';
import getDirection from '@utils/getDirection';
import { Keyboard, Linking, Platform } from 'react-native';
import { createMapLink } from 'react-native-open-maps';
import { useSelector } from 'react-redux';
import atmLocationsThunks from '@store/features/atmLocations/asyncThunks';
import {
  selectIsMarkersPending,
  selectMarkers,
} from '@store/features/atmLocations/selectors';
import { atmLocationsActions } from '@store/features/atmLocations/slice';
import addressObjToString from '@utils/addressObjToString';
import useIsTablet from '@hooks/useIsTablet';
import { countDistance, ltdLngToString } from '@utils/atmLocationsHelper';
import {
  IZipSearch,
  ZipCodeSuggestion,
} from '@store/features/atmLocations/types';
import { mockedLocation } from '@constants/markersList';
import { useAppDispatch } from '@store/hooks';
import { selectAppState } from '@store/features/general/selectors';
import { Region } from 'react-native-maps';
import { onPressDepositCash } from '@utils/redirectsHelper';
import accessTokenRenewCheck from '@utils/accessTokenRenewCheck';
import { useAuth0 } from '@hooks/useCustomAuth0';
//@ts-ignore
import { getAccessToken } from '@utils/accessTokenHelper';

const AtmAndReloadPage = ({ navigation, route }: AtmProps) => {
  const isPhone = useIsPhone();
  const isTablet = useIsTablet();

  const appState = useSelector(selectAppState);
  const isMarkersPending = useSelector(selectIsMarkersPending);
  const mapRef = useRef<any>();

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      navigation.navigate('BottomTabsNavigator', { screen: 'HomePage' });
    } else {
      navigation.goBack();
    }
  };

  const [zipSearchValue, setZipSearchValue] = useState('');
  const [mapDistance, setMapDistance] = useState('');
  const [currentZipCode, setCurrentZipCode] = useState('');
  const [isZipValidationError, setIsZipValidationError] = useState(false);
  const [isShowThisAriaButton, setIsShowThisAriaButton] = useState(false);

  const markers = useSelector(selectMarkers);

  const [isListViewOpen, setIsListViewOpen] = useState(false);

  const [currentMarkerId, setCurrentMarkerId] = useState<string | null>(null);
  const unselectMarker = () => {
    setTimeout(() => {
      setCurrentMarkerId(null);
    }, 500);
  };

  const {
    isModalOpen,
    modalName,
    toOpenModal,
    toCloseModal,
    toSetMarkerId,
    markerId,
  } = useSideModal({
    navigation,
    route,
  });

  const onPressMarker =
    ({
      markerId,
      latitude,
      longitude,
    }: {
      markerId: string;
      latitude: number;
      longitude: number;
    }) =>
    () => {
      if (isPhone) {
        Keyboard.dismiss();
        setCurrentMarkerId(markerId);
        const distance = {
          latitude: latitude,
          longitude: longitude,
          latitudeDelta: mockedLocation.latitudeDelta,
          longitudeDelta: mockedLocation.longitudeDelta,
        };
        mapRef.current?.animateToRegion(distance, 500);
        setIsShowThisAriaButton(false);
      } else {
        toOpenModal(sideModalNames.markerDetails)();
        toSetMarkerId(markerId);
        setMapCenter({
          lat: latitude,
          lng: longitude,
        });
        setIsShowThisAriaButton(false);
      }
    };

  const currentMarkerInfo = useMemo(() => {
    if (isPhone) {
      return markers.find((marker) => marker.location.id === currentMarkerId);
    } else {
      return markers.find((marker) => marker.location.id === markerId);
    }
  }, [currentMarkerId, markerId]);

  const {
    currentLocation,
    requestCurrentLocation,
    isPermissionDenied,
    resetPermission,
    checkLocationWhenInUsePermission,
    isRequestWithoutResponse,
  } = useCurrentLocation();

  const [locationPermissionGranted, setLocationPermissionGranted] =
    useState(false);
  const [locationPermissionChecked, setLocationPermissionChecked] =
    useState(false);

  const checkLocationPermission = ({
    onGranted,
    onDenied,
  }: {
    onGranted: () => void;
    onDenied: (value: { isRequestable?: boolean }) => void;
  }) => {
    setLocationPermissionChecked(true);

    checkLocationWhenInUsePermission({ onGranted, onDenied });
  };

  const onOpenSettings = async () => {
    resetPermission();

    if (Platform.OS === 'android') {
      checkLocationPermission({
        onGranted: () => setLocationPermissionGranted(true),
        onDenied: async ({ isRequestable }: { isRequestable?: boolean }) => {
          dispatch(atmLocationsActions.cleanUpMarkersList());
          setLocationPermissionGranted(false);
          if (!isRequestable) {
            await Linking.openSettings();
          }
        },
      });
    } else {
      await Linking.openSettings();
    }
  };

  useEffect(() => {
    if (locationPermissionChecked && Platform.OS === 'android') return; //TODO: review

    if (appState === 'active' && Platform.OS !== 'web') {
      checkLocationPermission({
        onGranted: () => setLocationPermissionGranted(true),
        onDenied: () => {
          setLocationPermissionGranted(false);
        },
      });
    }
  }, [appState, locationPermissionChecked]);

  useEffect(() => {
    dispatch(atmLocationsActions.cleanUpMarkersList());
  }, []);

  const onPressLocation = () => {
    requestCurrentLocation();
  };
  useEffect(() => {
    if (isPhone && Platform.OS !== 'web') {
      mapRef.current?.animateToRegion(currentLocation, 500);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (isPhone && Platform.OS !== 'web' && locationPermissionGranted) {
      requestCurrentLocation();
    }
  }, [locationPermissionGranted]);

  const isGoogleMapsAvailableIos = useMemo(() => {
    return (
      Platform.OS === 'ios' &&
      Linking.canOpenURL(createMapLink({ provider: 'google' }))
    );
  }, []);
  const [isBottomModalOpen, setIsBottomModalOpen] = useState(false);
  const onPressBottomModalClose = () => {
    setIsBottomModalOpen(false);
  };
  const onPressDirection = () => {
    if (isGoogleMapsAvailableIos) {
      setIsBottomModalOpen(true);
    } else {
      getDirection({
        address: addressObjToString(currentMarkerInfo?.location.address),
      });
    }
  };
  const onPressAppleMaps = () => {
    getDirection({
      address: addressObjToString(currentMarkerInfo?.location.address),
      provider: 'apple',
    });
  };
  const onPressGoogleMaps = () => {
    getDirection({
      address: addressObjToString(currentMarkerInfo?.location.address),
      provider: 'google',
    });
  };

  const dispatch = useAppDispatch();

  const { onLogout, accessTokenRenew } = useAuth0();

  const handleRequestError = async (err: any) => {
    if (err.status === 401 || err.status === 404) {
      const accessToken = await getAccessToken();
      if (accessTokenRenewCheck(accessToken)) {
        try {
          accessTokenRenew();
        } catch (error) {
          await onLogout();
        }
      }
    }
  };

  const getMarkers = (distance: string, coordinates: string) => {
    setMapDistance(distance);
    dispatch(atmLocationsActions.cleanUpMarkersList());
    dispatch(
      atmLocationsThunks.getAtmLocations({
        coordinates,
        distance,
        location_network: 'MoneyPass',
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
    dispatch(
      atmLocationsThunks.getAtmLocations({
        coordinates,
        distance,
        location_network: 'AllPoint',
      })
    )
      .unwrap()
      .catch((err) => handleRequestError(err));
  };

  const [mapCenter, setMapCenter] = useState({
    lat: currentLocation.latitude,
    lng: currentLocation.longitude,
  });

  const onPressSearchArea = ({
    region,
    zoomLevel,
    layout,
  }: {
    region?: Region;
    zoomLevel?: number;
    layout?: { w: number; h: number };
  }) => {
    if (Platform.OS !== 'web') {
      Keyboard.dismiss();
      setIsShowThisAriaButton(false);
      if (region) {
        const distance = countDistance(
          region.latitude,
          region.longitude,
          region.latitudeDelta,
          region.longitudeDelta
        );
        getMarkers(distance, ltdLngToString(region.latitude, region.longitude));
      }
    } else {
      setIsShowThisAriaButton(false);
      const metersPerPx =
        (156543.03392 * Math.cos((currentLocation.latitude * Math.PI) / 180)) /
        Math.pow(2, zoomLevel || 16);
      const y = layout ? layout.h * metersPerPx : 0;
      const x = layout ? layout.w * metersPerPx : 0;
      const distance = Math.ceil(Math.sqrt(x * x + y * y) / 2);
      getMarkers(
        distance < 1000 ? '1000' : distance.toString(),
        ltdLngToString(mapCenter.lat, mapCenter.lng)
      );
    }
  };

  const zipCodeSuggestions: ZipCodeSuggestion = useMemo(
    () =>
      markers.reduce(
        (result, marker) => ({
          ...result,
          [marker.location.address.postal_code]: {
            zipCode: marker.location.address.postal_code,
            address: `${marker.location.address.city}, ${marker.location.address.region}`,
            coordinates: {
              latitude: marker.location.coordinates.latitude,
              longitude: marker.location.coordinates.longitude,
            },
          },
        }),
        {}
      ),
    [markers, zipSearchValue]
  );

  useEffect(() => {
    if (currentZipCode && zipCodeSuggestions[currentZipCode]) {
      if (Platform.OS !== 'web') {
        const distance = {
          latitude: zipCodeSuggestions[currentZipCode].coordinates.latitude,
          longitude: zipCodeSuggestions[currentZipCode].coordinates.longitude,
          latitudeDelta: mockedLocation.latitudeDelta,
          longitudeDelta: mockedLocation.longitudeDelta,
        };
        mapRef.current?.animateToRegion(distance, 500);
        setIsShowThisAriaButton(false);
      } else {
        setMapCenter({
          lat: zipCodeSuggestions[currentZipCode].coordinates.latitude,
          lng: zipCodeSuggestions[currentZipCode].coordinates.longitude,
        });
        setIsShowThisAriaButton(false);
      }
    }
  }, [zipCodeSuggestions, currentZipCode]);

  const handleZipSearch = (zipCode: string) => {
    setZipSearchValue(zipCode);
  };

  const handlePressZipSuggestion = async ({ zipCode }: IZipSearch) => {
    if (zipCode && zipCode.length == 5) {
      dispatch(atmLocationsActions.cleanUpMarkersList());
      setIsZipValidationError(false);

      await dispatch(
        atmLocationsThunks.getAtmLocations({
          distance: mapDistance.length === 0 ? '10000' : mapDistance,
          postal_code: zipCode,
          location_network: 'AllPoint',
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
      await dispatch(
        atmLocationsThunks.getAtmLocations({
          distance: mapDistance.length === 0 ? '10000' : mapDistance,
          postal_code: zipCode,
          location_network: 'MoneyPass',
        })
      )
        .unwrap()
        .catch((err) => handleRequestError(err));
      setCurrentZipCode(zipCode);
    } else {
      setIsZipValidationError(true);
    }
  };

  return (
    <MediaLayoutTemplate
      Mobile={AtmAndReloadView.mobile}
      Desktop={isTablet ? AtmAndReloadView.mobile : AtmAndReloadView.desktop}
      navigateBack={navigateBack}
      isListViewOpen={isListViewOpen}
      setIsListViewOpen={setIsListViewOpen}
      onPressDepositCash={onPressDepositCash}
      onPressSearchArea={onPressSearchArea}
      markers={markers}
      isMarkersPending={isMarkersPending}
      onPressMarker={onPressMarker}
      unselectMarker={unselectMarker}
      currentMarkerInfo={currentMarkerInfo}
      isModalOpen={isModalOpen}
      modalName={modalName}
      toCloseModal={toCloseModal}
      mapRef={mapRef}
      locationPermissionGranted={locationPermissionGranted}
      onPressLocation={onPressLocation}
      currentLocation={currentLocation}
      isPermissionDenied={isPermissionDenied}
      requestCurrentLocation={requestCurrentLocation}
      isRequestWithoutResponse={isRequestWithoutResponse}
      resetPermission={resetPermission}
      onOpenSettings={onOpenSettings}
      onPressAppleMaps={onPressAppleMaps}
      onPressGoogleMaps={onPressGoogleMaps}
      isBottomModalOpen={isBottomModalOpen}
      onPressBottomModalClose={onPressBottomModalClose}
      onPressDirection={onPressDirection}
      isGoogleMapsAvailableIos={isGoogleMapsAvailableIos}
      zipSearchValue={zipSearchValue}
      onZipSearch={handleZipSearch}
      mapCenter={mapCenter}
      setMapCenter={setMapCenter}
      onPressZipSuggestion={handlePressZipSuggestion}
      setIsShowThisAriaButton={setIsShowThisAriaButton}
      isShowThisAriaButton={isShowThisAriaButton}
      currentZipCode={currentZipCode}
      isZipValidationError={isZipValidationError}
      setIsZipValidationError={setIsZipValidationError}
      setCurrentZipCode={setCurrentZipCode}
    />
  );
};

export default AtmAndReloadPage;
