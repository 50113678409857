import useIsPhone from '@hooks/useIsPhone';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { IAccountPreview } from '@devTypes/accountTypes';
import cardStatementsThunks from '@store/features/cardStatements/asyncThunks';
import { useAppDispatch } from '@store/hooks';
import useIsTablet from '@hooks/useIsTablet';
import { Platform } from 'react-native';
import { FEE_SCHEDULE_URL } from '@constants/accounts';
import openLinkInAppBrowser from '@utils/openLinkInAppBrowser';
import { directDepositEligibleLink } from '@constants/endpoints';
import { useSelector } from 'react-redux';
import { selectDdaEligibility } from '@store/features/homeWidgets/selectors';

const useAccount = ({
  accountPreview,
  onOpenDebitCardModal,
  onOpenDisputeChargeModal,
  onOpenDdaModal,
  onOpenCloseCardModal,
  onOpenStatementsListModal,
  statementsCardType, // change logic with be
  currentCardId,
  navigation,
}: {
  accountPreview: IAccountPreview | null;
  onOpenDebitCardModal?: () => void;
  onOpenDisputeChargeModal?: () => void;
  onOpenDdaModal?: () => void;
  onOpenCloseCardModal?: () => void;
  onOpenStatementsListModal?: () => void;
  statementsCardType?: 'savings' | 'spending';
  currentCardId?: string;
  navigation: any;
}) => {
  const { copyToClipboardHandler, CopyTextToastPopup } = useCopyToClipboard();
  const isPhone = useIsPhone();
  const isTablet = useIsTablet();

  const ddaEligibility = useSelector(selectDdaEligibility);

  const dispatch = useAppDispatch();

  const directDepositHandler = () => {
    if (ddaEligibility) {
      if (Platform.OS !== 'web') {
        openLinkInAppBrowser(directDepositEligibleLink);
      } else {
        const ddaTab = window.open('', '_blank');
        if (ddaTab) {
          ddaTab.opener = null;
          ddaTab.location.href = directDepositEligibleLink;
        }
      }
    } else {
      if (isTablet) {
        navigation.navigate('HomeNavigator', {
          screen: 'SetupDda',
          params: { stateName: statementsCardType },
        });
      } else {
        onOpenDdaModal && onOpenDdaModal();
      }
    }
  };

  const viewDebitCardHandler = () => {
    if (isTablet) {
      navigation.navigate('DebitCard', { isOpenedAsFullPage: true });
    } else {
      onOpenDebitCardModal && onOpenDebitCardModal();
    }
  };
  const feeScheduleHandler = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'FeeSchedule',
      });
    } else {
      if (Platform.OS === 'web') {
        window.open(FEE_SCHEDULE_URL, '_blank', 'noopener,noreferrer');
      }
    }
  };
  const getStatementHandler = async () => {
    if (currentCardId) {
      if (statementsCardType === 'savings') {
        dispatch(
          cardStatementsThunks.getSavingStatementsList({
            cardId: currentCardId,
          })
        );
      } else {
        dispatch(
          cardStatementsThunks.getSpendingStatementsList({
            cardId: currentCardId,
          })
        );
      }
    }
    if (isTablet && statementsCardType) {
      navigation.navigate('HomeNavigator', {
        screen: 'StatementsList',
        params: { cardType: statementsCardType },
      });
    } else {
      onOpenStatementsListModal && onOpenStatementsListModal();
    }
  };
  const closeSavingAccountHandler = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: { pageName: 'closeSaving' },
      });
    } else {
      onOpenCloseCardModal && onOpenCloseCardModal();
    }
  };
  const closeSpendingAccountHandler = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: { pageName: 'closeSpending' },
      });
    } else {
      onOpenCloseCardModal && onOpenCloseCardModal();
    }
  };
  const disputeChargeHandler = () => {
    if (isPhone) {
      navigation.navigate('HomeNavigator', {
        screen: 'Support',
        params: { pageName: 'disputeCharge' },
      });
    } else {
      onOpenDisputeChargeModal && onOpenDisputeChargeModal();
    }
  };

  const allMenuItems = {
    directDeposit: {
      label: 'Set up direct deposit',
      handler: directDepositHandler,
      testID: 'paychexpay.account.button.openDirectDepositAccountInfo',
    },
    debitCard: {
      label: 'View card',
      handler: viewDebitCardHandler,
      testID: 'paychexpay.account.button.openViewDebitCard',
    },
    feeSchedule: {
      label: 'See Deposit Account Fee Schedule',
      handler: feeScheduleHandler,
      testID: 'paychexpay.account.button.openFeeSchedule',
    },
    disputeCharge: {
      label: 'Dispute a transaction',
      handler: disputeChargeHandler,
      testID: 'paychexpay.account.button.openDisputeTransaction',
    },
    getStatement: {
      label: 'View statements',
      handler: getStatementHandler,
      testID: 'paychexpay.account.button.openViewStatements',
    },
    closeAccount: {
      label: 'Close this account',
      handler:
        accountPreview?.type === 'saving'
          ? closeSavingAccountHandler
          : closeSpendingAccountHandler,
      isRed: true,
      testID: 'paychexpay.account.button.closeAccount',
    },
  };

  const getMenuItems = () => {
    switch (accountPreview?.type) {
      case 'spending':
        return [
          allMenuItems.directDeposit,
          allMenuItems.debitCard,
          allMenuItems.feeSchedule,
          allMenuItems.disputeCharge,
          allMenuItems.getStatement,
          allMenuItems.closeAccount,
        ];
      case 'saving':
        if (accountPreview.status === 'CLOSED') {
          return [allMenuItems.getStatement];
        }
        return [allMenuItems.getStatement, allMenuItems.closeAccount];
      default:
        return [
          allMenuItems.directDeposit,
          allMenuItems.debitCard,
          allMenuItems.feeSchedule,
          allMenuItems.disputeCharge,
          allMenuItems.getStatement,
          allMenuItems.closeAccount,
        ];
    }
  };

  const menuItems = getMenuItems();

  return {
    menuItems,
    copyToClipboard: copyToClipboardHandler,
    ToastPopup: CopyTextToastPopup,
  };
};

export default useAccount;
