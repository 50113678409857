import { CardStyleInterpolators } from '@react-navigation/stack';
import { Platform } from 'react-native';

const DEFAULT_SCREEN_OPTIONS = {
  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  headerShown: false,
  animationEnabled: Platform.OS !== 'web',
  gestureEnabled: true,
};

const DEFAULT_TAB_BAR_OPTIONS = {
  borderTopWidth: 0,
  backgroundColor: 'white',
  paddingBottom: Platform.OS === 'web' ? 0 : 5,
  paddingTop: Platform.OS === 'web' ? 0 : 9,
  height: Platform.OS === 'web' ? 0 : 60,
  borderBottomWidth: 0,
  elevation: 0, // for Android
  shadowOffset: {
    width: 0,
    height: 0, // for iOS
  },
  safeAreaInsets: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
};

export { DEFAULT_SCREEN_OPTIONS, DEFAULT_TAB_BAR_OPTIONS };
