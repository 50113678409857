import { Dimensions, Linking, Platform } from 'react-native';
import { isoDateToTransactionsDetailsDate } from '@utils/dateHelpers';
import BalanceIcon from '../../../assets/transactions/balanceIcon.svg';
import DescriptionIcon from '../../../assets/transactions/descriptionIcon.svg';
import StatusIcon from '../../../assets/transactions/statusIcon.svg';
import ShopIcon from '../../../assets/transactions/shopIcon.svg';
import DateIcon from '../../../assets/transactions/dateIcon.svg';
import React, { useMemo } from 'react';
import { TPressable, Text } from '@atoms';
import { ITransaction } from '@store/features/transactions/types';
import {
  transactionToAmount,
  transactionToCategoryName,
  transactionToIsIncome,
  transactionToIsMerchant,
  transactionToPaymentMethod,
  transactionToStatus,
  transactionToTitle,
} from '@utils/transactionsHelper';
import { useSelector } from 'react-redux';
import {
  selectAccountIdsToNames,
  selectSavingsAccountPreviewInfo,
  selectSpendingAccountPreviewInfo,
} from '@store/features/cards/selectors';
import { transactionsToIcon } from '@utils/categoryToIcon';
import amountToString from '@utils/amountToString';
import { ScrollView, Separator, View, XStack, YStack } from 'tamagui';
import { Link } from 'native-base';

interface TransactionDetailsContentProps {
  transaction: ITransaction | undefined;
  isDesktop?: boolean;
}

interface TransactionDetailsSectionProps {
  sectionName: string;
  sectionValue: string | undefined;
  IconLeft?: React.ReactNode;
  IconRight?: React.ReactNode;
  isPressable?: boolean;
  onPress?: () => void;
  isDividerVisible?: boolean;
}

const TransactionDetailsSection = ({
  sectionName,
  sectionValue,
  IconLeft,
  IconRight,
  isPressable,
  onPress,
  isDividerVisible = true,
}: TransactionDetailsSectionProps) => {
  return (
    <>
      {isDividerVisible && <Separator px={'$4'} borderColor="$gray20" />}
      <XStack alignItems="center" justifyContent="space-between">
        <XStack alignItems="center" flexShrink={1}>
          {IconLeft && <View pr={'$4'}>{IconLeft}</View>}
          {!isPressable ? (
            <View paddingVertical={10} width="100%" pr={'$8'}>
              <Text variant="subtitle">{sectionName}</Text>
              <Text
                variant="regular"
                numberOfLines={sectionName === 'Description' ? 0 : 1}
              >
                {sectionValue}
              </Text>
            </View>
          ) : (
            <TPressable
              onPress={onPress}
              flex={1}
              width="100%"
              pr={'$8'}
              testID="paychexpay.TransactionDetailsSection.button.openSection"
            >
              <Text paddingTop={10} variant="subtitle">
                {sectionName}
              </Text>
              {Platform.OS === 'web' && sectionName === 'Store address' ? (
                <Link
                  paddingBottom={10}
                  isExternal
                  href="http://maps.google.com/maps?z=12&t=m&q=loc:38.9419+-78.3020"
                >
                  <Text width="100%" numberOfLines={1}>
                    {sectionValue}
                  </Text>
                </Link>
              ) : (
                <Text paddingBottom={14} width="100%">
                  {sectionValue}
                </Text>
              )}
            </TPressable>
          )}
        </XStack>
        {IconRight && <TPressable>{IconRight}</TPressable>}
      </XStack>
    </>
  );
};

const IOS_SCROLL_MARGIN = 500;

const TransactionDetailsContent = ({
  transaction,
  isDesktop = false,
}: TransactionDetailsContentProps) => {
  const scrollViewHeight = useMemo(
    () => Dimensions.get('window').height - 24 - 56 - 8,
    []
  );
  const spendingAccount = useSelector(selectSpendingAccountPreviewInfo);
  const savingsAccount = useSelector(selectSavingsAccountPreviewInfo);
  const accountIdsToNames = useSelector(selectAccountIdsToNames);

  const openMapLocation = async (
    city: string,
    region: string,
    zipCode: string,
    country: string
  ) => {
    const address = encodeURIComponent(
      `${city}, ${region} ${zipCode}, ${country}`
    );
    const provider = Platform.OS === 'ios' ? 'apple' : 'google';
    const link = `http://maps.${provider}.com/?q=${address}`;

    const supported = await Linking.canOpenURL(link);

    if (supported) Linking.openURL(link);
  };

  return (
    <YStack>
      <View
        flexDirection="column"
        borderTopLeftRadius={24}
        borderTopRightRadius={24}
        borderWidth={0}
        bg="white"
        px={isDesktop ? '$7' : '$4'}
        marginTop={28}
      >
        <View marginTop={-28} alignItems="center" pb={'$2'}>
          {transaction && transactionsToIcon(transaction, true)}
        </View>
      </View>
      <View flexGrow={1}>
        <ScrollView
          h={scrollViewHeight}
          showsVerticalScrollIndicator={false}
          bg="$blue10"
        >
          <View
            bg="white"
            style={{
              ...Platform.select({
                ios: {
                  paddingTop: IOS_SCROLL_MARGIN,
                  marginTop: -IOS_SCROLL_MARGIN,
                },
              }),
            }}
          >
            <Text
              variant="title"
              textAlign="center"
              mt={'$2'}
              mb={'$4'}
              marginHorizontal={'$2'}
              numberOfLines={2}
            >
              {transactionToTitle(transaction, accountIdsToNames)}
            </Text>
            <View
              h="20px"
              alignItems="center"
              justifyContent="center"
              px={isDesktop ? '$7' : '$4'}
            >
              <Separator borderColor="$gray20" />
              <View position="absolute" zIndex={1} bg="white" px={'$4'}>
                <Text variant="subtitle">
                  {transaction &&
                    isoDateToTransactionsDetailsDate(
                      transaction.transaction_datetime
                    )}
                </Text>
              </View>
            </View>
            <View
              alignItems="center"
              justifyContent="center"
              paddingVertical={16}
            >
              <Text paddingHorizontal={16} variant="pageTitle">
                {!transactionToIsIncome(transaction)
                  ? transactionToAmount(transaction)
                  : transactionToAmount(transaction)}
              </Text>
            </View>
          </View>
          {transactionToIsMerchant(transaction) && (
            <View
              bg="white"
              pr={isDesktop ? '$7' : '$4'}
              pl={isDesktop ? '$7' : '$4'}
              mt={'$4'}
            >
              <TransactionDetailsSection
                isDividerVisible={false}
                sectionName="Category"
                sectionValue={transactionToCategoryName(transaction)}
                IconLeft={<ShopIcon />}
              />
            </View>
          )}
          <View
            justifyContent="center"
            bg="white"
            px={isDesktop ? '$7' : '$4'}
            mt={'$4'}
          >
            <TransactionDetailsSection
              isDividerVisible={false}
              sectionName="Balance left"
              sectionValue={amountToString(transaction?.running_balance || 0)}
              IconLeft={
                <BalanceIcon color="white" width="24px" height="24px" />
              }
            />
            <TransactionDetailsSection
              sectionName="Status"
              sectionValue={transactionToStatus(transaction)}
              IconLeft={<StatusIcon color="white" width="24px" height="24px" />}
            />
            {transaction?.description && (
              <TransactionDetailsSection
                sectionName="Description"
                sectionValue={transaction.description}
                IconLeft={
                  <DescriptionIcon color="white" width="24px" height="24px" />
                }
              />
            )}
          </View>
          {transaction?.merchant && transaction?.merchant.merchant_city && (
            <View
              justifyContent="center"
              bg="white"
              px={isDesktop ? '$7' : '$4'}
              mt={'$4'}
            >
              <TransactionDetailsSection
                sectionName={'Location'}
                sectionValue={`${transaction?.merchant.merchant_city}, ${
                  transaction?.merchant.merchant_region
                } ${transaction?.merchant.merchant_postal_code ?? ''}`}
                isPressable
                onPress={() =>
                  openMapLocation(
                    transaction?.merchant.merchant_city ?? '',
                    transaction?.merchant.merchant_region ?? '',
                    transaction?.merchant.merchant_postal_code ?? '',
                    transaction?.merchant.merchant_country ?? ''
                  )
                }
              />
            </View>
          )}
          {transaction?.transaction_type === 'DEBIT' && (
            <View
              justifyContent="center"
              bg="white"
              px={isDesktop ? '$7' : '$4'}
              mt={'$4'}
            >
              <TransactionDetailsSection
                isDividerVisible={false}
                sectionName={'Payment method'}
                sectionValue={transactionToPaymentMethod(
                  transaction,
                  spendingAccount,
                  savingsAccount
                )}
                IconLeft={
                  <BalanceIcon width="24px" height="24px" color="white" />
                }
              />
              <TransactionDetailsSection
                sectionName={'Posted date'}
                sectionValue={isoDateToTransactionsDetailsDate(
                  transaction.transaction_received_datetime
                )}
                IconLeft={<DateIcon width="24px" height="24px" color="white" />}
              />
            </View>
          )}
          <View h="100px" />
        </ScrollView>
      </View>
    </YStack>
  );
};

export default TransactionDetailsContent;
