import { View } from 'tamagui';
import { Text } from '@atoms';
import { accessibleHitSlop } from '@constants/ui';
import useIsPhone from '@hooks/useIsPhone';
import PhoneIcon from '../../../assets/howToUseDigitalCard.svg';
import ShopIcon from '../../../assets/shop.svg';
import { Platform } from 'react-native';

const HowToUseCardContent = () => {
  const isPhone = useIsPhone();
  return (
    <View
      display={'flex'}
      flexDirection={'column'}
      backgroundColor="white"
      flexGrow={1}
      alignItems="center"
      paddingTop={48}
      paddingHorizontal={isPhone ? 5 : 10}
      accessible={false}
      accessibilityLabel="Getting started"
    >
      <View testID={'paychexpay.howToUseCardContent.phoneIcon'}>
        <PhoneIcon
          width={isPhone ? 160 : 120}
          height={isPhone ? 160 : 120}
          viewBox="0 0 160 160"
        />
      </View>
      <View display={'flex'} flexDirection={'column'} paddingTop={40}>
        <View
          display={'flex'}
          flexDirection="row"
          marginHorizontal={isPhone ? 50 : 70}
          accessible
          accessibilityLabel={
            'Shop and pay online. To find your account info, go to Account Details from the home screen.'
          }
        >
          <View
            flex={Platform.OS === 'web' ? 1 : 0}
            display="flex"
            paddingRight={16}
          >
            <ShopIcon hitSlop={accessibleHitSlop} />
          </View>
          <View
            flexShrink={1}
            paddingLeft={16}
            flex={Platform.OS === 'web' ? 1 : 0}
            display="flex"
            flexDirection="column"
          >
            <Text fontSize={14} fontWeight="700" paddingBottom={5}>
              Shop and pay online
            </Text>
            <Text fontSize={14} color="$gray40">
              To find your account info, go to{' '}
              <Text
                fontSize={14}
                color="$gray40"
                fontWeight={'700'}
                display={Platform.OS === 'web' ? 'inline' : 'flex'}
              >
                Account Details
              </Text>{' '}
              from the home screen.
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default HowToUseCardContent;
