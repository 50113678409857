import { NavigationState } from '@react-navigation/core';

// Recursive function that extracts current route from navigation state
const getCurrentRouteFromNavState = (
  state: NavigationState | Required<NavigationState['routes'][0]>['state']
): string | undefined => {
  if (state.index === undefined || state.index < 0) {
    return undefined;
  }
  const nestedState = state.routes[state.index].state;
  if (nestedState !== undefined) {
    return getCurrentRouteFromNavState(nestedState);
  }
  return state.routes[state.index].name;
};

export default getCurrentRouteFromNavState;
