const measure = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6378.137; // Radius of earth in KM
  const dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
  const dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d * 1000; // meters
};

export const countDistance = (
  lat: number,
  lng: number,
  dLat: number,
  dLng: number
) => {
  const distance = Math.ceil(measure(lat, lng, lat + dLat / 2, lng + dLng / 2));
  return (distance < 1000 ? 1000 : distance).toString();
};

export const ltdLngToString = (lat: number, lng: number) => {
  return lat + ',' + lng;
};
