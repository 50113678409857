import { useState } from 'react';
import { sideModalNames } from '@constants/sideModalNames';
import useIsPhone from '@hooks/useIsPhone';

interface useOnboardingSwiperProps {
  onPressLastNext?: () => void;
  toOpenModal?: (name: string) => () => void;
  componentsArrayLength: number;
}

const useSwiper = ({
  onPressLastNext = () => false,
  componentsArrayLength,
  toOpenModal,
}: useOnboardingSwiperProps) => {
  const isPhone = useIsPhone();
  const [isOpenHelpModal, setIsOpenHelpModal] = useState(false);
  const onOpenHelpModal = () => {
    if (isPhone) {
      setIsOpenHelpModal(true);
    } else if (toOpenModal) {
      toOpenModal(sideModalNames.aboutPaidEarly)();
    }
  };
  const onCloseHelpModal = () => {
    setIsOpenHelpModal(false);
  };
  const [index, setIndex] = useState(0);
  const onPressNext = () => {
    if (index >= componentsArrayLength) {
      onPressLastNext();
    } else {
      setIndex(index + 1);
    }
  };
  const onPressPrev = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };
  return {
    isOpenHelpModal,
    onCloseHelpModal,
    onOpenHelpModal,
    onPressNext,
    onPressPrev,
    index,
    setIndex,
  };
};

export default useSwiper;
