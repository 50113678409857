import {
  getAccessToken,
  savePayload,
  getPayload,
  removePayload,
  // @ts-ignore
} from '@utils/accessTokenHelper';
import { batch } from '@paychex/collector-batch';
import { pipeline } from './datalayer';
import { functions, signals, trackers } from '@paychex/core';
import baseUrl from '../src/constants/endpoints';
import { Platform } from 'react-native';

export async function send(payload: any) {
  const accessToken = await getAccessToken();

  const req_headers = {
    Authorization: 'Bearer ' + accessToken,
  };

  const sendLogs = {
    method: 'POST',
    base: baseUrl,
    path: '/log-ui-events',
    headers: req_headers,
    ignore: {
      tracking: true,
      traceability: true,
    },
  };
  // if there is no access token when signal is set then save those events to the local storage to be retrieved later
  if (!accessToken) {
    savePayload(payload);
  } else {
    // if access token is present and platform is web
    if (Platform.OS == 'web') {
      // check if there is a payload in local storage
      if (getPayload()) {
        // payload exists - send it over the BE endpoint
        await pipeline
          .fetch(
            pipeline.createRequest(
              // @ts-ignore
              sendLogs,
              null,
              getPayload()
            )
          )
          .catch(() => {
            //rejected promise, failed API call
          });
      }
    } else {
      // platform is native - different way of retrieving payload
      getPayload()
        // @ts-ignore
        .then(async (res) => {
          if (res) {
            // payload exists - send it over
            await pipeline
              .fetch(
                pipeline.createRequest(
                  // @ts-ignore
                  sendLogs,
                  null,
                  JSON.parse(res)
                )
              )
              .catch(() => {
                //rejected promise, failed API call
              });
          }
        });
    }
    removePayload();
    // handle other events' payloads - those that occur after login
    await pipeline
      // @ts-ignore
      .fetch(pipeline.createRequest(sendLogs, null, payload))
      .catch(() => {
        //rejected promise, failed API call
      });
  }
}

export const signal = signals.autoReset(false);
const collector = functions.buffer(batch(send), [signal]);

export const tracker = trackers.create(collector);
