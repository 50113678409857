import { sideModalNames } from '@constants/sideModalNames';
import { ACCOUNT_NAMES } from '@constants/accounts';
import { Keyboard, Platform } from 'react-native';
import openLinkInAppBrowser from '@utils/openLinkInAppBrowser';
import { masterCardDepositCash } from '@constants/endpoints';
import { IAgreement } from '@store/features/agreements/types';

export const onPressDepositCash = () => {
  if (Platform.OS !== 'web') {
    Keyboard.dismiss();
    openLinkInAppBrowser(masterCardDepositCash);
  } else {
    const masterCardDepositCashTab = window.open('', '_blank');
    if (masterCardDepositCashTab) {
      masterCardDepositCashTab.opener = null;
      masterCardDepositCashTab.location.href = masterCardDepositCash;
    }
  }
};

export const redirectsHelper = ({
  navigation,
  link,
  handlePayBillsPress,
  toOpenModal,
  feeAgreement,
  depositAgreement,
}: {
  navigation: any;
  link: string;
  handlePayBillsPress: () => void;
  toOpenModal?: (modalName: string) => () => void;
  feeAgreement: IAgreement | undefined;
  depositAgreement: IAgreement | undefined;
}) => {
  switch (link) {
    case 'https://atm':
      navigation.navigate('Atm');
      break;
    case 'https://pay-bill':
      handlePayBillsPress();
      break;
    case 'https://reload-locator':
      onPressDepositCash();
      break;
    case 'https://report-card-lost':
      if (Platform.OS === 'web' && toOpenModal) {
        toOpenModal(sideModalNames.cardLost)();
      } else {
        navigation.navigate('HomeNavigator', {
          screen: 'Support',
          params: { pageName: 'cardLost' },
        });
      }
      break;
    case 'https://customer-service':
      if (Platform.OS === 'web' && toOpenModal) {
        toOpenModal(sideModalNames.customerService)();
      } else {
        navigation.navigate('HomeNavigator', {
          screen: 'Support',
          params: { pageName: 'customerService' },
        });
      }
      break;
    case 'https://dispute-charg':
      if (Platform.OS === 'web' && toOpenModal) {
        toOpenModal(sideModalNames.disputeCharge)();
      } else {
        navigation.navigate('HomeNavigator', {
          screen: 'Support',
          params: { pageName: 'disputeCharge' },
        });
      }
      break;
    case 'https://fee-schedule':
      navigation.navigate('MoreNavigator', {
        screen: 'LegalAgreements',
        params: {
          document_id: feeAgreement?.id || 'Paychex Pay Fee Schedule',
          showSideBar: true,
        },
      });
      break;
    case 'https://deposit-account-agreement':
      navigation.navigate('MoreNavigator', {
        screen: 'LegalAgreements',
        params: {
          document_id:
            depositAgreement?.id || 'Paychex Pay Deposit Account Agreement',
          showSideBar: true,
        },
      });
      break;
    case 'https://freeze-your-card':
    case 'https://add-to-wallet':
      if (Platform.OS === 'web' && toOpenModal) {
        navigation.navigate('HomeNavigator', {
          screen: 'SpendingAccount',
          params: {
            modal: sideModalNames.debitCard,
            accountName: ACCOUNT_NAMES.PaychexPaySpending,
          },
        });
      } else {
        navigation.navigate('MoreNavigator', {
          screen: 'DigitalWalletPage',
        });
      }
  }
};

export const linkNameMap = (url: string) => {
  switch (url) {
    case 'https://atm':
      return 'ATM locator';
    case 'https://add-to-wallet':
      return 'adding the card to the wallet.';
    case 'https://pay-bill':
      return 'Pay a bill.';
    case 'https://reload-locator':
      return 'Reload locator';
    case 'https://fee-schedule':
      return 'Fee Schedule';
    case 'https://deposit-account-agreement':
      return 'Deposit Account Agreement';
    case 'https://report-card-lost':
      return 'Report lost or stolen';
    case 'https://customer-service':
      return 'Customer Service';
    case 'https://dispute-charg':
      return 'Dispute a transaction';
    case 'https://freeze-your-card':
      return 'freeze your card';
    default:
      return '';
  }
};

export default { onPressDepositCash, redirectsHelper, linkNameMap };
