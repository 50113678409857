import { SendFeedbackProps } from '@navigation/MoreNavigator/MoreNavigator.types';
import { SendFeedbackView } from '@views/More';
import { sendEmail } from '@utils/communicationServices';
import { supportInfo } from '@constants/support';

const SendFeedbackPage = ({ navigation }: SendFeedbackProps) => {
  const navigateBack = () => {
    navigation.goBack();
  };
  const onSendFeedback = () => {
    sendEmail(supportInfo.email);
  };
  return (
    <SendFeedbackView
      navigateBack={navigateBack}
      onSendFeedback={onSendFeedback}
    />
  );
};

export default SendFeedbackPage;
