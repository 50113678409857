import { useNavigation } from '@react-navigation/native';
import {
  HomeNavigatorParamsProps,
  StatementsListScreenProps,
} from '@navigation/HomeNavigator/HomeNavigator.types';
import { useSelector } from 'react-redux';
import { StatementsListView } from '@views/Accounts/StatementsListView';
import {
  selectSpendingStatementsList,
  selectSavingsStatementsList,
} from '@store/features/cardStatements/selectors';
import { Platform } from 'react-native';
import { useState } from 'react';
//@ts-ignore
import { Share } from '@utils/shareUtil';
//@ts-ignore
import { ReactNativeBlobUtil } from '@utils/rnBlobUtil';

const StatementsListPage = ({ route }: StatementsListScreenProps) => {
  const [isStatementWebViewOpened, setIsStatementWebViewOpened] =
    useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentState, setDocumentState] = useState('');

  const statementsList =
    route.params?.cardType === 'spending'
      ? useSelector(selectSpendingStatementsList)
      : useSelector(selectSavingsStatementsList);

  const navigation = useNavigation<HomeNavigatorParamsProps['navigation']>();
  const navigateBack = () => {
    if (isStatementWebViewOpened) {
      setIsStatementWebViewOpened(false);
    } else {
      navigation.goBack();
    }
  };

  const handlePdfIconPress = async (documentUrl: string, title?: string) => {
    if (Platform.OS === 'web') {
      window.open(documentUrl, '_blank', 'noopener,noreferrer');
    } else {
      setDocumentUrl(documentUrl);
      setDocumentTitle(title ? title : '');
      setIsStatementWebViewOpened(true);
    }
  };

  const handleSaveButtonPress = () => {
    const configOptions = {
      ios: {
        fileCache: true,
        path: `${ReactNativeBlobUtil.fs.dirs.DocumentDir}/${documentTitle}.pdf`,
        notification: true,
        IOSDownloadTask: true,
      },
      android: {
        fileCache: false,
        addAndroidDownloads: {
          useDownloadManager: true,
          notification: true,
          path: `${ReactNativeBlobUtil.fs.dirs.DownloadDir}/${documentTitle}.pdf`,
        },
      },
    };

    if (route.params?.platform === 'ios' || Platform.OS === 'ios') {
      ReactNativeBlobUtil.config(configOptions.ios)
        .fetch('GET', documentUrl)
        .progress(() => setDocumentState('pending'))
        .then((res: any) => {
          setDocumentState('downloaded');
          const filePath = res.path();
          const options = {
            type: 'application/pdf',
            url: filePath,
            saveToFiles: true,
          };
          Share.open(options);
        });
    } else {
      ReactNativeBlobUtil.config(configOptions.android)
        .fetch('GET', documentUrl)
        .progress(() => setDocumentState('pending'))
        .then((res: any) => {
          setDocumentState('downloaded');
          ReactNativeBlobUtil.android.actionViewIntent(
            res.path(),
            'application/pdf'
          );
        });
    }
  };

  const handleShareButtonPress = async () => {
    await Share.open({
      title: documentUrl,
      url: documentUrl,
    });
  };

  return (
    <StatementsListView
      navigateBack={navigateBack}
      statementsList={statementsList.list}
      isSpendingStatements={route.params?.cardType === 'spending'}
      onPdfIconPress={handlePdfIconPress}
      isStatementWebViewOpened={isStatementWebViewOpened}
      documentUrl={documentUrl}
      documentTitle={documentTitle}
      onSaveButtonPress={handleSaveButtonPress}
      onShareButtonPress={handleShareButtonPress}
      isSaveLoading={documentState === 'pending'}
    />
  );
};

export default StatementsListPage;
