import { Text, TextProps } from 'tamagui';
import { Platform } from 'react-native';

const textConfig = {
  variants: {
    pageTitle: {
      color: '$black',
      fontSize: '$h2',
      fontWeight: '$semiBold',
      lineHeight: 24,
    },
    regular: {
      color: '$black',
      fontSize: '$body',
      fontWeight: '$regular',
      lineHeight: 22,
    },
    subtitle: {
      color: '$gray40',
      fontSize: '$h5',
      fontWeight: '$regular',
      lineHeight: 20,
    },
    title: {
      color: '$black',
      fontSize: '$body',
      fontWeight: '$semiBold',
      lineHeight: 22,
    },
    semiTitle: {
      color: '$black',
      fontSize: '$h5',
      fontWeight: '$medium',
      lineHeight: 20,
    },
    caption: {
      color: '$gray40',
      fontSize: '$h5',
      fontWeight: '$regular',
      lineHeight: 16,
    },
    error: {
      color: '$red50',
      fontSize: '$h5',
      fontWeight: '$regular',
      lineHeight: 16,
    },
  },
};

const variants = { ...textConfig.variants } as const;
type textVariantsType = keyof typeof variants;

interface TTextProps extends TextProps {
  variant?: textVariantsType;
}

const getWebFontProps = ({ variant, fontWeight, textConfig }: any) => {
  if (Platform.OS === 'web') {
    if (fontWeight === '100' || fontWeight === '200' || fontWeight === '300') {
      return {
        fontFamily: '$interLight',
        fontWeight: 'normal',
      };
    } else if (
      textConfig.variants[variant].fontWeight === '$medium' ||
      fontWeight === '$medium' ||
      fontWeight === '500'
    ) {
      return {
        fontFamily: '$interMedium',
        fontWeight: 'normal',
      };
    } else if (
      textConfig.variants[variant].fontWeight === '$semiBold' ||
      fontWeight === '$semiBold' ||
      fontWeight === '600' ||
      fontWeight === '700'
    ) {
      return {
        fontFamily: '$interSemiBold',
        fontWeight: 'normal',
      };
    } else if (
      textConfig.variants[variant].fontWeight === '$bold' ||
      fontWeight === '$bold' ||
      fontWeight === '800'
    ) {
      return {
        fontFamily: '$interBold',
        fontWeight: 'normal',
      };
    }
  }

  return {
    fontFamily: '$interRegular',
    fontWeight: 'normal',
  };
};

const TText = ({ variant = 'regular', fontWeight, ...props }: TTextProps) => {
  return (
    // @ts-ignore
    <Text
      display={Platform.OS === 'web' ? 'block' : undefined}
      {...textConfig.variants[variant]}
      {...props}
      {...(Platform.OS === 'web' &&
        getWebFontProps({ variant, fontWeight, textConfig }))}
      {...(Platform.OS !== 'web' && fontWeight && { fontWeight: fontWeight })}
    />
  );
};

export default TText;
