import AddressVerificationView from '@views/SignUp/AddressVerificationView';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { AddressVerificationProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import { useState } from 'react';
import { fillInDataActions } from '@store/features/fillInData/slice';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { useAppDispatch } from '@store/hooks';

const AddressVerificationPage = ({
  navigation,
  route,
}: AddressVerificationProps) => {
  const { params } = route;
  const dispatch = useAppDispatch();

  const radioButtonOptions = [
    {
      value: '0',
      label: 'Verified address',
      additionalInfoLabel: `${params?.verifiedAddress.addressLine1}\n${params?.verifiedAddress.city}, ${params?.verifiedAddress.state} ${params?.verifiedAddress.zipCode}`,
    },
    {
      value: '1',
      label: 'Entered address',
      additionalInfoLabel: `${params?.enteredAddress.addressLine1}\n${params?.enteredAddress.city}, ${params?.enteredAddress.state} ${params?.enteredAddress.zipCode}`,
    },
  ];

  const [formValue, setFormValue] = useState(
    radioButtonOptions[0].value.toString()
  );

  const onCancelPress = () => {
    navigation.goBack();
  };

  const onSubmitPress = async () => {
    if (params?.verificationFlow === 'signUp') {
      if (formValue === '1' && params?.enteredAddress) {
        dispatch(fillInDataActions.localUpdateSecond(params.enteredAddress));
      }

      navigation.navigate('AgreementsIntro');
    } else {
      if (formValue === '1' && params?.enteredAddress) {
        await dispatch(
          cardholderThunks.updateCardholderInfo({
            addresses: [
              {
                address1: params?.enteredAddress.addressLine1,
                address2: params?.enteredAddress.addressLine2,
                address_type: 'PRIMARY',
                city: params?.enteredAddress.city,
                country: params?.enteredAddress.country ?? '',
                postal_code: params?.enteredAddress.zipCode,
                region: params?.enteredAddress.state,
              },
            ],
          })
        );
      }

      navigation.goBack();
    }
  };

  const onRadioButtonSelect = (optionId: string) => {
    setFormValue(optionId);
  };

  return (
    <MediaLayoutTemplate
      Mobile={AddressVerificationView.mobile}
      Desktop={AddressVerificationView.desktop}
      onCancelPress={onCancelPress}
      onSubmitPress={onSubmitPress}
      onRadioButtonSelect={onRadioButtonSelect}
      radioButtonOptions={radioButtonOptions}
      value={formValue}
    />
  );
};

export default AddressVerificationPage;
