import { RootState } from '@store';
import { createSelector } from '@reduxjs/toolkit';

const cardholderSelector = (state: RootState) => state.cardholder;

export const selectCardholder = createSelector(
  cardholderSelector,
  (state) => state
);

export const selectCardholderCards = createSelector(
  cardholderSelector,
  (state) => state.cards
);

export const selectSpendingAccountId = createSelector(
  cardholderSelector,
  (state) => {
    return (
      state.cards?.find(
        (card) => card.program_id === '{{bank.paychexpay.spending.program.id}}'
      )?.card_id || ''
    );
  }
);

export const selectCardholderCurrentStatus = createSelector(
  cardholderSelector,
  (state) => state.currentStatus
);

export const selectCardholderAdditionalInfo = createSelector(
  cardholderSelector,
  (state) => state.additional_info
);

export const selectShowCashBackPrompt = createSelector(
  cardholderSelector,
  (state) => state.additional_info.show_cash_back_prompt
);

export const selectShowBillPayPrompt = createSelector(
  cardholderSelector,
  (state) => state.additional_info.show_bill_pay_prompt
);

export const selectShowDirectDepositPrompt = createSelector(
  cardholderSelector,
  (state) => state.additional_info.show_direct_deposit_prompt
);

export const selectCardholderAdditionalInfoIsLoading = createSelector(
  cardholderSelector,
  (state) => state.additional_info_is_loading
);

export const selectSavingsAccountId = createSelector(
  cardholderSelector,
  (state) => {
    return (
      state.cards?.find(
        (card) => card.program_id === '{{bank.paychexpay.savings.program.id}}'
      )?.card_id || ''
    );
  }
);
