import { Text } from 'react-native';

export const toHighlight = (text: string | undefined, searchText: string) => {
  if (searchText === '' || !text) return text;
  const reg = new RegExp('(' + searchText + ')', 'gi');
  const sub = '!#$1!#';
  const array = text
    .replace(reg, sub)
    .split('!#')
    .map((str, index) => {
      if (str.toLowerCase() === searchText.toLowerCase()) {
        return (
          <Text key={index} style={{ backgroundColor: '#EBF1FA' }}>
            {str}
          </Text>
        );
      }
      return str;
    });
  return array?.length ? array : text;
};

export default toHighlight;
