import { View } from 'tamagui';
import Logo from '../../../assets/logoBlue.svg';
import React from 'react';

interface WebLogoTemplateProps {
  children: React.ReactNode;
  bg?: string;
  withLogoIcon?: boolean;
}

const WebLogoTemplate = ({
  children,
  bg = 'white',
  withLogoIcon = true,
}: WebLogoTemplateProps) => {
  return (
    <View
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
      flexGrow={1}
      height={0}
      backgroundColor={bg}
      accessible
      accessibilityLabel={'Paychex Pay logo'}
    >
      {withLogoIcon && (
        <View position="absolute" left="$7" top="$7" zIndex={3}>
          <Logo width={175} height={18} />
        </View>
      )}
      {children}
    </View>
  );
};

export default WebLogoTemplate;
