import OutWalletQuestionsView from '@views/CIP/OutWalletQuestionsView';
import { useEffect, useMemo, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import { useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';
import { useAuth0 } from '@hooks/useCustomAuth0';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import {
  selectOowExpTime,
  selectOowQuestions,
  selectOowStatus,
} from '@store/features/oowQuestions/selectors';
import oowQuestionsThunks from '@store/features/oowQuestions/asynkThunks';
import cardholderThunks from '@store/features/cardholder/asyncThunks';
import { selectSpendingAccountId } from '@store/features/cardholder/selectors';
import cardsThunks from '@store/features/cards/asyncThunks';
//@ts-ignore
import { saveAccessToken } from '@utils/accessTokenHelper';
import { OutWalletQuestionsProps } from '@navigation/CIPNavigator/CIPNavigator.types';
import { useAppDispatch } from '@store/hooks';
import { Platform } from 'react-native';
import { navigationStatesActions } from '@store/features/navigationStates/slice';

interface answerType {
  questionId: number;
  optionId: number;
}

const OutWalletQuestionsPage = ({ navigation }: OutWalletQuestionsProps) => {
  const isFocused = useIsFocused();
  const [isLoading, setIsLoading] = useState(false);
  const [isExpireModalOpened, setIsExpireModalOpened] = useState(false);
  const questions = useSelector(selectOowQuestions);
  const oowExpTime = useSelector(selectOowExpTime);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [progressBarValue, setProgressBarValue] = useState<number>(0);
  const [answers, setAnswers] = useState<answerType[]>([]);
  const { seconds, minutes } = useTimer({
    expiryTimestamp: new Date(oowExpTime),
    onExpire: () => setIsExpireModalOpened(true),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirectTo, setRedirectTo] = useState<
    null | 'signUp' | 'other' | 'unableToSignup'
  >(null);

  const goToProvideDocuments = () => {
    navigation.navigate('CIPNavigator', {
      screen: 'VerificationFailedPage',
    });
  };

  const setIsAuthorizedCardholderAndNavigate = async (
    cardholderStatus: string
  ) => {
    await dispatch(navigationStatesActions.setIsAuthorizedCardholder(true));
    setTimeout(async () => {
      switch (cardholderStatus) {
        case 'oowQuestions':
          break;
        case 'denied':
          setIsLoading(false);
          await goToProvideDocuments();
          break;
        default:
          setIsLoading(false);
          await getCardholder();
      }
    }, 2500);
  };

  const spendingAccountId = useSelector(selectSpendingAccountId);

  const {
    loginInfo,
    isLoginProcessFinished,
    onLogin,
    onLogout,
    handleWebViewNavigationStateChange,
    isWebViewLoading,
    getAccessTokenSilently,
    generateNewAccessToken,
  } = useAuth0(setRedirectTo, false);

  const progressBarStep = useMemo(() => 100 / questions.length, [questions]);
  const currentQuestionId = useMemo(
    () => questions && questions[currentQuestionIndex]?.id,
    [currentQuestionIndex, questions]
  );

  const refreshToken = async (currentStatus?: string) => {
    if (Platform.OS === 'web') {
      try {
        const accessToken = await getAccessTokenSilently({ ignoreCache: true });
        await saveAccessToken(accessToken);
        setIsTokenRefreshed(true);
      } catch (e: any) {
        if (e.error === 'login_required' || e.error === 'consent_required') {
          await onLogin();
        }
        const accessToken = await getAccessTokenSilently({ ignoreCache: true });
        await saveAccessToken(accessToken);
        setIsTokenRefreshed(true);
      }
      setIsLoading(false);
    } else {
      await generateNewAccessToken();
      if (currentStatus !== '') {
        await setIsAuthorizedCardholderAndNavigate(currentStatus || '');
      }
    }
  };

  const currentAnswer =
    answers.find(
      (answer: answerType) => answer.questionId === currentQuestionId
    )?.optionId || null;

  useEffect(() => {
    setProgressBarValue((currentQuestionIndex + 1) * progressBarStep);
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (isFocused) {
      refreshToken('');
      setCurrentQuestionIndex(0);
      setAnswers([]);
    }
  }, [isFocused]);

  const onNextPress = (answersArray?: answerType[]) => {
    const answersToSubmit = answersArray || answers;
    if (
      (currentQuestionIndex === 3 &&
        !answersToSubmit.find((answer) => answer.optionId === 6)) ||
      currentQuestionIndex === 4
    ) {
      submitAnswers(answersArray);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const onBackPress = () => {
    if (questions[currentQuestionIndex - 1]) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const addAnswer = (questionId: number, optionId: number) => {
    const newAnswers = [...answers, { questionId: questionId, optionId }];
    setAnswers(newAnswers);
    return newAnswers;
  };

  const changeAnswer = (
    questionId: number,
    optionId: number,
    answerIndex: number
  ) => {
    const newAnswers = [...answers];
    newAnswers[answerIndex] = { questionId, optionId };
    setAnswers(newAnswers);
    return newAnswers;
  };

  const onRadioButtonSelect = (optionId: string) => {
    const parsedOptionId = parseInt(optionId, 10);
    const answerIndex = answers.findIndex(
      (answer) => answer.questionId === currentQuestionId
    );
    if (answerIndex !== -1) {
      return changeAnswer(currentQuestionId, parsedOptionId, answerIndex);
    } else {
      return addAnswer(currentQuestionId, parsedOptionId);
    }
  };

  const dispatch = useAppDispatch();
  const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);

  const submitAnswers = async (answersArray?: answerType[]) => {
    setIsLoading(true);

    let answersToSubmit = answersArray || answers;

    answersToSubmit =
      answersToSubmit.length === 4
        ? [...answersToSubmit, { questionId: 5, optionId: 6 }] // if 4 answers is submited without skip then anyway we need to prefill 5th answer as skipped (requirement from CP)
        : answersToSubmit;

    await dispatch(
      oowQuestionsThunks.postSubmitOwwQuestions({
        program_code: '{{bank.paychexpay.spending.program.id}}',
        oow_answers: answersToSubmit.map((item) => ({
          question_id: item.questionId,
          answer_id: item.optionId,
        })),
      })
    )
      .unwrap()
      .then((payload) => {
        let currentStatus = '';
        if (payload.kyc_status === 'APPROVED') {
          currentStatus = 'justCreated';
        }
        if (payload.kyc_status === 'PENDING_OOW_QUESTIONS') {
          currentStatus = 'oowQuestions';
        }
        if (payload.kyc_status === 'DENIED') {
          currentStatus = 'denied';
        }
        if (payload.kyc_status === 'OOW_EXPIRED') {
          currentStatus = 'denied';
          setIsLoading(false);
          goToProvideDocuments();
        }
        refreshToken(currentStatus);
      })
      .catch(() => {
        setIsLoading(false);
        goToProvideDocuments();
      });
  };

  const oowStatus = useSelector(selectOowStatus);

  useEffect(() => {
    setIsTokenRefreshed(isLoginProcessFinished);
  }, [isLoginProcessFinished]);

  useEffect(() => {
    if (isLoading || !isTokenRefreshed) {
      return;
    }

    if (oowStatus === 'denied') {
      navigation.navigate('VerificationFailedPage');
    }
    if (oowStatus === 'approved') {
      getCardholder();
    }
  }, [oowStatus, isLoading, isTokenRefreshed]);

  const getCardholder = async () => {
    await dispatch(cardholderThunks.getCardholder());
    await dispatch(cardholderThunks.getCardholderAdditionalInfo());
    if (spendingAccountId) {
      await dispatch(
        cardsThunks.getCardInfo({
          cardId: spendingAccountId,
        })
      );
    }
    await navigation.reset({
      index: 0,
      // @ts-ignore
      routes: [
        {
          // @ts-ignore
          name: 'OnboardingNavigator',
          params: {
            screen: 'WelcomeToPaychexPayPage',
          },
        },
      ],
    });
  };

  const onExpireModalClose = async () => {
    await dispatch(cardholderThunks.deleteCardholder());
    await onLogout();
  };

  useEffect(() => {
    return () => {
      setIsExpireModalOpened(false);
    };
  }, []);

  return (
    <MediaLayoutTemplate
      Mobile={OutWalletQuestionsView.mobile}
      Desktop={OutWalletQuestionsView.desktop}
      isNextButtonDisabled={!currentAnswer}
      progressBarValue={progressBarValue}
      currentQuestion={questions[currentQuestionIndex]}
      onNextPress={onNextPress}
      onRadioButtonSelect={onRadioButtonSelect}
      onBackPress={onBackPress}
      isFirstQuestion={currentQuestionIndex === 0}
      isLoading={isLoading}
      currentAnswer={currentAnswer}
      handleWebViewNavigationStateChange={handleWebViewNavigationStateChange}
      loginInfo={loginInfo}
      isWebViewLoading={isWebViewLoading}
      isExpireModalOpened={isExpireModalOpened}
      minutesToExpire={minutes}
      secondsToExpire={seconds}
      onExpireModalClose={onExpireModalClose}
    />
  );
};

export default OutWalletQuestionsPage;
