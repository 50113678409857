import { View } from 'tamagui';
import AddToDigitalWallet from '../../../assets/AddToDigitalWallet.svg';
import { ImageWithContent } from '@molecules';
import { Text } from '@atoms';

const DigitalWalletContent = () => {
  return (
    <View
      paddingTop={48}
      paddingHorizontal={32}
      accessible={false}
      accessibilityLabel="Order Digital Wallet"
    >
      <ImageWithContent image={<AddToDigitalWallet />} space={'$7'}>
        <Text variant="title" paddingBottom={12} w="100%" textAlign="center">
          Is your card in your digital wallet?
        </Text>
        <Text variant="subtitle" w="100%" textAlign="center">
          Add your card to a digital wallet for easy and secure payments
          in-store and online.
        </Text>
      </ImageWithContent>
    </View>
  );
};

export default DigitalWalletContent;
