import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { customTheme } from '../../../styles/customTheme';
import React from 'react';
import { Text } from '@atoms';
import { View, XStack } from 'tamagui';
import { YStack } from 'tamagui';

const TransactionHistorySkeleton = () => {
  return (
    <SkeletonTheme
      baseColor={customTheme.colors.blue['20']}
      highlightColor={customTheme.colors.blue['10']}
    >
      <View
        p={'20px'}
        mb={'20px'}
        bg="white"
        borderRadius={12}
        borderWidth={1}
        borderColor="$gray20"
      >
        <XStack justifyContent="space-between" pb={2}>
          <Text variant="title">Spending activity</Text>
        </XStack>

        <View>
          <View my={4}>
            <Text variant="subtitle">Today</Text>
          </View>
          <XStack justifyContent="space-between" space={3} mb={4}>
            <Skeleton height={48} width={48} borderRadius={4} />
            <YStack flexGrow={3} space={2}>
              <Skeleton height={18} width="90%" borderRadius={4} />
              <Skeleton height={12} width="55%" borderRadius={4} />
            </YStack>
            <YStack flexGrow={1} space={1}>
              <Skeleton height={16} width="100%" borderRadius={4} />
            </YStack>
          </XStack>
          <XStack justifyContent="space-between" space={3}>
            <Skeleton height={48} width={48} borderRadius={4} />
            <YStack flexGrow={3} space={2}>
              <Skeleton height={18} width="90%" borderRadius={4} />
              <Skeleton height={12} width="55%" borderRadius={4} />
            </YStack>
            <YStack flexGrow={1} space={1}>
              <Skeleton height={16} width="100%" borderRadius={4} />
            </YStack>
          </XStack>
        </View>
        <View>
          <View my={4}>
            <Text variant="subtitle">Yesterday</Text>
          </View>
          <XStack justifyContent="space-between" space={3} mb={4}>
            <Skeleton height={48} width={48} borderRadius={4} />
            <YStack flexGrow={3} space={2}>
              <Skeleton height={18} width="90%" borderRadius={4} />
              <Skeleton height={12} width="55%" borderRadius={4} />
            </YStack>
            <YStack flexGrow={1} space={1}>
              <Skeleton height={16} width="100%" borderRadius={4} />
            </YStack>
          </XStack>
          <XStack justifyContent="space-between" space={3} mb={4}>
            <Skeleton height={48} width={48} borderRadius={4} />
            <YStack flexGrow={3} space={2}>
              <Skeleton height={18} width="90%" borderRadius={4} />
              <Skeleton height={12} width="55%" borderRadius={4} />
            </YStack>
            <YStack flexGrow={1} space={1}>
              <Skeleton height={16} width="100%" borderRadius={4} />
            </YStack>
          </XStack>
          <XStack justifyContent="space-between" space={3}>
            <Skeleton height={48} width={48} borderRadius={4} />
            <YStack flexGrow={3} space={2}>
              <Skeleton height={18} width="90%" borderRadius={4} />
              <Skeleton height={12} width="55%" borderRadius={4} />
            </YStack>
            <YStack flexGrow={1} space={1}>
              <Skeleton height={16} width="100%" borderRadius={4} />
            </YStack>
          </XStack>
        </View>
      </View>
    </SkeletonTheme>
  );
};

export default TransactionHistorySkeleton;
