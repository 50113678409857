import { VerificationFailedView } from '@views/CIP';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useAppDispatch } from '@store/hooks';
import { useAuth0 } from '@hooks/useCustomAuth0';
import { navigationStatesActions } from '@store/features/navigationStates/slice';
import { AuthNavigatorParamsProps } from '@navigation/AuthNavigator';
import { useNavigation } from '@react-navigation/native';

const VerificationFailedPage = () => {
  const navigation = useNavigation<AuthNavigatorParamsProps['navigation']>();
  const dispatch = useAppDispatch();
  const { onLogout } = useAuth0();

  const logoutHandler = async () => {
    await onLogout();
    navigation.goBack();
    await dispatch(navigationStatesActions.setIsAuthenticated(false));
    await dispatch(navigationStatesActions.setIsAuthorizedCardholder(false));
  };

  const commonProps = {
    logoutHandler,
  };

  return (
    <MediaLayoutTemplate
      Mobile={VerificationFailedView.mobile}
      Desktop={VerificationFailedView.desktop}
      commonProps={commonProps}
    />
  );
};

export default VerificationFailedPage;
