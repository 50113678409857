import { useSelector } from 'react-redux';
import {
  selectPhysicalCardStatus,
  selectSavingsRequestStatus,
  selectSpendingRequestStatus,
} from '@store/features/cards/selectors';
import { useEffect, useMemo, useState } from 'react';
import { selectCardholderCards } from '@store/features/cardholder/selectors';
import {
  selectIsFetchedInitiallySavingsTransactions,
  selectIsFetchedInitiallySpendingTransactions,
} from '@store/features/transactions/selectors';

const useHomePageSkeleton = () => {
  const spendingRequestStatus = useSelector(selectSpendingRequestStatus);
  const savingsRequestStatus = useSelector(selectSavingsRequestStatus);
  const physicalCardStatus = useSelector(selectPhysicalCardStatus);
  const cardholderCards = useSelector(selectCardholderCards);
  const isFetchedInitiallySpending = useSelector(
    selectIsFetchedInitiallySpendingTransactions
  );
  const isFetchedInitiallySaving = useSelector(
    selectIsFetchedInitiallySavingsTransactions
  );

  const isLoadingTransactions = useMemo(() => {
    return (
      spendingRequestStatus === 'pending' || savingsRequestStatus === 'pending'
    );
  }, [spendingRequestStatus, savingsRequestStatus]);

  const isFetchedInitially = useMemo(() => {
    if (cardholderCards.length === 1) {
      return isFetchedInitiallySpending || isFetchedInitiallySaving;
    } else {
      return isFetchedInitiallySpending && isFetchedInitiallySaving;
    }
  }, [isFetchedInitiallySpending, isFetchedInitiallySaving, cardholderCards]);

  const [isLoadingHomePage, setIsLoadingHomePage] = useState(
    isLoadingTransactions ||
      physicalCardStatus === undefined ||
      !isFetchedInitially
  );

  useEffect(() => {
    setIsLoadingHomePage(
      isLoadingTransactions ||
        physicalCardStatus === undefined ||
        !isFetchedInitially
    );
  }, [isLoadingTransactions, physicalCardStatus, isFetchedInitially]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoadingHomePage(false);
    }, 10000); // hide skeleton after 10 sec (for failure cases)

    return () => timeout && clearTimeout(timeout);
  }, []);

  return { isLoadingHomePage };
};

export default useHomePageSkeleton;
