import { createSlice } from '@reduxjs/toolkit';
import cardStatementsThunks from './asyncThunks';
import { IStatementsList, IStatement } from './types';

interface IStatementsListState {
  spending: {
    list: IStatementsList[];
  };
  saving: {
    list: IStatementsList[];
  };
  statementByIds: IStatement;
}

const initialState = {
  spending: {
    list: [],
  },
  saving: {
    list: [],
  },
  statementByIds: {},
} as IStatementsListState;

const cardStatementsSlice = createSlice({
  name: 'cardStatements',
  initialState,
  reducers: {
    resetSpendingStatementsList(state) {
      state.spending.list = [];
    },
    resetSavingStatementsList(state) {
      state.saving.list = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      cardStatementsThunks.getSpendingStatementsList.fulfilled,
      (state, { payload }) => {
        state.spending.list = payload.data;

        const statementsIds = payload.data.map((statement) => statement.id);
        statementsIds.every((id) => {
          if (!state.statementByIds[id]) state.statementByIds[id] = '';
        });
      }
    ),
      builder.addCase(
        cardStatementsThunks.getSavingStatementsList.fulfilled,
        (state, { payload }) => {
          state.saving.list = payload.data;

          const statementsIds = payload.data.map((statement) => statement.id);
          statementsIds.every((id) => {
            if (!state.statementByIds[id]) state.statementByIds[id] = '';
          });
        }
      ),
      builder.addCase(
        cardStatementsThunks.getStatement.fulfilled,
        (state, { payload }) => {
          state.statementByIds[payload.body.statementId] =
            payload.data.data['document-url'];
        }
      );
  },
});

export const {
  reducer: cardStatementsReducer,
  actions: cardStatementsActions,
} = cardStatementsSlice;
