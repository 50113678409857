const ButtonConfig = {
  baseStyle: {
    _disabled: {
      opacity: '100',
    },
    _focusVisible: {
      _web: {
        outlineWidth: '0',
        style: { boxShadow: 'black 0px 0px 0px 0px' },
      },
    },
  },
  defaultProps: {
    variant: 'primary',
    sizes: 'md',
  },
  sizes: {
    md: {
      _text: {
        fontSize: 'body',
        fontWeight: 'semiBold',
      },
      rounded: '8px',
      py: '11px',
      px: '14px',
    },
  },
  variants: {
    primary: {
      borderWidth: '1px',
      _light: {
        borderColor: 'primary.normal',
        bg: 'primary.normal',
        _text: {
          color: 'white',
        },
        _hover: {
          bg: 'primary.light',
          borderColor: 'primary.light',
        },
        _pressed: {
          bg: 'primary.dark',
          borderColor: 'primary.dark',
        },
        _disabled: {
          bg: '$gray30',
          borderColor: '$gray30',
        },
      },
    },
    // inline colors added because of 'primary.normal' dev error
    white: {
      borderWidth: '1px',
      fontSize: '16px',
      _light: {
        bg: 'white',
        fontSize: 16,
        borderColor: '$gray20',
        _text: {
          color: '#0073C4',
        },
        _hover: {
          bg: 'blue.10',
        },
        _pressed: {
          bg: 'blue.10',
          _text: {
            color: '#0059A2',
          },
        },
        _disabled: {
          bg: '$gray10',
          borderColor: '$gray10',
          _text: {
            color: '#B2BAC5',
          },
        },
      },
    },
    red: {
      borderWidth: '1px',
      _light: {
        bg: 'white',
        borderColor: '$gray20',
        _text: {
          color: 'red.50',
        },
        _hover: {
          bg: 'red.10',
        },
        _pressed: {
          bg: 'red.10',
        },
        _disabled: {
          bg: '$gray10',
          borderColor: '$gray10',
          _text: {
            color: '$gray30',
          },
        },
      },
    },
    simple: {
      _light: {
        _text: {
          color: 'primary.normal',
        },
        _hover: {
          _text: {
            color: 'primary.light',
          },
        },
        _pressed: {
          _text: {
            color: 'primary.dark',
          },
        },
        _disabled: {
          _text: {
            color: '$gray30',
          },
        },
      },
    },
    gray: {
      _light: {
        bg: '$gray10',
        _text: {
          color: '$gray60',
        },
        _hover: {
          bg: 'blue.10',
        },
        _pressed: {
          bg: 'blue.10',
          _text: {
            color: '#0059A2',
          },
        },
      },
    },
    darkGray: {
      _light: {
        borderColor: 'primary.normal',
        bg: 'primary.normal',
        _text: {
          color: 'white',
        },
        _hover: {
          bg: 'primary.light',
          borderColor: 'primary.light',
        },
        _pressed: {
          bg: 'primary.dark',
          borderColor: 'primary.dark',
        },
        _disabled: {
          bg: '#808797',
          borderColor: '#808797',
        },
      },
    },
  },
};

export default ButtonConfig;
