import React, { ReactElement } from 'react';
import { FlatList, Platform, Pressable } from 'react-native';
import { View, XStack, YStack } from 'tamagui';
import { Line, Text } from '@atoms';
import atmObjectToIcon from '@utils/atmObjectToIcon';
import { IMarker } from '@store/features/atmLocations/types';
import metersToMiles from '@utils/metersToMiles';
import GreenMarker from '../../../assets/atm/markerGreen.svg';
import WhiteCircle from '../../../assets/atm/whiteCircle.svg';
import { cloneDeep } from 'lodash';

interface AtmTileProps {
  icon: ReactElement;
  title: string;
  subtitle: string;
  distance: string;
  status: string;
  isClosed: boolean;
  onPress?: () => void;
}

export const AtmTile = ({
  icon,
  title,
  subtitle,
  distance,
  status,
  isClosed,
  onPress,
}: AtmTileProps) => {
  return (
    <Pressable onPress={onPress}>
      <XStack paddingVertical={16}>
        <View
          paddingVertical={1}
          paddingHorizontal={5}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          width={40}
          height={40}
        >
          <View position="relative">
            <GreenMarker color="#19AF92" />
            <View position="absolute" zIndex={1} top={3} left={3}>
              <WhiteCircle />
            </View>
            <View position="absolute" zIndex={2} top={6} left={6}>
              {icon}
            </View>
          </View>
        </View>
        <YStack paddingLeft={16} flexGrow={1} flex={1}>
          <XStack justifyContent="space-between">
            <Text
              fontWeight={'400'}
              flexWrap={'wrap'}
              flex={10}
              marginRight={12}
              numberOfLines={Platform.OS === 'web' ? 1 : 3}
              ellipse={true}
            >
              {title}
            </Text>

            <Text
              flex={3}
              flexShrink={0}
              textAlign={'right'}
              fontWeight={'400'}
              color="$gray40"
            >
              {distance}
            </Text>
          </XStack>
          <XStack justifyContent="space-between">
            <Text fontWeight={'400'} color="$gray40">
              {subtitle}
            </Text>
            <Text color={isClosed ? '$red50' : '$green40'}>{status}</Text>
          </XStack>
        </YStack>
      </XStack>
      <Line />
    </Pressable>
  );
};

interface AtmListProps {
  markers: IMarker[];
  onPressMarker: (onPressEvent: {
    markerId: string;
    latitude: number;
    longitude: number;
  }) => () => void;
}

const AtmList = ({ markers, onPressMarker }: AtmListProps) => {
  const markersCopy = cloneDeep(markers);
  const sortedMarkersByDistance = markersCopy
    ? markersCopy.sort((a, b) => a.distance - b.distance)
    : [];

  return (
    <View flex={1} flexGrow={1} paddingHorizontal={16} height={'100%'}>
      <FlatList
        data={sortedMarkersByDistance}
        renderItem={({ item: marker }) => (
          <AtmTile
            key={marker.location.id + '-tile'}
            icon={atmObjectToIcon(marker, { isLarge: false })}
            title={marker.location.business_name}
            subtitle={'ATM'}
            distance={metersToMiles(marker.distance)}
            status={
              marker.location.capabilities.includes('24hours-service')
                ? 'Open 24 hours'
                : ''
            }
            isClosed={false}
            onPress={onPressMarker({
              markerId: marker.location.id,
              latitude: marker.location.coordinates.latitude,
              longitude: marker.location.coordinates.longitude,
            })}
          />
        )}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.location.id}
      />
    </View>
  );
};

export default AtmList;
