import { useEffect } from 'react';
import * as Keychain from 'react-native-keychain';
import { useSelector } from 'react-redux';
import { BiometricsView } from '@views/Biometrics';
import { biometricsDataActions } from '@store/features/biometrics/slice';
import { selectBiometricsType } from '@store/features/biometrics/selectors';
import MediaLayoutTemplate from '@templates/MediaLayoutTemplate/MediaLayoutTemplate';
import { useAppDispatch } from '@store/hooks';
import { BiometricsPageProps } from '@navigation/AuthNavigator/AuthNavigator.types';
import { useBiometricSwitch } from '@hooks/useBiometricSwitchNative';
import { Platform } from 'react-native';
import { BIOMETRY_TYPE } from 'react-native-keychain';

const BiometricsPage = ({ route, navigation }: BiometricsPageProps) => {
  const nextScreen = route.params?.nextScreen || 'ErrorPage';
  const dispatch = useAppDispatch();
  const biometryType = useSelector(selectBiometricsType);

  const goToNextScreen = () => {
    if (nextScreen === 'HomePage') {
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [
            {
              // @ts-ignore
              name: 'BottomTabsNavigator',
              params: {
                screen: 'HomePage',
              },
            },
          ],
        });
      }, 500);
    } else {
      navigation.navigate(nextScreen);
    }
  };

  const { onEnableBiometric } = useBiometricSwitch({
    onSuccess: goToNextScreen,
  });

  const notNowButtonPress = async () => {
    goToNextScreen();
  };

  const isBiometricsSupport = async () => {
    const supportedBiometryType = await Keychain.getSupportedBiometryType();
    dispatch(biometricsDataActions.updateBiometricsType(supportedBiometryType));
  };

  useEffect(() => {
    if (biometryType === null) {
      goToNextScreen();
    }
    isBiometricsSupport();
  }, []);

  const commonProps = {
    biometryType:
      useSelector(selectBiometricsType) !== null && Platform.OS === 'android'
        ? BIOMETRY_TYPE.FINGERPRINT
        : useSelector(selectBiometricsType),
    enableHandler: onEnableBiometric,
    notNowHandler: notNowButtonPress,
  };
  return (
    <MediaLayoutTemplate
      Mobile={BiometricsView.mobile}
      commonProps={commonProps}
    />
  );
};

export default BiometricsPage;
