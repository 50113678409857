import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import { HeaderWithLineAnimTypes } from '@molecules/HeaderWithLineAnim/HeaderWithLineAnim.types';
import { customTheme } from '../../../styles/customTheme';
import { Text } from '@atoms';
import { View, XStack } from 'tamagui';

const HeaderWithLineAnim = ({
  progressBarValue,
  title,
}: HeaderWithLineAnimTypes) => {
  const progressAnim = useRef(new Animated.Value(progressBarValue)).current;
  const progressPercent = progressAnim.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
  });

  useEffect(() => {
    Animated.timing(progressAnim, {
      toValue: progressBarValue,
      duration: 3000,
      useNativeDriver: false,
      easing: Easing.bezier(0.22, 1, 0.36, 1),
    }).start();
  }, [progressBarValue]);

  const { top: topOffset } = useSafeAreaInsets();
  return (
    <View paddingTop={topOffset} bg="$blue10" overflow="hidden" width="100%">
      <View py="$5" pl="$4" width="100%">
        <Text variant="title" accessible accessibilityLabel={`${title}`}>
          {title}
        </Text>
      </View>
      <XStack>
        <Animated.View
          style={[
            { height: 2 },
            {
              backgroundColor: customTheme.colors.primary.normal,
              width: progressPercent,
            },
          ]}
        />
        <View w="100%" bg="$gray20" h={1} mt={1} />
      </XStack>
    </View>
  );
};

export default HeaderWithLineAnim;
