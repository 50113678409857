import { AboutPFAccountContent, AppPreviewDesktop } from '@organisms';
import { LoginFooter, SideModal, SignInTitle } from '@molecules';
import { Button, Loader } from '@atoms';
import { AppPreviewDesktopTypes } from '@organisms/AppPreviewDesktop/AppPreviewDesktop.types';
import Logo from '../../../assets/logoBlue.svg';
import HeaderAndFooterMobileTemplate from '@templates/HeaderAndFooterMobileTemplate/HeaderAndFooterMobileTemplate';
import ComponentsListProvider from '@utils/ComponentsListProvider';
import { sideModalNames } from '@constants/sideModalNames';
import { Dimensions, Platform, StatusBar } from 'react-native';
import WebView from 'react-native-webview';
import useIsLaptop from '@hooks/useIsLaptop';
import LogoIcon from '../../../assets/PaychexLogo.svg';
import { View } from 'tamagui';

interface PaychexLoginViewMobileProps {
  onPressBack: () => void;
  loginInfo: any;
  handleWebViewNavigationStateChange: () => void;
  isLoading: boolean;
}

interface PaychexLoginViewDesktopProps extends AppPreviewDesktopTypes {
  isModalOpen: boolean;
  onPressHelp: () => void;
  toCloseModal: () => void;
  modalName: string | undefined;
  loginWithPopup: () => void;
  isLoading: boolean;
  onSignUpPress: () => void;
  isRetry: boolean;
  isSkipLanding?: boolean;
}

const leftSidePreviewContent = ({
  onGooglePlayPress,
  onAppStorePress,
}: AppPreviewDesktopTypes) => {
  const isLaptop = useIsLaptop();

  if (isLaptop) {
    return (
      <View
        backgroundColor="$primaryNormal"
        flexGrow={0}
        maxWidth="180px"
        width={'100%'}
      >
        <View alignItems="center" pt={7}>
          <LogoIcon width="147px" height="14px" />
        </View>
      </View>
    );
  } else
    return (
      <AppPreviewDesktop
        onGooglePlayPress={onGooglePlayPress}
        onAppStorePress={onAppStorePress}
      />
    );
};

export const LoadingScreen = () => {
  return (
    <View
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Loader />
    </View>
  );
};

const mobile = ({
  onPressBack,
  loginInfo,
  handleWebViewNavigationStateChange,
  isLoading,
}: PaychexLoginViewMobileProps) => {
  const screenWith = Dimensions.get('window').width;
  const isWeb = Platform.OS === 'web';
  return (
    <HeaderAndFooterMobileTemplate
      header={{
        onPressBack,
        isBackButton: true,
        logo: <Logo />,
      }}
      footerContent={<LoginFooter />}
    >
      <View
        display="flex"
        flexGrow={1}
        px={4}
        bg="white"
        justifyContent="center"
        alignItems="center"
      >
        {isWeb ? (
          <LoadingScreen />
        ) : (
          <View flexGrow={1}>
            {loginInfo.authorizeUrl !== null && !isLoading ? (
              <WebView
                domStorageEnabled={true}
                source={{ uri: loginInfo.authorizeUrl }}
                startInLoadingState={true}
                renderLoading={() => <LoadingScreen />}
                onNavigationStateChange={handleWebViewNavigationStateChange}
                scalesPageToFit={true}
                style={{
                  width: screenWith,
                  flex: 1,
                }}
              />
            ) : (
              <LoadingScreen />
            )}
          </View>
        )}
        <StatusBar barStyle="dark-content" backgroundColor="white" />
      </View>
    </HeaderAndFooterMobileTemplate>
  );
};

const desktop = ({
  onPressHelp,
  isModalOpen,
  toCloseModal,
  onGooglePlayPress,
  onAppStorePress,
  modalName,
  loginWithPopup,
  isLoading,
  onSignUpPress,
  isRetry,
  isSkipLanding = false,
}: PaychexLoginViewDesktopProps) => {
  return (
    <View flexGrow={1} flexDirection="row">
      {leftSidePreviewContent({ onGooglePlayPress, onAppStorePress })}
      <View flexGrow={1} flexDirection="column" width="min-content">
        <View
          display="flex"
          justifyContent="center"
          backgroundColor="white"
          flexGrow={1}
          p={4}
          alignItems="center"
        >
          {(isLoading && !isRetry) || isSkipLanding ? (
            <LoadingScreen />
          ) : (
            <>
              <SignInTitle onPressHelp={onPressHelp} />
              <View minWidth="50%" p={'3'} alignItems="center">
                <Button
                  onPress={() => loginWithPopup()}
                  testID="paychexpay.desktop.paychexLoginView.button.loginButton"
                  width="325px"
                  fontSize={16}
                >
                  Sign in
                </Button>
              </View>
            </>
          )}
        </View>
        <LoginFooter />
      </View>
      <SideModal isModalOpen={isModalOpen} onPressModalClose={toCloseModal}>
        <ComponentsListProvider
          componentsName={modalName}
          components={{
            [sideModalNames.signInFlex]: (
              <AboutPFAccountContent signUp={onSignUpPress} />
            ),
          }}
        />
      </SideModal>
    </View>
  );
};

const PaychexLoginView = {
  mobile,
  desktop,
};

export default PaychexLoginView;
