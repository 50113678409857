import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TILES from '@constants/tiles';
import EarnRewardsTileIcon from '../../../assets/tiles/cashback.svg';
import PayBillsTileIcon from '../../../assets/tiles/payBills.svg';
import { View, YStack } from 'tamagui';
import { Tile } from '@molecules';
import { Dimensions } from 'react-native';
import { TileCarouselLooped } from '@organisms';
import { useSelector } from 'react-redux';
import {
  frozenCardWidget,
  rewardsWidget,
} from '@store/features/homeWidgets/selectors';
import useTileButtonHandler from '@hooks/useTileButtonHandler';
import { selectShowBillPayPrompt } from '@store/features/cardholder/selectors';

interface TileCarouselProps {
  onPayBillsButtonPress?: () => void;
  isPayBillButtonDisabled?: boolean;
  isRedirectToPayBillsLoading?: boolean;
  onRewardsButtonPress?: () => void;
  isRedirectToRewardsLoading?: boolean;
}
type SlideType = {
  title: string;
  subtitle: string;
  leftButtonText: string;
  rightButtonText?: string;
  icon: any;
  type: string;
  onRightButtonPress?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isVisible?: boolean;
};
const TileCarousel = ({
  onPayBillsButtonPress,
  isPayBillButtonDisabled,
  isRedirectToPayBillsLoading,
  onRewardsButtonPress,
  isRedirectToRewardsLoading,
}: TileCarouselProps) => {
  const isRewardsWidgetVisible = useSelector(rewardsWidget);
  const showBillPayPrompt = useSelector(selectShowBillPayPrompt);

  const items: Array<SlideType> = useMemo(() => {
    const _items = [];
    if (isRewardsWidgetVisible) {
      _items.push({
        ...TILES.earnRewards,
        icon: <EarnRewardsTileIcon />,
        type: 'rewards',
        onRightButtonPress: onRewardsButtonPress,
        isLoading: isRedirectToRewardsLoading,
        isVisible: isRewardsWidgetVisible,
        firstTestID: 'rewardsLeftButton',
        secondTestID: 'rewardsRightButton',
      });
    }

    if (showBillPayPrompt || showBillPayPrompt === null) {
      _items.push({
        ...TILES.payBills,
        icon: <PayBillsTileIcon />,
        type: 'bills',
        onRightButtonPress: onPayBillsButtonPress,
        isDisabled: isPayBillButtonDisabled,
        isLoading: isRedirectToPayBillsLoading,
        isVisible: showBillPayPrompt || showBillPayPrompt === null,
        firstTestID: 'payBillsLeftButton',
        secondTestID: 'payBillsRightButton',
      });
    }

    return _items;
  }, [
    isPayBillButtonDisabled,
    isRedirectToPayBillsLoading,
    isRewardsWidgetVisible,
    isRedirectToRewardsLoading,
    onRewardsButtonPress,
  ]);

  const [data, setData] = useState(items);
  const activeType = useSelector(frozenCardWidget);
  const buttonHandler = useTileButtonHandler();

  useEffect(() => {
    setData([...items]);
  }, [
    activeType,
    isPayBillButtonDisabled,
    isRedirectToPayBillsLoading,
    isRewardsWidgetVisible,
    isRedirectToRewardsLoading,
    onRewardsButtonPress,
  ]);

  const onPressLeftButton = (type: string) => () => {
    buttonHandler(type, 'positive');
    const filteredItems = items.filter(
      (widgetItem) => widgetItem.type !== type
    );
    setData([...filteredItems]);
  };

  const renderItem = useCallback((item: any) => {
    if (!item) return <></>;

    return (
      <View display="flex" px="$4" pb="$5" flexGrow={1}>
        <Tile
          isCarousel={true}
          skeletonEnabled={false}
          variant={item.rightButtonText ? 'twoButtons' : 'simpleButton'}
          {...item}
          leftButtonHandler={
            item.rightButtonText
              ? onPressLeftButton(item.type)
              : item.onRightButtonPress
          }
          {...(item.isDisabled && {
            isDisabled: item.isDisabled,
          })}
          {...(item.isLoading && {
            isRightButtonLoading: item.isLoading,
          })}
          {...(item.isLoading &&
            !item.rightButtonText && {
              isLeftButtonLoading: item.isLoading,
            })}
          {...(item.onRightButtonPress && {
            rightButtonHandler: item.onRightButtonPress,
          })}
        />
      </View>
    );
  }, []);

  const CarouselMemoized = useMemo(() => {
    if (!data) return null;
    if (data.length === 1) return renderItem(data[0]);
    return (
      <TileCarouselLooped
        sliderWidth={Dimensions.get('window').width}
        itemWidth={Dimensions.get('window').width}
        loop={true}
        renderSlide={renderItem}
        data={data}
      />
    );
  }, [data]);
  return <YStack>{CarouselMemoized}</YStack>;
};

export default TileCarousel;
