import { wrappedAxiosRequest } from '@store/api';
import endpoints from '@constants/endpoints';
import {
  IAddressSuggestionsRequest,
  IAddressSuggestionsResponse,
  IAddressVerifyRequest,
  IUserInfoResponse,
  IUserIsEligibleResponse,
} from '@store/features/fillInData/types';
import { application, operations } from '@constants/traceability';

const fillInDataAPI = {
  logoutFromSessions() {
    return wrappedAxiosRequest<null, IUserInfoResponse>({
      method: 'get',
      url: endpoints.logoutFromSessions,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.LogoutFromSessions,
        'x-payx-comp': application.compPrefix + operations.LogoutFromSessions,
      },
    });
  },
  getUserInfo() {
    return wrappedAxiosRequest<null, IUserInfoResponse>({
      method: 'get',
      url: endpoints.userInfo,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.GetUserInfo,
        'x-payx-comp': application.compPrefix + operations.GetUserInfo,
      },
    });
  },
  checkIfUserEligible() {
    return wrappedAxiosRequest<null, IUserIsEligibleResponse>({
      method: 'get',
      url: endpoints.checkIsEligible,
      headers: {
        'x-payx-bizpn':
          application.bizpnPrefix + operations.CheckIfUserEligible,
        'x-payx-comp': application.compPrefix + operations.CheckIfUserEligible,
      },
    });
  },
  postAddressVerify(body: IAddressVerifyRequest) {
    return wrappedAxiosRequest<IAddressVerifyRequest[], any>({
      method: 'post',
      url: endpoints.addressVerify,
      data: [body],
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.VerifyAddress,
        'x-payx-comp': application.compPrefix + operations.VerifyAddress,
      },
    });
  },
  postAddressSuggestions(body: IAddressSuggestionsRequest) {
    return wrappedAxiosRequest<
      IAddressSuggestionsRequest,
      IAddressSuggestionsResponse
    >({
      method: 'post',
      url: endpoints.addressSuggestions,
      data: body,
      headers: {
        'x-payx-bizpn': application.bizpnPrefix + operations.AddressSuggestions,
        'x-payx-comp': application.compPrefix + operations.AddressSuggestions,
      },
    });
  },
};

export default fillInDataAPI;
