export const application = {
  bizpnPrefix: 'PaychexPay:',
  compPrefix: 'PaychexPay.',
};

export const operations = {
  OutgoingTransfer: 'OutgoingTransfer',
  IncomingTransfer: 'IncomingTransfer',
  SpendingAccount: 'FetchSpendingAccount',
  SavingsAccount: 'FetchSavingsAccount',
  ExternalAccount: 'FetchExternalAccounts',
  SpendingTransactions: 'FetchSpendingTransactions',
  SavingsTransactions: 'FetchSavingsTransactions',
  FilteredSpendingTransactions: 'FetchFilteredSpendingTransactions',
  FilteredSavingsTransactions: 'FetchFilteredSavingsTransactions',
  AllSpendingTransactions: 'FetchAllSpendingTransactions',
  AllSavingsTransactions: 'FetchAllSavingsTransactions',
  SearchSpendingTransactions: 'SearchSpendingTransactions',
  SearchSavingsTransactions: 'SearchSavingsTransactions',
  DeleteExternalAccount: 'DeleteExternalAccount',
  AddExternalAccount: 'AddExternalAccount',
  GetCardInfo: 'GetCardInfo',
  GetCardAuthInfo: 'GetCardAuthInfo',
  PutCardStatus: 'PutCardStatus',
  CreateSavingsAccount: 'CreateSavingsAccount',
  ActivePhysicalCard: 'ActivatePhysicalCard',
  ChangePin: 'ChangePin',
  OrderNewCard: 'OrderNewCard',
  GetAgreementById: 'GetAgreementById',
  GetSavingsAgreement: 'GetSavingsAgreement',
  AgreementAcknowledgement: 'AgreementAcknowledgement',
  GetATMLocations: 'GetATMLocations',
  CreateCardholder: 'CreateCardholder',
  GetCardholder: 'GetCardholder',
  DeleteCardholder: 'DeleteCardholder',
  GetAdditionalCardholderInfo: 'GetAdditionalCardholderInfo',
  UpdateAdditionalCardholderInfo: 'UpdateAdditionalCardholderInfo',
  UpdateCardholderInfo: 'UpdateCardholderInfo',
  GetSpendingStatementsList: 'GetSpendingStatementsList',
  GetSavingsStatementsList: 'GetSavingsStatementsList',
  GetStatement: 'GetStatement',
  GenerateDdaForm: 'GenerateDDAForm',
  LogoutFromSessions: 'LogoutFromSessions',
  GetUserInfo: 'GetUserInfo',
  CheckIfUserEligible: 'CheckIfUserIsEligible',
  VerifyAddress: 'VerifyAddress',
  AddressSuggestions: 'AddressSuggestions',
  BillPay: 'BillPay',
  CheckIfUserDdaEligible: 'CheckIfUserDDAEligible',
  GetMobileCheckLoadCreds: 'GetMobileCheckDepositCredentials',
  GetNotifications: 'GetNotifications',
  UpdateNotifications: 'UpdateNotifications',
  SubmitOOWQuestions: 'SubmitOOWQuestions',
  RewardsURL: 'FetchRewardsURL',
  NetworkTransfer: 'NetworkTransfer',
  Transfer: 'Transfer',
};
