import { HeaderMobileTemplate } from '@templates';
import AboutSsnContent from '@organisms/AboutSsnContent/AboutSsnContent';

interface AboutSsnViewProps {
  onPressRightButton: () => void;
}

const AboutSsnView = ({ onPressRightButton }: AboutSsnViewProps) => {
  return (
    <HeaderMobileTemplate
      header={{
        title: 'SSN info',
        isRightButton: true,
        onPressRightButton,
      }}
    >
      <AboutSsnContent />
    </HeaderMobileTemplate>
  );
};

export default AboutSsnView;
