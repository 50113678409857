import { Switch } from 'native-base';
import { ReactElement, useMemo } from 'react';
import { Pressable, Text } from '@atoms';
import EditIcon from '../../../assets/Edit.svg';
import { Dimensions, Platform, Text as RNText } from 'react-native';
import useIsPhone from '@hooks/useIsPhone';
import { ICardholder } from '@store/features/cardholder/types';
import useIsTablet from '@hooks/useIsTablet';
import { BIOMETRY_TYPE } from 'react-native-keychain';
import { accessibleHitSlop } from '@constants/ui';
import { getBiometryName } from '@utils/biometricsHelper';
import { Separator, View, XStack, YStack } from 'tamagui';

const SimpleUserInfoLine = ({
  icon,
  title,
  info,
  isPhone,
}: {
  icon: ReactElement;
  title: string;
  info: string;
  isPhone: boolean;
}) => {
  return (
    <XStack pb={'$4'} pt={isPhone ? '$4' : 0} alignItems="center">
      {icon}
      <YStack pl={'$3'}>
        <Text variant="subtitle" pb={'$1'}>
          {title}
        </Text>
        <Text
          variant="regular"
          testID={`paychexpay.simpleUserInfoLine.${title}`}
        >
          {info}
        </Text>
      </YStack>
    </XStack>
  );
};

const textWidthPhone = Dimensions.get('window').width - 72;
const textWidthTablet = Dimensions.get('window').width - 150;

const EditableUserInfoLine = ({
  title,
  info,
  onPressEdit,
  isPhone,
  isTablet,
}: {
  title: string;
  info: string;
  onPressEdit: () => void;
  isPhone: boolean;
  isTablet: boolean | undefined;
}) => {
  return (
    <>
      <XStack
        paddingVertical={14}
        alignItems="center"
        justifyContent="space-between"
      >
        <YStack>
          <Text variant="subtitle" pb={1}>
            {title}
          </Text>
          <Text
            variant="regular"
            numberOfLines={1}
            w={isPhone ? textWidthPhone : isTablet ? textWidthTablet : 284}
            testID={`paychexpay.profileSettingContent.userInfoLine.${title}`}
          >
            {info}
          </Text>
        </YStack>
        <Pressable
          onPress={onPressEdit}
          testID={`paychexpay.profileSettingContent.button.userProfileEditHandler-${title}`}
          hitSlop={accessibleHitSlop}
          accessible
          accessibilityLabel={`Edit ${title}, button`}
        >
          <EditIcon />
        </Pressable>
      </XStack>
      <Separator borderColor="$gray20" />
    </>
  );
};

interface ProfileSettingContentProps {
  userProfileData: ICardholder;
  dateOfBirth: string;
  userSSN: string;
  icons: {
    nameIcon: ReactElement;
    calendarIcon: ReactElement;
    ssnIcon: ReactElement;
    infoIcon: ReactElement;
  };
  navigateToEditPhone: () => void;
  navigateToEditEmail: () => void;
  navigateToEditAddress: () => void;
  toOpenSupport: () => void;
  isTurnedOnBiometric?: null | boolean;
  onToggleBiometric?: () => void;
  showBiometricSection?: boolean;
  biometryType?: '' | BIOMETRY_TYPE | null;
}

const ProfileSettingContent = ({
  userProfileData,
  dateOfBirth,
  userSSN,
  icons,
  navigateToEditPhone,
  navigateToEditEmail,
  navigateToEditAddress,
  toOpenSupport,
  isTurnedOnBiometric,
  onToggleBiometric,
  showBiometricSection,
  biometryType,
}: ProfileSettingContentProps) => {
  const isPhone = useIsPhone();
  const isTablet = useIsTablet();
  const biometryName = useMemo(
    () => getBiometryName(biometryType),
    [biometryType]
  );

  const [primaryAddress] = userProfileData.addresses.filter(
    (address) => address.address_type === 'PRIMARY'
  );
  const [mobilePhone] = userProfileData.phone_details.filter(
    (phone) => phone.phone_type === 'MOBILE'
  );

  const address = useMemo(() => {
    return `${primaryAddress.address1}, ${primaryAddress.postal_code}, ${primaryAddress.city}, ${primaryAddress.region}, ${primaryAddress.country}`;
  }, [userProfileData]);

  const mobilePhoneView = useMemo(() => {
    return `(${mobilePhone.phone_number.slice(
      0,
      3
    )}) ${mobilePhone.phone_number.slice(
      3,
      6
    )}-${mobilePhone.phone_number.slice(6, 10)}`;
  }, [mobilePhone.phone_number]);

  const name = `${userProfileData.first_name} ${
    userProfileData.middle_name ? `${userProfileData.middle_name} ` : ''
  }${userProfileData.last_name}`;

  return (
    <YStack>
      <View
        bg="$blue10"
        px={'$4'}
        pt={isPhone ? 0 : '$4'}
        borderTopRightRadius={isPhone ? 0 : 8}
        borderTopLeftRadius={isPhone ? 0 : 8}
        accessible={false}
        accessibilityLabel="Profile Settings"
      >
        <SimpleUserInfoLine
          icon={icons.nameIcon}
          title="Name"
          info={name}
          isPhone={isPhone}
        />
        {isPhone && <Separator borderWidth={0.5} borderColor="$gray20" />}
        <SimpleUserInfoLine
          icon={icons.calendarIcon}
          title="Birth date"
          info={dateOfBirth}
          isPhone={isPhone}
        />
        {userSSN && (
          <>
            {isPhone && <Separator borderWidth={0.5} borderColor="$gray20" />}
            <SimpleUserInfoLine
              icon={icons.ssnIcon}
              title="SSN"
              info={userSSN}
              isPhone={isPhone}
            />
          </>
        )}
      </View>
      <View
        bg="$blue20"
        p={'$4'}
        borderBottomRightRadius={isPhone ? 0 : 8}
        borderBottomLeftRadius={isPhone ? 0 : 8}
      >
        <XStack flex={1}>
          <View width="24px" height="24px">
            {icons.infoIcon}
          </View>
          <Pressable
            onPress={toOpenSupport}
            width="100%"
            pr="28px"
            testID="paychexpay.profileSettingContent.button.contactSupportButton"
            hitSlop={accessibleHitSlop}
            accessible
            accessibilityLabel={
              'If you need to change the legal name on your account, contact us, button'
            }
          >
            <XStack>
              <RNText
                style={{
                  fontSize: 14,
                  lineHeight: 22,
                  paddingLeft: 8,
                  fontFamily: 'Inter-Regular, sans-serif',
                }}
              >
                If you need to change the legal name on your account,
                <RNText
                  style={{
                    fontSize: 14,
                    fontWeight: '400',
                    color: '#0073C4',
                    lineHeight: 22,
                  }}
                >
                  {' '}
                  contact us
                </RNText>
              </RNText>
            </XStack>
          </Pressable>
        </XStack>
      </View>
      {showBiometricSection && (
        <View pt={isPhone ? '$5' : '$4'} px={isPhone ? '$4' : 0}>
          <Separator borderWidth={0.5} borderColor="$gray20" />
          {Platform.OS !== 'web' && Platform.OS !== 'android' && (
            <>
              <XStack
                justifyContent="space-between"
                alignItems="center"
                py={'$5'}
              >
                <Text variant="regular">
                  Use {biometryName ?? 'biometric'} authentication
                </Text>
                {isTurnedOnBiometric !== null && (
                  <Switch
                    size="sm"
                    hitSlop={accessibleHitSlop}
                    onTrackColor="primary.normal"
                    isChecked={isTurnedOnBiometric}
                    onToggle={onToggleBiometric}
                    testID="paychexpay.profileSettingContent.button.biometricSwitch"
                  />
                )}
              </XStack>
              <Separator borderWidth={0.5} borderColor="$gray20" />
            </>
          )}
        </View>
      )}
      <View px={isPhone ? '$4' : 0}>
        {!showBiometricSection && (
          <Separator borderWidth={0.5} borderColor="$gray20" />
        )}
        <EditableUserInfoLine
          title="Mobile phone"
          info={mobilePhoneView}
          onPressEdit={navigateToEditPhone}
          isPhone={isPhone}
          isTablet={isTablet}
        />
        <EditableUserInfoLine
          title="Personal email"
          info={userProfileData.email}
          onPressEdit={navigateToEditEmail}
          isPhone={isPhone}
          isTablet={isTablet}
        />
        <EditableUserInfoLine
          title="Address"
          info={address}
          onPressEdit={navigateToEditAddress}
          isPhone={isPhone}
          isTablet={isTablet}
        />
      </View>
      {showBiometricSection && Platform.OS === 'android' && (
        <>
          <XStack
            justifyContent="space-between"
            alignItems="center"
            py={'$5'}
            px={'$4'}
          >
            <Text variant="regular">Touch ID</Text>
            {isTurnedOnBiometric !== null && (
              <Switch
                size="sm"
                hitSlop={accessibleHitSlop}
                onTrackColor="primary.normal"
                isChecked={isTurnedOnBiometric}
                onToggle={onToggleBiometric}
                testID="paychexpay.profileSettingContent.button.biometricSwitch"
              />
            )}
          </XStack>
          <View
            bg="$blue20"
            p={'$4'}
            borderBottomRightRadius={isPhone ? 0 : 8}
            borderBottomLeftRadius={isPhone ? 0 : 8}
          >
            <XStack flex={1}>
              <View width="24px" height="24px">
                {icons.infoIcon}
              </View>

              <XStack>
                <RNText
                  style={{
                    fontSize: 14,
                    lineHeight: 22,
                    paddingLeft: 8,
                    fontFamily: 'Inter-Regular, sans-serif',
                  }}
                >
                  Allow Touch ID to make logging in easier
                </RNText>
              </XStack>
            </XStack>
          </View>
        </>
      )}
    </YStack>
  );
};

export default ProfileSettingContent;
