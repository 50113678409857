import { ScrollView, View, XStack } from 'tamagui';
import { HeaderMobileTemplate } from '@templates';
import { Line, Text } from '@atoms';
import MenuArrow from '../../../assets/menuArrow.svg';
import { MobileWebHeader } from '@molecules';
import React from 'react';
import { Platform } from 'react-native';
import { IAgreement } from '@store/features/agreements/types';

interface LegalViewProps {
  navigateBack: () => void;
  menuItems: IAgreement[];
  handleAgreementPress: (item: IAgreement) => void;
}

const LegalMenuItem = ({
  title,
  handler,
}: {
  title: string;
  handler: () => void;
}) => {
  return (
    <View paddingHorizontal={16} onPress={handler} flex={0}>
      <XStack paddingVertical={20} justifyContent="space-between">
        <View flex={1} paddingRight={10}>
          <Text variant="regular">{title} </Text>
        </View>
        <View
          display={'flex'}
          flex={0}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <MenuArrow />
        </View>
      </XStack>
      <Line />
    </View>
  );
};

const LegalView = ({
  navigateBack,
  menuItems,
  handleAgreementPress,
}: LegalViewProps) => {
  return (
    <>
      {Platform.OS === 'web' && <MobileWebHeader />}

      <HeaderMobileTemplate
        header={{
          title: 'Document Center',
          isBackButton: true,
          onPressBack: navigateBack,
        }}
      >
        <ScrollView showsVerticalScrollIndicator={false}>
          {menuItems.map((item, index) => (
            <LegalMenuItem
              handler={() => handleAgreementPress(item)}
              title={item.name}
              key={index}
            />
          ))}
        </ScrollView>
      </HeaderMobileTemplate>
    </>
  );
};

export default LegalView;
