import React, { ReactNode, useState } from 'react';
import { Platform, StatusBar } from 'react-native';
import { ScrollView, View, XStack } from 'tamagui';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Logo from '../../../assets/logo.svg';
import HelpIcon from '../../../assets/helpIconWhite.svg';
import BurgerMenuIcon from '../../../assets/burgerMenu.svg';
import CloseIcon from '../../../assets/closeIcon.svg';
import { STATIC_TOP_INSET, STATIC_TOP_LARGE_INSET } from './constants';
import { CustomDrawer } from '@molecules';
import { TPressable } from '@atoms';
import { accessibleHitSlop } from '@constants/ui';
import { MOBILE_WEB_HEADER_HEIGHT } from '@constants/general';
import useIsPhone from '@hooks/useIsPhone';
import ToastPopup from '@atoms/ToastPopup/ToastPopup.web';

interface HomepageLayoutProps {
  onPressHelp: () => void;
  children: ReactNode;
  isActiveCardToastPopupOpen?: boolean;
  isUnfrozenCardToastPopupOpen?: boolean;
}

const BLUE_TOP_MARGIN = 500;

const HomepageLayout = ({
  children,
  onPressHelp,
  isActiveCardToastPopupOpen,
  isUnfrozenCardToastPopupOpen,
}: HomepageLayoutProps) => {
  const isPhone = useIsPhone();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { top: topInset } = useSafeAreaInsets();

  const toMenuPress = () => {
    setMenuOpen((prevState) => !prevState);
  };

  return Platform.OS === 'web' ? (
    <View bg="$white" position="relative" flex={1}>
      <StatusBar
        barStyle="light-content"
        backgroundColor={'rgba(255, 0, 255, 0)'}
      />
      <ToastPopup
        isOpen={!!isActiveCardToastPopupOpen}
        text={'Activated! Your card is ready to use.'}
      />
      <ToastPopup
        isOpen={!!isUnfrozenCardToastPopupOpen}
        text={'Unfrozen! Your card is ready to use again.'}
      />
      <View
        position="absolute"
        zIndex={3}
        w="100%"
        h={`${MOBILE_WEB_HEADER_HEIGHT}px`}
        bg="$primaryDark"
        px="$4"
        py="$3"
      >
        <XStack justifyContent="space-between">
          <XStack alignItems="center" height={24}>
            <TPressable
              pr="$4"
              height={24}
              onPress={toMenuPress}
              testID="paychexpay.homepageLayout.button.openMenuButton"
              accessible
              accessibilityLabel={`${isMenuOpen ? 'Close' : 'Open'} menu`}
            >
              {isMenuOpen ? (
                <CloseIcon height="24px" width="24px" fill="white" />
              ) : (
                <BurgerMenuIcon />
              )}
            </TPressable>
            <Logo accessible accessibilityLabel={'Paychex Pay logo'} />
          </XStack>
          <XStack>
            <View
              onPress={onPressHelp}
              accessible
              accessibilityLabel={'Help, button'}
            >
              <HelpIcon height="24px" width="24px" fill="white" />
            </View>
            <View />
          </XStack>
        </XStack>
      </View>
      {isMenuOpen ? (
        <View
          position={'absolute'}
          height="100%"
          width="100%"
          display={'flex'}
          flexGrow={1}
          flexDirection={'column'}
          zIndex={2}
        >
          <CustomDrawer
            isFullScreenDrawer={isPhone}
            onDrawerItemPress={toMenuPress}
          />
        </View>
      ) : (
        <ScrollView
          showsVerticalScrollIndicator={false}
          h={Platform.OS === 'web' ? '100vh' : 'auto'}
          contentContainerStyle={{
            paddingHorizontal: 16,
            paddingVertical: 24,
            marginTop: 48,
            marginBottom: Platform.OS === 'web' ? 48 : 0,
          }}
        >
          {children}
        </ScrollView>
      )}
    </View>
  ) : (
    <View bg="$white" position="relative" h="100%">
      <StatusBar
        barStyle="light-content"
        backgroundColor={'rgba(255, 0, 255, 0)'}
      />
      <View
        position="absolute"
        zIndex={1}
        w="100%"
        h={topInset + STATIC_TOP_INSET}
        bg="$primaryNormal"
        px="$4"
      >
        <View h={topInset} />
        <XStack justifyContent="space-between" mt="$4" mb="$5">
          <View
            onPress={onPressHelp}
            hitSlop={accessibleHitSlop}
            accessible
            accessibilityLabel={'Help, button'}
          >
            <HelpIcon height="20px" width="20px" fill="white" />
          </View>
          <Logo accessible accessibilityLabel={'Paychex Pay logo'} />
          <View />
        </XStack>
      </View>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View
          h={BLUE_TOP_MARGIN + topInset + STATIC_TOP_LARGE_INSET}
          mt={-BLUE_TOP_MARGIN}
          bg="$primaryNormal"
        />
        <View px="$4" mt={-STATIC_TOP_INSET}>
          {children}
        </View>
      </ScrollView>
    </View>
  );
};

export default HomepageLayout;
