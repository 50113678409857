import { Dimensions, PixelRatio, Platform } from 'react-native';
import { extendTheme } from 'native-base';
import { DEFAULT_SCREEN_HEIGHT } from '@constants/general';
import ButtonConfig from '@atoms/Button/ButtonConfig';
import InputConfig from '@atoms/Input/InputConfig';
import RadioConfig from '@atoms/Radio/RadioConfig';
import Checkbox from '@atoms/Checkbox/CheckboxConfig';
import TextConfig from '@atoms/Text/TextConfig';

const spacing = {
  '0': 0,
  '1': 4,
  '2': 8,
  '3': 12,
  '4': 16,
  '5': 24,
  '6': 32,
  '7': 40,
  '8': 48,
  '9': 64,
  '10': 80,
};

export const normalizeFontSize = (size: number) => {
  if (Platform.OS === 'web' || process.env.JEST_WORKER_ID !== undefined)
    return size;
  const minimumFontSize = size;
  const height = Dimensions.get('window').height;
  const scale = height / DEFAULT_SCREEN_HEIGHT;
  const newSize = Math.round(PixelRatio.roundToNearestPixel(size * scale));
  return newSize < minimumFontSize ? minimumFontSize : newSize;
};

export const customTheme = extendTheme({
  colors: {
    primary: {
      normal: '#0073C4',
      dark: '#0059A2',
      light: '#6AA0DB',
      coveredWith25PercentBlack: '#2F579E',
    },
    black: '#272830',
    white: '#FFF',
    gray: {
      50: '#666C7B',
      40: '#6F7686',
      35: '#808797',
      30: '#B2BAC5',
      20: '#DEE3EC',
      10: '#EFEFEF',
      60: '#656C7B',
    },
    red: {
      50: '#DC2433',
      40: '#F85E5E',
      20: '#FFE6E6',
      10: '#FFF6F6',
    },
    violet: {
      40: '#9568FF',
      20: '#E3D7FF',
    },
    green: {
      40: '#0B7E67',
      20: '#D8F1EA',
    },
    yellow: {
      40: '#F8B414',
      20: '#FFEDBD',
    },
    blue: {
      20: '#EBF1FA',
      10: '#F6F7F9',
    },
  },
  fontSizes: {
    h1: normalizeFontSize(24),
    h2: normalizeFontSize(20),
    h3: normalizeFontSize(16),
    h4: normalizeFontSize(14),
    h5: normalizeFontSize(14),
    body: normalizeFontSize(16),
    caption: normalizeFontSize(12),
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 800,
  },
  fontFamily: {
    InterBlack: 'Inter-Black',
    InterBold: 'Inter-Bold',
    InterExtraBold: 'Inter-ExtraBold',
    InterExtraLight: 'Inter-ExtraLight',
    InterLight: 'Inter-Light',
    InterMedium: 'Inter-Medium',
    InterRegular: 'Inter-Semibold',
    InterThin: 'Inter-Thin',
    OCRB: 'OCRB',
  },
  fontConfig: {
    Inter: {
      100: {
        normal: 'Inter-Light',
      },
      200: {
        normal: 'Inter-Light',
      },
      300: {
        normal: 'Inter-Light',
      },
      400: {
        normal: 'Inter-Regular',
      },
      500: {
        normal: 'Inter-Medium',
      },
      600: {
        normal: 'Inter-SemiBold',
      },
      700: {
        normal: 'Inter-SemiBold',
      },
      800: {
        normal: 'Inter-Bold',
      },
    },
    OCRB: {
      100: {
        normal: 'OCRB',
      },
      200: {
        normal: 'OCRB',
      },
      300: {
        normal: 'OCRB',
      },
      400: {
        normal: 'OCRB',
      },
      500: {
        normal: 'OCRB',
      },
      600: {
        normal: 'OCRB',
      },
      700: {
        normal: 'OCRB',
      },
      800: {
        normal: 'OCRB',
      },
    },
  },
  fonts: {
    body: 'Inter',
    h1: 'Inter',
    h2: 'Inter',
    h3: 'Inter',
    h4: 'Inter',
    h5: 'Inter',
    caption: 'Inter',
    card: 'OCRB',
  },
  space: spacing,
  sizes: spacing,
  components: {
    Button: ButtonConfig,
    Input: InputConfig,
    Checkbox: Checkbox,
    Radio: RadioConfig,
    Text: TextConfig,
  },
});

type CustomThemeType = typeof customTheme;

declare module 'native-base' {
  interface ICustomTheme extends CustomThemeType {}
}
