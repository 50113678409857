import { View, XStack } from 'tamagui';
import { Text as RNText } from 'react-native';
import { Text } from '@atoms';
import { ReactElement, useMemo } from 'react';
import toHighlight from '@utils/toHighlight';
import { useSelector } from 'react-redux';
import { selectSearchText } from '@store/features/transactions/selectors';
import { stripHtml } from 'string-strip-html';

export interface TransactionPreviewProps {
  icon: ReactElement;
  title: string | undefined;
  subtitle: string | undefined;
  amount: string;
  isIncome?: boolean;
  onPress?: () => void;
  withHighlight?: boolean;
  index?: number;
}

const TransactionPreview = ({
  icon,
  title,
  subtitle,
  amount,
  isIncome = false,
  onPress,
  withHighlight = false,
  index,
}: TransactionPreviewProps) => {
  const searchText = useSelector(selectSearchText);
  const highlightedTitle = useMemo(
    () =>
      withHighlight
        ? toHighlight(
            stripHtml((title || '').replace(/\s+/g, ' ')).result,
            searchText
          )
        : stripHtml((title || '').replace(/\s+/g, ' ')).result,
    [searchText, title]
  );
  const highlightedAmount = useMemo(
    () =>
      withHighlight
        ? toHighlight(amount ? amount : '', searchText.replace('-', ''))
        : amount,
    [searchText, amount]
  );

  const isErrorTransactionType =
    subtitle?.includes('Declined') || subtitle?.includes('Expire');

  const isPending = subtitle?.includes('wait');

  return (
    <View
      mt="$4"
      onPress={onPress}
      testID={`paychexpay.TransactionPreview.button.${index}`}
      cursor="pointer"
      height={40}
      accessible
      accessibilityLabel={`Transaction ${title}, ${subtitle || ''}, ${amount}`}
    >
      <XStack justifyContent="space-between" w="100%">
        <XStack flexGrow={1} flexBasis={0}>
          <View flexShrink={0} borderRadius="$1" overflow="hidden">
            {icon}
          </View>
          <View flex={1} flexGrow={1} pl="$4" pr={30} overflow={'hidden'}>
            <Text
              variant="semiTitle"
              numberOfLines={1}
              textOverflow={'ellipsis'}
            >
              {highlightedTitle}
            </Text>
            <Text
              variant="subtitle"
              numberOfLines={1}
              textOverflow={'ellipsis'}
              color={isErrorTransactionType ? '$red50' : '$gray40'}
            >
              {stripHtml((subtitle || '').replace(/\s+/g, ' ')).result}
            </Text>
          </View>
        </XStack>
        <RNText
          style={{
            fontWeight: '500',
            fontSize: 14,
            lineHeight: 20,
            color: isErrorTransactionType
              ? '#DC2433'
              : isPending
              ? '#808797'
              : isIncome
              ? '#0B7E67'
              : 'black',
          }}
        >
          {highlightedAmount}
        </RNText>
      </XStack>
    </View>
  );
};

export default TransactionPreview;
